import { Stack, useTheme } from '@mui/material';

import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';

import {
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../../components/DetailDrawer';
import { BreakingChangesOverview } from '../BreakingChangesOverview';
import { BreakingChangesSection } from '../BreakingChangesSection';

export const RemediationDetailDrawerBreakingChanges = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  const { palette, space } = useTheme();

  return (
    <DetailDrawerSectionStack>
      <Stack
        padding={space.md}
        sx={{ backgroundColor: palette.background.default }}
      >
        <BreakingChangesOverview upgradeInfo={upgradeInfo} />
      </Stack>
      <DetailDrawerSection>
        <BreakingChangesSection upgradeInfo={upgradeInfo} />
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
