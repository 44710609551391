import { Stack, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { useListAssuredPackageVersions } from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  ButtonLinkSecondary,
  ButtonStack,
  PackageVersionNameDisplay,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { DetailDrawerContainer } from '../../../../components/DetailDrawer';
import { useAuthInfo } from '../../../../providers';
import { getAssuredPackageVersionPath } from '../../../../routes';
import { VersionUpgradeDisplay } from '../../../Remediations';
import { AssuredPackageVersionDetailDrawerOverview } from './AssuredPackageVersionDetailDrawerOverview';
import { AssuredPackageVersionDetailDrawerPatches } from './AssuredPackageVersionDetailDrawerPatches';

const AssuredPackageVersionDetailDrawerTabRecords = [
  {
    label: <FM defaultMessage="Overview" />,
    value: 'overview',
  },
  {
    label: <FM defaultMessage="Patches" />,
    value: 'patches',
  },
];

export type AssuredPackageVersionDetailDrawerProps = {
  packageVersionName: string;
};
/**
 * Detail Info Drawer for an AssuredPackageVersion
 */
export const AssuredPackageVersionDetailDrawer = ({
  packageVersionName,
}: AssuredPackageVersionDetailDrawerProps) => {
  const { space } = useTheme();
  const { activeNamespace } = useAuthInfo();

  const qListAssuredPackageVersions = useListAssuredPackageVersions(
    NAMESPACES.OSS,
    {
      filter: `meta.name=="${packageVersionName}-endor-latest"`,
      page_size: 1,
    },
    { enabled: !!packageVersionName }
  );

  const assuredPackageVersion = qListAssuredPackageVersions.data?.objects.at(0);

  const { version: fromVersion } =
    UIPackageVersionUtils.parsePackageName(packageVersionName);
  const { version: toVersion } = UIPackageVersionUtils.parsePackageName(
    assuredPackageVersion?.meta.name
  );

  return (
    <DetailDrawerContainer
      headerProps={{
        title: (
          <Stack gap={space.sm}>
            <Stack gap={space.xs}>
              <PackageVersionNameDisplay
                packageVersion={{ meta: { name: packageVersionName } }}
                size="xlarge"
              />

              {fromVersion && toVersion && (
                <VersionUpgradeDisplay
                  fromVersion={fromVersion}
                  toVersion={toVersion}
                />
              )}
            </Stack>

            <ButtonStack>
              <ButtonLinkPrimary disabled linkProps={{ to: '' }}>
                <FM defaultMessage="Patch Instructions" />
              </ButtonLinkPrimary>

              <ButtonLinkSecondary
                linkProps={{
                  to: getAssuredPackageVersionPath({
                    namespace: activeNamespace,
                    packageVersionName,
                  }),
                }}
                sx={{ width: 'fit-content' }}
              >
                <FM defaultMessage="View Details" />
              </ButtonLinkSecondary>
            </ButtonStack>
          </Stack>
        ),
      }}
      tabProps={
        // TODO: add loading and empty states
        assuredPackageVersion && {
          id: 'AssuredPackageVersionDetailDrawer',
          tabRecords: AssuredPackageVersionDetailDrawerTabRecords,
          tabPanelMap: {
            overview: (
              <AssuredPackageVersionDetailDrawerOverview
                assuredPackageVersion={assuredPackageVersion}
              />
            ),
            patches: (
              <AssuredPackageVersionDetailDrawerPatches
                assuredPackageVersion={assuredPackageVersion}
              />
            ),
          },
        }
      }
    />
  );
};
