import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { MasterToolChainProfileResource } from '@endorlabs/endor-core/MasterToolChainProfile';
import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import {
  ControlledCodeEditorField,
  ControlledTextField,
  RowStack,
} from '@endorlabs/ui-common';
import { safeParse as safeParseJSON } from '@endorlabs/utils/encoding/json';

import { FormUpsertScanProfileFieldValues } from './types';
import { getDefaultValues } from './utils';

export type FormUpsertScanProfileProps = {
  isLoading?: boolean;
  namespace: string;
  onSubmit: (data: ScanProfileResource) => void;
  scanProfile?: ScanProfileResource;
  masterToolChainProfile?: MasterToolChainProfileResource;
};

export const FormUpsertScanProfile = ({
  isLoading,
  masterToolChainProfile,
  onSubmit,
  scanProfile,
}: FormUpsertScanProfileProps) => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const formMethods = useForm<FormUpsertScanProfileFieldValues>();
  const {
    control,
    handleSubmit: hookFormSubmit,
    reset,
    setError,
  } = formMethods;

  useEffect(() => {
    const defaultValues = getDefaultValues({
      masterToolChainProfile,
      scanProfile,
    });

    reset(defaultValues);
  }, [masterToolChainProfile, reset, scanProfile]);

  const onBeforeSubmit = (values: FormUpsertScanProfileFieldValues) => {
    const toolchainProfile = safeParseJSON(values.extra.raw_toolchain_profile);
    if (!toolchainProfile.ok) {
      setError('extra.raw_toolchain_profile', {
        message: toolchainProfile.error.message,
        type: 'validate',
      });
      return;
    }

    const resource = {
      meta: values.meta,
      spec: { toolchain_profile: toolchainProfile.value },
    } as ScanProfileResource;

    onSubmit(resource);
  };

  const isEdit = !!scanProfile;

  return (
    <FormProvider {...formMethods}>
      <form
        id="FormUpsertScanProfile"
        onSubmit={hookFormSubmit(onBeforeSubmit)}
        noValidate
      >
        <Stack direction="column" spacing={space.md}>
          <RowStack spacing={space.md} justifyContent="space-between">
            <Box flexGrow={1} maxWidth={400}>
              <ControlledTextField
                control={control}
                label={fm({
                  defaultMessage: 'Scan Profile Name',
                })}
                name="meta.name"
                placeholder={fm({
                  defaultMessage: 'Enter a name for the Scan Profile.',
                })}
                required
              />
            </Box>

            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              {isEdit ? (
                <FM defaultMessage="Save Scan Profile" />
              ) : (
                <FM defaultMessage="Create Scan Profile" />
              )}
            </LoadingButton>
          </RowStack>

          <Card>
            <CardContent>
              <ControlledCodeEditorField
                control={control}
                name="extra.raw_toolchain_profile"
                codeEditorProps={{
                  enableClipboard: false,
                  language: 'application/json',
                  height: 600,
                  width: '100%',
                }}
              />
            </CardContent>
          </Card>
        </Stack>
      </form>
    </FormProvider>
  );
};
