import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';

import { FindingCount } from '@endorlabs/queries';
import {
  FindingCountArrayDisplay,
  IconCheck,
  IconX,
  PackageVersionNameDisplay,
  Pip,
  RowStack,
} from '@endorlabs/ui-common';

interface RemediationsGroupAccordionTitleNodeProps {
  dependencyPackageVersionName: string;
  findingCounts?: FindingCount[];
  isLoading?: boolean;
  hasEndorPatchUpgrade?: boolean;
  manifestFiles?: string[];
  packageVersionName: string;
  version: string;
}

export const RemediationsGroupAccordionTitleNode = ({
  dependencyPackageVersionName,
  findingCounts,
  isLoading,
  hasEndorPatchUpgrade = false,
  manifestFiles,
  packageVersionName,
  version,
}: RemediationsGroupAccordionTitleNodeProps) => {
  const { spacing } = useTheme();

  return (
    <RowStack
      className="RemediationsGroupAccordionTitleNode-root"
      justifyContent="space-between"
      width="100%"
      padding={spacing(4, 0)}
    >
      <PackageVersionNameDisplay
        packageVersion={{
          meta: { name: dependencyPackageVersionName },
        }}
        size="medium"
        iconFrame
        IconProps={{
          height: 24,
        }}
      />

      <Stack>
        <PackageVersionNameDisplay
          packageVersion={{ meta: { name: packageVersionName } }}
          size="xsmall"
        />

        {isLoading && (
          <Skeleton width={64} variant="text" sx={{ fontSize: 'small' }} />
        )}

        {!isLoading &&
          manifestFiles?.map((file, index) => (
            <Typography key={index} variant="caption" color="text.secondary">
              {file}
            </Typography>
          ))}
      </Stack>

      <Box>
        <Pip label={version} variant="outlined" color="warning" />
      </Box>

      {hasEndorPatchUpgrade ? (
        <IconCheck color="success" />
      ) : (
        <IconX color="error" />
      )}

      <FindingCountArrayDisplay value={findingCounts} />
    </RowStack>
  );
};
