import { Box, Stack, Typography } from '@mui/material';
import { formatDistanceToNowStrict, isBefore } from 'date-fns';
import { isNil as _isNil } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import {
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  IconStar,
  NilDisplay,
  PackageNameDisplay,
  ProjectNameDisplay,
  RowStack,
} from '@endorlabs/ui-common';

import {
  RemediationRiskDisplay,
  RemediationsTableProps,
  RemediationsTableRow,
  VersionUpgradeDisplay,
} from '../components';

export const buildRemediationsTableColumns = ({
  excludeColumns,
}: Pick<
  RemediationsTableProps,
  'excludeColumns'
>): DataTableColumnDef<RemediationsTableRow>[] => {
  const columns: DataTableColumnDef<RemediationsTableRow>[] = [
    {
      accessorKey: 'dependencyName',
      cell: (t) => {
        return (
          <Box sx={{ overflowWrap: 'anywhere' }}>
            <PackageNameDisplay name={t.getValue()} />
          </Box>
        );
      },
      header: () => <FM defaultMessage="Dependency" />,
      enableSorting: true,
      maxSize: 220,
      size: 220,
    },
    {
      accessorKey: 'packageVersionName',
      cell: (t) => {
        return (
          <Box sx={{ overflowWrap: 'anywhere' }}>
            <PackageNameDisplay name={t.getValue()} />
          </Box>
        );
      },
      header: () => <FM defaultMessage="Affected Package" />,
      enableSorting: true,
      maxSize: 220,
      size: 220,
    },
    {
      id: 'versionUpgrade',
      accessorKey: 'fromVersion',
      cell: (t) => {
        const isBest = t.row.original.isBest;
        const isLatest = t.row.original.isLatest;
        const fromVersion = t.row.original.fromVersion;
        const toVersion = t.row.original.toVersion;
        const upgradeDisplay = (
          <VersionUpgradeDisplay
            fromVersion={fromVersion}
            toVersion={toVersion}
          />
        );

        if (!isBest && !isLatest) {
          return upgradeDisplay;
        }

        return (
          <Stack gap={2}>
            <RowStack gap={4}>
              {isBest && (
                <Typography
                  component="div"
                  sx={({ palette }) => ({
                    color: palette.success.dark,
                    fontSize: 10,
                  })}
                  variant="body2"
                >
                  <RowStack gap={1}>
                    <IconStar sx={{ height: '10px' }} />
                    <FM defaultMessage="Recommended" />
                  </RowStack>
                </Typography>
              )}

              {isLatest && (
                <Typography sx={{ fontSize: 10 }} variant="body2">
                  <FM defaultMessage="Latest Version" />{' '}
                </Typography>
              )}
            </RowStack>
            {upgradeDisplay}
          </Stack>
        );
      },
      header: () => <FM defaultMessage="Version Upgrade" />,
      enableSorting: true,
      maxSize: 200,
      size: 200,
    },
    {
      accessorKey: 'remediationRisk',
      cell: (t) => {
        return <RemediationRiskDisplay remediationRisk={t.getValue()} />;
      },
      header: () => <FM defaultMessage="Remediation Risk" />,
      enableSorting: true,
      maxSize: 150,
      size: 150,
    },
    {
      accessorKey: 'findingCounts',
      colType: ColTypes.FINDING_COUNTS,
      header: () => <FM defaultMessage="Fixed Findings" />,
      enableSorting: true,
      maxSize: 180,
      size: 180,
    },
    {
      accessorKey: 'versionAge',
      cell: (t) => {
        const publishTime = t.getValue();
        const publishDate = new Date(publishTime);

        if (
          _isNil(publishTime) ||
          publishTime === '' ||
          isBefore(publishDate, new Date('01-01-1970'))
        ) {
          return <NilDisplay variant="text" />;
        }

        const diff = formatDistanceToNowStrict(publishDate);

        return <Typography>{diff}</Typography>;
      },
      header: () => <FM defaultMessage="Version Age" />,
      enableSorting: true,
      maxSize: 150,
      size: 150,
    },
    {
      accessorKey: 'projectName',
      cell: (t) => {
        return (
          <Box sx={{ overflowWrap: 'anywhere' }}>
            <ProjectNameDisplay name={t.getValue()} showIcon={true} />
          </Box>
        );
      },
      header: () => <FM defaultMessage="Project" />,
      enableSorting: true,
      maxSize: 220,
      size: 220,
    },
  ];

  // TODO: Probably make this generic handling for DataTableView

  // HACK: removing excluded columns from definitions
  return columns.filter((col) => {
    if (!col.accessorKey) return true;
    return (excludeColumns ?? []).includes(col.accessorKey) ? false : true;
  });
};
