import { Box, ListItemText, MenuItem, MenuList, Skeleton } from '@mui/material';
import { uniqBy as _uniqBy } from 'lodash-es';
import { matchSorter } from 'match-sorter';
import { forwardRef, SyntheticEvent, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { UserInfoResponseTenantInfo as TenantInfo } from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListTenantNamespaces } from '@endorlabs/queries';
import { EmptyState } from '@endorlabs/ui-common';

import { mapToNamespaceResources } from '../../utils';
import { NamespaceLabel } from '../NamespaceLabel';

const ListItemSkeletons = () => {
  return (
    <>
      <MenuItem>
        <Skeleton variant="rounded" width="80%" />
      </MenuItem>
      <MenuItem>
        <Skeleton variant="rounded" width="50%" />
      </MenuItem>
      <MenuItem>
        <Skeleton variant="rounded" width="65%" />
      </MenuItem>
    </>
  );
};

type NamespaceSwitcherListViewProps = {
  activeNamespace: string;
  namespaces?: string[];
  onSelect: (event: SyntheticEvent, namespace: string) => void;
  searchValue: string;
  selectedNamespace?: string;
  tenants: TenantInfo[];
};

export const NamespaceSwitcherListView = forwardRef<
  HTMLUListElement,
  NamespaceSwitcherListViewProps
>((props, ref) => {
  const {
    activeNamespace,
    namespaces,
    onSelect,
    searchValue,
    selectedNamespace,
    tenants,
  } = props;

  const qListNamespaces = useListTenantNamespaces(tenants, {
    filter: filterExpressionBuilders.or([
      `spec.full_name matches "${searchValue}"`,
      `meta.description matches "${searchValue}"`,
      `meta.tags matches "${searchValue}"`,
    ]),
    traverse: true,
  });

  const namespaceResources = useMemo(() => {
    const namespaceResources = mapToNamespaceResources(tenants, namespaces);

    const uniqueNamespaceResources = _uniqBy(
      [...namespaceResources, ...qListNamespaces.data],
      'spec.full_name'
    );

    return matchSorter(uniqueNamespaceResources, searchValue, {
      keys: ['spec.full_name', 'meta.description', 'meta.tags'],
    });
  }, [namespaces, qListNamespaces.data, searchValue, tenants]);

  const isFetching = qListNamespaces.isFetching;
  const isLoading = qListNamespaces.isLoading;
  const isEmptyState = !isFetching && namespaceResources.length === 0;

  if (isEmptyState) {
    return (
      <Box paddingX={2}>
        <EmptyState
          size="small"
          title={<FM defaultMessage="No namespaces found" />}
        />
      </Box>
    );
  }

  return (
    <MenuList
      ref={ref}
      disablePadding
      sx={{
        maxHeight: '58vh',
        overflowY: 'auto',
        '& .MuiMenuItem-root': {
          padding: (t) => t.spacing(1, 3),
          minHeight: 'auto',
        },
      }}
    >
      {!isLoading &&
        namespaceResources.map((nr) => (
          <MenuItem
            key={nr.spec.full_name}
            onClick={(e) => onSelect(e, nr.spec.full_name)}
            selected={nr.spec.full_name === selectedNamespace}
          >
            <ListItemText>
              <NamespaceLabel namespaceResource={nr} showFullNamespace />
            </ListItemText>
          </MenuItem>
        ))}

      {isFetching && <ListItemSkeletons />}
    </MenuList>
  );
});

NamespaceSwitcherListView.displayName = 'NamespaceSwitcherListView';
