import { defineMessages, MessageDescriptor } from 'react-intl';

import {
  ScanResultSpecType as ScanResultType,
  V1EndorctlRC as ExitCode,
} from '@endorlabs/api_client';

export const ScanResultExitCodeMessages: Record<ExitCode, MessageDescriptor> =
  defineMessages({
    [ExitCode.AlreadyExists]: { defaultMessage: 'Already Exists' },
    [ExitCode.AnalyticsError]: { defaultMessage: 'Analytics Error' },
    [ExitCode.BadPolicyType]: { defaultMessage: 'Bad Policy Type' },
    [ExitCode.CallGraphError]: { defaultMessage: 'Call Graph Error' },
    [ExitCode.DeadlineExceeded]: { defaultMessage: 'Deadline Exceeded' },
    [ExitCode.DependencyResolutionError]: {
      defaultMessage: 'Dependency Resolution Error',
    },
    [ExitCode.DependencyScanningError]: {
      defaultMessage: 'Dependency Scanning Error',
    },
    [ExitCode.DoctorFailure]: { defaultMessage: 'Doctor Failure' },
    [ExitCode.HostCheckFailure]: { defaultMessage: 'Host-Check Failure' },

    [ExitCode.EndorAuthFailure]: { defaultMessage: 'Endor Auth Failure' },
    [ExitCode.Error]: { defaultMessage: 'Error' },
    [ExitCode.FindingsError]: { defaultMessage: 'Findings Error' },
    [ExitCode.GithubAuthFailure]: { defaultMessage: 'GitHub Auth Failure' },
    [ExitCode.GithubApiError]: { defaultMessage: 'GitHub API Error' },
    [ExitCode.GithubPermissionsError]: {
      defaultMessage: 'GitHub Permissions Error',
    },
    [ExitCode.GitError]: { defaultMessage: 'Git Error' },
    [ExitCode.InitializationError]: { defaultMessage: 'Initialization Error' },
    [ExitCode.InternalError]: { defaultMessage: 'Internal Error' },
    [ExitCode.InvalidArgs]: { defaultMessage: 'Invalid Args' },
    [ExitCode.LinterError]: { defaultMessage: 'Linter Error' },
    [ExitCode.NotFound]: { defaultMessage: 'Not Found' },
    [ExitCode.NotificationsError]: { defaultMessage: 'Notifications Error' },
    [ExitCode.PolicyError]: { defaultMessage: 'Policy Error' },
    [ExitCode.PolicyViolation]: { defaultMessage: 'Policy Violation' },
    [ExitCode.PolicyWarning]: { defaultMessage: 'Policy Warning' },
    [ExitCode.Success]: { defaultMessage: 'Success' },
    [ExitCode.Unauthenticated]: { defaultMessage: 'Unauthenticated' },
    [ExitCode.Unspecified]: { defaultMessage: 'Unspecified' },
    [ExitCode.VulnError]: { defaultMessage: 'Vulnerability Error' },
    [ExitCode.SbomImportError]: { defaultMessage: 'SBOM Import Error' },
    [ExitCode.PreCommitCheckFailure]: {
      defaultMessage: 'Pre-commit Check Failure',
    },
    [ExitCode.GhActionWorkflowScanFailure]: {
      defaultMessage: 'GitHub Action Workflow Scan Failure',
    },
    [ExitCode.FileAnalyticsError]: { defaultMessage: 'File Analytics Error' },
    [ExitCode.SignatureVerificationFailure]: {
      defaultMessage: 'Signature Verification Failure',
    },
    [ExitCode.LicenseError]: { defaultMessage: 'License Error' },
    [ExitCode.HuggingFaceError]: { defaultMessage: 'HuggingFace Error' },
    [ExitCode.SastError]: { defaultMessage: 'SAST Error' },

  });

export const ScanResultTypeMessages: Record<ScanResultType, MessageDescriptor> =
  defineMessages({
    [ScanResultType.AllScans]: { defaultMessage: 'All Scans' },
    [ScanResultType.ExceptionPolicies]: {
      defaultMessage: 'Exception Policies',
    },
    [ScanResultType.AdmissionPolicies]: { defaultMessage: 'Action Policies' },
    [ScanResultType.AlertPolicies]: { defaultMessage: 'Alert' },
    [ScanResultType.Analytics]: { defaultMessage: 'Analytics' },
    [ScanResultType.Doctor]: { defaultMessage: 'Doctor' },
    [ScanResultType.Findings]: { defaultMessage: 'Findings' },
    [ScanResultType.Git]: { defaultMessage: 'Git' },
    [ScanResultType.Github]: { defaultMessage: 'GitHub' },
    [ScanResultType.Linter]: { defaultMessage: 'Linter' },
    [ScanResultType.NotificationPolicies]: { defaultMessage: 'Notifications' },
    [ScanResultType.Org]: { defaultMessage: 'Org' },
    [ScanResultType.Package]: { defaultMessage: 'Package' },
    [ScanResultType.SbomImport]: { defaultMessage: 'SBOM Import' },
    [ScanResultType.Unspecified]: { defaultMessage: 'Unspecified' },
    [ScanResultType.HostCheck]: { defaultMessage: 'Host-Check' },
    [ScanResultType.DependencyResolution]: {
      defaultMessage: 'Dependency Resolution',
    },
    [ScanResultType.CallGraph]: { defaultMessage: 'Call-Graph' },
    [ScanResultType.FileAnalytics]: { defaultMessage: 'File Analytics' },
    [ScanResultType.HuggingFace]: { defaultMessage: 'Hugging Face' },
  });
