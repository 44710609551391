import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FindingSource } from '@endorlabs/endor-core/Finding';
import { BetaBadge, IconChevronDown } from '@endorlabs/ui-common';

export type FindingSourceSelectOption = {
  value: FindingSource;
  label: ReactNode;
  isBeta?: boolean;
};

export type FindingSourceSelectProps = {
  options?: FindingSourceSelectOption[];
  onChange?: (value: FindingSource) => void;
  value?: FindingSource;
};

export const buildFindingSourceSelectOptions = ({
  excludeList = [],
}: {
  excludeList?: FindingSource[];
}) => {
  const options: FindingSourceSelectOption[] = [
    {
      value: FindingSource.All,
      label: <FM defaultMessage="All Findings" />,
    },
    {
      value: FindingSource.Dependency,
      label: <FM defaultMessage="Dependency Findings" />,
    },
    {
      value: FindingSource.Package,
      label: <FM defaultMessage="Package Findings" />,
    },
    {
      value: FindingSource.Repository,
      label: <FM defaultMessage="Repository Findings" />,
    },
    {
      value: FindingSource.Secrets,
      label: <FM defaultMessage="Secrets Findings" />,
    },
    {
      value: FindingSource.GithubAction,
      label: <FM defaultMessage="CI Workflows Findings" />,
      isBeta: true,
    },
  ];

  return options.filter((option) => !excludeList.includes(option.value));
};

const DEFAULT_FINDING_SOURCE_SELECT_OPTIONS = buildFindingSourceSelectOptions(
  {}
);

export function FindingSourceSelect({
  options = DEFAULT_FINDING_SOURCE_SELECT_OPTIONS,
  onChange,
  value,
}: FindingSourceSelectProps) {
  const theme = useTheme();
  const sx = styles(theme);

  return (
    <Select
      onChange={(evt: SelectChangeEvent<FindingSource>) =>
        onChange && onChange(evt.target.value as FindingSource)
      }
      value={value}
      sx={sx.root}
      variant="standard"
      IconComponent={IconChevronDown}
    >
      {options.map(({ isBeta, label: optionLabel, value: optionValue }) => {
        if (isBeta) {
          return (
            <MenuItem key={optionValue} sx={sx.menuItem} value={optionValue}>
              <BetaBadge>{optionLabel}</BetaBadge>
            </MenuItem>
          );
        }
        return (
          <MenuItem key={optionValue} value={optionValue}>
            {optionLabel}
          </MenuItem>
        );
      })}
    </Select>
  );
}

function styles({ palette, spacing, typography }: Theme) {
  return {
    root: {
      '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
        fontWeight: typography.fontWeightMedium,
        paddingLeft: 0,
        paddingY: 0,
        overflow: 'visible',
      },

      '&.Mui-focused .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
        backgroundColor: palette.background.paper,
      },
    },

    menuItem: {
      '& .MuiBadge-badge': {
        position: 'relative',
        right: spacing(5),
      },
    },
  };
}
