import { memoize as _memoize, set as _set } from 'lodash-es';

import {
  ResourceGroupResponseGroupData,
  tryParseGroupResponseAggregationKey,
} from '@endorlabs/queries';

export type AggregatedRecords = Record<
  string,
  Record<string, Record<string, number>>
>;

export const getAggregatedRecordsFromGroupResponse = _memoize(
  (
    data: Record<string, ResourceGroupResponseGroupData>,
    options: { primaryKey: string }
  ): AggregatedRecords => {
    const result: AggregatedRecords = {};

    for (const [aggregationKey, group] of Object.entries(data)) {
      const values = tryParseGroupResponseAggregationKey(aggregationKey);

      const primaryKey = values.find((kv) => kv.key === options.primaryKey)
        ?.value as string;

      // Skip entries with an empty or "falsy" value for the primary key
      if (!primaryKey) continue;

      const attributes = (result[primaryKey] = result[primaryKey] ?? {});
      for (const kv of values) {
        if (kv.key === options.primaryKey) {
          continue;
        }

        const count = group.aggregation_count?.count ?? 1;

        if (Array.isArray(kv.value)) {
          for (const value of kv.value) {
            const k = String(value);
            _set(attributes, [kv.key, k], count);
          }
        } else {
          const k = String(kv.value);
          _set(attributes, [kv.key, k], count);
        }
      }
    }

    return result;
  }
);
