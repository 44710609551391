import { Box, SvgIconProps, Theme, useTheme } from '@mui/material';

import { V1Ecosystem } from '@endorlabs/api_client';

import { EcosystemIcons } from '../../constants';
import { IconConstellation, IconPlanet } from '../../themes';

const getIconSize = (
  theme: Theme,
  size: 'inherit' | 'small' | 'medium' | 'large'
) => {
  const sizes: Record<typeof size, string> = {
    inherit: '1rem',
    small: theme.typography.pxToRem(20),
    medium: theme.typography.pxToRem(24),
    large: theme.typography.pxToRem(35),
  };

  return sizes[size];
};

const getPaddingBySize = (
  theme: Theme,
  size: 'inherit' | 'small' | 'medium' | 'large'
) => {
  const paddings: Record<typeof size, string> = {
    inherit: theme.typography.pxToRem(2),
    small: theme.typography.pxToRem(4),
    medium: theme.typography.pxToRem(8),
    large: theme.typography.pxToRem(8),
  };
  return paddings[size];
};

const getBorderRadiusBySize = (
  theme: Theme,
  size: 'inherit' | 'small' | 'medium' | 'large'
) => {
  const radius: Record<typeof size, string> = {
    inherit: theme.typography.pxToRem(4),
    small: theme.typography.pxToRem(4),
    medium: theme.typography.pxToRem(8),
    large: theme.typography.pxToRem(8),
  };
  return radius[size];
};

export interface PackageIconDisplayProps extends SvgIconProps {
  ecosystem: V1Ecosystem;
  displayDefault: 'package' | 'dependency';
  size?: 'inherit' | 'small' | 'medium' | 'large';
  withBorder?: boolean;
}

export const PackageIconDisplay = ({
  ecosystem,
  displayDefault,
  size = 'small',
  withBorder = false,
}: PackageIconDisplayProps) => {
  const theme = useTheme();
  const iconSize = getIconSize(theme, size);

  const ecosystemIcon = EcosystemIcons[ecosystem] ? (
    <img
      src={EcosystemIcons[ecosystem]}
      style={{ height: iconSize, width: 'auto' }}
      alt=""
      role="presentation"
    />
  ) : displayDefault === 'package' ? (
    <IconPlanet fontSize={size} />
  ) : (
    <IconConstellation fontSize={size} />
  );

  if (withBorder) {
    return (
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          padding: getPaddingBySize(theme, size),
          border: `1px solid ${theme.palette.text.light}`,
          borderRadius: getBorderRadiusBySize(theme, size),
        }}
      >
        {ecosystemIcon}
      </Box>
    );
  }

  return ecosystemIcon;
};
