import { ChartMargin, LegendPosition } from './types';

export const getChartContainerDirection = (legendPosition: LegendPosition) => {
  switch (legendPosition) {
    case 'top':
      return 'column-reverse';
    case 'bottom':
      return 'column';
    case 'left':
      return 'row-reverse';
    case 'right':
      return 'row';
    case 'center':
      return 'row';
    default:
      return 'row';
  }
};

export const getChartDimensions = (
  width: number,
  height: number,
  margin: ChartMargin,
  showLegends: boolean,
  legendPosition: LegendPosition,
  legendContainerSize: number
) => {
  const chartWidth =
    showLegends && ['left', 'right'].includes(legendPosition)
      ? width -
        margin.left -
        margin.right -
        (width - margin.left - margin.right) * legendContainerSize
      : width - margin.left - margin.right;
  const chartHeight =
    showLegends && ['top', 'bottom'].includes(legendPosition)
      ? height -
        margin.top -
        margin.bottom -
        (height - margin.top - margin.bottom) * legendContainerSize
      : height - margin.top - margin.bottom;

  const legendContainerWidth = showLegends
    ? ['left', 'right'].includes(legendPosition)
      ? width * legendContainerSize
      : chartWidth
    : 0;
  const legendContainerHeight = showLegends
    ? ['top', 'bottom'].includes(legendPosition)
      ? height * legendContainerSize
      : chartHeight
    : 0;

  const svgWidth = ['top', 'bottom', 'center'].includes(legendPosition)
    ? width
    : width - legendContainerWidth;

  const svgHeight = ['left', 'right', 'center'].includes(legendPosition)
    ? height
    : height - legendContainerHeight;

  return [
    svgWidth,
    svgHeight,
    chartWidth,
    chartHeight,
    legendContainerWidth,
    legendContainerHeight,
  ];
};
