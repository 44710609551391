import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { PackageVersionResolutionErrors as ResolutionErrors } from '@endorlabs/api_client';

import { StatusIndicator } from '../../../components/StatusIndicator';
import { Status } from '../../../constants';

export const ResolutionErrorStatusIndicator = ({
  errors,
  onClick,
}: {
  errors?: ResolutionErrors;
  onClick?: (event: React.MouseEvent, errors?: ResolutionErrors) => void;
}) => {
  const { formatMessage: fm } = useIntl();

  // check if there were resolution errors
  const resolutionErrorCount = [
    errors?.unresolved?.status_error,
    errors?.resolved?.status_error,
    errors?.call_graph?.status_error,
  ].filter(Boolean).length;

  const title = fm({ defaultMessage: 'Package Resolution Errors' });
  const details = fm(
    {
      defaultMessage:
        'There are resolution errors for this Package Version. Click for more details.',
    },
    {
      count: resolutionErrorCount,
    }
  );

  if (resolutionErrorCount === 0) {
    return <StatusIndicator status={Status.Success} />;
  }

  const tooltipNode = (
    <>
      <Typography variant="h4" marginBottom={1}>
        {title}
      </Typography>
      <Typography variant="caption">{details}</Typography>
    </>
  );

  const handleClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event, errors);
    }
  };

  return (
    <StatusIndicator
      status={Status.Failure}
      tooltipNode={tooltipNode}
      onClick={handleClick}
    />
  );
};
