import { getEnv } from '../../../constants';
import { BasicTourStepProps } from '../types';

const ENV = getEnv();

const selForm = '#FormUpsertPolicy';
const selNav = '[data-testid="AppDrawerNavItem-policies"]';

export const getCreatePolicyStep = ({ tourData }: BasicTourStepProps) => {
  return {
    attachTo: {
      element: selForm,
      on: 'right-start',
    },
    title: 'Ship Fast and Secure',
    description:
      'Use fine-grained policies that only disrupt the SDLC when absolutely necessary.',
    highlightSelectors: [selForm, selNav],
    stepUrl: `/t/${tourData.tourTenant}/policies/actions/edit/${tourData.actionPolicyUuid}`,
    urlLearn: `${ENV.URL_ENDOR_DOCS}/managing-policies/`,
  };
};
