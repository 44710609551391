import { Box, IconButton, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Filter } from '@endorlabs/filters';
import { IconX, RowStack, SearchBarInput } from '@endorlabs/ui-common';

import { FilterFieldConfig } from '../types';
import { buildFilterFieldElements, getUpdatedFilterMap } from '../utils';

export interface FilterBarBasicProps {
  fields: FilterFieldConfig[];
  filterValues?: Map<string, Filter>;
  onClear: () => void;
  onFilterChange: (values: Map<string, Filter>) => void;
  onSearchChange: (searchValue: string) => void;
  searchPlaceholder?: string;
  searchValue?: string;
}

export const FilterBarBasic = ({
  fields,
  filterValues,
  onClear,
  onFilterChange,
  onSearchChange,
  searchPlaceholder,
  searchValue,
}: FilterBarBasicProps) => {
  const { formatMessage: fm } = useIntl();
  const { space } = useTheme();

  // FIXME: Need to ignore default filters
  const showClear = filterValues?.size || searchValue;

  const handleChange = useCallback(
    (fields: FilterFieldConfig, value: unknown) => {
      const next = getUpdatedFilterMap({
        fields,
        filterValues: filterValues,
        value,
      });

      onFilterChange(next);
    },

    [filterValues, onFilterChange]
  );

  const filterFieldElements = buildFilterFieldElements({
    fields,
    filterValues,
    handleChange,
  });

  return (
    <RowStack
      className="FilterBarBasic-root"
      columnGap={space.xs}
      flexGrow={1}
      flexWrap="wrap"
      rowGap={space.md}
    >
      <Box flexGrow={1} order={0}>
        <SearchBarInput
          onSearch={onSearchChange}
          placeholder={searchPlaceholder ?? fm({ defaultMessage: 'Search' })}
          searchValue={searchValue}
          size="medium"
        />
      </Box>

      <RowStack
        alignItems="center"
        columnGap={space.xs}
        flexWrap="wrap"
        order={{ xs: 2, lg: 1 }}
        rowGap={1}
        width={{
          xs: '100%',
          lg: 'auto',
        }}
      >
        {filterFieldElements}
      </RowStack>

      {showClear && (
        <Box order={{ xs: 1, lg: 2 }}>
          <IconButton
            onClick={onClear}
            size="small"
            title={fm({ defaultMessage: 'Clear Filters' })}
          >
            <IconX fontSize="inherit" />
          </IconButton>
        </Box>
      )}
    </RowStack>
  );
};
