import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Theme,
} from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { GraphNode } from '@endorlabs/utils/graph';

import { GraphDataProvider } from '../../../../domains/graph';
import { useStyles } from '../../../../hooks';
import { ChecklistItem } from './ChecklistItem';
import { ChecklistInputGroupFn, ChecklistInputOption } from './types';
import { buildChecklistGraphData } from './utils';

export interface ChecklistInputProps {
  groupBy?: ChecklistInputGroupFn;
  onChange: (value: ChecklistInputOption[]) => void;
  options: ChecklistInputOption[];
  value: ChecklistInputOption[];
}

export const ChecklistInput = ({
  groupBy,
  value,
  options,
  onChange,
}: ChecklistInputProps) => {
  const state = useMemo(() => {
    const checked = new Set<string>();

    for (const v of value) {
      checked.add(v.key);
    }

    return { checked };
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked: isChecked, name: key } = event.target;
    const next = new Set(state.checked);

    if (isChecked) {
      next.add(key);
    } else {
      next.delete(key);
    }

    // When grouped, propagate change to immediate children
    if (groupBy) {
      const children = options.filter((o) => groupBy(o) === key);
      for (const c of children) {
        if (isChecked) {
          next.add(c.key);
        } else {
          next.delete(c.key);
        }
      }
    }

    onChange(options.filter((o) => next.has(o.key)));
  };

  const handleToggleAll = (_: SyntheticEvent, isChecked: boolean) => {
    const next = isChecked ? options : [];
    onChange(next);
  };

  const hasAllChecked = options.every((o) => state.checked.has(o.key));
  const hasSomeChecked = options.some((o) => state.checked.has(o.key));

  const graphData = useMemo(() => {
    const { graph, nodes, rootNodes } = buildChecklistGraphData({
      options,
      groupBy,
    });

    // HACK: type assertion for graph provider
    return { graph, nodes: nodes as GraphNode[], rootNodes };
  }, [groupBy, options]);

  const checklistStyles = useStyles(styles);

  return (
    <Box className="ChecklistInput-root" sx={checklistStyles}>
      <Box className="ChecklistInput-header">
        <FormControlLabel
          label={
            hasAllChecked ? (
              <FM defaultMessage="Deselect All" />
            ) : (
              <FM defaultMessage="Select All" />
            )
          }
          onChange={handleToggleAll}
          control={
            <Checkbox
              checked={hasAllChecked}
              indeterminate={hasSomeChecked && !hasAllChecked}
            />
          }
        />
      </Box>

      <Divider className="ChecklistInput-divider" />

      <GraphDataProvider {...graphData}>
        <Box className="ChecklistInput-container">
          <FormGroup
            className="ChecklistInput-formGroup"
            onChange={handleChange}
          >
            {graphData.rootNodes.map((node) => (
              <ChecklistItem
                isGrouped={!!groupBy}
                key={node.id}
                nodeId={node.id}
                state={state}
              />
            ))}
          </FormGroup>
        </Box>
      </GraphDataProvider>
    </Box>
  );
};

function styles(theme: Theme) {
  const { palette, shape, spacing } = theme;

  return {
    '&.ChecklistInput-root': {
      border: `1px solid ${palette.divider}`,
      borderRadius: `${shape.borderRadius}px`,
    },
    '& .ChecklistInput-header': {
      padding: spacing(2, 4),
    },
    '& .ChecklistInput-container': {
      height: 400,
      maxHeight: '50vh',
      overflow: 'auto',
      padding: spacing(2, 4),
    },
    '& .ChecklistInput-formGroup': {
      gap: 2,
    },
    '& .ChecklistItem-collapseToggle': {
      width: 20,
      height: 20,
      marginLeft: -0.5,
    },
  };
}
