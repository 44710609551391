import { minutesToMilliseconds } from 'date-fns';
import { useMemo } from 'react';

import {
  DependencyMetadataDiscoveryType,
  DependencyMetadataReachabilityType,
  GroupResponseGroupData,
  V1Ecosystem,
  V1GroupResponse,
} from '@endorlabs/api_client';
import { FilterExpression } from '@endorlabs/filters';
import { useGroupDependencyMetadata } from '@endorlabs/queries';

import { UIPackageVersionUtils } from '../../PackageVersion';

export type DependencyInfo = {
  uuid?: string;
  namespace?: string;
  name: string;
  hasApproximation?: boolean;
  hasPhantomDependency?: boolean;
  isDirectDependency?: boolean;
  isPublic?: boolean;
  isUtilized: boolean;
  dependentsCount: number;
  reachability?: DependencyMetadataReachabilityType;
  packageName?: string;
  parentVersionName?: string;
  versionRef?: string;
  sourceCode?: string;
  unresolvedVersionRefs?: string[];
  ecosystem: V1Ecosystem;
};

const getAllGroupDataUniqueValues = <T = unknown>(
  group: GroupResponseGroupData,
  key: string
): T[] => {
  return (
    (group.unique_values?.[key] as unknown as T[] | undefined)?.filter(
      Boolean
    ) ?? []
  );
};

const getFirstGroupDataUniqueValue = <T = unknown>(
  group: GroupResponseGroupData,
  key: string
): T | undefined => {
  return group.unique_values?.[key]?.[0] as unknown as T;
};

const getFirstMatchingGroupDataUniqueValue = <T = unknown>(
  group: GroupResponseGroupData,
  key: string,
  values: T[]
): T | undefined => {
  const uniqueValues = (group.unique_values?.[key] ?? []) as unknown as T[];
  return values.find((value) => uniqueValues.includes(value));
};

const hasSomeGroupDataUniqueValue = <T = unknown>(
  group: GroupResponseGroupData,
  key: string,
  predicate: (value: T) => boolean
): boolean => {
  return group.unique_values?.[key]?.some(predicate as any) as boolean;
};

// Order used to determine the reachability type for grouped dependencies
const REACHABILITY_TYPE_ORDER = [
  DependencyMetadataReachabilityType.Unknown,
  DependencyMetadataReachabilityType.Unspecified,
  DependencyMetadataReachabilityType.Reachable,
  DependencyMetadataReachabilityType.Unreachable,
];

const buildPackageVersionDependencyMetadataMap = (
  groupResponse?: V1GroupResponse
) => {
  const dependencyMetadataMap: Record<string, DependencyInfo> = {};
  if (!groupResponse?.groups) return dependencyMetadataMap;

  for (const group of Object.values(groupResponse.groups)) {
    const dependentsCount = group.aggregation_count?.count ?? 0;
    const name = getFirstGroupDataUniqueValue<string>(group, 'meta.name');
    const uuid = getFirstGroupDataUniqueValue<string>(
      group,
      'spec.dependency_data.package_version_uuid'
    );
    const namespace = getFirstGroupDataUniqueValue<string>(
      group,
      'spec.dependency_data.namespace'
    );
    const packageName = getFirstGroupDataUniqueValue<string>(
      group,
      'spec.dependency_data.package_name'
    );
    const versionRef = getFirstGroupDataUniqueValue<string>(
      group,
      'spec.dependency_data.resolved_version'
    );
    const hasApproximation = hasSomeGroupDataUniqueValue(
      group,
      'spec.dependency_data.approximation',
      (isApproximate: boolean) => isApproximate === true
    );
    const isDirectDependency = hasSomeGroupDataUniqueValue(
      group,
      'spec.dependency_data.direct',
      (isDirect: boolean) => isDirect === true
    );
    const isPublic = hasSomeGroupDataUniqueValue(
      group,
      'spec.dependency_data.public',
      (isPublic: boolean) => isPublic === true
    );
    const isUtilized = hasSomeGroupDataUniqueValue(
      group,
      'spec.dependency_data.utilization',
      (utilization: unknown) =>
        'number' === typeof utilization && utilization > 0
    );
    const reachability = getFirstMatchingGroupDataUniqueValue(
      group,
      'spec.dependency_data.reachable',
      REACHABILITY_TYPE_ORDER
    );
    const sourceCode = getFirstGroupDataUniqueValue<string>(
      group,
      'spec.dependency_data.repo_name'
    );
    const unresolvedVersionRefs = getAllGroupDataUniqueValues<string>(
      group,
      'spec.dependency_data.unresolved_version'
    );
    const hasPhantomDependency = hasSomeGroupDataUniqueValue(
      group,
      'spec.dependency_data.discovery_type',
      (discoveryType: DependencyMetadataDiscoveryType) =>
        discoveryType === DependencyMetadataDiscoveryType.Phantom
    );
    const parentVersionName = getFirstGroupDataUniqueValue<string>(
      group,
      'spec.importer_data.package_version_name'
    );

    if (name) {
      const { ecosystem } = UIPackageVersionUtils.parsePackageName(name);

      dependencyMetadataMap[name] = {
        name,
        uuid,
        namespace,
        hasApproximation,
        hasPhantomDependency,
        isDirectDependency,
        isPublic,
        isUtilized,
        dependentsCount,
        reachability,
        packageName,
        parentVersionName,
        versionRef,
        sourceCode,
        unresolvedVersionRefs,
        ecosystem,
      };
    }
  }

  return dependencyMetadataMap;
};

/**
 * From a given list of Package Versions, build a map of dependencies for the
 * Package Versions, keyed by the dependency name.
 */
export const useDependenciesFromDependencyMetadata = (
  namespace: string,
  filterExpression: FilterExpression,
  options: { enabled?: boolean } = { enabled: true }
) => {
  const qGroupedDependencyMetadata = useGroupDependencyMetadata(
    namespace,
    {
      enabled: !!namespace && !!filterExpression && options.enabled,
      staleTime: minutesToMilliseconds(5),
    },
    {
      filter: filterExpression,
      group: {
        aggregation_paths: 'meta.name',
        unique_value_paths: [
          'meta.name',
          'spec.dependency_data.approximation',
          'spec.dependency_data.direct',
          'spec.dependency_data.discovery_type',
          'spec.dependency_data.namespace',
          'spec.dependency_data.package_name',
          'spec.dependency_data.package_version_uuid',
          'spec.dependency_data.public',
          'spec.dependency_data.reachable',
          'spec.dependency_data.resolved_version',
          'spec.dependency_data.unresolved_version',
          'spec.dependency_data.utilization',
          'spec.dependency_data.repo_name',
          'spec.importer_data.package_version_name',
        ].join(','),
      },
    }
  );

  const dependenciesMap = useMemo(() => {
    return buildPackageVersionDependencyMetadataMap(
      qGroupedDependencyMetadata.data
    );
  }, [qGroupedDependencyMetadata.data]);

  return {
    dependenciesMap,
    isLoading: qGroupedDependencyMetadata.isLoading,
    isSuccess: qGroupedDependencyMetadata.isSuccess,
  };
};
