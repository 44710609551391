import { isAfter } from 'date-fns';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { useFeatureFlags } from '@endorlabs/queries';

import { useAuthInfo } from './useAuthInfo';
import { useAuthTenantInfo } from './useAuthTenantInfo';

export const useLicensingInfo = () => {
  const { ENABLE_LICENSE_AND_BUNDLING: isLicensingAndBundling } =
    useFeatureFlags();
  const { isPremiumTenant } = useAuthTenantInfo();
  const { licenseInfo } = useAuthInfo();

  const now = useMemo(() => Date.now(), []);

  //Function returns if license is expired or not
  const licenseExpired = useCallback(
    (expiryTime: string) => {
      const licenseExpiry = new Date(expiryTime);
      if (isAfter(now, licenseExpiry)) return true;
      return false;
    },
    [now]
  );

  const checkLicensePresent = useCallback(
    (licenseTypeRequired: SpecEndorLicenseFeatureType | undefined): boolean => {
      if (
        !isLicensingAndBundling ||
        _isEmpty(licenseInfo) ||
        !isPremiumTenant ||
        !licenseTypeRequired
      )
        return true;

      const licenseFound = licenseInfo?.find(
        (l) => l.type === licenseTypeRequired
      );
      if (!licenseFound || !licenseFound.expiration_time) return false;

      return !licenseExpired(licenseFound?.expiration_time);
    },
    [isLicensingAndBundling, licenseInfo, isPremiumTenant, licenseExpired]
  );

  /* Check if there are additional licenses than the license passed.
   * Function used to enable /disable componenets based on additional licenses present or not.
   */
  const hasAdditionalLicenses = (
    currentLicense: SpecEndorLicenseFeatureType
  ) => {
    if (!isLicensingAndBundling || _isEmpty(licenseInfo)) return true;
    return licenseInfo?.some((license) => license.type !== currentLicense);
  };

  return {
    checkLicensePresent,
    hasAdditionalLicenses,
  };
};
