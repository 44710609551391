import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';
import {
  AttributeDisplay,
  AttributeDisplayRecord,
  AttributeDisplayStack,
  HelpIconButton,
  NumberDisplay,
  RowStack,
} from '@endorlabs/ui-common';

import { getBreakingChangeCountByConfidence } from '../../utils';
import { BreakingConfidenceTooltip } from '../BreakingConfidenceTooltip';

const BreakingChangesByConfidenceRecords: AttributeDisplayRecord[] = [
  {
    attributeKey: 'high',
    disableTypography: true,
    heading: <FM defaultMessage="High Confidence" />,
    headingTypographyProps: { textAlign: 'center' },
  },
  {
    attributeKey: 'medium',
    disableTypography: true,
    heading: <FM defaultMessage="Medium Confidence" />,
    headingTypographyProps: { textAlign: 'center' },
  },
  {
    attributeKey: 'low',
    disableTypography: true,
    heading: <FM defaultMessage="Low Confidence" />,
    headingTypographyProps: { textAlign: 'center' },
  },
];

export const BreakingChangesOverview = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  const { palette, space } = useTheme();

  const breakingChangeCount = upgradeInfo?.cia_results?.length ?? 0;

  const breakingCountByConfidence =
    getBreakingChangeCountByConfidence(upgradeInfo);

  return (
    <Stack divider={<Divider flexItem />} spacing={space.sm}>
      <AttributeDisplay
        containerProps={{ justifyContent: 'center' }}
        disableTypography
        heading={<FM defaultMessage="Breaking Changes" />}
        headingTypographyProps={{ textAlign: 'center' }}
        value={
          <NumberDisplay
            value={breakingChangeCount}
            typographyProps={{
              textAlign: 'center',
              variant: 'h3',
            }}
          />
        }
      />

      <Stack alignItems="center" gap={space.sm}>
        <RowStack alignItems="center">
          <Typography variant="overline">
            <FM defaultMessage="Breaking Change Confidence" />
          </Typography>
          <HelpIconButton tooltip={<BreakingConfidenceTooltip />} />
        </RowStack>

        <AttributeDisplayStack
          attributeRecords={BreakingChangesByConfidenceRecords}
          containerProps={{
            justifyContent: 'center',
            divider: <Divider flexItem orientation="vertical" />,
          }}
          resource={{
            high: (
              <NumberDisplay
                value={breakingCountByConfidence.CONFIDENCE_HIGH}
                typographyProps={{
                  align: 'center',
                  color: palette.severity.critical,
                  fontWeight: 'bold',
                  variant: 'h3',
                }}
              />
            ),
            medium: (
              <NumberDisplay
                value={breakingCountByConfidence.CONFIDENCE_MEDIUM}
                typographyProps={{
                  align: 'center',
                  color: palette.severity.high,
                  fontWeight: 'bold',
                  variant: 'h3',
                }}
              />
            ),
            low: (
              <NumberDisplay
                value={breakingCountByConfidence.CONFIDENCE_LOW}
                typographyProps={{
                  align: 'center',
                  color: palette.severity.medium,
                  fontWeight: 'bold',
                  variant: 'h3',
                }}
              />
            ),
          }}
          variant="row"
        />
      </Stack>
    </Stack>
  );
};
