import { Stack, Tooltip, Typography } from '@mui/material';

import { V1ArtifactType } from '@endorlabs/api_client';

import { ArtifactIconDisplay } from '../ArtifactIconDisplay';

interface ArtifactDisplayProps {
  name: string;
  type?: V1ArtifactType;
  fullName: string;
}

/**
 * Display for a Artifact Name
 */
export const ArtifactNameDisplay = ({
  name,
  type,
  fullName,
}: ArtifactDisplayProps) => {
  const spacing = 2;

  return (
    <Stack direction="row" spacing={spacing} alignItems="center">
      <ArtifactIconDisplay type={type} />
      <Tooltip title={fullName}>
        <Typography component="span" variant="inherit">
          {name}
        </Typography>
      </Tooltip>
    </Stack>
  );
};
