import { FormattedMessage as FM } from 'react-intl';

import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';
import {
  AttributeDisplayRecord,
  AttributeDisplayStack,
  PackageVersionNameDisplay,
  ProjectNameDisplay,
} from '@endorlabs/ui-common';

const RemediationDetailsRecords: AttributeDisplayRecord[] = [
  {
    attributeKey: 'projectName',
    heading: <FM defaultMessage="Project Name" />,
    variant: 'column',
  },
  {
    attributeKey: 'packageName',
    heading: <FM defaultMessage="Package Name" />,
    variant: 'column',
  },
];

export const RemediationDetails = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  return (
    <AttributeDisplayStack
      attributeRecords={RemediationDetailsRecords}
      headingWidth="16ch"
      resource={{
        projectName: <ProjectNameDisplay name={upgradeInfo?.project} />,
        packageName: (
          <PackageVersionNameDisplay
            packageVersion={{
              meta: { name: upgradeInfo?.root_package_version ?? '' },
            }}
          />
        ),
      }}
      variant="column"
    />
  );
};
