import { Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { useCallback } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1PlatformSource, V1ScanResult } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  ProjectNameDisplay,
  ScanResultStatusIndicator,
} from '@endorlabs/ui-common';

import { getProjectPath } from '../../routes';

export interface InstallationProjectsTableRow {
  uuid: string;
  scanTime?: string;
  analyticTime?: string;
  name?: string;
  namespace?: string;
  platformSource?: V1PlatformSource;
  webUrl?: string;
  scanResults?: V1ScanResult[];
}

const buildInstallationProjectsTableColDefs = () => {
  const columns: DataTableColumnDef<InstallationProjectsTableRow>[] = [
    {
      colType: ColTypes.STATUS_INDICATOR,
      accessorKey: 'scanResults',
      cell: ({ getValue }) => (
        <ScanResultStatusIndicator scanResults={getValue()} />
      ),
    },
    {
      id: 'link',
      accessorFn: (row) => row,
      cell: ({ row: { original } }) => {
        if (!original?.name) {
          return (
            <Typography color="text.secondary">
              <FM defaultMessage="Unknown Project" />
            </Typography>
          );
        }

        return (
          <ProjectNameDisplay
            name={original.name}
            platformSource={original.platformSource}
            showIcon={false}
          />
        );
      },
      header: () => <FM defaultMessage="Project" />,
    },
    {
      accessorKey: 'scanTime',
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Last Project Scan" />,
    },
    {
      accessorKey: 'analyticTime',
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Last Analytics Scan" />,
    },
    {
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
    },
  ];

  return columns;
};

export type InstallationProjectsTableProps = Omit<
  DataTableProps<InstallationProjectsTableRow>,
  'columns'
>;

/**
 * Displays a collection of projects from a given installation
 */
export const InstallationProjectsTable = ({
  data = [],
  ...rest
}: InstallationProjectsTableProps) => {
  const navigate = useNavigate();
  const handleRowClick = useCallback(
    (row: InstallationProjectsTableRow) => {
      if (row.uuid && row.namespace) {
        navigate({
          to: getProjectPath({
            tenantName: row.namespace,
            uuid: row.uuid,
          }),
        });
      }
    },
    [navigate]
  );

  return (
    <DataTable
      columns={buildInstallationProjectsTableColDefs()}
      data={data}
      onRowClick={handleRowClick}
      {...rest}
    />
  );
};
