import { Stack, useTheme } from '@mui/material';
import { ReactNode, useRef, useState } from 'react';

import {
  DataTableViewProps,
  DrawerAccordion,
  DrawerAccordionProps,
} from '@endorlabs/ui-common';

import { RemediationsTableRow } from './RemediationsTable';
import {
  RemediationsTableView,
  RemediationsTableViewRef,
} from './RemediationsTableView';

interface RemediationsGroupAccordionProps extends DrawerAccordionProps {
  actionList?: DataTableViewProps<RemediationsTableRow>['bulkActions'];
  dependencyPackageVersionName: string;
  hasPatch?: boolean;
  isLoading: boolean;
  namespace: string;
  remediationUuids?: string[];
  titleNode: ReactNode;
}

export const RemediationsGroupAccordion = ({
  actionList = [],
  dependencyPackageVersionName,
  isLoading,
  namespace,
  remediationUuids = [],
  titleNode,
}: RemediationsGroupAccordionProps) => {
  const { space, spacing } = useTheme();
  const tableRef = useRef<RemediationsTableViewRef>(null);

  const [isOpen, setIsOpen] = useState<boolean | null>(null);

  const filter = remediationUuids.length ? `uuid in [${remediationUuids}]` : '';

  return (
    <DrawerAccordion
      align="center"
      expanded={!!isOpen}
      id={`remediations-${dependencyPackageVersionName}`}
      onChange={(_, value) => setIsOpen(value)}
      titleNode={titleNode}
    >
      <Stack rowGap={space.sm} paddingLeft={spacing(4)}>
        {isOpen !== null && (
          // HACK: delay initial render and data load until the accordion is first opened
          <RemediationsTableView
            actions={actionList}
            baseFilterExpression={filter}
            enableFilters={false}
            enableEndorPatchFallback
            enablePagination={false}
            excludeColumns={[
              'dependencyName',
              'packageVersionName',
              'projectName',
              'versionAge',
              'lastScanned',
            ]}
            isLoading={isLoading}
            ref={tableRef}
            tenantName={namespace}
          />
        )}
      </Stack>
    </DrawerAccordion>
  );
};
