import { Stack, Typography, useTheme } from '@mui/material';
import { Suspense, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1ServiceRequest } from '@endorlabs/api_client';
import { EmptyState } from '@endorlabs/ui-common';
import { CodeEditor } from '@endorlabs/ui-common/domains/code';

export const ServiceRequestDataField = () => {
  const { space } = useTheme();

  const { formatMessage: fm } = useIntl();

  const { clearErrors, control, setError } = useFormContext<V1ServiceRequest>();

  const { field, fieldState } = useController({
    control,
    name: 'spec.data',
    rules: {
      required: {
        value: true,
        message: fm({ defaultMessage: 'Data is required' }),
      },
    },
  });

  const serviceRequestDataString = JSON.stringify(field.value, null, 2) ?? '';
  const serviceRequestDataError = fieldState.error?.message;

  const resetError = useCallback(() => {
    clearErrors('spec.data');
  }, [clearErrors]);

  const updateHiddenInput = useCallback(
    (codeEditorValue?: string) => {
      try {
        if (codeEditorValue) {
          const parsedValue = JSON.parse(codeEditorValue ?? '{}');
          field.onChange(parsedValue);
          field.onBlur();
          resetError();
        }
      } catch (e: any) {
        setError('spec.data', {
          type: 'validate',
          message: `Invalid JSON: ${e?.message}`,
        });
      }
    },
    [field, resetError, setError]
  );

  return (
    <Stack spacing={space.xs}>
      <Suspense
        fallback={
          <EmptyState
            size="medium"
            title={<FM defaultMessage="Loading Editor …" />}
          />
        }
      >
        <CodeEditor
          width="100%"
          language="application/json"
          onChange={updateHiddenInput}
          title={<FM defaultMessage="Resource Data" />}
          value={serviceRequestDataString}
        />
      </Suspense>
      {serviceRequestDataError && (
        <Typography color="error" variant="caption">
          {serviceRequestDataError}
        </Typography>
      )}
    </Stack>
  );
};
