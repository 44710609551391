import {
  Card,
  CardActions,
  CardContent,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { ComponentProps } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  ButtonLinkSecondary,
  PackageNameDisplay,
  RowStack,
} from '@endorlabs/ui-common';

import { DesignSystemColors } from '../../../../../../ui-common/src/themes/endor/DesignSystemColors';
import { RemediationRecommendationType } from '../../constants';
import { RemediationRisk } from '../../types';
import { RemediationRiskDisplay } from '../RemediationRiskDisplay';
import { VersionUpgradeDisplay } from '../VersionUpgradeDisplay';

const { Severity } = DesignSystemColors;

export type RemediationRecommendationDisplayProps = {
  actions?: React.ReactNode;
  display?: 'card' | 'inline';
  fromVersion?: string;
  packageName?: string;
  recommendation?: RemediationRecommendationType;
  toVersion?: string;
  remediationRisk?: string;
  // align the content size with PackageNameDisplay text size
  size?: ComponentProps<typeof PackageNameDisplay>['size'];
  url?: string;
};

export const RemediationRecommendationDisplay = ({
  actions,
  display = 'card',
  fromVersion,
  packageName,
  recommendation,
  toVersion,
  remediationRisk,
  size = 'small',
  url,
}: RemediationRecommendationDisplayProps) => {
  const theme = useTheme();
  const sx = styles(theme);

  const recommendationContent = (
    <Stack gap={theme.space.xs}>
      <RowStack>
        <Typography fontWeight="bold" fontSize={size}>
          {recommendation}
        </Typography>

        {packageName && (
          <PackageNameDisplay name={packageName} showIcon={false} size={size} />
        )}
      </RowStack>

      <RowStack>
        {fromVersion && toVersion && (
          <VersionUpgradeDisplay
            fromVersion={fromVersion}
            toVersion={toVersion}
          />
        )}

        {remediationRisk && (
          <Typography fontSize={size}>
            <FM
              defaultMessage="with {RemediationRisk} Remediation Risk"
              values={{
                RemediationRisk: (
                  <RemediationRiskDisplay
                    remediationRisk={remediationRisk as RemediationRisk}
                  />
                ),
              }}
            />
          </Typography>
        )}
      </RowStack>

      {url && (
        <ButtonLinkSecondary
          linkProps={{ to: url }}
          sx={{ alignSelf: 'flex-start' }}
        >
          <FM defaultMessage="View Details" />
        </ButtonLinkSecondary>
      )}
    </Stack>
  );

  return (
    <>
      {display === 'card' && (
        <Card elevation={0} variant="outlined" sx={sx.container}>
          <CardContent sx={sx.content}>{recommendationContent}</CardContent>
          {actions && <CardActions sx={sx.actions}>{actions}</CardActions>}
        </Card>
      )}
      {display === 'inline' && <RowStack>{recommendationContent}</RowStack>}
    </>
  );
};

function styles({ space, spacing }: Theme) {
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 'fit-content',
      padding: space.sm,
      gap: space.sm,
      borderColor: Severity.safe.lightMode.hsla,
      '& .MuiCardContent-root:last-child': {
        paddingBottom: 0,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      alignContent: 'center',
      gap: spacing(2, 1),
      padding: 0,
    },
    actions: { padding: 0 },
  };
}
