import { useCallback, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { useQueries } from 'react-query';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  countFindingsQueryOptions,
  useUserPreferences,
} from '@endorlabs/queries';

import {
  isPageNavigationGroupItem,
  PageNavigation,
} from '../../components/PageNavigation';
import { PageNavigationItem } from '../../components/PageNavigation/PageNavigationGroup';
import { useFilterContext } from '../../domains/filters';
import { FindingsPageNavigationItems } from '../../domains/Findings';
import { useAuthInfo } from '../../providers';

const CustomItem: PageNavigationItem = {
  isHidden: true,
  label: <FM defaultMessage="Custom" />,
  navKey: 'custom',
  suffix: undefined,
};

export const FindingsPageNavigation = () => {
  const { filter: activeFilterExpression, updateFilter } = useFilterContext();
  const { activeNamespace } = useAuthInfo();

  const shouldTraverse = useUserPreferences(
    (s) => s.auth?.includeChildNamespaces
  );

  // Set filter value on the default item based on current filter state
  // Add the "Custom" item to top of list
  const navigationItems = useMemo((): PageNavigationItem[] => {
    const allItems = [CustomItem].concat(FindingsPageNavigationItems);
    return allItems;
  }, []);

  // When comparing filters, only match against items with values
  const searchableItems = useMemo(() => {
    return navigationItems.reduce((acc: PageNavigationItem[], navItem) => {
      if (navItem.value) {
        acc.push(navItem);
      }

      // NOTE: Only goes a single level down
      if (isPageNavigationGroupItem(navItem)) {
        acc = acc.concat(
          (navItem.groupItems ?? []).filter((item: PageNavigationItem) =>
            Boolean(item.value)
          )
        );
      }
      return acc;
    }, []);
  }, [navigationItems]);

  // Make count calls for each item with a value
  const queryProps = searchableItems.map((item) => {
    const { queryFn, queryKey } = countFindingsQueryOptions(activeNamespace, {
      count: true,
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.mainResourceContext(),
        item.value as string,
      ]),
      traverse: shouldTraverse,
    });

    return {
      queryFn,
      queryKey,
    };
  });

  const qqFindingCounts = useQueries(queryProps);

  // Update items from count results
  searchableItems.forEach((item, index) => {
    item.suffix = qqFindingCounts[index].data?.count;
  });

  // Determine if any nav items match current filter
  const selectedItemKey = useMemo(() => {
    const matchingItem = searchableItems.find((item) => {
      return item.value && activeFilterExpression.includes(item.value);
    });

    return matchingItem?.navKey;
  }, [activeFilterExpression, searchableItems]);

  // If there is a match, mark the "Custom" item as hidden
  navigationItems[0].isHidden = Boolean(selectedItemKey);

  const handleSelection = useCallback(
    (navKey: string) => {
      const selectedItem = searchableItems.find(
        (item) => item.navKey === navKey
      );
      if (!selectedItem) return;

      // @ts-expect-error nav items extend PageNavigationGroupProps
      const selectedFilterValues = selectedItem.filterValues;

      // Set filter based on expression, which is a general override
      if (selectedFilterValues) updateFilter({ values: selectedFilterValues });
    },
    [searchableItems, updateFilter]
  );

  return (
    <PageNavigation
      handleSelection={handleSelection}
      items={navigationItems}
      selectedItemKey={selectedItemKey ?? 'custom'}
    />
  );
};
