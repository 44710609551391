import { Stack, useTheme } from '@mui/material';
import { ReactNode, useRef, useState } from 'react';

import { V1ScanState } from '@endorlabs/api_client';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListAssuredPackageVersions } from '@endorlabs/queries';
import {
  DataTableViewProps,
  DrawerAccordion,
  DrawerAccordionProps,
} from '@endorlabs/ui-common';

import { PatchAvailableCard } from './PatchAvailableCard';
import { RemediationsTableRow } from './RemediationsTable';
import {
  RemediationsTableView,
  RemediationsTableViewRef,
} from './RemediationsTableView';

interface RemediationsGroupAccordionProps extends DrawerAccordionProps {
  actionList?: DataTableViewProps<RemediationsTableRow>['bulkActions'];
  dependencyPackageVersionName: string;
  hasPatch?: boolean;
  isLoading: boolean;
  namespace: string;
  remediationUuids?: string[];
  titleNode: ReactNode;
}

export const RemediationsGroupAccordion = ({
  actionList = [],
  dependencyPackageVersionName,
  hasPatch = false,
  isLoading,
  namespace,
  remediationUuids = [],
  titleNode,
}: RemediationsGroupAccordionProps) => {
  const { space, spacing } = useTheme();
  const tableRef = useRef<RemediationsTableViewRef>(null);

  const [isOpen, setIsOpen] = useState(false);

  const filter = remediationUuids.length ? `uuid in [${remediationUuids}]` : '';

  const qAssuredPackageVersion = useListAssuredPackageVersions(
    namespace,
    {
      filter: filterExpressionBuilders.and([
        `meta.name=="${dependencyPackageVersionName}-endor-latest"`,
        `processing_status.scan_state==${V1ScanState.Idle}`,
      ]),
    },
    { enabled: hasPatch }
  );

  return (
    <DrawerAccordion
      align="start"
      expanded={isOpen}
      id={`remediations-${dependencyPackageVersionName}`}
      onChange={(_, value) => setIsOpen(value)}
      titleNode={titleNode}
    >
      <Stack rowGap={space.sm} paddingLeft={spacing(4)}>
        {hasPatch && (
          <PatchAvailableCard
            assuredPackageVersions={qAssuredPackageVersion.data?.objects}
          />
        )}

        <RemediationsTableView
          actions={actionList}
          baseFilterExpression={filter}
          enableFilters={false}
          enablePagination={false}
          excludeColumns={[
            'dependencyName',
            'packageVersionName',
            'projectName',
            'versionAge',
          ]}
          isLoading={isLoading}
          ref={tableRef}
          tenantName={namespace}
        />
      </Stack>
    </DrawerAccordion>
  );
};
