import { Box, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useStyles } from '../../hooks';
import { RowStack } from '../RowStack';

enum DiffRatio {
  removed = '-',
  Neutral = ' ',
  added = '+',
}

const generateDiffRatio = (
  added: number,
  removed: number,
  scale = 5
): string[] => {
  const total = added + removed;

  const ratio = new Array(scale).fill(DiffRatio.Neutral);

  const ratioAdded = Math.floor((added / total) * scale);
  const ratioRemoved = Math.floor((removed / total) * scale);

  for (let i = 0; i < ratioAdded; i++) {
    ratio[i] = DiffRatio.added;
  }

  for (let i = ratioAdded; i < ratioAdded + ratioRemoved; i++) {
    ratio[i] = DiffRatio.removed;
  }

  return ratio;
};

type DiffStatProps = {
  added: number;
  removed: number;
};

export const DiffStat = ({ added, removed }: DiffStatProps) => {
  const ratio = useMemo(
    () => generateDiffRatio(added, removed),
    [removed, added]
  );

  const sx = useStyles(styles);

  return (
    <RowStack className="DiffStat-root" gap={1} sx={sx}>
      <Typography
        className="DiffStat-count added"
        component="span"
        variant="caption"
      >
        <FM
          defaultMessage="+{value, number, ::compact-short}"
          values={{ value: added }}
        />
      </Typography>
      <Typography
        className="DiffStat-count removed"
        component="span"
        variant="caption"
      >
        <FM
          defaultMessage="-{value, number, ::compact-short}"
          values={{ value: removed }}
        />
      </Typography>

      <RowStack gap="1px" borderRadius="2px" overflow="hidden">
        {ratio.map((value, index) => (
          <Box
            className={clsx('DiffStat-ratio', {
              removed: value === DiffRatio.removed,
              neutral: value === DiffRatio.Neutral,
              added: value === DiffRatio.added,
            })}
            key={index}
            width="8px"
            height="8px"
          />
        ))}
      </RowStack>
    </RowStack>
  );
};

function styles({ palette }: Theme) {
  // Colors copied from the design palette
  const colors = {
    // DesignColors: Severity High Hover
    removed: 'hsl(355, 71%, 45%)',
    // DesignColors: Grays light_gray_150
    neutral: 'hsl(0, 0%, 94%)',
    // DesignColors: Severity safe_hover
    added: 'hsl(85, 56%, 26%)',
  };

  return {
    '.DiffStat-count': {
      fontWeight: 600,
      '&.removed': {
        color: colors.removed,
      },
      '&.added': {
        color: colors.added,
      },
    },

    '.DiffStat-ratio': {
      '&.removed': {
        backgroundColor: colors.removed,
      },
      '&.neutral': {
        backgroundColor: colors.neutral,
      },
      '&.added': {
        backgroundColor: colors.added,
      },
    },
  };
}
