import { Tab } from '@mui/material';
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

export interface TabRecord {
  disabled?: boolean;
  label: ReactNode;
  to?: string;
  value: string;
}

interface UseTabsProps {
  activeTabKey?: string;
  id: string;
  tabRecords: TabRecord[];
}

export const useTabs = ({ activeTabKey, id, tabRecords }: UseTabsProps) => {
  const [activeKey, setActiveKey] = useState<string>(
    activeTabKey ?? tabRecords[0]?.value
  );

  // Reset if tabs change & active key is no longer valid
  useEffect(() => {
    if (!tabRecords.find((tr) => tr.value === activeTabKey)) {
      setActiveKey(tabRecords[0]?.value);
    }
  }, [activeTabKey, tabRecords]);

  const changeTab = useCallback((evt: SyntheticEvent, newValue: string) => {
    setActiveKey(newValue);
  }, []);

  const children = tabRecords.map((tabRecord) => {
    const a11yProps = getTabA11yProps(tabRecord, id);

    return <Tab {...tabRecord} {...a11yProps} key={tabRecord.value} />;
  });

  return {
    activeTabValue: activeKey,

    getTabsProps: () => ({
      children,
      onChange: changeTab,
      value: activeKey,
    }),

    getTabPanelValues: () => tabRecords.map((tr) => getTabPanelValue(tr, id)),
  };
};

const getTabPanelValue = (tabRecord: TabRecord, id: string) =>
  `${id}-tabpanel-${tabRecord.value}`;

const getTabA11yProps = (tabRecord: TabRecord, id: string) => ({
  'aria-controls': getTabPanelValue(tabRecord, id),
  id: `${id}-tab-{tabRecord.value}`,
});
