import { SvgIconProps } from '@mui/material';
import { JSXElementConstructor, ReactNode } from 'react';

import {
  NotificationTargetActionActionType as ActionType,
  V1CustomTemplateType,
} from '@endorlabs/api_client';

export const CustomNotificationTemplateTypeKeys = {
  pr_comments: V1CustomTemplateType.PrComments,
  email: V1CustomTemplateType.Email,
  slack: V1CustomTemplateType.Slack,
  webhook: V1CustomTemplateType.Webhook,
} as const;

export type CustomNotificationTemplateTypeKey =
  keyof typeof CustomNotificationTemplateTypeKeys;

export const NotificationTargetActionTypeKeys = {
  email: ActionType.Email,
  jira: ActionType.Jira,
  slack: ActionType.Slack,
  vanta: ActionType.Vanta,
  webhook: ActionType.Webhook,
} as const;

export type NotificationTargetActionTypeKey =
  keyof typeof NotificationTargetActionTypeKeys;

export const NotificationTargetActionTemplateKeyMap: {
  [key in NotificationTargetActionTypeKey]?: CustomNotificationTemplateTypeKey;
} = {
  email: 'email',
  slack: 'slack',
  webhook: 'webhook',
};

export type NotificationTargetOption = {
  count?: number;
  disabled?: boolean;
  /**
   * Icon for the source control manager
   */
  Icon: JSXElementConstructor<SvgIconProps>;
  isLoading?: boolean;
  label: ReactNode;
  key: NotificationTargetActionTypeKey;
};
