import { Alert, AlertTitle, Grid, MenuItem } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ErrorOption, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ApiKeyResource, IQueryErrorResponse } from '@endorlabs/queries';
import {
  ButtonPrimary,
  ControlledTextField,
  IconAlertTriangle,
} from '@endorlabs/ui-common';

import { ApiKeyAdvancedFields } from './ApiKeyAdvancedFields';
import { FormNewApiKeyFieldValues } from './types';
import { useApiKeyFieldOptions } from './useApiKeyFieldOptions';
import { toApiKeyResourceModel } from './utils';

interface ServerFieldError<KField extends string> {
  field?: KField;
  object: ErrorOption;
}

const translateServerError = (code: number): ServerFieldError<'meta.name'> => {
  switch (code) {
    case 403: {
      return {
        object: {
          type: 'server',
          message:
            'Failed to generate API Key. Not authorized to create API Keys for this Tenant.',
        },
      };
    }
    default:
      return {
        object: {
          type: 'server',
          message: 'An unknown error occurred',
        },
      };
  }
};

export interface FormNewApiKeyProps {
  onSubmit: (data: ApiKeyResource) => void;
  serverErrorResponse?: IQueryErrorResponse;
  showSubmitButton?: boolean;
}

export const FormNewApiKey = ({
  onSubmit,
  serverErrorResponse,
  showSubmitButton = true,
}: FormNewApiKeyProps) => {
  const { control, handleSubmit, setError } = useForm<FormNewApiKeyFieldValues>(
    {
      defaultValues: {
        propagate: true,
      },
    }
  );
  const { formatMessage: fm } = useIntl();

  const [localError, setLocalError] = useState<ErrorOption | undefined>();
  useEffect(() => {
    // handle server error
    if (serverErrorResponse) {
      const { field, object } = translateServerError(
        serverErrorResponse.status
      );
      if (field) {
        setError(field, object);
      } else {
        setLocalError(object);
      }
    } else {
      setLocalError(undefined);
    }
  }, [serverErrorResponse, setError]);

  // process the form data before passing it up to the parent
  const wrappedOnSubmit = useCallback(
    (fieldValues: FormNewApiKeyFieldValues) => {
      const model = toApiKeyResourceModel(fieldValues);
      onSubmit(model);
    },
    [onSubmit]
  );

  // get the field options for the form
  const { expirations, permissions } = useApiKeyFieldOptions();

  return (
    <form id="FormNewApiKey" onSubmit={handleSubmit(wrappedOnSubmit)}>
      <Grid container direction="column" item spacing={6}>
        <Grid item>
          <ControlledTextField
            control={control}
            defaultValue=""
            name="meta.name"
            label={fm({ defaultMessage: 'Name your API Key' })}
            rules={{
              required: fm({ defaultMessage: 'API Key Name is required' }),
              maxLength: {
                value: 255,
                message: fm({
                  defaultMessage:
                    'API Key Name must be 255 characters or less.',
                }),
              },
            }}
          />
        </Grid>

        <Grid item>
          <ControlledTextField
            control={control}
            defaultValue={[]}
            name="permissionRoles"
            label={fm({
              defaultMessage: 'Permissions',
            })}
            rules={{
              required: fm({ defaultMessage: 'Permissions are required' }),
            }}
            select
            SelectProps={{
              multiple: true,
            }}
          >
            {permissions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>

        <Grid item>
          <ControlledTextField
            control={control}
            defaultValue={expirations[0].value}
            name="spec.expiration_time"
            label={fm({ defaultMessage: 'When should your API key expire?' })}
            select
            rules={{
              required: fm({
                defaultMessage: 'API Key Expiration is required',
              }),
            }}
          >
            {expirations.map(({ id, value, label }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>

        <Grid item>
          <ApiKeyAdvancedFields control={control} />
        </Grid>

        {showSubmitButton && (
          <Grid item marginTop={4}>
            <ButtonPrimary type="submit">
              <FM defaultMessage="Generate API Key" />
            </ButtonPrimary>
          </Grid>
        )}

        {localError && (
          <Grid item>
            <Alert severity="error" icon={<IconAlertTriangle />}>
              <AlertTitle>{localError.message}</AlertTitle>
            </Alert>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
