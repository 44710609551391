import { MakeGenerics, Navigate, useRouter } from '@tanstack/react-location';
import { useMemo } from 'react';

import { TenantType } from '@endorlabs/endor-core/auth';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { useSession } from '@endorlabs/queries';

import { useOnboardingSteps } from '../../domains/Onboarding';
import { useAuthTenantInfo } from '../../providers';
import {
  getDashboardPath,
  getOnboardRootPath,
  getProjectPath,
  NamedRoutes,
} from '../../routes';

type LocationGenerics = MakeGenerics<{
  Params: {
    namespace?: string;
  };
}>;

/**
 * A waypoint route that serves as the default "home" page. It "intelligently" redirects users based on their access to existing tenants.
 */
export const TenantsIndexPage = () => {
  const { getLastUsedTenant, hasValidTenantAccess, getUserTenants } =
    useSession();
  const router = useRouter<LocationGenerics>();

  const lastUsedTenant = getLastUsedTenant(true);
  const routeObject = router.state.matches.find((m) => m.params.namespace);

  const tenantNameInParams = routeObject?.params?.namespace;

  const tenantName = tenantNameInParams ? tenantNameInParams : lastUsedTenant;

  const { isFreeTrialTenant, isSharedTenant } = useAuthTenantInfo(tenantName);
  const { getIsOnboardingComplete } = useOnboardingSteps((state) => state);
  const isOnboardingComplete = getIsOnboardingComplete();

  const isDemoTrialTenant = useMemo(() => {
    const sharedTenants = getUserTenants().filter(
      (t) => t.tenantType === TenantType.Shared
    );
    return (
      (sharedTenants || []).findIndex((t) => t.name === NAMESPACES.DEMO_TRIAL) >
      -1
    );
  }, [getUserTenants]);

  if (tenantName && hasValidTenantAccess()) {
    if (isFreeTrialTenant && !isSharedTenant && !isOnboardingComplete) {
      return <Navigate to={getOnboardRootPath({ tenantName })} replace />;
    }

    if (isSharedTenant) {
      //Shared Tenants redirected from App drawer seitch tenants goto projects page by default
      return <Navigate to={getProjectPath({ tenantName })} replace />;
    }

    //For premium tenants and free trial tenants with onboarding completed
    return <Navigate to={getDashboardPath({ tenantName })} replace />;
  }
  if (!isOnboardingComplete && isDemoTrialTenant) {
    return (
      <Navigate
        to={getProjectPath({ tenantName: NAMESPACES.DEMO_TRIAL })}
        replace
      />
    );
  }
  /**
   * For authenticated users who do do not have access to tenants other than
   * Global or Shared tenants, redirect to the tenant create page.
   */
  return <Navigate to={NamedRoutes.TENANT_CREATE} replace />;
};
