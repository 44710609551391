import { Box, SvgIconProps, useTheme } from '@mui/material';
import { JSXElementConstructor } from 'react';

export type WithIconFrameOptions = {
  displayName?: string;
  variant?: 'circular' | 'rounded';
  framePadding?: number;
};

/**
 * Given an Icon component, return a new component composed of the icon wrapped
 * within an outer frame.
 */
export const withIconFrame = (
  IconComponent: JSXElementConstructor<SvgIconProps>,
  options: WithIconFrameOptions
) => {
  const FramedIcon = (props: Omit<SvgIconProps, 'sx'>) => {
    const { palette, shape, spacing } = useTheme();

    const { fontSize = 'inherit', ...iconProps } = props;

    const borderRadius =
      options.variant === 'circular'
        ? `999px`
        : options.variant === 'rounded'
        ? `${shape.borderRadius}px`
        : 0;

    return (
      <Box
        sx={{
          backgroundColor: palette.background.paper,
          border: `1px solid ${palette.divider}`,
          borderRadius,
          display: 'flex',
          padding: spacing(options.framePadding ?? 0.5),
        }}
      >
        <IconComponent {...iconProps} fontSize={fontSize} />
      </Box>
    );
  };

  if (options.displayName) {
    FramedIcon.displayName = options.displayName;
  }

  return FramedIcon;
};
