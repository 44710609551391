import produce from 'immer';

import { V1ListParameters } from '@endorlabs/api_client';
import { isResourceWithContext } from '@endorlabs/endor-core/common/utils';

import { ResourceKind } from '../../common';
import { ResourceReadAction } from '../types';

const warn = (...msg: any[]): void =>
  // eslint-disable-next-line no-console
  console.warn('DEVELOPER', ...msg);

const validateReadRequestParameters = (
  kind: ResourceKind,
  action: ResourceReadAction,
  parameters: Partial<V1ListParameters>
): void => {
  // NOTE: validation is a noop for production builds
  if (process.env.NODE_ENV !== 'production') {
    // Validate parameters related to specific read types
    if (action !== 'COUNT' && parameters.count) {
      warn('`count` parameter used in non-count action');
    }

    if (action === 'GROUP' && !parameters.group?.aggregation_paths) {
      warn('Group call used without expected value for `aggregation_paths`');
    }

    // Validate parameters for resources with context
    if (
      isResourceWithContext(kind) &&
      // Supress warning for ImportedSBOM. They should only ever have an
      // `SBOM` context type
      kind !== 'ImportedSBOM'
    ) {
      const filterValue = parameters?.filter;

      // check if filter includes context key
      const missingContextFilter =
        // if no filter is given, consider as missing
        !filterValue ||
        // else, consider missing if the filter is missing key for `context`
        (filterValue && !filterValue.includes('context.'));

      // if the uuid is explicitly given, suppress the context warning
      const hasExplicitUuidFilter =
        filterValue?.includes('uuid==') || filterValue?.includes('uuid in');

      if (missingContextFilter && !hasExplicitUuidFilter) {
        warn(
          `Call may be missing expected context filter ${kind} ${action}:`,
          parameters
        );
      }
    }

    // Warn on deprecated usages
    if (parameters.action && parameters.action === 'includeoss') {
      warn('UI-504: Avoid usage of the `includeoss` action');
    }
  }
};

/**
 * Utility to validate and build request parameters for the given
 * Resource Kind and action.
 */
export const buildReadRequestParameters = (
  kind: ResourceKind,
  action: ResourceReadAction,
  parameters: Partial<V1ListParameters> = {},
  options?: { skipValidation: boolean }
): V1ListParameters => {
  if (!options?.skipValidation) {
    validateReadRequestParameters(kind, action, parameters);
  }

  return produce(parameters, (draft) => {
    // Ensure that the `count` parameter is set for count requests
    if (action === 'COUNT') {
      draft.count = true;
    }
  });
};
