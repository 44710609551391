import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Row } from '@tanstack/react-table';
import merge from 'lodash-es/merge';
import noop from 'lodash-es/noop';
import omitBy from 'lodash-es/omitBy';
import sortBy from 'lodash-es/sortBy';
import { useState } from 'react';
import { defineMessages } from 'react-intl';
import { FormattedMessage as FM } from 'react-intl';

import {
  useCreatePackageManager,
  useDeletePackageManager,
  useListPackageManagers,
  useUpdatePackageManager,
} from '@endorlabs/queries';
import { ButtonPrimary, EmptyState, useAppNotify } from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { useAuthInfo } from '../../providers';
import { getIntegrationsRootPath, useFullMatch } from '../../routes';
import {
  AuthLoginPackageManagerKey,
  PACKAGE_MANAGERS,
  PackageManagerFieldsInForm,
  PackageManagerKey,
  PackageManagerSpecFieldMasks,
  PackageManagersWithPasswordAuth,
  PackageManagersWithTokenAuth,
  PackagistPackageManagerKey,
  TokenLoginPackageManagerKey,
} from './constants';
import { FormUpsertPackagistPackageManager } from './FormUpsertPackagistPackageManager';
import { FormUpsertPasswordAuthPackageManager } from './FormUpsertPasswordAuthPackageManager';
import { FormUpsertTokenAuthPackageManager } from './FormUpsertTokenAuthPackageManager';
import { PackageManagerDeleteConfirmationDialog } from './PackageManagerDeleteConfirmation';
import {
  PackageManagersTable,
  PackageManagersTableRow,
} from './PackageManagersTable';
import {
  AllPackageManagerFieldKeys,
  FormUpsertPackageManagerFieldValues,
} from './types';

const messages = defineMessages({
  addPackageManager: { defaultMessage: 'Add Package Manager' },
  createError: {
    defaultMessage: 'Could not create this package manager. {err}',
  },
  createSuccess: { defaultMessage: 'Package manager created successfully' },
  deleteError: {
    defaultMessage: 'Package manager could not be deleted. {err}',
  },
  deleteSuccess: { defaultMessage: 'Package manager deleted' },
  updateError: {
    defaultMessage: 'Could not update this package manager. {err}',
  },
  updateSuccess: { defaultMessage: 'Package manager updated successfully' },
});

/**
 * Individual page to handle CRUD of package managers of a given type (Maven, NPM, etc)
 */
export const PackageManagerSettingsPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { packageManagerName },
  } = useFullMatch();
  const addAppNotification = useAppNotify();

  const packageManagerKey = packageManagerName as PackageManagerKey;

  /**
   * Dialog is open if there's an object in state.
   * For `update` & `delete` modes, a `uuid` must be present.
   */
  const [editingPackageManager, setEditingPackageManager] = useState<
    { uuid?: string; action?: 'create' | 'update' | 'delete' } | undefined
  >(undefined);

  const qPackageManagersList = useListPackageManagers(
    tenantName,
    {},
    {
      mask: [
        'uuid',
        'meta',
        'tenant_meta',
        PackageManagerSpecFieldMasks,
        'propagate',
      ].join(','),
    }
  );

  // Filter to only package managers with a url matching the current package manager type
  const activePackageManagers =
    qPackageManagersList.data?.list?.objects.filter(
      (pm) => !!pm?.spec[packageManagerKey]
    ) ?? [];

  // Sort by priority
  const displayedPms = sortBy(
    activePackageManagers.map(
      (pm) =>
        ({
          uuid: pm.uuid,
          namespace: pm.tenant_meta.namespace,
          ...pm.spec[packageManagerKey],
        } as PackageManagersTableRow)
    ),
    ['priority']
  );

  const packageManagerUnderEdit = editingPackageManager
    ? activePackageManagers.find(
        (pm) => pm.uuid === editingPackageManager?.uuid
      )
    : undefined;

  const handleEditDialog = (row: PackageManagersTableRow) => {
    setEditingPackageManager({ uuid: row.uuid, action: 'update' });
  };

  const handleDeleteDialog = (row: PackageManagersTableRow) => {
    setEditingPackageManager({ uuid: row.uuid, action: 'delete' });
  };

  const handleConfirmDelete = () => {
    qPackageManagerDelete.mutate({
      namespace: tenantName,
      uuid: editingPackageManager?.uuid as string,
    });
  };

  const closeDialog = () => setEditingPackageManager(undefined);

  const qPackageManagerCreate = useCreatePackageManager({
    onError: (err) => {
      addAppNotification({
        message: (
          <FM
            {...messages.createError}
            values={{ err: err.response.data?.message }}
          />
        ),
        severity: 'error',
      });
      closeDialog();
    },
    onSuccess: () => {
      addAppNotification({
        message: <FM {...messages.createSuccess} />,
        severity: 'success',
      });
      closeDialog();
    },
  });

  const qPackageManagerUpdate = useUpdatePackageManager({
    onError: (err) => {
      addAppNotification({
        message: (
          <FM
            {...messages.updateError}
            values={{ err: err.response.data?.message }}
          />
        ),
        severity: 'error',
      });
      closeDialog();
    },
    onSuccess: () => {
      addAppNotification({
        message: <FM {...messages.updateSuccess} />,
        severity: 'success',
      });
      closeDialog();
    },
  });
  const qPackageManagerDelete = useDeletePackageManager({
    onError: (err) => {
      addAppNotification({
        message: (
          <FM
            {...messages.deleteError}
            values={{ err: err.response.data?.message }}
          />
        ),
        severity: 'error',
      });
      closeDialog();
    },
    onSuccess: () => {
      addAppNotification({
        message: <FM {...messages.deleteSuccess} />,
        severity: 'success',
      });
      closeDialog();
    },
  });

  /**
   * Form submission
   */
  const onSubmit = (formData: FormUpsertPackageManagerFieldValues) => {
    const updateUuid = editingPackageManager?.uuid;

    // HACK: split shared fields from package manager-specific spec fields
    const { propagate, ...updatedPackageManager } = formData;
    const existingPm = activePackageManagers.find(
      (pm) => pm.uuid === updateUuid
    );

    if (existingPm) {
      //Filter key value pair not updated in form
      const restValues = omitBy(
        existingPm.spec[packageManagerKey],
        (_, key) => {
          const formKey = key as AllPackageManagerFieldKeys;
          return PackageManagerFieldsInForm.includes(formKey);
        }
      );
      const updatedPm = {
        ...existingPm,
        spec: {
          [packageManagerKey]: {
            ...restValues,
            ...updatedPackageManager,
          },
        },
        propagate,
      };

      qPackageManagerUpdate.mutate({
        namespace: tenantName,
        resource: updatedPm,
        mask: 'meta,spec,propagate',
      });
    } else {
      const newPm = {
        meta: { name: `${packageManagerName} PackageManager` },
        spec: {
          [packageManagerKey]: {
            ...updatedPackageManager,
            priority: activePackageManagers.length,
          },
        },
        propagate,
      };

      qPackageManagerCreate.mutate({
        namespace: tenantName,
        resource: newPm,
      });
    }
  };

  /**
   * Update all rows to match new priority order
   */
  // Separate named query to avoid multiple app notifications for every reordered manager
  const qUpdateOrder = useUpdatePackageManager();
  const handleReorder = (rows: Row<PackageManagersTableRow>[]) => {
    rows.forEach((row, newIndex) => {
      const pm = activePackageManagers.find(
        (pm) => pm.uuid === row.original?.uuid
      );
      const updatedSpec = { [packageManagerKey]: { priority: newIndex } };
      const newPm = merge(pm, { spec: updatedSpec });

      qUpdateOrder.mutate(
        { namespace: tenantName, resource: newPm },
        {
          onSuccess: noop,
        }
      );
    });
  };

  const isEmptyState =
    !qPackageManagersList.isLoading && activePackageManagers.length === 0;

  const packageManager = PACKAGE_MANAGERS.find(
    (pm) => pm.key === packageManagerName
  );

  const isPasswordAuth =
    PackageManagersWithPasswordAuth.includes(packageManagerKey);
  const isTokenAuth = PackageManagersWithTokenAuth.includes(packageManagerKey);

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <PageHeader
            action={
              <Box display="flex" justifyContent="flex-end">
                <ButtonPrimary
                  onClick={() =>
                    setEditingPackageManager({
                      uuid: undefined,
                      action: 'create',
                    })
                  }
                >
                  <FM {...messages.addPackageManager} />
                </ButtonPrimary>
              </Box>
            }
            breadcrumbsLinks={[
              {
                label: <FM defaultMessage="Integrations" />,
                url: getIntegrationsRootPath({ tenantName }),
              },
            ]}
            Icon={packageManager?.Icon}
            metadata={{ summary: [] }}
            title={packageManager?.label}
          />
        </Grid>

        {!isEmptyState && (
          <Grid item>
            <Card>
              <CardContent>
                <PackageManagersTable
                  data={displayedPms}
                  isLoading={qPackageManagersList.isLoading}
                  onDelete={handleDeleteDialog}
                  onEdit={handleEditDialog}
                  onReorderComplete={handleReorder}
                  packageManagerKey={packageManagerKey}
                />
              </CardContent>
            </Card>
          </Grid>
        )}

        {isEmptyState && (
          <Grid item>
            <EmptyState
              size="large"
              title={
                <FM defaultMessage="You have not added any Package Manager definitions yet" />
              }
            >
              <ButtonPrimary
                onClick={() =>
                  setEditingPackageManager({
                    uuid: undefined,
                    action: 'create',
                  })
                }
              >
                <FM {...messages.addPackageManager} />
              </ButtonPrimary>
            </EmptyState>
          </Grid>
        )}
      </Grid>

      {/* ===== UPSERT DIALOG ===== */}
      <Dialog
        onClose={() => setEditingPackageManager(undefined)}
        open={
          editingPackageManager !== undefined &&
          ['create', 'update'].includes(editingPackageManager.action as string)
        }
      >
        <DialogTitle>
          {editingPackageManager?.uuid !== undefined ? (
            <FM defaultMessage="Editing Package Manager" />
          ) : (
            <FM {...messages.addPackageManager} />
          )}
        </DialogTitle>
        <DialogContent>
          {
            isPasswordAuth ? (
              <FormUpsertPasswordAuthPackageManager
                authRequired={Boolean(packageManager?.authRequired)}
                onSubmit={onSubmit}
                packageManager={packageManagerUnderEdit}
                packageManagerKey={
                  packageManagerName as AuthLoginPackageManagerKey
                }
              />
            ) : isTokenAuth ? (
              <FormUpsertTokenAuthPackageManager
                authRequired={Boolean(packageManager?.authRequired)}
                onSubmit={onSubmit}
                packageManager={packageManagerUnderEdit}
                packageManagerKey={
                  packageManagerName as TokenLoginPackageManagerKey
                }
              />
            ) : (
              //This has both password and token auth, based on auth kind. Hence added it separately
              <FormUpsertPackagistPackageManager
                authRequired={Boolean(packageManager?.authRequired)}
                onSubmit={onSubmit}
                packageManager={packageManagerUnderEdit}
                packageManagerKey={
                  packageManagerName as PackagistPackageManagerKey
                }
              />
            )
            // :
          }
        </DialogContent>
      </Dialog>

      {/* ===== DELETE DIALOG ===== */}
      <PackageManagerDeleteConfirmationDialog
        open={
          !!editingPackageManager && editingPackageManager.action === 'delete'
        }
        onConfirm={handleConfirmDelete}
        onCancel={closeDialog}
      />
    </>
  );
};
