import { Box, Card, CardContent, CardHeader, Grid, Slide } from '@mui/material';
import { MakeGenerics, useSearch } from '@tanstack/react-location';
import { useEffect, useRef } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  IconBookmark,
  useControlledTableMultiselect,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../../components';
import { useAuthInfo } from '../../../providers';
import { getPoliciesRootPath, useFullMatch } from '../../../routes';
import { useEditPolicyPage } from '../hooks';
import { EditPolicyPage as messages } from '../locales';
import { PolicyUmbrellaType } from '../types';
import { FormUpsertPolicy } from './FormUpsertPolicy';

type EditPolicyPageLocationGenerics = MakeGenerics<{
  Params: {
    activeView: PolicyUmbrellaType;
    policyUuid?: string;
  };
  Search: {
    templateUuid?: string;
  };
}>;

export const EditPolicyPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { activeView, policyUuid },
  } = useFullMatch<EditPolicyPageLocationGenerics>();
  const { templateUuid } = useSearch<EditPolicyPageLocationGenerics>();
  const policyUmbrellaType = activeView as PolicyUmbrellaType;

  // Establish a DOM element to support `ControlledTableMultiselect` transitions.
  const cardContainerEl = useRef();
  const { isAnyMultiselectTableDisplayed, reset: resetControlledMultiselect } =
    useControlledTableMultiselect({
      targetElement: cardContainerEl.current,
    });

  // Reset the controlled multiselect state when the component unmounts.
  useEffect(() => {
    return () => {
      resetControlledMultiselect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    activePolicyTemplate,
    isEditing,
    policy,
    policyTemplates,
    onFormSubmitError,
    onFormSubmitSuccess,
  } = useEditPolicyPage({
    namespace: tenantName,
    policyUuid,
    policyTemplateUuid: templateUuid as string,
    policyUmbrellaType: policyUmbrellaType,
  });

  const pageTitle = isEditing ? (
    <FM {...messages[policyUmbrellaType].pageTitleUpdate} />
  ) : (
    <FM {...messages[policyUmbrellaType].pageTitleCreate} />
  );

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          breadcrumbsLinks={[
            {
              label: <FM defaultMessage="Policies" />,
              url: getPoliciesRootPath({ tenantName }),
            },
          ]}
          Icon={IconBookmark}
          metadata={{ summary: [] }}
          title={pageTitle}
        />
      </Grid>

      <Grid item xs={12}>
        <Box ref={cardContainerEl} sx={{ position: 'relative' }}>
          <Slide
            appear={false}
            container={cardContainerEl.current}
            direction="right"
            in={!isAnyMultiselectTableDisplayed}
          >
            <Card sx={{ position: 'absolute', width: '100%' }}>
              <CardHeader
                title={<FM {...messages[policyUmbrellaType].formHeading} />}
              />
              <CardContent>
                <Grid container width="100%">
                  <Grid item xs={12}>
                    <FormUpsertPolicy
                      activeTemplate={activePolicyTemplate}
                      onError={onFormSubmitError}
                      onSuccess={onFormSubmitSuccess}
                      policy={policy}
                      policyTemplates={policyTemplates}
                      policyUmbrellaType={policyUmbrellaType}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Slide>
        </Box>
      </Grid>
    </Grid>
  );
};
