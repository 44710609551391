/**
 * Similar to `Verified` from '@mui/icons-material/Verified';
 *
 * Exposes a
 */

import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type VerifiedProps = {
  color?: string;
  size?: string | number;
};

const Verified = forwardRef<SVGSVGElement, VerifiedProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M 8.5 3.5 Q 12 0 15.5 3.5 Q 20.5 3.5 20.5 8.5 Q 24 12 20.5 15.5 Q 20.5 20.5 15.5 20.5 Q 12 24 8.5 20.5 Q 3.5 20.5 3.5 15.5 Q 0 12 3.5 8.5 Q 3.5 3.5 8.5 3.5 Z"
          fill="hsla(150, 44%, 94%, 1)"
          stroke="hsla(157, 100%, 30%, 1)"
        />
        <polyline points="8 13 11 16 17 10" stroke="hsla(157, 100%, 30%, 1)" />
      </svg>
    );
  }
);

Verified.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Verified.displayName = 'Verified';

export default Verified;
