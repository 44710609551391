import { FormattedMessage as FM } from 'react-intl';

import {
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
} from '@endorlabs/ui-common';

export interface SASTRulesTableRow {
  enabled: boolean;
  ruleName: string;
  definedBy: string;
  cwe: string;
  owasp: string;
  ruleTags: Array<string> | undefined;
  uuid: string;
}

export type SASTRulesTableColumnId = keyof SASTRulesTableRow;

export const buildSASTRulesTableColumnDefs = ({
  excludeColumns,
}: {
  excludeColumns: SASTRulesTableColumnId[];
}) => {
  const columns: DataTableColumnDef<SASTRulesTableRow>[] = [
    {
      accessorKey: 'ruleName',
      header: () => <FM defaultMessage="SAST Rule" />,
      colType: ColTypes.TEXT_LONG,
    },
    {
      accessorKey: 'definedBy',
      header: () => <FM defaultMessage="Defined By" />,
      colType: ColTypes.TEXT_WITH_DESCRIPTION,
    },
    {
      accessorKey: 'cwe',
      header: () => <FM defaultMessage="CWE" />,
      colType: ColTypes.TEXT_LONG,
    },
    {
      accessorKey: 'owasp',
      header: () => <FM defaultMessage="OWASP" />,
      colType: ColTypes.TEXT_WITH_DESCRIPTION,
    },
    {
      accessorKey: 'ruleTags',
      header: () => <FM defaultMessage="RuleTags" />,
      colType: ColTypes.TAGS,
    },
  ];
  return columns.filter((col) => {
    if (!col.accessorKey) return true;
    return (excludeColumns ?? []).includes(col.accessorKey) ? false : true;
  });
};
