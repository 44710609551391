import { FormattedMessage as FM } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';

import { PageHeader } from '../../components';
import { PageLayout } from '../../components/PageLayout';
import {
  getDefaultFilterValues,
  getInitialFilterValues,
  ResourceFilterContextProvider,
} from '../../domains/filters';
import {
  FINDING_FILTER_FIELDS,
  FINDING_SEARCH_KEYS,
} from '../../domains/Findings';
import { FindingsDataTableView } from '../../domains/Findings/components/FindingsDataTableView';
import {
  OnboardingStepIds,
  useOnboardingSteps,
} from '../../domains/Onboarding';
import { useAuthInfo } from '../../providers';
import { OnboardingPopoverFindings } from '../Onboarding/OnboardingPopoverFindings';
import { FindingsPageNavigation } from './FindingsPageNavigation';

const DEFAULT_FILTER_VALUES = getDefaultFilterValues(FINDING_FILTER_FIELDS);
const INITIAL_FILTER_VALUES = getInitialFilterValues(FINDING_FILTER_FIELDS);

export const FindingsIndexPageV2 = () => {
  const { activeNamespace } = useAuthInfo();

  // Show a popover if user arrived at this page during onboarding
  const { getStepCompletedWithin } = useOnboardingSteps();
  const completedOnboarding = getStepCompletedWithin(
    OnboardingStepIds.ONBOARD_VIEW_FINDINGS,
    10
  );

  return (
    <ResourceFilterContextProvider
      baseFilter={filterExpressionBuilders.mainResourceContext()}
      defaultFilterValues={DEFAULT_FILTER_VALUES}
      initialFilterValues={INITIAL_FILTER_VALUES}
      namespace={activeNamespace}
      resourceKind="Finding"
      resourceSearchKeys={FINDING_SEARCH_KEYS}
    >
      <PageLayout
        pageContent={<FindingsDataTableView namespace={activeNamespace} />}
        pageHeader={<PageHeader title={<FM defaultMessage="Findings" />} />}
        pageNavigation={<FindingsPageNavigation />}
      />

      <OnboardingPopoverFindings willShow={completedOnboarding} />
    </ResourceFilterContextProvider>
  );
};
