import { Grid } from '@mui/material';
import { useRef } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';
import { useCountVersionUpgrade } from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  ButtonLinkSecondary,
  ButtonSecondary,
  ButtonStack,
  EmptyState,
  IconExternalLink,
  IconRefreshCw,
  IconTicket,
} from '@endorlabs/ui-common';

import { PageHeader, PageHeaderCount } from '../../components';
import { ResourceFilterContextProvider } from '../../domains/filters';
import {
  REMEDIATIONS_SEARCH_KEYS,
  useRemediationsExportDialog,
} from '../../domains/Remediations';
import {
  RemediationsTableView,
  RemediationsTableViewRef,
} from '../../domains/Remediations/components/RemediationsTableView';
import { useAuthInfo } from '../../providers';
import { getPoliciesPath, NamedRoutes } from '../../routes';

export const RemediationsIndexPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();

  const findingsReductionFilter = [
    'spec.upgrade_info.total_findings_fixed > 0',
  ];
  const baseFilterExpression = filterExpressionBuilders.and([
    'meta.name == PrioritizedPerPackage',
    'spec.upgrade_info exists',
    filterExpressionBuilders.or(findingsReductionFilter),
    filterExpressionBuilders.mainResourceContext(),
  ]);

  const qCountVersionUpgrades = useCountVersionUpgrade(tenantName, {
    filter: baseFilterExpression,
  });

  const upgradesCount = qCountVersionUpgrades.data?.count;

  const isEmptyState = !qCountVersionUpgrades.isLoading && upgradesCount === 0;

  const tableRef = useRef<RemediationsTableViewRef>(null);

  const handleRefresh = () => {
    if (tableRef.current) {
      tableRef.current.refreshData();
    }
  };

  const remediationsExportDialog = useRemediationsExportDialog();

  const actionList = [
    {
      actionId: 'remediations-export',
      label: 'Export',
      onApply: () => {
        const remediationsFilterExpression = tableRef.current
          ? tableRef.current?.remediationsFilterExpression
          : baseFilterExpression;

        remediationsExportDialog.openDialog({
          namespace: tenantName,
          filter: remediationsFilterExpression,
          downloadProps: {
            filename: `tenant_${tenantName}_remediations-export.csv`,
          },
        });
      },
    },
  ];

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          action={
            <ButtonStack>
              <ButtonSecondary
                onClick={handleRefresh}
                startIcon={<IconRefreshCw />}
              >
                <FM defaultMessage="Refresh" />
              </ButtonSecondary>
              <ButtonLinkSecondary
                linkProps={{
                  to: NamedRoutes.DOCS_REMEDIATIONS,
                  target: '_blank',
                }}
                startIcon={<IconExternalLink />}
              >
                <FM defaultMessage="View Docs" />
              </ButtonLinkSecondary>
              {/* NOTE: Hidden until create policy is available */}
              {/* <ButtonLinkPrimary
                linkProps={{
                  to: getPoliciesPath({ tenantName, section: `actions/new` }),
                }}
                startIcon={<IconTicket />}
              >
                <FM defaultMessage="Create Policy" />
              </ButtonLinkPrimary> */}
            </ButtonStack>
          }
          title={<FM defaultMessage="Remediations" />}
          titleDetails={
            upgradesCount && <PageHeaderCount value={upgradesCount} />
          }
        />
      </Grid>
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="Remediations will be available to view here." />
            }
            description={
              <FM defaultMessage="As new remediations are found, this is where you can easily search across them." />
            }
          ></EmptyState>
        </Grid>
      )}
      {!isEmptyState && (
        <>
          <Grid item>
            <ResourceFilterContextProvider
              namespace={tenantName}
              resourceKind="VersionUpgrade"
              resourceSearchKeys={REMEDIATIONS_SEARCH_KEYS}
            >
              <RemediationsTableView
                actions={actionList}
                baseFilterExpression={baseFilterExpression}
                ref={tableRef}
                tenantName={tenantName}
              />
            </ResourceFilterContextProvider>
          </Grid>
        </>
      )}
      <remediationsExportDialog.Dialog
        {...remediationsExportDialog.getDialogProps()}
      />
    </Grid>
  );
};
