import { Box, Checkbox, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { Cell, ColumnDef, Row, RowData } from '@tanstack/react-table';
import { FormattedMessage as FM, FormattedNumber } from 'react-intl';

import {
  SpecFindingLevel,
  V1CiCdTool,
  V1ScoreCategory,
} from '@endorlabs/api_client';
import {
  getChildNamespace,
  isRootNamespace,
} from '@endorlabs/endor-core/Namespace';
import { FindingCount } from '@endorlabs/queries';

import {
  BooleanDisplay,
  DataTableColumnHeader,
  DateDisplay,
  DateTimeDisplay,
  Link,
  NilDisplay,
  NumberDisplay,
  PackageNameDisplay,
  ProjectNameDisplay,
  ProjectNameDisplayProps,
  RelativeTimeDisplay,
  ScoreDisplay,
  SeverityDisplay,
  TagsDisplay,
  TimeDisplay,
  ToolCategoryDisplay,
  ToolLogoArrayDisplay,
  ToolNameArrayDisplay,
} from '../../../components';
import { FindingCountArrayDisplay } from '../../../domains/Findings';
import { ScoreCategoryIcon } from '../../../domains/Metrics';
import { IconHelp } from '../../../themes';
import { DataTableRowData } from './common';

/**
 * Augment ColumnDef with our own properties.
 */
// TODO: Tanstack table has begun typing cell values as `unknown` instead of `any`, which causes many typing issues
// We should refactor column definitions to use column helpers:
// https://tanstack.com/table/v8/docs/guide/column-defs#column-helpers
// But in the meantime, restoring the original `any` definition here.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataTableColumnDef<T extends RowData> = ColumnDef<T, any> & {
  accessorKey?: keyof T;
  colType?: DataTableColumnTypeKey;
  displayName?: string;
  enableReorder?: boolean;
  hidden?: boolean;
  onClick?: (
    columnDef: ColumnDef<T>,
    cell: Cell<DataTableRowData, unknown>,
    row: Row<T>
  ) => void;
};

/**
 * String keys used to designate column "types" when defining Data Table columns.
 */
export const DataTableColumnTypeKeys = {
  ACTIONS: 'actions',
  BOOLEAN: 'boolean',
  DATE_TIME: 'dateTime',
  DATE: 'date',
  FINDING_COUNTS: 'findingCounts',
  NAMESPACE: 'namespace',
  NUMBER_LARGE: 'numberLarge',
  NUMBER: 'number',
  PACKAGE: 'package',
  ICONLIST: 'iconList',
  PERCENT: 'percent',
  PROJECT: 'project',
  RELATIVE_TIME: 'relativeTime',
  ROW_SELECT: 'rowSelect',
  SCORE: 'score',
  SEVERITY: 'severity',
  STATUS_INDICATOR: 'statusIndicator',
  SWITCH: 'switch',
  TAGS: 'tags',
  TEXT_LONG: 'textLong',
  TEXT_WITH_DESCRIPTION: 'titleAndDescription',
  TEXT: 'text',
  TIME: 'time',
  TOOL_CATEGORY: 'toolCategory',
  TOOLS_NAMES: 'toolsNames',
  TOOLS_LOGOS: 'toolsLogos',
  URL: 'url',
  VERSION: 'version',
} as const;

export type DataTableColumnTypeKey =
  (typeof DataTableColumnTypeKeys)[keyof typeof DataTableColumnTypeKeys];

const CK = DataTableColumnTypeKeys;

/**
 * Column type definitions. If a "type" is provided to a DataTable column definition,
 * that definition will be based upon the properties here.
 */
export const getDataTableColumnTypes = (
  colType: DataTableColumnTypeKey,
  props?: unknown
) => {
  const colOptions: Record<
    DataTableColumnTypeKey,
    Partial<DataTableColumnDef<DataTableRowData>>
  > = {
    // Actions column, typically at right-hand side of table
    [CK.ACTIONS]: {
      enableResizing: false,
      header: '',
      meta: { wrapCell: true },
    },

    [CK.BOOLEAN]: {
      cell: (t) => <BooleanDisplay value={t.getValue() as boolean} />,
      size: 80,
    },

    [CK.DATE]: {
      cell: (t) => <DateDisplay value={t.getValue() as string} />,
    },

    [CK.DATE_TIME]: {
      cell: (t) => <DateTimeDisplay value={t.getValue() as string} />,
    },

    // TODO: cell definition a la https://github.com/endorlabs/monorepo/blob/main/src/typescript/packages/webapp/src/components/CIRunsTable/columnDefs.tsx#L172-L172
    [CK.FINDING_COUNTS]: {
      enableResizing: false,
      header: () => <FM defaultMessage="Findings" />,
      cell: (t) => {
        return (
          <FindingCountArrayDisplay
            forceWidth
            value={t.getValue() as FindingCount[]}
          />
        );
      },
      size: 250,
    },

    //Show package Icon only
    [CK.ICONLIST]: {
      minSize: 40,
      maxSize: 150,
    },

    [CK.NAMESPACE]: {
      header: () => (
        <DataTableColumnHeader
          label={<FM defaultMessage="Created In" />}
          helpTooltip={
            <FM defaultMessage="The namespace the object was created in." />
          }
        />
      ),
      /**
       * Default formatted display for namespace. For non-root namespaces, the
       * child namespace is displayed in the table, and the full namespace is
       * shown on hover.
       */
      cell: (t) => {
        const namespace = t.getValue();
        if (!namespace) return null;

        if (isRootNamespace(namespace)) return namespace;

        const childNamespace = getChildNamespace(namespace);

        return (
          <Tooltip title={namespace}>
            <Typography component="span">{childNamespace}</Typography>
          </Tooltip>
        );
      },
    },

    [CK.NUMBER]: {
      cell: (t) => <NumberDisplay value={t.getValue() as number} />,
      size: 80,
    },

    [CK.NUMBER_LARGE]: {
      cell: (t) => <NumberDisplay value={t.getValue() as number} />,
    },

    // Standard package name display
    [CK.PACKAGE]: {
      cell: ({ getValue }) => (
        <PackageNameDisplay name={getValue() as string} />
      ),
      size: 320,
    },

    [CK.PERCENT]: {
      cell: (t) => {
        const pct = t.getValue() satisfies number;

        return pct ? (
          <FormattedNumber
            style="percent"
            maximumFractionDigits={2}
            value={t.getValue() satisfies number}
          />
        ) : (
          <NilDisplay variant="text" />
        );
      },
      size: 80,
    },

    // Standard project name display
    [CK.PROJECT]: {
      cell: (t) => {
        const platformSource = (t.row.original as ProjectNameDisplayProps)
          ?.platformSource;
        return (
          <ProjectNameDisplay
            name={t.getValue() as string}
            platformSource={platformSource}
            showIcon={!!platformSource}
          />
        );
      },
      size: 320,
    },

    [CK.RELATIVE_TIME]: {
      cell: (t) => <RelativeTimeDisplay value={t.getValue() as string} />,
    },

    // Checkbox for row selection
    [CK.ROW_SELECT]: {
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      onClick: (columnDef, cell, row) => {
        if (row.getCanSelect()) {
          row.toggleSelected();
        }
      },
      minSize: 40,
      maxSize: 48,
    },

    // Display of an individual score category
    // NOTE: This requires the column id/accessorKey to be a V1ScoreCategory
    [CK.SCORE]: {
      cell: (t) => {
        const category = t.column.id;
        return (
          <ScoreDisplay
            category={category}
            value={t.getValue() as number}
            variant="bar"
          />
        );
      },
      enableResizing: false,
      header: (t) => (
        <Box display="flex" justifyContent="center">
          <ScoreCategoryIcon
            category={t.column.id as V1ScoreCategory}
            fontSize="medium"
            sx={{ width: 30 }}
          />
        </Box>
      ),
      minSize: 40,
      maxSize: 48,
      size: 40,
    },

    // Standard severity display
    [CK.SEVERITY]: {
      cell: (t) => <SeverityDisplay value={t.getValue() as SpecFindingLevel} />,
      header: () => <FM defaultMessage="Severity" />,
      minSize: 30,
    },

    // Status indicator (e.g. for project scan status)
    [CK.STATUS_INDICATOR]: {
      enableResizing: false,
      header: '',
      size: 24,
    },

    // A Switch component (e.g. to enable/disable a policy)
    [CK.SWITCH]: {
      enableResizing: false,
      header: () => <FM defaultMessage="Enabled" />,
      minSize: 64,
      size: 64,
    },

    // Standard list display for tags
    [CK.TAGS]: {
      header: () => <FM defaultMessage="Tags" />,
      cell: (t) => <TagsDisplay tags={t.getValue()} />,
      maxSize: 360,
      minSize: 240,
    },

    [CK.TEXT]: {},

    [CK.TEXT_LONG]: {
      size: 320,
    },

    [CK.TIME]: {
      cell: (t) => <TimeDisplay value={t.getValue() as string} />,
    },

    // A standard size text display with an optional description tooltip
    [CK.TEXT_WITH_DESCRIPTION]: {
      cell: ({ getValue, row }) => {
        // @ts-expect-error - NOTE: Expects "description" key to exist in row data
        const description = row.original?.description;

        return (
          <>
            <Typography
              sx={({ space }) => ({
                display: 'inline',
                marginRight: space.xs,
                maxWidth: '94%',
                verticalAlign: 'middle',
              })}
            >
              {getValue()}
            </Typography>

            {description && (
              <Tooltip title={description}>
                <IconHelp
                  sx={({ palette }) => ({
                    color: palette.text.secondary,
                    lineHeight: '24px',
                    verticalAlign: 'middle',
                  })}
                />
              </Tooltip>
            )}
          </>
        );
      },
      size: 360,
    },

    [CK.TOOL_CATEGORY]: {
      header: () => <FM defaultMessage="Tool Category" />,
      cell: (t) => {
        return <ToolCategoryDisplay category={t.getValue()} size="small" />;
      },
    },

    [CK.TOOLS_NAMES]: {
      header: () => <FM defaultMessage="Tools" />,
      cell: (t) => {
        const tools = (t.getValue() ?? []) as V1CiCdTool[];
        return <ToolNameArrayDisplay tools={tools} />;
      },
      size: 480,
    },

    [CK.TOOLS_LOGOS]: {
      header: (t) => (
        <Typography textAlign="center" variant="inherit" width="100%">
          {t.column.id}
        </Typography>
      ),
      cell: (t) => {
        const category = t.column.id;
        // @ts-expect-error - NOTE: Expects "description" key to exist in row data
        const { projectName, platformSource } = t.row.original;
        const tools = (t.getValue() ?? []) as V1CiCdTool[];
        return (
          <ToolLogoArrayDisplay
            category={category}
            platformSource={platformSource}
            projectName={projectName}
            tools={tools}
          />
        );
      },
      size: 480,
    },

    // Package version display. Can require more space than a standard text display.
    [CK.VERSION]: {
      header: () => <FM defaultMessage="Version" />,
      size: 160,
    },

    // A linked url
    [CK.URL]: {
      cell: (t) => {
        const url = t.getValue();
        return <Link to={url as string}>{url}</Link>;
      },
    },
  };
  return colOptions[colType];
};
