import { SecurityAttestationPatch } from '@endorlabs/api_client';

const REGEX_PATCH_PREFIX = /[-+]{3} [ab]\//;

type FileDiffStat = {
  diff: string;
  linesAdded: number;
  linesRemoved: number;
  nameNew?: string;
  nameOrig?: string;
};

export const parseSecurityAttestationPatch = (
  patch: SecurityAttestationPatch
): FileDiffStat[] => {
  const stats: FileDiffStat[] = [];

  for (const diff of patch.patch_files ?? []) {
    let linesAdded = 0;
    let linesRemoved = 0;
    let nameNew;
    let nameOrig;

    const lines = diff.split('\n');
    if (lines.length < 2) continue;

    // Find the index of the first section, and parse the metadata before it
    const firstSectionIx = lines.findIndex((line) => line.startsWith('@@'));
    const metadata = lines.splice(0, firstSectionIx);
    if (metadata.length > 2) {
      nameNew = metadata.pop()?.replace(REGEX_PATCH_PREFIX, '');
      nameOrig = metadata.pop()?.replace(REGEX_PATCH_PREFIX, '');
    }

    // Count the lines added/removed
    for (const line of lines) {
      if (line.startsWith('+')) {
        linesAdded++;
      } else if (line.startsWith('-')) {
        linesRemoved++;
      }
    }

    stats.push({ diff, linesAdded, linesRemoved, nameNew, nameOrig });
  }

  return stats;
};
