import { IconButton, Typography } from '@mui/material';
import { defineMessages, FormattedMessage as FM, useIntl } from 'react-intl';

import { IconChevronLeft, IconChevronRight } from '../../themes';
import { DataTablePaginator } from '../DataTable';
import { RowStack } from '../RowStack';

const messages = defineMessages({
  next: { defaultMessage: 'Next page' },
  previous: { defaultMessage: 'Previous page' },
  summary: {
    defaultMessage:
      '<b>{pageMin, number}-{pageMax, number}</b> of <b>{count, number, ::compact-short}</b>',
  },
});

interface SimplePaginationProps {
  paginator: DataTablePaginator;
}

/**
 * Generic pagination component
 */
export const SimplePagination = ({ paginator }: SimplePaginationProps) => {
  const { formatMessage: fm } = useIntl();
  const { pageCount } = paginator;
  const { pageIndex, pageSize, totalCount } = paginator.state;

  const hasPreviousPage = pageIndex > 0;
  const hasNextPage = pageIndex + 1 < pageCount;

  // calculate min and max indexes for items in this page
  const pageMin = Math.min(totalCount, pageSize * pageIndex + 1);
  const pageMax = Math.min(totalCount, pageSize * (pageIndex + 1));

  const gotoPreviousPage = () =>
    paginator.onPaginationChange((state) => ({
      ...state,
      pageIndex: state.pageIndex - 1,
    }));

  const gotoNextPage = () =>
    paginator.onPaginationChange((state) => ({
      ...state,
      pageIndex: state.pageIndex + 1,
    }));

  return (
    <RowStack gap={1}>
      <IconButton
        aria-label={fm(messages.previous)}
        disabled={!hasPreviousPage}
        onClick={gotoPreviousPage}
      >
        <IconChevronLeft />
      </IconButton>

      <Typography component="span" variant="body2" color="text.secondary">
        <FM
          {...messages.summary}
          values={{
            count: totalCount,
            pageMin,
            pageMax,
            b: (value) => (
              <Typography
                component="span"
                variant="inherit"
                color="text.primary"
              >
                {value}
              </Typography>
            ),
          }}
        />
      </Typography>
      <IconButton
        aria-label={fm(messages.next)}
        disabled={!hasNextPage}
        onClick={gotoNextPage}
      >
        <IconChevronRight />
      </IconButton>
    </RowStack>
  );
};
