import { defineMessage } from 'react-intl';

import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({
  defaultMessage: 'License Settings | Endor Labs',
});

export const Route = createFileRoute('/t/:namespace/settings/license')({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
});
