import { useGetProject } from '@endorlabs/queries';

import { DetailDrawerContainer } from '../../../../components/DetailDrawer';
import { InfoDrawerContentProps } from '../../../../components/InfoDrawer';
import { ProjectDetailDrawerContent } from './ProjectDetailDrawerContent';
import { ProjectDetailDrawerHeader } from './ProjectDetailDrawerHeader';

export interface ProjectDetailDrawerProps extends InfoDrawerContentProps {
  uuid: string;
  namespace: string;
}

export const ProjectDetailDrawer = ({
  uuid,
  namespace,
}: ProjectDetailDrawerProps) => {
  const qProject = useGetProject({ namespace, uuid });

  return (
    <DetailDrawerContainer
      headerProps={{
        title: (
          <ProjectDetailDrawerHeader
            isLoading={qProject.isLoading}
            namespace={namespace}
            name={qProject.data?.meta.name}
            platformSource={qProject.data?.spec.platform_source}
          />
        ),
      }}
    >
      <ProjectDetailDrawerContent
        uuid={uuid}
        project={qProject.data}
        isProjectLoading={qProject.isLoading}
        namespace={namespace}
      />
    </DetailDrawerContainer>
  );
};
