import { ScanProfileCreatePage } from '../../../../../views/Settings/ScanProfiles';
import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

const TenantScanProfileCreateRoute = () => {
  return <ScanProfileCreatePage />;
};

export const Route = createFileRoute(
  '/t/:namespace/settings/scan-profiles/new'
)({
  component: TenantScanProfileCreateRoute,
});
