import _uniqBy from 'lodash-es/uniqBy';

import { AggregatedRecords } from './getAggregatedRecordsFromGroupResponse';

const DEFAULT_DISPLAY_COUNT = 8;

export interface ChartDataPrimaryRecord {
  key: string;
  name: string;
  url?: string;
}

export interface InterimChartData {
  counts: Record<string, number>;
  primaryKey: string;
  total: number;
}

interface GenerateInterimChartData {
  // The attribute on categoryRecords used to determine individual subcategories
  categoryKeyAttr: string;
  // Map of all possible categories in the chart: categoryKeyAttr value => translated label
  categoryMessages: Map<string, string>;
  // Records representing the resources that will form the categories within each primary resource group.
  // Number of primary records to display
  primaryDisplayCount?: number;
  // The attribute on categoryGroupRecords used to key against uuid of the primary records.
  primaryKeyAttr: string;
  records: AggregatedRecords;
}

interface GenBarChartData {
  // Map of all possible categories in the chart: categoryKeyAttr value => translated label
  categoryMessages: Map<string, string>;
  // Records representing the category records
  interimChartData: InterimChartData[];
  // Records representing the resources the chart will be grouped by.
  // E.g. for a "Top Projects" chart, this is a list of Project records.
  primaryRecords: ChartDataPrimaryRecord[];
}

export const generateInterimChartData = ({
  categoryKeyAttr,
  categoryMessages,
  primaryDisplayCount = DEFAULT_DISPLAY_COUNT,
  primaryKeyAttr,
  records,
}: GenerateInterimChartData) => {
  // Transform into an interim object with only the top N primary records with the highest counts.
  // This prevents further transformations from operating on the entire list.
  const topNGroups = Object.entries(records)
    .map(([primaryKey, record]) => {
      const categoryCounts = record[categoryKeyAttr] ?? {};
      const total = Object.values(categoryCounts).reduce((t, v) => t + v, 0);

      return {
        counts: categoryCounts,
        primaryKey,
        total,
      };
    })
    // Sort by total count, then name.
    // TODO: support custom sort, to allow sorting by severity level
    .sort(
      (a, b) => b.total - a.total || a.primaryKey.localeCompare(b.primaryKey)
    )
    .slice(0, primaryDisplayCount);

  return topNGroups;
};

// Stitches interim chart data together with primary records.
// Returns data in form consumable by bar chart visualizations.
export const genBarChartData = ({
  categoryMessages,
  interimChartData = [],
  primaryRecords,
}: GenBarChartData) => {
  const chartData = interimChartData.reduce((arr: unknown[], group) => {
    const primaryRecord = primaryRecords.find(
      (p) => p.key === group.primaryKey
    );
    if (!primaryRecord) return arr;

    const hasSubcategories = categoryMessages.size > 1;
    const countsByTranslatedKeys: Record<string, number> = {};

    categoryMessages.forEach((translation, messageKey) => {
      countsByTranslatedKeys[translation] = hasSubcategories
        ? group.counts[messageKey] ?? 0
        : group.total;
    });

    return arr.concat([
      {
        name: primaryRecord?.name ?? 'Unknown',
        url: primaryRecord?.url ?? '',
        ...countsByTranslatedKeys,
      },
    ]);
  }, []);

  return _uniqBy(chartData, 'name');
};
