import { CircularProgress } from '@mui/material';
import { MakeGenerics, Navigate, useMatch } from '@tanstack/react-location';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListDependencyMetadata } from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  EmptyState,
  useResourceCRUDMessages,
} from '@endorlabs/ui-common';

import { useAuthInfo } from '../../providers/AuthInfo';
import { NamedRoutes } from '../constants';
import { getDependencyPath } from '../utils';

type DependencyRedirectByNameLocationGenerics = MakeGenerics<{
  Params: {
    dependencyPackageVersionName?: string;
  };
}>;

/**
 * Redirects to a DependencyMetadata uuid from the given Dependency package name
 *
 * NOTE: limited to dependencies under the MAIN context.
 */
export const DependencyRedirectByName = () => {
  const { getErrorMessage } = useResourceCRUDMessages();
  const { activeNamespace } = useAuthInfo();
  const { params } = useMatch<DependencyRedirectByNameLocationGenerics>();

  // NOTE: dependency name in url is encoded to prevent issues
  const dependencyPackageVersionName = params.dependencyPackageVersionName
    ? decodeURIComponent(params.dependencyPackageVersionName)
    : undefined;

  const qDependency = useListDependencyMetadata(
    activeNamespace,
    { enabled: !!activeNamespace && !!dependencyPackageVersionName },
    {
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.mainResourceContext(),
        `meta.name=="${dependencyPackageVersionName}"`,
      ]),
      page_size: 1, // get the first
    }
  );

  const dependencyPath = useMemo(() => {
    const dependency = qDependency.data?.list?.objects[0];

    if (!dependency) return;

    return getDependencyPath({
      tenantName: activeNamespace,
      uuid: dependency.uuid,
    });
  }, [activeNamespace, qDependency.data]);

  const errorMessage = useMemo(() => {
    return qDependency.isError
      ? getErrorMessage('GET', 'DependencyMetadata', qDependency.error)
      : undefined;
  }, [getErrorMessage, qDependency.error, qDependency.isError]);

  if (qDependency.isLoading) {
    return (
      <EmptyState title={<FM defaultMessage="Loading" />} size="large">
        <CircularProgress />;
      </EmptyState>
    );
  }

  if (qDependency.isSuccess && dependencyPath) {
    return <Navigate to={dependencyPath} />;
  }

  if (errorMessage) {
    return (
      <EmptyState
        title={errorMessage?.message}
        description={errorMessage?.details}
      >
        <ButtonLinkPrimary linkProps={{ to: NamedRoutes.TENANTS_INDEX }}>
          <FM defaultMessage="Return Home" />
        </ButtonLinkPrimary>
      </EmptyState>
    );
  }

  return (
    <EmptyState
      title={<FM defaultMessage="Unable to Find Dependency" />}
      description={
        <FM
          defaultMessage="We were unable to find the dependency `{packageName}`. Please try again or contact Endor Labs for support."
          values={{ packageName: dependencyPackageVersionName }}
        />
      }
    >
      <ButtonLinkPrimary linkProps={{ to: NamedRoutes.TENANTS_INDEX }}>
        <FM defaultMessage="Return Home" />
      </ButtonLinkPrimary>
    </EmptyState>
  );
};
