import { SvgIcon, SvgIconProps } from '@mui/material';
import { JSXElementConstructor, ReactNode } from 'react';

import {
  SpecEndorLicenseFeatureType,
  SpecEndorLicenseInfo,
} from '@endorlabs/api_client';

export enum DisplayStatus {
  Show = 'show',
  Deactivate = 'deactivate',
  Hide = 'hide',
}

export type LicenseInfoType =
  (typeof SpecEndorLicenseFeatureType)[keyof typeof SpecEndorLicenseFeatureType];

export interface AppDrawerNavItemProps {
  appDrawerMinimized?: boolean;
  badgeContent?: ReactNode;
  Icon: typeof SvgIcon | JSXElementConstructor<SvgIconProps>;
  isActive?: boolean;
  isBeta?: boolean;
  navKey: string;
  label: ReactNode;
  url: string;
  displayStatus?: DisplayStatus;
}

export interface NavItemsContextProps {
  licenseInfo: SpecEndorLicenseInfo[] | undefined;
  hasTenants: boolean;
  isCiCdToolsEnabled: boolean;
  isLicenseAndBundlingEnabled: boolean;
  isPremiumTenant: boolean;
  isRemediationsEnabled: boolean;
  isServiceRequestEnabled: boolean;
  isSharedTenant: boolean;
  lastUsedTenant: string | undefined;
}
