import { SvgIconProps } from '@mui/material';
import { JSXElementConstructor } from 'react';

import { V1ChangeType, V1Confidence } from '@endorlabs/api_client';
import {
  IconArrowRight,
  IconEdit,
  IconFileText,
  IconMinus,
  IconPlusSquare,
  IconSquare,
  IconTrash,
  IconTrendingDown,
  IconTrendingUp,
} from '@endorlabs/ui-common';

export const RemediationRiskColorMap: Record<
  string,
  'error' | 'info' | 'success' | 'warning'
> = {
  low: 'success',
  medium: 'info',
  high: 'error',
} as const;

export const VersionUpgradeTableSortKeys: Record<string, string> = {
  dependencyName: 'spec.upgrade_info.direct_dependency_package',
  findingCounts: 'spec.upgrade_info.total_findings_fixed',
  packageVersionName: 'spec.upgrade_info.root_package_version',
  projectName: 'spec.upgrade_info.project',
  remediationRisk: 'spec.upgrade_info.upgrade_risk',
  score: 'spec.upgrade_info.score',
  versionAge: 'spec.upgrade_info.from_version_publish_time',
  versionUpgrade: 'spec.upgrade_info.from_version',
} as const;

export const BreakingChangeTypeIconMap: Record<
  V1ChangeType,
  JSXElementConstructor<SvgIconProps>
> = {
  [V1ChangeType.Added]: IconPlusSquare,
  [V1ChangeType.Changed]: IconEdit,
  [V1ChangeType.Removed]: IconTrash,
  [V1ChangeType.Renamed]: IconFileText,
  [V1ChangeType.Unspecified]: IconSquare,
};

export const BreakingConfidenceColorMap: Record<
  V1Confidence,
  'default' | 'error' | 'info' | 'success' | 'warning'
> = {
  [V1Confidence.High]: 'error',
  [V1Confidence.Medium]: 'warning',
  [V1Confidence.Low]: 'info',
  [V1Confidence.Unknown]: 'default',
  [V1Confidence.Unspecified]: 'default',
} as const;

export const BreakingConfidenceIconMap: Record<
  V1Confidence,
  JSXElementConstructor<SvgIconProps>
> = {
  [V1Confidence.Low]: IconTrendingDown,
  [V1Confidence.Medium]: IconArrowRight,
  [V1Confidence.High]: IconTrendingUp,
  [V1Confidence.Unknown]: IconMinus,
  [V1Confidence.Unspecified]: IconMinus,
} as const;

export const BreakingChangeConfidenceOrder: Record<V1Confidence, number> = {
  [V1Confidence.High]: 1,
  [V1Confidence.Medium]: 2,
  [V1Confidence.Low]: 3,
  [V1Confidence.Unknown]: 4,
  [V1Confidence.Unspecified]: 5,
};

export const BreakingChangeTypeOrder: Record<V1ChangeType, number> = {
  [V1ChangeType.Removed]: 1,
  [V1ChangeType.Changed]: 2,
  [V1ChangeType.Renamed]: 3,
  [V1ChangeType.Added]: 4,
  [V1ChangeType.Unspecified]: 5,
};

export enum RemediationRecommendationType {
  Patch = 'Patch',
  Upgrade = 'Upgrade',
}
