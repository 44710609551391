import { getEnv } from '../../constants/Env';

const ENV = getEnv();

export const NamedRoutes = {
  AUTH_GITHUB: `${ENV.API_BASE_URL}/v1/auth/github`, // Backend route to initiate GitHub OAuth
  AUTH_GITLAB: `${ENV.API_BASE_URL}/v1/auth/gitlab`, // Backend route to initiate GitLab OAuth
  AUTH_GOOGLE: `${ENV.API_BASE_URL}/v1/auth/google`, // Backend route to initiate Google OAuth
  AUTH_AZURE: `${ENV.API_BASE_URL}/v1/auth/azureadv2`, // Backend route to initiate Azure/Microsoft OAuth
  AUTH_OIDC: `${ENV.API_BASE_URL}/v1/auth/oidc`, // Backend route to initiate OIDC Provider
  AUTH_SSO: `${ENV.API_BASE_URL}/v1/auth/sso`, // Backend route to initiate SSO providers (OIDC or SAML)
  AUTH_LOGIN: `${ENV.API_BASE_URL}/v1/auth/login`,
  AUTH_LOGOUT: `${ENV.API_BASE_URL}/v1/auth/:authProvider/logout`, // Terminate session
  DOCS: ENV.URL_ENDOR_DOCS,
  DOCS_CUSTOM_TEMPLATE_PR_COMMENT: `${ENV.URL_ENDOR_DOCS}/scan-with-endorlabs/integrating-into-ci/scan-with-github-actions/#customize-github-pr-comments-notification-templates`,
  DOCS_CUSTOM_TEMPLATE_SLACK: `${ENV.URL_ENDOR_DOCS}/integrations/slack-integration/#customize-slack-notification-templates`,
  DOCS_CUSTOM_TEMPLATE_WEBHOOK: `${ENV.URL_ENDOR_DOCS}/integrations/webhooks/#customize-webhook-notification-templates`,
  DOCS_CUSTOM_TEMPLATE_EMAIL: `${ENV.URL_ENDOR_DOCS}/integrations/email/#customize-email-notification-templates`,
  DOCS_ENDORCTL: `${ENV.URL_ENDOR_DOCS}/endorctl`,
  DOCS_GITHUB_APP: `${ENV.URL_ENDOR_DOCS}/integrations/github-app/`,
  DOCS_REMEDIATIONS: `${ENV.URL_ENDOR_DOCS}/remediations`,
  DOCS_PRICING: `${ENV.URL_ENDOR_MARKETING}/pricing`,
  DOCS_RELEASENOTES: `${ENV.URL_ENDOR_DOCS}/releasenotes/`,
  ENDORCTL_INIT: '/endorctl-success',
  ERROR_PAGE: '/page-error', // All-purpose error page
  INSTALL_SUCCESS: '/install-success', // Redirect from authorizing Endor platform app
  INVITATION_ACCEPT: '/invitation-accept/:namespace',
  LOGIN: '/login', // Dedicated login page
  LOGIN_SUCCESS: '/login-success', // Redirect from external OAuth success
  LOGIN_ERROR: '/login-error',
  NO_ACCOUNT: '/no-account',
  OSS_EXPLORER: '/oss-explorer', // OSS Explorer page
  PUBLIC_HOME: '/', // Public-facing home page
  REDIRECT_DEFAULT_TENANT: '/redirect/t', // Redirect to the given path in the Authenticated User's default tenant
  REDIRECT_DOCS: '/redirect/docs', // Redirect route to the Endor Docs site
  SIGNUP: '/signup', // Dedicated signup page
  TENANTS_INDEX: '/t',
  TENANT_CREATE: '/tenant-create',
} as const;

/**
 * Established search parameters used in redirect logic
 */
export const RedirectParams: Record<string, string> = {
  'install-success': NamedRoutes.INSTALL_SUCCESS,
  'login-success': NamedRoutes.LOGIN_SUCCESS,
} as const;
