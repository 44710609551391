import { Stack, Typography } from '@mui/material';
import { addDays } from 'date-fns/esm';
import { ReactNode, useMemo } from 'react';
import { FormattedDate, FormattedMessage as FM } from 'react-intl';

import { V1SystemRole } from '@endorlabs/api_client';
import { PermissionRoleLabel } from '@endorlabs/ui-common';

export const PERMISSIONS = [
  {
    id: V1SystemRole.Admin,
    label: <PermissionRoleLabel role={V1SystemRole.Admin} />,
    value: V1SystemRole.Admin,
  },
  {
    id: V1SystemRole.ReadOnly,
    label: <PermissionRoleLabel role={V1SystemRole.ReadOnly} />,
    value: V1SystemRole.ReadOnly,
  },
  {
    id: V1SystemRole.CodeScanner,
    label: <PermissionRoleLabel role={V1SystemRole.CodeScanner} />,
    value: V1SystemRole.CodeScanner,
  },
  {
    id: V1SystemRole.PolicyEditor,
    label: <PermissionRoleLabel role={V1SystemRole.PolicyEditor} />,
    value: V1SystemRole.PolicyEditor,
  },
];

const getExpirationFieldOptions = (offsets: number[]) => {
  const now = Date.now();

  return offsets.map((offset) => {
    const value = addDays(now, offset).toISOString();

    return {
      id: `EXPIRATIONS_${offset}_DAYS`,
      value,
      label: (
        <Stack direction="row" spacing={1}>
          <Typography component="span">
            <FM defaultMessage="{offset} Days" values={{ offset }} />
          </Typography>
          <Typography component="span" color="text.secondary">
            &middot;
          </Typography>
          <Typography component="span" color="text.secondary">
            <FormattedDate value={value} dateStyle="medium" />
          </Typography>
        </Stack>
      ),
    };
  });
};

export interface ApiKeyFieldOption {
  id: string;
  value: string;
  label: ReactNode;
}

/**
 * Utility to generate the field options for the API Key form
 */
export const useApiKeyFieldOptions = (): Record<
  string,
  ApiKeyFieldOption[]
> => {
  const expirations = useMemo(() => {
    return getExpirationFieldOptions([30, 60, 90]);
  }, []);

  return { permissions: PERMISSIONS, expirations };
};
