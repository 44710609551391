import { useMutation, useQueryClient } from 'react-query';

import { AnyResourceType, ResourceKind } from '@endorlabs/endor-core';

import { ResourceMutateOptions } from '../types';
import {
  buildResourceMutateMeta,
  createResourceQueryKeyBuilders,
} from '../utils';

type ResourceCreateParams<T extends AnyResourceType> = {
  namespace: string;
  resource: Omit<T, 'uuid'>;
};
type ResourceCreateOptions<T extends AnyResourceType> = ResourceMutateOptions<
  T,
  ResourceCreateParams<T>
>;

type ResourceDeleteParams<T extends AnyResourceType> = {
  namespace: string;
  resource: Pick<T, 'uuid'>;
};
type ResourceDeleteOptions<T extends AnyResourceType> = ResourceMutateOptions<
  void,
  ResourceDeleteParams<T>
>;

type ResourceUpdateOptions<T extends AnyResourceType> = ResourceMutateOptions<
  T,
  ResourceUpdateParams<T>
>;
type ResourceUpdateParams<T extends AnyResourceType> = {
  namespace: string;
  resource: T;
  mask?: string;
};

export type MutateServiceRequestOptions = { signal?: AbortSignal };

export interface ResourceMutateService<T extends AnyResourceType> {
  create(
    namespace: string,
    resource: Omit<T, 'uuid'>,
    options?: MutateServiceRequestOptions
  ): Promise<T>;
  delete(
    namespace: string,
    resource: Pick<T, 'uuid'>,
    options?: MutateServiceRequestOptions
  ): Promise<void>;
  update(
    namespace: string,
    params: { resource: T; mask?: string },
    options?: MutateServiceRequestOptions
  ): Promise<T>;
}

export const createResourceMutateHooks = <T extends AnyResourceType>(
  kind: ResourceKind,
  serviceFactory: () => ResourceMutateService<T>
) => {
  const qk = createResourceQueryKeyBuilders(kind);
  const service = serviceFactory();

  const useCreate = (opts: ResourceCreateOptions<T> = {}) => {
    const queryClient = useQueryClient();

    return useMutation({
      ...opts,
      meta: buildResourceMutateMeta('CREATE', kind),
      mutationFn: (params) => service.create(params.namespace, params.resource),
      onSettled: (data, error, vars, context) => {
        if (data && !error) {
          // On success, invalidate cache
          queryClient.invalidateQueries(qk.list(vars.namespace));
        }

        // Honor existing callback
        if (opts.onSettled) {
          opts.onSettled(data, error, vars, context);
        }
      },
    });
  };

  const useDelete = (opts: ResourceDeleteOptions<T> = {}) => {
    const queryClient = useQueryClient();

    return useMutation({
      ...opts,
      meta: buildResourceMutateMeta('DELETE', kind),
      mutationFn: (params) => service.delete(params.namespace, params.resource),
      onSettled: (data, error, vars, context) => {
        if (!error) {
          // On success, invalidate cache
          queryClient.invalidateQueries(qk.list(vars.namespace));
          queryClient.invalidateQueries(
            qk.record(vars.namespace, vars.resource.uuid)
          );
        }

        // Honor existing callback
        if (opts.onSettled) {
          opts.onSettled(data, error, vars, context);
        }
      },
    });
  };

  const useUpdate = (opts: ResourceUpdateOptions<T> = {}) => {
    const queryClient = useQueryClient();

    return useMutation({
      ...opts,
      meta: buildResourceMutateMeta('UPDATE', kind),
      mutationFn: ({ namespace, ...params }) =>
        service.update(namespace, params),
      onSettled: (data, error, vars, context) => {
        if (data && !error) {
          // On success, invalidate cache
          queryClient.invalidateQueries(qk.list(vars.namespace));
          if (vars.resource.uuid) {
            queryClient.invalidateQueries(
              qk.record(vars.namespace, vars.resource.uuid)
            );
          }
        }

        // Honor existing callback
        if (opts.onSettled) {
          opts.onSettled(data, error, vars, context);
        }
      },
    });
  };

  return { useCreate, useDelete, useUpdate };
};
