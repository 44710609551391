import { Box, Card, Stack, Theme, Typography, useTheme } from '@mui/material';
import { uniq as _uniq } from 'lodash-es';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders as FB } from '@endorlabs/filters';
import {
  ButtonPrimary,
  RowStack,
  SimplePagination,
  useStyles,
} from '@endorlabs/ui-common';

import { Layout } from '../../../constants';
import { FilterBar, useFilterContext } from '../../filters';
import { REMEDIATIONS_FILTER_FIELDS } from '../constants';
import { useRemediationsExportDialog } from '../hooks';
import { useRemediationsByDependency } from '../hooks/useRemediationsByDependency';
import { RemediationsGroupAccordion } from './RemediationsGroupAccordion';
import { RemediationsGroupAccordionTitleNode } from './RemediationsGroupAccordionTitleNode';

const colHeadingMessages = defineMessages({
  dependency: { defaultMessage: 'Dependency' },
  package: { defaultMessage: 'Affected Package' },
  version: { defaultMessage: 'Current Version' },
  patch: { defaultMessage: 'Endor Magic Patch Available' },
  findings: { defaultMessage: 'Fixed Vulnerabilities' },
});

const COL_HEADINGS = [
  colHeadingMessages.dependency,
  colHeadingMessages.package,
  colHeadingMessages.version,
  colHeadingMessages.patch,
  colHeadingMessages.findings,
];

export type RemediationsAggregatedViewProps = {
  namespace: string;
} & (
  | {
      packageVersion?: never;
      project?: ProjectResource;
      projectVersion?: ProjectVersionResource;
    }
  | {
      packageVersion?: PackageVersionResource;
      project?: never;
      projectVersion?: never;
    }
);

export const RemediationsAggregatedView = ({
  namespace,
  packageVersion,
  project,
  projectVersion,
}: RemediationsAggregatedViewProps) => {
  const { space } = useTheme();
  const sx = useStyles(styles);

  const { filter } = useFilterContext();
  const { paginator, remediationGroups } = useRemediationsByDependency({
    namespace,
    packageVersion,
    project,
    projectVersion,
  });

  const remediationsExportDialog = useRemediationsExportDialog();
  const handleExport = () => {
    // Limit export to upgrades contained on the page
    // FIXME: This might not include patches
    const uuidsFilter = _uniq(
      remediationGroups.reduce((acc: string[], remediationGroup) => {
        return acc.concat(remediationGroup.uuids);
      }, [])
    ).join(',');

    const uuidsFilterExpression = `uuid in [${uuidsFilter}]`;

    const exportFilter = filter
      ? FB.and([filter, uuidsFilterExpression])
      : uuidsFilterExpression;

    remediationsExportDialog.openDialog({
      downloadProps: {
        filename: `project_${project?.uuid}_remediations-export.csv`,
      },
      filter: exportFilter,
      namespace,
    });
  };

  return (
    <>
      <Card className="RemediationsAggregatedView-root" sx={sx}>
        <Stack className="RemediationsAggregatedView-header">
          <FilterBar fields={REMEDIATIONS_FILTER_FIELDS} isCard={false} />
          <RowStack gap={space.sm} justifyContent="flex-end">
            <SimplePagination
              paginator={paginator}
              resourceLabel="Remediation"
              resourceLabelPlural="Remediations"
              verbose
            />

            <ButtonPrimary onClick={handleExport}>
              <FM defaultMessage="Export All" />
            </ButtonPrimary>
          </RowStack>
        </Stack>

        <Box className="RemediationsAggregatedView-columnHeadings">
          {COL_HEADINGS.map((headingMsg, index) => (
            <Typography key={index} variant="h6">
              <FM {...headingMsg} />
            </Typography>
          ))}
        </Box>

        <Box>
          {remediationGroups.map((remediationGroup) => (
            <RemediationsGroupAccordion
              actionList={[]}
              id={remediationGroup.filter}
              key={remediationGroup.filter}
              {...remediationGroup}
              namespace={namespace}
              remediationUuids={remediationGroup.uuids}
              titleNode={
                <RemediationsGroupAccordionTitleNode
                  {...remediationGroup}
                  packageVersionName={
                    remediationGroup.packageVersionName as string
                  }
                />
              }
            />
          ))}
        </Box>
      </Card>
      <remediationsExportDialog.Dialog
        {...remediationsExportDialog.getDialogProps()}
      />
    </>
  );
};

function styles({ palette, space, spacing, typography }: Theme) {
  const accordionOffset = 6;
  const arrowButtonOffset = 1;

  const columnSpacing = '30% 25% 10% 10% 1fr';
  const columnAttrs = {
    columnGap: space.sm,
    display: 'grid',
    gridTemplateColumns: columnSpacing,
  };

  return {
    '& .RemediationsAggregatedView-columnHeadings': {
      ...columnAttrs,
      borderBottom: `1px solid ${palette.divider}`,
      padding: `${space.sm} ${space.md} ${space.xs}`,
      paddingLeft: spacing(
        Layout.CARD_PADDING + accordionOffset + arrowButtonOffset
      ),

      '& .MuiTypography-h6': {
        fontSize: typography.body2.fontSize,
      },
    },

    '& .RemediationsAggregatedView-header': {
      padding: `${spacing(4)} ${spacing(Layout.CARD_PADDING)} ${spacing(5)}`,
      gap: space.sm,
    },

    '& .DrawerAccordion-root': {
      borderBottom: `1px solid ${palette.divider}`,
      paddingX: spacing(Layout.CARD_PADDING),
    },

    '& .RemediationsGroupAccordionTitleNode-root': {
      ...columnAttrs,
    },
  };
}
