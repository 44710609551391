import { Box, Typography } from '@mui/material';
import produce from 'immer';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ProjectResource } from '@endorlabs/endor-core/Project';
import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import { useListProjects, useUpdateProject } from '@endorlabs/queries';
import {
  BulkActionRecord,
  CommonDataTableRowActions,
  DataTableActionItem,
  DataTableColumnDef,
  DataTableView,
  useDialog,
} from '@endorlabs/ui-common';

import { AddScanProfileProjectDialog } from '../../../domains/ScanProfiles';

const SCAN_PROFILE_PROJECT_COLUMNS: DataTableColumnDef<ProjectResource>[] = [
  {
    accessorFn: (p) => p.meta.name,
    id: 'name',
    header: () => <FM defaultMessage="Project" />,
  },
];

export const ScanProfileDetailProjectsView = ({
  scanProfile,
}: {
  scanProfile: ScanProfileResource;
}) => {
  const scanProfileNamespace = scanProfile.tenant_meta.namespace;
  const qListProjects = useListProjects(
    scanProfileNamespace,
    {},
    {
      filter: `spec.scan_profile_uuid=="${scanProfile.uuid}"`,
      mask: ['meta.name', 'spec.platform_source', 'tenant_meta', 'uuid'].join(
        ','
      ),
      traverse: false,
    }
  );

  const projects = useMemo(() => {
    return qListProjects.data?.list?.objects ?? [];
  }, [qListProjects.data?.list?.objects]);

  const qUpdateProject = useUpdateProject();

  const handleAddScanProfile = (project: ProjectResource) => {
    const updated = produce(project, (p) => {
      p.spec.scan_profile_uuid = scanProfile.uuid;
    });

    qUpdateProject.mutate({
      namespace: project.tenant_meta.namespace,
      resource: updated,
      mask: 'spec.scan_profile_uuid',
    });

    addScanProfileProjectDialog.closeDialog();
  };

  const handleRemoveScanProfile = (project: ProjectResource) => {
    const updated = produce(project, (p) => {
      p.spec.scan_profile_uuid = undefined;
    });

    return qUpdateProject.mutateAsync({
      namespace: project.tenant_meta.namespace,
      resource: updated,
      mask: 'spec.scan_profile_uuid',
    });
  };

  const handleRemoveScanProfileBulk = async (projects: ProjectResource[]) => {
    for (const p of projects) {
      await handleRemoveScanProfile(p);
    }
  };

  const bulkActions: BulkActionRecord<ProjectResource>[] = [
    {
      actionId: 'add',
      label: <FM defaultMessage="Add Projects" />,
      isSelectionRequired: false,
      onApply: () =>
        addScanProfileProjectDialog.openDialog({
          scanProfile,
          onSubmit: handleAddScanProfile,
        }),
    },
    {
      actionId: 'delete',
      label: (
        <Typography color="error" component="span" variant="inherit">
          <FM defaultMessage="Delete Projects" />
        </Typography>
      ),
      isSelectionRequired: true,
      onApply: handleRemoveScanProfileBulk,
    },
  ];

  const rowActions: DataTableActionItem<ProjectResource>[] = [
    {
      ...CommonDataTableRowActions.DELETE_RESOURCE,
      onClick: handleRemoveScanProfile,
    },
  ];

  const addScanProfileProjectDialog = useDialog({
    component: AddScanProfileProjectDialog,
  });

  return (
    <Box paddingTop={4}>
      <DataTableView
        bulkActions={bulkActions}
        columns={SCAN_PROFILE_PROJECT_COLUMNS}
        data={projects}
        emptyStateProps={{
          title: (
            <FM defaultMessage="No projects have been added to this scan profile" />
          ),
        }}
        namespace={scanProfileNamespace}
        rowActions={rowActions}
      />

      <addScanProfileProjectDialog.Dialog
        {...addScanProfileProjectDialog.dialogProps}
      />
    </Box>
  );
};
