import { Box } from '@mui/material';

import { FindingCount } from '@endorlabs/queries';
import {
  FindingCountArrayDisplay,
  IconCheck,
  IconX,
  PackageVersionNameDisplay,
  Pip,
  RowStack,
} from '@endorlabs/ui-common';

interface RemediationsGroupAccordionTitleNodeProps {
  dependencyPackageVersionName: string;
  findingCounts?: FindingCount[];
  hasPatch?: boolean;
  packageVersionName: string;
  version: string;
}

export const RemediationsGroupAccordionTitleNode = ({
  dependencyPackageVersionName,
  findingCounts,
  hasPatch = false,
  packageVersionName,
  version,
}: RemediationsGroupAccordionTitleNodeProps) => {
  return (
    <RowStack
      className="RemediationsGroupAccordionTitleNode-root"
      justifyContent="space-between"
      width="100%"
    >
      <PackageVersionNameDisplay
        packageVersion={{
          meta: { name: dependencyPackageVersionName },
        }}
        size="medium"
      />

      <PackageVersionNameDisplay
        packageVersion={{ meta: { name: packageVersionName } }}
        size="medium"
      />

      <Box>
        <Pip label={version} variant="outlined" color="warning" />
      </Box>

      {hasPatch ? <IconCheck color="success" /> : <IconX color="error" />}

      <FindingCountArrayDisplay value={findingCounts} />
    </RowStack>
  );
};
