import { Alert, AlertTitle, Stack, useTheme } from '@mui/material';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorOption, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  NotificationTargetActionActionType,
  V1NotificationTarget,
} from '@endorlabs/api_client';
import {
  IQueryErrorResponse,
  NotificationTargetResource,
} from '@endorlabs/queries';
import {
  ButtonPrimary,
  ControlledTextField,
  IconAlertTriangle,
  NotificationTargetActionTypeMessages,
} from '@endorlabs/ui-common';

import { EmailActionTypeFields } from '../EmailActionTypeFields';
import { JiraActionTypeFields } from '../JiraActionTypeFields';
import { SlackActionTypeFields } from '../SlackActionTypeFields';
import { VantaActionTypeFields } from '../VantaTypeFields';
import { WebhookActionTypeFields } from '../WebhookTypeFields';
import {
  FormNotificationDirtyValues,
  FormUpsertNotificationTargetFieldValues,
} from './types';
import { useNotificationTargetValidator } from './useNotificationTargetValidator';
import {
  fromNotificationTargetResourceModel,
  getNotificationTargetDefaultValues,
  toNotificationTargetResourceModel,
  translateServerError,
} from './utils';

export interface FormUpsertNotificationTargetProps {
  isLoading?: boolean;
  /**
   * Existing Notification Target to update. Must be memoized.
   */
  notificationTarget?: NotificationTargetResource;
  /**
   * Action type to create Notification Target for.
   */
  notificationTargetActionType: NotificationTargetActionActionType;
  /**
   * Namespace to create Notification Target in.
   */
  notificationTargetNamespace: string;
  onSubmit: (
    data: V1NotificationTarget,
    dirtyFields: FormNotificationDirtyValues
  ) => void;
  serverErrorResponse?: IQueryErrorResponse;
}

/**
 * Form used to create or edit an Notification Target
 */
export const FormUpsertNotificationTarget = ({
  isLoading,
  notificationTarget,
  notificationTargetActionType,
  notificationTargetNamespace,
  onSubmit,
  serverErrorResponse,
}: FormUpsertNotificationTargetProps) => {
  const theme = useTheme();
  const { formatMessage: fm } = useIntl();
  const { validate } = useNotificationTargetValidator();

  const defaultNotificationValues = useMemo(
    () =>
      getNotificationTargetDefaultValues(
        notificationTargetNamespace,
        notificationTargetActionType
      ),
    [notificationTargetNamespace, notificationTargetActionType]
  );
  const formMethods = useForm<FormUpsertNotificationTargetFieldValues>({
    defaultValues: defaultNotificationValues,
  });
  const {
    control,
    handleSubmit: hookFormSubmit,
    reset,
    setError,
    formState: { dirtyFields },
  } = formMethods;

  const isEdit = Boolean(notificationTarget);

  // reset the form with defaultValues
  useEffect(() => {
    if (notificationTarget) {
      const defaultValues =
        fromNotificationTargetResourceModel(notificationTarget);
      reset(defaultValues);
    } else {
      reset(defaultNotificationValues);
    }
  }, [notificationTarget, defaultNotificationValues, reset]);

  // manage server errors
  const [localError, setLocalError] = useState<ErrorOption | undefined>();
  useEffect(() => {
    if (serverErrorResponse) {
      const { field, object } = translateServerError(serverErrorResponse);
      if (field) {
        setError(
          field as keyof FormUpsertNotificationTargetFieldValues,
          object
        );
      } else {
        setLocalError(object);
      }
    } else {
      setLocalError(undefined);
    }
  }, [serverErrorResponse, setError]);

  const handleSubmit = useCallback(
    (fieldValues: FormUpsertNotificationTargetFieldValues) => {
      // Handle errors from validation
      const { errors } = validate(fieldValues);
      const errorEntries = Object.entries(errors);
      if (errorEntries.length) {
        for (const [name, error] of errorEntries) {
          setError(
            name as keyof FormUpsertNotificationTargetFieldValues,
            error
          );
        }
        return;
      }

      const model = toNotificationTargetResourceModel(
        notificationTargetNamespace,
        notificationTargetActionType,
        fieldValues,
        notificationTarget
      );
      onSubmit(model, dirtyFields);
    },
    [
      validate,
      notificationTargetNamespace,
      notificationTargetActionType,
      notificationTarget,
      onSubmit,
      dirtyFields,
      setError,
    ]
  );

  const actionTypeLabel = fm(
    NotificationTargetActionTypeMessages[notificationTargetActionType]
  );

  const hasChanges = !_isEmpty(dirtyFields);

  return (
    <FormProvider {...formMethods}>
      <form
        id="FormUpsertAuthorizationPolicy"
        onSubmit={hookFormSubmit(handleSubmit)}
        noValidate
      >
        <Stack direction="column" spacing={theme.space.md}>
          <ControlledTextField
            control={control}
            defaultValue=""
            label={fm({ defaultMessage: 'Name' })}
            placeholder={fm(
              {
                defaultMessage: 'Name this {actionType} integration',
              },
              { actionType: actionTypeLabel }
            )}
            name="meta.name"
            required
            rules={{
              minLength: {
                value: 1,
                message: fm({ defaultMessage: 'Name is required' }),
              },
            }}
            InputLabelProps={{ shrink: true }}
          />

          <ControlledTextField
            control={control}
            defaultValue=""
            name="meta.description"
            label={fm({ defaultMessage: 'Description' })}
            placeholder={fm(
              {
                defaultMessage:
                  'Describe the purpose of this {actionType} integration',
              },
              { actionType: actionTypeLabel }
            )}
          />

          {/* Handle specific fields per action type */}
          {notificationTargetActionType ===
            NotificationTargetActionActionType.Email && (
            <EmailActionTypeFields />
          )}
          {notificationTargetActionType ===
            NotificationTargetActionActionType.Jira && <JiraActionTypeFields />}
          {notificationTargetActionType ===
            NotificationTargetActionActionType.Slack && (
            <SlackActionTypeFields />
          )}
          {notificationTargetActionType ===
            NotificationTargetActionActionType.Vanta && (
            <VantaActionTypeFields />
          )}

          {notificationTargetActionType ===
            NotificationTargetActionActionType.Webhook && (
            <WebhookActionTypeFields />
          )}

          <Stack direction="row" spacing={theme.space.sm}>
            <ButtonPrimary type="submit" disabled={isLoading || !hasChanges}>
              {isEdit ? (
                <FM defaultMessage="Update Notification Integration" />
              ) : (
                <FM defaultMessage="Add Notification Integration" />
              )}
            </ButtonPrimary>
          </Stack>

          {localError && (
            <Alert severity="error" icon={<IconAlertTriangle />}>
              <AlertTitle>{localError.message}</AlertTitle>
            </Alert>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
