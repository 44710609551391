import { FormattedMessage as FM } from 'react-intl';

import { ContextContextType } from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  DependencyMetadataResource,
  PackageVersionResource,
  useCountDependencyMetadata,
  useCountFindings,
} from '@endorlabs/queries';
import { LinkTabCountValue, LinkTabProps } from '@endorlabs/ui-common';

import { FIFTEEN_MINUTES_IN_MILLISECONDS } from '../../constants';

interface DependencyDetailTabRecord extends LinkTabProps {
  value: typeof DependencyDetailTabNames[keyof typeof DependencyDetailTabNames];
}

export const DependencyDetailTabNames = {
  OVERVIEW: 'overview',
  DEPENDENCIES: 'dependencies',
  DEPENDENTS: 'dependents',
  FINDINGS: 'findings',
  DEP_GRAPH: 'dep-graph',
  // COMPARE: 'compare',
} as const;

const buildTabRecords = (props: {
  packagesCount?: number;
  dependenciesCount?: number | LinkTabCountValue;
  dependenciesIsLoading: boolean;
  dependentsCount?: number;
  dependentsIsLoading: boolean;
  findingsCount?: number;
  findingsIsLoading: boolean;
}) => {
  const tabRecords: DependencyDetailTabRecord[] = [
    {
      label: <FM defaultMessage="Overview" />,
      to: DependencyDetailTabNames.OVERVIEW,
      value: DependencyDetailTabNames.OVERVIEW,
    },
    {
      label: <FM defaultMessage="Findings" />,
      to: DependencyDetailTabNames.FINDINGS,
      value: DependencyDetailTabNames.FINDINGS,
      count: props.findingsCount,
      isLoading: props.findingsIsLoading,
    },
    {
      label: <FM defaultMessage="Dependents" />,
      to: DependencyDetailTabNames.DEPENDENTS,
      value: DependencyDetailTabNames.DEPENDENTS,
      count: props.dependentsCount,
      isLoading: props.dependentsIsLoading,
    },
    {
      label: <FM defaultMessage="Dependencies" />,
      to: DependencyDetailTabNames.DEPENDENCIES,
      value: DependencyDetailTabNames.DEPENDENCIES,
      count: props.dependenciesCount,
      isLoading: props.dependenciesIsLoading,
    },
    {
      label: <FM defaultMessage="Dep Graph" />,
      to: DependencyDetailTabNames.DEP_GRAPH,
      value: DependencyDetailTabNames.DEP_GRAPH,
    },
    // {
    //   label: <FM defaultMessage="Compare" />,
    //   to: DependencyDetailTabNames.COMPARE,
    //   value: DependencyDetailTabNames.COMPARE,
    // },
  ];

  return tabRecords;
};

export interface UseDependencyDetailTabsProps {
  activeTab: string;
  dependency?: DependencyMetadataResource;
  dependencyPackageVersion?: PackageVersionResource;
  namespace: string;
}

export const useDependencyDetailTabs = ({
  activeTab: targetActiveTab,
  dependency,
  dependencyPackageVersion,
  namespace,
}: UseDependencyDetailTabsProps) => {
  const staleTime = FIFTEEN_MINUTES_IN_MILLISECONDS;

  const dependencyPackageVersionUuid =
    dependency?.spec.dependency_data?.package_version_uuid;

  const qCountPackageVersionDependencies = useCountDependencyMetadata(
    dependencyPackageVersion?.tenant_meta.namespace as string,
    {
      enabled: !!dependencyPackageVersion,
    },
    {
      filter: [
        `spec.importer_data.package_version_uuid==${dependencyPackageVersion?.uuid}`,
        `context.type in [${[
          ContextContextType.Main,
          ContextContextType.Sbom,
        ]}]`,
      ].join(' and '),
    }
  );

  const qCountDependencyDependents = useCountDependencyMetadata(
    namespace,
    {
      staleTime,
      enabled: !!dependencyPackageVersionUuid,
    },
    {
      filter: [
        `spec.dependency_data.package_version_uuid==${dependencyPackageVersionUuid}`,
        `context.type in [${[
          ContextContextType.Main,
          ContextContextType.Sbom,
        ]}]`,
      ].join(' and '),
    }
  );

  const qCountDependencyFindings = useCountFindings(
    dependencyPackageVersion?.tenant_meta.namespace ?? '',
    { staleTime, enabled: !!dependencyPackageVersion },
    {
      filter: filterExpressionBuilders.and([
        `meta.parent_kind==PackageVersion`,
        `meta.parent_uuid==${dependencyPackageVersion?.uuid}`,
      ]),
    }
  );

  const tabRecords = buildTabRecords({
    dependenciesCount: qCountPackageVersionDependencies.data?.count,
    dependenciesIsLoading: qCountPackageVersionDependencies.isLoading,
    dependentsCount: qCountDependencyDependents.data?.count,
    dependentsIsLoading: qCountDependencyDependents.isLoading,
    findingsCount: qCountDependencyFindings.data?.count,
    findingsIsLoading: qCountDependencyFindings.isLoading,
  });

  const activeTab =
    tabRecords.find((r) => r.value === targetActiveTab)?.value ??
    DependencyDetailTabNames.FINDINGS;

  return { activeTab, tabRecords };
};
