import { Box, Card, Divider, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { ProjectResource } from '@endorlabs/endor-core/Project';
import {
  AttributeDisplayStack,
  CopyToClipboardButton,
  EcosystemIconDisplay,
  ExternalLink,
  FindingCountArrayDisplay,
  NilDisplay,
  NumberDisplay,
  RowStack,
  TagsDisplay,
} from '@endorlabs/ui-common';

import {
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../../components/DetailDrawer';
import { ScanResultScannedByDisplay } from '../../../ScanResult';
import { useProjectDetailDrawerContentData } from '../../hooks';
import { ProjectCiCdToolsTable } from '../ProjectCiCdToolsTable';

export interface ProjectDetailDrawerContentProps {
  namespace: string;
  uuid: string;
  project?: ProjectResource;
  isProjectLoading: boolean;
}

const ProjectMetadataAttributeRecords = [
  {
    attributeKey: 'uuid',
    heading: <FM defaultMessage="Project UUID" />,
  },
  {
    attributeKey: 'lastScanned',
    heading: <FM defaultMessage="Last scanned" />,
  },
  {
    attributeKey: 'repository',
    heading: <FM defaultMessage="Repository" />,
  },
  {
    attributeKey: 'tags',
    heading: <FM defaultMessage="Tags" />,
  },
  {
    attributeKey: 'packages',
    heading: <FM defaultMessage="Packages" />,
  },
  {
    attributeKey: 'repositoryVersions',
    heading: <FM defaultMessage="Repository versions" />,
  },
  {
    attributeKey: 'dependencies',
    heading: <FM defaultMessage="Dependencies" />,
  },
  {
    attributeKey: 'usedLanguages',
    heading: <FM defaultMessage="Languages used" />,
  },
];

const ProjectFindingsAttributeRecords = [
  {
    attributeKey: 'dependencyFindings',
    heading: <FM defaultMessage="Dependency" />,
  },
  {
    attributeKey: 'packageFindings',
    heading: <FM defaultMessage="Package" />,
  },
  {
    attributeKey: 'repositoryFindings',
    heading: <FM defaultMessage="Repository" />,
  },
  {
    attributeKey: 'secretsFindings',
    heading: <FM defaultMessage="Secrets" />,
  },
  {
    attributeKey: 'ciFindings',
    heading: <FM defaultMessage="CI Workflows" />,
  },
];

export const ProjectDetailDrawerContent = ({
  isProjectLoading,
  uuid,
  project,
  namespace,
}: ProjectDetailDrawerContentProps) => {
  const { palette } = useTheme();
  const {
    isLoading,
    findingCounts,
    packagesCount,
    dependenciesCount,
    languages,
    repositoryLink,
    projectCiCdTableRows,
    scanResult,
    repositoryVersionsCount,
    tags,
    packageEcosystems,
  } = useProjectDetailDrawerContentData({
    isProjectLoading,
    uuid,
    project,
    namespace,
  });

  return (
    <DetailDrawerSectionStack divider={<Divider />}>
      <DetailDrawerSection title="Project Metadata" id="project_metadata">
        <AttributeDisplayStack
          attributeRecords={ProjectMetadataAttributeRecords}
          headingWidth="20ch"
          isLoading={isLoading}
          variant="flex"
          resource={{
            uuid: (
              <RowStack gap={1}>
                <Typography variant="body2">{uuid}</Typography>
                <CopyToClipboardButton size="small" value={uuid} />
              </RowStack>
            ),
            lastScanned: (
              <ScanResultScannedByDisplay
                scanResult={scanResult}
                size="small"
                showScanTime
              />
            ),
            repository: repositoryLink ? (
              <Box width="26ch">
                <ExternalLink variant="body2" to={repositoryLink}>
                  {repositoryLink}
                </ExternalLink>
              </Box>
            ) : (
              <NilDisplay variant="text" />
            ),
            tags:
              tags.length > 0 ? (
                <TagsDisplay tags={project?.meta.tags} />
              ) : (
                <NilDisplay variant="text" />
              ),
            packages: (
              <RowStack gap={1}>
                <NumberDisplay
                  typographyProps={{ variant: 'body2' }}
                  value={packagesCount ?? 0}
                />
                {packageEcosystems && (
                  <EcosystemIconDisplay
                    ecosystem={packageEcosystems}
                    displayDefault="package"
                    multiplePackages={
                      packagesCount !== undefined && packagesCount > 1
                    }
                  />
                )}
              </RowStack>
            ),
            repositoryVersions: (
              <NumberDisplay
                typographyProps={{ variant: 'body2' }}
                value={repositoryVersionsCount ?? 0}
              />
            ),
            dependencies: (
              <RowStack gap={1}>
                <Typography variant="body2">
                  <FM
                    defaultMessage="{direct, number} Direct"
                    values={dependenciesCount}
                  />
                </Typography>
                <Typography variant="body2" color={palette.text.secondary}>
                  <FM
                    defaultMessage="({indirect, number} Transitive)"
                    values={dependenciesCount}
                  />
                </Typography>
              </RowStack>
            ),
            usedLanguages: languages ? (
              <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                {languages}
              </Typography>
            ) : (
              <NilDisplay variant="text" />
            ),
          }}
        />
      </DetailDrawerSection>
      <DetailDrawerSection
        id="project_findings"
        title={<FM defaultMessage="Findings" />}
      >
        <AttributeDisplayStack
          attributeRecords={ProjectFindingsAttributeRecords}
          headingWidth="20ch"
          isLoading={isLoading}
          variant="flex"
          resource={{
            dependencyFindings: (
              <FindingCountArrayDisplay
                value={findingCounts.dependencies}
                forceWidth
              />
            ),
            packageFindings: (
              <FindingCountArrayDisplay
                value={findingCounts.packages}
                forceWidth
              />
            ),
            repositoryFindings: (
              <FindingCountArrayDisplay
                value={findingCounts.repositories}
                forceWidth
              />
            ),
            secretsFindings: (
              <FindingCountArrayDisplay
                value={findingCounts.secrets}
                forceWidth
              />
            ),
            ciFindings: (
              <FindingCountArrayDisplay
                value={findingCounts.ciWorkflows}
                forceWidth
              />
            ),
          }}
        />
      </DetailDrawerSection>
      <DetailDrawerSection title="Tools Used" id="project_tools">
        <Card variant="outlined" elevation={0}>
          <ProjectCiCdToolsTable
            data={projectCiCdTableRows}
            isLoading={isLoading}
          />
        </Card>
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
