import { Stack, useTheme } from '@mui/material';

import { IconArrowRight, Pip } from '@endorlabs/ui-common';

export const VersionUpgradeDisplay = ({
  fromVersion,
  toVersion,
  size = 'small',
}: {
  fromVersion?: string;
  toVersion?: string;
  size?: 'small' | 'medium' | 'large';
}) => {
  const { spacing } = useTheme();
  return (
    <Stack alignItems="center" direction="row" flexWrap="wrap" gap={spacing(1)}>
      <Pip color="info" label={fromVersion} size={size} variant="outlined" />
      <IconArrowRight />
      <Pip color="success" label={toVersion} size={size} variant="outlined" />
    </Stack>
  );
};
