import {
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { CommitCommitType } from '@endorlabs/api_client';
import {
  AssuredPackageVersionResource,
  parseSecurityAttestationPatch,
} from '@endorlabs/endor-core/AssuredPackageVersion';
import {
  AttributeDisplay,
  DateDisplay,
  DiffStat,
  ExpandCollapseControl,
  IconEndorLogo,
  IconGitCommit,
  NumberDisplay,
  RowStack,
} from '@endorlabs/ui-common';

import {
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../../components/DetailDrawer';
import { Patch, PatchDisplayAccordion } from '../PatchDisplayAccordion';

export const AssuredPackageVersionDetailDrawerPatches = ({
  assuredPackageVersion,
}: {
  assuredPackageVersion: AssuredPackageVersionResource;
}) => {
  const { space } = useTheme();

  const [expandAll, setExpandAll] = useState(false);

  const upgradeSummary = assuredPackageVersion.spec.upgrade_summary;

  const summary = useMemo(() => {
    const endorPatchCount = upgradeSummary?.number_of_endor_patches ?? 0;
    const originalPatchCount = upgradeSummary?.number_of_original_patches ?? 0;

    return {
      endorPatchCount,
      originalPatchCount,
      totalPatchCount: endorPatchCount + originalPatchCount,
    };
  }, [
    upgradeSummary?.number_of_endor_patches,
    upgradeSummary?.number_of_original_patches,
  ]);

  const patches = useMemo(() => {
    const patches =
      assuredPackageVersion.spec.security_attestation?.patches ?? [];

    return patches.flatMap((p): Patch[] => {
      const commit = p.included_commits?.[0];
      const commitDate = commit?.commit_date;
      const sha = commit?.sha ? commit?.sha.slice(0, 7) : undefined;
      const isEndorPatch = p.included_commits?.some(
        (c) => c.commit_type === CommitCommitType.Manual
      );

      const diffStats = parseSecurityAttestationPatch(p);
      return diffStats.map((stat) => ({
        ...stat,
        commitDate,
        isEndorPatch,
        sha,
      }));
    });
  }, [assuredPackageVersion.spec.security_attestation?.patches]);

  return (
    <DetailDrawerSectionStack>
      <DetailDrawerSection>
        <Box
          sx={(t) => ({
            border: `1px solid ${t.palette.divider}`,
            borderLeft: 'none',
            borderRight: 'none',
            backgroundColor: t.palette.grey[100],
            marginX: -6,
            marginY: 0,
            padding: 4,
          })}
        >
          <Stack gap={2} divider={<Divider />}>
            <RowStack justifyContent="center">
              <AttributeDisplay
                heading={<FM defaultMessage="Patches" />}
                value={<NumberDisplay value={summary.totalPatchCount} />}
                // TODO: add tooltip when known
                // helpTooltip={<FM defaultMessage="Patches" />}
                valueTypographyProps={{
                  textAlign: 'center',
                  variant: 'h3',
                }}
              />
            </RowStack>

            <RowStack
              divider={<Divider orientation="vertical" flexItem />}
              justifyContent="space-evenly"
            >
              <AttributeDisplay
                headingWidth={200}
                heading={<FM defaultMessage="Original" />}
                value={<NumberDisplay value={summary.originalPatchCount} />}
                // TODO: add tooltip when known
                // helpTooltip={<FM defaultMessage="Original" />}
                valueTypographyProps={{
                  textAlign: 'center',
                  variant: 'h3',
                }}
              />

              <AttributeDisplay
                headingWidth={200}
                heading={<FM defaultMessage="Endor" />}
                value={<NumberDisplay value={summary.endorPatchCount} />}
                // TODO: add tooltip when known
                // helpTooltip={<FM defaultMessage="Endor" />}
                valueTypographyProps={{
                  textAlign: 'center',
                  variant: 'h3',
                }}
              />
            </RowStack>
          </Stack>
        </Box>
      </DetailDrawerSection>

      <DetailDrawerSection>
        <RowStack justifyContent="space-between">
          <ExpandCollapseControl onClick={setExpandAll} />

          {upgradeSummary && (
            <DiffStat
              added={upgradeSummary.line_added ?? 0}
              removed={upgradeSummary.line_removed ?? 0}
            />
          )}
        </RowStack>

        <Stack gap={space.md} marginTop={4}>
          {patches.map((p, index) => (
            <Stack gap={space.sm} key={index}>
              <RowStack>
                {p.isEndorPatch ? (
                  <IconEndorLogo sx={{ color: 'text.secondary' }} />
                ) : (
                  <IconGitCommit sx={{ color: 'text.secondary' }} />
                )}

                <Typography component="span" variant="body2">
                  <FM
                    defaultMessage="<b>{patchType}</b> on {date}"
                    values={{
                      patchType: p.isEndorPatch ? (
                        <FM defaultMessage="Endor Patch" />
                      ) : (
                        <FM defaultMessage="Original Patch" />
                      ),
                      date: <DateDisplay value={p.commitDate} />,
                      b: (value) => <b>{value}</b>,
                    }}
                  />
                </Typography>
              </RowStack>

              {p && (
                <Paper sx={{ marginLeft: space.md }} variant="outlined">
                  <PatchDisplayAccordion expanded={expandAll} patch={p} />
                </Paper>
              )}
            </Stack>
          ))}
        </Stack>
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
