import { Chip, ChipProps, Theme } from '@mui/material';

import { useStyles } from '../../hooks';
import { DesignSystemColors } from '../../themes/endor/DesignSystemColors';

const { Grays, Greens, Severity } = DesignSystemColors;

interface PipProps extends ChipProps {}

export const Pip = ({ size = 'small', ...props }: PipProps) => {
  const sx = useStyles(styles);

  return (
    <Chip
      className="Pip-root"
      size={size}
      variant="filled"
      {...props}
      sx={sx}
    />
  );
};

const styles = ({ palette, spacing }: Theme) => {
  return {
    '&.MuiChip-root': {
      borderRadius: '4px',

      '&.MuiChip-filled': {
        borderStyle: 'solid',
        borderWidth: '1px',
        fontWeight: 500,
      },

      '&.MuiChip-sizeXsmall': {
        height: spacing(4.5),
        paddingX: spacing(0.5),
      },

      '&.MuiChip-sizeSmall': {
        height: spacing(5.5),
      },

      '&.MuiChip-sizeMedium': {
        height: spacing(7),
      },
      '& .MuiChip-icon': {
        marginRight: 0,
      },
    },

    // FIXME: Need separate treatment for color="primary"
    '&, &.MuiChip-colorDefault, &.MuiChip-colorPrimary': {
      backgroundColor: Grays.light_gray_100.lightMode.hsla,
      borderColor: Grays.light_gray_200.lightMode.hsla,
      color: palette.text.primary,
    },

    '&.MuiChip-colorSecondary': {
      backgroundColor: Grays.light_gray_100.lightMode.hsla,
      borderColor: Greens.dark_teal_600.lightMode.hsla,
      color: Greens.dark_teal_800.lightMode.hsla,
    },

    '&.MuiChip-colorWarning': {
      backgroundColor: Severity.medium_50.lightMode.hsla,
      borderColor: Severity.high_200.lightMode.hsla,
      color: Severity.medium_hover.lightMode.hsla,
    },

    '&.MuiChip-colorError': {
      backgroundColor: Severity.high_50.lightMode.hsla,
      borderColor: Severity.critical.lightMode.hsla,
      color: Severity.high_hover.lightMode.hsla,
    },

    '&.MuiChip-colorInfo': {
      backgroundColor: Severity.low_50.lightMode.hsla,
      borderColor: Severity.low.lightMode.hsla,
      color: Severity.medium_hover.lightMode.hsla,
    },

    '&.MuiChip-colorSuccess': {
      backgroundColor: Severity.safe_100.lightMode.hsla,
      borderColor: Severity.safe.lightMode.hsla,
      color: Severity.safe_hover.lightMode.hsla,
    },

    '&.MuiChip-outlined.MuiChip-colorPrimary, &.MuiChip-outlined.MuiChip-colorInfo':
      {
        backgroundColor: 'transparent',
      },

    '&.MuiChip-outlined.MuiChip-colorWarning': {
      backgroundColor: 'transparent',
      borderColor: Severity.medium.lightMode.hsla,
      color: palette.text.primary,
    },

    '&.MuiChip-outlined.MuiChip-colorError': {
      backgroundColor: 'transparent',
      color: palette.text.primary,
    },

    '&.MuiChip-outlined.MuiChip-colorInfo': {
      backgroundColor: 'transparent',
      color: palette.text.primary,
    },

    '&.MuiChip-outlined.MuiChip-colorSuccess': {
      backgroundColor: 'transparent',
      borderColor: Severity.safe.lightMode.hsla,
      color: palette.text.primary,
    },
  };
};
