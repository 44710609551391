import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Stack,
} from '@mui/material';
import { useReducer } from 'react';

import { useGraphDataNode } from '../../../../domains/graph';
import { IconChevronDown } from '../../../../themes';
import { RowStack } from '../../../RowStack';
import { ChecklistInputGraphNode, ChecklistInputGraphState } from './types';

type ChecklistItemProps = {
  isGrouped?: boolean;
  nodeId: string;
  state: ChecklistInputGraphState;
};

export const ChecklistItem = ({
  isGrouped = false,
  nodeId,
  state,
}: ChecklistItemProps) => {
  const [isExpanded, toggleExpanded] = useReducer((value) => !value, true);

  const { children, node } = useGraphDataNode(nodeId) as unknown as {
    // HACK: override types from provider
    node: ChecklistInputGraphNode;
    children: ChecklistInputGraphNode[];
  };

  const hasChildren = children.length > 0;

  const hasAllChecked =
    hasChildren && children.every((c) => state.checked.has(c.id));
  const hasSomeChecked = children.some((c) => state.checked.has(c.id));
  const isChecked = hasChildren ? hasAllChecked : state.checked.has(node.id);

  return (
    <RowStack alignItems="flex-start" className="ChecklistItem-root">
      {isGrouped && (
        <Box className="ChecklistItem-collapseToggle">
          {hasChildren && (
            <IconButton onClick={toggleExpanded}>
              <IconChevronDown
                fontSize="small"
                sx={[
                  {
                    transition: 'transform 300ms',
                    transform: 'rotate(-90deg)',
                  },
                  isExpanded && {
                    transform: 'rotate(0)',
                  },
                ]}
              />
            </IconButton>
          )}
        </Box>
      )}

      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              disabled={node.attributes?.disabled}
              indeterminate={hasSomeChecked && !hasAllChecked}
              name={node.id}
            />
          }
          label={node.attributes?.label ?? node.id}
        />

        {hasChildren && (
          <Collapse in={isExpanded} unmountOnExit>
            <Stack spacing={2} marginTop={2}>
              {children.map((child) => (
                <ChecklistItem
                  isGrouped={isGrouped}
                  key={child.id}
                  nodeId={child.id}
                  state={state}
                />
              ))}
            </Stack>
          </Collapse>
        )}
      </Box>
    </RowStack>
  );
};
