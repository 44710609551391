import {
  Box,
  Button,
  ButtonProps,
  LinearProgress,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';

import { IconRocket, Link, LinkProps } from '@endorlabs/ui-common';

import { ICONSPACE } from './constants';

interface ButtonFillProps extends Omit<ButtonProps, 'href'> {
  progress: number;
  btnLabel: JSX.Element;
  appDrawerMinimized: boolean | undefined;
  /**
   * NOTE: using Link for navigation within app
   */
  linkProps?: Pick<LinkProps, 'to' | 'search' | 'replace'>;
}

export const OnboardingBtn = ({
  progress,
  btnLabel,
  appDrawerMinimized,
  linkProps,
  ...props
}: ButtonFillProps) => {
  const theme = useTheme();

  let backgroundColor = 'transparent';
  let textGradient = theme.palette.primary.light;
  let border = `1px solid ${theme.palette.primary.light}`;
  const nextStep = progress + 1;

  //Change background color
  if (progress > 0 && progress < ICONSPACE) {
    backgroundColor = `linear-gradient(90deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.light} ${progress}%, transparent ${nextStep}%, transparent 100%)`;
  } else if (progress > ICONSPACE && progress < 100) {
    backgroundColor = `linear-gradient(90deg, ${
      theme.palette.primary.light
    } 0%, ${theme.palette.primary.light} ${progress}%, transparent ${
      nextStep - ICONSPACE
    }%, transparent 100%)`;
  }

  //Add color to button label
  if (progress > ICONSPACE && progress < 100) {
    textGradient = `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} ${progress}%, ${theme.palette.primary.light} ${nextStep}%, ${theme.palette.primary.light} 100%)`;
  }

  if (progress === 100) {
    backgroundColor = `${theme.palette.primary.main}`;
    textGradient = `${theme.palette.background.paper}`;
    border = `1px solid ${theme.palette.primary.main}`;
  }

  const iconColor =
    progress == 0
      ? theme.palette.primary.light
      : progress < 100
      ? theme.palette.primary.main
      : theme.palette.background.paper;

  const { formatMessage: fm } = useIntl();

  return (
    <Button
      variant="outlined"
      {...props}
      {...linkProps}
      LinkComponent={Link}
      sx={{
        position: 'relative',
        background: backgroundColor,
        borderRadius: '4px',
        border,
        boxShadow: '0px 0px 14px 0px rgba(53, 64, 82, 0.05)',
        padding: '6px 16px',
        minWidth: appDrawerMinimized ? '30px' : 'inherit',
        '& div': {
          background: textGradient,
          WebkitBackgroundClip: 'text', // Apply the gradient to the text
          backgroundClip: 'text', // Apply the gradient to the text
          color: 'transparent',
        },
      }}
      data-testid="onboarding-btn"
    >
      {!appDrawerMinimized ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <Box pr={2} pt={1}>
            <IconRocket sx={{ color: iconColor }} />
          </Box>
          <span>{btnLabel}</span>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              opacity: 0,
              width: `${progress}%`,
              zIndex: 1,
              height: '100%',
            }}
          />
        </div>
      ) : (
        <Tooltip
          title={fm({ defaultMessage: 'Getting Started' })}
          arrow
          placement="right"
        >
          <Box>
            <IconRocket sx={{ color: iconColor }} />
          </Box>
        </Tooltip>
      )}
    </Button>
  );
};
