import { Alert, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';
import { QueryPackageVersionsResponseObject } from '@endorlabs/queries';
import {
  AttributeDisplay,
  AttributeDisplayStack,
  BooleanDisplay,
  DateDisplay,
  DependencyPathDisplay,
  FindingCountArrayDisplay,
  NumberDisplay,
} from '@endorlabs/ui-common';

import {
  DetailDrawerPaginatedSection,
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../../components/DetailDrawer';
import { useDependencyDetailOverviewData } from '../../hooks';
import { DependencyDetailDrawerProps } from './types';

const DependencyMetadataAttributeRecords = [
  { attributeKey: 'findingCounts', heading: <FM defaultMessage="Findings" /> },
  {
    attributeKey: 'dependencyCount',
    heading: <FM defaultMessage="Dependencies" />,
  },
  { attributeKey: 'licenseNames', heading: <FM defaultMessage="Licenses" /> },
  {
    attributeKey: 'hasPhantomDependency',
    heading: <FM defaultMessage="Phantom Dependency" />,
    helpTooltip: (
      <FM defaultMessage="A phantom dependency is a dependency that is used in source but not declared in the package's manifest file." />
    ),
  },
  {
    attributeKey: 'resolutionTime',
    heading: <FM defaultMessage="Resolution Time" />,
  },
];

const DependencySpecificationAttributeRecords = [
  {
    attributeKey: 'importerPackage',
    heading: <FM defaultMessage="Dependent Package" />,
  },
  {
    attributeKey: 'resolvedVersion',
    heading: <FM defaultMessage="Resolved Version" />,
  },
  {
    attributeKey: 'unresolvedVersion',
    heading: <FM defaultMessage="Unresolved Version" />,
  },
  { attributeKey: 'scope', heading: <FM defaultMessage="Dependency Scope" /> },
];

type DependencyDetailDrawerOverviewSectionProps =
  DependencyDetailDrawerProps & {
    dependencyPackageVersion?: QueryPackageVersionsResponseObject;
    isLoading: boolean;
  };

export const DependencyDetailDrawerOverviewSection = ({
  name: dependencyPackageVersionName,
  dependencyPackageVersion,
  importingNamespace,
  importingPackageVersion,
  importingProject,
  importingProjectVersion,
  isLoading: isLoadingDependencyPackageVersion,
}: DependencyDetailDrawerOverviewSectionProps) => {
  const {
    dependencyCount,
    dependencyEcosystem,
    dependencyFileLocations,
    dependencyGraph,
    dependencySpecifications,
    findingCounts,
    hasPhantomDependency,
    isLoading: isLoadingDependencyDetailOverviewData,
    importingPackageVersionNames,
    isPartial,
    licenseNames,
    resolutionTimestamp,
  } = useDependencyDetailOverviewData({
    dependencyPackageVersionName,
    dependencyPackageVersion,
    importingNamespace,
    importingPackageVersion,
    importingProject,
    importingProjectVersion,
  });

  const [dependencySpecificationIndex, setDependencySpecificationIndex] =
    useState<number>(0);

  // Reset the displayed index on change
  useEffect(() => {
    setDependencySpecificationIndex(0);
  }, [dependencyPackageVersionName]);

  const isLoading =
    isLoadingDependencyPackageVersion || isLoadingDependencyDetailOverviewData;
  const showDependencyLocations = dependencyFileLocations.length > 0;
  const showDependencySpecifications =
    dependencySpecifications.length > 0 &&
    dependencyEcosystem !== V1Ecosystem.GithubAction;

  return (
    <DetailDrawerSectionStack divider={<Divider />}>
      <DetailDrawerSection id="dependency_metadata">
        <AttributeDisplayStack
          attributeRecords={DependencyMetadataAttributeRecords}
          headingWidth="24ch"
          isLoading={isLoading}
          resource={{
            findingCounts: <FindingCountArrayDisplay value={findingCounts} />,
            dependencyCount: <NumberDisplay value={dependencyCount} />,
            licenseNames: licenseNames.length ? (
              <Stack direction="column">
                {licenseNames.map((value, index) => (
                  <span key={index}>{value}</span>
                ))}
              </Stack>
            ) : undefined,
            hasPhantomDependency: (
              <BooleanDisplay
                highlightFalse={false}
                value={hasPhantomDependency}
              />
            ),
            resolutionTime: resolutionTimestamp ? (
              <DateDisplay value={resolutionTimestamp} />
            ) : undefined,
          }}
          variant="flex"
        />
      </DetailDrawerSection>

      {showDependencyLocations && (
        <DetailDrawerSection id="dependency_locations">
          {isPartial && (
            <Alert severity="info">
              <FM defaultMessage="Sample of the locations found for the dependency are shown below. Additional locations may exist." />
            </Alert>
          )}

          <AttributeDisplay
            heading={<FM defaultMessage="Locations" />}
            isLoading={isLoading}
            value={
              dependencyFileLocations.length ? (
                <Stack direction="column" gap={1}>
                  {dependencyFileLocations.map((value, index) => (
                    <Typography
                      component="span"
                      key={index}
                      sx={{ wordBreak: 'break-all' }}
                      variant="body2"
                    >
                      {value}
                    </Typography>
                  ))}
                </Stack>
              ) : undefined
            }
          />
        </DetailDrawerSection>
      )}

      <DetailDrawerSection id="dependency_path">
        <DependencyPathDisplay
          displayAsAccordion={false}
          dependencyGraph={dependencyGraph}
          isLoading={isLoading}
          isPartial={isPartial}
          project={importingProject}
          sourcePackageNames={importingPackageVersionNames}
          targetPackageName={dependencyPackageVersionName}
        />
      </DetailDrawerSection>

      {showDependencySpecifications && (
        <DetailDrawerPaginatedSection
          onPaginationChange={setDependencySpecificationIndex}
          paginatorProps={{
            index: dependencySpecificationIndex,
            totalCount: dependencySpecifications.length,
          }}
          title={<FM defaultMessage="Dependency Specification" />}
        >
          <AttributeDisplayStack
            attributeRecords={DependencySpecificationAttributeRecords}
            headingWidth="24ch"
            isLoading={isLoading}
            resource={dependencySpecifications[dependencySpecificationIndex]}
            variant="flex"
          />
        </DetailDrawerPaginatedSection>
      )}
    </DetailDrawerSectionStack>
  );
};
