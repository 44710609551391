import {
  has as _has,
  orderBy as _orderBy,
  set as _set,
  uniq as _uniq,
} from 'lodash-es';
import { useMemo } from 'react';
import { useQueries } from 'react-query';

import { V1ScanState } from '@endorlabs/api_client';
import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import {
  Filter,
  filterExpressionBuilders,
  serialize,
} from '@endorlabs/filters';
import {
  listVersionUpgrade,
  sortParamBuilders,
  tryParseGroupResponseAggregationKey,
  useGroupVersionUpgrade,
  useListAssuredPackageVersions,
} from '@endorlabs/queries';
import {
  getPaginatorPageSlice,
  UIProjectUtils,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { useFilterContext } from '../../filters';

interface UseRemedationsByDependencyProps {
  namespace: string;
  packageVersion?: PackageVersionResource;
  project?: ProjectResource;
  projectVersion?: ProjectVersionResource;
}

/**
 * Retrieve version upgrades "remediations" grouped by dependency,
 * associating related data as needed.
 */
export const useRemediationsByDependency = ({
  namespace,
  packageVersion,
  project,
  projectVersion,
}: UseRemedationsByDependencyProps) => {
  const { filter: userFilterExpression } = useFilterContext();

  // Get base filter from the given PackageVersion or Project + Project Version
  const baseFilterExpression = useMemo(() => {
    if (project && projectVersion) {
      return UIProjectUtils.getProjectRelatedFilterExpressions(
        project,
        projectVersion,
        { key: 'spec.project_uuid' }
      );
    }

    if (packageVersion) {
      return filterExpressionBuilders.and([
        `spec.upgrade_info.root_pkg_version_uuid=="${packageVersion.uuid}"`,
      ]);
    }
  }, [packageVersion, project, projectVersion]);

  const filterExpression = useMemo(() => {
    if (!baseFilterExpression) return;

    const expressions = [
      baseFilterExpression,
      `meta.name==AllUpgradesPerDependency`,
      `spec.upgrade_info exists`,
      filterExpressionBuilders.or([
        'spec.upgrade_info.vuln_finding_info.reduction > 0',
        'spec.upgrade_info.other_finding_info.reduction > 0',
      ]),
    ];

    if (userFilterExpression) {
      expressions.push(userFilterExpression);
    }

    return filterExpressionBuilders.and(expressions);
  }, [baseFilterExpression, userFilterExpression]);

  // Perform primary grouped query for version upgrades
  const qVersionUpgrades = useGroupVersionUpgrade(
    namespace,
    {
      filter: filterExpression,
      group: {
        aggregation_paths: [
          'spec.upgrade_info.direct_dependency_package',
          'spec.upgrade_info.root_package_version',
        ].join(','),
        show_aggregation_uuids: true,
        unique_value_paths: [
          'spec.upgrade_info.from_version',
          'spec.upgrade_info.is_endor_patch',
          'spec.upgrade_info.root_pkg_version_uuid',
          'spec.upgrade_info.score',
          'spec.upgrade_info.total_findings_fixed',
        ].join(','),
      },
    },
    {
      enabled: !!filterExpression,
    }
  );

  // Augment & simplify grouped data structure
  const data = useMemo(() => {
    const aggregated = Object.entries(qVersionUpgrades.data?.groups ?? {}).map(
      ([key, group]) => {
        const values = tryParseGroupResponseAggregationKey(key);
        const groupValues = group.unique_values ?? {};

        const dependencyPackageName = values.find(
          (kv) => kv.key === 'spec.upgrade_info.direct_dependency_package'
        )?.value;

        // may be multiple
        const version = (
          groupValues['spec.upgrade_info.from_version'] ?? []
        ).at(0);

        const hasEndorPatchUpgrade = (
          groupValues['spec.upgrade_info.is_endor_patch'] ?? []
        ).some((v) => v === true);

        const filter = filterExpressionBuilders.and([
          filterExpression as string,
          serialize(
            values.map((kv) => ({ ...kv, comparator: 'EQUAL' } as Filter))
          ),
        ]);

        return {
          count: group.aggregation_count?.count ?? 1,
          dependencyPackageName: dependencyPackageName,
          dependencyPackageVersionName: dependencyPackageName + '@' + version,
          filter,
          hasEndorPatchUpgrade,
          packageVersionName: values.find(
            (kv) => kv.key === 'spec.upgrade_info.root_package_version'
          )?.value,
          packageVersionUuid: (
            groupValues['spec.upgrade_info.root_pkg_version_uuid'] ?? []
          ).at(0),
          score: groupValues['spec.upgrade_info.score'].reduce(
            (max, v) => Math.max(max, v),
            0
          ),
          total_findings_fixed:
            groupValues['spec.upgrade_info.total_findings_fixed'],
          uuids: group.aggregation_uuids ?? [],
          version,
        };
      }
    );

    return _orderBy(
      aggregated,
      ['score', 'dependencyPackageVersionName'],
      ['desc', 'asc']
    );
  }, [filterExpression, qVersionUpgrades.data?.groups]);

  const paginator = useDataTablePaginator({
    pageSize: 50,
    totalCount: data.length,
  });

  const pageSlice = useMemo(
    () => getPaginatorPageSlice(paginator.state, data),
    [data, paginator.state]
  );

  // Fallback lookup for dependencies with Endor Patches (matching AssuredPackageVersion)
  //
  // Patches _may_ be included in the UIA VersionUupgrade response (`spec.upgrade_info.is_endor_patch`),
  // and the patch information should be retrieved from the VersionUpgrade data instead.
  const fallbackDependencyPackageVersionNames = pageSlice
    .filter((d) => d.hasEndorPatchUpgrade === false)
    .map((d) => d.dependencyPackageVersionName);
  const qListAssuredPackageVersions = useListAssuredPackageVersions(
    namespace,
    {
      filter: filterExpressionBuilders.and([
        `meta.name in ["${fallbackDependencyPackageVersionNames
          .map((packageVersionName) => packageVersionName + '-endor-latest')
          .join('","')}"]`,
        `processing_status.scan_state==${V1ScanState.Idle}`,
      ]),
      mask: ['uuid', 'meta.name'].join(','),
    },
    { enabled: fallbackDependencyPackageVersionNames.length > 0 }
  );

  /**
   * For each dependency, we want to display the fix count, broken down by level,
   * for the remediation with the MOST vulnerabilties fixed.
   *
   * For each dependency:
   * 1) Determine which version upgrade fixes the most findings
   * 2) Retrieve that specific version upgrade, filtering by dep name & fixed finding count
   */
  const mostFindingsFixedFilters = pageSlice
    .map((d) => {
      if (!baseFilterExpression) return;

      return filterExpressionBuilders.and([
        baseFilterExpression,
        `spec.upgrade_info.direct_dependency_package=="${d.dependencyPackageName}"`,
        `spec.upgrade_info.root_package_version=="${d.packageVersionName}"`,
      ]);
    })
    .filter((v): v is string => !!v);

  // Perform parallel queries to retrieve most fixed remediations.
  const qqMostFixedVersionUpgrades = useQueries(
    mostFindingsFixedFilters.map((filter) => {
      return {
        enabled: !!filter,
        queryFn: () =>
          listVersionUpgrade(
            namespace,
            {
              filter,
              mask: [
                'meta.parent_uuid',
                'spec.upgrade_info.direct_dependency_package',
                'spec.upgrade_info.from_version',
                'spec.upgrade_info.vuln_finding_info.severity',
                'spec.upgrade_info.direct_dependency_manifest_files',
              ].join(','),
              sort: sortParamBuilders.descendingBy(
                'spec.upgrade_info.total_findings_fixed'
              ),
              page_size: 1,
            },
            {}
          ),
        queryKey: [namespace, 'versionUpgrade', filter],
      };
    })
  );

  const [mostFixedVersionUpgrades, isLoadingAdditionalData] = useMemo(() => {
    const mostFixedVersionUpgrades = qqMostFixedVersionUpgrades.map(
      (q) => (q.data?.objects ?? [])[0]
    );
    const isLoadingAdditionalData = qqMostFixedVersionUpgrades.some(
      (q) => q.isLoading
    );

    return [mostFixedVersionUpgrades, isLoadingAdditionalData];
  }, [qqMostFixedVersionUpgrades]);

  const remediationGroups = useMemo(() => {
    // Augment group data with a findings fix count map for each dependency, based on most fixed.
    const findingCountsMap: Record<string, Record<string, number>> = {};
    const manifestFilesMap: Record<string, string[]> = {};

    (mostFixedVersionUpgrades ?? []).forEach((mostFixedUpgrade) => {
      if (!mostFixedUpgrade) return;

      const id = [
        `${mostFixedUpgrade.spec?.upgrade_info?.direct_dependency_package}@${mostFixedUpgrade.spec?.upgrade_info?.from_version}`,
        mostFixedUpgrade.meta?.parent_uuid ?? '',
      ].join(':');

      _set(
        findingCountsMap,
        [id],
        Object.entries(
          mostFixedUpgrade.spec?.upgrade_info?.vuln_finding_info?.severity ?? {}
        ).reduce(
          (acc, [level, values]) => ({ ...acc, [level]: values.reduction }),
          {}
        )
      );

      _set(
        manifestFilesMap,
        [id],
        mostFixedUpgrade.spec?.upgrade_info?.direct_dependency_manifest_files
      );
    });

    // Augment with Endor Patch (AssuredPackageVersion) information
    const patchesMap: Record<string, AssuredPackageVersionResource> = {};
    qListAssuredPackageVersions.data?.objects.forEach((o) => {
      const depName = o.meta.name.replace(/-endor-latest$/, '');
      patchesMap[depName] = o;
    });

    const isLoading = qVersionUpgrades?.isLoading || isLoadingAdditionalData;

    return pageSlice.map((d) => {
      const id = [d.dependencyPackageVersionName, d.packageVersionUuid].join(
        ':'
      );

      const hasEndorPatchUpgrade =
        d.hasEndorPatchUpgrade ||
        _has(patchesMap, d.dependencyPackageVersionName);
      const manifestFiles = _uniq(manifestFilesMap[id]).sort();
      const findingCounts = FINDING_LEVELS.map((level) => {
        return { level, value: findingCountsMap[id]?.[level] ?? 0 };
      });

      // Return final composed object
      return {
        ...d,
        findingCounts,
        hasEndorPatchUpgrade,
        isLoading,
        manifestFiles,
      };
    });
  }, [
    isLoadingAdditionalData,
    mostFixedVersionUpgrades,
    pageSlice,
    qListAssuredPackageVersions.data,
    qVersionUpgrades?.isLoading,
  ]);

  return {
    // Consider as loading if the data has not yet been fetched, or is actively loading
    isLoading:
      qVersionUpgrades.isLoading || qVersionUpgrades.isFetched === false,
    paginator,
    remediationGroups,
  };
};
