import { useTheme } from '@mui/material';
import produce from 'immer';
import _get from 'lodash-es/get';
import _groupBy from 'lodash-es/groupBy';
import { useIntl } from 'react-intl';

import { GroupResponseGroupData } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { tryParseGroupResponseAggregationKey } from '@endorlabs/queries';
import { Color, ResourceKindPluralMessages } from '@endorlabs/ui-common';

import {
  DashboardChartFilter,
  DashboardChartFilterKey,
  DashboardChartFilterPrimaryKeyMap,
  DashboardChartFilters,
} from '../constants/DashboardChartFilters';
import { DashboardBucketContextChartMessageKeys } from '../constants/DashboardCharts';
import { DashboardViewRecord } from '../types';
import {
  genBarChartData,
  generateInterimChartData,
  getChartColors,
  InterimChartData,
} from '../utils';
import { useDashboardPreferences } from './useDashboardPreferences';
import { useDashboardQueries } from './useDashboardQueries';

interface UseDashboardStateReturnValue {
  changeActiveViewFilter: (
    viewId: string,
    newFilter: DashboardChartFilterKey
  ) => void;
  getActiveViewFilter: (viewId: string) => DashboardChartFilter | undefined;
  getAvailableFilters: (viewId: string) => DashboardChartFilter[][];
  getCategoryMessages: (viewId: string) => Map<string, string>;
  getView: (viewId: string) => DashboardViewRecord | undefined;
  getViewColors: (viewId: string) => Color[] | undefined;
  getViewIsLoading: (viewId: string) => boolean;
  getViewRecords: (viewId: string) => Record<string, string | number>[];
}

interface UseDashboardStateProps {
  namespace: string;
}

export const useDashboardState = ({
  namespace,
}: UseDashboardStateProps): UseDashboardStateReturnValue => {
  const intl = useIntl();
  const { palette } = useTheme();

  // Get primary query actions
  const { getPrimaryRecords, getQueryResult, getQueryStatus } =
    useDashboardQueries({
      namespace,
    });

  // Get dashboard layout variables from persisted state
  const { bucketContext, views, setDashboardPreferences } =
    useDashboardPreferences();

  const getView = (viewId: string): DashboardViewRecord | undefined => {
    return views.find((c) => c.viewId === viewId);
  };

  const getActiveViewFilter = (viewId: string) => {
    const view = getView(viewId);

    return DashboardChartFilters.find(
      (filterDef) => filterDef.key === view?.filterKeyActive
    );
  };

  const getAvailableFilters = (viewId: string) => {
    const view = getView(viewId);
    if (!view) return [];

    const filters = DashboardChartFilters.filter((f) =>
      f.allowedResourceKindsPrimary.includes(view?.resourceKindPrimary)
    );
    return Object.values(_groupBy(filters, 'allowSubcategories'));
  };

  const getNonCategoricalKeyMap = (viewId: string) => {
    const activeFilter = getActiveViewFilter(viewId);
    if (!activeFilter) return undefined;

    const { resourceKindCategory } = activeFilter;
    return new Map([
      [resourceKindCategory, ResourceKindPluralMessages[resourceKindCategory]],
    ]);
  };

  const getCategoryMessages = (viewId: string): Map<string, string> => {
    const activeFilter = getActiveViewFilter(viewId);
    if (!activeFilter) return new Map();

    const { allowSubcategories, resourceKindCategory } = activeFilter;

    const messageKeys = allowSubcategories
      ? _get(DashboardBucketContextChartMessageKeys, [
          bucketContext,
          resourceKindCategory,
        ])
      : getNonCategoricalKeyMap(viewId);

    const translatedMessageMap = new Map();
    for (const [messageKey, message] of messageKeys) {
      translatedMessageMap.set(messageKey, intl.formatMessage(message));
    }

    return translatedMessageMap;
  };

  const getViewColors = (viewId: string) => {
    const activeFilter = getActiveViewFilter(viewId);

    if (!activeFilter) {
      return getChartColors(undefined, palette);
    }

    if (activeFilter.allowSubcategories) {
      return getChartColors(bucketContext, palette);
    }

    return getChartColors(undefined, palette);
  };

  const getPrimaryKeyAttr = (viewId: string) => {
    const view = getView(viewId);
    if (!view) return undefined;

    const activeViewFilter = getActiveViewFilter(viewId);
    if (!activeViewFilter) return undefined;

    return _get(DashboardChartFilterPrimaryKeyMap, [
      activeViewFilter.resourceKindCategory,
      view.resourceKindPrimary,
    ]);
  };

  const getCategoryQueryAttributes = (viewId: string) => {
    const view = getView(viewId);
    if (!view) return undefined;

    const activeViewFilter = getActiveViewFilter(viewId);
    if (!activeViewFilter) return undefined;

    const primaryKeyAttr = getPrimaryKeyAttr(viewId);
    const isFinding = activeViewFilter.resourceKindCategory === 'Finding';

    // TODO: Probably move this into a shared constant
    const baseFilters = isFinding ? ['meta.parent_kind==PackageVersion'] : [];
    const aggregation_paths = isFinding ? ['spec.level'] : [];
    if (primaryKeyAttr) aggregation_paths.push(primaryKeyAttr);

    return {
      aggregation_paths,
      filters: activeViewFilter.expression
        ? [activeViewFilter.expression].concat(baseFilters)
        : baseFilters,
      resourceKind: activeViewFilter.resourceKindCategory,
    };
  };

  const getPrimaryRecordMask = (resourceKind: ResourceKind) => {
    const base = [
      'meta.name',
      'meta.parent_uuid',
      'tenant_meta.namespace',
      'uuid',
    ];

    switch (resourceKind) {
      case 'Project':
        base.push('spec.platform_source');
        break;
    }

    return base.join(',');
  };

  const getPrimaryQueryAttributes = (viewId: string, uuids: string[] = []) => {
    const view = getView(viewId);
    if (!view) return { resourceKind: 'Project' as ResourceKind };

    const { resourceKindPrimary } = view;

    return {
      filters: uuids.length > 0 ? [`uuid in [${uuids.join(',')}]`] : [],
      mask: getPrimaryRecordMask(resourceKindPrimary),
      resourceKind: resourceKindPrimary,
    };
  };

  // Parses keys from an aggregation path response and merges these into the records themselves
  const getFlatRecordsFromAggregationPaths = (
    groups: Record<string, GroupResponseGroupData>
  ) => {
    const flatEntries = Object.entries(groups)
      .sort((a, b) => {
        const countA = a[1].aggregation_count?.count ?? 1;
        const countB = b[1].aggregation_count?.count ?? 1;
        return countB === countA ? a[0].localeCompare(b[0]) : countB - countA;
      })
      // HACK: limit the records that are parsed
      .slice(0, 1_000)
      .map(([key, groups]) => {
        const item: any = { groups };
        const aggregation = tryParseGroupResponseAggregationKey(key);

        for (const kv of aggregation) {
          item[kv.key] = kv.value;
        }

        return item;
      });

    return flatEntries;
  };

  // Transform category data into interim records and return top N based on total count
  const getTopNInterimData = (viewId: string) => {
    const view = getView(viewId);
    if (!view) return [];

    const attrs = getCategoryQueryAttributes(viewId);
    if (!attrs) return [];

    const primaryKeyAttr = getPrimaryKeyAttr(viewId);
    const data = getQueryResult(attrs);
    const groups = data?.spec?.query_response?.group_response?.groups ?? {};
    const flatRecords = getFlatRecordsFromAggregationPaths(groups);

    return generateInterimChartData({
      // FIXME: Will need to change for priority buckets
      categoryKeyAttr: 'spec.level',
      categoryMessages: getCategoryMessages(viewId),
      categoryGroupRecords: flatRecords as { groups: GroupResponseGroupData }[],
      primaryUuidAttr: primaryKeyAttr,
    });
  };

  const getPrimaryRecordUuids = (interimData: InterimChartData[]) => {
    return interimData.map((r) => r.primaryUuid);
  };

  const getViewRecords = (viewId: string) => {
    const topN = getTopNInterimData(viewId);
    const primaryRecordUuids = getPrimaryRecordUuids(topN);

    const primaryRecords =
      primaryRecordUuids.length > 0
        ? getPrimaryRecords(
            getPrimaryQueryAttributes(viewId, primaryRecordUuids)
          )
        : [];

    const chartRecords = genBarChartData({
      categoryMessages: getCategoryMessages(viewId),
      interimChartData: topN,
      primaryRecords,
    });

    return chartRecords as Record<string, string | number>[];
  };

  const getViewIsLoading = (viewId: string) => {
    const view = getView(viewId);
    const activeFilter = getActiveViewFilter(viewId);
    const categoryQueryAttrs = getCategoryQueryAttributes(viewId);
    if (!view || !activeFilter || !categoryQueryAttrs) return false;

    const categoryQueryStatus = getQueryStatus(categoryQueryAttrs);

    const topN = getTopNInterimData(viewId);
    const primaryRecordUuids = getPrimaryRecordUuids(topN);
    const primaryQueryStatus = primaryRecordUuids.length
      ? getQueryStatus(getPrimaryQueryAttributes(viewId, primaryRecordUuids))
      : undefined;

    return (
      (categoryQueryStatus?.isFetching || primaryQueryStatus?.isFetching) ??
      false
    );
  };

  const changeActiveViewFilter = (
    viewId: string,
    newFilterKey: DashboardChartFilterKey
  ) => {
    const newViews = produce(views, (newViews) => {
      const relevantChart = newViews.find((c) => c.viewId === viewId);
      if (relevantChart) {
        relevantChart.filterKeyActive = newFilterKey;
      }
    });

    setDashboardPreferences({ bucketContext, views: newViews });
  };

  return {
    changeActiveViewFilter,
    getActiveViewFilter,
    getAvailableFilters,
    getView,
    getViewRecords,
    getViewIsLoading,
    getCategoryMessages,
    getViewColors,
  };
};
