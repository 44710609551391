import IconClose from '@mui/icons-material/Close';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { PropsWithChildren, useCallback } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ApiKeyResource } from '@endorlabs/queries';
import {
  ButtonPrimary,
  CodeBlock,
  IconAlertTriangle,
  useCopyToClipboard,
} from '@endorlabs/ui-common';

const buildApiKeyClipboardText = (apiKey: ApiKeyResource) => `\
# Endor API Key: ${apiKey.meta.name}
# Expires: ${new Date(apiKey.spec.expiration_time).toISOString()}
ENDOR_API_CREDENTIALS_KEY: ${apiKey.spec.key}
ENDOR_API_CREDENTIALS_SECRET: ${apiKey.spec.secret}
`;

export type ApiKeyPreviewModalProps = PropsWithChildren<{
  apiKey?: ApiKeyResource;
  open: boolean;
  onClose: () => void;
}>;

export const ApiKeyPreviewModal = ({
  apiKey,
  open,
  onClose,
}: ApiKeyPreviewModalProps) => {
  const { formatMessage: fm } = useIntl();
  const [isCopied, copyToClipboard, setIsCopied] = useCopyToClipboard(
    apiKey
      ? buildApiKeyClipboardText(apiKey)
      : fm({ defaultMessage: 'Failed to copy API Key' })
  );

  const handleCopyApiKey = useCallback(() => {
    copyToClipboard();

    // ensure the copied state is cleared
    setTimeout(() => setIsCopied(false), 1800);
  }, [copyToClipboard, setIsCopied]);

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FM defaultMessage="Copy Your API Key" />

          {onClose && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ marginRight: -2 }}
            >
              <IconClose />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        {apiKey && (
          <Stack spacing={4}>
            <Alert
              severity="error"
              variant="outlined"
              icon={<IconAlertTriangle />}
            >
              <FM defaultMessage="Save your API secret in a safe place. You will not be able to access it again from here." />
            </Alert>
            <Stack>
              <Typography color="text.secondary" variant="h4">
                <FM defaultMessage="API Key ID" />
              </Typography>

              <CodeBlock value={apiKey.spec.key ?? ''} />
            </Stack>

            <Stack>
              <Typography color="text.secondary" variant="h4">
                <FM defaultMessage="API Secret" />
              </Typography>
              <CodeBlock value={apiKey.spec.secret ?? ''} />
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonPrimary onClick={handleCopyApiKey} disabled={isCopied}>
          {isCopied ? (
            <FM defaultMessage="API Key &amp; Secret Copied" />
          ) : (
            <FM defaultMessage="Copy API Key &amp; Secret" />
          )}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};
