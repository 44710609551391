import { Box, Stack, Theme } from '@mui/material';

import { ProjectResource } from '@endorlabs/endor-core/Project';
import { LinkSecondary, ProjectNameDisplayV2 } from '@endorlabs/ui-common';

type ProjectLocationDisplayProps = {
  project: ProjectResource;
  vulnLocation: string | undefined;
};
export const ProjectLocationDisplay = ({
  project,
  vulnLocation,
}: ProjectLocationDisplayProps) => {
  if (!project && !vulnLocation) {
    return null;
  }
  const projectName = project?.meta.name ?? '';
  return (
    <Stack justifyContent={'space-evenly'}>
      <ProjectNameDisplayV2
        project={{
          meta: {
            name: projectName,
          },
          spec: {
            platform_source: project?.spec?.platform_source,
          },
          uuid: project?.uuid,
        }}
        showIcon
        size="xsmall"
      />
      <Box
        sx={({ typography, palette }: Theme) => ({
          color: palette.text.secondary,
          fontSize: typography.body2.fontSize,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <LinkSecondary to={vulnLocation} target="_blank">
          {vulnLocation}
        </LinkSecondary>
      </Box>
    </Stack>
  );
};
