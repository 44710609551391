import { getEnv } from '../../../constants';
import { getProjectDetailPath } from '../../../routes';
import { BasicTourStepProps } from '../types';
import { clickOn, waitForElementAvailable } from '../utils';

const ENV = getEnv();

const selSecretsButton = `button[value="FINDING_SOURCE_SECRETS"]`;
const selNav = '#app-primary-nav [data-testid="AppDrawerNavItem-projects"]';

export const getSecretsStep = ({ navigate, tourData }: BasicTourStepProps) => {
  const selFinding = `#FindingSummaryAccordion-${tourData.secretsFindingUuid}-header`;
  const fullFinding = `#FindingSummaryAccordion-${tourData.secretsFindingUuid}`;

  return {
    attachTo: {
      element: fullFinding,
      on: 'top-end',
    },

    beforeShowPromise: async function () {
      navigate({
        to: getProjectDetailPath({
          tenantName: tourData.tourTenant,
          uuid: tourData.findingsProject?.uuid,
          section: 'findings',
        }),
      });

      await clickOn(selSecretsButton);
      await waitForElementAvailable(selFinding);
      await clickOn(selFinding);
    },
    title: 'Detect Exposed Secrets',
    description:
      'Scan your source code repositories for valid secrets so that your teams can proactively manage the potential exposure of secret leaks.',
    highlightSelectors: [selNav, selSecretsButton, fullFinding],
    urlLearn: `${ENV.URL_ENDOR_DOCS}/secrets-leak-detection/`,
  };
};
