import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { wrap } from 'lodash-es';

import { V1PlatformSource } from '@endorlabs/api_client';
import { RowStack, UIProjectUtils } from '@endorlabs/ui-common';

interface ProjectDetailDrawerHeaderProps {
  isLoading: boolean;
  namespace: string;
  name?: string;
  platformSource?: V1PlatformSource;
}

export const ProjectDetailDrawerHeader = ({
  isLoading,
  namespace,
  name,
  platformSource,
}: ProjectDetailDrawerHeaderProps) => {
  const PlatformIcon = UIProjectUtils.getPlatformIcon(platformSource);
  const projectName = UIProjectUtils.parseProjectName(
    name ?? '',
    platformSource
  );
  return (
    <RowStack alignItems="flex-start" flexWrap="nowrap">
      {isLoading ? (
        <>
          <Skeleton height={40} width={44} />
          <Skeleton height={40} width="60%" />
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              padding: 2,
              border: `1px solid #CCC`,
              borderRadius: '4px',
            }}
          >
            <PlatformIcon fontSize="small" />
          </Box>

          <Stack direction="column">
            <Typography component="span" variant="h6">
              {projectName}
            </Typography>

            <Typography
              component="span"
              variant="caption"
              color="text.secondary"
            >
              {namespace}
            </Typography>
          </Stack>
        </>
      )}
    </RowStack>
  );
};
