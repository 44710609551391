import { Card, CardContent, Grid, useTheme } from '@mui/material';
import { MakeGenerics } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { getFindingTitle } from '@endorlabs/endor-core/Finding';
import {
  useGetDependencyMetadata,
  useGetFinding,
  useGetPackageVersion,
  useGetProject,
} from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  EmptyState,
  IconTelescope,
  NavigationTabs,
} from '@endorlabs/ui-common';

import { FindingDetail, PageHeader } from '../../../components';
import { FindingCallPaths } from '../../../domains/Findings';
import { useAuthInfo } from '../../../providers';
import { getFindingsPath, useFullMatch, usePageTitle } from '../../../routes';
import {
  FindingDetailPageTabName,
  FindingDetailPageTabNames,
  useFindingDetailPageTabs,
} from './useFindingDetailPageTabs';
import { getFindingDetailMetadata, getFindingErrorMessage } from './utils';

type FindingDetailPageLocation = MakeGenerics<{
  Params: {
    findingUuid: string;
    activeView: FindingDetailPageTabName;
  };
}>;

export const FindingDetailPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { findingUuid, activeView },
  } = useFullMatch<FindingDetailPageLocation>();
  const theme = useTheme();

  const qFinding = useGetFinding({ namespace: tenantName, uuid: findingUuid });

  const findingResource = qFinding.data;

  const qProject = useGetProject(
    { namespace: tenantName, uuid: findingResource?.spec.project_uuid ?? '' },
    { enabled: qFinding.isSuccess && !!findingResource?.spec.project_uuid }
  );

  const qPackageVersion = useGetPackageVersion(
    { namespace: tenantName, uuid: findingResource?.meta.parent_uuid ?? '' },
    {
      enabled:
        qFinding.isSuccess &&
        findingResource?.meta.parent_kind === 'PackageVersion',
    }
  );

  const qTargetDependencyMetadata = useGetDependencyMetadata(
    { namespace: tenantName, uuid: findingResource?.spec.target_uuid ?? '' },
    {
      enabled: qFinding.isSuccess && !!findingResource?.spec.target_uuid,
    }
  );

  const findingTitle = findingResource ? (
    getFindingTitle(findingResource)
  ) : (
    <FM defaultMessage="Unknown Finding" />
  );

  const findingMetadataSummary = getFindingDetailMetadata(
    findingResource,
    qProject.data,
    qPackageVersion.data
  );

  usePageTitle({ findingName: findingTitle });

  const { tabRecords, activeTab } = useFindingDetailPageTabs({
    activeTab: activeView,
  });

  const isLoading = qFinding.isLoading;
  const hasError = !isLoading && qFinding.isError;

  // handle errors for finding
  if (hasError) {
    const { title, description } = getFindingErrorMessage(qFinding.error);
    return (
      <EmptyState size="large" title={title} description={description}>
        <ButtonLinkPrimary linkProps={{ to: getFindingsPath({ tenantName }) }}>
          <FM defaultMessage="View All Findings" />
        </ButtonLinkPrimary>
      </EmptyState>
    );
  }

  return (
    <Grid
      container
      direction="column"
      flexWrap="nowrap"
      spacing={theme.space.md}
    >
      <Grid item>
        <PageHeader
          breadcrumbsLinks={[
            {
              label: <FM defaultMessage="All Findings" />,
              url: getFindingsPath({ tenantName }),
            },
          ]}
          Icon={IconTelescope}
          isLoading={qFinding.isLoading}
          title={findingTitle}
          metadata={{ summary: findingMetadataSummary }}
        />
      </Grid>

      <Grid item>
        <NavigationTabs tabs={tabRecords} activeTab={activeTab} />
      </Grid>

      {activeTab === FindingDetailPageTabNames.OVERVIEW && (
        <Grid item>
          <Card>
            <CardContent>
              {findingResource && (
                <FindingDetail
                  finding={findingResource}
                  project={qProject.data}
                  sourcePackageVersion={qPackageVersion.data}
                  targetDependencyMetadata={qTargetDependencyMetadata.data}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      )}

      {activeTab === FindingDetailPageTabNames.CALL_PATHS && (
        <Grid item>
          <Card>
            <CardContent>
              <FindingCallPaths
                finding={findingResource}
                findingPackageVersion={qPackageVersion.data}
                findingProject={qProject.data}
                isLoading={isLoading}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
