import { SxProps, Theme, useTheme } from '@mui/material';
import { useMemo } from 'react';

export type UseStylesFunction<T> = (theme: Theme, options?: T) => SxProps;

export const useStyles = <T,>(stylesFn: UseStylesFunction<T>, options?: T) => {
  const theme = useTheme();

  return useMemo(() => {
    return stylesFn(theme, options);
  }, [options, stylesFn, theme]);
};
