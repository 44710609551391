import { QueryKey, useQuery } from 'react-query';

import {
  MetricServiceApi,
  V1CountResponse,
  V1ListParameters,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';
import { MetricResource } from '@endorlabs/endor-core/Metric';

import { useBuildReadRequestParameters } from './hooks';
import { ResourceQueryOptions, TResourceList } from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  getClientConfiguration,
} from './utils';

type MetricResourceList = TResourceList<MetricResource>;

type CountMetricOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type GetMetricOptions = ResourceQueryOptions<MetricResource>;
type ListMetricOptions = ResourceQueryOptions<MetricResourceList>;

const BASE_KEY = 'v1/metrics';
const QK = {
  count: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, listParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  listAll: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list-all', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};

export const MetricsQueryKeys = QK;

export const METRIC_UPDATE_MASK = 'meta,spec';

const getApiService = () => new MetricServiceApi(getClientConfiguration());

const countMetrics = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.metricServiceListMetrics(
    namespace,
    ...buildCountParamArgs(listParams),
    { signal }
  );
  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountMetrics = (
  namespace: string,
  listParams?: CountRequestParameters,
  opts?: CountMetricOptions
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Metric',
    'COUNT',
    listParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    (ctx) => countMetrics(namespace, requestParameters, ctx.signal),
    opts
  );
};

const listMetrics = async (
  namespace: string,
  listParams: V1ListParameters = {},
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.metricServiceListMetrics(
    namespace,
    ...buildListParamArgs(listParams),
    { signal }
  );
  return resp.data as MetricResourceList;
};

export const useListMetrics = (
  namespace: string,
  opts: ListMetricOptions = {},
  listParams?: ListRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Metric',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    (ctx) => listMetrics(namespace, requestParameters, ctx.signal),
    opts
  );
};

const getMetric = async (namespace: string, metricUuid: string) => {
  const api = getApiService();
  const resp = await api.metricServiceGetMetric(namespace, metricUuid);
  return resp.data as MetricResource;
};

export const useGetMetric = (
  namespace: string,
  metricUuid: string,
  opts: GetMetricOptions = {}
) => {
  return useQuery(
    QK.record(namespace, metricUuid),
    () => getMetric(namespace, metricUuid),
    opts
  );
};
