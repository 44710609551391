import {
  Alert,
  AlertTitle,
  Autocomplete,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { matchSorter } from 'match-sorter';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1PlatformSource } from '@endorlabs/api_client';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { sortParamBuilders, useListProjects } from '@endorlabs/queries';
import {
  ButtonPrimary,
  ButtonStack,
  ProjectNameDisplayV2,
  UIProjectUtils,
} from '@endorlabs/ui-common';

export type AddScanProfileProjectDialogContentProps = {
  onSubmit: (project: ProjectResource) => void;
  scanProfile: ScanProfileResource;
};

export const AddScanProfileProjectDialogContent = ({
  onSubmit,
  scanProfile,
}: AddScanProfileProjectDialogContentProps) => {
  const { space } = useTheme();
  const [inputValue, setInputValue] = useState('');

  const [inputValueDebounced, setInputValueDebounced] = useState('');
  useEffect(() => {
    const timer = setTimeout(() => {
      setInputValueDebounced(inputValue);
    }, 1_000);

    return () => clearTimeout(timer);
  }, [inputValue]);

  const filterExpression = useMemo(() => {
    const expressions = [
      // `spec.platform_source in [${[
      //   V1PlatformSource.Bitbucket,
      //   V1PlatformSource.Github,
      //   V1PlatformSource.Gitlab,
      //   V1PlatformSource.Gitserver,
      // ]}]`,
    ];

    if (inputValueDebounced) {
      expressions.push(
        filterExpressionBuilders.or([
          `meta.name matches "${inputValueDebounced}"`,
          `meta.tags matches "${inputValueDebounced}"`,
        ])
      );
    }

    return filterExpressionBuilders.and(expressions);
  }, [inputValueDebounced]);

  const qListProjects = useListProjects(
    scanProfile.tenant_meta.namespace,
    {},
    {
      mask: [
        'meta.name',
        'tenant_meta',
        'uuid',
        'spec.platform_source',
        'spec.scan_profile_uuid',
      ].join(','),
      traverse: false,
      filter: filterExpression,
      sort: sortParamBuilders.descendingBy('meta.update_time'),
    }
  );

  const projects = useMemo(() => {
    const projects = qListProjects.data?.list?.objects ?? [];
    return matchSorter(projects, inputValueDebounced, {
      keys: ['meta.name', 'meta.tags'],
    });
  }, [inputValueDebounced, qListProjects.data]);

  const [selectedProject, setSelectedProject] =
    useState<ProjectResource | null>(null);

  const hasScanProfile = !!selectedProject?.spec.scan_profile_uuid;

  const handleAddScanProfile = () => {
    if (!selectedProject) return;
    onSubmit(selectedProject);
  };

  return (
    <Stack gap={space.md}>
      <Autocomplete
        filterOptions={(i) => i}
        getOptionLabel={(p) =>
          UIProjectUtils.parseProjectName(
            p.meta.name,
            p.spec.platform_source
          ) ?? p.meta.name
        }
        isOptionEqualToValue={(a, b) => a.uuid === b.uuid}
        loading={qListProjects.isLoading}
        noOptionsText={<FM defaultMessage="No projects found" />}
        onChange={(_, value) => setSelectedProject(value)}
        onInputChange={(_, value) => setInputValue(value)}
        options={projects}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Project Name"
            placeholder="Search for a Project by name or tags"
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <ProjectNameDisplayV2 project={option} />
            </li>
          );
        }}
        value={selectedProject}
      />

      {hasScanProfile && (
        <Alert severity="error">
          <AlertTitle>
            <FM defaultMessage="Project has a Scan Profile already set." />
          </AlertTitle>
        </Alert>
      )}

      <ButtonStack>
        <ButtonPrimary
          disabled={!selectedProject || hasScanProfile}
          onClick={handleAddScanProfile}
        >
          <FM defaultMessage="Add to Scan Profile" />
        </ButtonPrimary>
      </ButtonStack>
    </Stack>
  );
};
