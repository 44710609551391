import { useNavigate } from '@tanstack/react-location';
import _sortBy from 'lodash-es/sortBy';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Policy } from '@endorlabs/api_client';
import {
  IQueryError,
  useGetPolicy,
  useListPolicyTemplates,
} from '@endorlabs/queries';
import { useAppNotify } from '@endorlabs/ui-common';

import { getPoliciesPath } from '../../../routes';
import {
  AllowedTemplateTypesByPolicyUmbrellaType,
  PolicyUmbrellaType,
} from '../types';

interface UseEditPolicyPageProps {
  namespace: string;
  policyUuid?: string;
  policyTemplateName?: string;
  policyTemplateUuid?: string;
  policyUmbrellaType: PolicyUmbrellaType;
}

/**
 * Given information from the URL, performs & returns all queries needed to drive the policy create/edit form
 */
export const useEditPolicyPage = ({
  namespace,
  policyUuid,
  policyTemplateName,
  policyTemplateUuid,
  policyUmbrellaType,
}: UseEditPolicyPageProps) => {
  const navigate = useNavigate();
  const addAppNotification = useAppNotify();

  const isEditing = Boolean(policyUuid);
  const policyTemplateTypes =
    AllowedTemplateTypesByPolicyUmbrellaType[policyUmbrellaType];

  const qGetPolicy = useGetPolicy(
    { namespace, uuid: policyUuid as string },
    { enabled: isEditing }
  );
  const policy = useMemo(() => qGetPolicy?.data, [qGetPolicy.data]);

  // Retrieve policy templates relevant
  const qListPolicyTemplates = useListPolicyTemplates(
    namespace,
    { staleTime: Infinity },
    { filter: `spec.policy_type in [${policyTemplateTypes.join(',')}]` }
  );

  const policyTemplates = useMemo(
    () =>
      _sortBy(qListPolicyTemplates.data?.list?.objects ?? [], ['meta.name']),
    [qListPolicyTemplates.data]
  );

  // Find a Policy Template matching the given UUID or Name
  const activePolicyTemplate = useMemo(
    () =>
      policyTemplates.find(
        (pt) =>
          pt.uuid === policyTemplateUuid || pt.meta.name === policyTemplateName
      ),
    [policyTemplates, policyTemplateUuid, policyTemplateName]
  );

  const onFormSubmitSuccess = (policyData: V1Policy) => {
    addAppNotification({
      message: (
        <FM
          defaultMessage={`Policy {policyName} Successfully {action}`}
          values={{
            action: isEditing ? 'Updated' : 'Created',
            policyName: `"${policyData?.meta?.name}"`,
          }}
        />
      ),
      severity: 'success',
    });

    setTimeout(() => {
      navigate({
        to: getPoliciesPath({
          tenantName: namespace,
          section: policyUmbrellaType,
        }),
      });
    }, 1200);
  };

  const onFormSubmitError = (err: IQueryError) => {
    addAppNotification({
      details: err?.response?.data?.message,
      message: (
        <FM
          defaultMessage={`Error {action} Policy`}
          values={{ action: isEditing ? 'Updating' : 'Creating' }}
        />
      ),
      severity: 'error',
    });
  };

  return {
    activePolicyTemplate,
    isEditing,
    onFormSubmitError,
    onFormSubmitSuccess,
    policy,
    policyTemplates,
  };
};
