import { MenuItem } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { JIRAConfigJiraIssueType } from '@endorlabs/api_client';

import {
  ControlledLabelsField,
  ControlledTextField,
} from '../../../components';
import { REGEX_URL_VALIDATION } from '../../../constants';
import { FormUpsertNotificationTargetFieldValues } from './FormUpsertNotificationTarget/types';

const JIRA_ISSUE_TYPES = [
  {
    label: <FM defaultMessage="Task" />,
    value: JIRAConfigJiraIssueType.Task,
  },
  {
    label: <FM defaultMessage="Bug" />,
    value: JIRAConfigJiraIssueType.Bug,
  },
];

// The fields for Jira action are a subset of _all_ form fields
type JiraConfigFieldValues = Exclude<
  FormUpsertNotificationTargetFieldValues['spec']['action']['jira_config'],
  undefined
>;

type JiraActionTypeFieldValues = {
  spec: {
    action: {
      jira_config: Pick<
        JiraConfigFieldValues,
        | 'user_name'
        | 'api_key'
        | 'url'
        | 'project_key'
        | 'issue_type'
        | 'labels'
      >;
    };
  };
};

/**
 * Form fields for Jira action type. To be used with {@see FormUpsertNotificationTarget}
 */
export const JiraActionTypeFields = () => {
  const { control } = useFormContext<JiraActionTypeFieldValues>();
  const { formatMessage: fm } = useIntl();

  return (
    <>
      <ControlledTextField
        control={control}
        defaultValue=""
        label={fm({ defaultMessage: 'Username' })}
        helperText={fm({
          defaultMessage:
            ' Any Jira issues created by Endor Labs will appear as being created by this user account',
        })}
        name="spec.action.jira_config.user_name"
        placeholder={fm({
          defaultMessage: 'What Jira user do you want to authenticate as?',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
          minLength: {
            value: 1,
            message: fm({ defaultMessage: 'A non-empty value is required' }),
          },
          maxLength: {
            value: 1_024,
            message: fm({
              defaultMessage: 'The value must be 1024 characters or less',
            }),
          },
        }}
      />

      <ControlledTextField
        control={control}
        defaultValue=""
        label={fm({ defaultMessage: 'API Key' })}
        name="spec.action.jira_config.api_key"
        helperText={fm({
          defaultMessage:
            'Make sure this grants the ability to create new issues in the Jira Project to which notifications will be sent',
        })}
        placeholder={fm({
          defaultMessage: 'Provide a API key for Jira',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
          minLength: {
            value: 1,
            message: fm({ defaultMessage: 'A non-empty value is required' }),
          },
          maxLength: {
            value: 65_536,
            message: fm({
              defaultMessage: 'The value must be 1024 characters or less',
            }),
          },
        }}
        type="password"
      />

      <ControlledTextField
        control={control}
        defaultValue=""
        label={fm({ defaultMessage: 'Jira URL' })}
        name="spec.action.jira_config.url"
        helperText={fm({
          defaultMessage: 'The HTTP endpoint of your Jira instance',
        })}
        placeholder={fm({
          defaultMessage: 'https://mycompany.atlassian.net/',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
          minLength: {
            value: 1,
            message: fm({ defaultMessage: 'A non-empty value is required' }),
          },
          maxLength: {
            value: 1_024,
            message: fm({
              defaultMessage: 'The value must be 1024 characters or less',
            }),
          },
          pattern: {
            value: REGEX_URL_VALIDATION,
            message: fm({
              defaultMessage: 'Must be a valid URL',
            }),
          },
          validate: (_value: unknown) => {
            const value = String(_value);
            if (value.length > 1 && !value.endsWith('/')) {
              return fm({
                defaultMessage:
                  'The endpoint value must end with a trailing slash (`/`)',
              });
            }
          },
        }}
      />

      <ControlledTextField
        control={control}
        defaultValue=""
        label={fm({ defaultMessage: 'Project Key' })}
        name="spec.action.jira_config.project_key"
        helperText={fm({
          defaultMessage: 'Notifications will create issues in this project',
        })}
        placeholder={fm({
          defaultMessage: 'MYPROJECT',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
          minLength: {
            value: 1,
            message: fm({ defaultMessage: 'A non-empty value is required' }),
          },
          maxLength: {
            value: 1_024,
            message: fm({
              defaultMessage: 'The value must be 1024 characters or less',
            }),
          },
        }}
      />

      <ControlledTextField
        control={control}
        defaultValue={JIRA_ISSUE_TYPES[0].value}
        label={fm({ defaultMessage: 'Issue Type' })}
        name="spec.action.jira_config.issue_type"
        helperText={fm({
          defaultMessage: 'Notifications will create issues with this type',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
        }}
        select
      >
        {JIRA_ISSUE_TYPES.map(({ label, value }, index) => (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        ))}
      </ControlledTextField>

      <ControlledLabelsField
        control={control}
        defaultValue={[]}
        name="spec.action.jira_config.labels"
        label={fm({ defaultMessage: 'Labels' })}
        helperText={fm({
          defaultMessage:
            'These will be added to any Jira issues created as the result of an alert',
        })}
      />
    </>
  );
};
