import { Typography } from '@mui/material';
import { ColumnSort } from '@tanstack/react-table';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';
import { sortParamBuilders } from '@endorlabs/queries';
import {
  BulkActionRecord,
  ButtonCancel,
  CommonDataTableRowActions,
  DataTableView,
  FlexList,
  FlexListItem,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { FilterBar, useFilterContext } from '../../../filters';
import {
  REMEDIATIONS_FILTER_FIELDS,
  VersionUpgradeTableSortKeys,
} from '../../constants';
import { useRemediationsIndexPageData } from '../../hooks';
import { buildRemediationsTableColumns } from '../../utils';
import { useRemediationDetailDrawer } from '../RemediationDetailDrawer';
import {
  RemediationsTableColumnId,
  RemediationsTableRow,
} from '../RemediationsTable/types';

export type RemediationsTableViewProps = {
  actions: BulkActionRecord<RemediationsTableRow>[];
  baseFilterExpression: string;
  enableFilters?: boolean;
  enablePagination?: boolean;
  excludeColumns?: RemediationsTableColumnId[];
  isLoading?: boolean;
  tenantName: string;
};

export type RemediationsTableViewRef = {
  remediationsFilterExpression: string;
  refreshData: () => void;
};

const DEFAULT_COLUMN_SORT: ColumnSort = {
  desc: true,
  id: VersionUpgradeTableSortKeys.score,
};

export const RemediationsTableView = forwardRef(
  function BaseRemediationsTableView(
    {
      actions,
      baseFilterExpression,
      enableFilters = true,
      enablePagination = true,
      excludeColumns,
      tenantName,
    }: RemediationsTableViewProps,
    ref: ForwardedRef<RemediationsTableViewRef>
  ) {
    const { DetailDrawer } = useRemediationDetailDrawer();

    const { clearFilter, filter: userFilterExpression } = useFilterContext();

    const remediationsFilterExpression = useMemo(() => {
      if (!userFilterExpression) {
        return baseFilterExpression;
      }

      return filterExpressionBuilders.and([
        baseFilterExpression,
        userFilterExpression,
      ]);
    }, [baseFilterExpression, userFilterExpression]);

    const [columnSort, setColumnSort] = useState<ColumnSort | undefined>(
      DEFAULT_COLUMN_SORT
    );

    const handleSortChange = (columnSort?: ColumnSort | undefined) => {
      setColumnSort(columnSort);
    };

    const sortParam = useMemo(() => {
      if (columnSort) {
        const columnId = VersionUpgradeTableSortKeys[columnSort.id];
        if (columnId) {
          return columnSort.desc
            ? sortParamBuilders.descendingBy(columnId)
            : sortParamBuilders.ascendingBy(columnId);
        }
      }
      return sortParamBuilders.descendingBy(DEFAULT_COLUMN_SORT.id);
    }, [columnSort]);

    const paginator = useDataTablePaginator({
      isInfinite: true,
      hasNextPage: () => !!qVersionUpgrades.data?.response?.next_page_token,
    });

    // Reset pagination on filter or tenant change.
    // NOTE: with infinite pagination, the paginator is not reset on the total
    // count change when filters are applied
    useEffect(
      () => {
        paginator.resetPagination();
      },
      // ignore changes from paginator outside of the reset handler
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [paginator.resetPagination, remediationsFilterExpression, tenantName]
    );

    const { upgradesCount, upgradesList, isCountLoading, ...qVersionUpgrades } =
      useRemediationsIndexPageData({
        baseFilterExpression,
        namespace: tenantName,
        paginator,
        sortParam,
        userFilterExpression: remediationsFilterExpression,
      });

    const rowActions = [
      {
        ...CommonDataTableRowActions.ACTIVATE_DRAWER,
        isPrimaryAction: true,
        onClick: (row: RemediationsTableRow) => {
          DetailDrawer.activate(
            {
              namespace: tenantName,
              upgradeUuid: row?.uuid,
            },
            {
              namespace: tenantName,
              upgradeUuid: row?.uuid,
            }
          );
        },
      },
    ];

    const handleRefresh = useCallback(() => {
      qVersionUpgrades.refetch();
    }, [qVersionUpgrades]);

    useImperativeHandle(
      ref,
      () => {
        return {
          // Allow parent components to refresh the data
          refreshData: handleRefresh,
          // Allow parent components to access the filter expression for export
          remediationsFilterExpression,
        };
      },
      [handleRefresh, remediationsFilterExpression]
    );

    const emptyStateProps = {
      title: <FM defaultMessage="No Remediations match the filter criteria" />,
      children: (
        <FlexList
          divider={
            <Typography color="text.secondary">
              <FM defaultMessage="or" />
            </Typography>
          }
          justifyContent="center"
        >
          {userFilterExpression && (
            <FlexListItem>
              <ButtonCancel onClick={clearFilter}>
                <FM defaultMessage="Clear Filters" />
              </ButtonCancel>
            </FlexListItem>
          )}
          {paginator.state.pageIndex > 0 && (
            <FlexListItem>
              <ButtonCancel onClick={() => paginator.resetPagination()}>
                <FM defaultMessage="Go to First Page of Results" />
              </ButtonCancel>
            </FlexListItem>
          )}
        </FlexList>
      ),
    };

    return (
      <DataTableView
        bulkActions={actions}
        columns={buildRemediationsTableColumns({ excludeColumns })}
        countMessage={
          <FM
            defaultMessage="{count, number} Remediations"
            values={{ count: upgradesCount ?? 0 }}
          />
        }
        data={upgradesList}
        emptyStateProps={emptyStateProps}
        enableColumnSort
        filtersContent={
          enableFilters && (
            <FilterBar fields={REMEDIATIONS_FILTER_FIELDS} isCard={false} />
          )
        }
        isLoading={qVersionUpgrades.isLoading}
        namespace={tenantName}
        onColumnSort={handleSortChange}
        paginator={enablePagination ? paginator : undefined}
        rowActions={rowActions}
      />
    );
  }
);
