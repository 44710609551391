import { Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { CiCdToolEvidence } from '@endorlabs/api_client';

export const ToolEvidenceBadgesDisplay = ({
  evidences,
}: {
  evidences?: CiCdToolEvidence[];
}) => {
  const { palette, spacing } = useTheme();
  if (!evidences) {
    return;
  }
  return (
    <Stack spacing={spacing(4)}>
      <Typography variant="h3">
        <FM defaultMessage="Badges" />
      </Typography>
      <Stack spacing={spacing(2)}>
        {evidences.map((evidence) => {
          const badgeUrl = evidence.badge;

          return (
            <Typography
              key={badgeUrl}
              color={palette.info.main}
              noWrap
              paddingRight={4}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {badgeUrl}
            </Typography>
          );
        })}
      </Stack>
    </Stack>
  );
};
