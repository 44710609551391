import {
  CircularProgress,
  Stack,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  tryParseGroupResponseAggregationKey,
  useListDependencyMetadata,
} from '@endorlabs/queries';
import {
  BarChartHorizontal,
  EmptyState,
  TitleActionHeader,
  ToggleButton,
} from '@endorlabs/ui-common';

import { FIVE_MINUTES_IN_MILLISECONDS } from '../../constants';
import { useAuthInfo } from '../../providers';
import { getDependencyPath } from '../../routes';

enum Usage {
  MOST_USED = 'most-used',
  LEAST_USED = 'least-used',
}

export const DependencyImportsVis = ({
  height,
}: {
  height: number;
  width?: number;
  fontSize?: number;
}) => {
  const { activeNamespace: tenantName } = useAuthInfo();

  const navigate = useNavigate();
  const { space } = useTheme();

  const [usage, setUsage] = useState<Usage>(() => Usage.MOST_USED);

  const qListDependencyMetadata = useListDependencyMetadata(
    tenantName,
    { staleTime: FIVE_MINUTES_IN_MILLISECONDS },
    {
      filter: filterExpressionBuilders.mainResourceContext(),
      group: {
        aggregation_paths: 'meta.name',
      },
    }
  );

  const dependencyList = useMemo(() => {
    const dependencyList = Object.entries(
      qListDependencyMetadata.data?.group_response?.groups ?? {}
    )
      .map(([key, group]) => {
        const aggregationValues = tryParseGroupResponseAggregationKey(key);
        const count = group.aggregation_count?.count as number;

        const name = aggregationValues.find((av) => av.key === 'meta.name')
          ?.value as string;

        return {
          name,
          count,
          url: getDependencyPath({
            tenantName,
            dependencyPackageVersionName: name,
          }),
        };
      })
      .filter((d) => !!d.name);

    return dependencyList;
  }, [qListDependencyMetadata.data, tenantName]);

  const sortedDependencyList = useMemo(() => {
    // sort the initial list of dependencies
    if (usage === Usage.MOST_USED) {
      dependencyList.sort((a, b) => b.count - a.count);
    } else {
      dependencyList.sort((a, b) => a.count - b.count);
    }

    return dependencyList.slice(0, 8);
  }, [dependencyList, usage]);

  const handleLevelToggle = (
    event: React.MouseEvent<HTMLElement>,
    newValue: Usage | null
  ) => {
    if (newValue !== null) {
      setUsage(newValue);
    }
  };

  const handleDependencyClick = (
    data: Record<string, string | number | null>
  ) => {
    navigate({ to: data.url });
  };

  const isLoading = qListDependencyMetadata.isLoading;
  const isSuccess = qListDependencyMetadata.isSuccess;
  const isEmptyState = !isLoading && isSuccess && dependencyList.length === 0;

  return (
    <div style={{ position: 'relative' }} data-testid="dependency-imports">
      <Stack spacing={space.sm}>
        <TitleActionHeader
          action={
            <ToggleButtonGroup
              size="small"
              exclusive
              value={usage}
              onChange={handleLevelToggle}
            >
              <ToggleButton value={Usage.MOST_USED}>
                <FM defaultMessage="Most Used" />
              </ToggleButton>
              <ToggleButton value={Usage.LEAST_USED}>
                <FM defaultMessage="Least Used" />
              </ToggleButton>
            </ToggleButtonGroup>
          }
          title={<FM defaultMessage="Most Frequently Used Dependencies" />}
          variant="h2"
        />

        <Stack height={height} justifyContent="center" alignItems="center">
          {isLoading && <CircularProgress />}
          {isEmptyState && (
            <EmptyState
              size="medium"
              imageWidth={300}
              textAlign="center"
              title={
                <FM defaultMessage="No dependencies meet the filter criteria" />
              }
            />
          )}
          {!isLoading && !isEmptyState && (
            <ParentSize debounceTime={100}>
              {({ width: visWidth, height: visHeight }) => (
                <BarChartHorizontal
                  data={sortedDependencyList}
                  width={visWidth}
                  height={visHeight}
                  xKey="count"
                  yKey="name"
                  axisLeft={false}
                  axisBottom={false}
                  barPadding={0.4}
                  showBarTitles={true}
                  barTitleFontSize={10}
                  showGrid={false}
                  margin={{ top: 4, left: 0, bottom: 4, right: 40 }}
                  onClickHandler={handleDependencyClick}
                />
              )}
            </ParentSize>
          )}
        </Stack>
      </Stack>
    </div>
  );
};
