import { V1FindingCategory, V1FindingTags } from '@endorlabs/api_client';

import { FindingResource } from '../types';

const getCategoryCheckFn = (category: V1FindingCategory) => {
  return (finding: Partial<FindingResource>): boolean =>
    (finding.spec?.finding_categories ?? []).includes(category);
};

/** Helpers to determine finding category */
export const isSecretsFinding = getCategoryCheckFn(V1FindingCategory.Secrets);

export const isSecurityFinding = getCategoryCheckFn(V1FindingCategory.Security);

export const isOperationalFinding = getCategoryCheckFn(
  V1FindingCategory.Operational
);

export const isVulnerabilityFinding = getCategoryCheckFn(
  V1FindingCategory.Vulnerability
);

export const isCicdFinding = getCategoryCheckFn(V1FindingCategory.Cicd);

export const isMalwareFinding = getCategoryCheckFn(V1FindingCategory.Malware);

export const isReachableDependency = (finding: FindingResource): boolean => {
  return [
    V1FindingTags.ReachableDependency,
    V1FindingTags.ReachableFunction,
  ].some((tag) => finding.spec.finding_tags.includes(tag));
};

export const isSelfFinding = (finding: FindingResource): boolean =>
  finding.spec.finding_tags.includes(V1FindingTags.Self);

export const isPackageFinding = (finding: FindingResource): boolean =>
  finding.meta.parent_kind === 'PackageVersion';
