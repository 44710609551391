import { KeysMatching } from '@endorlabs/utils/types';

import { DashboardConfigValue } from '../../hooks';

type DashboardConfigValueNumericKeys = KeysMatching<
  DashboardConfigValue,
  number
>;

export const cleanDashboardConfigValueFields = (
  fieldValues: DashboardConfigValue
): DashboardConfigValue => {
  const numericValueKeys: DashboardConfigValueNumericKeys[] = [
    'devHours',
    'epssProbabilityThreshold',
    'hourlyCost',
  ];

  const clean: DashboardConfigValue = { ...fieldValues };
  for (const key of numericValueKeys) {
    clean[key] = Number(fieldValues[key]);
  }

  return clean;
};
