import { FormattedMessage as FM } from 'react-intl';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { ButtonPrimary, useDialog } from '@endorlabs/ui-common';

import { useLicensingInfo } from '../../../providers';
import { PatchConfigurationDialog } from './PatchConfigurationDialog';

/**
 * Helper component to open the Patch Configuration Dialog
 */
export const PatchConfigurationDialogButton = ({
  namespace,
}: {
  namespace: string;
}) => {
  const { checkLicensePresent } = useLicensingInfo();
  const hasEndorPatchesLicense = checkLicensePresent(
    SpecEndorLicenseFeatureType.MagicPatching
  );

  const patchConfigurationDialog = useDialog({
    component: PatchConfigurationDialog,
  });

  return (
    <>
      <ButtonPrimary
        onClick={() =>
          patchConfigurationDialog.openDialog({
            hasEndorPatchesLicense,
            namespace,
          })
        }
      >
        <FM defaultMessage="Patch Instructions" />
      </ButtonPrimary>

      <patchConfigurationDialog.Dialog
        {...patchConfigurationDialog.dialogProps}
      />
    </>
  );
};
