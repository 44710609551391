import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1ExportedSBOMFormat,
  V1ExportedVEXFormat,
} from '@endorlabs/api_client';
import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  IQueryErrorResponse,
  PackageVersionResource,
  useCreateSBOMExport,
  useCreateVEXExport,
  useListAllPackageVersions,
} from '@endorlabs/queries';
import {
  IconX,
  UIPackageVersionUtils,
  UIProjectUtils,
} from '@endorlabs/ui-common';

import {
  FormCreateExport,
  FormCreateSBOMExportFieldValues,
  FormCreateVEXExportFieldValues,
} from '../../components';
import { useOnboardingUserEventTracking } from '../../domains/Onboarding';

/**
 * Clean filename from a package version name
 */
const getFilenameForPackageVersion = (
  packageVersion: PackageVersionResource
) => {
  const { label, version } = UIPackageVersionUtils.parsePackageName(
    packageVersion.meta.name
  );
  return label.replace(/[^a-z0-9._-]+/i, '-') + '@' + version;
};

// get mime types and file extensions for either sbom or vex
const MIME_TYPES: Record<V1ExportedSBOMFormat | V1ExportedVEXFormat, string> = {
  FORMAT_UNSPECIFIED: 'text/plain',
  FORMAT_JSON: 'application/json',
  FORMAT_XML: 'application/xml',
};
const getMimeType = (format: V1ExportedSBOMFormat | V1ExportedVEXFormat) =>
  MIME_TYPES[format] ?? MIME_TYPES.FORMAT_UNSPECIFIED;

const FILE_EXTENSIONS: Record<
  V1ExportedSBOMFormat | V1ExportedVEXFormat,
  string
> = {
  FORMAT_UNSPECIFIED: 'txt',
  FORMAT_JSON: 'json',
  FORMAT_XML: 'xml',
};
const getFileExtension = (format: V1ExportedSBOMFormat | V1ExportedVEXFormat) =>
  FILE_EXTENSIONS[format] ?? FILE_EXTENSIONS.FORMAT_UNSPECIFIED;

// trigger download when new sbom or vex is generated
const downloadFile = (
  fileData: string,
  fileFormat: V1ExportedSBOMFormat | V1ExportedVEXFormat,
  fileType?: 'sbom' | 'vex' | undefined,
  packageVersionFilename?: string
) => {
  const generatedFile = {
    data: fileData,
    format: fileFormat,
    type: fileType,
  };
  if (!generatedFile || !packageVersionFilename) return;

  const { data, format, type } = generatedFile;
  const fileExtension = getFileExtension(format);

  const blob = new Blob([data], {
    type: getMimeType(format),
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download =
    type === 'sbom'
      ? `sbom_export_${packageVersionFilename}.${fileExtension}`
      : `vex_export_${packageVersionFilename}.${fileExtension}`;
  link.click();

  return window.URL.revokeObjectURL(url);
};

export type PackageExportDialogState =
  | {
      namespace: string;
      packageVersion: PackageVersionResource;
      project?: never;
      projectVersion?: never;
    }
  | {
      namespace: string;
      packageVersion?: never;
      project: ProjectResource;
      projectVersion: ProjectVersionResource;
    };

export type PackageExportDialogProps = {
  open: boolean;
  onClose?: () => void;
  state?: PackageExportDialogState | undefined;
};

/**
 * Dialog for Package SBOM Export
 *
 * Additional handling allows for exporting a single package from a given Project and Project Version
 */
export const PackageExportDialog = ({
  open,
  onClose,
  state,
}: PackageExportDialogProps) => {
  const [exportName, exportVersion] = useMemo(() => {
    if (state?.project) {
      const projectName = UIProjectUtils.parseProjectName(
        state.project.meta.name,
        state.project.spec.platform_source
      ) as string;

      // Get version from project version
      let projectVersion = state.projectVersion.meta.name;
      if (state.projectVersion.meta.kind === 'PackageVersion') {
        projectVersion = UIPackageVersionUtils.parsePackageName(
          state.projectVersion.meta.name
        ).version;
      }

      return [projectName, projectVersion];
    }

    if (state?.packageVersion) {
      const { label, version } = UIPackageVersionUtils.parsePackageName(
        state.packageVersion.meta.name
      );
      return [label, version];
    }

    return ['package'];
  }, [state]);

  const { trackOnboardingUserEvent } = useOnboardingUserEventTracking();
  useEffect(() => {
    if (!open) return;
    trackOnboardingUserEvent('EXPORTED_SBOM', 'INITIATED');
  }, [open, trackOnboardingUserEvent]);

  const packageVersionsFilterExpression = useMemo(() => {
    if (!state) return;

    const { packageVersion, project, projectVersion } = state;

    if (packageVersion) {
      return `uuid=="${packageVersion.uuid}"`;
    }

    if (project && projectVersion) {
      return filterExpressionBuilders.and([
        `spec.project_uuid=="${project.uuid}"`,
        filterExpressionBuilders.relatedResourceContext(projectVersion) ??
          filterExpressionBuilders.mainResourceContext(),
      ]);
    }
  }, [state]);

  const qListPackageVersions = useListAllPackageVersions(
    state?.namespace as string,
    { enabled: !!state?.namespace && !!packageVersionsFilterExpression },
    {
      filter: packageVersionsFilterExpression,
      mask: ['uuid', 'meta.name'].join(','),
      traverse: false,
    }
  );

  const [serverSBOMErrorResponse, setServerSBOMErrorResponse] = useState<
    IQueryErrorResponse | undefined
  >(undefined);
  const [serverVEXErrorResponse, setServerVEXErrorResponse] = useState<
    IQueryErrorResponse | undefined
  >(undefined);

  const qCreateSBOMExport = useCreateSBOMExport();
  const handleCreateSBOMExport = (
    fieldValues: FormCreateSBOMExportFieldValues
  ) => {
    // retrieve selected package version from uuid
    const targetUuid = fieldValues.meta?.parent_uuid;
    const selectedPackageVersion = qListPackageVersions.data?.find(
      (o) => o.uuid === targetUuid
    );

    if (!state || !selectedPackageVersion) return;

    // set package version file name
    const packageVersionFilename = getFilenameForPackageVersion(
      selectedPackageVersion
    );

    qCreateSBOMExport.mutate(
      {
        namespace: state.namespace,
        resource: {
          tenant_meta: { namespace: state.namespace },
          meta: {
            name: `SBOM Export: ${selectedPackageVersion.meta.name}`,
            parent_kind: 'PackageVersion',
            parent_uuid: selectedPackageVersion.uuid,
          },
          spec: fieldValues.spec,
        },
      },
      {
        onSuccess: (data) => {
          if (data.spec?.data) {
            trackOnboardingUserEvent('EXPORTED_SBOM', 'COMPLETED', {
              packageVersionName: selectedPackageVersion?.meta.name,
              projectName: state.project?.meta.name, // add if exists
              type: 'ExportedSBOM',
            });

            downloadFile(
              data.spec.data,
              data.spec.format ?? V1ExportedSBOMFormat.Unspecified,
              'sbom',
              packageVersionFilename
            );
            return;
          }

          setServerSBOMErrorResponse({
            status: 500,
            statusText:
              'Failed to Create SBOM Export: Generate finished successfully, but no data returned',
          });
        },
        // pass error down to form
        onError: (error) => {
          setServerSBOMErrorResponse(error.response);
        },
      }
    );
  };

  const qCreateVEXExport = useCreateVEXExport();
  const handleCreateVEXExport = (
    fieldValues: FormCreateVEXExportFieldValues
  ) => {
    // retrieve selected package version from uuid
    const targetUuid = fieldValues.meta?.parent_uuid;
    const selectedPackageVersion = qListPackageVersions.data?.find(
      (o) => o.uuid === targetUuid
    );

    if (!state || !selectedPackageVersion) return;

    // set package version file name
    const packageVersionFilename = getFilenameForPackageVersion(
      selectedPackageVersion
    );

    qCreateVEXExport.mutate(
      {
        namespace: state.namespace,
        resource: {
          tenant_meta: { namespace: state.namespace },
          meta: {
            name: `VEX Export: ${selectedPackageVersion.meta.name}`,
            parent_kind: 'PackageVersion',
            parent_uuid: selectedPackageVersion.uuid,
          },
          spec: fieldValues.spec,
        },
      },
      {
        onSuccess: (data) => {
          if (data.spec?.data) {
            trackOnboardingUserEvent('EXPORTED_SBOM', 'COMPLETED', {
              packageVersionName: selectedPackageVersion?.meta.name,
              projectName: state.project?.meta.name,
              type: 'ExportedVEX',
            });

            downloadFile(
              data.spec.data,
              data.spec.format ?? V1ExportedVEXFormat.Unspecified,
              'vex',
              packageVersionFilename
            );
            return;
          }

          setServerVEXErrorResponse({
            status: 500,
            statusText:
              'Failed to Create VEX Export: Generate finished successfully, but no data returned',
          });
        },
        // pass error down to form
        onError: (error) => {
          setServerVEXErrorResponse(error.response);
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="span" fontSize="inherit" variant="inherit">
            <FM
              defaultMessage="Export {title}"
              values={{ title: exportName }}
            />

            {exportVersion && (
              <Typography
                component="span"
                fontSize="inherit"
                color="text.secondary"
              >
                @{exportVersion}
              </Typography>
            )}
          </Typography>

          {onClose && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ marginRight: -2 }}
            >
              <IconX />
            </IconButton>
          )}
        </Stack>

        <Typography
          color="text.secondary"
          component="span"
          fontSize="small"
          variant="subtitle1"
        >
          <FM
            defaultMessage="Get a SBOM or Vulnerability Exchange (VEX) file for this {context} version"
            values={{
              context: state?.project ? 'project' : 'package',
            }}
          />
        </Typography>
      </DialogTitle>

      {/* Padding to compensate for the absence of DialogActions  */}
      <DialogContent sx={{ paddingBottom: 8 }}>
        {!!state && (
          <FormCreateExport
            namespace={state.namespace}
            onCancel={onClose}
            isLoadingPackageVersions={qListPackageVersions.isLoading}
            isLoadingSBOM={qCreateSBOMExport.isLoading}
            isLoadingVEX={qCreateVEXExport.isLoading}
            onSubmitSBOM={handleCreateSBOMExport}
            onSubmitVEX={handleCreateVEXExport}
            serverSBOMErrorResponse={serverSBOMErrorResponse}
            serverVEXErrorResponse={serverVEXErrorResponse}
            packageVersions={qListPackageVersions.data}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
