import { ArtifactSignatureResource } from '../types';

const DEFAULT_SHORT_HASH_LENGTH = 12;

/**
 * For a Given Artifact Signature resource, get or parse the digest value
 */
export const getArtifactSignatureDigest = (
  artifactSignature: ArtifactSignatureResource,
  options?: { short?: true | number }
): string => {
  const { meta, spec } = artifactSignature;
  const digest =
    spec.artifact_digest ?? getArtifactSignatureDigestFromName(meta.name);

  // Parse a digest formatted as `sha256:00000000...`
  const [_, hash] = digest.split(':');

  // Fallback to raw digest
  if (!hash) {
    return digest;
  }

  if (options?.short) {
    const truncateLength =
      true === options.short ? DEFAULT_SHORT_HASH_LENGTH : options.short;
    return hash.slice(0, truncateLength);
  }

  return hash;
};

export const getArtifactSignatureDigestFromName = (name: string): string => {
  const ix = name.lastIndexOf('@');
  return name.slice(ix);
};
