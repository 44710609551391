import { Table } from '@tanstack/react-table';
import { ForwardedRef, forwardRef } from 'react';

import { V1ScoreCategory } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  IconTitleDisplay,
  ImgIconLogoHuggingFace,
} from '@endorlabs/ui-common';

export type HuggingFaceModelsTableRow = {
  uuid: string;
  name?: string;
  SCORE_CATEGORY_SECURITY?: number;
  SCORE_CATEGORY_ACTIVITY?: number;
  SCORE_CATEGORY_POPULARITY?: number;
  SCORE_CATEGORY_CODE_QUALITY?: number;
};

export interface HuggingFaceModelsTableProps
  extends Omit<DataTableProps<HuggingFaceModelsTableRow>, 'columns'> {
  onClickDetail: (row: HuggingFaceModelsTableRow) => void;
}

const buildHuggingFaceModelsTableColumns =
  (): DataTableColumnDef<HuggingFaceModelsTableRow>[] => [
    {
      accessorKey: 'name',
      header: 'Model',
      cell: ({ getValue }) => {
        return (
          <IconTitleDisplay
            Icon={ImgIconLogoHuggingFace}
            iconFrame
            title={getValue()}
          />
        );
      },
      size: 1024,
      minSize: 1024,
      maxSize: 1024,
    },
    {
      accessorKey: V1ScoreCategory.Security,
      colType: ColTypes.SCORE,
      enableSorting: true,
    },
    {
      accessorKey: V1ScoreCategory.Activity,
      colType: ColTypes.SCORE,
      enableSorting: true,
    },
    {
      accessorKey: V1ScoreCategory.Popularity,
      colType: ColTypes.SCORE,
      enableSorting: true,
    },
    {
      accessorKey: V1ScoreCategory.CodeQuality,
      colType: ColTypes.SCORE,
      enableSorting: true,
    },
  ];

export const HuggingFaceModelsTable = forwardRef(
  function HuggingFaceModelsTableComponent(
    { onClickDetail, ...props }: HuggingFaceModelsTableProps,
    ref: ForwardedRef<Table<HuggingFaceModelsTableRow>>
  ) {
    const columns = buildHuggingFaceModelsTableColumns();

    function handleRowClick(row: HuggingFaceModelsTableRow) {
      return onClickDetail(row);
    }

    return (
      <DataTable
        {...props}
        columns={columns}
        onRowClick={handleRowClick}
        ref={ref}
        tableId="HuggingFaceModelsTable"
      />
    );
  }
);
