import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ProcessingStatus, V1ScanState } from '@endorlabs/api_client';
import {
  ButtonSecondary,
  IconAlertTriangle,
  IconClock,
  IconRotateCcw,
  ScanStateLabel,
} from '@endorlabs/ui-common';

const UnscannedStates = [V1ScanState.Idle, V1ScanState.Unspecified] as (
  | V1ScanState
  | undefined
)[];

const ScanningStates = [
  V1ScanState.Analytic,
  V1ScanState.Ingesting,
  V1ScanState.NotProcessed,
] as (V1ScanState | undefined)[];

interface ProjectVersionScanButtonProps {
  allowRescan?: boolean;
  handleRescan: () => void;
  processingStatus?: V1ProcessingStatus;
}

export const ProjectVersionScanButton = ({
  allowRescan = false,
  handleRescan,
  processingStatus,
}: ProjectVersionScanButtonProps) => {
  if (!processingStatus) {
    return null;
  }

  const isAutomatedScanDisabled =
    processingStatus.disable_automated_scan === true;

  const scanState = processingStatus.scan_state;
  const isScanning =
    scanState &&
    [V1ScanState.Analytic, V1ScanState.Ingesting].includes(scanState);

  if (UnscannedStates.includes(scanState)) {
    return (
      // If Project is unscanned or scan complete, and project settings allow, permit user to trigger a new scan
      <Tooltip
        title={
          <FM
            defaultMessage="Schedule project to be re-scanned (project must use a GitHub app installation and have automated scanning enabled)."
            values={{
              scanState: <ScanStateLabel value={scanState} />,
            }}
          />
        }
      >
        {/* HACK: wrapping div needed for tooltip on disabled button */}
        <div>
          <ButtonSecondary
            data-testid="project-details-btn-rescan"
            disabled={!allowRescan || isAutomatedScanDisabled}
            onClick={handleRescan}
            startIcon={<IconRotateCcw />}
          >
            <FM defaultMessage="Rescan Project" />
          </ButtonSecondary>
        </div>
      </Tooltip>
    );
  }

  if (ScanningStates.includes(scanState)) {
    return (
      <Tooltip
        title={
          <FM
            defaultMessage="Scan Status: {scanState}"
            values={{
              scanState: <ScanStateLabel value={scanState} />,
            }}
          />
        }
      >
        {/* HACK: wrapping div needed for tooltip on disabled button */}
        <div>
          <LoadingButton
            disabled
            loading={isScanning}
            startIcon={!isScanning && !isAutomatedScanDisabled && <IconClock />}
          >
            {isScanning ? (
              <FM defaultMessage="Project Scanning" />
            ) : isAutomatedScanDisabled ? (
              <FM defaultMessage="Not Scanned" />
            ) : (
              <FM defaultMessage="Scan Scheduled" />
            )}
          </LoadingButton>
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={<FM defaultMessage="This project is unreachable for scanning." />}
    >
      {/* HACK: wrapping div needed for tooltip on disabled button */}
      <div>
        <ButtonSecondary disabled startIcon={<IconAlertTriangle />}>
          <FM defaultMessage="Problem Scanning" />
        </ButtonSecondary>
      </div>
    </Tooltip>
  );
};
