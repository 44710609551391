import { LinearProgress } from '@mui/material';
import { MakeGenerics, Navigate, useSearch } from '@tanstack/react-location';
import { useEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { useFeatureFlags } from '@endorlabs/queries';
import { EmptyState } from '@endorlabs/ui-common';

import {
  getProjectPath,
  getTenantIndexPath,
  NamedRoutes,
} from '../../../routes';
import { useInvitation } from '../../Invitation';

type LoginSuccessPageLocationGenerics = MakeGenerics<{
  Search: {
    event?: string;
  };
}>;

/**
 * Waypoint route that:
 * 1. Processes the onboarding flow if coming from an invitation link
 * 2. Determines user access & routes them accordingly (Usually TenantsIndex)
 *
 * NOTE: By default, the backend redirects to this route directly after user authentication via a 3rd-party provider
 */
export const LoginSuccessPage = () => {
  const { event } = useSearch<LoginSuccessPageLocationGenerics>();

  const { ENABLE_TOUR_AS_DEFAULT: isTourAsDefault } = useFeatureFlags();

  const {
    invitationNamespace,
    invitationStatus,
    invitationUuid,
    processInvitation,
  } = useInvitation();

  // If there's a stored invitation, but no onboarding in process, kick off onboarding creation
  useEffect(() => {
    if (invitationUuid && !invitationStatus) {
      processInvitation();
    }
  }, [invitationStatus, invitationUuid, processInvitation]);

  if (invitationUuid) {
    // Onboarding is in flight
    if (!invitationStatus || invitationStatus === 'loading') {
      return (
        <EmptyState
          size="large"
          title={<FM defaultMessage="Retrieving invitation" />}
        >
          <LinearProgress />
        </EmptyState>
      );
    }

    // Onboarding error. Redirect to TenantsIndex—it's their problem now.
    if (invitationStatus === 'error') {
      return <Navigate to={NamedRoutes.TENANTS_INDEX} replace />;
    }

    if (
      (invitationStatus === 'accepted' || invitationStatus === 'success') &&
      !!invitationNamespace
    ) {
      return (
        <Navigate
          to={getTenantIndexPath({ tenantName: invitationNamespace })}
          replace
        />
      );
    }
  }

  if (event === 'signup') {
    if (isTourAsDefault) {
      return (
        <Navigate
          to={getProjectPath({ tenantName: NAMESPACES.DEMO_TRIAL })}
          replace
        />
      );
    }

    return <Navigate to={NamedRoutes.TENANT_CREATE} replace />;
  }
  // Standard login. No invitations to process. Go to TenantsIndex for proper re-routing.
  return <Navigate to={NamedRoutes.TENANTS_INDEX} replace search={{ event }} />;
};
