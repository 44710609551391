import { Skeleton, Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import {
  useCountScanProfiles,
  useDeleteScanProfile,
  useListScanProfiles,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  ButtonLinkPrimary,
  CommonDataTableRowActions,
  DataTableActionItem,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableView,
  useConfirmationDialog,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { getSettingsScanProfilesPath } from '../../../routes';
import { FilterBar, FilterFieldConfig, useFilterContext } from '../../filters';
import { useScanProfileDetailDrawer } from '../hooks';

const buildScanProfilesTableColumns =
  (): DataTableColumnDef<ScanProfileResource>[] => [
    {
      accessorFn: (r) => r.meta.name,
      cell: ({ getValue }) => (
        <Typography component="span" variant="body1" fontWeight={600}>
          {getValue()}
        </Typography>
      ),
      colType: ColTypes.TEXT_LONG,
      header: () => <FM defaultMessage="Name" />,
      id: 'name',
    },
  ];

const FILTER_FIELDS: FilterFieldConfig<any>[] = [];

export const ScanProfilesDataTableView = ({
  namespace,
}: {
  namespace: string;
}) => {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const { clearFilter, filter: filterExpression } = useFilterContext();

  const { DetailDrawer } = useScanProfileDetailDrawer();

  const qTotalCountScanProfiles = useCountScanProfiles(namespace, {});

  const totalCount = qTotalCountScanProfiles.data?.count;

  const qFilteredCountScanProfiles = useCountScanProfiles(namespace, {
    filter: filterExpression,
  });

  const filteredCount = qFilteredCountScanProfiles.data?.count;

  const paginator = useDataTablePaginator({
    totalCount: filteredCount,
  });

  const qListScanProfiles = useListScanProfiles(namespace, {
    filter: filterExpression,
    mask: ['meta.name', 'tenant_meta', 'uuid'].join(','),
    ...paginator.getListParameters(),
  });

  const scanProfiles: ScanProfileResource[] = useMemo(() => {
    return qListScanProfiles.data?.objects ?? [];
  }, [qListScanProfiles.data]);

  const qDeleteScanProfile = useDeleteScanProfile();

  const scanProfileDeleteConfirmation =
    useConfirmationDialog<ScanProfileResource>({
      confirmText: <FM defaultMessage="Delete" />,
      isDestructive: true,
      onConfirm: (scanProfile) => {
        if (scanProfile) {
          qDeleteScanProfile.mutate({
            namespace: scanProfile.tenant_meta.namespace,
            resource: { uuid: scanProfile.uuid },
          });
        }
      },
      titleText: <FM defaultMessage="Delete this Scan Profile?" />,
      // descriptionText: TODO: better copy for the confirm dialog
    });

  const rowActions: DataTableActionItem<ScanProfileResource>[] = [
    // TODO(UI-1466): Tool Chain Summary
    // {
    //   ...CommonDataTableRowActions.ACTIVATE_DRAWER,
    //   isPrimaryAction: true,
    //   onClick: (scanProfile: ScanProfileResource) => {
    //     const params = {
    //       scanProfileNamespace: scanProfile.tenant_meta.namespace,
    //       scanProfileUuid: scanProfile.uuid,
    //     };
    //     DetailDrawer.activate(params, params);
    //   },
    // },
    {
      ...CommonDataTableRowActions.EDIT_RESOURCE,
      isPrimaryAction: true,
      onClick: (scanProfile: ScanProfileResource) => {
        navigate({
          to: getSettingsScanProfilesPath({
            tenantName: scanProfile.tenant_meta.namespace,
            uuid: scanProfile.uuid,
          }),
        });
      },
    },
    {
      ...CommonDataTableRowActions.DELETE_RESOURCE,
      onClick: (scanProfile) =>
        scanProfileDeleteConfirmation.openDialog(scanProfile),
    },
  ];

  const isLoading =
    qTotalCountScanProfiles.isLoading || qListScanProfiles.isLoading;
  const isEmptyState = !isLoading && totalCount === 0;
  const isFilteredEmptyState = isEmptyState && !!filterExpression;

  return (
    <>
      <DataTableView
        countMessage={
          isLoading ? (
            <Skeleton width={120} />
          ) : (
            fm(
              {
                defaultMessage:
                  '{count, plural, =0 {No scan profiles} one {# scan profile} other {# scan profiles}}',
              },
              { count: filteredCount }
            )
          )
        }
        actionsContent={
          <ButtonLinkPrimary
            linkProps={{
              to: getSettingsScanProfilesPath({
                tenantName: namespace,
                uuid: 'new',
              }),
            }}
          >
            <FM defaultMessage="New Scan Profile" />
          </ButtonLinkPrimary>
        }
        columns={buildScanProfilesTableColumns()}
        data={scanProfiles}
        emptyStateProps={{
          title: isFilteredEmptyState ? (
            <FM defaultMessage="No scan profiles match the search criteria" />
          ) : (
            <FM defaultMessage="No scan profiles exist" />
          ),
          children: isFilteredEmptyState ? (
            <ButtonCancel onClick={() => clearFilter()}>
              <FM defaultMessage="Clear Filter" />
            </ButtonCancel>
          ) : (
            <ButtonLinkPrimary
              linkProps={{
                to: getSettingsScanProfilesPath({
                  tenantName: namespace,
                  uuid: 'new',
                }),
              }}
            >
              <FM defaultMessage="Create new scan profile" />
            </ButtonLinkPrimary>
          ),
        }}
        filtersContent={<FilterBar isCard={false} fields={FILTER_FIELDS} />}
        namespace={namespace}
        rowActions={rowActions}
      />
      <scanProfileDeleteConfirmation.Dialog
        {...scanProfileDeleteConfirmation.dialogProps}
      />
    </>
  );
};
