import { Stack, Typography, useTheme } from '@mui/material';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { useListArtifactSignature } from '@endorlabs/queries';
import {
  DrawerAccordion,
  DrawerAccordionTitle,
  FlexList,
  FlexListItem,
  MetadataListMetricListItem,
  NilDisplay,
} from '@endorlabs/ui-common';

import { InfoDrawerContent } from '../../components';

export interface ArtifactDetailDrawerProps {
  artifactNamespace: string;
  artifactUuid: string;
}

export const ArtifactDetailDrawer = ({
  artifactNamespace,
  artifactUuid,
}: ArtifactDetailDrawerProps) => {
  const { space } = useTheme();

  const qArtifactsSignature = useListArtifactSignature(
    artifactNamespace,
    {
      enabled: !!artifactNamespace && !!artifactUuid,
    },
    {
      filter: `uuid=="${artifactUuid}"`,
      mask: 'spec.signature,meta.name,meta.create_time,spec.provenance',
      page_size: 1,
    }
  );

  const labelWidth = { labelMinWidth: 135 };
  const artifactSignature = qArtifactsSignature?.data?.list?.objects[0];
  const spec = artifactSignature?.spec;
  const timestamp = artifactSignature?.meta.create_time;
  const artifactName = artifactSignature?.meta?.name;

  return (
    <InfoDrawerContent title={<FM defaultMessage="Artifact Digest Detail" />}>
      <Stack
        spacing={space.sm}
        direction="column"
        sx={({ spacing }) => ({
          // Enforce consistent bottom padding of accordion content when open
          '& > .MuiAccordion-root .MuiAccordionDetails-root': {
            paddingBottom: spacing(6),
          },
        })}
      >
        <DrawerAccordion
          expanded
          id="artifact-metadata"
          titleNode={<DrawerAccordionTitle title={messages.headerMetadata} />}
        >
          <FlexList direction="column" gap={4}>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Artifact Name" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {artifactName}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Signature" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {spec?.signature}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Timestamp" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {timestamp}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Certificate Identity" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {spec?.provenance?.certificate_identity}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Certificate OIDC Issuer" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {spec?.provenance?.certificate_oidc_issuer}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
          </FlexList>
        </DrawerAccordion>

        <DrawerAccordion
          expanded
          id="artifact-bulid_confirguration"
          titleNode={
            <DrawerAccordionTitle title={messages.headerBuildConfirguration} />
          }
        >
          <FlexList direction="column" gap={4}>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Digest" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {spec?.provenance?.build_config_digest}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Name" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {spec?.provenance?.build_config_name}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Run Environment" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {spec?.provenance?.runner_environment}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
          </FlexList>
        </DrawerAccordion>
        <DrawerAccordion
          expanded
          id="artifact-source_repository"
          titleNode={
            <DrawerAccordionTitle title={messages.headerSourceRepository} />
          }
        >
          <FlexList direction="column" gap={4}>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Name" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {spec?.provenance?.source_repository}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Digest" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {spec?.provenance?.source_repository_digest}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Owner" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {spec?.provenance?.source_repository_owner}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
            <FlexListItem>
              <MetadataListMetricListItem
                {...labelWidth}
                label={<FM defaultMessage="Reference" />}
                value={
                  <Typography variant="inherit" sx={{ wordBreak: 'break-all' }}>
                    {spec?.provenance?.source_repository_ref}
                  </Typography>
                }
                variant="raw"
              />
            </FlexListItem>
          </FlexList>
        </DrawerAccordion>
      </Stack>
    </InfoDrawerContent>
  );
};

const messages = defineMessages({
  headerMetadata: { defaultMessage: 'Metadata' },
  headerBuildConfirguration: {
    defaultMessage: 'Build Configuration',
  },
  headerSourceRepository: {
    defaultMessage: 'Source Repository',
  },
});
