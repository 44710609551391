import { IconButton, Stack } from '@mui/material';
import { isEmpty as _isEmpty, isUndefined as _isUndefined } from 'lodash-es';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { PackagistSpecAuthKind } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  IconEdit,
  IconTrash,
  NumberDisplay,
  OrderableTable,
  OrderableTableProps,
  useDataTablePaginator,
} from '@endorlabs/ui-common';
import { PackagistAuthKindLabel } from '@endorlabs/ui-common/domains/PackageManagers/PackageManagersLabel';

import {
  PackageManagerKey,
  PackageManagerKeys,
  PackageManagersWithTokenAuth,
} from './constants';

export interface PackageManagersTableRow {
  password?: string;
  priority: number;
  scope?: string;
  token?: string;
  uuid: string;
  namespace: string;
  url?: string;
  user?: string;
  host?: string;
  auth_kind?: PackagistSpecAuthKind;
}

const colHeaders = defineMessages({
  password: { defaultMessage: 'Password' },
  priority: { defaultMessage: 'Priority' },
  scope: { defaultMessage: 'Scope' },
  token: { defaultMessage: 'Token' },
  url: { defaultMessage: 'URL' },
  user: { defaultMessage: 'Username' },
  host: { defaultMessage: 'Hostname' },
  authKind: { defaultMessage: 'Authentication Kind' },
});

const buildPackageManagersTableColDefs = ({
  packageManagerKey,
  onDelete,
  onEdit,
}: {
  onDelete?: (row: PackageManagersTableRow) => void;
  onEdit?: (row: PackageManagersTableRow) => void;
  packageManagerKey: PackageManagerKey;
}) => {
  const usesTokenAuth =
    PackageManagersWithTokenAuth.includes(packageManagerKey);
  const isPacakgist = packageManagerKey === PackageManagerKeys.packagist;
  const pwHeader = usesTokenAuth ? colHeaders.token : colHeaders.password;
  const pwKey = usesTokenAuth ? 'token' : 'password';
  const userHeader = usesTokenAuth ? colHeaders.scope : colHeaders.user;
  const userKey = usesTokenAuth ? 'scope' : 'user';
  const urlKey = isPacakgist ? 'host' : 'url';
  const columns: DataTableColumnDef<PackageManagersTableRow>[] = [];

  //Packagist do not have priority
  if (!isPacakgist) {
    columns.push({
      accessorKey: 'priority',
      header: () => <FM {...colHeaders.priority} />,
      cell: ({ getValue }) => <NumberDisplay value={getValue()} />,
    });
  } else {
    columns.push({
      accessorKey: 'auth_kind',
      cell: ({ row }) => {
        const selectedAuth = row?.original['auth_kind'];
        if (!_isEmpty(selectedAuth) && !_isUndefined(selectedAuth)) {
          return PackagistAuthKindLabel({ packagistAuthKind: selectedAuth });
        }
        return '';
      },
      header: () => {
        return <FM {...colHeaders.authKind} />;
      },
    });
  }

  columns.push(
    {
      accessorKey: urlKey,
      header: () => (
        <FM
          {...(isPacakgist ? { ...colHeaders.host } : { ...colHeaders.url })}
        />
      ),
    },
    {
      accessorKey: userKey,
      header: () => {
        return <FM {...userHeader} />;
      },
    },
    {
      accessorKey: pwKey,
      cell: ({ row }) =>
        ((row.original && row.original[userKey]) || isPacakgist) && '********',
      header: () => <FM {...pwHeader} />,
    },
    {
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
    }
  );

  if (onDelete || onEdit) {
    columns.push({
      id: 'actions',
      header: '',
      cell: ({ row: { original } }) =>
        original && (
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            {onEdit && (
              <IconButton onClick={() => onEdit(original)} size="small">
                <IconEdit fontSize="inherit" />
              </IconButton>
            )}
            {onDelete && (
              <IconButton onClick={() => onDelete(original)} size="small">
                <IconTrash fontSize="inherit" />
              </IconButton>
            )}
          </Stack>
        ),
    });
  }

  return columns;
};

export interface PackageManagersTableProps
  extends Omit<OrderableTableProps<PackageManagersTableRow>, 'columns'> {
  onEdit?: (row: PackageManagersTableRow) => void;
  onDelete?: (row: PackageManagersTableRow) => void;
  packageManagerKey: PackageManagerKey;
}

/**
 * Displays a collection of package managers using a given platform
 */
export const PackageManagersTable = ({
  data = [],
  packageManagerKey,
  onDelete,
  onEdit,
  onReorderComplete,
}: PackageManagersTableProps) => {
  const columns = buildPackageManagersTableColDefs({
    onDelete,
    onEdit,
    packageManagerKey,
  });
  const paginator = useDataTablePaginator({
    totalCount: data.length,
  });

  //Packagist does not have priority hence reordering is not required
  const isPackagist = packageManagerKey === PackageManagerKeys.packagist;
  return isPackagist ? (
    <DataTable data={data} columns={columns} paginator={paginator} />
  ) : (
    <OrderableTable
      columns={columns}
      data={data}
      onReorderComplete={onReorderComplete}
    />
  );
};
