import { SvgIconProps } from '@mui/material';
import { JSXElementConstructor } from 'react';

import { V1ArtifactType } from '@endorlabs/api_client';

import {
  IconArtifactRegistry,
  IconArtifactTypeContainer,
  IconArtifactTypeJar,
} from '../../themes';

const ArtifactTypeIcons: Record<
  V1ArtifactType,
  JSXElementConstructor<SvgIconProps> | undefined
> = {
  [V1ArtifactType.Container]: IconArtifactTypeContainer,
  [V1ArtifactType.Dll]: undefined,
  [V1ArtifactType.Elf]: undefined,
  [V1ArtifactType.Jar]: IconArtifactTypeJar,
  [V1ArtifactType.Sbom]: undefined,
  [V1ArtifactType.Wheel]: undefined,
  [V1ArtifactType.Unspecified]: undefined,
};

export interface ArtifactIconDisplayProps extends SvgIconProps {
  type?: V1ArtifactType;
}

export const ArtifactIconDisplay = ({ type }: ArtifactIconDisplayProps) => {
  if (!type) {
    return <IconArtifactRegistry />;
  }

  const EcosystemIcon = ArtifactTypeIcons[type];

  if (EcosystemIcon) {
    return <EcosystemIcon />;
  }
  return <IconArtifactRegistry />;
};
