import { MenuItem, Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  NotificationActionAggregationType,
  NotificationTargetActionActionType,
} from '@endorlabs/api_client';
import { useListNotificationTargets } from '@endorlabs/queries';
import {
  ControlledTextField,
  Link,
  NotificationTargetDisplay,
} from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { getIntegrationsRootPath } from '../../../routes';

export const RemediationPolicyFields = () => {
  const { space } = useTheme();
  const { activeNamespace: tenantName } = useAuthInfo();
  const { control, setValue } = useFormContext();
  const { formatMessage: fm } = useIntl();

  useEffect(() => {
    // Remediation notification are not aggregated. Set aggregation type to Unspecified.
    setValue(
      'spec.notification.aggregation_type',
      NotificationActionAggregationType.Unspecified
    );
  }, [setValue]);

  // Only JIRA notification targets are supported for now
  const jiraTargetsFilter = `spec.action.action_type == ${NotificationTargetActionActionType.Jira}`;

  const notificationTargets =
    useListNotificationTargets(tenantName, {
      filter: jiraTargetsFilter,
    }).data?.list?.objects ?? [];

  return (
    <Stack
      alignItems="flex-start"
      direction="column"
      gap={space.md}
      width="100%"
    >
      {/* Alert Target selector */}
      <ControlledTextField
        control={control}
        defaultValue={[]}
        disabled={notificationTargets.length === 0}
        helperText={
          <Link target="_blank" to={getIntegrationsRootPath({ tenantName })}>
            {fm({
              defaultMessage: 'Manage notification targets for this namespace',
            })}
          </Link>
        }
        fullWidth
        label={fm({ defaultMessage: 'Select notification targets' })}
        name="spec.notification.notification_target_uuids"
        select
        SelectProps={{ multiple: true }}
      >
        {notificationTargets.map((notificationTarget) => (
          <MenuItem
            key={notificationTarget.uuid}
            value={notificationTarget.uuid}
          >
            <NotificationTargetDisplay
              notificationTarget={notificationTarget}
            />
          </MenuItem>
        ))}
      </ControlledTextField>
    </Stack>
  );
};
