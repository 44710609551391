import { useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1Confidence, V1ReleaseUpgradeInfo } from '@endorlabs/api_client';
import {
  AttributeDisplayStack,
  NumberDisplay,
  RowStack,
} from '@endorlabs/ui-common';

import { getBreakingChangeCountByConfidence } from '../../utils';
import { BreakingChangeConfidenceDisplay } from '../BreakingChangeConfidenceDisplay';
import { BreakingConfidenceTooltip } from '../BreakingConfidenceTooltip';

const RemediationRiskDriversRecords = [
  {
    attributeKey: 'conflicts',
    heading: <FM defaultMessage="Conflicts" />,
  },
  {
    attributeKey: 'breakingChanges',
    heading: <FM defaultMessage="Breaking Changes" />,
  },
  {
    attributeKey: 'breakingConfidence',
    disableTypography: true,
    heading: <FM defaultMessage="Breaking Change Confidence" />,
    helpTooltip: <BreakingConfidenceTooltip />,
  },
];

export const RemediationRiskDrivers = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  const { space } = useTheme();

  if (!upgradeInfo) return null;

  const conflicts =
    (upgradeInfo?.conflicts ?? 0) + (upgradeInfo?.minor_conflicts ?? 0);

  const breakingChanges = upgradeInfo?.cia_results?.length ?? 0;

  const breakingChangesByConfidence =
    getBreakingChangeCountByConfidence(upgradeInfo);

  return (
    <AttributeDisplayStack
      attributeRecords={RemediationRiskDriversRecords}
      headingWidth="28ch"
      resource={{
        conflicts: <NumberDisplay value={conflicts} />,
        breakingChanges: <NumberDisplay value={breakingChanges} />,
        breakingConfidence: (
          <RowStack gap={space.xs}>
            {Object.entries(breakingChangesByConfidence).map(
              ([confidence, count], index) => (
                <RowStack gap={1} key={`${confidence}-${index}`}>
                  <BreakingChangeConfidenceDisplay
                    confidence={confidence as V1Confidence}
                    showIcon={true}
                    showLabel={false}
                  />
                  <NumberDisplay value={count} />
                </RowStack>
              )
            )}
          </RowStack>
        ),
      }}
      variant="flex"
    />
  );
};
