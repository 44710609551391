import { Box, Card, Theme, Typography } from '@mui/material';
import { useRouter } from '@tanstack/react-location';
import { useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { RepoVersionResource } from '@endorlabs/queries';
import { useStyles } from '@endorlabs/ui-common';

import { Layout } from '../../constants';
import { FilterBar } from '../../domains/filters';
import { withFilterProvider } from '../../domains/filters';
import { REMEDIATIONS_FILTER_FIELDS } from '../../domains/Remediations';
import { RemediationsGroupAccordion } from '../../domains/Remediations/components/RemediationsGroupAccordion';
import { RemediationsTableViewRef } from '../../domains/Remediations/components/RemediationsTableView';
import { useRemediationsByDependency } from '../../domains/Remediations/hooks/useRemediationsByDependency';
import { useAuthInfo, useLicensingInfo } from '../../providers';
import { RemediationsGroupAccordionTitleNode } from './RemediationsGroupAccordionTitleNode';

const colHeadings = defineMessages({
  dependency: { defaultMessage: 'Dependency' },
  package: { defaultMessage: 'Affected Package' },
  version: { defaultMessage: 'Current Version' },
  patch: { defaultMessage: 'Endor Magic Patch Available' },
  findings: { defaultMessage: 'Fixed Vulnerabilities' },
});

const colHeadingsArray = [
  colHeadings.dependency,
  colHeadings.package,
  colHeadings.version,
  colHeadings.patch,
  colHeadings.findings,
];

export interface ProjectVersionRemediationsProps {
  namespace: string;
  project?: ProjectResource;
  repositoryVersion?: RepoVersionResource;
}

const BaseProjectVersionRemediations = ({
  namespace,
  project,
  repositoryVersion,
}: ProjectVersionRemediationsProps) => {
  const router = useRouter();

  const tableRef = useRef<RemediationsTableViewRef>(null);
  const { activeNamespace, setLicenseInCurrentRoute } = useAuthInfo();
  const sx = useStyles(styles);

  const { checkLicensePresent } = useLicensingInfo();

  const isRemediationLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.Recommendation
  );

  const remediationsData = useRemediationsByDependency({
    namespace: activeNamespace,
    project,
    repositoryVersion,
  });

  useEffect(() => {
    if (!isRemediationLicensePresent) {
      setLicenseInCurrentRoute({
        pathname: router.state.location.pathname,
        isLicense: false,
      });
      return () => {
        setLicenseInCurrentRoute({
          pathname: '',
          isLicense: true,
        });
      };
    }
  }, [
    isRemediationLicensePresent,
    router.state.location.pathname,
    setLicenseInCurrentRoute,
  ]);

  const handleExport = () => {
    // const remediationsFilterExpression = tableRef.current
    //   ? tableRef.current?.remediationsFilterExpression
    //   : baseFilterExpression;
    // remediationsExportDialog.openDialog({
    //   namespace: tenantName,
    //   filter: remediationsFilterExpression,
    //   downloadProps: {
    //     filename: `project_${project?.uuid}_remediations-export.csv`,
    //   },
    // });
  };

  const handleRefresh = () => {
    if (tableRef.current) {
      tableRef.current.refreshData();
    }
  };

  // const handleCreatePolicy = () => {
  //   navigate({ to: getPoliciesPath({ tenantName, section: `actions/new` }) });
  // };


  return (
    <>
      <Card className="ProjectVersionRemediations-root" sx={sx}>
        <FilterBar fields={REMEDIATIONS_FILTER_FIELDS} isCard={false} />

        <Box className="ProjectVersionRemediations-columnHeadings">
          {colHeadingsArray.map((headingMsg, i) => {
            return (
              <Typography key={i} variant="h6">
                <FM {...headingMsg} />
              </Typography>
            );
          })}
        </Box>

        <Box>
          {remediationsData.map((remediationGroup) => (
            <RemediationsGroupAccordion
              actionList={[]}
              id={remediationGroup.filter}
              key={remediationGroup.filter}
              {...remediationGroup}
              namespace={namespace}
              remediationUuids={remediationGroup.uuids}
              titleNode={
                <RemediationsGroupAccordionTitleNode
                  {...remediationGroup}
                  packageVersionName={
                    remediationGroup.packageVersionName as string
                  }
                />
              }
            />
          ))}
        </Box>
      </Card>
    </>
  );
};

export const ProjectVersionRemediationsV2 = withFilterProvider(
  BaseProjectVersionRemediations,
  {
    displayName: 'ProjectVersionRemediations',
    searchKeys: [
      'meta.name',
      'spec.upgrade_info.direct_dependency_package',
      'spec.upgrade_info.root_package_version',
    ],
  }
);

function styles({ palette, space, spacing, typography }: Theme) {
  const accordionOffset = 6;
  const arrowButtonOffset = 1;

  const columnSpacing = '30% 25% 10% 10% 1fr';
  const columnAttrs = {
    columnGap: space.sm,
    display: 'grid',
    gridTemplateColumns: columnSpacing,
  };

  return {
    '& .ProjectVersionRemediations-columnHeadings': {
      ...columnAttrs,
      borderBottom: `1px solid ${palette.divider}`,
      padding: `${space.sm} ${space.md} ${space.xs}`,
      paddingLeft: spacing(
        Layout.CARD_PADDING + accordionOffset + arrowButtonOffset
      ),

      '& .MuiTypography-h6': {
        fontSize: typography.body2.fontSize,
      },
    },

    '& .FilterBar-root': {
      padding: `${spacing(4)} ${spacing(Layout.CARD_PADDING)} ${spacing(5)}`,
    },

    '& .DrawerAccordion-root': {
      borderBottom: `1px solid ${palette.divider}`,
      paddingX: spacing(Layout.CARD_PADDING),
    },

    '& .RemediationsGroupAccordionTitleNode-root': {
      ...columnAttrs,
    },
  };
}
