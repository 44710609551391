import { defineMessages, MessageDescriptor } from 'react-intl';

import {
  CVSSV3SeverityLevel,
  SpecFindingLevel as FindingLevel,
  V1FindingCategory as FindingCategory,
  V1FindingTags as FindingTags,
} from '@endorlabs/api_client';

/**
 * Map of Finding Categories to internationalized, human-readable labels.
 */
export const FindingCategoryMessages: Record<
  FindingCategory,
  MessageDescriptor
> = defineMessages({
  [FindingCategory.LicenseRisk]: { defaultMessage: 'License Risk' },
  [FindingCategory.Operational]: { defaultMessage: 'Operational' },
  [FindingCategory.Sast]: { defaultMessage: 'SAST' },
  [FindingCategory.Secrets]: { defaultMessage: 'Secrets' },
  [FindingCategory.Security]: { defaultMessage: 'Security' },
  [FindingCategory.Scpm]: { defaultMessage: 'RSPM' },
  [FindingCategory.SupplyChain]: { defaultMessage: 'Supply Chain' },
  [FindingCategory.Unspecified]: { defaultMessage: 'Unspecified' },
  [FindingCategory.Vulnerability]: { defaultMessage: 'Vulnerability' },
  [FindingCategory.Malware]: { defaultMessage: 'Malware' },
  [FindingCategory.Cicd]: { defaultMessage: 'CI/CD' },
  [FindingCategory.Tools]: { defaultMessage: 'Tools' },
  [FindingCategory.Ghactions]: { defaultMessage: 'GitHub Actions' },
  [FindingCategory.Container]: { defaultMessage: 'Container' },
});

/**
 * Map of Finding Levels to internationalized, human-readable labels.
 */
export const FindingLevelMessages: Record<FindingLevel, MessageDescriptor> =
  defineMessages({
    [FindingLevel.Critical]: { defaultMessage: 'Critical' },
    [FindingLevel.High]: { defaultMessage: 'High' },
    [FindingLevel.Low]: { defaultMessage: 'Low' },
    [FindingLevel.Medium]: { defaultMessage: 'Medium' },
    [FindingLevel.Unspecified]: { defaultMessage: 'Unspecified' },

    // Include CVSS severity levels, as they are sometimes used interchangeably for vulnerabilities
    [CVSSV3SeverityLevel.Critical]: { defaultMessage: 'Critical' },
    [CVSSV3SeverityLevel.High]: { defaultMessage: 'High' },
    [CVSSV3SeverityLevel.Low]: { defaultMessage: 'Low' },
    [CVSSV3SeverityLevel.Medium]: { defaultMessage: 'Medium' },
    [CVSSV3SeverityLevel.Unspecified]: { defaultMessage: 'Unspecified' },
    [CVSSV3SeverityLevel.None]: { defaultMessage: 'Unspecified' },
  });

/**
 * Map of Finding Levels to abbreviated labels.
 */
export const FindingLevelAbbreviatedMessages: Record<
  FindingLevel,
  MessageDescriptor
> = defineMessages({
  [FindingLevel.Critical]: { defaultMessage: 'C' },
  [FindingLevel.High]: { defaultMessage: 'H' },
  [FindingLevel.Low]: { defaultMessage: 'L' },
  [FindingLevel.Medium]: { defaultMessage: 'M' },
  [FindingLevel.Unspecified]: { defaultMessage: 'U' },

  // Include CVSS severity levels, as they are sometimes used interchangeably for vulnerabilities
  [CVSSV3SeverityLevel.Critical]: { defaultMessage: 'C' },
  [CVSSV3SeverityLevel.High]: { defaultMessage: 'H' },
  [CVSSV3SeverityLevel.Low]: { defaultMessage: 'L' },
  [CVSSV3SeverityLevel.Medium]: { defaultMessage: 'M' },
  [CVSSV3SeverityLevel.Unspecified]: { defaultMessage: 'U' },
  [CVSSV3SeverityLevel.None]: { defaultMessage: 'U' },
});

/**
 * Map of Finding Tags to internationalized, human-readable labels.
 */
export const FindingTagsMessages: Record<FindingTags, MessageDescriptor> =
  defineMessages({
    // Dependency Import Types
    [FindingTags.Direct]: {
      defaultMessage: 'Direct',
    },
    [FindingTags.Transitive]: {
      defaultMessage: 'Transitive',
    },

    // Reachability of a Dependency
    [FindingTags.ReachableDependency]: {
      defaultMessage: 'Reachable Dependency',
    },
    [FindingTags.PotentiallyReachableDependency]: {
      defaultMessage: 'Potentially Reachable Dependency',
    },
    [FindingTags.UnreachableDependency]: {
      defaultMessage: 'Unreachable Dependency',
    },

    // Reachability of a Known Vulnerable Function
    [FindingTags.ReachableFunction]: {
      defaultMessage: 'Reachable Function',
    },
    [FindingTags.PotentiallyReachableFunction]: {
      defaultMessage: 'Potentially Reachable Function',
    },
    [FindingTags.UnreachableFunction]: {
      defaultMessage: 'Unreachable Function',
    },

    // First-Party Package Context
    [FindingTags.Self]: {
      defaultMessage: 'Self',
    },
    [FindingTags.ProjectInternal]: {
      defaultMessage: 'Same Repository',
    },
    [FindingTags.NamespaceInternal]: {
      defaultMessage: 'First Party',
    },
    [FindingTags.PathExternal]: {
      defaultMessage: 'External Path Only',
    },

    // Patch Availability
    [FindingTags.Unfixable]: {
      defaultMessage: 'Unfixable',
    },
    [FindingTags.Fixable]: {
      defaultMessage: 'Fixable',
    },
    [FindingTags.FixAvailable]: {
      defaultMessage: 'Fix Available',
    },

    // This is deprecated
    [FindingTags.Production]: {
      defaultMessage: 'Production',
    },

    // Dependency Scope
    [FindingTags.Normal]: {
      defaultMessage: 'Normal',
    },
    [FindingTags.Test]: {
      defaultMessage: 'Test',
    },
    [FindingTags.Exception]: {
      defaultMessage: 'Exception',
    },

    [FindingTags.Unspecified]: { defaultMessage: 'Unspecified' },
    [FindingTags.Policy]: { defaultMessage: 'Policy Based' },
    [FindingTags.CiBlocker]: { defaultMessage: 'Blocker' },
    [FindingTags.CiWarning]: { defaultMessage: 'Warning' },
    [FindingTags.Notification]: { defaultMessage: 'Notification' },

    // Secret validation result tags
    [FindingTags.ValidSecret]: {
      defaultMessage: 'Valid Secret',
    },
    [FindingTags.InvalidSecret]: {
      defaultMessage: 'Invalid Secret',
    },
    [FindingTags.Malware]: {
      defaultMessage: 'Malware',
    },
    [FindingTags.UnderReview]: {
      defaultMessage: 'Under Review',
    },

    // Phantom tags
    [FindingTags.Phantom]: {
      defaultMessage: 'Phantom',
    },
  });
