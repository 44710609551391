/* eslint sort-keys: "error" */
import { defineMessages, MessageDescriptor } from 'react-intl';

import { ResourceKind } from '@endorlabs/endor-core';

export const ResourceKindMessages: Record<ResourceKind, MessageDescriptor> =
  defineMessages({
    APIKey: { defaultMessage: 'API Key' },
    ApproveServiceRequest: { defaultMessage: 'Approve Service Request' },
    ArtifactSignature: { defaultMessage: 'Artifact Signatures' },
    AssuredPackageVersion: { defaultMessage: 'Assured Package Version' },
    AuthorizationPolicy: { defaultMessage: 'Authorization Policy' },
    CIRun: { defaultMessage: 'PR Run' },
    DashboardConfig: { defaultMessage: 'Dashboard Configuration' },
    Demo: { defaultMessage: 'Demo' },
    DependencyMetadata: { defaultMessage: 'Dependency' },
    EndorLicense: { defaultMessage: 'Endor License' },
    Finding: { defaultMessage: 'Finding' },
    IdentityProvider: { defaultMessage: 'Identity Provider' },
    ImportedSBOM: { defaultMessage: 'SBOM' },
    Installation: { defaultMessage: 'Installation' },
    Invitation: { defaultMessage: 'Invitation' },
    Metric: { defaultMessage: 'Metric' },
    Namespace: { defaultMessage: 'Namespace' },
    Notification: { defaultMessage: 'Notification' },
    NotificationTarget: { defaultMessage: 'Notification Target' },
    PRCommentConfig: { defaultMessage: 'PR Comment Config' },
    Package: { defaultMessage: 'Package' },
    PackageManager: { defaultMessage: 'Package Manager' },
    PackageVersion: { defaultMessage: 'Package Version' },
    PackageVersionMetadata: { defaultMessage: 'Package Version Metadata' },
    Policy: { defaultMessage: 'Policy' },
    PolicyTemplate: { defaultMessage: 'Policy Template' },
    Project: { defaultMessage: 'Project' },
    Repository: { defaultMessage: 'Repository' },
    RepositoryCommit: { defaultMessage: 'Repository Commit' },
    RepositoryVersion: { defaultMessage: 'Repository Version' },
    SBOMOrganizationalContact: {
      defaultMessage: 'SBOM Organizational Contact',
    },
    SastRules: { defaultMessage: 'SAST Rule' },
    SavedQuery: { defaultMessage: 'Saved Query' },
    ScanResult: { defaultMessage: 'Scan Result' },
    SecretRule: { defaultMessage: 'Secret Rule' },
    SemgrepRule: { defaultMessage: 'Semgrep Rule' },
    ServiceRequest: { defaultMessage: 'Service Request' },
    SystemConfig: { defaultMessage: 'System Config' },
    Tenant: { defaultMessage: 'Tenant' },
    ToolPattern: { defaultMessage: 'CI/CD Tool Pattern' },
    UITelemetry: { defaultMessage: 'UI Telemetry' },
    UserTelemetry: { defaultMessage: 'User Telemetry' },
    VersionUpgrade: { defaultMessage: 'Version Upgrade' },
  });

export const ResourceKindPluralMessages: Record<
  ResourceKind,
  MessageDescriptor
> = defineMessages({
  APIKey: { defaultMessage: 'API Keys' },
  ApproveServiceRequest: { defaultMessage: 'Approve Service Requests' },
  ArtifactSignature: { defaultMessage: 'Artifact Signatures' },
  AssuredPackageVersion: { defaultMessage: 'Assured Package Versions' },
  AuthorizationPolicy: { defaultMessage: 'Authorization Policies' },
  CIRun: { defaultMessage: 'PR Runs' },
  DashboardConfig: { defaultMessage: 'Dashboard Configurations' },
  Demo: { defaultMessage: 'Demos' },
  DependencyMetadata: { defaultMessage: 'Dependencies' },
  EndorLicense: { defaultMessage: 'Endor Licenses' },
  Finding: { defaultMessage: 'Findings' },
  IdentityProvider: { defaultMessage: 'Identity Providers' },
  ImportedSBOM: { defaultMessage: 'SBOMs' },
  Installation: { defaultMessage: 'Installations' },
  Invitation: { defaultMessage: 'Invitations' },
  Metric: { defaultMessage: 'Metrics' },
  Namespace: { defaultMessage: 'Namespaces' },
  Notification: { defaultMessage: 'Notifications' },
  NotificationTarget: { defaultMessage: 'Notification Targets' },
  PRCommentConfig: { defaultMessage: 'PR Comment Configs' },
  Package: { defaultMessage: 'Packages' },
  PackageManager: { defaultMessage: 'Package Managers' },
  PackageVersion: { defaultMessage: 'Package Versions' },
  PackageVersionMetadata: { defaultMessage: 'Package Versions Metadata' },
  Policy: { defaultMessage: 'Policies' },
  PolicyTemplate: { defaultMessage: 'Policie Templates' },
  Project: { defaultMessage: 'Projects' },
  Repository: { defaultMessage: 'Repositories' },
  RepositoryCommit: { defaultMessage: 'Repository Commits' },
  RepositoryVersion: { defaultMessage: 'Repository Versions' },
  SBOMOrganizationalContact: {
    defaultMessage: 'SBOM Organizational Contacts',
  },
  SastRules: { defaultMessage: 'SAST Rules' },
  SavedQuery: { defaultMessage: 'Saved Queries' },
  ScanResult: { defaultMessage: 'Scan Results' },
  SecretRule: { defaultMessage: 'Secret Rules' },
  SemgrepRule: { defaultMessage: 'Semgrep Rules' },
  ServiceRequest: { defaultMessage: 'Service Requests' },
  SystemConfig: { defaultMessage: 'System Configs' },
  Tenant: { defaultMessage: 'Tenants' },
  ToolPattern: { defaultMessage: 'CI/CD Tool Patterns' },
  UITelemetry: { defaultMessage: 'UI Telemetries' },
  UserTelemetry: { defaultMessage: 'User Telemetries' },
  VersionUpgrade: { defaultMessage: 'Version Upgrades' },
});
