import {
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Theme,
} from '@mui/material';
import clsx from 'clsx';
import { SyntheticEvent, useCallback } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  IconXCircle,
  RowStack,
  SearchBarInput,
  useStyles,
  UseStylesFunction,
} from '@endorlabs/ui-common';

import {
  useFilterContext,
  useFilterSavedFiltersContext,
  useFilterValidationContext,
} from '../providers';
import { FilterBarMode, FilterFieldConfig } from '../types';
import { buildFilterFieldElements, getUpdatedFilterMap } from '../utils';
import { FilterBarProps } from './FilterBar';
import { FilterBarAdvanced } from './FilterBarAdvanced';
import { FilterBarBasicProps } from './FilterBarBasic';
import { FilterBarHeaderProps } from './FilterBarHeader';
import { FilterBarSavedFilters } from './FilterBarSavedFilters';

type StyleOptions = { enableSavedFilters: boolean };

export interface DataTableViewFiltersProps
  extends Omit<
    FilterBarProps & FilterBarBasicProps & FilterBarHeaderProps,
    | 'filter'
    | 'filterValues'
    | 'isCard'
    | 'isDataTableView'
    | 'isParseable'
    | 'searchValue'
  > {}

export const DataTableViewFilters = ({
  enableAdvanced,
  enableSavedFilters = false,
  fields,
  mode,
  onClear,
  onCreateSavedFilter,
  onFilterChange,
  onModeChange,
  onSavedFilterSelect,
  onSearchChange,
  searchPlaceholder,
}: DataTableViewFiltersProps) => {
  // @ts-expect-error - useStyles arg issue
  const sx = useStyles(styles, {
    enableSavedFilters,
  });
  const { formatMessage: fm } = useIntl();

  const {
    clearFilter,
    filter,
    isParseable,
    updateFilter,
    _state: filterState,
  } = useFilterContext();

  const { savedFilters } = useFilterSavedFiltersContext();
  const { error: filterValidationError } = useFilterValidationContext();

  const filterValues = filterState.values;
  const searchValue = filterState.search;

  const isAdvanced = mode === FilterBarMode.Advanced;
  // FIXME: Need to ignore default filters
  const showClear = filterValues?.size || searchValue;

  const handleChange = useCallback(
    (fields: FilterFieldConfig, value: unknown) => {
      const next = getUpdatedFilterMap({
        fields,
        filterValues: filterState.values,
        value,
      });

      onFilterChange(next);
    },

    [filterState.values, onFilterChange]
  );

  const handleModeChange = (_: SyntheticEvent, newCheckedState: boolean) => {
    // if Filter is not parseable, clear before switching from advanced mode
    if (!isParseable && !isAdvanced) {
      onClear();
    }

    onModeChange(
      newCheckedState ? FilterBarMode.Advanced : FilterBarMode.Basic
    );
  };

  const filterFieldElements = buildFilterFieldElements({
    fields,
    filterValues: filterState.values,
    handleChange,
  });

  const classNames = clsx({
    'DataTableViewFilters-root': true,
    'DataTableViewFilters-isAdvanced': isAdvanced,
  });

  return (
    <Box className={classNames} sx={sx}>
      {enableSavedFilters && (
        <Box className="DataTableViewFilters-saved">
          <FilterBarSavedFilters
            filter={filter}
            onCreateSavedFilter={onCreateSavedFilter}
            onSavedFilterSelect={onSavedFilterSelect}
            savedFilters={savedFilters}
          />
        </Box>
      )}

      {!isAdvanced && (
        <Box className="DataTableViewFilters-search">
          <SearchBarInput
            disableUnderline={false}
            onSearch={onSearchChange}
            placeholder={searchPlaceholder ?? fm({ defaultMessage: 'Search' })}
            searchValue={filterState.search}
            size="medium"
          />
        </Box>
      )}

      <Box className="DataTableViewFilters-filters">
        {!isAdvanced && (
          <RowStack>
            {filterFieldElements}
            {showClear && (
              <IconButton
                onClick={onClear}
                title={fm({ defaultMessage: 'Clear Filters' })}
              >
                <IconXCircle />
              </IconButton>
            )}
          </RowStack>
        )}

        {isAdvanced && (
          <FilterBarAdvanced
            filter={filter}
            filterValidationError={filterValidationError}
            onClear={clearFilter}
            onExpressionChange={updateFilter}
            searchPlaceholder={searchPlaceholder}
          />
        )}
      </Box>

      {enableAdvanced && (
        <Box className="DataTableViewFilters-controls">
          <FormControlLabel
            control={
              <Switch
                aria-label={fm({ defaultMessage: 'Change Filters Mode' })}
                checked={isAdvanced}
                onChange={handleModeChange}
              />
            }
            label={<FM defaultMessage="Advanced" />}
            sx={{ marginRight: 0, width: '100%' }}
          />
        </Box>
      )}
    </Box>
  );
};

const styles = (theme: Theme, { enableSavedFilters }: StyleOptions) => {
  const { breakpoints, space, spacing } = theme;
  const searchBarSize = `minmax(${spacing(30)}, ${spacing(60)})`;

  return {
    '&.DataTableViewFilters-root': {
      alignItems: 'start',
      columnGap: space.md,
      containerType: 'inline-size',
      display: 'grid',
      gridTemplateColumns: enableSavedFilters
        ? `auto ${searchBarSize} 1fr auto`
        : `${searchBarSize} 1fr auto`,
      rowGap: space.sm,
      width: '100%',

      '&.DataTableViewFilters-isAdvanced': {
        columnGap: 0,
        gridTemplateColumns: 'auto 1fr auto',

        '& .DataTableViewFilters-filters': { gridColumn: '1 / 4', order: 3 },
        '& .DataTableViewFilters-controls': { gridColumn: '3 / 4', order: 2 },
      },

      [`@container (max-width: ${breakpoints.values.xl}px)`]: {
        gridTemplateColumns: `auto 1fr auto`,

        '& .DataTableViewFilters-controls': { order: 3 },
        '& .DataTableViewFilters-filters': {
          gridColumn: enableSavedFilters ? '1 / 4' : '1 / 3',
          order: 4,
        },
        '& .DataTableViewFilters-saved': { order: 1 },
        '& .DataTableViewFilters-search': {
          order: 2,
        },
      },

      [`@container (max-width: ${breakpoints.values.sm}px)`]: {
        gridTemplateColumns: `auto auto`,

        '& .DataTableViewFilters-controls': { order: 2 },
        '& .DataTableViewFilters-filters': { gridColumn: '1 / 3', order: 4 },
        '& .DataTableViewFilters-saved': { order: 1 },
        '& .DataTableViewFilters-search': {
          gridColumn: '1 / 3',
          order: 3,
        },
      },
    },

    '& .DataTableViewFilters-search, & .DataTableViewFilters-saved': {
      '& .MuiInput-root': {
        paddingTop: 0,
      },
    },

    '& .DataTableViewFilters-controls': {
      justifySelf: 'flex-end',
    },
  };
};
