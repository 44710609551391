import { Box, Grid, Slide } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  ProjectResource,
  RepositoryResource,
  RepoVersionResource,
  useFeatureFlags,
} from '@endorlabs/queries';
import { EmptyState, NavigationTabs } from '@endorlabs/ui-common';

import { ProjectMetadataView } from '../../components';
import {
  ProjectSettingsView,
  useProjectDetailMetadata,
} from '../../domains/Projects';
import { ProjectDetailCIRuns } from '../Projects';
import { ProjectVersionDependencies } from './ProjectVersionDependencies';
import { ProjectVersionFindings } from './ProjectVersionFindings';
import { ProjectVersionOverview } from './ProjectVersionOverview';
import { ProjectVersionPackages } from './ProjectVersionPackages';
import { ProjectVersionRemediations } from './ProjectVersionRemediations';
import { ProjectVersionRemediationsV2 } from './ProjectVersionRemediationsV2';
import { ProjectVersionTools } from './ProjectVersionTools';
import {
  ProjectVersionTabNames,
  useProjectVersionTabs,
} from './useProjectVersionTabs';

export interface ProjectVersionDetailViewProps {
  activeTab?: string;
  isGitHubInstallation?: boolean;
  isLoading: boolean;
  namespace: string;
  project?: ProjectResource;
  repository?: RepositoryResource;
  repositoryVersion?: RepoVersionResource;
  viewMode?: 'metadata' | undefined;
}

export const ProjectVersionDetailView = ({
  activeTab: targetActiveTab,
  isGitHubInstallation,
  isLoading,
  namespace,
  project,
  repository,
  repositoryVersion,
  viewMode,
}: ProjectVersionDetailViewProps) => {
  // get tabs, and default to overview view
  const { activeTab, tabRecords } = useProjectVersionTabs({
    activeTab: targetActiveTab,
    namespace,
    project,
    projectVersion: repositoryVersion,
  });

  const { ENABLE_MAGIC_PATCHING } = useFeatureFlags();

  const {
    findingsByLevelAndReachability,
    isMetricLoading,
    scoreCard,
    scoreFactors,
    topMetadataProps,
  } = useProjectDetailMetadata({
    isLoading,
    namespace,
    project,
    repositoryVersion,
  });

  // Handle Empty State: no project data loaded
  const isEmptyState = !isLoading && !project;
  const isMetadataView = !!viewMode && viewMode === 'metadata';

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <Box sx={{ position: 'relative' }}>
          <Box
            style={{
              width: '100%',
              visibility: isMetadataView ? 'hidden' : 'visible',
            }}
          >
            {isEmptyState && (
              <Grid item>
                <EmptyState
                  size="large"
                  title={<FM defaultMessage="Project not found" />}
                  description={
                    <FM defaultMessage="Unable to load the data for this project" />
                  }
                />
              </Grid>
            )}

            {!isEmptyState && (
              <Grid container direction="column" flexWrap="nowrap" spacing={6}>
                <Grid item>
                  <NavigationTabs
                    id="repository-version-details-tabs"
                    activeTab={activeTab}
                    persistSearchParams={['versionRef']}
                    tabs={tabRecords}
                  />
                </Grid>

                <Grid item>
                  {activeTab === ProjectVersionTabNames.OVERVIEW && (
                    <ProjectVersionOverview
                      findingGroups={findingsByLevelAndReachability}
                      repositoryVersion={repositoryVersion}
                      isMetricLoading={isMetricLoading}
                      scoreCard={scoreCard}
                      scoreFactors={scoreFactors}
                      topMetadataProps={topMetadataProps}
                    />
                  )}

                  {activeTab === ProjectVersionTabNames.PACKAGES && (
                    <ProjectVersionPackages
                      isLoading={isLoading}
                      namespace={namespace}
                      project={project}
                      repositoryVersion={repositoryVersion}
                    />
                  )}

                  {activeTab === ProjectVersionTabNames.DEPENDENCIES && (
                    <ProjectVersionDependencies
                      namespace={namespace}
                      project={project}
                      repositoryVersion={repositoryVersion}
                    />
                  )}

                  {activeTab === ProjectVersionTabNames.FINDINGS && (
                    <ProjectVersionFindings
                      namespace={namespace}
                      project={project}
                      projectVersion={repositoryVersion}
                    />
                  )}

                  {activeTab === ProjectVersionTabNames.TOOLS && (
                    <ProjectVersionTools
                      namespace={namespace}
                      repository={repository}
                      repositoryVersion={repositoryVersion}
                    />
                  )}

                  {activeTab === ProjectVersionTabNames.REMEDIATIONS &&
                    ENABLE_MAGIC_PATCHING && (
                      <ProjectVersionRemediationsV2
                        namespace={namespace}
                        project={project}
                        repositoryVersion={repositoryVersion}
                      />
                    )}

                  {activeTab === ProjectVersionTabNames.REMEDIATIONS &&
                    !ENABLE_MAGIC_PATCHING && (
                      <ProjectVersionRemediations
                        namespace={namespace}
                        project={project}
                        repositoryVersion={repositoryVersion}
                      />
                    )}

                  {activeTab === ProjectVersionTabNames.CI_RUNS && (
                    <ProjectDetailCIRuns
                      namespace={namespace}
                      project={project}
                      repositoryVersion={repositoryVersion}
                    />
                  )}

                  {activeTab === ProjectVersionTabNames.SETTINGS && (
                    <ProjectSettingsView
                      isGitHubInstallation={isGitHubInstallation}
                      isLoading={isLoading}
                      namespace={namespace}
                      project={project}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Box>

          {/* Raw code view */}
          <Slide direction="up" in={isMetadataView} mountOnEnter unmountOnExit>
            <Box
              sx={({ palette, spacing }) => ({
                backgroundColor: palette.background.default,
                paddingBottom: spacing(6),
                position: 'absolute',
                top: spacing(6),
                width: '100%',
              })}
            >
              {/* Raw code view */}
              <ProjectMetadataView
                project={project}
                repository={repository}
                repositoryVersion={repositoryVersion}
              />
            </Box>
          </Slide>
        </Box>
      </Grid>
    </Grid>
  );
};
