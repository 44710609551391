import { V1ScoreCategory as SC } from '@endorlabs/api_client';

import { IconActivity, IconHeart, IconLock, IconTarget } from '../../../themes';

export const ScoreCategoryIcons = {
  [SC.Security]: IconLock,
  [SC.CodeQuality]: IconTarget,
  [SC.Popularity]: IconHeart,
  [SC.Activity]: IconActivity,
};
