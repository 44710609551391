import { Stack, Typography } from '@mui/material';
import { isEmpty as _isEmpty, isNil as _isNil } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { ResourceKind } from '@endorlabs/endor-core';
import {
  getFindingTitle,
  getSASTSecurityFindingCweId,
  isSASTFinding,
} from '@endorlabs/endor-core/Finding';
import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';
import {
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  FindingAttributesArrayDisplay,
  FindingNameDisplay,
  NilDisplay,
  PackageVersionNameDisplay,
  ProjectLocationDisplay,
  RowStack,
} from '@endorlabs/ui-common';

import { FindingsTableRowV2, MapFindingsTableDataToRowtype } from './types';

export const buildFindingsTableV2ColumnDefs =
  (): DataTableColumnDef<FindingsTableRowV2>[] => {
    const columns: DataTableColumnDef<FindingsTableRowV2>[] = [
      {
        accessorFn: (originalRow) => originalRow.name,
        cell: (t) => {
          const row = t.row.original;

          return (
            <FindingNameDisplay
              finding={row.finding}
              showCategories={true}
              showCve={false}
            />
          );
        },
        colType: ColTypes.TEXT_LONG,
        header: () => <FM defaultMessage="Finding" />,
        id: 'finding_name',
      },

      {
        cell: (t) => {
          const {
            dependencyFilePaths,
            dependencyName,
            packageVersion,
            parentKind,
            project,
            vulnLocation,
            isSastFindings,
          } = t.row.original;

          if (isSastFindings && project) {
            return (
              <ProjectLocationDisplay
                project={project}
                vulnLocation={vulnLocation}
              />
            );
          } else if (parentKind !== 'PackageVersion') {
            return <NilDisplay variant="text" />;
          }
          return (
            <Stack rowGap={1}>
              {packageVersion && (
                <PackageVersionNameDisplay
                  packageVersion={packageVersion}
                  showVersion
                  size="xsmall"
                />
              )}

              {dependencyName && (
                <Stack gap={1}>
                  <RowStack>
                    {/* {dependencyName && (
                      <IconArrowRight htmlColor="textSecondary" />
                    )} */}
                    <PackageVersionNameDisplay
                      packageVersion={{
                        meta: { name: dependencyName },
                      }}
                      showVersion
                      size="xsmall"
                    />
                  </RowStack>

                  {dependencyFilePaths.length > 0 && (
                    <Stack>
                      {dependencyFilePaths.slice(0, 2).map((path) => (
                        <Typography
                          key={path}
                          sx={({ palette }) => ({
                            color: palette.text.secondary,
                            wordBreak: 'break-word',
                          })}
                          variant="body2"
                        >
                          {path}
                        </Typography>
                      ))}
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>
          );
        },
        header: () => <FM defaultMessage="Location" />,
        id: 'location',
        maxSize: 360,
        minSize: 280,
      },

      {
        accessorKey: 'epssProbability',
        colType: ColTypes.PERCENT,
        header: () => <FM defaultMessage="EPSS" />,
      },

      {
        accessorKey: 'scanInitial',
        colType: ColTypes.RELATIVE_TIME,
        header: () => <FM defaultMessage="First Scanned" />,
      },

      {
        accessorKey: 'tags',
        cell: (t) => (
          <FindingAttributesArrayDisplay value={t.row.original.tags ?? []} />
        ),
        header: () => <FM defaultMessage="Tags" />,
        maxSize: 360,
        minSize: 240,
        size: 360,
      },
    ];
    return columns.filter((c) => !c.hidden);
  };

export const mapFindingToV2TableRow = ({
  finding,
  packageVersions = [],
  projects,
  semgrepRules = [] as SemgrepRulesResource[],
}: MapFindingsTableDataToRowtype): FindingsTableRowV2 => {
  const parentKind = finding.meta.parent_kind as ResourceKind | undefined;
  const project =
    projects?.find((p) => p.uuid === finding.spec.project_uuid) ?? undefined;

  const isSastFindings = isSASTFinding(finding);

  const sastVulnerability =
    finding?.spec?.finding_metadata?.source_policy_info?.results ?? [];

  const sastVulnFound =
    !_isEmpty(sastVulnerability) && !_isNil(sastVulnerability);

  const sastRuleId = !_isEmpty(sastVulnerability)
    ? sastVulnerability[0]?.fields?.['SAST Rule ID']
    : '';

  const semgrepRuleForFinding =
    isSastFindings && !_isEmpty(semgrepRules)
      ? semgrepRules?.find((s) => s?.spec?.rule?.id === sastRuleId)
      : undefined;

  const cweIdData = semgrepRuleForFinding
    ? (getSASTSecurityFindingCweId(semgrepRuleForFinding) || []).map(
        (c) => c.cweId
      )
    : [];

  return {
    categories: finding.spec.finding_categories,
    dependencyName: finding.spec.target_dependency_package_name,
    dependencyFilePaths: finding?.spec.dependency_file_paths ?? [],
    epssProbability:
      finding?.spec.finding_metadata?.vulnerability?.spec?.epss_score
        ?.probability_score,
    finding,
    level: finding.spec.level,
    name: getFindingTitle(finding) ?? '',
    packageVersion:
      parentKind === 'PackageVersion'
        ? packageVersions.find((pv) => pv.uuid === finding.meta.parent_uuid)
        : undefined,
    parentKind,
    namespace: finding.tenant_meta.namespace,
    scanInitial: finding.meta.create_time,
    tags: finding.spec.finding_tags,
    uuid: finding.uuid,
    project,
    cweIdData,
    isSastFindings,
    vulnLocation: sastVulnFound
      ? sastVulnerability[0]?.fields?.Location
      : undefined,
  };
};
