import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  isBinaryProject,
  ProjectResource,
} from '@endorlabs/endor-core/Project';
import {
  useDeleteRepositoryVersion,
  useFeatureFlags,
  useListAllScanProfiles,
} from '@endorlabs/queries';
import {
  IconChevronDown,
  Link,
  useAppNotify,
  useConfirmationDialog,
} from '@endorlabs/ui-common';

import { getSettingsPath } from '../../../../routes';
import { NamedRoutes } from '../../../../routes/constants';
import { useUpdateSingleProjectFields } from '../../hooks';
import { FormProjectSettingsLabels } from '../FormProjectSettingsLabels';
import { ProjectMonitoredVersions } from '../ProjectMonitoredVersions';

export type ProjectSettingsViewProps = {
  isGitHubInstallation?: boolean;
  isLoading: boolean;
  namespace: string;
  project?: ProjectResource;
};

export const ProjectSettingsView = ({
  isGitHubInstallation,
  isLoading,
  namespace,
  project,
}: ProjectSettingsViewProps) => {
  const { ENABLE_FALCON_TOOLCHAINS } = useFeatureFlags();

  // Hide monitored versions for projects without repositories
  const showMonitoredVersions = project && !isBinaryProject(project);

  const isAutomatedScanDisabled =
    project?.processing_status?.disable_automated_scan === true;

  const {
    handleUpdateLabels,
    handleUpdateProjectField,
    handleToggleAutomatedScans,
    qUpdateProject,
  } = useUpdateSingleProjectFields(project);

  const addAppNotification = useAppNotify();

  const qRepositoryVersionDelete = useDeleteRepositoryVersion({
    onSuccess: () => {
      addAppNotification({
        id: 'repo-version:delete:success',
        severity: 'success',
        message: <FM defaultMessage="Project Version Successfully Deleted" />,
      });
    },
    onError: (error) => {
      addAppNotification({
        id: 'repo-version:delete:error',
        severity: 'error',
        message: <FM defaultMessage="Error Deleting Project Version" />,
        details: error.response.data?.message,
      });
    },
  });

  const RepositoryVersionDeleteConfirmation = useConfirmationDialog<{
    namespace: string;
    uuid: string;
  }>({
    confirmText: <FM defaultMessage="Delete" />,
    isDestructive: true,
    onConfirm: (props) => {
      if (props) {
        qRepositoryVersionDelete.mutate({
          namespace: props.namespace,
          uuid: props.uuid,
        });
      }
    },
    titleText: <FM defaultMessage="Delete this Project Version?" />,
    descriptionText: (
      <FM defaultMessage="This Project Version and related resources will be deleted." />
    ),
  });

  const qListScanProfiles = useListAllScanProfiles(namespace, {
    mask: ['meta.name', 'meta.description', 'uuid'].join(','),
    traverse: false,
  });

  const [selectedScanProfileUuid, setSelectedScanProfileUuid] =
    useState<string>('');

  useEffect(() => {
    if (project?.spec.scan_profile_uuid) {
      setSelectedScanProfileUuid(project.spec.scan_profile_uuid);
    }
  }, [project]);

  const handleUpdateScanProfile = () => {
    handleUpdateProjectField(
      'spec.scan_profile_uuid',
      selectedScanProfileUuid,
      project
    );
  };

  return (
    <>
      <Stack direction="column" spacing={6}>
        {ENABLE_FALCON_TOOLCHAINS && (
          <Card>
            <CardHeader title={<FM defaultMessage="Scan Profile" />} />
            <CardContent>
              <Stack gap={4} alignItems="start">
                <Typography>
                  <FM
                    defaultMessage="Specify which build tools to use for this project. Scan Profiles can be managed in the <link>settings page</link>."
                    values={{
                      link: (value) => (
                        <Link
                          to={getSettingsPath({
                            tenantName: namespace,
                            section: 'scan-profiles',
                          })}
                        >
                          {value}
                        </Link>
                      ),
                    }}
                  />
                </Typography>

                <Select
                  displayEmpty
                  IconComponent={IconChevronDown}
                  onChange={(evt) =>
                    setSelectedScanProfileUuid(evt.target.value)
                  }
                  renderValue={(uuid) =>
                    qListScanProfiles.data?.find((o) => o.uuid === uuid)?.meta
                      .name ?? <FM defaultMessage="None" />
                  }
                  sx={{
                    color: selectedScanProfileUuid
                      ? 'text.primary'
                      : 'text.secondary',
                    minWidth: 240,
                  }}
                  value={selectedScanProfileUuid}
                >
                  {qListScanProfiles.data?.map((sp) => (
                    <MenuItem key={sp.uuid} value={sp.uuid}>
                      <ListItemText
                        primary={sp.meta.name}
                        secondary={sp.meta.description}
                      />
                    </MenuItem>
                  ))}
                </Select>

                <LoadingButton
                  color="primary"
                  loading={qUpdateProject.isLoading}
                  onClick={handleUpdateScanProfile}
                  variant="contained"
                >
                  <FM defaultMessage="Save Profile" />
                </LoadingButton>
              </Stack>
            </CardContent>
          </Card>
        )}

        <Card>
          <CardHeader title={<FM defaultMessage="Custom Tags" />} />
          <CardContent>
            <FormProjectSettingsLabels
              isLoading={isLoading || qUpdateProject.isLoading}
              onSubmit={handleUpdateLabels}
              project={project}
            />
          </CardContent>
        </Card>

        {showMonitoredVersions && (
          <Card>
            <CardHeader title={<FM defaultMessage="Monitored Versions" />} />
            <CardContent>
              <Stack direction="column" spacing={4}>
                <FormControl>
                  <FormControlLabel
                    sx={{ marginLeft: -1 }}
                    checked={!isAutomatedScanDisabled}
                    control={<Switch size="small" />}
                    disabled={
                      !isGitHubInstallation ||
                      isLoading ||
                      qUpdateProject.isLoading
                    }
                    label={
                      <FM defaultMessage="Enable automated scans for this project" />
                    }
                    onChange={handleToggleAutomatedScans}
                  />
                  {!isGitHubInstallation && (
                    <FormHelperText sx={{ marginLeft: 0 }}>
                      <FM
                        defaultMessage="Automated scanning through the GitHub App is supported on GitHub Cloud only. For more infomation, see the <link>GitHub App Docs</link>."
                        values={{
                          link: (value) => (
                            <Link to={NamedRoutes.DOCS_GITHUB_APP}>
                              {value}
                            </Link>
                          ),
                        }}
                      />
                    </FormHelperText>
                  )}
                </FormControl>

                <ProjectMonitoredVersions
                  isLoading={isLoading}
                  namespace={namespace}
                  onDelete={(row) =>
                    RepositoryVersionDeleteConfirmation.openDialog(row)
                  }
                  project={project}
                />
              </Stack>
            </CardContent>
          </Card>
        )}
      </Stack>

      <RepositoryVersionDeleteConfirmation.Dialog
        {...RepositoryVersionDeleteConfirmation.dialogProps}
      />
    </>
  );
};
