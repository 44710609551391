import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecEnabledFeatureType } from '@endorlabs/api_client';

export const installStep: string[] = ['Select a namespace', 'Select scanners'];

export const ENABLED_FEATURES_MAP: {
  value: SpecEnabledFeatureType;
  label: ReactNode;
  description: ReactNode;
}[] = [
  {
    value: SpecEnabledFeatureType.GitScan,
    label: <FM defaultMessage="SCA" />,
    description: (
      <FM defaultMessage="Prioritize open-source dependency risk with reachability analysis" />
    ),
  },
  {
    value: SpecEnabledFeatureType.GithubScan,
    label: <FM defaultMessage="RSPM" />,
    description: (
      <FM defaultMessage="Strengthen the security posture of source code repositories" />
    ),
  },
  {
    value: SpecEnabledFeatureType.SecretsScan,
    label: <FM defaultMessage="Secret" />,
    description: <FM defaultMessage="Detect and prevent secret leaks" />,
  },
  {
    value: SpecEnabledFeatureType.ToolsScan,
    label: <FM defaultMessage="CI/CD" />,
    description: (
      <FM defaultMessage="Discover shadow pipelines quickly, and validate the integrity of your builds" />
    ),
  },
];
