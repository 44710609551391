import { getEnv } from '../../../constants';
import { getProjectDetailPath } from '../../../routes';
import { BasicTourStepProps } from '../types';

const ENV = getEnv();

const selCallpath = '.GraphPathList-root';
const selNav = '#app-primary-nav [data-testid="AppDrawerNavItem-projects"]';

export const getCallpathStep = ({ tourData }: BasicTourStepProps) => {
  return {
    attachTo: {
      element: selCallpath,
      on: 'left-start',
    },

    description:
      'View full call-graphs highlighting how your first-party code reaches the vulnerable function within a direct or transitive dependency. All analyzed statically without any dreadful runtime agents.',
    highlightSelectors: [selCallpath, selNav],

    // TODO: Search value here is somewhat arbitrary. Could use a route helper.
    stepUrl: `${getProjectDetailPath({
      tenantName: tourData.tourTenant,
      uuid: tourData.findingsProject?.uuid,
      section: 'findings',
    })}?resourceDetail=%7B"type"%3A"FindingCallPaths"%2C"findingUuid"%3A"${tourData.vulnFindingUuid
      }"%7D`,

    title: 'Evidence for Engineers',
    urlLearn: `${ENV.URL_ENDOR_MARKETING}/blog/securing-code-with-beautiful-call-graph-visualizations`,
  };
};
