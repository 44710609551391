import { Stack, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { IconArrowRight, Link, RowStack } from '@endorlabs/ui-common';

import { DetailDrawerSection } from '../../../components/DetailDrawer/DetailDrawerSection';
import { getProjectDetailPath, getRemediationsPath } from '../../../routes';
import {
  RemediationRecommendationDisplay,
  RemediationRecommendationType,
} from '../../Remediations';
import { useFindingDetailData } from '../hooks';

interface FindingRemediationsSectionProps {
  findingUuid?: string;
  namespace: string;
}

export const FindingRemediationsSection = ({
  findingUuid,
  namespace,
}: FindingRemediationsSectionProps) => {
  const { space } = useTheme();

  const { finding, magicPatchVersionUpgrade, project, versionUpgrade } =
    useFindingDetailData({
      findingUuid,
      namespace,
    });

  return (
    <DetailDrawerSection
      title={<FM defaultMessage="Remediation Recommendation" />}
    >
      <Stack gap={space.sm}>
        {magicPatchVersionUpgrade && (
          <RemediationRecommendationDisplay
            recommendation={RemediationRecommendationType.Patch}
            fromVersion={
              magicPatchVersionUpgrade.spec?.upgrade_info?.from_version
            }
            toVersion={magicPatchVersionUpgrade.spec?.upgrade_info?.to_version}
            packageName={
              magicPatchVersionUpgrade.spec?.upgrade_info
                ?.direct_dependency_package
            }
            remediationRisk={
              magicPatchVersionUpgrade.spec?.upgrade_info?.upgrade_risk
            }
            url={getRemediationsPath({
              tenantName: magicPatchVersionUpgrade.tenant_meta.namespace,
              uuid: magicPatchVersionUpgrade.uuid,
            })}
          />
        )}

        {versionUpgrade && (
          <RemediationRecommendationDisplay
            recommendation={RemediationRecommendationType.Upgrade}
            fromVersion={versionUpgrade.spec?.upgrade_info?.from_version}
            toVersion={versionUpgrade.spec?.upgrade_info?.to_version}
            packageName={
              versionUpgrade.spec?.upgrade_info?.direct_dependency_package
            }
            remediationRisk={versionUpgrade.spec?.upgrade_info?.upgrade_risk}
            url={getRemediationsPath({
              tenantName: versionUpgrade.tenant_meta.namespace,
              uuid: versionUpgrade.uuid,
            })}
          />
        )}

        {project && (
          <Link
            to={getProjectDetailPath({
              tenantName: project.tenant_meta.namespace,
              uuid: project.uuid,
              section: 'remediations',
            })}
            search={{ 'filter.search': finding?.spec.target_dependency_name }}
          >
            <RowStack>
              <FM defaultMessage="View Full Recommendation" />
              <IconArrowRight />
            </RowStack>
          </Link>
        )}
      </Stack>
    </DetailDrawerSection>
  );
};
