import { orderBy as _orderBy } from 'lodash-es';
import { useMemo } from 'react';

import {
  ListParametersSortEntry,
  SortEntrySortEntryOrder,
} from '@endorlabs/api_client';
import {
  useCountVersionUpgrade,
  useListVersionUpgrade,
} from '@endorlabs/queries';
import { DataTablePaginator } from '@endorlabs/ui-common';

import { VersionUpgradeTableSortKeys } from '../constants';
import { mapToRemediationsTableRow } from '../utils';

export const useRemediationsIndexPageData = ({
  namespace,
  paginator,
  baseFilterExpression,
  userFilterExpression,
  sortParam,
}: {
  namespace: string;
  paginator: DataTablePaginator;
  baseFilterExpression?: string;
  userFilterExpression?: string;
  sortParam?: ListParametersSortEntry;
}) => {
  const qCountVersionUpgrades = useCountVersionUpgrade(namespace, {
    filter: baseFilterExpression,
  });

  const qVersionUpgrades = useListVersionUpgrade(namespace, {
    ...paginator.getListParameters(),
    filter: userFilterExpression,
    mask: [
      'meta.update_time',
      'spec.upgrade_info.direct_dependency_manifest_files',
      'spec.upgrade_info.direct_dependency_package',
      'spec.upgrade_info.from_version',
      'spec.upgrade_info.from_version_publish_time',
      'spec.upgrade_info.is_best',
      'spec.upgrade_info.is_endor_patch',
      'spec.upgrade_info.is_latest',
      'spec.upgrade_info.other_finding_info.severity',
      'spec.upgrade_info.project',
      'spec.upgrade_info.root_package_version',
      'spec.upgrade_info.to_version',
      'spec.upgrade_info.total_findings_fixed',
      'spec.upgrade_info.upgrade_risk',
      'spec.upgrade_info.vuln_finding_info.severity',
    ].join(','),
    sort: sortParam,
  });

  const versionUpgrades = useMemo(() => {
    const data = qVersionUpgrades.data?.objects || [];

    if (sortParam?.path !== VersionUpgradeTableSortKeys.score) {
      return data;
    }

    // When sorting by score (default), apply secondary sort to force the "best" upgrade first
    return _orderBy(
      data,
      [VersionUpgradeTableSortKeys.score, 'spec.upgrade_info.is_best'],
      sortParam.order === SortEntrySortEntryOrder.Desc
        ? ['desc', 'desc']
        : ['asc', 'asc']
    );
  }, [qVersionUpgrades.data?.objects, sortParam?.order, sortParam?.path]);

  const upgradesList = mapToRemediationsTableRow(versionUpgrades);

  return {
    upgradesCount: qCountVersionUpgrades.data?.count,
    upgradesList,
    isCountLoading: qCountVersionUpgrades.isLoading,
    ...qVersionUpgrades,
  };
};
