import { Skeleton } from '@mui/material';
import { RowSelectionState } from '@tanstack/react-table';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FindingResource, FindingSource } from '@endorlabs/endor-core/Finding';
import { filterExpressionBuilders as FB } from '@endorlabs/filters';
import {
  PackageVersionResource,
  selectFindingCountsFromGroupResponse,
  useListFindings,
} from '@endorlabs/queries';
import {
  BulkActionRecord,
  CommonDataTableRowActions,
  DataTableView,
  DataTableViewProps,
  FindingCountArrayDisplay,
  RowStack,
  useDataTablePaginator,
  useDialog,
} from '@endorlabs/ui-common';

import { StaleTimes } from '../../../constants';
import { FilterBar, useFilterContext } from '../../filters';
import { ExceptionPolicyCreateDialog } from '../../Policies/components/ExceptionPolicyCreateDialog';
import {
  useFindingDetailDrawer,
  useFindingsExportDialog,
  useFindingsFilterFields,
} from '../hooks';
import { useFindingsData } from '../hooks/useFindingsData';
import {
  buildFindingsTableV2ColumnDefs,
  FindingsTableRowV2,
  mapFindingToV2TableRow,
} from './FindingsTable';

interface FindingsDataTableViewProps
  extends Omit<DataTableViewProps<FindingsTableRowV2>, 'columns' | 'data'> {}

export const FindingsDataTableView = ({
  namespace,
}: FindingsDataTableViewProps) => {
  const { DetailDrawer, permalinkEffect } = useFindingDetailDrawer();

  const { filter: filterExpression, _state: filterState } = useFilterContext();

  // TODO: Need shared contextual state instead of each table re-implementing this
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const hasSelection = Object.keys(selectedRows).length > 0;

  const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(rowSelection);
  }, []);

  const filterFields = useFindingsFilterFields(FindingSource.All);

  const qFindingsGroupByLevel = useListFindings(
    namespace,
    {
      enabled: !!namespace && !!filterExpression,
      staleTime: StaleTimes.EXTENDED,
    },
    {
      filter: filterExpression,
      group: {
        aggregation_paths: 'spec.level',
      },
    }
  );

  const [findingCounts, totalCount] = useMemo(() => {
    if (qFindingsGroupByLevel.isLoading) {
      return [[], 0];
    }

    const findingCounts = selectFindingCountsFromGroupResponse(
      qFindingsGroupByLevel.data?.group_response
    );
    const totalCount = findingCounts.reduce((s, v) => s + v.value, 0);

    return [findingCounts, totalCount];
  }, [qFindingsGroupByLevel.data, qFindingsGroupByLevel.isLoading]);

  const paginator = useDataTablePaginator({
    totalCount: totalCount,
  });

  const { findings, isLoading, packageVersions } = useFindingsData({
    filterExpression,
    filterState,
    namespace,
    paginator,
  });

  useLayoutEffect(() => {
    // @ts-expect-error - Wants FindingsQueryResponse but that's unnecessary
    permalinkEffect(findings);
  }, [findings, permalinkEffect]);

  /**
   * EXCEPTION POLICY HANDLING
   */
  const exceptionPolicyCreateDialog = useDialog({
    component: ExceptionPolicyCreateDialog,
  });

  const openExceptionPolicyDialog = useCallback(
    (rows: FindingsTableRowV2[]) => {
      if (rows.length < 1) return;

      // Get Finding and PackageVersion objects from the selected rows
      const selectedFindings = rows
        .map((r) => findings.find((f) => f.uuid === r.uuid))
        .filter(Boolean) as FindingResource[];

      const selectedPackageVersions = rows
        .map((r) => r.packageVersion)
        .filter(Boolean) as PackageVersionResource[];

      exceptionPolicyCreateDialog.openDialog({
        findings: selectedFindings,
        namespace,
        packageVersions: selectedPackageVersions,
      });
    },
    [exceptionPolicyCreateDialog, findings, namespace]
  );

  /**
   * EXPORT HANDLING
   */
  const findingsExportDialog = useFindingsExportDialog();

  const bulkActions: BulkActionRecord<FindingsTableRowV2>[] = [
    {
      actionId: 'ADD_TO_EXCEPTION_POLICY',
      disabled: !hasSelection,
      isSelectionRequired: true,
      label: <FM defaultMessage="Add Exception" />,
      onApply: openExceptionPolicyDialog,
    },

    {
      actionId: 'EXPORT_FINDINGS_SELECTION',
      disabled: !hasSelection,
      isSelectionRequired: true,
      label: <FM defaultMessage="Export Selected" />,
      onApply: (rows) => {
        const filter = hasSelection
          ? FB.and([
              filterExpression,
              `uuid in [${rows.map((r) => r.uuid).join(', ')}]`,
            ])
          : filterExpression;

        findingsExportDialog.openDialog({
          downloadProps: {
            filename: `${namespace}-findings-export.csv`,
          },
          filter,
          namespace,
        });
      },
    },

    {
      actionId: 'EXPORT_FINDINGS_ALL',
      isSelectionRequired: false,
      label: <FM defaultMessage="Export All" />,
      onApply: () => {
        findingsExportDialog.openDialog({
          downloadProps: {
            filename: `${namespace}-findings-export.csv`,
          },
          filter: filterExpression,
          namespace,
        });
      },
    },
  ];

  const rowActions = [
    {
      ...CommonDataTableRowActions.ACTIVATE_DRAWER,
      isPrimaryAction: true,
      onClick: (row: FindingsTableRowV2) => {
        DetailDrawer.activate(
          {
            findingUuid: row.uuid,
            findingNamespace: row.namespace,
          },
          { findingUuid: row.uuid, namespace: row.namespace }
        );
      },
    },
  ];

  return (
    <>
      <DataTableView
        bulkActions={bulkActions}
        columns={buildFindingsTableV2ColumnDefs()}
        countMessage={
          <RowStack>
            {qFindingsGroupByLevel.isLoading ? (
              <Skeleton width={72} />
            ) : (
              <FM
                defaultMessage="{totalCount, number, ::compact-short} Findings"
                values={{ totalCount }}
              />
            )}
            <FindingCountArrayDisplay value={findingCounts} />
          </RowStack>
        }
        data={findings.map((finding) =>
          mapFindingToV2TableRow({ finding, namespace, packageVersions })
        )}
        emptyStateProps={{
          description: (
            <FM defaultMessage="Try expanding your time window or adjusting your filter selections." />
          ),
          title: <FM defaultMessage="No findings based on current filters" />,
        }}
        filtersContent={
          <FilterBar
            enableAdvanced
            enableSavedFilters
            fields={filterFields}
            isDataTableView
          />
        }
        isLoading={isLoading}
        namespace={namespace}
        onRowSelectionChange={handleRowSelection}
        paginator={paginator}
        rowActions={rowActions}
      />

      <exceptionPolicyCreateDialog.Dialog
        {...exceptionPolicyCreateDialog.dialogProps}
      />

      <findingsExportDialog.Dialog {...findingsExportDialog.getDialogProps()} />
    </>
  );
};
