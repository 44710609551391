import { Chip } from '@mui/material';

import { FlexList, FlexListItem, NilDisplay } from '../../../../components';

export const FindingCategoriesSASTArrayDisplay = ({
  value: findingCategories,
}: {
  value?: string[];
}) => {
  if (!findingCategories?.length) return <NilDisplay />;

  return (
    <FlexList>
      {findingCategories.map((findingCategory) => (
        <FlexListItem key={findingCategory}>
          <Chip label={findingCategory} size="small" />
        </FlexListItem>
      ))}
    </FlexList>
  );
};
