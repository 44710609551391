/**
 * Captures the palette values defined in Figma here:
 * https://www.figma.com/design/XneTcn3Urmh31ThynELmss/Design-System?node-id=2-2103
 *
 * NOTE: These colors do not represent an MUI theme palette object.
 * They are intended as standalone variables that can be incorporated into a theme,
 * or accessed for specific component use cases.
 */
interface ColorValues {
  rgb: string;
  hsla: string;
  hex: string;
}

export interface PaletteColorObj {
  darkMode: ColorValues;
  fullName: string;
  group: string;
  key: string;
  lightMode: ColorValues;
  name: string;
}

const Text = {
  primary: {
    name: 'Primary',
    lightMode: {
      rgb: 'rgb(31, 31, 31)',
      hsla: 'hsl(0, 0%, 12%)',
      hex: '#1f1f1f',
    },
    darkMode: {
      rgb: 'rgb(255, 255, 255)',
      hsla: 'hsl(0, 0%, 100%)',
      hex: '#ffffff',
    },
    group: 'Text',
    key: 'primary',
    fullName: 'Text/Primary',
  },
  secondary: {
    name: 'Secondary',
    lightMode: {
      rgb: 'rgb(111, 111, 111)',
      hsla: 'hsl(0, 0%, 44%)',
      hex: '#6f6f6f',
    },
    darkMode: {
      rgb: 'rgb(224, 224, 224)',
      hsla: 'hsl(0, 0%, 88%)',
      hex: '#e0e0e0',
    },
    group: 'Text',
    key: 'secondary',
    fullName: 'Text/Secondary',
  },
  link: {
    name: 'Link',
    lightMode: {
      rgb: 'rgb(21, 101, 192)',
      hsla: 'hsl(212, 80%, 42%)',
      hex: '#1565c0',
    },
    darkMode: {
      rgb: 'rgb(64, 181, 255)',
      hsla: 'hsl(203, 100%, 63%)',
      hex: '#40b5ff',
    },
    group: 'Text',
    key: 'link',
    fullName: 'Text/Link',
  },
};

const Grays = {
  black: {
    name: 'Black',
    lightMode: {
      rgb: 'rgb(0, 0, 0)',
      hsla: 'hsl(0, 0%, 0%)',
      hex: '#000000',
    },
    darkMode: {
      rgb: 'rgb(255, 255, 255)',
      hsla: 'hsl(0, 0%, 100%)',
      hex: '#ffffff',
    },
    group: 'Grays',
    key: 'black',
    fullName: 'Grays/Black',
  },
  light_gray_200: {
    name: 'Light Gray 200',
    lightMode: {
      rgb: 'rgb(224, 224, 224)',
      hsla: 'hsl(0, 0%, 88%)',
      hex: '#e0e0e0',
    },
    darkMode: {
      rgb: 'rgb(111, 111, 111)',
      hsla: 'hsl(0, 0%, 44%)',
      hex: '#6f6f6f',
    },
    group: 'Grays',
    key: 'light_gray_200',
    fullName: 'Grays/Light Gray 200',
  },
  light_gray_100: {
    name: 'Light Gray 100',
    lightMode: {
      rgb: 'rgb(249, 249, 252)',
      hsla: 'hsl(240, 33%, 98%)',
      hex: '#f9f9fc',
    },
    darkMode: {
      rgb: 'rgb(0, 5, 3)',
      hsla: 'hsl(156, 100%, 1%)',
      hex: '#000503',
    },
    group: 'Grays',
    key: 'light_gray_100',
    fullName: 'Grays/Light Gray 100',
  },
  light_gray_150: {
    name: 'Light Gray 150',
    lightMode: {
      rgb: 'rgb(239, 239, 239)',
      hsla: 'hsl(0, 0%, 94%)',
      hex: '#efefef',
    },
    darkMode: {
      rgb: 'rgb(51, 51, 51)',
      hsla: 'hsl(0, 0%, 20%)',
      hex: '#333333',
    },
    group: 'Grays',
    key: 'light_gray_150',
    fullName: 'Grays/Light Gray 150',
  },
  hot_black: {
    name: 'Hot Black',
    lightMode: {
      rgb: 'rgb(249, 249, 252)',
      hsla: 'hsl(240, 33%, 98%)',
      hex: '#f9f9fc',
    },
    darkMode: {
      rgb: 'rgb(0, 5, 3)',
      hsla: 'hsl(156, 100%, 1%)',
      hex: '#000503',
    },
    group: 'Grays',
    key: 'hot_black',
    fullName: 'Grays/Hot Black',
  },
  white: {
    name: 'White',
    lightMode: {
      rgb: 'rgb(255, 255, 255)',
      hsla: 'hsl(0, 0%, 100%)',
      hex: '#ffffff',
    },
    darkMode: {
      rgb: 'rgb(0, 0, 0)',
      hsla: 'hsl(0, 0%, 0%)',
      hex: '#000000',
    },
    group: 'Grays',
    key: 'white',
    fullName: 'Grays/White',
  },
};

const Greens = {
  dark_teal: {
    name: 'Dark Teal',
    lightMode: {
      rgb: 'rgb(0, 86, 82)',
      hsla: 'hsl(177, 100%, 17%)',
      hex: '#005652',
    },
    darkMode: {
      rgb: 'rgb(0, 240, 120)',
      hsla: 'hsl(150, 100%, 47%)',
      hex: '#00f078',
    },
    group: 'Greens',
    key: 'dark_teal',
    fullName: 'Greens/Dark Teal',
  },
  dark_teal_hover: {
    name: 'Dark Teal (Hover)',
    lightMode: {
      rgb: 'rgb(0, 60, 57)',
      hsla: 'hsl(177, 100%, 12%)',
      hex: '#003c39',
    },
    darkMode: {
      rgb: 'rgb(0, 208, 41)',
      hsla: 'hsl(132, 100%, 41%)',
      hex: '#00d029',
    },
    group: 'Greens',
    key: 'dark_teal_hover',
    fullName: 'Greens/Dark Teal (Hover)',
  },
  dark_teal_100: {
    name: 'Dark Teal 100',
    lightMode: {
      rgb: 'rgb(237, 241, 241)',
      hsla: 'hsl(180, 12%, 94%)',
      hex: '#edf1f1',
    },
    darkMode: {
      rgb: 'rgb(79, 110, 109)',
      hsla: 'hsl(178, 16%, 37%)',
      hex: '#4f6e6d',
    },
    group: 'Greens',
    key: 'dark_teal_100',
    fullName: 'Greens/Dark Teal 100',
  },
  dark_teal_200: {
    name: 'Dark Teal 200',
    lightMode: {
      rgb: 'rgb(225, 233, 233)',
      hsla: 'hsl(180, 15%, 90%)',
      hex: '#e1e9e9',
    },
    darkMode: {
      rgb: 'rgb(111, 135, 134)',
      hsla: 'hsl(178, 10%, 48%)',
      hex: '#6f8786',
    },
    group: 'Greens',
    key: 'dark_teal_200',
    fullName: 'Greens/Dark Teal 200',
  },
  hot_green: {
    name: 'Hot Green',
    lightMode: {
      rgb: 'rgb(0, 208, 41)',
      hsla: 'hsl(132, 100%, 41%)',
      hex: '#00d029',
    },
    darkMode: {
      rgb: 'rgb(0, 86, 82)',
      hsla: 'hsl(177, 100%, 17%)',
      hex: '#005652',
    },
    group: 'Greens',
    key: 'hot_green',
    fullName: 'Greens/Hot Green',
  },
  hot_green_hover: {
    name: 'Hot Green (Hover)',
    lightMode: {
      rgb: 'rgb(0, 208, 41)',
      hsla: 'hsl(132, 100%, 41%)',
      hex: '#00d029',
    },
    darkMode: {
      rgb: 'rgb(0, 60, 57)',
      hsla: 'hsl(177, 100%, 12%)',
      hex: '#003c39',
    },
    group: 'Greens',
    key: 'hot_green_hover',
    fullName: 'Greens/Hot Green (Hover)',
  },
  dark_teal_50: {
    name: 'Dark Teal 50',
    lightMode: {
      rgb: 'rgb(247, 249, 252)',
      hsla: 'hsl(216, 45%, 98%)',
      hex: '#f7f9fc',
    },
    darkMode: {
      rgb: 'rgb(47, 52, 51)',
      hsla: 'hsl(168, 5%, 19%)',
      hex: '#2f3433',
    },
    group: 'Greens',
    key: 'dark_teal_50',
    fullName: 'Greens/Dark Teal 50',
  },
  dark_teal_600: {
    name: 'Dark Teal 600',
    lightMode: {
      rgb: 'rgb(111, 135, 134)',
      hsla: 'hsl(178, 10%, 48%)',
      hex: '#6f8786',
    },
    darkMode: {
      rgb: 'rgb(225, 233, 233)',
      hsla: 'hsl(180, 15%, 90%)',
      hex: '#e1e9e9',
    },
    group: 'Greens',
    key: 'dark_teal_600',
    fullName: 'Greens/Dark Teal 600',
  },
  teal_200: {
    name: 'Teal 200',
    lightMode: {
      rgb: 'rgb(232, 246, 239)',
      hsla: 'hsl(150, 44%, 94%)',
      hex: '#e8f6ef',
    },
    darkMode: {
      rgb: 'rgb(61, 78, 76)',
      hsla: 'hsl(173, 12%, 27%)',
      hex: '#3d4e4c',
    },
    group: 'Greens',
    key: 'teal_200',
    fullName: 'Greens/Teal 200',
  },
  dark_teal_800: {
    name: 'Dark Teal 800',
    lightMode: {
      rgb: 'rgb(79, 110, 109)',
      hsla: 'hsl(178, 16%, 37%)',
      hex: '#4f6e6d',
    },
    darkMode: {
      rgb: 'rgb(225, 233, 233)',
      hsla: 'hsl(180, 15%, 90%)',
      hex: '#e1e9e9',
    },
    group: 'Greens',
    key: 'dark_teal_800',
    fullName: 'Greens/Dark Teal 800',
  },
};

const Severity = {
  critical: {
    name: 'Critical',
    lightMode: {
      rgb: 'rgb(145, 36, 62)',
      hsla: 'hsl(346, 60%, 35%)',
      hex: '#91243e',
    },
    darkMode: {
      rgb: 'rgb(145, 36, 62)',
      hsla: 'hsl(346, 60%, 35%)',
      hex: '#91243e',
    },
    group: 'Severity',
    key: 'critical',
    fullName: 'Severity/Critical',
  },
  critical_hover: {
    name: 'Critical (Hover)',
    lightMode: {
      rgb: 'rgb(121, 28, 50)',
      hsla: 'hsl(346, 62%, 29%)',
      hex: '#791c32',
    },
    darkMode: {
      rgb: 'rgb(222, 189, 197)',
      hsla: 'hsl(345, 33%, 81%)',
      hex: '#debdc5',
    },
    group: 'Severity',
    key: 'critical_hover',
    fullName: 'Severity/Critical (Hover)',
  },
  critical_100: {
    name: 'Critical 100',
    lightMode: {
      rgb: 'rgb(222, 189, 197)',
      hsla: 'hsl(345, 33%, 81%)',
      hex: '#debdc5',
    },
    darkMode: {
      rgb: 'rgb(121, 28, 50)',
      hsla: 'hsl(346, 62%, 29%)',
      hex: '#791c32',
    },
    group: 'Severity',
    key: 'critical_100',
    fullName: 'Severity/Critical 100',
  },
  critical_200: {
    name: 'Critical 200',
    lightMode: {
      rgb: 'rgb(167, 80, 101)',
      hsla: 'hsl(346, 35%, 48%)',
      hex: '#a75065',
    },
    darkMode: {
      rgb: 'rgb(167, 80, 101)',
      hsla: 'hsl(346, 35%, 48%)',
      hex: '#a75065',
    },
    group: 'Severity',
    key: 'critical_200',
    fullName: 'Severity/Critical 200',
  },
  medium: {
    name: 'Medium',
    lightMode: {
      rgb: 'rgb(255, 141, 60)',
      hsla: 'hsl(25, 100%, 62%)',
      hex: '#ff8d3c',
    },
    darkMode: {
      rgb: 'rgb(255, 141, 60)',
      hsla: 'hsl(25, 100%, 62%)',
      hex: '#ff8d3c',
    },
    group: 'Severity',
    key: 'medium',
    fullName: 'Severity/Medium',
  },
  medium_hover: {
    name: 'Medium (Hover)',
    lightMode: {
      rgb: 'rgb(185, 61, 18)',
      hsla: 'hsl(15, 82%, 40%)',
      hex: '#b93d12',
    },
    darkMode: {
      rgb: 'rgb(255, 217, 191)',
      hsla: 'hsl(24, 100%, 87%)',
      hex: '#ffd9bf',
    },
    group: 'Severity',
    key: 'medium_hover',
    fullName: 'Severity/Medium (Hover)',
  },
  medium_200: {
    name: 'Medium 200',
    lightMode: {
      rgb: 'rgb(255, 187, 138)',
      hsla: 'hsl(25, 100%, 77%)',
      hex: '#ffbb8a',
    },
    darkMode: {
      rgb: 'rgb(255, 187, 138)',
      hsla: 'hsl(25, 100%, 77%)',
      hex: '#ffbb8a',
    },
    group: 'Severity',
    key: 'medium_200',
    fullName: 'Severity/Medium 200',
  },
  medium_100: {
    name: 'Medium 100',
    lightMode: {
      rgb: 'rgb(255, 217, 191)',
      hsla: 'hsl(24, 100%, 87%)',
      hex: '#ffd9bf',
    },
    darkMode: {
      rgb: 'rgb(255, 141, 60)',
      hsla: 'hsl(25, 100%, 62%)',
      hex: '#ff8d3c',
    },
    group: 'Severity',
    key: 'medium_100',
    fullName: 'Severity/Medium 100',
  },
  high: {
    name: 'High',
    lightMode: {
      rgb: 'rgb(218, 30, 40)',
      hsla: 'hsl(357, 76%, 49%)',
      hex: '#da1e28',
    },
    darkMode: {
      rgb: 'rgb(218, 30, 40)',
      hsla: 'hsl(357, 76%, 49%)',
      hex: '#da1e28',
    },
    group: 'Severity',
    key: 'high',
    fullName: 'Severity/High',
  },
  high_hover: {
    name: 'High (Hover)',
    lightMode: {
      rgb: 'rgb(194, 33, 46)',
      hsla: 'hsl(355, 71%, 45%)',
      hex: '#c2212e',
    },
    darkMode: {
      rgb: 'rgb(252, 227, 227)',
      hsla: 'hsl(0, 81%, 94%)',
      hex: '#fce3e3',
    },
    group: 'Severity',
    key: 'high_hover',
    fullName: 'Severity/High (Hover)',
  },
  high_200: {
    name: 'High 200',
    lightMode: {
      rgb: 'rgb(233, 120, 126)',
      hsla: 'hsl(357, 72%, 69%)',
      hex: '#e9787e',
    },
    darkMode: {
      rgb: 'rgb(233, 120, 126)',
      hsla: 'hsl(357, 72%, 69%)',
      hex: '#e9787e',
    },
    group: 'Severity',
    key: 'high_200',
    fullName: 'Severity/High 200',
  },
  high_100: {
    name: 'High 100',
    lightMode: {
      rgb: 'rgb(250, 198, 200)',
      hsla: 'hsl(358, 84%, 88%)',
      hex: '#fac6c8',
    },
    darkMode: {
      rgb: 'rgb(218, 30, 40)',
      hsla: 'hsl(357, 76%, 49%)',
      hex: '#da1e28',
    },
    group: 'Severity',
    key: 'high_100',
    fullName: 'Severity/High 100',
  },
  low: {
    name: 'Low',
    lightMode: {
      rgb: 'rgb(237, 164, 0)',
      hsla: 'hsl(42, 100%, 46%)',
      hex: '#eda400',
    },
    darkMode: {
      rgb: 'rgb(237, 164, 0)',
      hsla: 'hsl(42, 100%, 46%)',
      hex: '#eda400',
    },
    group: 'Severity',
    key: 'low',
    fullName: 'Severity/Low',
  },
  low_hover: {
    name: 'Low (Hover)',
    lightMode: {
      rgb: 'rgb(160, 121, 77)',
      hsla: 'hsl(32, 35%, 46%)',
      hex: '#a0794d',
    },
    darkMode: {
      rgb: 'rgb(255, 239, 184)',
      hsla: 'hsl(46, 100%, 86%)',
      hex: '#ffefb8',
    },
    group: 'Severity',
    key: 'low_hover',
    fullName: 'Severity/Low (Hover)',
  },
  low_200: {
    name: 'Low 200',
    lightMode: {
      rgb: 'rgb(255, 227, 128)',
      hsla: 'hsl(47, 100%, 75%)',
      hex: '#ffe380',
    },
    darkMode: {
      rgb: 'rgb(255, 227, 128)',
      hsla: 'hsl(47, 100%, 75%)',
      hex: '#ffe380',
    },
    group: 'Severity',
    key: 'low_200',
    fullName: 'Severity/Low 200',
  },
  low_100: {
    name: 'Low 100',
    lightMode: {
      rgb: 'rgb(255, 239, 184)',
      hsla: 'hsl(46, 100%, 86%)',
      hex: '#ffefb8',
    },
    darkMode: {
      rgb: 'rgb(160, 121, 77)',
      hsla: 'hsl(32, 35%, 46%)',
      hex: '#a0794d',
    },
    group: 'Severity',
    key: 'low_100',
    fullName: 'Severity/Low 100',
  },
  low_50: {
    name: 'Low 50',
    lightMode: {
      rgb: 'rgb(255, 245, 209)',
      hsla: 'hsl(47, 100%, 91%)',
      hex: '#fff5d1',
    },
    darkMode: {
      rgb: 'rgb(155, 74, 42)',
      hsla: 'hsl(17, 57%, 39%)',
      hex: '#9b4a2a',
    },
    group: 'Severity',
    key: 'low_50',
    fullName: 'Severity/Low 50',
  },
  safe: {
    name: 'Safe',
    lightMode: {
      rgb: 'rgb(127, 176, 61)',
      hsla: 'hsl(86, 49%, 46%)',
      hex: '#7fb03d',
    },
    darkMode: {
      rgb: 'rgb(127, 176, 61)',
      hsla: 'hsl(86, 49%, 46%)',
      hex: '#7fb03d',
    },
    group: 'Severity',
    key: 'safe',
    fullName: 'Severity/Safe',
  },
  safe_hover: {
    name: 'Safe (Hover)',
    lightMode: {
      rgb: 'rgb(72, 103, 29)',
      hsla: 'hsl(85, 56%, 26%)',
      hex: '#48671d',
    },
    darkMode: {
      rgb: 'rgb(219, 233, 200)',
      hsla: 'hsl(85, 43%, 85%)',
      hex: '#dbe9c8',
    },
    group: 'Severity',
    key: 'safe_hover',
    fullName: 'Severity/Safe (Hover)',
  },
  safe_200: {
    name: 'Safe 200',
    lightMode: {
      rgb: 'rgb(191, 215, 158)',
      hsla: 'hsl(85, 42%, 73%)',
      hex: '#bfd79e',
    },
    darkMode: {
      rgb: 'rgb(191, 215, 158)',
      hsla: 'hsl(85, 42%, 73%)',
      hex: '#bfd79e',
    },
    group: 'Severity',
    key: 'safe_200',
    fullName: 'Severity/Safe 200',
  },
  safe_100: {
    name: 'Safe 100',
    lightMode: {
      rgb: 'rgb(219, 233, 200)',
      hsla: 'hsl(85, 43%, 85%)',
      hex: '#dbe9c8',
    },
    darkMode: {
      rgb: 'rgb(72, 103, 29)',
      hsla: 'hsl(85, 56%, 26%)',
      hex: '#48671d',
    },
    group: 'Severity',
    key: 'safe_100',
    fullName: 'Severity/Safe 100',
  },
  info: {
    name: 'Info',
    lightMode: {
      rgb: 'rgb(0, 165, 181)',
      hsla: 'hsl(185, 100%, 35%)',
      hex: '#00a5b5',
    },
    darkMode: {
      rgb: 'rgb(0, 165, 181)',
      hsla: 'hsl(185, 100%, 35%)',
      hex: '#00a5b5',
    },
    group: 'Severity',
    key: 'info',
    fullName: 'Severity/Info',
  },
  info_hover: {
    name: 'Info (Hover)',
    lightMode: {
      rgb: 'rgb(0, 128, 140)',
      hsla: 'hsl(185, 100%, 27%)',
      hex: '#00808c',
    },
    darkMode: {
      rgb: 'rgb(190, 241, 246)',
      hsla: 'hsl(185, 76%, 85%)',
      hex: '#bef1f6',
    },
    group: 'Severity',
    key: 'info_hover',
    fullName: 'Severity/Info (Hover)',
  },
  info_200: {
    name: 'Info 200',
    lightMode: {
      rgb: 'rgb(128, 210, 218)',
      hsla: 'hsl(185, 55%, 68%)',
      hex: '#80d2da',
    },
    darkMode: {
      rgb: 'rgb(128, 210, 218)',
      hsla: 'hsl(185, 55%, 68%)',
      hex: '#80d2da',
    },
    group: 'Severity',
    key: 'info_200',
    fullName: 'Severity/Info 200',
  },
  info_100: {
    name: 'Info 100',
    lightMode: {
      rgb: 'rgb(190, 241, 246)',
      hsla: 'hsl(185, 76%, 85%)',
      hex: '#bef1f6',
    },
    darkMode: {
      rgb: 'rgb(0, 128, 140)',
      hsla: 'hsl(185, 100%, 27%)',
      hex: '#00808c',
    },
    group: 'Severity',
    key: 'info_100',
    fullName: 'Severity/Info 100',
  },
  unknown: {
    name: 'Unknown',
    lightMode: {
      rgb: 'rgb(111, 111, 111)',
      hsla: 'hsl(0, 0%, 44%)',
      hex: '#6f6f6f',
    },
    darkMode: {
      rgb: 'rgb(111, 111, 111)',
      hsla: 'hsl(0, 0%, 44%)',
      hex: '#6f6f6f',
    },
    group: 'Severity',
    key: 'unknown',
    fullName: 'Severity/Unknown',
  },
  unknown_hover: {
    name: 'Unknown (Hover)',
    lightMode: {
      rgb: 'rgb(31, 31, 31)',
      hsla: 'hsl(0, 0%, 12%)',
      hex: '#1f1f1f',
    },
    darkMode: {
      rgb: 'rgb(249, 249, 252)',
      hsla: 'hsl(240, 33%, 98%)',
      hex: '#f9f9fc',
    },
    group: 'Severity',
    key: 'unknown_hover',
    fullName: 'Severity/Unknown (Hover)',
  },
  unknown_200: {
    name: 'Unknown 200',
    lightMode: {
      rgb: 'rgb(224, 224, 224)',
      hsla: 'hsl(0, 0%, 88%)',
      hex: '#e0e0e0',
    },
    darkMode: {
      rgb: 'rgb(224, 224, 224)',
      hsla: 'hsl(0, 0%, 88%)',
      hex: '#e0e0e0',
    },
    group: 'Severity',
    key: 'unknown_200',
    fullName: 'Severity/Unknown 200',
  },
  unknown_100: {
    name: 'Unknown 100',
    lightMode: {
      rgb: 'rgb(249, 249, 252)',
      hsla: 'hsl(240, 33%, 98%)',
      hex: '#f9f9fc',
    },
    darkMode: {
      rgb: 'rgb(31, 31, 31)',
      hsla: 'hsl(0, 0%, 12%)',
      hex: '#1f1f1f',
    },
    group: 'Severity',
    key: 'unknown_100',
    fullName: 'Severity/Unknown 100',
  },
  in_progress: {
    name: 'InProgress',
    lightMode: {
      rgb: 'rgb(121, 140, 229)',
      hsla: 'hsl(229, 68%, 69%)',
      hex: '#798ce5',
    },
    darkMode: {
      rgb: 'rgb(121, 140, 229)',
      hsla: 'hsl(229, 68%, 69%)',
      hex: '#798ce5',
    },
    group: 'Severity',
    key: 'in_progress',
    fullName: 'Severity/InProgress',
  },
  in_progress_hover: {
    name: 'InProgress (Hover)',
    lightMode: {
      rgb: 'rgb(82, 98, 170)',
      hsla: 'hsl(229, 35%, 49%)',
      hex: '#5262aa',
    },
    darkMode: {
      rgb: 'rgb(241, 243, 252)',
      hsla: 'hsl(229, 65%, 97%)',
      hex: '#f1f3fc',
    },
    group: 'Severity',
    key: 'in_progress_hover',
    fullName: 'Severity/InProgress (Hover)',
  },
  in_progress_200: {
    name: 'InProgress 200',
    lightMode: {
      rgb: 'rgb(217, 222, 247)',
      hsla: 'hsl(230, 65%, 91%)',
      hex: '#d9def7',
    },
    darkMode: {
      rgb: 'rgb(217, 222, 247)',
      hsla: 'hsl(230, 65%, 91%)',
      hex: '#d9def7',
    },
    group: 'Severity',
    key: 'in_progress_200',
    fullName: 'Severity/InProgress 200',
  },
  in_progress_100: {
    name: 'InProgress 100',
    lightMode: {
      rgb: 'rgb(241, 243, 252)',
      hsla: 'hsl(229, 65%, 97%)',
      hex: '#f1f3fc',
    },
    darkMode: {
      rgb: 'rgb(82, 98, 170)',
      hsla: 'hsl(229, 35%, 49%)',
      hex: '#5262aa',
    },
    group: 'Severity',
    key: 'in_progress_100',
    fullName: 'Severity/InProgress 100',
  },
  high_50: {
    name: 'High 50',
    lightMode: {
      rgb: 'rgb(252, 227, 227)',
      hsla: 'hsl(0, 81%, 94%)',
      hex: '#fce3e3',
    },
    darkMode: {
      rgb: 'rgb(194, 33, 46)',
      hsla: 'hsl(355, 71%, 45%)',
      hex: '#c2212e',
    },
    group: 'Severity',
    key: 'high_50',
    fullName: 'Severity/High 50',
  },
  medium_50: {
    name: 'Medium 50',
    lightMode: {
      rgb: 'rgb(255, 241, 232)',
      hsla: 'hsl(23, 100%, 95%)',
      hex: '#fff1e8',
    },
    darkMode: {
      rgb: 'rgb(185, 61, 18)',
      hsla: 'hsl(15, 82%, 40%)',
      hex: '#b93d12',
    },
    group: 'Severity',
    key: 'medium_50',
    fullName: 'Severity/Medium 50',
  },
};

const Data = {
  green: {
    name: 'Green',
    lightMode: {
      rgb: 'rgb(0, 86, 82)',
      hsla: 'hsl(177, 100%, 17%)',
      hex: '#005652',
    },
    darkMode: {
      rgb: 'rgb(32, 181, 120)',
      hsla: 'hsl(155, 70%, 42%)',
      hex: '#20b578',
    },
    group: 'Data',
    key: 'green',
    fullName: 'Data/Green',
  },
  blue: {
    name: 'Blue',
    lightMode: {
      rgb: 'rgb(28, 142, 215)',
      hsla: 'hsl(203, 77%, 48%)',
      hex: '#1c8ed7',
    },
    darkMode: {
      rgb: 'rgb(28, 142, 215)',
      hsla: 'hsl(203, 77%, 48%)',
      hex: '#1c8ed7',
    },
    group: 'Data',
    key: 'blue',
    fullName: 'Data/Blue',
  },
  teal: {
    name: 'Teal',
    lightMode: {
      rgb: 'rgb(0, 165, 181)',
      hsla: 'hsl(185, 100%, 35%)',
      hex: '#00a5b5',
    },
    darkMode: {
      rgb: 'rgb(0, 165, 181)',
      hsla: 'hsl(185, 100%, 35%)',
      hex: '#00a5b5',
    },
    group: 'Data',
    key: 'teal',
    fullName: 'Data/Teal',
  },
  brown: {
    name: 'Brown',
    lightMode: {
      rgb: 'rgb(201, 116, 70)',
      hsla: 'hsl(21, 55%, 53%)',
      hex: '#c97446',
    },
    darkMode: {
      rgb: 'rgb(201, 116, 70)',
      hsla: 'hsl(21, 55%, 53%)',
      hex: '#c97446',
    },
    group: 'Data',
    key: 'brown',
    fullName: 'Data/Brown',
  },
  salmon: {
    name: 'Salmon',
    lightMode: {
      rgb: 'rgb(227, 79, 103)',
      hsla: 'hsl(350, 73%, 60%)',
      hex: '#e34f67',
    },
    darkMode: {
      rgb: 'rgb(227, 79, 103)',
      hsla: 'hsl(350, 73%, 60%)',
      hex: '#e34f67',
    },
    group: 'Data',
    key: 'salmon',
    fullName: 'Data/Salmon',
  },
  purple: {
    name: 'Purple',
    lightMode: {
      rgb: 'rgb(212, 88, 163)',
      hsla: 'hsl(324, 59%, 59%)',
      hex: '#d458a3',
    },
    darkMode: {
      rgb: 'rgb(212, 88, 163)',
      hsla: 'hsl(324, 59%, 59%)',
      hex: '#d458a3',
    },
    group: 'Data',
    key: 'purple',
    fullName: 'Data/Purple',
  },
  violet: {
    name: 'Violet',
    lightMode: {
      rgb: 'rgb(82, 98, 170)',
      hsla: 'hsl(229, 35%, 49%)',
      hex: '#5262aa',
    },
    darkMode: {
      rgb: 'rgb(82, 98, 170)',
      hsla: 'hsl(229, 35%, 49%)',
      hex: '#5262aa',
    },
    group: 'Data',
    key: 'violet',
    fullName: 'Data/Violet',
  },
  blue_400: {
    name: 'Blue 400',
    lightMode: {
      rgb: 'rgb(64, 181, 255)',
      hsla: 'hsl(203, 100%, 63%)',
      hex: '#40b5ff',
    },
    darkMode: {
      rgb: 'rgb(202, 234, 255)',
      hsla: 'hsl(204, 100%, 90%)',
      hex: '#caeaff',
    },
    group: 'Data',
    key: 'blue_400',
    fullName: 'Data/Blue 400',
  },
  blue_hover: {
    name: 'Blue (Hover)',
    lightMode: {
      rgb: 'rgb(0, 113, 185)',
      hsla: 'hsl(203, 100%, 36%)',
      hex: '#0071b9',
    },
    darkMode: {
      rgb: 'rgb(229, 245, 255)',
      hsla: 'hsl(203, 100%, 95%)',
      hex: '#e5f5ff',
    },
    group: 'Data',
    key: 'blue_hover',
    fullName: 'Data/Blue (Hover)',
  },
  blue_300: {
    name: 'Blue 300',
    lightMode: {
      rgb: 'rgb(141, 210, 255)',
      hsla: 'hsl(204, 100%, 78%)',
      hex: '#8dd2ff',
    },
    darkMode: {
      rgb: 'rgb(141, 210, 255)',
      hsla: 'hsl(204, 100%, 78%)',
      hex: '#8dd2ff',
    },
    group: 'Data',
    key: 'blue_300',
    fullName: 'Data/Blue 300',
  },
  blue_200: {
    name: 'Blue 200',
    lightMode: {
      rgb: 'rgb(202, 234, 255)',
      hsla: 'hsl(204, 100%, 90%)',
      hex: '#caeaff',
    },
    darkMode: {
      rgb: 'rgb(28, 142, 215)',
      hsla: 'hsl(203, 77%, 48%)',
      hex: '#1c8ed7',
    },
    group: 'Data',
    key: 'blue_200',
    fullName: 'Data/Blue 200',
  },
  blue_100: {
    name: 'Blue 100',
    lightMode: {
      rgb: 'rgb(229, 245, 255)',
      hsla: 'hsl(203, 100%, 95%)',
      hex: '#e5f5ff',
    },
    darkMode: {
      rgb: 'rgb(0, 113, 185)',
      hsla: 'hsl(203, 100%, 36%)',
      hex: '#0071b9',
    },
    group: 'Data',
    key: 'blue_100',
    fullName: 'Data/Blue 100',
  },
  brown_hover: {
    name: 'Brown (Hover)',
    lightMode: {
      rgb: 'rgb(155, 74, 42)',
      hsla: 'hsl(17, 57%, 39%)',
      hex: '#9b4a2a',
    },
    darkMode: {
      rgb: 'rgb(254, 245, 239)',
      hsla: 'hsl(24, 88%, 97%)',
      hex: '#fef5ef',
    },
    group: 'Data',
    key: 'brown_hover',
    fullName: 'Data/Brown (Hover)',
  },
  green_hover: {
    name: 'Green (Hover)',
    lightMode: {
      rgb: 'rgb(23, 60, 60)',
      hsla: 'hsl(180, 45%, 16%)',
      hex: '#173c3c',
    },
    darkMode: {
      rgb: 'rgb(233, 249, 242)',
      hsla: 'hsl(154, 57%, 95%)',
      hex: '#e9f9f2',
    },
    group: 'Data',
    key: 'green_hover',
    fullName: 'Data/Green (Hover)',
  },
  green_400: {
    name: 'Green 400',
    lightMode: {
      rgb: 'rgb(32, 181, 120)',
      hsla: 'hsl(155, 70%, 42%)',
      hex: '#20b578',
    },
    darkMode: {
      rgb: 'rgb(194, 239, 219)',
      hsla: 'hsl(153, 58%, 85%)',
      hex: '#c2efdb',
    },
    group: 'Data',
    key: 'green_400',
    fullName: 'Data/Green 400',
  },
  green_300: {
    name: 'Green 300',
    lightMode: {
      rgb: 'rgb(125, 223, 179)',
      hsla: 'hsl(153, 60%, 68%)',
      hex: '#7ddfb3',
    },
    darkMode: {
      rgb: 'rgb(125, 223, 179)',
      hsla: 'hsl(153, 60%, 68%)',
      hex: '#7ddfb3',
    },
    group: 'Data',
    key: 'green_300',
    fullName: 'Data/Green 300',
  },
  green_200: {
    name: 'Green 200',
    lightMode: {
      rgb: 'rgb(194, 239, 219)',
      hsla: 'hsl(153, 58%, 85%)',
      hex: '#c2efdb',
    },
    darkMode: {
      rgb: 'rgb(32, 181, 120)',
      hsla: 'hsl(155, 70%, 42%)',
      hex: '#20b578',
    },
    group: 'Data',
    key: 'green_200',
    fullName: 'Data/Green 200',
  },
  green_100: {
    name: 'Green 100',
    lightMode: {
      rgb: 'rgb(233, 249, 242)',
      hsla: 'hsl(154, 57%, 95%)',
      hex: '#e9f9f2',
    },
    darkMode: {
      rgb: 'rgb(23, 60, 60)',
      hsla: 'hsl(180, 45%, 16%)',
      hex: '#173c3c',
    },
    group: 'Data',
    key: 'green_100',
    fullName: 'Data/Green 100',
  },
  teal_hover: {
    name: 'Teal (Hover)',
    lightMode: {
      rgb: 'rgb(0, 128, 140)',
      hsla: 'hsl(185, 100%, 27%)',
      hex: '#00808c',
    },
    darkMode: {
      rgb: 'rgb(232, 250, 252)',
      hsla: 'hsl(186, 77%, 95%)',
      hex: '#e8fafc',
    },
    group: 'Data',
    key: 'teal_hover',
    fullName: 'Data/Teal (Hover)',
  },
  teal_400: {
    name: 'Teal 400',
    lightMode: {
      rgb: 'rgb(19, 196, 213)',
      hsla: 'hsl(185, 84%, 45%)',
      hex: '#13c4d5',
    },
    darkMode: {
      rgb: 'rgb(190, 241, 246)',
      hsla: 'hsl(185, 76%, 85%)',
      hex: '#bef1f6',
    },
    group: 'Data',
    key: 'teal_400',
    fullName: 'Data/Teal 400',
  },
  teal_300: {
    name: 'Teal 300',
    lightMode: {
      rgb: 'rgb(115, 227, 238)',
      hsla: 'hsl(185, 78%, 69%)',
      hex: '#73e3ee',
    },
    darkMode: {
      rgb: 'rgb(115, 227, 238)',
      hsla: 'hsl(185, 78%, 69%)',
      hex: '#73e3ee',
    },
    group: 'Data',
    key: 'teal_300',
    fullName: 'Data/Teal 300',
  },
  teal_200: {
    name: 'Teal 200',
    lightMode: {
      rgb: 'rgb(190, 241, 246)',
      hsla: 'hsl(185, 76%, 85%)',
      hex: '#bef1f6',
    },
    darkMode: {
      rgb: 'rgb(19, 196, 213)',
      hsla: 'hsl(185, 84%, 45%)',
      hex: '#13c4d5',
    },
    group: 'Data',
    key: 'teal_200',
    fullName: 'Data/Teal 200',
  },
  teal_100: {
    name: 'Teal 100',
    lightMode: {
      rgb: 'rgb(232, 250, 252)',
      hsla: 'hsl(186, 77%, 95%)',
      hex: '#e8fafc',
    },
    darkMode: {
      rgb: 'rgb(0, 128, 140)',
      hsla: 'hsl(185, 100%, 27%)',
      hex: '#00808c',
    },
    group: 'Data',
    key: 'teal_100',
    fullName: 'Data/Teal 100',
  },
  brown_400: {
    name: 'Brown 400',
    lightMode: {
      rgb: 'rgb(251, 161, 101)',
      hsla: 'hsl(24, 95%, 69%)',
      hex: '#fba165',
    },
    darkMode: {
      rgb: 'rgb(253, 228, 212)',
      hsla: 'hsl(23, 91%, 91%)',
      hex: '#fde4d4',
    },
    group: 'Data',
    key: 'brown_400',
    fullName: 'Data/Brown 400',
  },
  brown_300: {
    name: 'Brown 300',
    lightMode: {
      rgb: 'rgb(252, 198, 162)',
      hsla: 'hsl(24, 94%, 81%)',
      hex: '#fcc6a2',
    },
    darkMode: {
      rgb: 'rgb(252, 198, 162)',
      hsla: 'hsl(24, 94%, 81%)',
      hex: '#fcc6a2',
    },
    group: 'Data',
    key: 'brown_300',
    fullName: 'Data/Brown 300',
  },
  brown_200: {
    name: 'Brown 200',
    lightMode: {
      rgb: 'rgb(253, 228, 212)',
      hsla: 'hsl(23, 91%, 91%)',
      hex: '#fde4d4',
    },
    darkMode: {
      rgb: 'rgb(251, 161, 101)',
      hsla: 'hsl(24, 95%, 69%)',
      hex: '#fba165',
    },
    group: 'Data',
    key: 'brown_200',
    fullName: 'Data/Brown 200',
  },
  brown_100: {
    name: 'Brown 100',
    lightMode: {
      rgb: 'rgb(254, 245, 239)',
      hsla: 'hsl(24, 88%, 97%)',
      hex: '#fef5ef',
    },
    darkMode: {
      rgb: 'rgb(155, 74, 42)',
      hsla: 'hsl(17, 57%, 39%)',
      hex: '#9b4a2a',
    },
    group: 'Data',
    key: 'brown_100',
    fullName: 'Data/Brown 100',
  },
  salmon_hover: {
    name: 'Salmon (Hover)',
    lightMode: {
      rgb: 'rgb(206, 63, 86)',
      hsla: 'hsl(350, 59%, 53%)',
      hex: '#ce3f56',
    },
    darkMode: {
      rgb: 'rgb(252, 227, 227)',
      hsla: 'hsl(0, 81%, 94%)',
      hex: '#fce3e3',
    },
    group: 'Data',
    key: 'salmon_hover',
    fullName: 'Data/Salmon (Hover)',
  },
  salmon_400: {
    name: 'Salmon 400',
    lightMode: {
      rgb: 'rgb(253, 99, 123)',
      hsla: 'hsl(351, 97%, 69%)',
      hex: '#fd637b',
    },
    darkMode: {
      rgb: 'rgb(254, 211, 218)',
      hsla: 'hsl(350, 96%, 91%)',
      hex: '#fed3da',
    },
    group: 'Data',
    key: 'salmon_400',
    fullName: 'Data/Salmon 400',
  },
  salmon_300: {
    name: 'Salmon 300',
    lightMode: {
      rgb: 'rgb(253, 161, 176)',
      hsla: 'hsl(350, 96%, 81%)',
      hex: '#fda1b0',
    },
    darkMode: {
      rgb: 'rgb(253, 161, 176)',
      hsla: 'hsl(350, 96%, 81%)',
      hex: '#fda1b0',
    },
    group: 'Data',
    key: 'salmon_300',
    fullName: 'Data/Salmon 300',
  },
  salmon_200: {
    name: 'Salmon 200',
    lightMode: {
      rgb: 'rgb(254, 211, 218)',
      hsla: 'hsl(350, 96%, 91%)',
      hex: '#fed3da',
    },
    darkMode: {
      rgb: 'rgb(253, 99, 123)',
      hsla: 'hsl(351, 97%, 69%)',
      hex: '#fd637b',
    },
    group: 'Data',
    key: 'salmon_200',
    fullName: 'Data/Salmon 200',
  },
  salmon_100: {
    name: 'Salmon 100',
    lightMode: {
      rgb: 'rgb(252, 227, 227)',
      hsla: 'hsl(0, 81%, 94%)',
      hex: '#fce3e3',
    },
    darkMode: {
      rgb: 'rgb(206, 63, 86)',
      hsla: 'hsl(350, 59%, 53%)',
      hex: '#ce3f56',
    },
    group: 'Data',
    key: 'salmon_100',
    fullName: 'Data/Salmon 100',
  },
  purple_hover: {
    name: 'Purple (Hover)',
    lightMode: {
      rgb: 'rgb(191, 70, 143)',
      hsla: 'hsl(324, 49%, 51%)',
      hex: '#bf468f',
    },
    darkMode: {
      rgb: 'rgb(254, 241, 249)',
      hsla: 'hsl(323, 87%, 97%)',
      hex: '#fef1f9',
    },
    group: 'Data',
    key: 'purple_hover',
    fullName: 'Data/Purple (Hover)',
  },
  purple_400: {
    name: 'Purple 400',
    lightMode: {
      rgb: 'rgb(244, 117, 194)',
      hsla: 'hsl(324, 85%, 71%)',
      hex: '#f475c2',
    },
    darkMode: {
      rgb: 'rgb(252, 217, 238)',
      hsla: 'hsl(324, 85%, 92%)',
      hex: '#fcd9ee',
    },
    group: 'Data',
    key: 'purple_400',
    fullName: 'Data/Purple 400',
  },
  purple_300: {
    name: 'Purple 300',
    lightMode: {
      rgb: 'rgb(250, 174, 220)',
      hsla: 'hsl(324, 88%, 83%)',
      hex: '#faaedc',
    },
    darkMode: {
      rgb: 'rgb(250, 174, 220)',
      hsla: 'hsl(324, 88%, 83%)',
      hex: '#faaedc',
    },
    group: 'Data',
    key: 'purple_300',
    fullName: 'Data/Purple 300',
  },
  purple_200: {
    name: 'Purple 200',
    lightMode: {
      rgb: 'rgb(252, 217, 238)',
      hsla: 'hsl(324, 85%, 92%)',
      hex: '#fcd9ee',
    },
    darkMode: {
      rgb: 'rgb(244, 117, 194)',
      hsla: 'hsl(324, 85%, 71%)',
      hex: '#f475c2',
    },
    group: 'Data',
    key: 'purple_200',
    fullName: 'Data/Purple 200',
  },
  purple_100: {
    name: 'Purple 100',
    lightMode: {
      rgb: 'rgb(254, 241, 249)',
      hsla: 'hsl(323, 87%, 97%)',
      hex: '#fef1f9',
    },
    darkMode: {
      rgb: 'rgb(191, 70, 143)',
      hsla: 'hsl(324, 49%, 51%)',
      hex: '#bf468f',
    },
    group: 'Data',
    key: 'purple_100',
    fullName: 'Data/Purple 100',
  },
  violet_hover: {
    name: 'Violet (Hover)',
    lightMode: {
      rgb: 'rgb(45, 59, 114)',
      hsla: 'hsl(228, 43%, 31%)',
      hex: '#2d3b72',
    },
    darkMode: {
      rgb: 'rgb(241, 243, 252)',
      hsla: 'hsl(229, 65%, 97%)',
      hex: '#f1f3fc',
    },
    group: 'Data',
    key: 'violet_hover',
    fullName: 'Data/Violet (Hover)',
  },
  violet_400: {
    name: 'Violet 400',
    lightMode: {
      rgb: 'rgb(121, 140, 229)',
      hsla: 'hsl(229, 68%, 69%)',
      hex: '#798ce5',
    },
    darkMode: {
      rgb: 'rgb(217, 222, 247)',
      hsla: 'hsl(230, 65%, 91%)',
      hex: '#d9def7',
    },
    group: 'Data',
    key: 'violet_400',
    fullName: 'Data/Violet 400',
  },
  violet_300: {
    name: 'Violet 300',
    lightMode: {
      rgb: 'rgb(174, 186, 239)',
      hsla: 'hsl(229, 67%, 81%)',
      hex: '#aebaef',
    },
    darkMode: {
      rgb: 'rgb(174, 186, 239)',
      hsla: 'hsl(229, 67%, 81%)',
      hex: '#aebaef',
    },
    group: 'Data',
    key: 'violet_300',
    fullName: 'Data/Violet 300',
  },
  violet_200: {
    name: 'Violet 200',
    lightMode: {
      rgb: 'rgb(217, 222, 247)',
      hsla: 'hsl(230, 65%, 91%)',
      hex: '#d9def7',
    },
    darkMode: {
      rgb: 'rgb(121, 140, 229)',
      hsla: 'hsl(229, 68%, 69%)',
      hex: '#798ce5',
    },
    group: 'Data',
    key: 'violet_200',
    fullName: 'Data/Violet 200',
  },
  violet_100: {
    name: 'Violet 100',
    lightMode: {
      rgb: 'rgb(241, 243, 252)',
      hsla: 'hsl(229, 65%, 97%)',
      hex: '#f1f3fc',
    },
    darkMode: {
      rgb: 'rgb(45, 59, 114)',
      hsla: 'hsl(228, 43%, 31%)',
      hex: '#2d3b72',
    },
    group: 'Data',
    key: 'violet_100',
    fullName: 'Data/Violet 100',
  },
};

const Hots = {
  hot_navy: {
    name: 'Hot Navy',
    lightMode: {
      rgb: 'rgb(0, 37, 247)',
      hsla: 'hsl(231, 100%, 48%)',
      hex: '#0025f7',
    },
    darkMode: {
      rgb: 'rgb(0, 37, 247)',
      hsla: 'hsl(231, 100%, 48%)',
      hex: '#0025f7',
    },
    group: 'Hot',
    key: 'hot_navy',
    fullName: 'Hot/Hot Navy',
  },
  hot_blue: {
    name: 'Hot Blue',
    lightMode: {
      rgb: 'rgb(63, 225, 243)',
      hsla: 'hsl(186, 88%, 60%)',
      hex: '#3fe1f3',
    },
    darkMode: {
      rgb: 'rgb(63, 225, 243)',
      hsla: 'hsl(186, 88%, 60%)',
      hex: '#3fe1f3',
    },
    group: 'Hot',
    key: 'hot_blue',
    fullName: 'Hot/Hot Blue',
  },
  hot_purple: {
    name: 'Hot Purple',
    lightMode: {
      rgb: 'rgb(159, 105, 247)',
      hsla: 'hsl(263, 90%, 69%)',
      hex: '#9f69f7',
    },
    darkMode: {
      rgb: 'rgb(159, 105, 247)',
      hsla: 'hsl(263, 90%, 69%)',
      hex: '#9f69f7',
    },
    group: 'Hot',
    key: 'hot_purple',
    fullName: 'Hot/Hot Purple',
  },
};

export const DesignSystemColors: Record<
  string,
  Record<string, PaletteColorObj>
> = {
  Severity,
  Grays,
  Greens,
  Data,
  Hots,
  Text,
};
