import {
  Card,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty as _isEmpty } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { useGetVersionUpgrade } from '@endorlabs/queries';
import { NavigationTabs, RowStack } from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import {
  BreakingChangesSection,
  FixedFindingsSection,
  PotentialConflictsSection,
  RemediationDetails,
  RemediationOverviewStats,
  RemediationRecommendationDisplay,
  RemediationRecommendationType,
  RemediationRiskDrivers,
} from '../../domains/Remediations';
import { useAuthInfo } from '../../providers';
import { useFullMatch } from '../../routes';
import {
  RemediationDetailTabNames,
  useRemediationDetailTabs,
} from './useRemediationDetailTabs';

export const RemediationDetailPage = () => {
  const {
    params: { upgradeUuid, activeView },
  } = useFullMatch();
  const { activeNamespace: tenantName } = useAuthInfo();

  const { palette, space } = useTheme();

  const qGetVersionUpgrade = useGetVersionUpgrade(tenantName, {
    uuid: upgradeUuid,
  });

  const upgradeInfo = qGetVersionUpgrade.data?.spec?.upgrade_info;
  const isLoading = qGetVersionUpgrade.isLoading;

  const dependencyName = upgradeInfo?.direct_dependency_package ?? '';

  const { activeTab, tabRecords } = useRemediationDetailTabs({
    upgradeInfo,
    activeTab: activeView,
  });

  const title = (
    <Stack direction="column" spacing={2} alignItems="start">
      {isLoading ? (
        <Skeleton width={240} />
      ) : (
        <RemediationRecommendationDisplay
          display="inline"
          recommendation={RemediationRecommendationType.Upgrade}
          packageName={dependencyName}
          fromVersion={upgradeInfo?.from_version}
          toVersion={upgradeInfo?.to_version}
          size="large"
        />
      )}
    </Stack>
  );

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader title={title} />
      </Grid>
      <Grid item>
        <Card
          elevation={0}
          variant="outlined"
          sx={{
            backgroundColor: palette.background.paper,
            padding: space.md,
          }}
        >
          <RowStack>
            <RemediationOverviewStats
              upgradeInfo={upgradeInfo}
              variant="column"
            />
            <Stack
              alignItems="stretch"
              alignSelf="stretch"
              flex="1 1 0"
              spacing={space.sm}
            >
              <Typography variant="subtitle2">
                <FM defaultMessage="Remediation Risk Drivers" />
              </Typography>
              <RemediationRiskDrivers upgradeInfo={upgradeInfo} />
            </Stack>
            <Stack
              alignItems="stretch"
              alignSelf="stretch"
              flex="1 1 0"
              spacing={space.sm}
            >
              <Typography variant="subtitle2">
                <FM defaultMessage="Details" />
              </Typography>
              <RemediationDetails upgradeInfo={upgradeInfo} />
            </Stack>
          </RowStack>
        </Card>
      </Grid>
      <Grid item>
        <NavigationTabs
          id="remediation-details-tabs"
          activeTab={activeTab}
          tabs={tabRecords}
        />
      </Grid>

      <Grid item>
        <Card
          elevation={0}
          variant="outlined"
          sx={{
            backgroundColor: palette.background.paper,
            padding: space.md,
          }}
        >
          {activeTab === RemediationDetailTabNames.FIXED_FINDINGS && (
            <FixedFindingsSection upgradeInfo={upgradeInfo} />
          )}
          {activeTab === RemediationDetailTabNames.CONFLICTS && (
            <PotentialConflictsSection upgradeInfo={upgradeInfo} />
          )}
          {activeTab === RemediationDetailTabNames.BREAKING_CHANGES && (
            <BreakingChangesSection upgradeInfo={upgradeInfo} />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};
