import { Skeleton, Stack } from '@mui/material';

import {
  RepositoryResource,
  RepoVersionResource,
  selectMetricCiCdToolsByCategory,
  useListMetrics,
} from '@endorlabs/queries';
import { ToolCategoryDisplay } from '@endorlabs/ui-common';

import { InfoDrawerContent, InfoDrawerContentProps } from '../InfoDrawer';
import { ProjectToolDetailDrawerToolEvidence } from './ProjectToolDetailDrawerToolEvidence';

export interface ProjectToolsDetailDrawerProps extends InfoDrawerContentProps {
  namespace: string;
  repository?: RepositoryResource;
  repositoryVersion?: RepoVersionResource;
  toolCategory: string;
}

/**
 * Detail Info Drawer for a Project Tools Table rows
 */
export const ProjectToolsDetailDrawer = ({
  namespace,
  repository,
  repositoryVersion,
  toolCategory,
}: ProjectToolsDetailDrawerProps) => {
  const qListCiCdTools = useListMetrics(
    repositoryVersion?.tenant_meta.namespace ?? '',
    { enabled: !!repositoryVersion },
    {
      filter: [
        `meta.name==version_cicd_tools`,
        `meta.parent_uuid==${repositoryVersion?.uuid}`,
      ].join(' and '),
      mask: 'spec.metric_values',
      page_size: 1,
    }
  );

  const isLoading = qListCiCdTools.isLoading;

  const toolsByCategory = selectMetricCiCdToolsByCategory(
    qListCiCdTools.data?.list?.objects[0]
  );

  const tools = toolsByCategory[toolCategory] ?? [];

  if (!namespace) return null;

  const title = (
    <Stack direction="column" spacing={2} alignItems="start">
      {isLoading ? (
        <Skeleton width={240} />
      ) : (
        <ToolCategoryDisplay category={toolCategory} size="medium" />
      )}
    </Stack>
  );

  return (
    <InfoDrawerContent title={title}>
      <ProjectToolDetailDrawerToolEvidence
        tools={tools}
        repository={repository}
        repositoryVersion={repositoryVersion}
      />
    </InfoDrawerContent>
  );
};
