import {
  Box,
  Skeleton,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';

import { SpecFindingLevel } from '@endorlabs/api_client';
import {
  FindingResource,
  getFindingTitle,
  getSecurityFindingCveId,
} from '@endorlabs/endor-core/Finding';

import { RowStack } from '../../../../components/RowStack';
import { TShirtSize, TShirtSizes } from '../../../../types';
import { CveIdDisplay } from '../CveIdDisplay';
import { FindingAttributesArrayDisplay } from '../FindingAttributesArrayDisplay';
import { FindingCategoriesArrayDisplay } from '../FindingCategoriesArrayDisplay';
import { FindingLevelChip } from '../FindingLevelChip';

interface FindingNameDisplayVariant {
  chipSize: TShirtSize;
  typProps: TypographyProps;
}

const VariantProps: Record<string, FindingNameDisplayVariant> = {
  [TShirtSizes.xsmall]: {
    chipSize: 'xsmall',
    typProps: { lineHeight: '1.2', variant: 'body2' },
  },
  [TShirtSizes.small]: {
    chipSize: 'small',
    typProps: { lineHeight: '1.2', variant: 'body1' },
  },
  [TShirtSizes.medium]: {
    chipSize: 'small',
    typProps: { lineHeight: '1.2', variant: 'button' },
  },
  [TShirtSizes.large]: {
    chipSize: 'medium',
    typProps: { lineHeight: '1.2', variant: 'h3' },
  },
  [TShirtSizes.xlarge]: {
    chipSize: 'medium',
    typProps: { lineHeight: '1.2', variant: 'h3' },
  },
};

interface MinimalFinding {
  meta: { name: string };
  spec: {
    level: SpecFindingLevel;
    finding_categories?: FindingResource['spec']['finding_categories'];
    finding_tags?: FindingResource['spec']['finding_tags'];
  };
}
interface FindingNameDisplayProps {
  finding?: Partial<FindingResource>;
  showCategories?: boolean;
  showCve?: boolean;
  showIcon?: boolean;
  showTags?: boolean;
  size?: TShirtSize;
}

export const FindingNameDisplay = ({
  finding,
  showCategories = false,
  showCve = true,
  showIcon = true,
  showTags = false,
  size = 'medium',
}: FindingNameDisplayProps) => {
  const { space, spacing } = useTheme();
  const variantProps = VariantProps[size] ?? VariantProps.medium;
  const cveId = finding ? getSecurityFindingCveId(finding) : undefined;

  return (
    <Stack>
      <RowStack alignItems="flex-start" flexWrap="nowrap" gap={space.xs}>
        {showIcon &&
          (finding ? (
            <FindingLevelChip
              level={finding.spec?.level as SpecFindingLevel}
              size={variantProps.chipSize}
            />
          ) : (
            <Skeleton variant="rounded" width={16} />
          ))}

        {finding ? (
          <Stack gap={1}>
            <Typography {...variantProps.typProps}>
              {getFindingTitle(finding)}
            </Typography>

            {showCategories && finding?.spec?.finding_categories && (
              <FindingCategoriesArrayDisplay
                value={finding?.spec?.finding_categories}
              />
            )}

            {showTags && finding?.spec?.finding_tags && (
              <FindingAttributesArrayDisplay
                value={finding?.spec?.finding_tags}
              />
            )}
          </Stack>
        ) : (
          <Skeleton width={60} />
        )}
      </RowStack>

      {showCve && cveId && (
        <Box marginLeft={spacing(getCveOffset(size))}>
          <CveIdDisplay cveId={cveId} />
        </Box>
      )}
    </Stack>
  );
};

function getCveOffset(size: TShirtSize) {
  switch (size) {
    case 'xsmall':
      return 6;
    case 'small':
    case 'medium':
      return 7;
    case 'large':
    case 'xlarge':
      return 8;
    default:
      return 6;
  }
}
