import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { findKey as _findKey, remove as _remove } from 'lodash-es';
import { useMemo } from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import {
  useCountInstallations,
  useFeatureFlags,
  useGroupNotificationTargets,
  useListPackageManagers,
} from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  ButtonLinkSecondary,
  IconCloud,
  NotificationTargetActionTypeKey,
  NotificationTargetActionTypeKeys,
  NotificationTargetIntegrationDisplay,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { useAuthInfo } from '../../providers';
import { getCustomTemplatePath, getIntegrationsPath } from '../../routes';
import {
  CUSTOM_TEMPLATE_TARGETS,
  NOTIFICATION_TARGETS,
  PACKAGE_MANAGERS,
  SOURCE_CONTROL_MANAGERS,
} from './constants';

const messages = defineMessages({
  notificationTargetsCount: {
    defaultMessage:
      '{count, plural, =0 {No notification integrations} one {# notification integration} other {# notification integrations} } configured',
  },
  sourceControlManagerCount: {
    defaultMessage:
      '{count, plural, =0 {No integrations} one {One integration} other {# integrations} } installed',
  },
  packageManagerCount: {
    defaultMessage:
      '{count, plural, =0 {No package managers} one {One package manager} other {# package managers}} defined',
  },
});

export const IntegrationsPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    ENABLE_CUSTOM_PR_COMMENT_TEMPLATES: isCustomPRCommentTemplatesEnabled,
    ENABLE_VANTA: isVantaEnabled,
    ENABLE_SLACK_INTEGRATION: isSlackIntegrationEnabled,
  } = useFeatureFlags();

  const qPackageManagersList = useListPackageManagers(tenantName);
  const activePackageManagers = qPackageManagersList.data?.list?.objects ?? [];

  // NOTE: considering all installations as GitHub
  const qGitHubInstallationCount = useCountInstallations(tenantName);

  const qGroupNotificationTargets = useGroupNotificationTargets(tenantName, {
    group: { aggregation_paths: 'spec.action.action_type' },
  });

  // hydrate the list with the fetched data
  const sourceControlManagers = useMemo(() => {
    const [github, gitlab] = SOURCE_CONTROL_MANAGERS.map((scm) => ({
      ...scm,
      url: getIntegrationsPath({
        tenantName,
        section: 'source-control',
        key: scm.key,
      }),
    }));

    github.isLoading = qGitHubInstallationCount.isLoading;
    if (qGitHubInstallationCount.data) {
      github.installationCount = qGitHubInstallationCount.data.count;
    }

    return [github, gitlab];
  }, [
    qGitHubInstallationCount.data,
    qGitHubInstallationCount.isLoading,
    tenantName,
  ]);

  const packageManagers = useMemo(() => {
    return PACKAGE_MANAGERS.map((pm) => ({
      ...pm,
      url: getIntegrationsPath({
        tenantName,
        section: 'package-managers',
        key: pm.key,
      }),
    }));
  }, [tenantName]);

  const notificationTargets = useMemo(() => {
    const { isLoading, data } = qGroupNotificationTargets;
    const countByNotificationTargetKey = Object.entries(
      data?.groups ?? {}
    ).reduce((acc, [key, groupData]) => {
      const notificationTargetKey = _findKey(
        NotificationTargetActionTypeKeys,
        (notificationTarget) => key.includes(notificationTarget)
      ) as NotificationTargetActionTypeKey | undefined;
      if (notificationTargetKey) {
        acc[notificationTargetKey] = groupData.aggregation_count?.count ?? 1;
      }
      return acc;
    }, {} as Record<keyof typeof NotificationTargetActionTypeKeys, number>);

    const targets = NOTIFICATION_TARGETS.slice();

    if (!isVantaEnabled) {
      _remove(targets, (t) => t.key === 'vanta');
    }

    if (!isSlackIntegrationEnabled) {
      _remove(targets, (t) => t.key === 'slack');
    }

    return targets.map((at) => ({
      ...at,
      isLoading,
      count: countByNotificationTargetKey[at.key] ?? 0,
      url: getIntegrationsPath({
        tenantName,
        section: 'notification-targets',
        key: at.key,
      }),
    }));
  }, [
    isSlackIntegrationEnabled,
    isVantaEnabled,
    qGroupNotificationTargets,
    tenantName,
  ]);

  const customTemplateTargets = useMemo(() => {
    return CUSTOM_TEMPLATE_TARGETS.map((ct) => ({
      ...ct,
      url: getCustomTemplatePath({
        tenantName,
        section: 'custom-notification-template',
        key: ct.key,
      }),
    }));
  }, [tenantName]);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={10}>
      <Grid item>
        <PageHeader
          Icon={IconCloud}
          title={<FM defaultMessage="Integrations" />}
          metadata={{ summary: [] }}
        />
      </Grid>

      <Grid item container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h2">
            <FM defaultMessage="Source Control Managers" />
          </Typography>
        </Grid>

        {sourceControlManagers.map(
          (
            { disabled, label, Icon, installationCount, isLoading, url },
            index
          ) => (
            <Grid key={index} item sm={12} md={6} lg={4}>
              <Card>
                <CardHeader
                  title={label}
                  avatar={<Icon fontSize="large" />}
                  action={
                    installationCount === 0 ? (
                      <ButtonLinkPrimary
                        disabled={disabled || isLoading}
                        linkProps={{ to: url }}
                      >
                        <FM defaultMessage="Connect" />
                      </ButtonLinkPrimary>
                    ) : (
                      <ButtonLinkSecondary
                        disabled={disabled || isLoading}
                        linkProps={{ to: url }}
                      >
                        <FM defaultMessage="Manage" />
                      </ButtonLinkSecondary>
                    )
                  }
                  titleTypographyProps={{
                    component: 'h3',
                    variant: 'h2',
                  }}
                ></CardHeader>
                <CardContent>
                  <Typography color="text.secondary">
                    {isLoading ? (
                      <Skeleton width={80} />
                    ) : (
                      <FM
                        {...messages.sourceControlManagerCount}
                        values={{ count: installationCount }}
                      />
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        )}
      </Grid>

      <Grid item container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h2">
            <FM defaultMessage="Package Managers" />
          </Typography>
        </Grid>

        {packageManagers.map(({ label, Icon, key, url, isLoading }, index) => {
          const packageManagersOfType = activePackageManagers.filter(
            (pm) => !!pm.spec[key]
          );

          return (
            <Grid key={index} item sm={12} md={6} lg={4}>
              <Card>
                <CardHeader
                  title={label}
                  avatar={<Icon fontSize="large" />}
                  action={
                    <ButtonLinkSecondary
                      disabled={isLoading}
                      linkProps={{ to: url }}
                    >
                      <FM defaultMessage="Manage" />
                    </ButtonLinkSecondary>
                  }
                  titleTypographyProps={{
                    component: 'h3',
                    variant: 'h2',
                  }}
                ></CardHeader>
                <CardContent>
                  <Typography color="text.secondary">
                    {isLoading ? (
                      <Skeleton width={80} />
                    ) : (
                      <FM
                        {...messages.packageManagerCount}
                        values={{ count: packageManagersOfType.length }}
                      />
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Grid item container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h2">
            <FM defaultMessage="Notifications" />
          </Typography>
        </Grid>

        {notificationTargets.map(
          ({ count, disabled, Icon, isLoading, label, url }, index) => (
            <Grid key={index} item sm={12} md={6} lg={4}>
              <NotificationTargetIntegrationDisplay
                action={
                  count === 0 ? (
                    <ButtonLinkPrimary
                      disabled={disabled || isLoading}
                      linkProps={{ to: url }}
                    >
                      <FM defaultMessage="Add" />
                    </ButtonLinkPrimary>
                  ) : (
                    <ButtonLinkSecondary
                      disabled={disabled || isLoading}
                      linkProps={{ to: url }}
                    >
                      <FM defaultMessage="Manage" />
                    </ButtonLinkSecondary>
                  )
                }
                count={count}
                Icon={Icon}
                isLoading={isLoading}
                title={label}
              />
            </Grid>
          )
        )}
        {isCustomPRCommentTemplatesEnabled &&
          customTemplateTargets.map(({ disabled, Icon, label, url }, index) => (
            <Grid key={index} item sm={12} md={6} lg={4}>
              <NotificationTargetIntegrationDisplay
                action={
                  <ButtonLinkSecondary
                    disabled={disabled}
                    linkProps={{ to: url }}
                  >
                    <FM defaultMessage="Edit Template" />
                  </ButtonLinkSecondary>
                }
                Icon={Icon}
                showCount={false}
                title={label}
              />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};
