import { orderBy as _orderBy } from 'lodash-es';

import {
  ArtifactSignatureResource,
  getArtifactSignatureDigest,
} from '@endorlabs/endor-core/Artifact';

import { ArtifactDetailTableRow } from './ArtifactDetailTable';

export const mapToArtifactDetailTableRow = (
  artifactSignatures: ArtifactSignatureResource[]
): ArtifactDetailTableRow[] => {
  const rows = artifactSignatures.map((a) => {
    const { meta, spec, uuid } = a;

    const digestShort = getArtifactSignatureDigest(a, { short: true });

    return {
      uuid: uuid,
      artifactDigest: digestShort,
      createTime: meta.create_time,
      updateTime: meta.update_time,
      reference: spec.provenance?.source_repository_ref ?? '',
    };
  });

  return _orderBy(rows, ['createTime'], ['desc']);
};
