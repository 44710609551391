import { LoadingButton } from '@mui/lab';
import { Box, Stack, useTheme } from '@mui/material';
import { uniq as _uniq } from 'lodash-es';
import {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { FormProvider, FormState, useForm } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { PolicyPolicyType, V1Policy } from '@endorlabs/api_client';
import {
  IQueryError,
  PolicyResource,
  PolicyTemplateResource,
  useCreatePolicy,
  useUpdatePolicy,
} from '@endorlabs/queries';
import { ButtonLinkSecondary } from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { getPoliciesPath } from '../../../routes';
import { useValidatePolicy } from '../hooks';
import { FormUpsertPolicy as formMessages } from '../locales';
import {
  FormUpsertPolicyFieldValues,
  PolicyUmbrellaType,
  PolicyUmbrellaTypes,
} from '../types';
import { getDefaultPolicy, policyFieldValuesToModel } from '../utils';
import { AdmissionPolicySteps } from './AdmissionPolicySteps';
import { ExceptionPolicySteps } from './ExceptionPolicySteps';
import { FindingPolicySteps } from './FindingPolicySteps';
import { PolicyAdvancedFields } from './PolicyAdvancedFields';
import { RemediationPolicySteps } from './RemediationPolicySteps';

interface FormUpsertPolicyProps {
  activeTemplate?: PolicyTemplateResource;
  onError: (err: IQueryError) => void;
  onSuccess: (policyData: V1Policy) => void;
  policy?: PolicyResource;
  policyTemplates: PolicyTemplateResource[];
  policyUmbrellaType: PolicyUmbrellaType;
}

export interface FormUpsertPolicyRef {
  getValues: () => FormUpsertPolicyFieldValues;
  getState: () => FormState<FormUpsertPolicyFieldValues>;
}

const FormUpsertPolicyBase = (
  {
    activeTemplate,
    onError,
    onSuccess,
    policy,
    policyTemplates,
    policyUmbrellaType,
  }: FormUpsertPolicyProps,
  ref: Ref<FormUpsertPolicyRef>
) => {
  const { space } = useTheme();
  const { activeNamespace: tenantName } = useAuthInfo();

  const messages = useMemo(
    () => formMessages[policyUmbrellaType],
    [policyUmbrellaType]
  );

  // Set default values for form
  const defaultValues = useMemo(() => {
    if (policy?.meta.description === null) {
      policy.meta.description = '';
    }

    return (
      (policy as FormUpsertPolicyFieldValues) ??
      getDefaultPolicy(policyUmbrellaType)
    );
  }, [policy, policyUmbrellaType]);

  const formMethods = useForm<FormUpsertPolicyFieldValues>({
    mode: 'onTouched',
    defaultValues,
  });

  const {
    clearErrors,
    formState,
    getValues,
    handleSubmit: hookFormSubmit,
    reset,
    setError,
    setValue,
  } = formMethods;

  // Reset form state if existing policy comes in after initial render
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // Trigger change to template uuid if an activeTemplate comes in
  useEffect(() => {
    if (activeTemplate) {
      setValue('spec.template_uuid', activeTemplate.uuid);
    }
  }, [activeTemplate, setValue]);

  /**
   * Transform form data as needed and submit
   */
  const qCreatePolicy = useCreatePolicy({ onError, onSuccess });
  const qUpdatePolicy = useUpdatePolicy({ onError, onSuccess });
  const { validate: validatePolicy, isLoading: isValidating } =
    useValidatePolicy({ namespace: tenantName });

  const handleValidate = useCallback(
    async (model?: PolicyResource) => {
      // init model if not provided
      if (!model) {
        const fieldValues = getValues();
        model = policyFieldValuesToModel(fieldValues);
      }

      const result = await validatePolicy(model, { skipFullValidation: true });

      if (!result.ok) {
        const { field, message, type = 'validate' } = result.error;
        setError(field, { type, message });
        return;
      }

      // On successful validation, merge the suggested resource kinds with any existing values
      const { resource_kinds: suggestedResourceKinds = [] } = result.value;

      const existingResourceKinds = model.spec.resource_kinds ?? [];
      const mergedResourceKinds = _uniq([
        ...existingResourceKinds,
        ...suggestedResourceKinds,
      ]);
      setValue('spec.resource_kinds', mergedResourceKinds);

      // return the validated model
      return {
        ...model,
        spec: { ...model.spec, resource_kinds: mergedResourceKinds },
      };
    },
    [getValues, setError, setValue, validatePolicy]
  );

  const createOrUpdatePolicy = useCallback(
    async (fieldValues: FormUpsertPolicyFieldValues) => {
      const model = policyFieldValuesToModel(fieldValues);

      // Validate policy before saving
      const validatedModel = await handleValidate(model);
      if (!validatedModel) return;

      // Lock down update fields for system policies
      const mask = [
        PolicyPolicyType.MlFinding,
        PolicyPolicyType.SystemFinding,
      ].includes(model.spec.policy_type)
        ? 'meta.tags,spec.disable,spec.project_selector,spec.project_exceptions,spec.finding.meta_tags'
        : undefined;

      if (policy && policy.uuid) {
        qUpdatePolicy.mutate({
          mask,
          namespace: validatedModel.tenant_meta.namespace,
          resource: validatedModel,
        });
      } else {
        qCreatePolicy.mutate({
          namespace: tenantName,
          resource: validatedModel,
        });
      }
    },
    [handleValidate, policy, qCreatePolicy, qUpdatePolicy, tenantName]
  );

  const onBeforeSubmit = () => {
    // Unset server errors for a rego rule, if present
    if (
      formState.errors.spec?.rule &&
      formState.errors.spec.rule.type !== 'validate'
    ) {
      clearErrors('spec.rule');
    }
  };

  useImperativeHandle(ref, () => ({
    getValues,
    getState: () => formState,
  }));

  return (
    <Stack
      spacing={space.md}
      width="50%"
      className="policy-container FormUpsertPolicy-root"
    >
      <FormProvider {...formMethods}>
        <form
          id="FormUpsertPolicy"
          onSubmit={hookFormSubmit(createOrUpdatePolicy)}
        >
          {policyUmbrellaType === PolicyUmbrellaTypes.ACTION && (
            <AdmissionPolicySteps
              activeTemplate={activeTemplate}
              policy={policy}
              policyTemplates={policyTemplates}
            />
          )}

          {policyUmbrellaType === PolicyUmbrellaTypes.EXCEPTION && (
            <ExceptionPolicySteps policy={policy} />
          )}

          {policyUmbrellaType === PolicyUmbrellaTypes.FINDING && (
            <FindingPolicySteps
              activeTemplate={activeTemplate}
              policy={policy}
              policyTemplates={policyTemplates}
            />
          )}

          {policyUmbrellaType === PolicyUmbrellaTypes.REMEDIATION && (
            <RemediationPolicySteps
              activeTemplate={activeTemplate}
              policy={policy}
              policyTemplates={policyTemplates}
            />
          )}

          <PolicyAdvancedFields />

          {/* SUBMIT */}
          <Box alignItems="flex-start" marginTop={space.md}>
            <Stack alignItems="center" direction="row" spacing={space.sm}>
              <ButtonLinkSecondary
                disabled={formState.isSubmitting}
                linkProps={{
                  to: getPoliciesPath({
                    tenantName,
                    section: policyUmbrellaType,
                  }),
                }}
              >
                <FM defaultMessage="Cancel" />
              </ButtonLinkSecondary>

              <LoadingButton
                loading={formState.isSubmitting}
                onClick={onBeforeSubmit}
                type="submit"
                variant="contained"
              >
                {policy && policy.uuid ? (
                  <FM {...messages.submitUpdate} />
                ) : (
                  <FM {...messages.submitCreate} />
                )}
              </LoadingButton>
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </Stack>
  );
};

export const FormUpsertPolicy = forwardRef<
  FormUpsertPolicyRef,
  FormUpsertPolicyProps
>(FormUpsertPolicyBase);
