import {
  Alert,
  AlertTitle,
  IconButton,
  Snackbar,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { TenantType } from '@endorlabs/endor-core/auth';
import { useSession } from '@endorlabs/queries';
import { IconCornerUpLeft, IconX } from '@endorlabs/ui-common';

import { getOnboardRootPath } from '../../../routes';

interface ReturnAlertProps {
  isOpen: boolean;
  closeCallback: () => void;
}

export const ReturnToOnboarding = ({
  isOpen,
  closeCallback,
}: ReturnAlertProps) => {
  const { getUserTenants } = useSession();
  const theme = useTheme();

  //Find the tenant name to return from sandbox
  const onboardingTenantName = useMemo(() => {
    const tenantName = getUserTenants().find(
      (t) => t.tenantType === TenantType.Trial
    )?.name;
    if (!tenantName)
      getUserTenants().find((t) => t.tenantType === TenantType.Premium)?.name;
    return tenantName ?? getUserTenants()[0]?.name;
  }, [getUserTenants]);

  const navigate = useNavigate();

  const handleReturn = () => {
    navigate({
      to: getOnboardRootPath({
        tenantName: onboardingTenantName,
      }),
    });
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    closeCallback();
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClick={handleReturn}
      open={isOpen}
      sx={{ cursor: 'pointer' }}
    >
      <Alert
        variant="filled"
        severity="success"
        sx={{ backgroundColor: theme.palette.brand.main }}
        elevation={12}
        icon={<IconCornerUpLeft />}
        action={
          <IconButton
            onClick={handleClose}
            aria-label="close"
            sx={{
              marginLeft: '0.25rem',
              color: 'inherit',
              '&:hover': {
                color: 'inherit',
              },
            }}
          >
            <IconX />
          </IconButton>
        }
      >
        <AlertTitle>
          <FM defaultMessage="Return to Getting Started" />
        </AlertTitle>
      </Alert>
    </Snackbar>
  );
};
