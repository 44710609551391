import {
  V1Ecosystem,
  V1FindingCategory,
  V1FindingTags,
} from '@endorlabs/api_client';
import { FindingSource } from '@endorlabs/endor-core/Finding';
import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';

/**
 * Helper to build the Filter Expressions for Findings with a given Finding Source.
 */
export const getFindingFilterExpression = (
  findingSource?: FindingSource
): FilterExpression | undefined => {
  switch (findingSource) {
    case FindingSource.Container:
      return filterExpressionBuilders.and([
        `spec.finding_categories contains ["${V1FindingCategory.Container}"]`,
      ]);
    case FindingSource.Dependency:
      return filterExpressionBuilders.and([
        `meta.parent_kind==PackageVersion`,
        `spec.ecosystem!=${V1Ecosystem.GithubAction}`,
        `spec.finding_tags not contains [${V1FindingTags.Self}]`,
      ]);
    case FindingSource.GithubAction:
      return filterExpressionBuilders.and([
        `meta.parent_kind==PackageVersion`,
        `spec.ecosystem==${V1Ecosystem.GithubAction}`,
      ]);
    case FindingSource.Package:
      return filterExpressionBuilders.and([
        `meta.parent_kind==PackageVersion`,
        `spec.ecosystem!=${V1Ecosystem.GithubAction}`,
        `spec.finding_tags contains [${V1FindingTags.Self}]`,
      ]);
    case FindingSource.Repository:
      return filterExpressionBuilders.and([
        `meta.parent_kind in [Repository,RepositoryVersion]`,
        // NOTE: excluding "Secret" findings from other RepositoryVersion findings
        `spec.finding_categories not contains [${V1FindingCategory.Secrets}]`,
      ]);
    case FindingSource.Secrets:
      return filterExpressionBuilders.and([
        `spec.finding_categories contains [${V1FindingCategory.Secrets}]`,
      ]);
  }
};
