import { FormattedMessage as FM } from 'react-intl';

import { V1FindingCategory } from '@endorlabs/api_client';
import {
  Filter,
  FILTER_COMPARATORS,
  filterExpressionBuilders as FB,
  serialize,
} from '@endorlabs/filters';

import { PageNavigationItem } from '../../../components/PageNavigation/PageNavigationGroup';
import { getInitialFilterValues } from '../../filters';
import { FINDING_FILTER_FIELDS } from './filters';

const INITIAL_FILTER_VALUES = getInitialFilterValues(FINDING_FILTER_FIELDS);
const COMMON_FILTER_VALUES = ['findingExceptions', 'meta.create_time'];

const buildCategoryFilter = (cat: V1FindingCategory) => {
  const filterValues = new Map<string, Filter>([
    [
      'spec.finding_categories',
      {
        key: 'spec.finding_categories',
        comparator: FILTER_COMPARATORS.CONTAINS,
        value: [cat],
      },
    ],
  ]);

  // serialize base filter expression
  const value = serialize(Array.from(filterValues.values()));

  // copy common filter values for the quick filters
  for (const id of COMMON_FILTER_VALUES) {
    const filterValue = INITIAL_FILTER_VALUES.get(id);
    if (filterValue) {
      filterValues.set(id, filterValue);
    }
  }

  return { filterValues, value };
};

export type FindingsPageNavigationItem = Omit<
  PageNavigationItem,
  'handleSelection'
> & { filterValues?: Map<string, Filter> };

export const FindingsPageNavigationItems: FindingsPageNavigationItem[] = [
  {
    label: <FM defaultMessage="Prioritized Findings" />,
    navKey: 'prioritizedFindings',
    suffix: undefined,
    value: serialize(Array.from(INITIAL_FILTER_VALUES.values())),
    filterValues: INITIAL_FILTER_VALUES,
  },
  {
    label: <FM defaultMessage="Code Dependencies" />,
    navKey: 'codeDependencies',
    groupItems: [
      {
        label: <FM defaultMessage="Vulnerabilities" />,
        navKey: 'vulnerabilities',
        ...buildCategoryFilter(V1FindingCategory.Vulnerability),
      },
      {
        label: <FM defaultMessage="Operational" />,
        navKey: 'operational',
        ...buildCategoryFilter(V1FindingCategory.Operational),
      },
      {
        label: <FM defaultMessage="License Risk" />,
        navKey: 'licenseRisk',
        ...buildCategoryFilter(V1FindingCategory.LicenseRisk),
      },
      {
        label: <FM defaultMessage="Malware" />,
        navKey: 'malware',
        ...buildCategoryFilter(V1FindingCategory.Malware),
      },
    ],
  },

  {
    label: <FM defaultMessage="First Party Code" />,
    navKey: 'firstPartyCode',
    groupItems: [
      {
        label: <FM defaultMessage="Secrets" />,
        navKey: 'secrets',
        ...buildCategoryFilter(V1FindingCategory.Secrets),
      },
    ],
  },

  {
    label: <FM defaultMessage="CI/CD" />,
    navKey: 'ciCd',
    groupItems: [
      {
        label: <FM defaultMessage="GitHub Actions" />,
        navKey: 'ghActions',
        ...buildCategoryFilter(V1FindingCategory.Ghactions),
      },
      {
        label: <FM defaultMessage="CI/CD Tools" />,
        navKey: 'cicd',
        ...buildCategoryFilter(V1FindingCategory.Cicd),
      },
      {
        label: <FM defaultMessage="RSPM" />,
        navKey: 'rspm',
        ...buildCategoryFilter(V1FindingCategory.Scpm),
      },
    ],
  },

  {
    label: <FM defaultMessage="Containers" />,
    navKey: 'containers',
    ...buildCategoryFilter(V1FindingCategory.Container),
  },

  // {
  //   label: <FM defaultMessage="Packages" />,
  //   navKey: 'packages',
  //   value: FB.and([
  //     `meta.parent_kind=="PackageVersion"`,
  //     `spec.finding_tags contains ["${V1FindingTags.Self}"]`,
  //     `spec.ecosystem not in ["${V1Ecosystem.Container}"]`,
  //     FB.mainResourceContext(),
  //   ]),
  // },
];
