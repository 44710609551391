import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';
import {
  FindingResource,
  isSecurityFinding,
  isSelfFinding,
  isVulnerabilityFinding,
} from '@endorlabs/endor-core/Finding';
import { PackageVersionResource, useFeatureFlags } from '@endorlabs/queries';
import {
  ButtonLinkSecondary,
  DependencyPathDisplay,
  FindingNameDisplay,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { DetailDrawerContainer } from '../../../../components/DetailDrawer/DetailDrawerContainer';
import { DetailDrawerSection } from '../../../../components/DetailDrawer/DetailDrawerSection';
import { DetailDrawerSectionStack } from '../../../../components/DetailDrawer/DetailDrawerSectionStack';
import { DetailDrawerTabs } from '../../../../components/DetailDrawer/DetailDrawerTabs';
import { FindingCallPathsDrawerContent } from '../../../../components/FindingCallPathsDrawer';
import { getFindingsPath } from '../../../../routes';
import { useFindingDetailData } from '../../hooks/useFindingDetailData';
import { FindingExceptionPoliciesSection } from '../FindingExceptionPoliciesSection';
import { FindingFixInfoSection } from '../FindingFixInfoSection';
import { FindingMetadataSection } from '../FindingMetadataSection';
import { FindingPrimaryAttributesSection } from '../FindingPrimaryAttributesSection';
import { FindingRemediationsSection } from '../FindingRemediationsSection';
import { FindingRiskDetailsSection } from '../FindingRiskDetailsSection';
import { FindingRiskStatsSection } from '../FindingRiskStatsSection';

export interface FindingDetailDrawerV2Props {
  finding?: FindingResource;
  findingUuid: string;
  namespace: string;
}

export const FindingDetailDrawerV2 = ({
  findingUuid,
  namespace,
}: FindingDetailDrawerV2Props) => {
  const { ENABLE_MAGIC_PATCHING: isMagicPatchingEnabled } = useFeatureFlags();

  const {
    exceptionPolicies,
    finding,
    isLoading,
    magicPatchVersionUpgrade,
    packageVersion,
    project,
    versionUpgrade,
  } = useFindingDetailData({
    findingUuid,
    namespace,
  });

  // FIXME: Util
  const getTargetDependencyEcosystem = (
    finding: FindingResource,
    sourcePackageVersion: PackageVersionResource
  ) => {
    const ecosystem =
      finding.spec.ecosystem === V1Ecosystem.Unspecified
        ? sourcePackageVersion?.spec?.ecosystem ?? V1Ecosystem.Unspecified
        : finding.spec.ecosystem ?? V1Ecosystem.Unspecified;
    return ecosystem as V1Ecosystem;
  };

  const isSecurity = finding ? isSecurityFinding(finding) : false;
  const isVuln = finding ? isVulnerabilityFinding(finding) : false;
  const hasRemediations =
    isMagicPatchingEnabled && (!!versionUpgrade || !!magicPatchVersionUpgrade);

  const drawerTabRecords = useMemo(() => {
    let tabs = [
      {
        label: <FM defaultMessage="Info" />,
        value: 'info',
      },
      { label: <FM defaultMessage="Fix Info" />, value: 'fix' },
      { label: <FM defaultMessage="Dependency Path" />, value: 'depPath' },
      { label: <FM defaultMessage="Call Paths" />, value: 'callPath' },
    ];

    if (!isSecurity) {
      tabs = tabs.filter((tab) => ['info', 'depPath'].includes(tab.value));
    }

    return tabs;
  }, [isSecurity]);

  const infoContent = (
    <DetailDrawerSectionStack>
      {hasRemediations && (
        <FindingRemediationsSection
          findingUuid={finding?.uuid}
          namespace={namespace}
        />
      )}

      {isVuln && (
        <FindingRiskStatsSection finding={finding} namespace={namespace} />
      )}

      <FindingPrimaryAttributesSection
        finding={finding}
        isLoading={isLoading}
        namespace={namespace}
        packageVersion={packageVersion}
        project={project}
      />

      {exceptionPolicies.length > 0 && (
        <FindingExceptionPoliciesSection
          exceptionPolicies={exceptionPolicies}
        />
      )}

      <FindingRiskDetailsSection
        finding={finding}
        isLoading={isLoading}
        namespace={namespace}
      />

      {isSecurity && (
        <FindingMetadataSection
          finding={finding}
          isLoading={isLoading}
          namespace={namespace}
        />
      )}
    </DetailDrawerSectionStack>
  );

  const depPathContent = (
    <DetailDrawerSection>
      <DependencyPathDisplay
        dependencyGraph={
          (packageVersion?.spec?.resolved_dependencies
            ?.dependency_graph as unknown as Record<string, string[]>) ??
          ({} as Record<string, string[]>)
        }
        displayAsAccordion={false}
        isLoading={isLoading}
        project={project}
        sourcePackageNames={[packageVersion?.meta?.name ?? '']}
        targetPackageName={UIPackageVersionUtils.deriveFullPackageName({
          ecosystem:
            finding && packageVersion
              ? getTargetDependencyEcosystem(finding, packageVersion)
              : V1Ecosystem.Unspecified,
          name: finding?.spec.target_dependency_name ?? '',
          version: finding?.spec.target_dependency_version ?? '',
        })}
      />
    </DetailDrawerSection>
  );

  const fixInfo = (
    <FindingFixInfoSection
      finding={finding}
      isLoading={isLoading}
      namespace={namespace}
    />
  );

  const callPath = (
    <DetailDrawerSection>
      <FindingCallPathsDrawerContent
        finding={finding}
        findingPackageVersion={packageVersion}
        findingProject={project}
        isLoading={false}
      />
    </DetailDrawerSection>
  );

  return (
    <DetailDrawerContainer
      headerProps={{
        action: finding && (
          <ButtonLinkSecondary
            linkProps={{
              to: getFindingsPath({
                tenantName: namespace,
                uuid: finding?.uuid,
              }),
            }}
          >
            <FM defaultMessage="View Details" />
          </ButtonLinkSecondary>
        ),
        title: <FindingNameDisplay finding={finding} size="large" />,
      }}
    >
      <DetailDrawerTabs
        id="FindingDetailDrawer"
        tabRecords={drawerTabRecords}
        tabPanelMap={{
          callPath,
          depPath:
            finding && packageVersion && !isSelfFinding(finding)
              ? depPathContent
              : undefined,
          fix: fixInfo,
          info: infoContent,
        }}
      />
    </DetailDrawerContainer>
  );
};
