import { isArray as _isArray, isString as _isString } from 'lodash-es';
import { ErrorOption } from 'react-hook-form';

import {
  JIRAConfigJiraIssueType,
  NotificationTargetActionActionType,
  V1JIRAConfig,
  V1NotificationTarget,
  V1SlackConfig,
  V1VantaConfig,
  V1WebhookConfig,
  VantaConfigVantaAuthMethod,
  WebhookConfigWebhookAuthMethod,
} from '@endorlabs/api_client';
import {
  IQueryErrorResponse,
  NotificationTargetResource,
} from '@endorlabs/queries';

import {
  FormNotificationTargetDefaultValues,
  FormUpsertNotificationTargetFieldValues,
} from './types';

export const getNotificationTargetDefaultValues = (
  namespace: string,
  actionType: NotificationTargetActionActionType
): FormNotificationTargetDefaultValues => {
  const defaultValues: FormNotificationTargetDefaultValues = {
    meta: {
      name: '',
      description: '',
    },
    spec: {
      action: {
        action_type: actionType,
      },
    },
    tenant_meta: { namespace },
  };

  switch (actionType) {
    case NotificationTargetActionActionType.Email:
      defaultValues.spec.action.email_config = {
        receivers_addresses: [],
      };
      break;
    case NotificationTargetActionActionType.Jira:
      defaultValues.spec.action.jira_config = {
        url: '',
        api_key: '',
        user_name: '',
        project_key: '',
        issue_type: JIRAConfigJiraIssueType.Task,
        labels: [],
      };
      break;
    case NotificationTargetActionActionType.Slack:
      defaultValues.spec.action.slack_config = {
        webhook_url: '',
      };
      break;
    case NotificationTargetActionActionType.Vanta:
      defaultValues.spec.action.vanta_config = {
        auth_method: VantaConfigVantaAuthMethod.AccessToken,
        vulnerable_component_resource_id: '',
        package_vulnerability_resource_id: '',
        sca_resource_id: '',
      };
      break;
    case NotificationTargetActionActionType.Webhook:
      defaultValues.spec.action.webhook_config = {
        url: '',
        api_key: '',
        auth_method: WebhookConfigWebhookAuthMethod.None,
        basic_auth: {},
        disable_hmac: false,
        hmac_shared_secret: '',
      };
      break;
  }

  return defaultValues;
};

interface ServerFieldError {
  field?: string;
  object: ErrorOption;
}

export const translateServerError = (
  errorResponse: IQueryErrorResponse
): ServerFieldError => {
  const statusCode = errorResponse.status;

  if (statusCode === 403) {
    return {
      object: {
        type: 'server',
        message:
          'Failed to create or update Notification Integration. Not authorized to make updates.',
      },
    };
  }

  if (
    statusCode === 412 ||
    (statusCode === 500 &&
      errorResponse.data?.message.includes('validating notification target'))
  ) {
    return {
      object: {
        type: 'server',
        message:
          'Failed to validate the Notification Integration. Please check the configuration values and try again.',
      },
    };
  }

  if (
    statusCode === 400 &&
    errorResponse.data?.message.includes(
      'at least one fieldmask should be given'
    )
  ) {
    return {
      object: {
        type: 'server',
        message:
          'Failed to update the Notification Integration. No changes detected.',
      },
    };
  }

  return {
    object: {
      type: 'server',
      message: 'An unknown error occurred',
    },
  };
};

export const fromNotificationTargetResourceModel = (
  notificationTarget: V1NotificationTarget
): FormUpsertNotificationTargetFieldValues => {
  const { uuid, tenant_meta, meta, spec } = notificationTarget;

  let cleanSpec = spec;
  if (
    spec.action.email_config &&
    notificationTarget.spec.action.email_config?.receivers_addresses
  ) {
    const addresses =
      notificationTarget.spec.action.email_config?.receivers_addresses ?? '';
    if (_isString(addresses)) {
      notificationTarget.spec.action.email_config.receivers_addresses =
        addresses;
    } else if (_isArray(addresses)) {
      // @ts-expect-error - want a string here
      notificationTarget.spec.action.email_config.receivers_addresses =
        addresses.join(', ');
    }
  }

  if (spec.action.vanta_config) {
    const vantaConfig = { ...spec.action.vanta_config };
    // unset any `null` values to allow the default values in the form
    for (const key of Object.keys(vantaConfig) as (keyof V1VantaConfig)[]) {
      if (vantaConfig[key] === null) {
        delete vantaConfig[key];
      }
    }

    cleanSpec = {
      ...spec,
      action: { ...spec.action, vanta_config: vantaConfig },
    };
  }

  return {
    uuid,
    tenant_meta,
    meta,
    spec: cleanSpec,
  };
};

/**
 * Maps Field Values from the form to a valid model
 *
 * @throws Error if action config is invalid
 */
export const toNotificationTargetResourceModel = (
  namespace: string,
  notificationTargetActionType: NotificationTargetActionActionType,
  fieldValues: FormUpsertNotificationTargetFieldValues,
  notificationTarget?: NotificationTargetResource
): V1NotificationTarget => {
  const { meta, spec } = fieldValues;

  const modelMeta = {
    ...notificationTarget?.meta,
    name: meta.name,
    description: meta.description,
    tags: meta.tags,
  } as NotificationTargetResource['meta'];

  const modelSpec = {
    action: {
      action_type: notificationTargetActionType,
      ...notificationTarget?.spec.action,
    },
  } as NotificationTargetResource['spec'];

  // handle integration configs
  const actionType = modelSpec.action.action_type;
  if (actionType === NotificationTargetActionActionType.Email) {
    modelSpec.action.email_config = {
      receivers_addresses: (
        spec.action.email_config?.receivers_addresses as unknown as string
      )
        .split(',')
        .map((s) => s.trim())
        .filter(Boolean),
    };
  } else if (actionType === NotificationTargetActionActionType.Jira) {
    const jiraConfig = fieldValues.spec.action.jira_config as V1JIRAConfig;
    modelSpec.action.jira_config = {
      ...jiraConfig,
      labels: jiraConfig.labels ?? [],
    };
  } else if (actionType === NotificationTargetActionActionType.Vanta) {
    const vantaConfig = { ...fieldValues.spec.action.vanta_config };

    // Ensure that empty strings are unset before sending to the API
    // This is required to avoid triggering the min-length validation from the protobuf schema
    const stringKeys = [
      'package_vulnerability_resource_id',
      'sca_resource_id',
      'vulnerable_component_resource_id',
    ] satisfies (keyof V1VantaConfig)[];
    for (const key of stringKeys) {
      const value = vantaConfig[key];

      if ('string' === typeof value && value.trim() === '') {
        delete vantaConfig[key as keyof typeof vantaConfig];
      }
    }

    // Handle vanta auth method - protobuf expects exactly one of `access_token_auth` or `api_key`
    if (vantaConfig.auth_method === VantaConfigVantaAuthMethod.AccessApiKey) {
      delete vantaConfig.access_token_auth;
    } else if (
      vantaConfig.auth_method === VantaConfigVantaAuthMethod.AccessToken
    ) {
      delete vantaConfig.api_key;
    }

    modelSpec.action.vanta_config = vantaConfig as V1VantaConfig;
  } else if (actionType === NotificationTargetActionActionType.Webhook) {
    const webhookConfig = fieldValues.spec.action
      .webhook_config as V1WebhookConfig;
    if (webhookConfig.auth_method != WebhookConfigWebhookAuthMethod.Basic) {
      delete webhookConfig?.basic_auth;
    }
    if (webhookConfig.auth_method != WebhookConfigWebhookAuthMethod.ApiKey) {
      delete webhookConfig?.api_key;
    }
    if (webhookConfig.hmac_shared_secret) {
      webhookConfig.disable_hmac = false;
    } else {
      //Handles disable_hmac == undfined in intial render
      webhookConfig.disable_hmac = true;
      delete webhookConfig.hmac_shared_secret;
    }
    modelSpec.action.webhook_config = webhookConfig;
  } else if (actionType === NotificationTargetActionActionType.Slack) {
    const slackConfig = {
      ...fieldValues.spec.action.slack_config,
    } as V1SlackConfig;
    modelSpec.action.slack_config = slackConfig;
  }

  return {
    uuid: notificationTarget?.uuid,
    tenant_meta: { namespace },
    meta: modelMeta,
    spec: modelSpec,
  };
};
