import { Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { CVSSV3SeverityLevel } from '@endorlabs/api_client';
import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import {
  FindingCountArrayDisplay,
  FindingCountArrayDisplayProps,
  RowStack,
  UIFindingUtils,
} from '@endorlabs/ui-common';

interface AssuredPackageVersionFindingCountArrayDisplayProps
  extends Omit<FindingCountArrayDisplayProps, 'value'> {
  assuredPackageVersion: AssuredPackageVersionResource;
  showTotal?: boolean;
}

/**
 * Generate a finding count display based upon deeply-nested assured package version properties
 */
export const AssuredPackageVersionFindingCountArrayDisplay = ({
  assuredPackageVersion,
  showTotal = true,
  ...props
}: AssuredPackageVersionFindingCountArrayDisplayProps) => {
  const { space } = useTheme();

  const { vulnCounts, vulnsTotal } = useMemo(() => {
    const fixedVulnsByLevel =
      assuredPackageVersion?.spec.upgrade_summary?.fixed_vulns ?? {};

    let vulnsTotal = 0;
    const vulnCounts = FINDING_LEVELS.map((level) => ({
      level,
      value: 0,
    }));

    Object.entries(fixedVulnsByLevel).forEach(([level, vulnsObj]) => {
      const { vulns } = vulnsObj;

      if (vulns) {
        const existingCount = vulnCounts.find((c) => {
          return UIFindingUtils.isFindingLevelMatch(
            c.level,
            level as CVSSV3SeverityLevel
          );
        });

        if (existingCount) {
          existingCount.value = vulns.length;
          vulnsTotal += vulns.length;
        }
      }
    });

    return { vulnCounts, vulnsTotal };
  }, [assuredPackageVersion?.spec.upgrade_summary?.fixed_vulns]);

  return showTotal ? (
    <RowStack gap={space.xs}>
      <Typography color="text.secondary" component="span" variant="caption">
        <FM
          defaultMessage="{count, number, ::compact-short} Fixed {count, plural, =0 {Vulnerabilities} one {Vulnerability} other {Vulnerabilities}}"
          values={{ count: vulnsTotal }}
        />
      </Typography>
      <FindingCountArrayDisplay {...props} value={vulnCounts} />
    </RowStack>
  ) : (
    <FindingCountArrayDisplay {...props} value={vulnCounts} />
  );
};
