import { Card, CardContent, CardHeader, Stack, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { matchSorter } from 'match-sorter';
import { useCallback, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  PolicyPolicyType,
  SpecFindingLevel,
  V1Policy,
} from '@endorlabs/api_client';
import { applyFilters } from '@endorlabs/filters';
import {
  PolicyResource,
  useDeletePolicy,
  useUpdatePolicy,
} from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  ExclusiveToggleButtonGroup,
  useConfirmationDialog,
  useExclusiveToggleButtonGroup,
} from '@endorlabs/ui-common';

import { FacetedSearchBar, useFacetedSearchBar } from '../../../components';
import { useAuthInfo } from '../../../providers';
import { getPoliciesPath } from '../../../routes';
import { FindingPolicyFacets } from '../constants/filters';
import { usePoliciesAndRelatedFindings } from '../hooks';
import { usePolicyScanResults } from '../hooks/usePoliciesAndScanResults';
import { FindingPoliciesView as messages } from '../locales';
import {
  AllowedTemplateTypesByPolicyUmbrellaType,
  PolicyUmbrellaTypes,
} from '../types';
import {
  FindingPoliciesTable,
  FindingPoliciesTableRow,
} from './FindingPoliciesTable';
import { PolicyTemplatesView } from './PolicyTemplatesView';

export const FindingPoliciesView = () => {
  const { space } = useTheme();
  const { activeNamespace: tenantName } = useAuthInfo();
  const navigate = useNavigate();

  type TableViews = 'policies' | 'templates';
  const { getToggleButtonGroupProps, value: selectedTable } =
    useExclusiveToggleButtonGroup<TableViews>('policies', [
      { value: 'policies', children: <FM defaultMessage="Policies" /> },
      { value: 'templates', children: <FM defaultMessage="Templates" /> },
    ]);

  const { getFacetedSearchBarProps, filters, searchValue } =
    useFacetedSearchBar({
      resourceKind: 'Policy',
    });

  const { policies, totalPolicyCount } = usePoliciesAndRelatedFindings({
    namespace: tenantName,
    policyTypes: [
      PolicyPolicyType.MlFinding,
      PolicyPolicyType.SystemFinding,
      PolicyPolicyType.UserFinding,
    ],
  });

  const { getLastScanTimeForPolicy, getTriggerCountForPolicy } =
    usePolicyScanResults({ namespace: tenantName, policies });

  const filteredPolicies = applyFilters(filters, policies);

  const searchedPolicies = useMemo(() => {
    if (!searchValue) return filteredPolicies;
    const filterKeys = ['meta.name', 'meta.description', 'meta.tags'];

    return matchSorter(filteredPolicies, searchValue, {
      // use the default sort order as tie breaker
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
      keys: filterKeys,
      threshold: matchSorter.rankings.CONTAINS,
    });
  }, [filteredPolicies, searchValue]);

  // NOTE: navigating to the policy under the policy namespace
  const onPolicyEdit = useCallback(
    ({ namespace, uuid }: { namespace: string; uuid: string }) =>
      navigate({
        to: getPoliciesPath({
          tenantName: namespace,
          section: `findings/edit/${uuid}`,
        }),
      }),
    [navigate]
  );

  const qPolicyEdit = useUpdatePolicy();

  const onPolicyDisable = useCallback(
    ({ uuid }: { namespace: string; uuid: string }) => {
      const policy = policies.find((policy) => policy.uuid === uuid);

      if (!policy || !policy.tenant_meta.namespace) return;

      const updatedPolicy = {
        spec: { disable: !policy.spec.disable },
        uuid: policy.uuid,
      };

      qPolicyEdit.mutate({
        mask: 'spec.disable',
        namespace: policy.tenant_meta.namespace,
        resource: updatedPolicy as V1Policy,
      });
    },
    [policies, qPolicyEdit]
  );

  const qPolicyDelete = useDeletePolicy();

  const PolicyDeleteConfirmation = useConfirmationDialog<{
    namespace: string;
    uuid: string;
  }>({
    confirmText: <FM defaultMessage="Delete" />,
    isDestructive: true,
    onConfirm: (props) => {
      if (!props) return;
      const { namespace, uuid } = props;
      qPolicyDelete.mutate({ namespace, uuid });
    },
    titleText: <FM defaultMessage="Delete this Policy?" />,
    descriptionText: (
      <FM defaultMessage="Findings will no longer be generated from this policy." />
    ),
  });

  const buildPolicyTableRows = (
    policies: PolicyResource[]
  ): FindingPoliciesTableRow[] => {
    return policies.map((policy) => {
      return {
        description: policy.meta.description,
        disable: policy.spec.disable,
        findingLevel: policy.spec.finding?.level ?? policy.spec.finding_level,
        labels: policy.meta.tags,
        lastTriggered: getLastScanTimeForPolicy(policy.uuid),
        name: policy.meta.name,
        namespace: policy.tenant_meta.namespace,
        policyType: policy.spec.policy_type,
        severity: policy.spec.finding?.level ?? SpecFindingLevel.Unspecified,
        triggerCount: getTriggerCountForPolicy(policy.uuid),
        uuid: policy.uuid,
      };
    });
  };

  const policyTableRows = buildPolicyTableRows(
    searchedPolicies as PolicyResource[]
  );

  const headerMessage =
    Boolean(searchValue) || filters.length > 0 ? (
      <FM
        {...messages.headerWithSearch}
        values={{
          policyCount: policyTableRows.length,
          totalPolicyCount,
        }}
      />
    ) : (
      <FM
        {...messages.headerWithoutSearch}
        values={{
          policyCount: policyTableRows.length,
          totalPolicyCount,
        }}
      />
    );

  return (
    <Stack spacing={space.md}>
      <ExclusiveToggleButtonGroup {...getToggleButtonGroupProps()} />

      {selectedTable === 'policies' && (
        <>
          {totalPolicyCount > 0 && (
            <FacetedSearchBar
              {...getFacetedSearchBarProps()}
              facets={FindingPolicyFacets}
            />
          )}

          <Card>
            <CardHeader
              action={
                <ButtonLinkPrimary
                  linkProps={{
                    to: getPoliciesPath({
                      tenantName,
                      section: `findings/new`,
                    }),
                  }}
                >
                  <FM defaultMessage="Create Finding Policy" />
                </ButtonLinkPrimary>
              }
              title={headerMessage}
            />
            <CardContent>
              <FindingPoliciesTable
                data={policyTableRows}
                emptyStateProps={{
                  title: <FM defaultMessage="No Policies Found" />,
                }}
                onEdit={onPolicyEdit}
                onDelete={PolicyDeleteConfirmation.openDialog}
                onDisable={onPolicyDisable}
              />
            </CardContent>
          </Card>

          <PolicyDeleteConfirmation.Dialog
            {...PolicyDeleteConfirmation.dialogProps}
          />
        </>
      )}

      {selectedTable === 'templates' && (
        <PolicyTemplatesView
          policyTypes={
            AllowedTemplateTypesByPolicyUmbrellaType[
              PolicyUmbrellaTypes.FINDING
            ]
          }
        />
      )}
    </Stack>
  );
};
