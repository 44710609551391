import { Chip, useTheme } from '@mui/material';

export const ToolCategoryDisplay = ({
  alignment = 'left',
  category,
  size = 'small',
}: {
  alignment?: 'left' | 'center';
  category: string;
  size?: 'small' | 'medium';
}) => {
  const theme = useTheme();

  const fontSize =
    size === 'small' ? theme.typography.body1 : theme.typography.h3;

  const categoryName = category.split('-').join(' ');

  return (
    <Chip
      size={size}
      variant="outlined"
      sx={{
        borderColor: theme.palette.info.main,
        color: theme.palette.info.main,
        fontSize: fontSize,
        textTransform: 'capitalize',
        marginX: alignment === 'center' ? 'auto' : undefined,
      }}
      label={categoryName}
    />
  );
};
