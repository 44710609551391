import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { get as _get, has as _has } from 'lodash-es';
import { isEmpty as _isEmpty } from 'lodash-es';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecEnabledFeatureType } from '@endorlabs/api_client';
import { TenantType } from '@endorlabs/endor-core/auth';
import { getEnabledFeaturesFromLicenses } from '@endorlabs/endor-core/EndorLicense';
import {
  IQueryError,
  isQueryError,
  useAuthentication,
  useCreateInstallation,
  useFeatureFlags,
  useSession,
} from '@endorlabs/queries';
import { useListEndorLicenses } from '@endorlabs/queries/licenses';
import {
  ButtonLinkPrimary,
  ButtonLinkSecondary,
  ButtonPrimary,
  ButtonSecondary,
  EmptyState,
  IconExternalLink,
} from '@endorlabs/ui-common';

import { getEnv } from '../../constants';
import { LicenseSelectComponent } from '../../domains/Licenses/components';
import { NamespaceSwitcher } from '../../domains/Namespace';
import {
  OnboardingApproaches,
  useOnboardingSteps,
} from '../../domains/Onboarding';
import { getDashboardPath, getOnboardRootPath } from '../../routes';
import { NamedRoutes } from '../../routes/constants';
import { installStep } from './constants';

// Timeout for the default redirect after successful installation
const REDIRECT_TIMEOUT = 3_000;

const { EMAIL_ENDOR_SUPPORT } = getEnv();

const ERROR_UNABLE_TO_PROCESS = (
  <FM
    defaultMessage="Unable to update GitHub installation. For assistance, please contact {supportEmail}."
    values={{ supportEmail: EMAIL_ENDOR_SUPPORT }}
  />
);

/**
 * Possible reasons for installation errors:
 * - 400: unable to process installation
 * - 401: user is not authenticated
 * - 403: user is not authorized to create an installation in this namespace
 * - 404: installation for current state was not found
 * - 424: unable to retrieve installation information from GitHub
 * - 500: unable to process installation - unable to determine installation state
 */
const KNOWN_ERROR_DESCRIPTIONS: Record<
  number,
  (error: IQueryError) => ReactNode
> = {
  400: (error) => {
    const message = error.response.data?.message ?? '';

    // Handle the more specific error message
    if (message.includes('Unable to create or update installation projects')) {
      return (
        <FM
          defaultMessage="Unable to create or update projects for GitHub installation. For assistance, please contact {supportEmail}."
          values={{ supportEmail: EMAIL_ENDOR_SUPPORT }}
        />
      );
    }

    return message;
  },
  401: () => (
    <FM defaultMessage="Not authenticated. Please login and try again" />
  ),
  403: () => (
    <FM defaultMessage="Not authorized to create GitHub application installations for this namespace." />
  ),
  404: () => (
    <FM defaultMessage="A GitHub application installation was not found for this user." />
  ),
  422: () => (
    <FM defaultMessage="The GitHub application installation already exists in another namespace. Please select another namespace and try again, or contact Endor Labs for support." />
  ),
  424: () => (
    <FM defaultMessage="Unable to retrieve GitHub application installation details." />
  ),
  500: () => ERROR_UNABLE_TO_PROCESS,
};

const getInstallationErrorMessage = (
  error: unknown
): { title: ReactNode; description: ReactNode } => {
  // Set the default error title and description
  const title = <FM defaultMessage="Failed to install GitHub application" />;
  let description: ReactNode = (
    <FM
      defaultMessage="Unable to update GitHub installation. For assistance, please contact {supportEmail}."
      values={{ supportEmail: EMAIL_ENDOR_SUPPORT }}
    />
  );

  // Override error description with more specific error, if possible
  if (isQueryError(error)) {
    const statusCode = error.response.status;

    if (_has(KNOWN_ERROR_DESCRIPTIONS, statusCode)) {
      const descriptionFn = _get(KNOWN_ERROR_DESCRIPTIONS, statusCode);
      description = descriptionFn(error);
    }
  }

  return {
    title,
    description,
  };
};

/**
 * Return page after successfully authorizing the Endor GitHub application.
 *
 * 1. Verifies the user's authentication status, and namespace to use for the installation
 * 2. Creates an Installation resource, which initiates the following actions on the backend
 *   - Finds the corresponding GitHub installation for the current user
 *   - Creates the related Projects, and enables for repo scanning
 * 3. Handles Create Installation result
 *   - On success: Redirects user to appropriate location in the Endor app
 *   - On failure: Displays error message for user
 */
export const InstallSuccessPage = () => {
  const { isAuthenticated, isLoading: isLoadingAuthentication } =
    useAuthentication();
  const navigate = useNavigate();
  const { getUserTenants, getUserNamespaces } = useSession();
  const [selectedNamespace, setSelectedNamespace] = useState<
    string | undefined
  >();
  const { space, palette } = useTheme();
  const userNamespaces = getUserNamespaces();
  const userTenants = getUserTenants();

  /** Licensing and Bundling code */
  const [stepId, setStepId] = useState<number>(0);
  const [enabledFeatures, setEnabledFeatures] = useState<
    SpecEnabledFeatureType[]
  >([]);
  const ENV = getEnv();
  const DOCS_ROOT = ENV.URL_ENDOR_DOCS;

  const qLicensesForTenants = useListEndorLicenses(
    { namespace: selectedNamespace! },
    {
      enabled: !!selectedNamespace && stepId === 1,
    }
  );

  /** Logic here is featuresListwithLicense has all the licenses user has access to.(featuresListwithLicense variable)
   *  They all need not be enabled by user for Github app installation. (enabledFeatures in useState)
   */
  const featuresListwithLicense = useMemo(() => {
    if (!qLicensesForTenants.data?.list?.objects) return [];
    const featuresList = getEnabledFeaturesFromLicenses(
      qLicensesForTenants.data.list.objects
    );
    return featuresList;
  }, [qLicensesForTenants.data]);

  const handleSelectNamespace = () => {
    setStepId(1);
    setSelectedNamespace(selectedNamespace);
  };

  //Github Action scan is done as part of CI/CD license.
  const handleSelectGithubActionScan = () => {
    setEnabledFeatures((prevFeatures) =>
      prevFeatures.includes(SpecEnabledFeatureType.GithubActionScan)
        ? prevFeatures.filter(
            (f) => f !== SpecEnabledFeatureType.GithubActionScan
          )
        : [...prevFeatures, SpecEnabledFeatureType.GithubActionScan]
    );
  };

  const handleCheckboxChange = (featureName: SpecEnabledFeatureType) => {
    setEnabledFeatures((prevFeatures) =>
      prevFeatures.includes(featureName)
        ? prevFeatures.filter((f) => f !== featureName)
        : [...prevFeatures, featureName]
    );
    if (featureName === SpecEnabledFeatureType.ToolsScan) {
      handleSelectGithubActionScan();
    }
  };

  const handleCheckboxAll = () => {
    setEnabledFeatures((prevFeatures) =>
      prevFeatures.length > 0
        ? []
        : [...featuresListwithLicense, SpecEnabledFeatureType.GithubActionScan]
    );
  };

  /* End of Licensing and bundling code */

  // Remove shared tenants or expired trial tenants from the listed options
  const filteredTenants = useMemo(() => {
    return userTenants.filter(
      (t) =>
        t.tenantType === TenantType.Premium || t.tenantType === TenantType.Trial
    );
  }, [userTenants]);

  const isEmptyState =
    !isLoadingAuthentication &&
    filteredTenants.length === 0 &&
    getUserNamespaces().length === 0;

  const { getCurrentApproach } = useOnboardingSteps();

  const defaultRedirectPath = useMemo(() => {
    if (selectedNamespace) {
      if (getCurrentApproach() === OnboardingApproaches.GITHUB_APP) {
        return getOnboardRootPath({ tenantName: selectedNamespace });
      }
      return getDashboardPath({ tenantName: selectedNamespace });
    }
    return NamedRoutes.TENANTS_INDEX;
  }, [getCurrentApproach, selectedNamespace]);

  const qCreateInstallation = useCreateInstallation({
    onError: () => {
      // error state handled below
    },
    onSuccess: () => {
      // success state handled below
    },
  });

  useEffect(() => {
    if (!qCreateInstallation.isSuccess) return;

    const timer = setTimeout(() => {
      navigate({ to: defaultRedirectPath });
    }, REDIRECT_TIMEOUT);

    return () => clearTimeout(timer);
  }, [defaultRedirectPath, navigate, qCreateInstallation.isSuccess]);

  const handleCreateInstallation = useCallback(() => {
    // NOTE: button is disabled until namespace is selected
    // this state should not be reached.
    if (!selectedNamespace) return;

    qCreateInstallation.mutate({
      namespace: selectedNamespace,
      resource: {
        tenant_meta: { namespace: selectedNamespace },
        meta: { name: 'GitHub Endor App' },
        spec: { public: false, enabled_features: enabledFeatures },
      },
    });
  }, [enabledFeatures, qCreateInstallation, selectedNamespace]);

  // Handle: Loading State
  if (isLoadingAuthentication) {
    return (
      <EmptyState
        size="large"
        title={<FM defaultMessage="Installing GitHub application" />}
      >
        <CircularProgress />
      </EmptyState>
    );
  }

  // Handle: non-authenticated state
  if (!isAuthenticated) {
    return (
      <EmptyState
        size="large"
        title={
          <FM defaultMessage="Not Authenticated. Please login and try again" />
        }
      >
        <ButtonLinkPrimary linkProps={{ to: NamedRoutes.LOGIN }}>
          <FM defaultMessage="Go to Login" />
        </ButtonLinkPrimary>
      </EmptyState>
    );
  }

  // Handle: empty state
  if (isEmptyState) {
    return (
      <EmptyState
        size="large"
        title={<FM defaultMessage="Unable to install GitHub application" />}
        description={
          <FM defaultMessage="No authorized namespaces found. Please contact Endor Labs for support." />
        }
      ></EmptyState>
    );
  }

  // Handle: installation success
  if (qCreateInstallation.isSuccess) {
    return (
      <EmptyState
        size="large"
        title={
          <FM defaultMessage="Successfully Installed GitHub application!" />
        }
        description={
          <FM defaultMessage="Automatically redirecting back to your dashboard&hellip;" />
        }
      >
        {/* redirecting back automatically */}
        <ButtonLinkPrimary
          linkProps={{
            to: defaultRedirectPath,
          }}
        >
          <FM defaultMessage="Return Home" />
        </ButtonLinkPrimary>
      </EmptyState>
    );
  }

  // Handle: installation failure
  if (qCreateInstallation.isError) {
    const { title, description } = getInstallationErrorMessage(
      qCreateInstallation.error
    );

    return (
      <EmptyState size="large" title={title} description={description}>
        <Stack direction="row" spacing={space.sm}>
          <ButtonPrimary onClick={() => qCreateInstallation.reset()}>
            <FM defaultMessage="Try Again" />
          </ButtonPrimary>

          <ButtonLinkSecondary linkProps={{ to: NamedRoutes.TENANTS_INDEX }}>
            <FM defaultMessage="Return Home" />
          </ButtonLinkSecondary>
        </Stack>
      </EmptyState>
    );
  }

  return (
    <>
      <EmptyState
        size="large"
        title={<FM defaultMessage="Installing GitHub application" />}
        description={
          <FM defaultMessage="Please select a namespace for the GitHub Application Installation." />
        }
      >
        <Stack direction="column" spacing={5} width="25%">
          <Typography variant="h1">
            <FM defaultMessage={'Installing GitHub application'} />
          </Typography>
          <Stepper activeStep={stepId} alternativeLabel>
            {installStep.map((label, index) => (
              <Step
                key={label}
                sx={{
                  padding:
                    index % 2 === 0
                      ? '0px 1.5rem 0px 0px'
                      : '0px 0px 0px 1.5rem',
                  '& svg': {
                    width: '1.5rem',
                    height: '1.5rem',
                  },
                }}
              >
                <StepLabel>
                  <Typography variant="h4">{label}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {stepId === 0 && (
            <>
              <Stack
                direction="column"
                spacing={2}
                paddingTop={4}
                paddingBottom={4}
              >
                <Typography variant={'body1'}>
                  <FM
                    defaultMessage={
                      'Please select a namespace for the GitHub Application'
                    }
                  />
                </Typography>
                <Button
                  href={`${DOCS_ROOT}/administration/namespaces/`}
                  startIcon={<IconExternalLink />}
                  target="_blank"
                  sx={{
                    '& svg': {
                      strokeWidth: 3,
                      width: '1rem',
                      height: '1rem',
                    },
                  }}
                >
                  <FM defaultMessage="Learn more about namespaces" />
                </Button>
              </Stack>
              <Paper
                sx={{
                  '.NamespaceSwitcherContent-root': {
                    border: '1px solid #E0E0E0',
                    borderRadius: '4px',
                  },
                }}
              >
                <NamespaceSwitcher
                  activeNamespace={selectedNamespace ?? filteredTenants[0].name}
                  disableEmptyState
                  hideHeaderContent
                  namespaces={userNamespaces}
                  onSelect={(_, namespace) => setSelectedNamespace(namespace)}
                  tenants={filteredTenants}
                  selectedNamespace={selectedNamespace}
                />
              </Paper>
              <Box>
                <ButtonPrimary
                  disabled={!selectedNamespace}
                  onClick={handleSelectNamespace}
                >
                  <FM defaultMessage="Next" />
                </ButtonPrimary>
              </Box>
            </>
          )}
          {stepId === 1 && (
            <>
              <Stack
                direction="column"
                spacing={2}
                paddingTop={4}
                paddingBottom={4}
              >
                <Typography variant={'body1'}>
                  <FM
                    defaultMessage={
                      'Please select the scanners you desire to enable.'
                    }
                  />
                </Typography>
                <Button
                  href={`${DOCS_ROOT}/scan-with-endorlabs/`}
                  startIcon={<IconExternalLink />}
                  target="_blank"
                  sx={{
                    '& svg': {
                      strokeWidth: 3,
                      width: '1rem',
                      height: '1rem',
                    },
                  }}
                >
                  <FM defaultMessage="Learn more about scanners" />
                </Button>
              </Stack>
              <Paper
                sx={{
                  border: `1px solid ${palette.status.pending}`,
                  borderRadius: '4px',
                }}
              >
                <LicenseSelectComponent
                  handleCheckboxAll={handleCheckboxAll}
                  handleCheckboxChange={handleCheckboxChange}
                  editedFeatures={enabledFeatures}
                  featuresListwithLicense={featuresListwithLicense}
                />
              </Paper>
              <Stack direction="row" justifyContent="center">
                <ButtonSecondary
                  sx={{ marginRight: 3 }}
                  onClick={() => setStepId(0)}
                >
                  <FM defaultMessage="Previous" />
                </ButtonSecondary>
                <LoadingButton
                  disabled={!selectedNamespace || _isEmpty(enabledFeatures)}
                  loading={qCreateInstallation.isLoading}
                  onClick={handleCreateInstallation}
                  variant="contained"
                >
                  <FM defaultMessage="Continue" />
                </LoadingButton>
              </Stack>
            </>
          )}
        </Stack>
      </EmptyState>
    </>
  );
};
