import { Stack, StackProps, useTheme } from '@mui/material';

interface RowStackProps extends Omit<StackProps, 'direction'> {}

export const RowStack = ({ ...props }: RowStackProps) => {
  const { space } = useTheme();

  return (
    <Stack
      alignItems="center"
      flexWrap="wrap"
      gap={space.xs}
      {...props}
      direction="row"
    />
  );
};
