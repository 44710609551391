import { Grid, useTheme } from '@mui/material';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import { useFeatureFlags } from '@endorlabs/queries';
import {
  EmptyState,
  FullHeightCard,
  ResponsiveCardLayout,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import {
  DashboardVulnerabilityPrioritization,
  FindingSeverityInput,
} from '../../domains/Dashboard';
import { useAuthInfo } from '../../providers';
import { CategoricalBarChartVis } from './CategoricalBarChartVis';
import { DashboardFeed } from './DashboardFeed';
import { DashboardInventory } from './DashboardInventory';

const visHeight = 330;

/**
 * Dashboard V2 (UI-771)
 * Replaces the existing dashboard page {@see DashboardIndexPage}
 */
export const DashboardIndexPageV2 = () => {
  const { activeNamespace } = useAuthInfo();
  const { space } = useTheme();

  const isDashboardFeedEnabled = useFeatureFlags(
    (f) => f.ENABLE_DASHBOARD_FEED
  );

  const [findingLevels, setFindingLevels] =
    useState<SpecFindingLevel[]>(FINDING_LEVELS);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={space.md}>
      <Grid item>
        <PageHeader title={<FM defaultMessage="Dashboard" />} />
      </Grid>

      {isDashboardFeedEnabled && (
        <Grid item>
          <DashboardFeed namespace={activeNamespace} />
        </Grid>
      )}

      <Grid item>
        <DashboardInventory namespace={activeNamespace} />
      </Grid>

      <Grid item xs={12}>
        <FindingSeverityInput
          value={findingLevels}
          onChange={(_, values) => setFindingLevels(values)}
        />
      </Grid>

      <Grid item>
        <DashboardVulnerabilityPrioritization
          findingLevels={findingLevels}
          namespace={activeNamespace}
        />
      </Grid>

      {/* Categorical Charts */}
      <Grid item>
        <ResponsiveCardLayout columnCount={3} gridProps={{ spacing: space.md }}>
          <FullHeightCard>
            <ErrorBoundary FallbackComponent={DashboardCardFallback}>
              <CategoricalBarChartVis
                chartId="TopProjects-1"
                findingLevels={findingLevels}
                height={visHeight}
                namespace={activeNamespace}
                title={<FM defaultMessage="Top Projects By" />}
              />
            </ErrorBoundary>
          </FullHeightCard>

          <FullHeightCard>
            <ErrorBoundary FallbackComponent={DashboardCardFallback}>
              <CategoricalBarChartVis
                chartId="TopPackages-1"
                findingLevels={findingLevels}
                height={visHeight}
                namespace={activeNamespace}
                title={<FM defaultMessage="Top Packages By" />}
              />
            </ErrorBoundary>
          </FullHeightCard>

          <FullHeightCard>
            <ErrorBoundary FallbackComponent={DashboardCardFallback}>
              <CategoricalBarChartVis
                chartId="TopDependencies-1"
                findingLevels={findingLevels}
                height={visHeight}
                namespace={activeNamespace}
                title={<FM defaultMessage="Top Dependencies By" />}
              />
            </ErrorBoundary>
          </FullHeightCard>
        </ResponsiveCardLayout>
      </Grid>

      <Grid item height={120}>
        {/* Just a lil extra space to strech out */}
      </Grid>
    </Grid>
  );
};

const DashboardCardFallback = () => (
  <EmptyState
    title={<FM defaultMessage="We've Encountered a Problem" />}
    description={
      <FM defaultMessage="Unable to display the content for this section." />
    }
  />
);
