import { get as _get, set as _set } from 'lodash-es';
import { Paths } from 'type-fest';

import { V1SupportedVersion, V1ToolChains } from '@endorlabs/api_client';
import {
  MasterToolChainProfileResource,
  ToolChainProfileArchKey,
  ToolChainProfileOSKey,
} from '@endorlabs/endor-core/MasterToolChainProfile';
import {
  ScanProfileResource,
  ScanProfileToolChain,
} from '@endorlabs/endor-core/ScanProfile';

import { FormUpsertScanProfileFieldValues } from './types';

export const buildDefaultToolChain = (
  masterToolChainProfile?: MasterToolChainProfileResource
): ScanProfileToolChain => {
  const osKey: ToolChainProfileOSKey = 'linux';
  const archKey: ToolChainProfileArchKey = 'amd64';

  const defaultToolchains: V1ToolChains = {};

  const mapping: Record<string, Paths<V1ToolChains>> = {
    java: 'java_tool_chain.version',
    javascript: 'javascript_tool_chain.nodejs_version',
    python: 'python_tool_chain.version',
  };

  for (const [toolKey, toolchainKey] of Object.entries(mapping)) {
    const versions: V1SupportedVersion[] = _get(
      masterToolChainProfile,
      ['spec', 'os', osKey, 'arch', archKey, 'toolchain', toolKey, 'versions'],
      []
    );

    const defaultVersion = versions.at(0);
    if (defaultVersion) {
      _set(defaultToolchains, toolchainKey, defaultVersion.version);
    }
  }

  // Set the default toolchain
  const toolchain: ScanProfileToolChain = {};
  _set(toolchain, ['os', osKey, 'arch', archKey], defaultToolchains);

  return toolchain;
};

export const getDefaultValues = ({
  masterToolChainProfile,
  scanProfile,
}: {
  masterToolChainProfile?: MasterToolChainProfileResource;
  scanProfile?: ScanProfileResource;
}): FormUpsertScanProfileFieldValues => {
  if (scanProfile) {
    return {
      ...scanProfile,
      extra: {
        raw_toolchain_profile: JSON.stringify(
          scanProfile.spec.toolchain_profile,
          null,
          2
        ),
      },
    };
  }

  const defaultToolchain = buildDefaultToolChain(masterToolChainProfile);
  return {
    meta: { name: '' },
    spec: {},
    extra: {
      raw_toolchain_profile: JSON.stringify(defaultToolchain, null, 2),
    },
  };
};
