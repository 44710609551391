import { SvgIconProps, Tooltip, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScoreCategory } from '@endorlabs/api_client';

import { ScoreCategoryIcons } from '../../constants';
import { getScoreCategoryLabel } from '../../utils';

interface ScoreCategoryIconProps extends SvgIconProps {
  category: V1ScoreCategory;
}

export const ScoreCategoryIcon = ({
  category,
  ...props
}: ScoreCategoryIconProps) => {
  // @ts-expect-error - Index error b/c not all categories have icons
  const Icon = ScoreCategoryIcons[category] ?? undefined;
  const className = (props.className ?? '') + ' ScoreCategoryIcon-root';

  const tooltip = (
    <>
      <Typography marginBottom={1} variant="h4" color="inherit">
        <FM
          defaultMessage="Endor {category} Score"
          values={{ category: getScoreCategoryLabel(category) }}
        />
      </Typography>
      <Typography variant="caption">
        <FM defaultMessage="The Endor Score is a metric that evaluates open source packages based on factors of security, activity, popularity, and code quality." />
      </Typography>
    </>
  );

  return (
    <Tooltip title={tooltip}>
      <Icon {...props} className={className} />
    </Tooltip>
  );
};
