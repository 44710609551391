import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { ReactNode, SyntheticEvent, useCallback, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import { isValueFilter, ValueFilter } from '@endorlabs/filters';
import {
  BarChartStackedHorizontal,
  Color,
  Colors,
  EmptyState,
  FindingLevelMessages,
  SimpleMenu,
  TitleActionHeader,
  ValueFormatter,
} from '@endorlabs/ui-common';

import {
  DashboardChartFilter,
  DashboardChartFilterKeys,
  useDashboardState,
} from '../../domains/Dashboard';

const BREAKPOINT_WIDTH = 450;
const DEFAULT_HEIGHT = 350;
const TRUNCATED_DISPLAY_CHARS = 30;

const truncateDisplayValue = (value: string | number) => {
  let displayValue = value.toString();

  if (displayValue.length > TRUNCATED_DISPLAY_CHARS) {
    displayValue = displayValue.slice(0, TRUNCATED_DISPLAY_CHARS) + '...';
  }

  return displayValue;
};

interface CategoricalBarChartVisProps {
  chartId: string;
  findingLevels?: SpecFindingLevel[];
  height?: number;
  namespace: string;
  title: ReactNode;
}

/**
 * Outputs a horizontal bar chart where each bar represents a "primary" record
 * and x-axis is a count of "categorical" records associated with the primary.
 * Categorical records can be further divided into categorical subgroups.
 */
export const CategoricalBarChartVis = ({
  chartId,
  findingLevels,
  height = DEFAULT_HEIGHT,
  namespace,
  title,
}: CategoricalBarChartVisProps) => {
  const { palette, space } = useTheme();
  const { formatNumber, formatMessage } = useIntl();

  const {
    changeActiveViewFilter,
    getActiveViewFilter,
    getAvailableFilters,
    getView,
    getViewRecords,
    getViewIsLoading,
    getCategoryMessages,
    getViewColors,
  } = useDashboardState({ namespace });

  const theme = useTheme();
  const navigate = useNavigate();

  const activeView = getView(chartId);
  const activeFilter = getActiveViewFilter(chartId);
  const availableFilters = getAvailableFilters(chartId);

  const chartData: Record<string, string | number>[] = getViewRecords(chartId);
  const chartKeys = Array.from(getCategoryMessages(chartId).values());

  const findingLevelMapping = useMemo(
    () =>
      FINDING_LEVELS.map((level) => ({
        key: formatMessage(FindingLevelMessages[level]),
        level,
      })),
    [formatMessage]
  );

  const filteredData = useMemo(() => {
    // HACK: when the categorical chart data is for findings, this filteres the
    // visible chart data to the selected finding levels by creating a shallow
    // copy of the chart data, with counts for non-selected levels set to 0.
    const isFindingLevelData = activeFilter?.resourceKindCategory === 'Finding';
    if (!isFindingLevelData || !findingLevels?.length) return chartData;

    const filteredData = [];
    for (const d of chartData) {
      const copy = { ...d };

      for (const m of findingLevelMapping) {
        if (!findingLevels.includes(m.level)) {
          copy[m.key] = 0;
        }
      }

      filteredData.push(copy);
    }
    return filteredData;
  }, [
    activeFilter?.resourceKindCategory,
    chartData,
    findingLevelMapping,
    findingLevels,
  ]);

  const getValueFormat: ValueFormatter = (value) => {
    if ('string' === typeof value) return value;
    return formatNumber(value, {
      notation: 'compact',
    });
  };

  const isLoading = getViewIsLoading(chartId);
  const isEmptyState =
    !isLoading && !!chartData && Object.keys(chartData).length === 0;

  const handleFilterSelect = useCallback(
    (_: SyntheticEvent, filter: DashboardChartFilter) => {
      changeActiveViewFilter(chartId, filter.key);
    },
    [changeActiveViewFilter, chartId]
  );

  const handleChartClick = useCallback(
    (data: Record<string, string | number>, key?: string | number) => {
      if (!activeFilter || !data.url) return;
      let path = data.url;

      // For filters against Findings, build up deep links
      if (activeFilter.resourceKindCategory === 'Finding') {
        let filterSearch: string | undefined = undefined;
        const filterValues = {} as Record<string, ValueFilter>;

        activeFilter.filters?.forEach((f) => {
          if (isValueFilter(f)) {
            filterValues[f.key] = f;
          }
        });

        if (activeView?.resourceKindPrimary === 'DependencyMetadata') {
          // HACK: the aggregation for DependencyMetadata results in invalid
          // tags based on the importer of the dependency. Unsetting the value
          // here until the aggregation is fixed.
          delete filterValues['spec.finding_tags'];
        }

        if (DashboardChartFilterKeys.OUTDATED_DEPS === activeFilter.key) {
          // HACK: replace the filter by name with a search filter
          delete filterValues['meta.name'];
          filterSearch = 'Outdated Dependency';
        }

        if (DashboardChartFilterKeys.UNMAINTAINED_DEPS === activeFilter.key) {
          // HACK: replace the filter by name with a search filter
          delete filterValues['meta.name'];
          filterSearch = 'Unmaintained Dependency';
        }

        // HACK: if the chart key is a mapped finding level, add as filter
        for (const m of findingLevelMapping) {
          if (m.key === key) {
            filterValues['spec.level'] = {
              key: 'spec.level',
              comparator: 'IN',
              value: [m.level],
            };
            break;
          }
        }

        // Build up filter search params
        const params = new URLSearchParams({
          'filter.values': JSON.stringify(filterValues),
          'filter.search': filterSearch ?? '',
        });

        path += `/findings?${params}`;
      }

      navigate({ to: path });
    },
    [
      activeFilter,
      activeView?.resourceKindPrimary,
      findingLevelMapping,
      navigate,
    ]
  );

  return (
    <div style={{ position: 'relative', height: '100%' }} data-testid={chartId}>
      <Stack
        justifyContent="space-between"
        spacing={theme.space.sm}
        sx={{ height: '100%' }}
      >
        <TitleActionHeader
          // action={chartControls}
          title={
            <Stack alignItems="center" direction="row" spacing={space.xs}>
              <Typography variant="h3">{title}</Typography>
              <SimpleMenu
                groupedOptions={availableFilters}
                id={`FilterSelect-${chartId}`}
                onClick={handleFilterSelect}
                triggerTitle={
                  <Typography variant="h3">
                    {activeFilter?.label ?? 'Select Filter'}
                  </Typography>
                }
              />
            </Stack>
          }
          variant="h2"
        />

        <Stack height={height} justifyContent="center" alignItems="center">
          {isLoading && <CircularProgress />}
          {isEmptyState && (
            <EmptyState
              size="medium"
              imageWidth={300}
              textAlign="center"
              title={
                <FM defaultMessage="No records meet the filter criteria" />
              }
            />
          )}

          {!isLoading && !isEmptyState && (
            <ParentSize debounceTime={100}>
              {({ width: visWidth, height: visHeight }) => {
                let barTitlePosition: 'bottom' | 'left' = 'bottom';
                let barTitleValueFormat = undefined;
                let barTitleWidth = undefined;
                let showBarTitleTooltips = false;

                // Adjust the chart display for larger screens
                if (visWidth > BREAKPOINT_WIDTH) {
                  barTitlePosition = 'left';
                  barTitleValueFormat = truncateDisplayValue;
                  barTitleWidth = 200;
                  showBarTitleTooltips = true;
                }

                return (
                  <BarChartStackedHorizontal
                    axisBottom
                    axisColor={palette.text.secondary as Color}
                    axisPadding={10}
                    axisTickFormat={getValueFormat}
                    barColors={
                      getViewColors(chartId) ?? ([palette.data.blue] as Color[])
                    }
                    barCount={8}
                    barPadding={0.4}
                    barTitlePosition={barTitlePosition}
                    barTitleValueFormat={barTitleValueFormat}
                    barTitleWidth={barTitleWidth}
                    data={filteredData}
                    height={visHeight}
                    labelColor={Colors.BLACK}
                    margin={{ top: 4, left: 4, bottom: 40, right: 40 }}
                    onClickHandler={handleChartClick}
                    showBarTitles
                    showBarTitleTooltips={showBarTitleTooltips}
                    showGrid={false}
                    valueFormat={getValueFormat}
                    width={visWidth}
                    xKeys={chartKeys}
                    yKey="name"
                  />
                );
              }}
            </ParentSize>
          )}
        </Stack>
      </Stack>
    </div>
  );
};
