import { getEnv } from '../../../constants';
import { waitForElementAvailable } from '../utils';

const ENV = getEnv();

const selExportButton = '[data-testid="project-details-btn-export"]';
const selNav = '#app-primary-nav [data-testid="AppDrawerNavItem-projects"]';

export const getExportDataStep = () => ({
  attachTo: {
    element: selExportButton,
    on: 'left',
  },
  beforeShowPromise: async function () {
    await waitForElementAvailable(selExportButton);
  },

  highlightSelectors: [selExportButton, selNav],
  title: 'Meet SBOM and VEX Compliance',
  description:
    'Automate generation of SBOMs and VEX across your applications, and also ingest and manage 3rd party SBOMs.',

  urlLearn: `${ENV.URL_ENDOR_DOCS}/managing-sboms/`,
});
