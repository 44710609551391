import {
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { FindingLevelLabel, UIFindingUtils } from '@endorlabs/ui-common';

/**
 * Custom inputs for managing selected finding severity levels
 *
 * @todo refactor as a generic form group for checkbox, similar to the Radio
 * Group component in MUI: {@link https://mui.com/material-ui/react-radio-button/}
 */
export const FindingSeverityInput = ({
  value,
  onChange,
}: {
  value: SpecFindingLevel[];
  onChange: (event: SyntheticEvent, value: SpecFindingLevel[]) => void;
}) => {
  const theme = useTheme();

  const changeHandler =
    (level: SpecFindingLevel) => (event: SyntheticEvent, checked: boolean) => {
      const next = new Set(value);
      if (checked) {
        next.add(level);
      } else if (next.size > 1) {
        // HACK: prevent empty set of levels
        next.delete(level);
      }

      onChange(event, Array.from(next));
    };

  const levels = [
    SpecFindingLevel.Critical,
    SpecFindingLevel.High,
    SpecFindingLevel.Medium,
    SpecFindingLevel.Low,
  ];

  return (
    <Stack direction="row" gap={theme.space.sm} alignItems="center">
      <Typography>
        <FM defaultMessage="Displaying" />
      </Typography>

      {levels.map((level) => (
        <FormControlLabel
          key={level}
          sx={{
            margin: 0,
            // Override the color of the checkbox
            '& .MuiCheckbox-root': {
              '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                backgroundColor: 'transparent',
                color: UIFindingUtils.getSeverityColor(level, theme),
              },
            },
          }}
          control={
            <Checkbox
              onChange={changeHandler(level)}
              checked={value.includes(level)}
            />
          }
          label={<FindingLevelLabel findingLevel={level} />}
        />
      ))}
    </Stack>
  );
};
