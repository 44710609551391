import { Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  useCountSemgrepRules,
  useDeleteSemgrepRules,
  useListSemgrepRules,
  useUpdateSemgrepRules,
} from '@endorlabs/queries';
import {
  CommonDataTableRowActions,
  DataTableView,
  useConfirmationDialog,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { FilterBar, useFilterContext } from '../../../domains/filters';
import { useAuthInfo } from '../../../providers';
import { SAST_FILTER_FIELDS } from '../constants/filters';
import { buildSASTRulesTableColumnDefs, SASTRulesTableRow } from './SASTRules';
import { SASTRulesImportDialog } from './SASTRules/SASTRulesImportDialog';
import { getCWEForSASTRules } from './SASTRules/utils';

export const SASTRulesView = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const { formatMessage: fm } = useIntl();

  const [isSASTRulesDialogOpen, setIsSASTRulesDialogOpen] = useState(false);

  const { filter: searchFilterExpression } = useFilterContext();

  const filterExpression = useMemo(() => {
    const parts = ['spec.rule.metadata.endor_category == "vulnerability"'];

    if (searchFilterExpression) {
      parts.push(searchFilterExpression);
    }

    return parts.join(' and ');
  }, [searchFilterExpression]);

  const qCountSASTRules = useCountSemgrepRules(
    tenantName,
    {
      filter: filterExpression,
    },
    { enabled: !!tenantName }
  );

  const paginator = useDataTablePaginator({
    totalCount: qCountSASTRules.data?.count,
  });

  const qSASTRules = useListSemgrepRules(
    tenantName,
    {
      ...paginator.getListParameters(),
      sort: { path: 'meta.name' },
      filter: filterExpression,
    },
    { enabled: !!tenantName }
  );

  const qDeleteRule = useDeleteSemgrepRules();
  const qEditRule = useUpdateSemgrepRules();

  const SASTRules: SASTRulesTableRow[] = useMemo(() => {
    const rulesData = qSASTRules?.data?.objects ?? [];
    return rulesData.map((ruleObj) => {
      return {
        namespace: ruleObj.tenant_meta?.namespace,
        disabled: ruleObj.spec.disabled,
        ruleName: ruleObj.spec.rule?.metadata?.description,
        definedBy: 'Endor Labs',
        confidence: ruleObj.spec.rule?.metadata?.confidence ?? '',
        cwe: getCWEForSASTRules(ruleObj.spec.rule?.metadata?.cwe),
        uuid: ruleObj.uuid,
        ruleTags: ruleObj.spec.rule?.metadata?.endor_tags,
        languages: (ruleObj.spec.rule?.languages || [])
          .map((value) => value)
          .join(', '),
      };
    });
  }, [qSASTRules?.data]);

  const emptyStateProps = {
    title: <FM defaultMessage="There are no SAST rules in this project" />,
    children: (
      <Typography>
        <FM defaultMessage="As new rules are created, they will appear here." />
      </Typography>
    ),
  };

  const SASTRuleDeleteConfirmation = useConfirmationDialog<SASTRulesTableRow>({
    confirmText: <FM defaultMessage="Delete" />,
    isDestructive: true,
    onConfirm: (props) => {
      if (props) {
        qDeleteRule.mutate({
          namespace: props.namespace,
          resource: { uuid: props.uuid },
        });
      }
    },
    titleText: <FM defaultMessage="Delete this SAST Rule?" />,
    descriptionText: (
      <FM defaultMessage="This SAST Rule and related resources will be deleted." />
    ),
  });

  const handleDelete = (row: SASTRulesTableRow) => {
    SASTRuleDeleteConfirmation.openDialog(row);
  };

  const rowActions = [
    { ...CommonDataTableRowActions.DELETE_RESOURCE, onClick: handleDelete },
  ];

  const handleDisableRule = ({
    uuid,
    namespace,
  }: {
    uuid: string;
    namespace: string;
  }) => {
    const rule = qSASTRules?.data?.objects.find((r) => r.uuid === uuid);
    if (!rule) return;
    qEditRule.mutate({
      namespace: namespace,
      mask: 'spec.disabled',
      resource: {
        ...rule,
        spec: { ...rule.spec, disabled: !rule.spec.disabled },
      },
    });
  };

  const isLoading = qSASTRules?.isLoading || qCountSASTRules?.isLoading;
  return (
    <>
      <DataTableView
        bulkActions={[]}
        filtersContent={
          <FilterBar
            fields={SAST_FILTER_FIELDS}
            isCard={false}
            searchPlaceholder={fm({
              defaultMessage:
                'Search by Rule ID/Name, CWE, Confidence, Language or Tag Name',
            })}
          />
        }
        columns={buildSASTRulesTableColumnDefs({
          labelSwitch: fm({ defaultMessage: 'Enabled' }),
          onDisable: handleDisableRule,
          excludeColumns: [],
        })}
        countMessage={
          <FM
            defaultMessage="{count} Rules"
            values={{ count: qCountSASTRules?.data?.count ?? 0 }}
          />
        }
        data={SASTRules}
        emptyStateProps={emptyStateProps}
        isLoading={isLoading}
        namespace={tenantName}
        paginator={paginator}
        rowActions={rowActions}
      />
      <SASTRulesImportDialog
        enableUpload
        namespace={tenantName}
        open={isSASTRulesDialogOpen}
        onClose={() => {
          setIsSASTRulesDialogOpen(false);
        }}
      />
      <SASTRuleDeleteConfirmation.Dialog
        {...SASTRuleDeleteConfirmation.dialogProps}
      />
    </>
  );
};
