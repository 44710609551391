import { isValid } from 'date-fns';
import { without as _without } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { V1ArtifactType } from '@endorlabs/api_client';
import { FILTER_COMPARATORS, isValueFilter } from '@endorlabs/filters';
import { MultiSelectInput } from '@endorlabs/ui-common';

import { ControlsFacetFilterDatePicker } from '../../../components/FacetedSearchBar/ControlsFacetFilterDatePicker';
import { FilterFieldConfig, filterFieldTransformBuilders } from '../../filters';
import { ArtifactTypeMessages } from '../locales';

/**
 * Filter fields for the Artifact Signatures view
 */
export const ARTIFACT_SIGNATURE_FILTER_FIELDS: FilterFieldConfig<any>[] = [
  {
    id: 'ArtifactSignature:spec.artifact_type',
    ...filterFieldTransformBuilders.fromFilter({
      key: 'spec.artifact_type',
      comparator: FILTER_COMPARATORS.CONTAINS,
    }),
    renderInput: ({ onChange, value }) => (
      <MultiSelectInput
        label={<FM defaultMessage="Types" />}
        onChange={onChange}
        options={_without(
          Object.values(V1ArtifactType),
          // omit unspecified as an option
          V1ArtifactType.Unspecified
        )
          .map((value) => ({
            value,
            label: <FM {...ArtifactTypeMessages[value]} />,
          }))
          .sort((a, b) => a.value.localeCompare(b.value))}
        value={value as V1ArtifactType[]}
      />
    ),
  } satisfies FilterFieldConfig<V1ArtifactType[]>,
  {
    id: 'ArtifactSignature:meta.create_time',
    ...filterFieldTransformBuilders.fromFilter({
      key: 'meta.create_time',
      comparator: FILTER_COMPARATORS.GREATER_OR_EQUAL,
    }),
    format: (value) => {
      if (value && 'string' === typeof value) {
        const date = new Date(value);
        if (isValid(date)) {
          return {
            key: 'meta.create_time',
            comparator: FILTER_COMPARATORS.GREATER_OR_EQUAL,
            value: `date(${date.toISOString()})`,
          };
        }
      }
    },
    parse: (filter) => {
      if (isValueFilter(filter) && 'string' === typeof filter.value) {
        // HACK: ensure date value is properly escaped for the filter, and parsed for the date picker component
        let dateValue = filter.value;
        if (dateValue.startsWith('date(')) {
          dateValue = dateValue.replace(/date\((.*)\)/, '$1');
        }

        if (isValid(new Date(dateValue))) {
          return dateValue;
        }
      }
    },
    renderInput: ({ onChange, value }) => (
      <ControlsFacetFilterDatePicker
        onChange={onChange}
        value={value}
        facet={
          {
            label: <FM defaultMessage="Artifact Signature created during: " />,
          } as any
        }
      />
    ),
  } satisfies FilterFieldConfig<string>,
];
