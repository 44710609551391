import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';
import {
  ButtonStack,
  IconCheck,
  IconSidebarRight,
  RowStack,
} from '@endorlabs/ui-common';

import {
  AssuredPackageVersionFindingCountArrayDisplay,
  useAssuredPackageVersionDetailDrawer,
} from '../../AssuredPackageVersion';

interface PatchAvailableCardProps {
  assuredPackageVersions?: AssuredPackageVersionResource[];
}

export const PatchAvailableCard = ({
  assuredPackageVersions = [],
}: PatchAvailableCardProps) => {
  const { space } = useTheme();

  const { DetailDrawer, permalinkEffect } =
    useAssuredPackageVersionDetailDrawer();

  const openDrawer = (depName: string) => {
    DetailDrawer.activate(
      { packageVersionName: depName },
      { packageVersionName: depName }
    );
  };

  useEffect(() => {
    permalinkEffect({ assuredPackageVersions });
  }, [assuredPackageVersions, permalinkEffect]);

  return (
    <Stack rowGap={space.sm}>
      {assuredPackageVersions.map((apv) => {
        return (
          <Card key={apv.uuid}>
            <CardContent sx={{ paddingRight: space.sm }}>
              <RowStack alignItems="top" columnGap={space.sm} width="100%">
                <IconCheck color="success" fontSize="medium" />

                <Stack rowGap={1}>
                  <Typography variant="h6">
                    <FM defaultMessage="Endor Magic Patch Available" />
                  </Typography>

                  <AssuredPackageVersionFindingCountArrayDisplay
                    assuredPackageVersion={apv}
                  />
                </Stack>

                <ButtonStack
                  sx={{
                    flexGrow: 2,
                    justifyContent: 'flex-end',
                    justifySelf: 'flex-end',
                  }}
                >
                  <IconButton onClick={() => openDrawer(apv.meta.name)}>
                    <IconSidebarRight />
                  </IconButton>
                </ButtonStack>
              </RowStack>
            </CardContent>
          </Card>
        );
      })}
    </Stack>
  );
};
