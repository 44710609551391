import { Grid, useTheme } from '@mui/material';
import { Outlet } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import { LinkTabProps, NavigationTabs } from '@endorlabs/ui-common';

import { PageHeader } from '../../../components';

const ScanProfileDetailPageTabRecords: LinkTabProps[] = [
  {
    label: <FM defaultMessage="Profile" />,
    to: 'profile',
    value: 'profile',
  },
  {
    label: <FM defaultMessage="Projects" />,
    to: 'projects',
    value: 'projects',
  },
];

export const ScanProfileDetailPage = ({
  scanProfile,
}: {
  scanProfile: ScanProfileResource;
}) => {
  const { space } = useTheme();

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={space.sm}>
      <Grid item>
        <PageHeader title={scanProfile?.meta.name} />
      </Grid>

      <Grid item>
        <NavigationTabs tabs={ScanProfileDetailPageTabRecords} />
      </Grid>

      <Grid item>
        <Outlet />
      </Grid>
    </Grid>
  );
};
