import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  IQueryErrorResponse,
  PackageManagerResource,
} from '@endorlabs/queries';
import { ButtonPrimary, ControlledTextField } from '@endorlabs/ui-common';

import { TokenLoginPackageManagerKey } from './constants';
import { PackageManagerAdvancedFields } from './PackageManagerAdvancedFields';
import { TokenAuthFieldValues } from './types';
import { updateTokenFieldsToModel } from './utils';

interface FormTokenManagerProps {
  authRequired: boolean;
  onSubmit: (data: TokenAuthFieldValues) => void;
  packageManager?: PackageManagerResource;
  packageManagerKey: TokenLoginPackageManagerKey;
  serverErrorResponse?: IQueryErrorResponse;
}

export const FormUpsertTokenAuthPackageManager = ({
  authRequired,
  onSubmit,
  packageManager,
  packageManagerKey,
}: FormTokenManagerProps) => {
  const { control, handleSubmit, reset } = useForm<TokenAuthFieldValues>();
  const { formatMessage: fm } = useIntl();
  //Check if it is edit and user has added token
  const isTokenAdded = packageManager?.spec[packageManagerKey]?.token;
  const isAuthAdded = isTokenAdded && isTokenAdded.length > 0 ? true : false;
  const [isAuthDisplayed, setIsAuthDisplayed] = useState<boolean>(
    authRequired || isAuthAdded
  );

  useEffect(() => {
    // reset fields from the given package manager
    reset({ propagate: packageManager?.propagate ?? false });
  }, [packageManager, reset]);

  const wrappedOnSubmit = useCallback(
    (fieldValues: TokenAuthFieldValues) => {
      const formattedValues = updateTokenFieldsToModel(
        fieldValues,
        isAuthDisplayed
      );
      onSubmit(formattedValues);
    },
    [onSubmit, isAuthDisplayed]
  );

  return (
    <form id="tokenAuthPM" onSubmit={handleSubmit(wrappedOnSubmit)}>
      <Grid container direction="column" item spacing={6}>
        <Grid item>
          <ControlledTextField
            control={control}
            defaultValue={
              packageManager?.spec[packageManagerKey]?.priority ?? ''
            }
            name="priority"
            type="hidden"
          />

          <ControlledTextField
            autoComplete="off"
            control={control}
            defaultValue={packageManager?.spec[packageManagerKey]?.url ?? ''}
            fullWidth
            label={fm({
              defaultMessage: 'Package manager URL',
            })}
            name="url"
            rules={{
              required: fm({
                defaultMessage: 'Package manager URL is required',
              }),
              // TODO: URL validation that accounts for S3
            }}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <ControlledTextField
            control={control}
            defaultValue={packageManager?.spec[packageManagerKey]?.scope ?? ''}
            label={<FM defaultMessage="Scope" />}
            placeholder={fm({
              defaultMessage: 'Example: @my-org/package-name',
            })}
            name="scope"
            rules={{
              pattern: {
                value: /\S+/,
                message: fm({
                  defaultMessage: 'Must be a valid npm scope',
                }),
              },
            }}
          />
        </Grid>
        <>
          <Grid item>
            <FormControlLabel
              label={
                authRequired ? (
                  <FM defaultMessage="Authenticate to this registry (required)" />
                ) : (
                  <FM defaultMessage="Authenticate to this registry (optional)" />
                )
              }
              control={
                <Checkbox
                  checked={isAuthDisplayed}
                  onClick={() => setIsAuthDisplayed(!isAuthDisplayed)}
                />
              }
            />
          </Grid>
          {isAuthDisplayed && (
            <Grid item width="36%">
              <ControlledTextField
                control={control}
                defaultValue={
                  packageManager?.spec[packageManagerKey]?.token ?? ''
                }
                label={<FM defaultMessage="Token" />}
                placeholder={fm({
                  defaultMessage:
                    'Token used to authenticate to private registry',
                })}
                name="token"
                rules={{
                  required: fm({
                    defaultMessage: 'Token is required if using authentication',
                  }),
                }}
                type="password"
              />
            </Grid>
          )}
        </>

        <Grid item>
          <PackageManagerAdvancedFields control={control} />
        </Grid>

        <Grid item marginTop={4}>
          <ButtonPrimary type="submit">
            {packageManager && <FM defaultMessage="Save Changes" />}
            {!packageManager && <FM defaultMessage="Add Package Manager" />}
          </ButtonPrimary>
        </Grid>
      </Grid>
    </form>
  );
};
