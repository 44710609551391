import IconClose from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

type ApiKeyFormModalProps = PropsWithChildren<{
  title: ReactNode;
  open: boolean;
  onClose: () => void;
}>;

/**
 * Modal wrapper for the Api Key Form.
 */
export const ApiKeyFormModal = ({
  title,
  open,
  onClose,
  children,
}: ApiKeyFormModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {title}
          {onClose && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ marginRight: -2 }}
            >
              <IconClose />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      {/* Padding to compensate for the absence of DialogActions  */}
      <DialogContent sx={{ paddingBottom: 8 }}>{children}</DialogContent>
    </Dialog>
  );
};
