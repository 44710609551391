import { uniq as _uniq } from 'lodash-es';
import { useMemo } from 'react';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  QueryProjectToolsJoinFilters,
  sortParamBuilders,
  tryParseGroupResponseAggregationKey,
  useCountProjects,
  useListMetrics,
  useQueryProjectTools,
} from '@endorlabs/queries';
import { DataTablePaginator } from '@endorlabs/ui-common';

import { mapToCiCdToolsTableRows } from '../utils';

export const useCiCdToolsIndexPageData = ({
  baseFilter,
  namespace,
  paginator,
  queryFilters,
}: {
  namespace: string;
  paginator: DataTablePaginator;
  baseFilter: string;
  queryFilters: QueryProjectToolsJoinFilters[];
}) => {
  // get TOTAL count of projects
  const qCountProjectTools = useCountProjects(
    namespace,
    {
      staleTime: Infinity,
    },
    { filter: baseFilter }
  );

  const qQueryProjectTools = useQueryProjectTools(
    namespace,
    {
      ...paginator.getListParameters(),
      sort: sortParamBuilders.descendingBy('meta.update_time'),
    },
    {
      filters: queryFilters,
    }
  );

  const qToolGroups = useListMetrics(
    namespace,
    {},
    {
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.mainResourceContext(),
        `meta.name==version_cicd_tools`,
      ]),
      group: {
        aggregation_paths:
          'spec.metric_values.CiCdTools.ci_cd_tools.tools.name',
      },
    }
  );

  const cicdTools = useMemo(() => {
    return mapToCiCdToolsTableRows(namespace, qQueryProjectTools.data);
  }, [qQueryProjectTools.data, namespace]);

  const toolCount = useMemo(() => {
    const allTools = Object.keys(
      qToolGroups.data?.group_response?.groups ?? {}
    ).reduce((acc, key) => {
      const values = tryParseGroupResponseAggregationKey(key);
      const projectToolNames = values.find(
        (kv) => kv.key === 'spec.metric_values.CiCdTools.ci_cd_tools.tools.name'
      )?.value as string[] | undefined;
      if (projectToolNames) {
        acc.push(...projectToolNames);
      }
      return acc;
    }, [] as string[]);
    const uniqueTools = _uniq(allTools);
    return uniqueTools.length;
  }, [qToolGroups.data?.group_response?.groups]);

  return {
    ...qQueryProjectTools,
    cicdTools,
    projectCount: qCountProjectTools.data?.count ?? 0,
    toolCount,
  };
};
