import { ResourceKind } from './types';

/**
 * Endpoints for API Resources
 */
export const RESOURCE_ENDPOINTS: Record<ResourceKind, string> = {
  APIKey: 'api-keys',
  ApproveServiceRequest: 'approve-service-requests',
  ArtifactSignature: 'artifact-signature',
  AssuredPackageVersion: 'assured-package-versions',
  AuthorizationPolicy: 'authorization-polcies',
  CIRun: 'ci-runs',
  DashboardConfig: 'dashboard-config',
  Demo: 'demos',
  DependencyMetadata: 'dependency-metadata',
  EndorLicense: 'endor-licenses',
  Finding: 'findings',
  IdentityProvider: 'identity-providers',
  ImportedSBOM: 'sbom-imports',
  Installation: 'installations',
  Invitation: 'invitations',
  Metric: 'metrics',
  Namespace: 'namespaces',
  Notification: 'notifications',
  NotificationTarget: 'notification-targets',
  PRCommentConfig: 'pr-comment-configs',
  Package: 'packages',
  PackageManager: 'package-managers',
  PackageVersion: 'package-versions',
  PackageVersionMetadata: 'package-versions-metadata',
  Policy: 'policies',
  PolicyTemplate: 'policy-templates',
  Project: 'projects',
  Repository: 'repositorioes',
  RepositoryCommit: 'repository-commits',
  RepositoryVersion: 'repository-versions',
  SBOMOrganizationalContact: 'sbom-organization-contact',
  SastRules: 'sast-rules',
  SavedQuery: 'saved-queries',
  ScanResult: 'scan-results',
  SecretRule: 'secret-rules',
  SemgrepRule: 'semgrep-rules',
  ServiceRequest: 'service-requests',
  SystemConfig: 'system-config',
  Tenant: 'tenants',
  ToolPattern: 'tool-patterns',
  UITelemetry: 'ui-telemetries',
  UserTelemetry: 'user-telemetries',
  VersionUpgrade: 'version-upgrades',
};

export const RESOURCES_WITH_CONTEXT: ResourceKind[] = [
  'DependencyMetadata',
  'Finding',
  'Metric',
  'Notification',
  'PackageVersion',
  'RepositoryVersion',
  'ScanResult',
  'ImportedSBOM',
];

export const RESOURCES_WITH_PROPAGATE: ResourceKind[] = [
  'APIKey',
  'AuthorizationPolicy',
  'PackageManager',
  'SecretRule',
];
