import { Card, CardContent, Grid } from '@mui/material';
import { useRouter } from '@tanstack/react-location';
import {
  head as _head,
  isEmpty as _isEmpty,
  orderBy as _orderBy,
} from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  SpecEndorLicenseFeatureType,
  V1ArtifactType,
} from '@endorlabs/api_client';
import {
  tryParseGroupResponseAggregationKey,
  useListArtifactSignature,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  EmptyState,
  IconArtifactRegistry,
  NavigationTabs,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { ARTIFACT_SIGNATURE_FILTER_FIELDS } from '../../domains/ArtifactSignature';
import {
  FilterBar,
  ResourceFilterContextProvider,
  useFilterContext,
} from '../../domains/filters';
import { useAuthInfo, useLicensingInfo } from '../../providers';
import {
  ArtifactSignaturesTable,
  ArtifactSignaturesTableRow,
} from './ArtifactSignaturesTable';
import { ArtifactsTabName, useArtifactsPageTabs } from './useArtifactsPageTab';

const BaseIndexPage = () => {
  const { activeNamespace: tenantName, setLicenseInCurrentRoute } =
    useAuthInfo();

  const [selectedRows, setSelectedRows] = useState({});
  const { filter: userFilterExpression, clearFilter } = useFilterContext();
  const router = useRouter();

  const { activeTab, tabRecords } = useArtifactsPageTabs({
    activeTab: 'signatures' as ArtifactsTabName,
    namespace: tenantName,
  });

  // const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
  //   setSelectedRows(rowSelection);
  // }, []);

  const qArtifactSignature = useListArtifactSignature(
    tenantName,
    {},
    {
      filter: userFilterExpression,
      group: {
        aggregation_paths: 'spec.artifact_name',
        unique_value_paths:
          'meta.create_time,meta.update_time,spec.artifact_type',
      },
    }
  );
  const hasError = qArtifactSignature.isError;
  const isLoading = qArtifactSignature.isLoading;
  const isEmptyState =
    !isLoading &&
    !hasError &&
    _isEmpty(qArtifactSignature.data?.group_response?.groups) &&
    !userFilterExpression;

  const artifactSignatures = useMemo(() => {
    const artifactSignatureGroupEntries = Object.entries(
      qArtifactSignature.data?.group_response?.groups ?? {}
    );

    const artifactSignatures: ArtifactSignaturesTableRow[] = [];

    for (const [key, group] of artifactSignatureGroupEntries) {
      // Find artifact name in group response
      const aggregation = tryParseGroupResponseAggregationKey(key);
      const artifactName = aggregation.find(
        (kv) => kv.key === 'spec.artifact_name'
      )?.value as undefined | string;

      // NOTE: extracting a "friendly" name from the artifact name
      const cleanArtifactName = artifactName?.split('/').pop();

      // NOTE: skipping entries where no artifact name exists. This could result
      // in an unexpected empty state for this page if artifact signatures are
      // created without an artifact name.
      if (!artifactName) continue;
      if (!cleanArtifactName) continue;

      const artifactType = _head(
        group.unique_values?.['spec.artifact_type']
      ) as V1ArtifactType;

      // Get most recent value for create and update times
      const createTimes = (
        group.unique_values?.['meta.create_time'] ?? []
      ).sort((a, b) => b.localeCompare(a));
      const updateTimes = (
        group.unique_values?.['meta.update_time'] ?? []
      ).sort((a, b) => b.localeCompare(a));

      artifactSignatures.push({
        artifactType,
        fullName: artifactName,
        name: cleanArtifactName,
        createTime: _head(createTimes),
        updateTime: _head(updateTimes),
      });
    }

    return _orderBy(artifactSignatures, ['name', 'createTime']);
  }, [qArtifactSignature.data?.group_response?.groups]);

  const { checkLicensePresent } = useLicensingInfo();
  const isArtifactLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.ArtifactSign
  );

  useEffect(() => {
    if (!isArtifactLicensePresent) {
      setLicenseInCurrentRoute({
        pathname: router.state.location.pathname,
        isLicense: false,
      });
      return () => {
        setLicenseInCurrentRoute({
          pathname: '',
          isLicense: true,
        });
      };
    }
  }, [
    isArtifactLicensePresent,
    router.state.location.pathname,
    setLicenseInCurrentRoute,
  ]);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          Icon={IconArtifactRegistry}
          title={<FM defaultMessage="Artifacts" />}
        />
      </Grid>
      <Grid item flexWrap="nowrap" sx={{ width: '100%' }} xs={12}>
        <NavigationTabs activeTab={activeTab} tabs={tabRecords} />
      </Grid>

      {!isEmptyState && (
        <Grid item>
          <FilterBar fields={ARTIFACT_SIGNATURE_FILTER_FIELDS} />
        </Grid>
      )}

      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="You have not signed any artifacts yet" />
            }
            description={
              <FM defaultMessage="As you sign your artifacts, they will appear here." />
            }
          ></EmptyState>
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <Card>
            <CardContent>
              <ArtifactSignaturesTable
                data={artifactSignatures}
                emptyStateProps={{
                  title: (
                    <FM defaultMessage="No Artifact Signatures to display" />
                  ),
                  children: (
                    <ButtonCancel onClick={clearFilter}>
                      <FM defaultMessage="Clear Filters" />
                    </ButtonCancel>
                  ),
                }}
                title={
                  !_isEmpty(selectedRows) ? (
                    <FM
                      defaultMessage="{selectedCount, number} of {totalCount, number} {totalCount, plural, one {Artifact Signature} other {Artifact Signatures}} selected"
                      values={{
                        selectedCount: Object.keys(selectedRows).length,
                        totalCount: artifactSignatures.length,
                      }}
                    />
                  ) : undefined
                }
                isLoading={isLoading}
                namespace={tenantName}
                enablePagination
                // enableRowSelection
                // onRowSelectionChange={handleRowSelection}
                enableColumnSort
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export const ArtifactsIndexPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  return (
    <ResourceFilterContextProvider
      namespace={tenantName}
      resourceKind="ArtifactSignature"
    >
      <BaseIndexPage />
    </ResourceFilterContextProvider>
  );
};
