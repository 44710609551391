import { Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';

interface NumberDisplayProps {
  typographyProps?: TypographyProps;
  value?: number;
  zeroDisplay?: ReactNode;
}

/**
 * NumberDisplay presents a numeric value in a consistent, internationalized format.
 */
export const NumberDisplay = ({
  typographyProps = {},
  value,
  zeroDisplay,
}: NumberDisplayProps) => {
  const { formatNumber } = useIntl();

  const output = `${formatNumber(value ?? 0, { notation: 'compact' })}`;

  const displayValue =
    value === 0 && !!zeroDisplay ? (
      zeroDisplay
    ) : value !== undefined && value < 1000 ? (
      <FormattedNumber value={value} />
    ) : (
      value !== undefined && output
    );

  return (
    <Typography
      className="NumberDisplay-root"
      variant="inherit"
      {...typographyProps}
    >
      {displayValue}
    </Typography>
  );
};
