import { Grid } from '@mui/material';

import { PackageVersionResource } from '@endorlabs/queries';
import { NavigationTabs } from '@endorlabs/ui-common';

import { PackageVersionDetailCallStack } from './PackageVersionDetailCallStack';
import { PackageVersionDetailCompare } from './PackageVersionDetailCompare';
import { PackageVersionDetailContainerLayers } from './PackageVersionDetailContainerLayers';
import { PackageVersionDetailDependencies } from './PackageVersionDetailDependencies';
import { PackageVersionDetailDependencyGraph } from './PackageVersionDetailDependencyGraph';
import { PackageVersionDetailDependents } from './PackageVersionDetailDependents';
import { PackageVersionDetailFindings } from './PackageVersionDetailFindings';
import { PackageVersionDetailOverview } from './PackageVersionDetailOverview';
import { PackageVersionRemediations } from './PackageVersionRemediations';
import {
  PackageVersionDetailTabName,
  usePackageVersionDetailTabs,
} from './usePackageVersionDetailTabs';

export interface PackageVersionDetailViewProps {
  activeTab: string;
  packageVersion?: PackageVersionResource;
  tenantName: string;
}

/**
 * Shared view for Package Versions
 */
export function PackageVersionDetailView({
  activeTab: targetActiveTab,
  packageVersion,
  tenantName,
}: PackageVersionDetailViewProps) {
  // build up tabs for view
  const { activeTab, tabRecords } = usePackageVersionDetailTabs({
    activeTab: targetActiveTab,
    packageVersion,
  });

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item flexWrap="nowrap" sx={{ width: '100%' }} xs={12}>
        <NavigationTabs activeTab={activeTab} tabs={tabRecords} />
      </Grid>

      <Grid item>
        {activeTab === PackageVersionDetailTabName.OVERVIEW && (
          <PackageVersionDetailOverview
            namespace={tenantName}
            packageVersion={packageVersion}
          />
        )}

        {activeTab === PackageVersionDetailTabName.DEPENDENCIES && (
          <PackageVersionDetailDependencies
            tenantName={tenantName}
            packageVersion={packageVersion}
          />
        )}

        {activeTab === PackageVersionDetailTabName.DEPENDENTS && (
          <PackageVersionDetailDependents
            tenantName={tenantName}
            packageVersion={packageVersion}
          />
        )}

        {activeTab === PackageVersionDetailTabName.FINDINGS && (
          <PackageVersionDetailFindings
            tenantName={tenantName}
            packageVersion={packageVersion}
          />
        )}

        {activeTab === PackageVersionDetailTabName.REMEDIATIONS && (
          <PackageVersionRemediations
            tenantName={tenantName}
            packageVersion={packageVersion}
          />
        )}

        {activeTab === PackageVersionDetailTabName.COMPARE && (
          <PackageVersionDetailCompare />
        )}

        {activeTab === PackageVersionDetailTabName.CALL_STACK && (
          <PackageVersionDetailCallStack />
        )}

        {activeTab === PackageVersionDetailTabName.DEP_GRAPH && (
          <PackageVersionDetailDependencyGraph
            tenantName={tenantName}
            packageVersion={packageVersion}
          />
        )}

        {activeTab === PackageVersionDetailTabName.LAYERS && (
          <PackageVersionDetailContainerLayers
            packageVersion={packageVersion}
          />
        )}

        {/*
          TODO: add additional tabs for pages
          {activeTab === PackageVersionDetailTabName.SETTINGS && <PackageVersionDetailSettings />}
        */}
      </Grid>
    </Grid>
  );
}
