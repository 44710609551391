import { pick as _pick } from 'lodash-es';
import { QueryKey, useQuery } from 'react-query';

import {
  ContextContextType,
  QueryJoinFilters,
  QueryQuerySpec,
  QueryServiceApi,
  ScanResultSpecType,
  V1ImportedSBOM,
  V1ImportedSBOMSpec,
  V1ListParameters,
  V1Meta,
  V1Project,
  V1ProjectSpec,
  V1Query,
  V1ScanResult,
  V1ScanResultSpec,
} from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { SelectFrom } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import {
  PackageVersionResource,
  ResourceQueryOptions,
  TResourceList,
} from './types';
import { getClientConfiguration, sortParamBuilders } from './utils';

// Resource Kinds included in the Project Query `with` clause
const REFERENCES_WITH_CONTEXT: ResourceKind[] = [
  'DependencyMetadata',
  'Finding',
  'Metric',
];

export const QuerySBOMImportProjectsQueryKeys = {
  query: (
    namespace: string,
    listParams: V1ListParameters = {},
    filters: QueryJoinFilters[] = []
  ): QueryKey =>
    ['v1/queries', 'sbom-imports', namespace, listParams, filters] as const,
};

export type QuerySBOMImportProjectsResponse =
  TResourceList<QuerySBOMImportProjectsResponseObject>;

export type QuerySBOMImportProjectsResponseObject = SelectFrom<
  V1Project,
  'processing_status' | 'tenant_meta' | 'uuid',
  {
    meta: SelectFrom<
      V1Meta,
      'create_time' | 'name' | 'tags',
      {
        references: {
          PackageVersion?: TResourceList<QuerySBOMImportsPackageVersionObject>;
          ImportedSBOM?: TResourceList<QueryImportedSBOMObject>;
          ScanResult?: TResourceList<QuerySBOMImportsScanResultsObject>;
        };
      }
    >;
    spec: SelectFrom<V1ProjectSpec, 'sbom'>;
  }
>;

export type QuerySBOMImportsScanResultsObject = SelectFrom<
  V1ScanResult,
  'uuid' | 'context',
  {
    spec: SelectFrom<V1ScanResultSpec, 'status'>;
  }
>;

export type QueryImportedSBOMObject = SelectFrom<
  V1ImportedSBOM,
  'context' | 'processing_status' | 'uuid',
  {
    meta: SelectFrom<V1Meta, 'name' | 'tags'>;
    spec: SelectFrom<
      V1ImportedSBOMSpec,
      'identifier' | 'kind' | 'main_component_purl' | 'supplier_name'
    >;
  }
>;

export type QuerySBOMImportsPackageVersionObject = SelectFrom<
  PackageVersionResource,
  'context' | 'uuid' | 'tenant_meta',
  {
    meta: SelectFrom<PackageVersionResource['meta'], 'create_time' | 'name'>;
  }
>;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuerySpec = (
  rootListParams: V1ListParameters,
  filters: QueryJoinFilters[]
): QueryQuerySpec => {
  const commonListParameters = _pick(rootListParams, ['traverse']);

  const projectFilter = filters.find((f) => f.kind === 'Project')?.filter;
  // For query join filters, ensure that context is set
  const queryJoinFilters = filters
    .filter((f) => f.kind !== 'Project')
    .map((f) => {
      if (REFERENCES_WITH_CONTEXT.some((kind) => kind === f.kind)) {
        return {
          ...f,
          filter: [
            `context.type == ${ContextContextType.Sbom}`,
            `(${f.filter})`,
          ].join(' and '),
        };
      }

      return f;
    });

  return {
    kind: 'Project',
    list_parameters: {
      // update as needed
      ...rootListParams,
      filter: projectFilter,
      mask: [
        'meta.create_time',
        'meta.name',
        'meta.tags',
        'processing_status',
        'spec.sbom',
        'tenant_meta',
        'uuid',
      ].join(','),
    },
    with: queryJoinFilters,
    references: [
      {
        connect_from: 'uuid',
        connect_to: 'meta.parent_uuid',
        query_spec: {
          kind: 'ImportedSBOM',
          list_parameters: {
            ...commonListParameters,
            // should only be 1
            page_size: 1,
            // update as needed
            mask: [
              'context',
              'meta.name',
              'meta.tags',
              'processing_status',
              'spec.kind',
              'spec.identifier',
              'spec.main_component_purl',
              'spec.supplier_name',
              'uuid',
            ].join(),
            sort: sortParamBuilders.descendingBy('meta.create_time'),
          },
        },
      },
      {
        connect_from: 'uuid',
        connect_to: 'spec.project_uuid',
        query_spec: {
          kind: 'PackageVersion',
          list_parameters: {
            ...commonListParameters,
            // should only be 1
            page_size: 1,
            mask: [
              'context',
              'meta.create_time',
              'meta.name',
              'tenant_meta',
              'uuid',
            ].join(','),
            filter: `context.type == ${ContextContextType.Sbom}`,
          },
        },
      },
      {
        connect_from: 'uuid',
        connect_to: 'meta.parent_uuid',
        query_spec: {
          kind: 'ScanResult',
          list_parameters: {
            ...commonListParameters,
            filter: filterExpressionBuilders.and([
              `context.type == ${ContextContextType.Sbom}`,
              `spec.type == ${ScanResultSpecType.AllScans}`,
            ]),
            mask: ['uuid', 'meta', 'spec.status', 'context'].join(),
            page_size: 1,
            sort: sortParamBuilders.descendingBy('meta.create_time'),
          },
        },
      },
    ],
  };
};

const buildQuery = (
  namespace: string,
  listParams: V1ListParameters,
  filters: QueryJoinFilters[]
): V1Query => {
  return {
    meta: {
      name: `QueryImportedSBOMProjects(namespace: ${namespace})`,
    },
    spec: {
      query_spec: buildQuerySpec(listParams, filters),
    },
    tenant_meta: { namespace },
  };
};

const querySBOMImportProjects = async (
  namespace: string,
  listParams: V1ListParameters,
  filters: QueryJoinFilters[],
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams, filters);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QuerySBOMImportProjectsResponse;
};

/**
 * Custom query for the SBOM Imports page
 */
export const useQuerySBOMImportProjects = (
  namespace: string,
  opts: ResourceQueryOptions<QuerySBOMImportProjectsResponse> = {},
  // Filters should be passed in formatted `QueryJoinFilters` rather
  // than as part of the list parameters object.
  // The Query spec sets the mask used
  listParams: Omit<ListRequestParameters, 'filter' | 'mask'> = {},
  filters: QueryJoinFilters[] = []
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ImportedSBOM',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QuerySBOMImportProjectsQueryKeys.query(
      namespace,
      requestParameters,
      filters
    ),
    ({ signal }) =>
      querySBOMImportProjects(namespace, requestParameters, filters, signal),
    { ...opts }
  );
};
