import { Typography } from '@mui/material';
import { defineMessages, useIntl } from 'react-intl';

import {
  ContextContextType,
  ScanResultSpecStatus,
  V1EndorctlRC,
} from '@endorlabs/api_client';
import {
  getBypassHostCheck,
  getRunBySystem,
  ScanResultResource,
} from '@endorlabs/endor-core/ScanResult';

import { RowStack, StatusIndicator } from '../../../../components';
import { Status } from '../../../../constants';

const messages = defineMessages({
  scan_failed: { defaultMessage: 'Scan Failed' },
  scan_failed_manual: { defaultMessage: 'Manual Scan Failed' },
  scan_failure_detail: {
    defaultMessage:
      'There are issues with this scan. See the Project page for more details.',
  },
  scan_failure_detail_ci_run: {
    defaultMessage:
      'There are issues with this scan. See the PR Run page for more details.',
  },
  scan_failure_detail_sbom: {
    defaultMessage:
      'There are issues with this scan. See the SBOM page for more details.',
  },
  scan_failure_detail_onclick: {
    defaultMessage: 'There are issues with this scan. Click for more details.',
  },
  bypass_host_check: {
    defaultMessage:
      'Host check was bypassed for this project using `--bypass-host-check`. This may result in incomplete or inaccurate data when project build tools are uninstalled or unsupported.',
  },
  scan_success_caveats: { defaultMessage: 'Scan Succeeded with Caveats' },
  scan_missing: { defaultMessage: 'No recent scans found' },
  scan_missing_details: {
    defaultMessage:
      'Scan status will be shown here after a scan has completed.',
  },
  scan_running: {
    defaultMessage: 'Scan is currently running.',
  }
});

export const ScanResultStatusIndicator = ({
  onClick,
  scanResults,
}: {
  onClick?: (event: React.MouseEvent) => void;
  scanResults?: ScanResultResource[];
}) => {
  const { formatMessage: fm } = useIntl();

  let hasBypassHostCheck = false;
  let hasAutomatedScan = false;

  let failureCount = 0;
  let policyFailureCount = 0;
  let partialSuccessCount = 0;

  let scanContextType: ContextContextType | undefined = undefined;
  for (const sr of scanResults ?? []) {
    scanContextType = sr.context?.type;
    if (getBypassHostCheck(sr)) {
      hasBypassHostCheck = true;
    }
    if (getRunBySystem(sr)) {
      hasAutomatedScan = true;
    }

    if (sr.spec?.status === ScanResultSpecStatus.Failure) {
      const exitCode = sr.spec?.exit_code;
      exitCode === V1EndorctlRC.PolicyViolation ||
        exitCode === V1EndorctlRC.PolicyWarning
        ? policyFailureCount++
        : failureCount++;
    } else if (sr.spec?.status === ScanResultSpecStatus.PartialSuccess) {
      partialSuccessCount++;
    }
  }

  if (failureCount > 0) {
    const title = hasAutomatedScan
      ? fm(messages.scan_failed)
      : fm(messages.scan_failed_manual);

    const details = onClick
      ? fm(messages.scan_failure_detail_onclick, { count: failureCount })
      : scanContextType === ContextContextType.CiRun
        ? fm(messages.scan_failure_detail_ci_run, { count: failureCount })
        : scanContextType === ContextContextType.Sbom
          ? fm(messages.scan_failure_detail_sbom, { count: failureCount })
          : fm(messages.scan_failure_detail, {
            count: failureCount,
          });

    const detailMessages = [details];
    if (hasBypassHostCheck) {
      detailMessages.push(fm(messages.bypass_host_check));
    }

    const tooltipNode = (
      <>
        <Typography variant="h4" marginBottom={1}>
          {title}
        </Typography>
        <RowStack alignItems="flex-start" gap={1}>
          {detailMessages.map((d, i) => (
            <Typography key={i} variant="caption">
              {d}
            </Typography>
          ))}
        </RowStack>
      </>
    );

    return (
      <StatusIndicator
        onClick={onClick}
        status={Status.Failure}
        tooltipNode={tooltipNode}
      />
    );
  }

  if (partialSuccessCount > 0) {
    const title = fm(messages.scan_success_caveats);

    const details = onClick
      ? fm(messages.scan_failure_detail_onclick, { count: failureCount })
      : scanContextType === ContextContextType.CiRun
        ? fm(messages.scan_failure_detail_ci_run, { count: failureCount })
        : scanContextType === ContextContextType.Sbom
          ? fm(messages.scan_failure_detail_sbom, { count: failureCount })
          : fm(messages.scan_failure_detail, {
            count: partialSuccessCount,
          });

    const detailMessages = [details];
    if (hasBypassHostCheck) {
      detailMessages.push(fm(messages.bypass_host_check));
    }

    const tooltipNode = (
      <>
        <Typography variant="h4" marginBottom={1}>
          {title}
        </Typography>

        <RowStack alignItems="flex-start" gap={1}>
          {detailMessages.map((d, i) => (
            <Typography key={i} variant="caption">
              {d}
            </Typography>
          ))}
        </RowStack>
      </>
    );

    return (
      <StatusIndicator
        onClick={onClick}
        status={Status.PartialSuccess}
        tooltipNode={tooltipNode}
      />
    );
  }

  // handle missing case
  if (!scanResults?.length) {
    const title = fm(messages.scan_missing);
    const details = fm(messages.scan_missing_details);

    const tooltipNode = (
      <>
        <Typography variant="h4" marginBottom={1}>
          {title}
        </Typography>
        <Typography variant="caption">{details}</Typography>
      </>
    );

    return (
      <StatusIndicator status={Status.Pending} tooltipNode={tooltipNode} />
    );
  }

  let tooltipNode;

  if (hasBypassHostCheck) {
    tooltipNode = (
      <Typography variant="caption">
        {fm(messages.bypass_host_check)}
      </Typography>
    );
  }

  if (
    scanResults.length === 1 &&
    scanResults[0].spec.status === ScanResultSpecStatus.Running
  ) {
    tooltipNode = (
      <Typography variant="caption">{fm(messages.scan_running)}</Typography>
    );
  }

  return <StatusIndicator status={Status.Success} tooltipNode={tooltipNode} />;
};
