import { Theme, Typography, useTheme } from '@mui/material';
import { isNumber } from 'lodash-es';
import { defineMessages, MessageDescriptor } from 'react-intl';

import {
  ReferenceReferenceType as VulnReferenceType,
  VulnSpecReference,
} from '@endorlabs/api_client';
import {
  isEqualReferenceType,
  VulnReferenceTypeValuesInverted,
} from '@endorlabs/endor-core/Finding';
import {
  ExternalLink,
  FlexList,
  FlexListItem,
  useStyles,
} from '@endorlabs/ui-common';

const VulnSpecReferenceTypeLabels = defineMessages<VulnReferenceType>({
  [VulnReferenceType.Unspecified]: { defaultMessage: 'Unspecified' },
  [VulnReferenceType.Web]: { defaultMessage: 'Web' },
  [VulnReferenceType.Advisory]: { defaultMessage: 'Advisory' },
  [VulnReferenceType.Report]: { defaultMessage: 'Report' },
  [VulnReferenceType.Fix]: { defaultMessage: 'Fix' },
  [VulnReferenceType.Package]: { defaultMessage: 'Package' },
  [VulnReferenceType.Article]: { defaultMessage: 'Article' },
});

const getVulnReferenceTypeMessage = (
  reference: VulnSpecReference
): MessageDescriptor => {
  let refType = reference.type;

  // handle numeric reference type
  if (
    isNumber(refType) &&
    Reflect.has(VulnReferenceTypeValuesInverted, refType)
  ) {
    refType = VulnReferenceTypeValuesInverted[refType];
  }

  return (
    VulnSpecReferenceTypeLabels[refType as VulnReferenceType] ??
    VulnSpecReferenceTypeLabels[VulnReferenceType.Unspecified]
  );
};

interface FindingDetailSecurityReferencesProps {
  direction?: 'column' | 'row';
  references?: VulnSpecReference[];
}

export const FindingDetailSecurityReferences = ({
  references = [],
}: FindingDetailSecurityReferencesProps) => {
  const sx = useStyles(styles);
  const { space } = useTheme();

  // exlude advisory references in this list
  const filteredReferences = references.filter(
    (ref) => !isEqualReferenceType(VulnReferenceType.Advisory, ref.type)
  );

  return (
    <FlexList className="FindingDetailSecurityReferences-root" gap={space.xs}>
      {filteredReferences.map((ref) => {
        return (
          <FlexListItem
            className="FindingDetailSecurityReferences-item"
            key={ref.url}
            sx={sx}
          >
            <Typography component="span">・</Typography>
            <ExternalLink sx={{ display: 'inline-block' }} to={ref.url}>
              {ref.url}
            </ExternalLink>
          </FlexListItem>
        );
      })}
    </FlexList>
  );
};

function styles({ typography }: Theme) {
  return {
    '&.FindingDetailSecurityReferences-item': {
      display: 'flex',
      flexWrap: 'nowrap',
      fontSize: typography.body2.fontSize ?? 'inherit',
      maxWidth: '100%',
    },
  };
}
