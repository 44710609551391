import { Box, Skeleton, Stack } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { Row } from '@tanstack/react-table';
import { forwardRef, MouseEvent, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1Ecosystem,
  V1PlatformSource,
  V1RepositoryVersion,
} from '@endorlabs/api_client';
import { isContainerProject } from '@endorlabs/endor-core/Project';
import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import {
  ProjectResource,
  QueryProjectsResponseObject,
} from '@endorlabs/queries';
import {
  DataTable,
  DataTableActionDropdown,
  DataTableColumnDef,
  DataTableColumnHeader,
  DataTableColumnTypeKeys as ColTypes,
  DataTableDrawerButton,
  DataTableProps,
  FindingCountDisplayProps,
  NilDisplay,
  NumberDisplay,
  PackageIconDisplay,
  ProjectNameDisplay,
  ScanResultStatusIndicator,
  UIEventUtils,
} from '@endorlabs/ui-common';

import { ContainerNameDisplay } from '../../domains/Containers';
import {
  FindingSourceSelect,
  FindingSourceSelectProps,
} from '../../domains/Findings';
import { ScanResultScannedByDisplay } from '../../domains/ScanResult';

type ProjectsTableRowProps = DataTableProps<ProjectsTableRow>;

export interface ProjectsTableProps
  extends Omit<ProjectsTableRowProps, 'columns' | 'onRowClick'> {
  findingSourceProps?: FindingSourceSelectProps;
  isLoadingFindings?: boolean;
  isLoadingRelated?: boolean;
  onDelete?: (event: MouseEvent, row: ProjectsTableRow) => void;
  onClickDetail?: (row?: ProjectsTableRow) => void;
}

export interface ProjectsTableRow {
  defaultRepositoryVersion?: V1RepositoryVersion;
  findingCounts?: FindingCountDisplayProps[];
  link: string;
  name: string;
  namespace: string;
  platformSource: V1PlatformSource;
  packagesList?: V1Ecosystem[];
  packagesCount?: number;
  project: ProjectResource | QueryProjectsResponseObject;
  scanResults: ScanResultResource[];
  tags?: string[];
  uuid: string;
  versionCount?: number;
}

export const buildProjectsTableColumnDefs = ({
  findingSourceProps,
  isLoadingRelated,
  onDelete,
  onClickDetail,
}: Partial<ProjectsTableProps>): DataTableColumnDef<ProjectsTableRow>[] => {
  const columns: DataTableColumnDef<ProjectsTableRow>[] = [
    {
      accessorKey: 'scanResults',
      cell: ({ getValue }) => (
        <ScanResultStatusIndicator scanResults={getValue()} />
      ),
      colType: ColTypes.STATUS_INDICATOR,
    },
    {
      accessorKey: 'name',
      cell: (t) => {
        const { project, packagesList } = t.row.original;

        if (isContainerProject(project, packagesList)) {
          return <ContainerNameDisplay name={project.meta.name} showIcon />;
        }

        return (
          <ProjectNameDisplay
            name={project.meta.name}
            platformSource={project.spec.platform_source}
            showIcon
          />
        );
      },
      colType: ColTypes.PROJECT,
      enableSorting: true,
      header: () => <FM defaultMessage="Project" />,
    },
    {
      id: 'processingStatus',
      colType: ColTypes.TEXT,
      cell: (t) =>
        isLoadingRelated ? (
          <Skeleton width="100%" />
        ) : (
          <ScanResultScannedByDisplay
            scanResult={t.row.original.scanResults[0]}
            showScanTime
          />
        ),
      header: () => (
        <DataTableColumnHeader
          label={<FM defaultMessage="Last Code Scan" />}
          helpTooltip={
            <FM defaultMessage="How long ago was the default branch scanned?" />
          }
        />
      ),
      minSize: 170,
    },
    {
      accessorKey: 'versionCount',
      cell: ({ row }) =>
        isLoadingRelated ? (
          <Skeleton width="100%" />
        ) : 'undefined' === typeof row.original?.versionCount ? (
          <NilDisplay fontSize="inherit" variant="text" />
        ) : (
          <NumberDisplay value={row.original.versionCount} />
        ),
      colType: ColTypes.NUMBER,
      enableSorting: true,
      header: () => <FM defaultMessage="Versions" />,
      // minSize: 90,
    },
    {
      accessorKey: 'packagesList',
      colType: ColTypes.ICONLIST,
      cell: ({ row }) => {
        const packagesList = row.original?.packagesList ?? [];
        return isLoadingRelated ? (
          <Skeleton width="100%" />
        ) : (
          <Stack direction="row" spacing={3}>
            <NumberDisplay value={row.original.packagesCount || 0} />
            <Stack direction="row" spacing={1} flexWrap="wrap">
              {packagesList.map((pEcosystem) => (
                <Box key={pEcosystem} mb={1}>
                  <PackageIconDisplay
                    ecosystem={pEcosystem}
                    displayDefault="package"
                    size={'small'}
                  />
                </Box>
              ))}
            </Stack>
          </Stack>
        );
      },
      header: () => <FM defaultMessage="Packages" />,
    },
    {
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
    },
    {
      accessorKey: 'findingCounts',
      colType: ColTypes.FINDING_COUNTS,
      header: () => <FindingSourceSelect {...findingSourceProps} />,
    },
    {
      accessorKey: 'tags',
      colType: ColTypes.TAGS,
    },
  ];

  if (onDelete) {
    columns.push({
      id: 'actions_dropdown',
      cell: ({ row }) =>
        row.original ? (
          <Box display="flex" justifyContent="end">
            <DataTableActionDropdown
              items={[
                {
                  isDestructive: true,
                  label: <FM defaultMessage="Delete Project" />,
                  onClick: (event) => {
                    event.stopPropagation();
                    onDelete(event, row.original as ProjectsTableRow);
                  },
                },
              ]}
            />
          </Box>
        ) : null,
      colType: ColTypes.ACTIONS,
      header: '',
    });
  }

  if (onClickDetail) {
    columns.push({
      id: 'actions_drawer',
      cell: ({ row }) =>
        row.original && (
          <Box display="flex" justifyContent="end">
            <DataTableDrawerButton
              onClick={(event) => {
                event.stopPropagation();
                onClickDetail(row.original);
              }}
            />
          </Box>
        ),
      colType: ColTypes.ACTIONS,
      header: '',
    });
  }
  return columns;
};

/**
 * A DataTable that displays a collection of Projects using a standardized set of attribute displays
 * NOTE: B/c DataTable contains Links, it must be displayed in a ReactLocation Router context.
 */
export const ProjectsTable = forwardRef(function PT(
  {
    findingSourceProps,
    isLoadingFindings,
    isLoadingRelated,
    onDelete,
    onClickDetail,
    ...props
  }: ProjectsTableProps,
  ref: any
) {
  const navigate = useNavigate();

  function handleRowClick(
    row: ProjectsTableRow,
    _: Row<ProjectsTableRow>,
    evt: MouseEvent
  ) {
    if (row.link) {
      return UIEventUtils.simulateLinkClick(row.link, evt, navigate);
    }

    if (onClickDetail) {
      return onClickDetail(row);
    }
  }

  const columns = useMemo(() => {
    return buildProjectsTableColumnDefs({
      findingSourceProps,
      isLoadingRelated,
      onDelete,
      onClickDetail,
    });
  }, [findingSourceProps, isLoadingRelated, onDelete, onClickDetail]);

  return (
    <DataTable
      ref={ref}
      {...props}
      customTableColProps={{ isCountsLoading: isLoadingFindings }}
      columns={columns}
      onRowClick={handleRowClick}
    />
  );
});
