import { Box, useTheme } from '@mui/material';
import { defaultsDeep as _defaultsDeep, uniq as _uniq } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PolicyResource } from '@endorlabs/queries';
import {
  ButtonSecondary,
  IconRefreshCw,
  RowStack,
  useAppNotify,
} from '@endorlabs/ui-common';
import { WithRequired } from '@endorlabs/utils';

import { useEditPolicyPage } from '../../hooks';
import { FormUpsertPolicyFieldValues, PolicyUmbrellaTypes } from '../../types';
import { getDefaultPolicy } from '../../utils';
import { FormUpsertPolicy, FormUpsertPolicyRef } from '../FormUpsertPolicy';
import { PolicyProjectsDrawer } from '../PolicyProjectsDrawer';
import { ExceptionPolicyCreateDialogProps } from './types';

// Template name to be used by default when creating exception policy from findings
const POLICY_TEMPLATE_NAME = 'Standard Exception Finding Attributes';

type ExceptionPolicyCreateDialogContentProps = WithRequired<
  ExceptionPolicyCreateDialogProps,
  'state'
>;

export const ExceptionPolicyCreateDialogContent = ({
  onClose,
  state,
}: ExceptionPolicyCreateDialogContentProps) => {
  const { namespace, findings = [], packageVersions = [] } = state;

  const { space } = useTheme();
  const addAppNotification = useAppNotify();

  const onFormError = useCallback(() => {
    addAppNotification({
      message: <FM defaultMessage="Problem Creating Exception Policy" />,
      severity: 'error',
    });
    onClose && onClose();
  }, [addAppNotification, onClose]);

  const onFormSuccess = useCallback(() => {
    addAppNotification({
      message: <FM defaultMessage="Exception Policy Created Successfully" />,
      severity: 'success',
    });
    onClose && onClose();
  }, [addAppNotification, onClose]);

  const { activePolicyTemplate, policyTemplates } = useEditPolicyPage({
    namespace,
    policyTemplateName: POLICY_TEMPLATE_NAME,
    policyUmbrellaType: PolicyUmbrellaTypes.EXCEPTION,
  });

  // Create a policy based on finding names & package version names
  const policy = useMemo(() => {
    // De-duplicate the values provided for finding names and package version names
    const findingNames = _uniq(
      findings.map((f) => f.meta.description).filter((v): v is string => !!v)
    );
    const packageVersionNames = _uniq(
      packageVersions.map((pv) => pv.meta.name).filter((v): v is string => !!v)
    );

    const defaultPolicy = getDefaultPolicy(PolicyUmbrellaTypes.EXCEPTION);
    return _defaultsDeep(
      {
        meta: { name: `Custom Exception Policy - ${findingNames[0]}` },
        spec: {
          template_uuid: activePolicyTemplate?.uuid,
          template_parameters:
            activePolicyTemplate?.spec?.template_parameters ?? [],
          // NOTE: This requires knowledge of the template parameter values
          template_values: {
            FindingName: { values: findingNames },
            PkgName: { values: packageVersionNames },
          },
        },
        tenant_meta: { namespace },
      },
      defaultPolicy
    );
  }, [activePolicyTemplate, findings, namespace, packageVersions]);

  const [workingPolicies, setWorkingPolicies] = useState<
    FormUpsertPolicyFieldValues[]
  >([]);

  // reset on input changes
  useEffect(() => {
    setWorkingPolicies([policy]);
  }, [policy]);

  const formStateRef = useRef<FormUpsertPolicyRef>(null);
  const handleRefresh = () => {
    const next = [];
    const values = formStateRef.current?.getValues();
    if (values) {
      next.push(values);
    }

    setWorkingPolicies(next);
  };

  return (
    <RowStack
      alignItems="flex-start"
      flexWrap="nowrap"
      gap={space.md}
      justifyContent="space-between"
      sx={{
        '& .FormUpsertPolicy-root': {
          flexGrow: 2,
          flexBasis: '65%',
          flexShrink: 0,
        },

        // Hide drawer affordances for the embeddded policy -> projects drawer
        '& .PolicyProjectsDrawerButton-root, & .InfoDrawerContent-close': {
          display: 'none',
        },

        // Remove the top padding to allow the refresh button
        '& .InfoDrawerContent-root > .MuiCardHeader-root': {
          paddingTop: 0,
        },
      }}
    >
      <FormUpsertPolicy
        ref={formStateRef}
        activeTemplate={activePolicyTemplate}
        onError={onFormError}
        onSuccess={onFormSuccess}
        policy={policy as unknown as PolicyResource}
        policyTemplates={policyTemplates}
        policyUmbrellaType={PolicyUmbrellaTypes.EXCEPTION}
      />

      <Box
        display="flex"
        flexDirection="column"
        flexBasis="30%"
        flexShrink={1}
        gap={2}
        sx={({ palette }) => ({ border: `1px solid ${palette.divider}` })}
      >
        <Box padding={4}>
          <ButtonSecondary
            onClick={handleRefresh}
            startIcon={<IconRefreshCw />}
            size="small"
          >
            <FM defaultMessage="Refresh affected Projects" />
          </ButtonSecondary>
        </Box>

        <PolicyProjectsDrawer policies={workingPolicies} />
      </Box>
    </RowStack>
  );
};
