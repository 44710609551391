import { Box } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  DataTable,
  DataTableActionDropdown,
  DataTableActionDropdownItem,
  DataTableColumnDef,
  DataTableProps,
  IconCheck,
} from '@endorlabs/ui-common';

type ProjectMonitoredVersionsTableRow = {
  uuid: string;
  name: string;
  sha?: string;
  isDefault?: boolean;
};

interface ProjectMonitoredVersionsTableProps
  extends Omit<DataTableProps<ProjectMonitoredVersionsTableRow>, 'columns'> {
  onDelete: (row: ProjectMonitoredVersionsTableRow) => void;
}

const buildProjectMonitoredVersionsTableColumms = ({
  onDelete,
}: Pick<ProjectMonitoredVersionsTableProps, 'onDelete'>) => {
  const columns: DataTableColumnDef<ProjectMonitoredVersionsTableRow>[] = [
    {
      accessorKey: 'name',
      header: () => <FM defaultMessage="Version" />,
    },
    {
      accessorKey: 'sha',
      header: () => <FM defaultMessage="SHA" />,
    },
    {
      accessorKey: 'isDefault',
      cell: ({ getValue }) => {
        const isDefault = getValue();
        return isDefault ? <IconCheck /> : null;
      },
      header: () => <FM defaultMessage="Default" />,
    },
  ];
  if (onDelete) {
    columns.push({
      id: 'actions',
      header: '',
      cell: ({ row }) => {
        // hide if no row data
        if (!row.original) {
          return null;
        }

        const { original } = row;
        const actions: DataTableActionDropdownItem[] = [];

        if (onDelete) {
          actions.push({
            isDestructive: true,
            label: <FM defaultMessage="Delete Repository Version" />,
            onClick: () => onDelete(original),
          });
        }

        return (
          <Box display="flex" justifyContent="flex-end">
            <DataTableActionDropdown items={actions} />
          </Box>
        );
      },
    });
  }

  return columns;
};

export const ProjectMonitoredVersionsTable = ({
  onDelete,
  ...rest
}: ProjectMonitoredVersionsTableProps) => {
  const columns = buildProjectMonitoredVersionsTableColumms({ onDelete });

  return <DataTable {...rest} columns={columns} />;
};
