import { useInfoDrawer } from '../../../../components/InfoDrawer';
import {
  RemediationDetailDrawer,
  RemediationDetailDrawerProps,
} from './RemediationDetailDrawer';

export interface RemediationDetailDrawerParams {
  namespace: string;
  upgradeUuid?: string;
}

interface PermalinkEffectProps {
  upgradeUuid?: string;
}

export const useRemediationDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    RemediationDetailDrawerProps,
    RemediationDetailDrawerProps
  >({
    drawerParams: ['namespace', 'upgradeUuid'],
    Component: RemediationDetailDrawer,
  });

  const permalinkEffect = ({ upgradeUuid }: PermalinkEffectProps) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();
    if (
      activeDrawerParams &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      DetailDrawer.activate(
        {
          namespace: activeDrawerParams.namespace,
          upgradeUuid: activeDrawerParams.upgradeUuid,
        },
        {
          namespace: activeDrawerParams.namespace ?? '',
          upgradeUuid,
        }
      );
    }
  };

  return { DetailDrawer, permalinkEffect };
};
