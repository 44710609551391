import _isEqual from 'lodash-es/isEqual';
import _partialRight from 'lodash-es/partialRight';
import { useEffect, useState } from 'react';
import { useQueries, useQueryClient, UseQueryOptions } from 'react-query';
import { QueryState } from 'react-query/types/core/query';

import {
  QueryServiceApi,
  V1ListParameters,
  V1PlatformSource,
  V1Query,
} from '@endorlabs/api_client';
import { isResourceWithContext, ResourceKind } from '@endorlabs/endor-core';
import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';
import {
  buildQueryCall,
  getClientConfiguration,
  useUserPreferences,
} from '@endorlabs/queries';
import { UIPackageVersionUtils, UIProjectUtils } from '@endorlabs/ui-common';

import { StaleTimes } from '../../../constants';
import {
  getDependencyPath,
  getPackageVersionPath,
  getProjectPath,
} from '../../../routes';

const STUB_QUERY_STATE: QueryState<V1Query> = {
  data: undefined,
  dataUpdateCount: 0,
  dataUpdatedAt: 0,
  error: null,
  errorUpdateCount: 0,
  errorUpdatedAt: 0,
  fetchFailureCount: 0,
  fetchMeta: undefined,
  isFetching: true,
  isInvalidated: false,
  isPaused: false,
  status: 'loading',
};

const apiService = () => new QueryServiceApi(getClientConfiguration());

// Polymorphic interface representing any of the minimal responses from primary record queries
interface PrimaryRecordData {
  meta: { name: string; parent_uuid: string };
  spec?: { platform_source?: V1PlatformSource };
  uuid: string;
  tenant_meta: { namespace: string };
}

// Attributes used to generate a query for a given dashboard request
interface DashboardQueryAttributes {
  aggregation_paths?: string[];
  filters?: FilterExpression[];
  mask?: string;
  count?: boolean;
  resourceKind: ResourceKind;
  unique_count_paths?: string[];
}

interface UseDashboardQueriesProps {
  namespace: string;
}

const sendQueryCall = async (
  namespace: string,
  query: V1Query,
  signal?: AbortSignal
) => {
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    signal,
  });
  return resp.data;
};

/**
 * Hook responsible for retrieving all data needed for dashboard.
 * Dynamically maintains a list of aggregation queries based on a subset of query attributes.
 * Query results have extended stale times.
 */
export const useDashboardQueries = ({
  namespace,
}: UseDashboardQueriesProps) => {
  const [enableQueries, setEnableQueries] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setEnableQueries(true));

    return () => {
      // Trigger cancellation of query API calls on unmount
      setEnableQueries(false);
      clearTimeout(timer);
    };
  }, []);

  const includeChildNamespaces = useUserPreferences(
    (s) => s.auth?.includeChildNamespaces
  );
  const queryClient = useQueryClient();

  // Internal state used to track dashboard requests and generate queries as needed
  const [queryAttrRegistry, setQueryAttrRegistry] = useState<
    DashboardQueryAttributes[]
  >([]);

  const getRegistryEntry = (attrs: DashboardQueryAttributes) => {
    return queryAttrRegistry.find((regAttrs) => _isEqual(attrs, regAttrs));
  };

  const addQueryToRegistry = (attrs: DashboardQueryAttributes) => {
    if (!getRegistryEntry(attrs)) {
      setQueryAttrRegistry(queryAttrRegistry.concat([attrs]));
    }
  };

  const attrsToListParams = (attrs: DashboardQueryAttributes) => {
    const defaultFilters: FilterExpression[] = [];

    // Ensure context.type is set to default for resources with context
    if (isResourceWithContext(attrs.resourceKind)) {
      defaultFilters.push(filterExpressionBuilders.mainResourceContext());
    }

    const combinedFilters = defaultFilters.concat(attrs.filters ?? []);
    const filter = combinedFilters.length
      ? filterExpressionBuilders.and(combinedFilters)
      : undefined;
    const group = attrs.aggregation_paths
      ? {
          aggregation_paths: attrs.aggregation_paths.join(','),
          unique_count_paths: attrs.unique_count_paths?.join(','),
        }
      : undefined;

    return {
      filter,
      group,
      mask: attrs.mask,
      count: attrs.count,
    };
  };

  const getQueryBuilder = (attrs: DashboardQueryAttributes) => {
    const overrides: V1ListParameters = {
      traverse: includeChildNamespaces,
    };
    const qb = buildQueryCall(attrs.resourceKind, attrsToListParams(attrs));
    return {
      getQueryKey: _partialRight(qb.getQueryKey, overrides),
      getBuiltQuery: _partialRight(qb.getBuiltQuery, overrides),
    };
  };

  const buildSingleQuery = (attrs: DashboardQueryAttributes) => {
    const qb = getQueryBuilder(attrs);

    const queryKey = qb.getQueryKey(namespace);
    const query = qb.getBuiltQuery(namespace);

    // TODO: Allow option overrides?
    return {
      enabled: enableQueries,
      queryFn: (ctx) => sendQueryCall(namespace, query, ctx.signal),
      queryKey: queryKey,
      staleTime: StaleTimes.EXTENDED,
    } satisfies UseQueryOptions<V1Query>;
  };

  // Ensure a query is registered for every combination of attrs requested
  // NOTE: This is the engine for everything
  useQueries(queryAttrRegistry.map(buildSingleQuery));

  const getQueryStatus = (attrs: DashboardQueryAttributes) => {
    const regEntry = getRegistryEntry(attrs);

    // If no query matches these attrs, initiate the query & return a temporary status
    if (!regEntry) {
      addQueryToRegistry(attrs);
      return STUB_QUERY_STATE;
    }

    const qb = getQueryBuilder(attrs);
    const queryKey = qb.getQueryKey(namespace);

    return queryClient.getQueryState<V1Query>(queryKey);
  };

  const getQueryData = (attrs: DashboardQueryAttributes) => {
    return getQueryStatus(attrs)?.data;
  };

  const getPrimaryRecordName = (
    resourceKind: ResourceKind,
    data: PrimaryRecordData
  ) => {
    switch (resourceKind) {
      case 'Project':
        return (
          UIProjectUtils.parseProjectName(
            data.meta.name,
            data.spec?.platform_source
          ) ?? data.meta.name
        );
      case 'PackageVersion':
        return UIPackageVersionUtils.stripEcosystemPrefix(data.meta.name);
      case 'DependencyMetadata':
        return UIPackageVersionUtils.stripEcosystemPrefix(data.meta.name);
      default:
        return data.meta.name;
    }
  };

  const getPrimaryRecordUrl = (
    resourceKind: ResourceKind,
    data: PrimaryRecordData
  ) => {
    const resourceNamespace = data.tenant_meta.namespace;
    switch (resourceKind) {
      case 'Project':
        return getProjectPath({
          tenantName: resourceNamespace,
          uuid: data.uuid,
        });

      case 'PackageVersion': {
        return getPackageVersionPath({
          tenantName: resourceNamespace,
          uuid: data.uuid,
        });
      }

      case 'DependencyMetadata':
        return getDependencyPath({
          tenantName: resourceNamespace,
          uuid: data.uuid,
        });

      default:
        return undefined;
    }
  };

  const getPrimaryRecords = (attrs: DashboardQueryAttributes) => {
    const data = getQueryData(attrs);

    const records: PrimaryRecordData[] =
      data?.spec?.query_response?.list?.objects ?? [];

    return records.map((r: PrimaryRecordData) => ({
      name: getPrimaryRecordName(attrs.resourceKind, r),
      uuid: r.uuid,
      url: getPrimaryRecordUrl(attrs.resourceKind, r),
    }));
  };

  return {
    addQuery: addQueryToRegistry,
    getPrimaryRecords,
    getQueryResult: getQueryData,
    getQueryStatus,
  };
};
