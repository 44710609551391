import { Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { CiCdToolEvidence } from '@endorlabs/api_client';
import { AttributeDisplay, ExternalLink } from '@endorlabs/ui-common';

export const ToolEvidenceAppsDisplay = ({
  evidences,
}: {
  evidences?: CiCdToolEvidence[];
}) => {
  const { spacing } = useTheme();
  if (!evidences) {
    return;
  }
  return (
    <Stack>
      {evidences.map((evidence, index) => {
        return (
          <Stack key={index} spacing={spacing(4)}>
            <Typography variant="h3">
              <FM
                defaultMessage="GitHub Application: {slug}"
                values={{
                  slug: evidence.app_slug,
                  id: evidence.app_id,
                }}
              />
            </Typography>
            <AttributeDisplay
              disableTypography
              heading={<FM defaultMessage="Repository URL" />}
              value={
                <ExternalLink to={evidence.app_repo_url}>
                  {evidence.app_repo_url}
                </ExternalLink>
              }
            />
            <AttributeDisplay
              disableTypography
              heading={<FM defaultMessage="Application Settings" />}
              value={
                <ExternalLink to={evidence.app_settings_url}>
                  {evidence.app_settings_url}
                </ExternalLink>
              }
            />
            <Stack spacing={spacing(2)}>
              <Typography variant="body1" fontWeight="bold">
                <FM defaultMessage="Application Permissions" />
              </Typography>
              {Object.entries(evidence.app_permissions ?? {}).map(
                ([key, value]) => (
                  <AttributeDisplay
                    key={key}
                    heading={key.replaceAll('_', ' ')}
                    value={value}
                    variant="row"
                    containerProps={{ justifyContent: 'space-between' }}
                    valueTypographyProps={{ textTransform: 'capitalize' }}
                  />
                )
              )}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
