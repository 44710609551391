import { SvgIcon, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { IconCheck, IconX } from '../../themes';

interface BooleanIconDisplayProps {
  colorize?: boolean;
  tooltip?: ReactNode;
  value: boolean;
  TruthyIconComponent?: typeof SvgIcon;
  FalsyIconComponent?: typeof SvgIcon;
}

/**
 * Presents a boolean value as icon, optionally with color applied
 */
export const BooleanIconDisplay = ({
  colorize = false,
  tooltip,
  value,
  TruthyIconComponent = IconCheck,
  FalsyIconComponent = IconX,
}: BooleanIconDisplayProps) => {
  const Icon = value ? TruthyIconComponent : FalsyIconComponent;

  const cls = clsx({
    colorize,
    isFalse: !value,
  });

  const display = (
    <Typography
      className={cls}
      sx={{
        '&.colorize.isFalse': {
          color: 'error.main',
        },
      }}
    >
      <Icon />
    </Typography>
  );

  if (!tooltip) return display;

  return <Tooltip title={tooltip}>{display}</Tooltip>;
};
