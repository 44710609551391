import {
  CircularProgress,
  Palette,
  SvgIconProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { JSXElementConstructor, useMemo } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';

import {
  ScanResultSpecStatus as Status,
  V1ScanState,
} from '@endorlabs/api_client';
import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import { ImportedSBOMResource } from '@endorlabs/queries';

import { useStyles } from '../../../../hooks';
import {
  IconCircleFilled,
  IconClock,
  IconSemiCircleFilled,
  IconSquareFilled,
  IconTriangleFilled,
} from '../../../../themes';

type SBOMProcessingStatusProps = {
  importedSBOM?: ImportedSBOMResource;
  scanResults?: ScanResultResource[];
};

const messages = defineMessages({
  scan_success: { defaultMessage: 'SBOM processed' },
  scan_partial_success: { defaultMessage: 'SBOM processed with some issues' },
  scan_failed: {
    defaultMessage:
      'Unable to process the SBOM. This project may have missing/incomplete data',
  },
  scan_pending: {
    defaultMessage: 'SBOM is processing',
  },
  scan_scheduled: {
    defaultMessage: 'SBOM has not been processed',
  },
  scan_unknown: {
    defaultMessage: 'No recent SBOM scan results found',
  },
});

const ColorForStatus: Record<Status, keyof Palette['status']> = {
  [Status.Failure]: 'failure',
  [Status.PartialSuccess]: 'partialSuccess',
  [Status.Success]: 'success',
  [Status.Unspecified]: 'pending',
};

const IconForStatus: Record<Status, JSXElementConstructor<SvgIconProps>> = {
  [Status.Failure]: IconTriangleFilled,
  [Status.PartialSuccess]: IconSemiCircleFilled,
  [Status.Success]: IconCircleFilled,
  [Status.Unspecified]: IconSquareFilled,
};

const MessageForStatus: Record<Status, MessageDescriptor> = {
  [Status.Failure]: messages.scan_failed,
  [Status.PartialSuccess]: messages.scan_partial_success,
  [Status.Success]: messages.scan_success,
  [Status.Unspecified]: messages.scan_unknown,
};

export const SBOMProcessingStatus = ({
  importedSBOM,
  scanResults,
}: SBOMProcessingStatusProps) => {
  const { formatMessage: fm } = useIntl();

  const processingScanState = importedSBOM?.processing_status?.scan_state;

  const scanStatus = useMemo(() => {
    return scanResults?.[0]?.spec.status ?? Status.Unspecified;
  }, [scanResults]);

  const Icon = IconForStatus[scanStatus];
  const iconStyles = useStyles(styles, { status: scanStatus });

  const isIngesting = processingScanState === V1ScanState.Ingesting;
  const isNotProcessed =
    processingScanState === V1ScanState.NotProcessed &&
    scanResults?.length === 0;

  // If no ImportedSBOM exists, consider as failed
  if (!importedSBOM) {
    return (
      <Tooltip title={fm(messages.scan_failed)}>
        <Icon sx={iconStyles} />
      </Tooltip>
    );
  }

  if (isIngesting) {
    return (
      <Tooltip title={fm(messages.scan_pending)}>
        <CircularProgress size="1.2rem" />
      </Tooltip>
    );
  }

  if (isNotProcessed) {
    return (
      <Tooltip title={fm(messages.scan_scheduled)}>
        <IconClock sx={iconStyles} />
      </Tooltip>
    );
  }

  // Else, show status from scan result
  return (
    <Tooltip title={fm(MessageForStatus[scanStatus])}>
      <Icon sx={iconStyles} />
    </Tooltip>
  );
};

function styles({ palette }: Theme, options: { status?: Status } = {}) {
  const { status = Status.Unspecified } = options;

  const statusColor = ColorForStatus[status];
  const iconColor = statusColor ? palette.status[statusColor] : undefined;

  return {
    color: iconColor,
    '&.MuiSvgIcon-root': {
      filter:
        status === Status.Unspecified
          ? undefined
          : 'drop-shadow( 0 0 4px hsla(0, 0%, 0%, 0.15) )',
    },
  };
}
