import { createTheme as createMuiTheme, Theme } from '@mui/material';

import { getAccordionOverrides } from './Accordions';
import { getAlertOverrides } from './Alerts';
import { getAutocompleteOverrides } from './Autocomplete';
import { getBreadcrumbOverrides } from './Breadcrumbs';
import { breakpoints } from './breakpoints';
import { getButtonOverrides } from './Buttons';
import { getCardOverrides } from './Cards';
import { getCheckboxOverrides } from './Checkboxes';
import { getChipOverrides } from './Chips';
import { getDialogOverrides } from './Dialogs';
import { getFormControlLabelOverrides } from './FormControlLabels';
import { getIconButtonOverrides } from './IconButtons';
import { getInputOverrides } from './Inputs';
import { getLinkOverrides } from './Links';
import { getPalette } from './palette';
import { getRadioOverrides } from './Radios';
import { getSelectOverrides } from './Selects';
import { shadows } from './shadows';
import { getSkeletonOverrides } from './Skeleton';
import { getSnackbarOverrides } from './Snackbars';
import { getSpacingOverrides } from './spacing';
import { getSvgIconOverrides } from './SvgIcons';
import { getSwitchOverrides } from './Switches';
import { getTableOverrides } from './Tables';
import { getTabsOverrides } from './Tabs';
import { getTextFieldOverrides } from './TextField';
import { getToggleButtonOverrides } from './ToggleButton';
import { getTooltipOverrides } from './Tooltips';
import { FontStacks, getTypographyOverrides } from './typography';

export const ThemeFontStacks = FontStacks;
export * from './constants';

/**
 * Generate overrides, providing a theme to be used within the overrides themselves
 */
const genComponentOverrides = (theme: Theme) => ({
  ...getSpacingOverrides(theme),

  components: {
    ...getAccordionOverrides(theme),
    ...getAlertOverrides(theme),
    ...getAutocompleteOverrides(),
    ...getBreadcrumbOverrides(theme),
    ...getButtonOverrides(theme),
    ...getCardOverrides(theme),
    ...getCheckboxOverrides(theme),
    ...getChipOverrides(theme),
    ...getDialogOverrides(theme),
    ...getFormControlLabelOverrides(theme),
    ...getIconButtonOverrides(theme),
    ...getInputOverrides(theme),
    ...getLinkOverrides(),
    ...getRadioOverrides(theme),
    ...getSelectOverrides(theme),
    ...getSkeletonOverrides(),
    ...getSnackbarOverrides(theme),
    ...getSvgIconOverrides(),
    ...getSwitchOverrides(theme),
    ...getTableOverrides(theme),
    ...getTabsOverrides(theme),
    ...getTextFieldOverrides(),
    ...getToggleButtonOverrides(theme),
    ...getTooltipOverrides(theme),
  },
});

export const getTheme = (themeMode: 'light' | 'dark' = 'light'): Theme => {
  // Create a theme with complete palette so we can use it in typography
  const { palette } = createMuiTheme({ palette: getPalette(themeMode) });

  // Create a base theme so that component overrides have access to correct typography, palette, etc.
  const baseTheme = createMuiTheme({
    breakpoints,
    palette,
    shadows,
    spacing: 4,
    typography: getTypographyOverrides({ breakpoints, palette }),
  });

  // Generate component overrides using our base theme
  return createMuiTheme(baseTheme, genComponentOverrides(baseTheme));
};
