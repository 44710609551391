import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  PackageVersionResource,
  useCountDependencyMetadata,
  useCountFindings,
  useCountVersionUpgrade,
} from '@endorlabs/queries';
import { LinkTabCountValue, LinkTabProps } from '@endorlabs/ui-common';

interface PackageVersionDetailTabRecord extends LinkTabProps {
  value: (typeof PackageVersionDetailTabName)[keyof typeof PackageVersionDetailTabName];
}

export const PackageVersionDetailTabName = {
  OVERVIEW: 'overview',
  DEPENDENCIES: 'dependencies',
  DEPENDENTS: 'dependents',
  CALL_STACK: 'call-stack',
  FINDINGS: 'findings',
  COMPARE: 'compare',
  REMEDIATIONS: 'remediations',
  DEP_GRAPH: 'dep-graph',
  LAYERS: 'container-layers',
} as const;

const buildTabRecords = (props: {
  isLoading: boolean;
  // we don't _need_ the type of the data, only the count
  dependenciesCount?: number | LinkTabCountValue;
  dependentsCount?: number;
  findingsCount?: number;
  packageVersion?: PackageVersionResource;
  remediationsCount?: number;
}) => {
  const tabRecords: PackageVersionDetailTabRecord[] = [
    {
      label: <FM defaultMessage="Overview" />,
      to: PackageVersionDetailTabName.OVERVIEW,
      value: PackageVersionDetailTabName.OVERVIEW,
    },
    {
      label: <FM defaultMessage="Findings" />,
      to: PackageVersionDetailTabName.FINDINGS,
      value: PackageVersionDetailTabName.FINDINGS,
      count: props.findingsCount,
      isLoading: props.isLoading,
    },
    {
      label: <FM defaultMessage="Dependencies" />,
      to: PackageVersionDetailTabName.DEPENDENCIES,
      value: PackageVersionDetailTabName.DEPENDENCIES,
      count: props.dependenciesCount,
      isLoading: props.isLoading,
    },
    {
      label: <FM defaultMessage="Dependents" />,
      to: PackageVersionDetailTabName.DEPENDENTS,
      value: PackageVersionDetailTabName.DEPENDENTS,
      count: props.dependentsCount,
      isLoading: props.isLoading,
    },
    {
      label: <FM defaultMessage="Remediations" />,
      to: PackageVersionDetailTabName.REMEDIATIONS,
      value: PackageVersionDetailTabName.REMEDIATIONS,
      count: props.remediationsCount,
      isLoading: props.isLoading,
      isBeta: true,
    },
    // {
    //   label: <FM defaultMessage="Call Stack" />,
    //   to: PackageVersionDetailTabName.CALL_STACK,
    //   value: PackageVersionDetailTabName.CALL_STACK,
    // },
    // {
    //   label: <FM defaultMessage="Compare" />,
    //   to: PackageVersionDetailTabName.COMPARE,
    //   value: PackageVersionDetailTabName.COMPARE,
    // },
    {
      label: <FM defaultMessage="Dependency Graph" />,
      to: PackageVersionDetailTabName.DEP_GRAPH,
      value: PackageVersionDetailTabName.DEP_GRAPH,
    },
  ];

  if (props.packageVersion?.spec.ecosystem === V1Ecosystem.Container) {
    tabRecords.push({
      label: <FM defaultMessage="Container Layers" />,
      to: PackageVersionDetailTabName.LAYERS,
      value: PackageVersionDetailTabName.LAYERS,
    });
  }

  return tabRecords;
};

const usePackageVersionDetailCounts = (
  packageVersion?: PackageVersionResource
) => {
  const packageVersionResourceContext =
    filterExpressionBuilders.relatedResourceContext(packageVersion);

  const tenantName = packageVersion?.tenant_meta.namespace ?? '';
  const qCountPackageVersionDependencies = useCountDependencyMetadata(
    tenantName,
    {
      enabled: !!packageVersion,
    },
    {
      filter: [
        `spec.importer_data.package_version_uuid==${packageVersion?.uuid}`,
        filterExpressionBuilders.defaultResourceContexts(),
      ].join(' and '),
    }
  );

  const qCountPackageVersionDependents = useCountDependencyMetadata(
    tenantName,
    {
      enabled: !!packageVersion,
    },
    {
      filter: [
        `spec.dependency_data.package_version_uuid==${packageVersion?.uuid}`,
        filterExpressionBuilders.defaultResourceContexts(),
      ].join(' and '),
    }
  );

  const qCountPackageVersionFindings = useCountFindings(
    tenantName,
    {
      enabled: !!packageVersion,
    },
    {
      filter: [
        `meta.parent_kind==PackageVersion`,
        `meta.parent_uuid==${packageVersion?.uuid}`,
        packageVersionResourceContext,
      ].join(' and '),
    }
  );

  const qCountRemediations = useCountVersionUpgrade(
    tenantName,
    {
      filter: filterExpressionBuilders.and([
        `meta.name==PrioritizedPerPackage`,
        `spec.upgrade_info exists`,
        `meta.parent_uuid==${packageVersion?.uuid}`,
        filterExpressionBuilders.or([
          'spec.upgrade_info.vuln_finding_info.reduction > 0',
          'spec.upgrade_info.other_finding_info.reduction > 0',
        ]),
      ]),
    },
    {
      enabled: !!packageVersion,
    }
  );

  const isLoading =
    !packageVersion ||
    qCountPackageVersionDependencies.isLoading ||
    qCountPackageVersionDependents.isLoading ||
    qCountPackageVersionFindings.isLoading ||
    qCountRemediations.isLoading;

  const dependenciesCount = qCountPackageVersionDependencies.data?.count ?? 0;
  const dependentsCount = qCountPackageVersionDependents.data?.count ?? 0;
  const findingsCount = qCountPackageVersionFindings.data?.count ?? 0;
  const remediationsCount = qCountRemediations.data?.count ?? 0;

  return {
    isLoading,
    dependenciesCount,
    dependentsCount,
    findingsCount,
    remediationsCount,
  };
};

export interface UsePackageVersionDetailTabsProps {
  activeTab: string;
  packageVersion?: PackageVersionResource;
}

export const usePackageVersionDetailTabs = ({
  activeTab: targetActiveTab,
  packageVersion,
}: UsePackageVersionDetailTabsProps) => {
  const packageVersionDetailCounts =
    usePackageVersionDetailCounts(packageVersion);

  const tabRecords = buildTabRecords({
    packageVersion,
    ...packageVersionDetailCounts,
  });

  const activeTab =
    tabRecords.find((r) => r.value === targetActiveTab)?.value ??
    PackageVersionDetailTabName.FINDINGS;

  return { activeTab, tabRecords };
};
