import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  DataTable,
  DataTableColumnDef,
  DataTableProps,
  RowStack,
  ToolLogoDisplay,
} from '@endorlabs/ui-common';

export interface ProjectCiCdToolsTableRow {
  tool: string;
  categories?: string[];
}

const buildProjectCiCdToolsTableColumns =
  (): DataTableColumnDef<ProjectCiCdToolsTableRow>[] => [
    {
      accessorKey: 'tool',
      header: () => (
        <Typography variant="body2" fontWeight={500}>
          <FM defaultMessage="Tool" />
        </Typography>
      ),
      cell: (t) => (
        <RowStack gap={1}>
          <ToolLogoDisplay toolName={t.getValue()} />
          <Typography variant="body2" fontWeight={500}>
            {t.getValue()}
          </Typography>
        </RowStack>
      ),
    },
    {
      accessorKey: 'categories',
      header: () => (
        <Typography variant="body2" fontWeight={500}>
          <FM defaultMessage="Categories" />
        </Typography>
      ),
      cell: (t) => (
        <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
          {t.getValue().join(', ')}
        </Typography>
      ),
    },
  ];

type ProjectCiCdToolsTableProps = Omit<
  DataTableProps<ProjectCiCdToolsTableRow>,
  'columns'
>;

export const ProjectCiCdToolsTable = (props: ProjectCiCdToolsTableProps) => {
  const sx = styles();
  const columns = useMemo(() => buildProjectCiCdToolsTableColumns(), []);
  return (
    <Box sx={sx} textAlign="right">
      <DataTable
        columns={columns}
        emptyStateProps={{
          title: <FM defaultMessage="No tools found" />,
          imageWidth: 300,
        }}
        {...props}
      />
    </Box>
  );
};
const styles = () => ({
  ' .MuiTableContainer-root': {
    borderBottom: 0,
  },

  ' .MuiTableBody-root .MuiTableRow-root:last-of-type': {
    '.MuiTableCell-root': {
      borderBottom: 0,
    },
  },

  ' .MuiTableCell-root': {
    padding: '8px',
  },
});
