import { createSvgIcon } from '@mui/material';

import Artifacts from '../../../assets/artifacts/artifact-registry';
import ArtifactTypeContainerIcon from '../../../assets/artifacts/artifact-type-container.svg';
import ArtifactTypeJarIcon from '../../../assets/artifacts/artifact-type-jar.svg';
import createImgIcon from './createImgIcon';

export const IconArtifactRegistry = createSvgIcon(
  <Artifacts />,
  'ArtifactRegistryIcon'
);

export const IconArtifactTypeContainer = createImgIcon(
  ArtifactTypeContainerIcon,
  'ArtifactTypeContainerIcon'
);

export const IconArtifactTypeJar = createImgIcon(
  ArtifactTypeJarIcon,
  'ArtifactTypeJarIcon'
);
