import { pick as _pick } from 'lodash-es';
import { QueryKey, useQuery } from 'react-query';

import {
  QueryJoinFilters,
  QueryServiceApi,
  ScanResultSpecType,
  SpecEnvironment,
  V1ListParameters,
  V1Meta,
  V1Project,
  V1ProjectSpec,
  V1Query,
  V1RepositoryVersion,
  V1RepositoryVersionSpec,
  V1ScanResult,
  V1ScanResultSpec,
} from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { SelectFrom } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import { ResourceCountResponse, TResourceList } from './types';
import { getClientConfiguration, sortParamBuilders } from './utils';

// Resource Kinds included in the Project Query `with` clause
const REFERENCES_WITH_CONTEXT: ResourceKind[] = [
  'RepositoryVersion',
  'DependencyMetadata',
  'Finding',
  'Metric',
];

export const QueryProjectsQueryKeys = {
  query: (
    namespace: string,
    listParams: V1ListParameters,
    filters: QueryJoinFilters[],
    includeReferences = false
  ): QueryKey => [
    'v1/queries',
    namespace,
    'projects',
    { listParams, filters, includeReferences },
  ],
};

export type QueryProjectsResponse = TResourceList<QueryProjectsResponseObject>;

export type QueryProjectsResponseObject = SelectFrom<
  V1Project,
  'uuid' | 'tenant_meta' | 'processing_status',
  {
    spec: SelectFrom<V1ProjectSpec, 'platform_source'>;
    meta: SelectFrom<
      V1Meta,
      'name' | 'tags',
      {
        references: {
          RepositoryVersionCount?: ResourceCountResponse;
          DefaultRepositoryVersion?: QueryProjectDefaultRepositoryVersion;
          LatestScanResult?: QueryProjectScanResultResponse;
        };
      }
    >;
  }
>;

type QueryProjectDefaultRepositoryVersion = TResourceList<
  SelectFrom<
    V1RepositoryVersion,
    'uuid' | 'scan_object',
    {
      meta: SelectFrom<V1Meta, 'name'>;
      spec: SelectFrom<V1RepositoryVersionSpec, 'version'>;
      context: never;
    }
  >
>;

type QueryProjectScanResultResponse = TResourceList<
  SelectFrom<
    V1ScanResult,
    'context' | 'uuid',
    {
      spec: SelectFrom<
        V1ScanResultSpec,
        'exit_code' | 'status' | 'type',
        { environment: SelectFrom<SpecEnvironment, 'config'> }
      >;
    }
  >
>;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuery = (
  namespace: string,
  rootListParams: V1ListParameters,
  filters: QueryJoinFilters[],
  includeReferences: boolean
): V1Query => {
  const commonListParameters = _pick(rootListParams, ['traverse']);

  const projectFilter = filters.find((f) => f.kind === 'Project')?.filter;
  // For query join filters, ensure that context is set
  const queryJoinFilters = filters
    .filter((f) => f.kind !== 'Project')
    .map((f) => {
      if (REFERENCES_WITH_CONTEXT.some((kind) => kind === f.kind)) {
        return {
          ...f,
          filter: [
            filterExpressionBuilders.mainResourceContext(),
            `(${f.filter})`,
          ].join(' and '),
        };
      }

      return f;
    });

  return {
    meta: {
      name: `QueryProjects(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'Project',
        list_parameters: {
          ...rootListParams,
          filter: projectFilter,
          mask: [
            'uuid',
            'meta.name',
            'meta.tags',
            'processing_status',
            'spec.platform_source',
            'tenant_meta',
          ].join(),
        },
        with: queryJoinFilters,
        references: includeReferences
          ? [
              {
                connect_from: 'uuid',
                connect_to: 'meta.parent_uuid',
                query_spec: {
                  kind: 'RepositoryVersion',
                  return_as: 'RepositoryVersionCount',
                  list_parameters: {
                    ...commonListParameters,
                    count: true,
                    filter:
                      filterExpressionBuilders.defaultResourceContexts(
                        namespace
                      ),
                  },
                },
              },
              {
                connect_from: 'uuid',
                connect_to: 'meta.parent_uuid',
                query_spec: {
                  kind: 'RepositoryVersion',
                  return_as: 'DefaultRepositoryVersion',
                  list_parameters: {
                    ...commonListParameters,
                    filter: filterExpressionBuilders.mainResourceContext(),
                    mask: [
                      'uuid',
                      'meta.name',
                      'scan_object',
                      'spec.version',
                    ].join(','),
                  },
                },
              },
              {
                connect_from: 'uuid',
                connect_to: 'meta.parent_uuid',
                query_spec: {
                  kind: 'ScanResult',
                  return_as: 'LatestScanResult',
                  list_parameters: {
                    ...commonListParameters,
                    filter: [
                      `spec.type==${ScanResultSpecType.AllScans}`,
                      filterExpressionBuilders.mainResourceContext(),
                    ].join(' and '),
                    sort: sortParamBuilders.descendingBy('meta.create_time'),
                    page_size: 1,
                    mask: [
                      'context',
                      'uuid',
                      'meta.create_time',
                      'spec.exit_code',
                      'spec.status',
                      'spec.type',
                      'spec.environment.config',
                      'spec.end_time',
                    ].join(','),
                  },
                },
              },
            ]
          : [],
      },
    },
    tenant_meta: { namespace },
  };
};

const queryProjects = async (
  namespace: string,
  listParams: V1ListParameters,
  filters: QueryJoinFilters[],
  includeReferences: boolean,
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams, filters, includeReferences);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryProjectsResponse;
};

/**
 * Custom query for the Projects page
 */
export const useQueryProjects = (
  namespace: string,
  // filters should be passed in formatted `QueryJoinFilters` rather
  // than as part of the list parameters object
  // list mask is set by the query spec
  listParams: Omit<ListRequestParameters, 'filter' | 'mask'> = {},
  queryOptions?: {
    filters: QueryJoinFilters[];
    includeReferences?: boolean;
  }
) => {
  const { filters = [], includeReferences = false } = queryOptions ?? {};
  const requestParameters = useBuildReadRequestParameters(
    'Project',
    'LIST',
    listParams
  );

  return useQuery(
    QueryProjectsQueryKeys.query(
      namespace,
      requestParameters,
      filters,
      includeReferences
    ),
    async ({ signal }) =>
      queryProjects(
        namespace,
        requestParameters,
        filters,
        includeReferences,
        signal
      ),
    { staleTime: Infinity }
  );
};
