import { useNavigate } from '@tanstack/react-location';
import { Row } from '@tanstack/react-table';
import { MouseEvent, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1ArtifactType } from '@endorlabs/api_client';
import {
  ArtifactNameDisplay,
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  UIEventUtils,
} from '@endorlabs/ui-common';

import { getArtifactDetailPath } from '../../routes';

export interface ArtifactSignaturesTableProps
  extends Omit<DataTableProps<ArtifactSignaturesTableRow>, 'columns'> {
  namespace?: string;
}

export const ArtifactSignaturesTable = ({
  namespace,

  ...props
}: ArtifactSignaturesTableProps) => {
  const { formatMessage: fm } = useIntl();

  const navigate = useNavigate();

  function handleRowClick(
    row: ArtifactSignaturesTableRow,
    _: Row<ArtifactSignaturesTableRow>,
    evt: MouseEvent
  ) {
    const url = getArtifactDetailPath({
      tenantName: namespace ?? '',
      artifactName: encodeURIComponent(row.fullName),
    });
    UIEventUtils.simulateLinkClick(url, evt, navigate);
  }

  const columns = useMemo(() => buildArtifactSignaturesTableColDefs(), []);

  return (
    <DataTable
      {...props}
      columns={columns}
      enablePagination
      onRowClick={handleRowClick}
    />
  );
};

export interface ArtifactSignaturesTableRow {
  fullName: string;
  name: string;
  createTime: string;
  updateTime: string;
  artifactType: V1ArtifactType;
}

/**
 * Build the Table columns
 * Allow for artifact dismissal, navigation to policy, and opening the detail drawer to learn more about artifact findings
 */
const buildArtifactSignaturesTableColDefs = () => {
  const columns: DataTableColumnDef<ArtifactSignaturesTableRow>[] = [
    {
      accessorKey: 'name',
      // colType: ColTypes.TEXT,
      cell: ({ row }) => {
        return (
          <ArtifactNameDisplay
            name={row.original.name}
            type={row.original.artifactType}
            fullName={row.original.fullName}
          />
        );
      },
      header: () => <FM defaultMessage="Name" />,
      enableSorting: true,
    },
    {
      accessorKey: 'createTime',
      colType: ColTypes.DATE_TIME,
      header: () => <FM defaultMessage="Created" />,
      enableSorting: true,
    },
    {
      accessorKey: 'updateTime',
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Last Updated" />,
      enableSorting: true,
    },
  ];

  return columns;
};
