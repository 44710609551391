import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { useCallback, useMemo } from 'react';
import { FormattedMessage as FM, FormattedNumber, useIntl } from 'react-intl';

import { SpecFindingLevel, V1Method } from '@endorlabs/api_client';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import { ValueFilter } from '@endorlabs/filters';
import {
  Color,
  Colors,
  CurrencySymbolDisplay,
  FindingLevelMessages,
  IconClock,
  IconEdit,
  IconMoreVertical,
  Link,
  StackedAreaChart,
  UIFindingUtils,
  useDialog,
} from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { getFindingsPath } from '../../../routes';
import {
  DashboardConfigValue,
  useDashboardConfigValue,
  useVulnerabilityPrioritization,
  VulnerabilityPrioritizationStage,
  VulnerabilityPrioritizationStageValue,
} from '../hooks';
import {
  DashboardConfigDialog,
  DashboardConfigDialogState,
} from './DashboardConfigDialog';
import { FormDashboardConfigValueFieldset } from './FormDashboardConfigValue';

export type DashboardVulnerabilityPrioritizationProps = {
  findingLevels: SpecFindingLevel[];
  namespace: string;
};

export const DashboardVulnerabilityPrioritization = ({
  findingLevels,
  namespace,
}: DashboardVulnerabilityPrioritizationProps) => {
  const { formatNumber, formatMessage: fm } = useIntl();
  const { space, palette } = useTheme();
  const navigate = useNavigate();

  const { checkActivePermission } = useAuthInfo();
  const canEditDashboardConfig = checkActivePermission(
    V1Method.Create,
    'DashboardConfig'
  );

  const dashboardConfigDialog = useDialog<DashboardConfigDialogState>({
    component: DashboardConfigDialog,
  });

  const dashboardConfigDialogHandler =
    (fieldset: FormDashboardConfigValueFieldset) => () => {
      const handleSubmit = (partial: Partial<DashboardConfigValue>) => {
        dashboardConfigDialog.update({ isLoading: true });

        updateConfig(
          { ...config, ...partial },
          {
            onSuccess: () => dashboardConfigDialog.closeDialog(),
            onError: () => dashboardConfigDialog.update({ isLoading: false }),
          }
        );
      };

      dashboardConfigDialog.openDialog({
        config,
        onSubmit: handleSubmit,
        fieldsets: [fieldset],
        isLoading: false,
      });
    };

  const {
    config,
    update: updateConfig,
    isLoading: isLoadingConfig,
  } = useDashboardConfigValue({
    namespace,
  });

  const {
    stages,
    devHoursSaved,
    costSaved,
    isLoading: isLoadingVulnerabilityPrioritization,
  } = useVulnerabilityPrioritization({
    config,
    enabled: !isLoadingConfig,
    findingLevels,
    namespace,
    onEditEpssThreshold: canEditDashboardConfig
      ? dashboardConfigDialogHandler('EPSS_THRESHOLD')
      : undefined,
    onEditFindingReachability: canEditDashboardConfig
      ? dashboardConfigDialogHandler('FINDING_REACHABILITY')
      : undefined,
  });

  const chartProps = useMemo(() => {
    const sortedFindingLevels = FINDING_LEVELS.filter((l) =>
      findingLevels.some((ll) => l === ll)
    );

    // Filter the chart colors to the selected finding levels
    const colors = sortedFindingLevels.map((level) =>
      UIFindingUtils.getSeverityColor(level, { palette })
    );

    // Filter the chart data to the selected finding levels
    const data = stages.map(({ label, value }) => {
      const data = { label } as VulnerabilityPrioritizationStageValue & {
        label: string;
      };
      for (const level of findingLevels) {
        data[level] = value[level];
      }
      return data;
    });

    return {
      colors,
      data,
      yKeys: sortedFindingLevels,
    };
  }, [findingLevels, palette, stages]);

  const getValueFormat = (value: string | number, key?: string | number) => {
    if ('number' !== typeof value || !key) return value.toString();

    const formattedValue = formatNumber(value, {
      notation: 'compact',
    });

    // Format finding level keys
    if (key in FindingLevelMessages) {
      key = fm(FindingLevelMessages[key as keyof typeof FindingLevelMessages]);
    }

    return `${key}: ${formattedValue}`;
  };

  const getStageDeepLink = useCallback(
    (
      stage: VulnerabilityPrioritizationStage,
      findingLevel?: SpecFindingLevel
    ) => {
      const filterValues: ValueFilter[] = [...(stage?.filters ?? [])];

      if (findingLevel) {
        filterValues.push({
          key: 'spec.level',
          comparator: 'IN',
          value: [findingLevel],
        });
      }

      return getFindingsPath({
        tenantName: namespace,
        section: 'dependency',
        filterValues,
      });
    },
    [namespace]
  );

  const handleChartClick = useCallback(
    (data: { label: string }, key?: string | number) => {
      const stage = stages.find((s) => s.label === data.label);

      if (!stage) return;
      const findingLevel = FINDING_LEVELS.find((l) => l === key);

      const path = getStageDeepLink(stage, findingLevel);
      navigate({ to: path });
    },
    [getStageDeepLink, navigate, stages]
  );

  return (
    <Grid container spacing={space.md}>
      <dashboardConfigDialog.Dialog {...dashboardConfigDialog.dialogProps} />

      <Grid item xl={8} xs={12}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'visible', // HACK: prevent cutting off tooltip overlay at bottom of chart
            '& .MuiCardContent-root': {
              flexGrow: 1,
            },
          }}
        >
          <CardHeader
            title={<FM defaultMessage="Vulnerability Prioritization Funnel" />}
            titleTypographyProps={{
              variant: 'h2',
            }}
          />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Stack direction="row" gap={space.xs}>
              {stages.map((s) => (
                <Stack
                  direction="column"
                  spacing={space.sm}
                  width="20%"
                  key={s.key}
                  marginRight="1px"
                >
                  <Stack direction="column" gap={space.xs} flexGrow={1}>
                    {s.isLoading ? (
                      <Skeleton height={24} variant="rounded" width={64} />
                    ) : (
                      <Typography
                        variant="xl"
                        textAlign="left"
                        fontSize="1.5rem"
                        component={Link}
                        color="text.primary"
                        to={getStageDeepLink(s)}
                        sx={{
                          '&:hover': { textDecoration: 'none' },
                        }}
                      >
                        <FormattedNumber
                          notation="compact"
                          maximumFractionDigits={1}
                          maximumSignificantDigits={3}
                          value={s.total}
                        />
                      </Typography>
                    )}

                    <Typography
                      variant="h6"
                      textAlign="left"
                      color="text.secondary"
                    >
                      {s.label}
                    </Typography>

                    <Stack direction="row" gap={space.xs} flexWrap="wrap">
                      {s.caption && (
                        <Chip
                          variant="outlined"
                          label={s.caption}
                          size="small"
                          sx={{
                            color: 'text.secondary',
                          }}
                        />
                      )}

                      {s.actions?.map((action, index) => (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={action.label}
                          icon={action.onClick ? <IconEdit /> : undefined}
                          onClick={action.onClick}
                          size="small"
                          sx={{
                            color: 'text.secondary',
                            paddingLeft: 1,
                            '& .MuiChip-icon': {
                              color: 'text.secondary',
                              fontSize: '1rem',
                            },
                          }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>

            <Box height={120}>
              <ParentSize debounceTime={100}>
                {({ width: visWidth, height: visHeight }) => (
                  <StackedAreaChart
                    axisBottom={false}
                    axisColor={Colors.BLACK}
                    axisLeft={false}
                    axisRight={false}
                    axisTop={false}
                    areaColors={
                      (isLoadingConfig || isLoadingVulnerabilityPrioritization
                        ? [palette.status.pending]
                        : chartProps.colors) as Color[]
                    }
                    data={chartProps.data}
                    width={visWidth}
                    height={visHeight}
                    valueFormat={getValueFormat}
                    margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    yKeys={chartProps.yKeys}
                    xKey="label"
                    onClickHandler={handleChartClick}
                  />
                )}
              </ParentSize>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xl={4} xs={12}>
        <Grid container spacing={space.md}>
          <Grid item xl={12} xs={6}>
            <Card>
              <CardHeader
                title={<FM defaultMessage="Dev Hours Saved" />}
                titleTypographyProps={{
                  component: 'h3',
                  variant: 'h2',
                }}
                action={
                  canEditDashboardConfig && (
                    <IconButton
                      disabled={isLoadingVulnerabilityPrioritization}
                      onClick={dashboardConfigDialogHandler('DEV_ESTIMATE')}
                    >
                      <IconMoreVertical />
                    </IconButton>
                  )
                }
              />
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={space.xs}
                  sx={{
                    // NOTE: matching color in figma "Color/Black Text 03"
                    color: palette.text.tertiary,
                  }}
                >
                  <IconClock
                    fontSize="medium"
                    htmlColor={palette.text.secondary}
                  />

                  <Typography variant="xxl" textAlign="left">
                    {isLoadingConfig || isLoadingVulnerabilityPrioritization ? (
                      <Skeleton
                        variant="text"
                        sx={{ transform: 'scale(1)' }}
                        width={120}
                      />
                    ) : (
                      <FormattedNumber
                        notation="compact"
                        maximumFractionDigits={1}
                        maximumSignificantDigits={3}
                        value={devHoursSaved}
                      />
                    )}
                  </Typography>

                  <Typography alignSelf="end">
                    <FM defaultMessage="hours" />
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xl={12} xs={6}>
            <Card>
              <CardHeader
                title={<FM defaultMessage="Cost Saved" />}
                titleTypographyProps={{
                  component: 'h3',
                  variant: 'h2',
                }}
                action={
                  canEditDashboardConfig && (
                    <IconButton
                      disabled={isLoadingConfig}
                      onClick={dashboardConfigDialogHandler('COST_ESTIMATE')}
                    >
                      <IconMoreVertical />
                    </IconButton>
                  )
                }
              />
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={space.xs}
                  sx={{
                    // NOTE: matching color in figma "Color/Black Text 03"
                    color: palette.text.tertiary,
                  }}
                >
                  <CurrencySymbolDisplay
                    value={config.currencyCode}
                    size="medium"
                  />

                  <Typography variant="xxl" textAlign="left">
                    {isLoadingConfig || isLoadingVulnerabilityPrioritization ? (
                      <Skeleton
                        variant="text"
                        sx={{ transform: 'scale(1)' }}
                        width={160}
                      />
                    ) : (
                      <FormattedNumber
                        notation="compact"
                        maximumFractionDigits={1}
                        maximumSignificantDigits={3}
                        value={costSaved}
                      />
                    )}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
