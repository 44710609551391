import { uniq as _uniq } from 'lodash-es';
import { useMemo } from 'react';

import { FilterExpression } from '@endorlabs/filters';
import {
  sortParamBuilders,
  useListFindings,
  useListPackageVersions,
} from '@endorlabs/queries';
import { DataTablePaginator } from '@endorlabs/ui-common';

import { FilterState } from '../../filters';

const FindingsMask = [
  'meta',
  'spec.dependency_file_paths',
  'spec.finding_categories',
  'spec.finding_metadata.vulnerability',
  'spec.finding_tags',
  'spec.level',
  'spec.target_dependency_package_name',
  'tenant_meta',
  'uuid',
].join(',');

const packageVersionMask = ['meta', 'spec.ecosystem', 'uuid'].join(',');

export interface UseFindingsDataProps {
  enabled?: boolean;
  filterState?: FilterState;
  filterExpression: FilterExpression;
  namespace: string;
  paginator: DataTablePaginator;
}

export const useFindingsData = ({
  enabled = true,
  filterState,
  filterExpression,
  namespace,
  paginator,
}: UseFindingsDataProps) => {
  const qFindings = useListFindings(
    namespace,
    { enabled },
    {
      filter: filterExpression,
      mask: FindingsMask,
      sort: sortParamBuilders.ascendingBy('spec.level'),
      ...paginator.getListParameters(),
    }
  );

  const findings = useMemo(() => {
    return qFindings.data?.list?.objects ?? [];
  }, [qFindings.data]);

  // Get the filter expression for related PackageVersion objects
  const relatedPackageVersionsFilter = useMemo(() => {
    const uuids = [];

    for (const f of findings) {
      if (f.meta.parent_kind === 'PackageVersion' && f.meta.parent_uuid) {
        uuids.push(f.meta.parent_uuid);
      }
    }

    if (uuids.length === 0) {
      return;
    }

    const deduped = _uniq(uuids);
    return `uuid in ["${deduped.join('","')}"]`;
  }, [findings]);

  const qListPackageVersions = useListPackageVersions(
    namespace,
    { enabled: !!findings && !!relatedPackageVersionsFilter },
    {
      filter: relatedPackageVersionsFilter,
      mask: packageVersionMask,
    }
  );

  const packageVersions = useMemo(() => {
    return qListPackageVersions.data?.list?.objects ?? [];
  }, [qListPackageVersions.data]);

  return {
    findings,
    packageVersions,
    isLoading: qFindings.isLoading || qListPackageVersions.isLoading,
  };
};
