import { Divider } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';

import {
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../../components/DetailDrawer';
import { RemediationDetails } from '../RemediationDetails';
import { RemediationOverviewStats } from '../RemediationOverviewStats';
import { RemediationRiskDrivers } from '../RemediationRiskDrivers';
import { RemediationsDetailDrawerFixedFindings } from './RemediationsDetailDrawerFixedFindings';

export const RemediationDetailDrawerOverview = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  if (!upgradeInfo) return null;

  return (
    <DetailDrawerSectionStack divider={<Divider />}>
      <DetailDrawerSection>
        <RemediationOverviewStats upgradeInfo={upgradeInfo} />
      </DetailDrawerSection>
      <DetailDrawerSection
        title={<FM defaultMessage="Remediation Risk Drivers" />}
      >
        <RemediationRiskDrivers upgradeInfo={upgradeInfo} />
      </DetailDrawerSection>
      <DetailDrawerSection title={<FM defaultMessage="Details" />}>
        <RemediationDetails upgradeInfo={upgradeInfo} />
      </DetailDrawerSection>
      <RemediationsDetailDrawerFixedFindings upgradeInfo={upgradeInfo} />
    </DetailDrawerSectionStack>
  );
};
