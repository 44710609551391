import { Typography } from '@mui/material';

import { SpecFindingLevel, V1FindingSpec } from '@endorlabs/api_client';
import {
  FindingNameDisplay,
  PackageVersionNameDisplay,
} from '@endorlabs/ui-common';

import { FindingAggregation } from '../types';

interface FindingsDataTableViewAccordionGroupTitleNodeProps {
  aggregationType: FindingAggregation;
  level?: SpecFindingLevel;
  title: string;
}

export const FindingsDataTableViewAccordionGroupTitleNode = ({
  aggregationType,
  level,
  title,
}: FindingsDataTableViewAccordionGroupTitleNodeProps) => {
  if (
    aggregationType === FindingAggregation.Dependency ||
    aggregationType === FindingAggregation.Package
  ) {
    return (
      <PackageVersionNameDisplay
        packageVersion={{ meta: { name: title } }}
        showVersion
        size="medium"
      />
    );
  }

  if (aggregationType === FindingAggregation.Finding) {
    return (
      <FindingNameDisplay
        finding={{
          meta: { name: title },
          spec: {
            level: level ?? SpecFindingLevel.Unspecified,
          } as V1FindingSpec,
        }}
        showCategories
        size="medium"
      />
    );
  }

  if (aggregationType === FindingAggregation.FindingAndDependency)
    return <Typography variant="h6">{title}</Typography>;
};
