import { Card, CardContent, Grid, useTheme } from '@mui/material';
import { useRouter } from '@tanstack/react-location';
import { clone as _clone, has as _has } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import {
  ContextContextType,
  ScanResultSpecType,
  SpecEndorLicenseFeatureType,
  SpecFindingLevel,
  V1Ecosystem,
} from '@endorlabs/api_client';
import {
  isBinaryProject,
  isContainerProject,
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';
import {
  EMPTY_FINDING_LEVEL_COUNTS,
  FindingCount,
  sortParamBuilders,
  tryParseGroupResponseAggregationKey,
  useCountPackageVersions,
  useCountRepositoryVersions,
  useListFindings,
  useListPackageVersions,
  useListRepositoryVersions,
  useListScanResults,
} from '@endorlabs/queries';
import {
  UIPackageVersionUtils,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { ContainersTable } from '../../domains/Containers';
import {
  FilterBar,
  useFilterContext,
  withFilterProvider,
} from '../../domains/filters';
import {
  ProjectPageHeader,
  ProjectVersionsTable,
  useProjectVersionMetadata,
} from '../../domains/Projects';
import { useAuthInfo, useLicensingInfo } from '../../providers';
import { AuthenticatedRouteLocationGenerics } from '../../providers/AuthInfo/types';
import { getPackageVersionPath } from '../../routes';

/**
 * Given a project, fetch the related "Project Versions"
 *
 * Currently restricting to versions from the MAIN and REF contexts
 */
const useProjectVersionsIndexPageData = (
  project: ProjectResource,
  filterExpression: FilterExpression
) => {
  const hasRepositoryVersions = !isBinaryProject(project);
  const projectNamespace = project.tenant_meta.namespace;

  const baseFilterExpression = useMemo(() => {
    const expressions = [filterExpressionBuilders.defaultResourceContexts()];

    if (filterExpression) {
      expressions.push(filterExpression);
    }

    return filterExpressionBuilders.and(expressions);
  }, [filterExpression]);

  const qCountRepositoryVersions = useCountRepositoryVersions(
    projectNamespace,
    { enabled: hasRepositoryVersions },
    {
      filter: filterExpressionBuilders.and([
        baseFilterExpression,
        `meta.parent_uuid=="${project?.uuid}"`,
      ]),
      traverse: false,
    }
  );
  const qCountPackageVersions = useCountPackageVersions(
    projectNamespace,
    { enabled: !hasRepositoryVersions },
    {
      filter: filterExpressionBuilders.and([
        baseFilterExpression,
        `spec.project_uuid=="${project?.uuid}"`,
      ]),
      traverse: false,
    }
  );

  const totalCount = hasRepositoryVersions
    ? qCountRepositoryVersions.data?.count
    : qCountPackageVersions.data?.count;

  const paginator = useDataTablePaginator({ totalCount });

  const qListRepositoryVersions = useListRepositoryVersions(
    projectNamespace,
    { enabled: hasRepositoryVersions },
    {
      filter: filterExpressionBuilders.and([
        baseFilterExpression,
        `meta.parent_uuid=="${project?.uuid}"`,
      ]),
      mask: 'uuid,context,meta.kind,meta.name,meta.create_time,spec.version.ref',
      sort: sortParamBuilders.descendingBy('meta.create_time'),
      traverse: false,
      ...paginator.getListParameters(),
    }
  );
  const qListPackageVersions = useListPackageVersions(
    projectNamespace,
    {
      enabled: !hasRepositoryVersions,
    },
    {
      filter: filterExpressionBuilders.and([
        baseFilterExpression,
        `spec.project_uuid=="${project?.uuid}"`,
      ]),
      mask: 'uuid,context,meta.kind,meta.name,meta.create_time,spec.ecosystem',
      sort: sortParamBuilders.descendingBy('meta.create_time'),
      traverse: false,
      ...paginator.getListParameters(),
    }
  );

  // Fetch related Findings and ScanResults
  const qRelatedFindingGroups = useListFindings(
    projectNamespace,
    {},
    {
      filter: filterExpressionBuilders.and([
        baseFilterExpression,
        `spec.project_uuid=="${project?.uuid}"`,
      ]),
      group: {
        aggregation_paths: 'context.id,spec.level',
      },
    }
  );
  const qRelatedScanResults = useListScanResults(
    projectNamespace,
    {},
    {
      filter: filterExpressionBuilders.and([
        baseFilterExpression,
        `spec.type==${ScanResultSpecType.AllScans}`,
        `meta.parent_uuid=="${project?.uuid}"`,
      ]),
      sort: sortParamBuilders.descendingBy('meta.create_time'),
      mask: [
        'context',
        'uuid',
        'spec.end_time',
        'spec.exit_code',
        'spec.status',
        'spec.type',
        'spec.environment.config',
      ].join(','),
    }
  );

  // Merge the project versions found with the related Findings and Scan Results
  const data = useMemo(() => {
    const findingCountsByContextId = Object.entries(
      qRelatedFindingGroups.data?.group_response?.groups ?? {}
    ).reduce((acc, [key, group]) => {
      const values = tryParseGroupResponseAggregationKey(key);

      const contextId = values.find((kv) => kv.key === 'context.id')?.value as
        | string
        | undefined;
      const findingLevel = values.find((kv) => kv.key === 'spec.level')
        ?.value as SpecFindingLevel | undefined;
      const count = group.aggregation_count?.count ?? 1;

      if (contextId && findingLevel) {
        let findingCounts = acc[contextId];
        if (!findingCounts) {
          findingCounts = _clone(EMPTY_FINDING_LEVEL_COUNTS);
        }

        const ix = findingCounts.findIndex((c) => c.level === findingLevel);
        if (ix !== -1) {
          findingCounts[ix] = { level: findingLevel, value: count };
        }

        acc[contextId] = findingCounts;
      }

      return acc;
    }, {} as Record<string, FindingCount[]>);

    const scanResultsByContextId = (
      qRelatedScanResults.data?.objects ?? []
    ).reduce((acc, scanResult) => {
      const contextId = scanResult.context.id;

      if (contextId && !_has(acc, contextId)) {
        acc[contextId] = scanResult;
      }

      return acc;
    }, {} as Record<string, ScanResultResource>);

    const projectVersions = [
      qListPackageVersions.data?.list?.objects,
      qListRepositoryVersions.data?.list?.objects,
    ]
      .flat()
      .filter((v) => !!v) as ProjectVersionResource[];

    return projectVersions.map((v) => {
      const contextId = v.context.id;

      let link = contextId;
      let name = v.meta.name;
      let versionRef = '';

      // Handle ProjectVersion as PackageVersion object
      if (v.meta.kind === 'PackageVersion') {
        const parsed = UIPackageVersionUtils.parsePackageName(v.meta.name);
        name = parsed.label;
        versionRef = parsed.version;
        link = getPackageVersionPath({
          tenantName: projectNamespace,
          uuid: v.uuid,
        });
      }

      return {
        isDefaultVersion: v.context.type === ContextContextType.Main,
        findingCounts: findingCountsByContextId[contextId],
        name,
        link,
        versionRef,
        latestScanResult: scanResultsByContextId[contextId],
      };
    });
  }, [
    projectNamespace,
    qListPackageVersions.data,
    qListRepositoryVersions.data,
    qRelatedFindingGroups.data,
    qRelatedScanResults.data,
  ]);

  const isLoading = [
    qCountPackageVersions,
    qCountRepositoryVersions,
    qListPackageVersions,
    qListRepositoryVersions,
  ].some((q) => q.isLoading);

  return { data, isLoading, paginator };
};

export type ProjectVersionSelectPageProps = {
  project: ProjectResource;
};

/**
 * List "Project Versions" for the given project
 */
const ProjectVersionsIndexPageBase = ({
  project,
}: ProjectVersionSelectPageProps) => {
  const { space } = useTheme();

  const { filter: userFilterExpression } = useFilterContext();

  const { packageEcosystems } = useProjectVersionMetadata(project);

  const { data, isLoading, paginator } = useProjectVersionsIndexPageData(
    project,
    userFilterExpression
  );
  const router = useRouter<AuthenticatedRouteLocationGenerics>();
  const { setLicenseInCurrentRoute } = useAuthInfo();
  const { checkLicensePresent } = useLicensingInfo();
  const isContainerLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.ContainerScan
  );

  const displayAsContainers = isContainerProject(project, packageEcosystems);

  useEffect(() => {
    if (!isContainerLicensePresent) {
      const hasContainers = packageEcosystems?.some(
        (p) => p === V1Ecosystem.Container
      );
      if ((displayAsContainers && isBinaryProject(project)) || hasContainers) {
        setLicenseInCurrentRoute({
          pathname: router.state.location.pathname,
          isLicense: false,
        });
      }

      return () => {
        setLicenseInCurrentRoute({
          pathname: '',
          isLicense: true,
        });
      };
    }
  }, [
    displayAsContainers,
    isContainerLicensePresent,
    packageEcosystems,
    project,
    router.state.location.pathname,
    setLicenseInCurrentRoute,
  ]);

  return (
    <Grid container direction="column" spacing={space.md}>
      <Grid item>
        <ProjectPageHeader project={project} />
      </Grid>

      <Grid item>
        <FilterBar fields={[]} />
      </Grid>

      <Grid item>
        <Card>
          <CardContent>
            {displayAsContainers ? (
              <ContainersTable
                data={data}
                enablePagination
                isLoading={isLoading}
                paginator={paginator}
              />
            ) : (
              <ProjectVersionsTable
                data={data}
                enablePagination
                isLoading={isLoading}
                paginator={paginator}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export const ProjectVersionsIndexPage = withFilterProvider(
  ProjectVersionsIndexPageBase,
  {
    displayName: 'ProjectVersionsIndexPage',
  }
);
