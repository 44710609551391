import { Card, CardContent, Grid } from '@mui/material';
import { MakeGenerics } from '@tanstack/react-location';
import { ColumnSort, RowSelectionState } from '@tanstack/react-table';
import { isEmpty as _isEmpty } from 'lodash-es';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  sortParamBuilders,
  useListArtifactSignature,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  EmptyState,
  IconArtifactRegistry,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { ARTIFACT_SIGNATURE_FILTER_FIELDS } from '../../domains/ArtifactSignature';
import {
  FilterBar,
  ResourceFilterContextProvider,
  useFilterContext,
} from '../../domains/filters';
import { useAuthInfo } from '../../providers';
import { getArtifactsPath, useFullMatch } from '../../routes';
import {
  ArtifactDetailTable,
  ArtifactDetailTableRow,
} from './ArtifactDetailTable';
import { ArtifactSignatureColumnIdMap } from './constants';
import { useArtifactDetailDrawer } from './useArtifactDetailDrawer';
import { mapToArtifactDetailTableRow } from './utils';

const FILTER_FIELDS = ARTIFACT_SIGNATURE_FILTER_FIELDS.filter(
  (field) => field.id !== 'ArtifactSignature:spec.artifact_type'
);

type ArtifactGenerics = MakeGenerics<{
  Params: {
    artifactValue: string;
  };
}>;

export const BaseDetail = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { artifactValue },
  } = useFullMatch<ArtifactGenerics>();
  const artifactName = decodeURIComponent(artifactValue);
  const [selectedRows, setSelectedRows] = useState({});

  const paginator = useDataTablePaginator({
    isInfinite: true,
    hasNextPage: () => !!nextPageToken,
  });

  const { filter: userFilterExpression, clearFilter } = useFilterContext();

  const { DetailDrawer: ArtifactDetailDrawer, permalinkEffect } =
    useArtifactDetailDrawer();
  const [columnSort, setColumnSort] = useState<ColumnSort | undefined>({
    id: 'updateTime',
    desc: true,
  });

  const filterExpression = useMemo(() => {
    const expressions = [
      // HACK: this can return multiple artifacts with the same name. A narrower
      // base filter will be needed in the future.
      `meta.name matches "${artifactName}"`,
    ];
    if (userFilterExpression) {
      expressions.push(userFilterExpression);
    }

    return filterExpressionBuilders.and(expressions);
  }, [artifactName, userFilterExpression]);

  const handleSortChange = (columnSort?: ColumnSort | undefined) => {
    if (columnSort) {
      setColumnSort(columnSort);
    }
  };

  const sortParam = useMemo(() => {
    if (columnSort) {
      const columnId = ArtifactSignatureColumnIdMap[columnSort.id];
      if (columnId) {
        return columnSort.desc
          ? sortParamBuilders.descendingBy(columnId)
          : sortParamBuilders.ascendingBy(columnId);
      }
    }
    return sortParamBuilders.descendingBy('meta.update_time');
  }, [columnSort]);

  const qArtifactsSignature = useListArtifactSignature(
    tenantName,
    {},
    {
      filter: filterExpression,
      mask: [
        'meta.name',
        'meta.create_time',
        'meta.update_time',
        'spec.provenance.source_repository_ref',
        'tenant_meta',
        'uuid',
      ].join(','),
      ...paginator.getListParameters(),
      sort: sortParam,
    }
  );

  const [artifactSignatures, nextPageToken] = useMemo(() => {
    const artifactSignatures = qArtifactsSignature.data?.list?.objects ?? [];
    const nextPageToken =
      qArtifactsSignature.data?.list?.response?.next_page_token;

    return [artifactSignatures, nextPageToken];
  }, [qArtifactsSignature.data]);

  const hasError = qArtifactsSignature.isError;
  const isLoading = qArtifactsSignature.isLoading;
  const isEmptyState =
    !isLoading &&
    !hasError &&
    artifactSignatures.length === 0 &&
    !userFilterExpression;

  const rows: ArtifactDetailTableRow[] = useMemo(() => {
    return mapToArtifactDetailTableRow(artifactSignatures);
  }, [artifactSignatures]);

  useLayoutEffect(() => {
    permalinkEffect({ artifacts: artifactSignatures });
  }, [artifactSignatures, permalinkEffect]);

  const openDetailDrawer = useCallback(
    (artifactUuid?: string) => {
      if (!artifactUuid) return;

      const artifact = artifactSignatures.find(
        (art) => art.uuid === artifactUuid
      );
      if (!artifact) return;

      const artifactNamespace = artifact.tenant_meta.namespace;
      ArtifactDetailDrawer.activate(
        {
          artifactNamespace,
          artifactUuid,
        },
        {
          artifactNamespace,
          artifactUuid,
        }
      );
    },
    [artifactSignatures, ArtifactDetailDrawer]
  );

  // Reset pagination on filter or tenant change.
  // NOTE: with infinite pagination, the paginator is not reset on the total
  // count change when filters are applied
  useEffect(
    () => {
      paginator.resetPagination();
    },
    // ignore changes from paginator outside of the reset handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterExpression, paginator.resetPagination, tenantName]
  );

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          breadcrumbsLinks={[
            {
              url: getArtifactsPath({ tenantName }),
              label: <FM defaultMessage="All Artifacts" />,
            },
          ]}
          Icon={IconArtifactRegistry}
          title={artifactName}
        />
      </Grid>

      {!isEmptyState && (
        <Grid item>
          <FilterBar fields={FILTER_FIELDS} />
        </Grid>
      )}

      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={<FM defaultMessage="No digests found for this artifact." />}
          ></EmptyState>
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <Card>
            <CardContent>
              <ArtifactDetailTable
                data={rows}
                emptyStateProps={{
                  title: (
                    <FM defaultMessage="No Digest found for this artifact." />
                  ),
                  children: (
                    <ButtonCancel onClick={clearFilter}>
                      <FM defaultMessage="Clear Filters" />
                    </ButtonCancel>
                  ),
                }}
                title={
                  !_isEmpty(selectedRows) ? (
                    <FM
                      defaultMessage="{selectedCount} of {totalCount} {totalCount, plural, one {Artifact Signature} other {Artifact Signatures}} selected"
                      values={{
                        selectedCount: Object.keys(selectedRows).length,
                        totalCount: rows.length,
                      }}
                    />
                  ) : undefined
                }
                isLoading={isLoading}
                namespace={tenantName}
                enablePagination
                enableColumnSort
                paginator={paginator}
                openDetailDrawer={openDetailDrawer}
                onColumnSort={handleSortChange}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export const ArtifactsDetail = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  return (
    <ResourceFilterContextProvider
      namespace={tenantName}
      resourceKind="ArtifactSignature"
      resourceSearchKeys={[
        'meta.name',
        'meta.tags',
        // Support searching artifact by the ref
        'spec.provenance.source_repository_ref',
      ]}
    >
      <BaseDetail />
    </ResourceFilterContextProvider>
  );
};
