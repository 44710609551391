import produce from 'immer';
import { isEqual as _isEqual, set as _set } from 'lodash-es';
import { FormMultiResourceTagsFieldValues } from 'packages/webapp/src/components';
import { FormattedMessage as FM } from 'react-intl';

import { V1Project } from '@endorlabs/api_client';
import {
  IQueryError,
  ProjectResource,
  useUpdateManyProject,
} from '@endorlabs/queries';
import { useAppNotify } from '@endorlabs/ui-common';

import { TAG_FIELD_NAME } from '../../../components';

export const useUpdateMultiProjectFields = (
  namespace: string,
  projects: (ProjectResource | V1Project | undefined)[],
  onUpdateManySuccess?: (projects: V1Project[]) => void,
  onUpdateManyError?: (error: IQueryError) => void
) => {
  const addAppNotification = useAppNotify();

  const qUpdateManyProjects = useUpdateManyProject({
    onSuccess: (projects) => {
      addAppNotification({
        id: 'project:update:success',
        severity: 'success',
        message: <FM defaultMessage="Projects Successfully Updated" />,
      });
      if (onUpdateManySuccess) {
        onUpdateManySuccess(projects);
      }
    },
    onError: (error: IQueryError) => {
      addAppNotification({
        id: 'project:update:error',
        severity: 'error',
        message: <FM defaultMessage="Error Updating Projects" />,
        details: error.response.data?.message,
      });
      if (onUpdateManyError) {
        onUpdateManyError(error);
      }
    },
  });

  /**
   * Handles updating a single field on multiple project, with the given mask
   */
  const handleUpdateManyProjectField = (
    mask: string,
    updates: { value: unknown; project?: V1Project }[]
  ) => {
    // TODO: handle edge case
    if (updates.length === 0) return;

    const projectsToUpdate: V1Project[] = [];

    updates.forEach((update) => {
      const { project, value } = update;
      if (project) {
        const updated = produce(project, (draft) => {
          _set(draft, mask, value);
        });
        projectsToUpdate.push(updated);
      }
    });

    qUpdateManyProjects.mutate({
      mask,
      namespace,
      resources: projectsToUpdate,
    });
  };

  const handleUpdateLabels = (values: FormMultiResourceTagsFieldValues) => {
    const projectsToUpdate: { value: unknown; project?: V1Project }[] = [];
    projects?.forEach((p) => {
      const updatedTagValues = values[TAG_FIELD_NAME];
      const updatedProjectTags: string[] = [];
      updatedTagValues.forEach((tag) => {
        if (
          p?.uuid &&
          tag.resourceList.length > 0 &&
          tag.resourceList.includes(p?.uuid)
        ) {
          updatedProjectTags.push(tag.label);
        }
      });
      const existingProjectTags = p?.meta.tags;
      if (!_isEqual(updatedProjectTags, existingProjectTags)) {
        projectsToUpdate.push({ value: updatedProjectTags, project: p });
      }
    });
    handleUpdateManyProjectField('meta.tags', projectsToUpdate);
  };

  return {
    handleUpdateLabels,
    qUpdateManyProjects,
  };
};
