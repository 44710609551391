import { useMemo } from 'react';

import { ScanResultSpecType } from '@endorlabs/api_client';
import { FindingSource } from '@endorlabs/endor-core/Finding';
import { MetricResource } from '@endorlabs/endor-core/Metric';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  buildQueryCall,
  RepositoryResource,
  RepoVersionResource,
  ScanResultResource,
  sortParamBuilders,
} from '@endorlabs/queries';

import { ProjectDetailDrawerContentProps } from '../components/ProjectDetailDrawer/ProjectDetailDrawerContent';
import { useProjectDetailMetadata } from './index';
import { useProjectFindingsBySource } from './useProjectFindingsBySource';

export const useProjectDetailDrawerContentData = ({
  isProjectLoading,
  uuid,
  project,
  namespace,
}: ProjectDetailDrawerContentProps) => {
  const qProjectReferences = buildQueryCall('Project', {
    filter: `uuid == "${uuid}"`,
  })
    .addReference('Repository', {
      mask: 'uuid,tenant_meta,meta.name,meta.parent_uuid,spec.languages',
    })
    .addReference('RepositoryVersion', {
      filter: filterExpressionBuilders.defaultResourceContexts(),
      mask: 'uuid,tenant_meta,meta.kind,meta.name,meta.parent_uuid,spec.version,spec.last_commit_date,scan_object,context',
      sort: sortParamBuilders.descendingBy('spec.last_commit_date'),
    })
    .addReference('ScanResult', {
      filter: filterExpressionBuilders.and([
        `spec.type==${ScanResultSpecType.AllScans}`,
        filterExpressionBuilders.defaultResourceContexts(),
      ]),
      page_size: 1,
      sort: sortParamBuilders.descendingBy('meta.create_time'),
    })
    .addReference(
      'Metric',
      {
        filter: filterExpressionBuilders.and([
          `spec.analytic==version_cicd_tools`,
          filterExpressionBuilders.defaultResourceContexts(),
        ]),
        sort: sortParamBuilders.descendingBy('meta.create_time'),
      },
      {
        connect_from: 'uuid',
        connect_to: 'spec.project_uuid',
      }
    )
    .useBuiltQuery(namespace, { enabled: !!project });

  const {
    repository,
    repositoryVersion,
    scanResult,
    repositoryVersionsCount,
    metric,
    tags,
  } = useMemo<{
    repository?: RepositoryResource;
    repositoryVersion: RepoVersionResource;
    scanResult: ScanResultResource;
    repositoryVersionsCount: number;
    metric: MetricResource;
    tags: string[];
  }>(() => {
    const projectResponse: ProjectResource | undefined =
      qProjectReferences.data?.spec?.query_response?.list.objects[0];
    const projectRefs = projectResponse?.meta.references;

    return {
      repository: projectRefs?.Repository.list.objects[0],
      scanResult: projectRefs?.ScanResult.list.objects[0],
      metric: projectRefs?.Metric.list.objects[0],
      repositoryVersionsCount:
        projectRefs?.RepositoryVersion.list.objects.length,
      repositoryVersion: projectRefs?.RepositoryVersion.list.objects[0],
      tags: project?.meta.tags ?? [],
    };
  }, [qProjectReferences.data, project?.meta.tags]);

  const { topMetadataProps } = useProjectDetailMetadata({
    project,
    isLoading: isProjectLoading,
    namespace,
    repository,
    repositoryVersion: repositoryVersion,
  });

  const isLoading = isProjectLoading || qProjectReferences.isLoading;
  const packagesCount = topMetadataProps.packageMetadata.packages;
  const packageEcosystems = topMetadataProps.packageMetadata.packageEcosystems;
  const dependenciesCount = topMetadataProps.packageMetadata.dependenciesCount;
  const languages = topMetadataProps.repositoryVersionMetadata.languages;
  const repositoryLink = project?.spec.git?.http_clone_url.replace(
    /\.git$/,
    ''
  );

  const projectCiCdTableRows = useMemo(() => {
    const cicdTools =
      metric?.spec.metric_values.CiCdTools.ci_cd_tools?.tools ?? [];

    const sortedTools = [...cicdTools].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return sortedTools?.map((tool) => ({
      categories: tool.categories,
      tool: tool.name,
    }));
  }, [metric?.spec.metric_values.CiCdTools]);

  const dependencyFindingsCount = useProjectFindingsBySource({
    uuid,
    namespace,
    project,
    source: FindingSource.Dependency,
  });
  const ciFindingsCount = useProjectFindingsBySource({
    uuid,
    namespace,
    project,
    source: FindingSource.GithubAction,
  });
  const packageFindingsCount = useProjectFindingsBySource({
    uuid,
    namespace,
    project,
    source: FindingSource.Package,
  });
  const repositoryFindingsCount = useProjectFindingsBySource({
    uuid,
    namespace,
    project,
    source: FindingSource.Repository,
  });
  const secretsFindingsCount = useProjectFindingsBySource({
    uuid,
    namespace,
    project,
    source: FindingSource.Secrets,
  });
  const findingCounts = {
    dependencies: dependencyFindingsCount,
    ciWorkflows: ciFindingsCount,
    packages: packageFindingsCount,
    repositories: repositoryFindingsCount,
    secrets: secretsFindingsCount,
  };
  return {
    isLoading,
    findingCounts,
    packagesCount,
    packageEcosystems,
    dependenciesCount,
    languages,
    repositoryLink,
    projectCiCdTableRows,
    scanResult,
    repositoryVersionsCount,
    tags,
  };
};
