import { useMemo } from 'react';

import { FindingSource } from '@endorlabs/endor-core/Finding';

import { FilterFieldConfig } from '../../filters';
import { FINDING_FILTER_FIELDS } from '../constants';

export const useFindingsFilterFields = (
  findingSource: FindingSource,
  options?: { exclude?: string[] }
): FilterFieldConfig[] => {
  return useMemo(() => {
    let fields = FINDING_FILTER_FIELDS;

    // Allow excluding custom fields
    if (options?.exclude?.length) {
      fields = fields.filter((f) => !options.exclude?.includes(f.id));
    }

    // Remove non-applicable filters for containers
    if (findingSource === FindingSource.Container) {
      return fields.filter((field) => field.id !== 'spec.finding_categories');
    } else {
      fields = fields.filter(
        (f) => f.id !== 'spec.finding_metadata.container_data.has_base_layer'
      );
    }

    // Remove non-applicable filters for repositories
    if (findingSource === FindingSource.Repository) {
      return fields.filter(
        (field) =>
          field.id !== 'spec.ecosystem' &&
          field.id !==
            'spec.finding_metadata.vulnerability.spec.epss_score.probability_score'
      );
    }

    // Remove non-applicable filters for secrets
    // TODO(UI-283): support filtering by secret valid/invalid
    if (findingSource === FindingSource.Secrets) {
      return fields.filter(
        (field) => field.id === 'spec.level' || field.id === 'meta.create_time'
      );
    }

    return fields;
  }, [findingSource, options?.exclude]);
};
