import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecEnabledFeatureType } from '@endorlabs/api_client';

import { ENABLED_FEATURES_MAP } from '../../../views/Onboarding/constants';

type LicenseSelectComponentProps = {
  editedFeatures: SpecEnabledFeatureType[];
  featuresListwithLicense: SpecEnabledFeatureType[];
  handleCheckboxChange: (feature: SpecEnabledFeatureType) => void;
  handleCheckboxAll: () => void;
};

export const LicenseSelectComponent = ({
  editedFeatures,
  featuresListwithLicense,
  handleCheckboxChange,
  handleCheckboxAll,
}: LicenseSelectComponentProps) => {
  const { palette, typography } = useTheme();

  const scannersListLength = useMemo(() => {
    return (
      Object.values(SpecEnabledFeatureType).filter(
        (value) => value !== SpecEnabledFeatureType.Unspecified
      ).length ?? 0
    );
  }, []);

  return (
    <>
      <FormGroup
        sx={{
          padding: '0.25rem 0.5rem',
          borderBottom: `1px solid ${palette.status.pending}`,
        }}
      >
        <FormControlLabel
          key={'scanners'}
          sx={{
            '& .MuiFormControlLabel-label': {
              fontWeight: typography.subtitle2.fontWeight,
            },
            padding: '0.5rem',
          }}
          control={
            <Checkbox
              checked={editedFeatures.length === scannersListLength}
              onChange={handleCheckboxAll}
              indeterminate={
                editedFeatures.length > 0 &&
                editedFeatures.length < ENABLED_FEATURES_MAP.length
              }
            />
          }
          label={<FM defaultMessage={'Scanners'} />}
        />
      </FormGroup>
      <FormGroup sx={{ padding: '0.5rem' }}>
        {ENABLED_FEATURES_MAP.map((featureType) => {
          return (
            <FormControlLabel
              key={featureType.value}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: typography.body1.fontSize,
                },
                padding: '0.5rem',
                alignItems: 'flex-start',
              }}
              control={
                <Checkbox
                  checked={editedFeatures.includes(featureType.value)}
                  onChange={() => handleCheckboxChange(featureType.value)}
                  disabled={
                    !featuresListwithLicense.includes(featureType.value)
                  }
                />
              }
              label={
                <Stack direction="column" spacing={1} alignItems="flex-start">
                  <Typography>{featureType.label}</Typography>
                  <Typography
                    variant="caption"
                    color={palette.text.secondary}
                    textAlign={'left'}
                  >
                    {featureType.description}
                  </Typography>
                </Stack>
              }
            />
          );
        })}
      </FormGroup>
    </>
  );
};
