import { V1Ecosystem } from '@endorlabs/api_client';

export const ECOSYSTEM_SEPARATOR = '://';
export const VERSION_SEPARATOR = '@';
export const ECOSYSTEM_PREFIXES: Record<
  Exclude<V1Ecosystem, V1Ecosystem.Unspecified>,
  string
> = {
  [V1Ecosystem.C]: 'c',
  [V1Ecosystem.Cargo]: 'cargo',
  [V1Ecosystem.Gem]: 'gem',
  [V1Ecosystem.Git]: 'git',
  [V1Ecosystem.Go]: 'go',
  [V1Ecosystem.Maven]: 'mvn',
  [V1Ecosystem.Npm]: 'npm',
  [V1Ecosystem.Packagist]: 'packagist',
  [V1Ecosystem.Pypi]: 'pypi',
  [V1Ecosystem.Nuget]: 'nuget',
  [V1Ecosystem.Sbom]: 'sbom',
  [V1Ecosystem.Rpm]: 'rpm',
  [V1Ecosystem.Debian]: 'debian',
  [V1Ecosystem.GithubAction]: 'githubaction',
  [V1Ecosystem.Cocoapod]: 'cocoapod',
  [V1Ecosystem.Apk]: 'apk',
  [V1Ecosystem.Container]: 'container',
  [V1Ecosystem.HuggingFace]: 'huggingface',
};

export const GITHUB_ACTION_PACKAGE_SUFFIX = '.yml';
