import { Link as MUILink, LinkProps as MUILinkProps } from '@mui/material';
import {
  Link as LocationLink,
  LinkProps as LocationLinkProps,
} from '@tanstack/react-location';
import { forwardRef } from 'react';

export type LinkProps = MUILinkProps & LocationLinkProps;

/**
 * Wrapper around react-location Link, to pass the ref down to the expected property name `_ref`, instead of `ref`
 */
const LinkBehavior = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <LocationLink _ref={ref} {...props} />
));
LinkBehavior.displayName = 'LinkBehavior';

/**
 * Single component that allows absolute (MUI) or relative (React Location) links with a unified API.
 * Both link types use MUI styling.
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ to, ...props }: LinkProps, ref) => {
    return String(to).match(/^http/) ? (
      <MUILink
        {...props}
        ref={ref}
        href={to as string}
        rel={
          /**
           * ensure `window.opener` is not passed
           * @link https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
           */
          props.target === '_blank' ? 'noopener noreferrer nofollow' : undefined
        }
      />
    ) : (
      <MUILink component={LinkBehavior} to={to} ref={ref} {...props} />
    );
  }
);

Link.displayName = 'Link';
