import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { get as _get } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { FormattedMessage as FM, FormattedNumber, useIntl } from 'react-intl';

import {
  V1FindingCategory,
  V1FindingTags,
  V1PlatformSource,
  V1Query,
} from '@endorlabs/api_client';
import { filterExpressionBuilders, ValueFilter } from '@endorlabs/filters';
import { tryParseGroupResponseAggregationKey } from '@endorlabs/queries';
import { Colors, Link, NilDisplay } from '@endorlabs/ui-common';

import {
  DashboardInventoryBreakdownChart,
  isQueryStateLoading,
  useDashboardQueries,
} from '../../domains/Dashboard';
import {
  getDependencyPath,
  getFindingsPath,
  getNotificationsPath,
  getPackageVersionRootPath,
  getProjectPath,
} from '../../routes';

const getCountFromQueryData = (data?: V1Query): number | undefined => {
  return _get(data, ['spec', 'query_response', 'count_response', 'count']);
};

const useDependencyMetrics = ({
  namespace,
}: Pick<DashboardInventoryProps, 'namespace'>) => {
  const { formatMessage: fm } = useIntl();
  const { getQueryStatus } = useDashboardQueries({
    namespace,
  });

  const qDependenciesGroup = getQueryStatus({
    resourceKind: 'DependencyMetadata',
    aggregation_paths: ['spec.dependency_data.direct'],
    unique_count_paths: ['meta.name'],
    filters: [filterExpressionBuilders.mainResourceContext()],
  });

  const { directDependencyCount, transitiveDependencyCount } = useMemo(() => {
    const groups = _get(
      qDependenciesGroup?.data,
      ['spec', 'query_response', 'group_response', 'groups'],
      {}
    );

    let directDependencyCount = 0,
      transitiveDependencyCount = 0;

    for (const [key, group] of Object.entries(groups)) {
      const aggregation = tryParseGroupResponseAggregationKey(key).find(
        (kv) => kv.key === 'spec.dependency_data.direct'
      );

      if (!aggregation) continue;

      const count = _get(group, ['unique_counts', 'meta.name', 'count'], 0);

      if (aggregation.value) {
        directDependencyCount = count;
      } else {
        transitiveDependencyCount = count;
      }
    }

    return { directDependencyCount, transitiveDependencyCount };
  }, [qDependenciesGroup?.data]);

  const data = [
    {
      key: 'direct',
      label: fm({ defaultMessage: 'Direct' }),
      value: directDependencyCount,
    },
    {
      key: 'transitive',
      label: fm({ defaultMessage: 'Transitive' }),
      value: transitiveDependencyCount,
    },
  ];

  const isLoading = isQueryStateLoading(qDependenciesGroup);

  return { data, isLoading };
};

const useVulnerabilityMetrics = ({
  namespace,
}: Pick<DashboardInventoryProps, 'namespace'>) => {
  const { formatMessage: fm } = useIntl();
  const { getQueryStatus } = useDashboardQueries({
    namespace,
  });

  const qUnreachableVulnerabilitiesCount = getQueryStatus({
    resourceKind: 'Finding',
    filters: [
      filterExpressionBuilders.mainResourceContext(),
      `spec.finding_categories contains [${V1FindingCategory.Vulnerability}]`,
      `spec.finding_tags contains [${V1FindingTags.UnreachableFunction}]`,
    ],
    count: true,
  });

  const qReachableVulnerabilitiesCount = getQueryStatus({
    resourceKind: 'Finding',
    filters: [
      filterExpressionBuilders.mainResourceContext(),
      `spec.finding_categories contains [${V1FindingCategory.Vulnerability}]`,
      `spec.finding_tags contains [${V1FindingTags.ReachableFunction}]`,
    ],
    count: true,
  });

  const qPotentiallyReachableVulnerabilitiesCount = getQueryStatus({
    resourceKind: 'Finding',
    filters: [
      filterExpressionBuilders.mainResourceContext(),
      `spec.finding_categories contains [${V1FindingCategory.Vulnerability}]`,
      `spec.finding_tags contains [${V1FindingTags.PotentiallyReachableFunction}]`,
    ],
    count: true,
  });

  const unreachableVulnerabilitiesCount = getCountFromQueryData(
    qUnreachableVulnerabilitiesCount?.data
  );

  const reachableVulnerabilitiesCount = getCountFromQueryData(
    qReachableVulnerabilitiesCount?.data
  );

  const potentiallyReachableVulnerabilitiesCount = getCountFromQueryData(
    qPotentiallyReachableVulnerabilitiesCount?.data
  );

  const data = [
    {
      key: V1FindingTags.ReachableFunction,
      label: fm({ defaultMessage: 'Reachable' }),
      value: reachableVulnerabilitiesCount ?? 0,
    },
    {
      key: V1FindingTags.PotentiallyReachableFunction,
      label: fm({ defaultMessage: 'Potentially Reachable' }),
      value: potentiallyReachableVulnerabilitiesCount ?? 0,
    },
    {
      key: V1FindingTags.UnreachableFunction,
      label: fm({ defaultMessage: 'Unreachable' }),
      value: unreachableVulnerabilitiesCount ?? 0,
    },
  ];

  const isLoading = [
    qReachableVulnerabilitiesCount,
    qPotentiallyReachableVulnerabilitiesCount,
    qUnreachableVulnerabilitiesCount,
  ].some(isQueryStateLoading);

  return { data, isLoading };
};

const useInventoryMetrics = ({
  namespace,
}: Pick<DashboardInventoryProps, 'namespace'>) => {
  const { formatMessage: fm } = useIntl();
  const { getQueryStatus } = useDashboardQueries({
    namespace,
  });

  const qProjectCount = getQueryStatus({
    resourceKind: 'Project',
    count: true,
    filters: [`spec.platform_source != ${V1PlatformSource.Unspecified}`],
  });

  const qPackageVersionCount = getQueryStatus({
    resourceKind: 'PackageVersion',
    count: true,
    filters: [filterExpressionBuilders.mainResourceContext()],
  });

  const qScanResultCount = getQueryStatus({
    resourceKind: 'ScanResult',
    count: true,
    filters: [filterExpressionBuilders.mainResourceContext()],
  });

  const qNotificationCount = getQueryStatus({
    resourceKind: 'Notification',
    count: true,
    filters: [filterExpressionBuilders.mainResourceContext()],
  });

  const projectCount = getCountFromQueryData(qProjectCount?.data);
  const packageVersionCount = getCountFromQueryData(qPackageVersionCount?.data);
  const scanResultCount = getCountFromQueryData(qScanResultCount?.data);
  const notificationCount = getCountFromQueryData(qNotificationCount?.data);

  const data = [
    {
      key: 'projects',
      label: fm({ defaultMessage: 'Projects' }),
      value: projectCount,
      link: getProjectPath({ tenantName: namespace }),
    },
    {
      key: 'packages',
      label: fm({ defaultMessage: 'Packages' }),
      value: packageVersionCount,
      link: getPackageVersionRootPath({ tenantName: namespace }),
    },
    {
      key: 'scans',
      label: fm({ defaultMessage: 'Scans' }),
      value: scanResultCount,
    },
    {
      key: 'notifications',
      label: fm({ defaultMessage: 'Notifications' }),
      value: notificationCount,
      link: getNotificationsPath({ tenantName: namespace, section: 'all' }),
    },
  ];

  const isLoading = [
    qProjectCount,
    qPackageVersionCount,
    qScanResultCount,
    qNotificationCount,
  ].some(isQueryStateLoading);

  return { data, isLoading };
};

export type DashboardInventoryProps = {
  namespace: string;
};

export const DashboardInventory = ({ namespace }: { namespace: string }) => {
  const { space, palette } = useTheme();
  const navigate = useNavigate();

  const dependencyMetrics = useDependencyMetrics({ namespace });
  const vulnerabilityMetrics = useVulnerabilityMetrics({ namespace });
  const inventoryMetrics = useInventoryMetrics({ namespace });

  const handleDependenciesBreakdownChartClick = useCallback(
    ({ key }: { key: string }) => {
      const filterValues: ValueFilter[] = [];
      if (key === 'direct') {
        filterValues.push({
          key: 'spec.dependency_data.direct',
          comparator: 'EQUAL',
          value: true,
        });
      } else if (key === 'transitive') {
        filterValues.push({
          key: 'spec.dependency_data.direct',
          comparator: 'NOT_EQUAL',
          value: true,
        });
      }

      navigate({
        to: getDependencyPath({ tenantName: namespace, filterValues }),
      });
    },
    [namespace, navigate]
  );

  const handleVulnerabilitiesBreakdownChartClick = useCallback(
    ({ key }: { key: string }) => {
      const filterValues: ValueFilter[] = [
        // base filter for vulnerabilities
        {
          key: 'spec.finding_categories',
          comparator: 'CONTAINS',
          value: [V1FindingCategory.Vulnerability],
        },
      ];

      if ((Object.values(V1FindingTags) as string[]).includes(key)) {
        filterValues.push({
          key: 'spec.finding_tags',
          comparator: 'CONTAINS',
          value: [key],
        });
      }

      navigate({
        to: getFindingsPath({
          section: 'dependency',
          tenantName: namespace,
          filterValues,
        }),
      });
    },
    [namespace, navigate]
  );

  return (
    <Card>
      <CardHeader
        title={<FM defaultMessage="Scanned By Endor Labs" />}
        titleTypographyProps={{
          variant: 'h2',
        }}
      />
      <CardContent>
        <Grid container spacing={space.md}>
          <Grid item xl={2} md={4} sm={6} data-testid="dependency-metrics">
            <Stack direction="column" justifyContent="start" spacing={2}>
              <Typography variant="h3" textAlign="left" color="text.secondary">
                <FM defaultMessage="Dependencies" />
              </Typography>

              <DashboardInventoryBreakdownChart
                isLoading={dependencyMetrics.isLoading}
                data={dependencyMetrics.data}
                colorList={[Colors.GREEN, Colors.BLUE]}
                onClickHandler={handleDependenciesBreakdownChartClick}
              />
            </Stack>
          </Grid>

          <Grid item xl={2} md={4} sm={6} data-testid="vulnerability-metrics">
            <Stack direction="column" justifyContent="center" spacing={2}>
              <Typography variant="h3" textAlign="left" color="text.secondary">
                <FM defaultMessage="Vulnerabilities" />
              </Typography>

              <DashboardInventoryBreakdownChart
                isLoading={vulnerabilityMetrics.isLoading}
                data={vulnerabilityMetrics.data}
                colorList={[
                  Colors.GREEN,
                  Colors.BLUE,
                  palette.grey[200] as Colors,
                ]}
                onClickHandler={handleVulnerabilitiesBreakdownChartClick}
              />
            </Stack>
          </Grid>

          {/* HACK: force grid to newline below `xl` breakpoint */}
          <Box
            sx={(t) => ({
              width: '100%',
              [t.breakpoints.up('xl')]: {
                display: 'none',
              },
            })}
          />

          {inventoryMetrics.data.map((m) => (
            <Grid
              item
              xl={2}
              lg={3}
              sm={6}
              key={m.key}
              data-testid="inventory-metrics"
              minWidth="max-content"
            >
              <Stack direction="column" spacing={space.sm}>
                <Typography
                  variant="h3"
                  textAlign="left"
                  color="text.secondary"
                >
                  {m.label}
                </Typography>

                <Typography
                  variant="xxl"
                  textAlign="left"
                  color={palette.text.tertiary}
                  sx={{
                    '& .MuiTypography-root': {
                      // HACK: overrides for nil-display
                      color: palette.text.tertiary,
                      fontSize: 'inherit',
                      lineHeight: 'inherit',
                    },
                    // Remove link styling
                    '&:hover': { textDecoration: 'none' },
                  }}
                  component={m.link ? Link : 'span'}
                  to={m.link}
                >
                  {inventoryMetrics.isLoading ? (
                    <Skeleton variant="rounded" height={64} width={80} />
                  ) : m.value !== undefined ? (
                    <FormattedNumber
                      notation="compact"
                      maximumFractionDigits={1}
                      maximumSignificantDigits={3}
                      value={m.value}
                    />
                  ) : (
                    <NilDisplay variant="text" />
                  )}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
