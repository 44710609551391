import { Box, Stack, Theme, Typography, useTheme } from '@mui/material';

import {
  CopyToClipboardButton,
  DiffStat,
  DrawerAccordion,
  RowStack,
  useStyles,
} from '@endorlabs/ui-common';
import { CodeEditor } from '@endorlabs/ui-common/domains/code';

export interface Patch {
  commitDate?: string;
  diff?: string;
  isEndorPatch?: boolean;
  linesAdded?: number;
  linesRemoved?: number;
  nameOrig?: string;
  sha?: string;
}

interface PatchDisplayAccordionProps {
  expanded?: boolean;
  patch: Patch;
}

export const PatchDisplayAccordion = ({
  expanded,
  patch,
}: PatchDisplayAccordionProps) => {
  const { space } = useTheme();
  const sx = useStyles(styles);

  const header = (
    <Stack gap={space.xs}>
      <RowStack gap={space.sm}>
        <RowStack flexGrow={2}>
          <DiffStat
            added={patch.linesAdded ?? 0}
            removed={patch.linesRemoved ?? 0}
          />
        </RowStack>

        <RowStack flexShrink={0} gap={2} justifySelf="flex-end">
          <Typography variant="code">{patch.sha}</Typography>
          <CopyToClipboardButton value={patch.diff ?? ''} />
          {/* <IconCode fontSize="small" /> */}
        </RowStack>
      </RowStack>

      <Typography
        component="span"
        variant="body2"
        sx={{ wordBreak: 'break-word' }}
      >
        {patch.nameOrig}
      </Typography>
    </Stack>
  );

  return (
    <Box sx={sx}>
      <DrawerAccordion
        align="start"
        expanded={expanded}
        id="Patch"
        titleNode={header}
      >
        <CodeEditor
          editorOptions={{
            lineNumbers: false,
            lineWrapping: false,
          }}
          enableClipboard={false}
          height="auto"
          language="text/x-diff"
          value={patch.diff}
          width="100%"
        />
      </DrawerAccordion>
    </Box>
  );
};

const styles = ({ palette, space }: Theme) => ({
  '& .MuiAccordionSummary-root': {
    borderBottom: `1px solid ${palette.divider}`,
    padding: `${space.sm}`,
  },

  '& .MuiAccordionDetails-root': {
    padding: 0,
  },

  '& .CodeMirror': { border: 0 },
});
``;
