import { useCallback } from 'react';

import { useDeleteSemgrepRules } from '@endorlabs/queries';

export const useSASTRulesRules = (namespace: string) => {
  const qDeleteRule = useDeleteSemgrepRules();
  const deleteRule = useCallback(
    (uuid: string) => {
      qDeleteRule.mutate({ namespace, resource: { uuid } });
    },
    [namespace, qDeleteRule]
  );

  return {
    deleteRule,
  };
};
