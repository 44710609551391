import { Stack, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  IconArrowRight,
  Link,
  RowStack,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { DetailDrawerSection } from '../../../components/DetailDrawer/DetailDrawerSection';
import {
  getAssuredPackageVersionPath,
  getProjectDetailPath,
  getRemediationsPath,
} from '../../../routes';
import {
  RemediationRecommendationDisplay,
  RemediationRecommendationType,
} from '../../Remediations';
import { useFindingDetailData } from '../hooks';

interface FindingRemediationsSectionProps {
  findingUuid?: string;
  namespace: string;
}

export const FindingRemediationsSection = ({
  findingUuid,
  namespace,
}: FindingRemediationsSectionProps) => {
  const { space } = useTheme();

  const { assuredPackageVersion, finding, project, versionUpgrade } =
    useFindingDetailData({
      findingUuid,
      namespace,
    });

  const { label: depLabel, version: depVersion } =
    UIPackageVersionUtils.parsePackageName(
      finding?.spec.target_dependency_package_name
    );

  return (
    <DetailDrawerSection
      title={<FM defaultMessage="Remediation Recommendation" />}
    >
      <Stack gap={space.sm}>
        {assuredPackageVersion && (
          <RemediationRecommendationDisplay
            recommendation={RemediationRecommendationType.Patch}
            fromVersion={depVersion}
            toVersion={
              UIPackageVersionUtils.parsePackageName(
                assuredPackageVersion?.meta.name
              ).version
            }
            packageName={depLabel}
            url={getAssuredPackageVersionPath({
              namespace,
              packageVersionName:
                finding?.spec.target_dependency_package_name ?? '',
            })}
          />
        )}

        {versionUpgrade && (
          <RemediationRecommendationDisplay
            recommendation={RemediationRecommendationType.Upgrade}
            fromVersion={versionUpgrade.spec?.upgrade_info?.from_version}
            toVersion={versionUpgrade.spec?.upgrade_info?.to_version}
            packageName={
              versionUpgrade.spec?.upgrade_info?.direct_dependency_package
            }
            remediationRisk={versionUpgrade.spec?.upgrade_info?.upgrade_risk}
            url={getRemediationsPath({
              tenantName: namespace,
              uuid: versionUpgrade?.uuid,
            })}
          />
        )}

        <Link
          to={getProjectDetailPath({
            tenantName: namespace,
            uuid: project?.uuid,
            section: 'remediations',
          })}
          search={{ 'filter.search': finding?.spec.target_dependency_name }}
        >
          <RowStack>
            <FM defaultMessage="View Full Recommendation" />
            <IconArrowRight />
          </RowStack>
        </Link>
      </Stack>
    </DetailDrawerSection>
  );
};
