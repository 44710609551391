import { createSvgIcon } from '@mui/material';
import {
  Activity,
  AlertCircle,
  AlertTriangle,
  Archive,
  ArrowDown,
  ArrowDownRight,
  ArrowRight,
  ArrowUp,
  ArrowUpRight,
  BarChart,
  Bell,
  Book,
  Bookmark,
  Box,
  Calendar,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsUp,
  ChevronUp,
  Circle,
  Clipboard,
  Clock,
  Cloud,
  CloudOff,
  Code,
  Command,
  Copy,
  CornerUpLeft,
  Download,
  Edit2,
  ExternalLink,
  FileText,
  Filter,
  Folder,
  GitBranch,
  GitCommit,
  GitHub,
  Gitlab,
  GitPullRequest,
  Globe,
  Heart,
  HelpCircle,
  Home,
  Info,
  Layers,
  Layout,
  Link,
  Loader,
  Lock,
  Mail,
  Menu,
  Minus,
  MinusCircle,
  MinusSquare,
  Moon,
  MoreVertical,
  Package,
  Plus,
  PlusCircle,
  PlusSquare,
  RefreshCw,
  RotateCcw,
  Save,
  Search,
  Settings,
  Share2,
  Sidebar,
  Slash,
  Square,
  Star,
  Sun,
  Target,
  Terminal,
  Tool,
  Trash2,
  TrendingDown,
  TrendingUp,
  Unlock,
  Upload,
  UploadCloud,
  Users,
  Wind,
  X,
  XCircle,
  Zap,
} from 'react-feather';

import ArrowOut from './ArrowOut';
import BarChartHorizontal from './BarChartHorizontal';
import CircleFilled from './CircleFilled';
import Constellation from './Constellation';
import Cve from './Cve';
import Cwe from './Cwe';
import EndorLogo from './EndorLogo';
import EnergyLeaf from './EnergyLeaf';
import Galaxy from './Galaxy';
import Planet from './Planet';
import Prioritize from './Prioritize';
import Rocket from './Rocket';
import SemiCircleFilled from './SemiCircleFilled';
import SidebarRight from './SidebarRight';
import SquareFilled from './SquareFilled';
import Telescope from './Telescope';
import TerminalOutlined from './TerminalOutlined';
import Ticket from './Ticket';
import TriangleFilled from './TriangleFilled';
import Ufo from './Ufo';
import Verified from './Verified';

/**
 * Export any icons we use from the Feather icon set as SvgIcon components
 */
export const IconActivity = createSvgIcon(<Activity />, 'IconActivity');
export const IconAlertCircle = createSvgIcon(
  <AlertCircle />,
  'IconAlertCircle'
);
export const IconAlertTriangle = createSvgIcon(
  <AlertTriangle />,
  'AlertTriangle'
);
export const IconArchive = createSvgIcon(<Archive />, 'IconArchive');
export const IconArrowDown = createSvgIcon(<ArrowDown />, 'IconArrowDown');
export const IconArrowDownRight = createSvgIcon(
  <ArrowDownRight />,
  'IconArrowDownRight'
);
export const IconArrowRight = createSvgIcon(<ArrowRight />, 'IconArrowOut');
export const IconArrowOut = createSvgIcon(<ArrowOut />, 'IconArrowOut');
export const IconArrowUp = createSvgIcon(<ArrowUp />, 'IconArrowUp');
export const IconArrowUpRight = createSvgIcon(
  <ArrowUpRight />,
  'IconArrowUpRight'
);
export const IconBarChart = createSvgIcon(<BarChart />, 'IconBarChart');
export const IconBarChartHorizontal = createSvgIcon(
  <BarChartHorizontal />,
  'IconBarChartHorizontal'
);
export const IconBell = createSvgIcon(<Bell />, 'IconBell');
export const IconBook = createSvgIcon(<Book />, 'IconBook');
export const IconBookmark = createSvgIcon(<Bookmark />, 'IconBookmark');
export const IconBox = createSvgIcon(<Box />, 'IconBox');
export const IconCalendar = createSvgIcon(<Calendar />, 'IconCalendar');
export const IconCheck = createSvgIcon(<Check />, 'IconCheck');
export const IconCheckSquare = createSvgIcon(
  <CheckSquare />,
  'IconCheckSquare'
);
export const IconCheckCircle = createSvgIcon(
  <CheckCircle />,
  'IconCheckCircle'
);
export const IconChevronDown = createSvgIcon(
  <ChevronDown />,
  'IconChevronDown'
);
export const IconChevronLeft = createSvgIcon(
  <ChevronLeft />,
  'IconChevronLeft'
);
export const IconChevronRight = createSvgIcon(
  <ChevronRight />,
  'IconChevronRight'
);
export const IconChevronUp = createSvgIcon(<ChevronUp />, 'IconChevronUp');
export const IconChevronsUp = createSvgIcon(<ChevronsUp />, 'IconChevronsUp');
export const IconCircle = createSvgIcon(<Circle />, 'IconCircle');
export const IconCircleFilled = createSvgIcon(
  <CircleFilled />,
  'IconCircleFilled'
);
export const IconClipboard = createSvgIcon(<Clipboard />, 'IconClipboard');
export const IconClock = createSvgIcon(<Clock />, 'IconClock');
export const IconCloud = createSvgIcon(<Cloud />, 'IconCloud');
export const IconCloudOff = createSvgIcon(<CloudOff />, 'IconCloudOff');
export const IconCode = createSvgIcon(<Code />, 'IconCode');
export const IconCommand = createSvgIcon(<Command />, 'IconCommand');
export const IconConstellation = createSvgIcon(
  <Constellation />,
  'IconConstellation'
);
export const IconCopy = createSvgIcon(<Copy />, 'IconCopy');
export const IconCve = createSvgIcon(<Cve />, 'IconCve');
export const IconCwe = createSvgIcon(<Cwe />, 'IconCwe');
export const IconDownload = createSvgIcon(<Download />, 'IconDownload');
export const IconDependency = createSvgIcon(<Share2 />, 'IconDependency');
export const IconEdit = createSvgIcon(<Edit2 />, 'IconEdit');
export const IconExternalLink = createSvgIcon(
  <ExternalLink />,
  'IconExternalLink'
);
export const IconEndorLogo = createSvgIcon(<EndorLogo />, 'IconEndorLogo');
export const IconEnergyLeaf = createSvgIcon(<EnergyLeaf />, 'IconEnergyLeaf');
export const IconFileText = createSvgIcon(<FileText />, 'IconFileText');
export const IconFilter = createSvgIcon(<Filter />, 'IconFilter');
export const IconFolder = createSvgIcon(<Folder />, 'IconFolder');
export const IconGalaxy = createSvgIcon(<Galaxy />, 'IconGalaxy');
export const IconGitBranch = createSvgIcon(<GitBranch />, 'IconGitBranch');
export const IconGitCommit = createSvgIcon(<GitCommit />, 'IconGitCommit');
export const IconGitHub = createSvgIcon(<GitHub />, 'IconGitHub');
export const IconGitLab = createSvgIcon(<Gitlab />, 'IconGitLab');
export const IconGitPullRequest = createSvgIcon(
  <GitPullRequest />,
  'IconGitPullRequest'
);
export const IconGlobe = createSvgIcon(<Globe />, 'IconGlobe');
export const IconHeart = createSvgIcon(<Heart />, 'IconHeart');
export const IconHelp = createSvgIcon(<HelpCircle />, 'IconHelp');
export const IconHome = createSvgIcon(<Home />, 'IconHome');
export const IconInfo = createSvgIcon(<Info />, 'IconInfo');
export const IconLayers = createSvgIcon(<Layers />, 'IconLayers');
export const IconLink = createSvgIcon(<Link />, 'IconLink');
export const IconLayout = createSvgIcon(<Layout />, 'IconLayout');
export const IconLock = createSvgIcon(<Lock />, 'IconLock');
export const IconLoader = createSvgIcon(<Loader />, 'IconLoader');
export const IconMail = createSvgIcon(<Mail />, 'IconMail');
export const IconMenu = createSvgIcon(<Menu />, 'IconMenu');
export const IconMinus = createSvgIcon(<Minus />, 'IconMinus');
export const IconMinusCircle = createSvgIcon(
  <MinusCircle />,
  'IconMinusCircle'
);
export const IconMinusSquare = createSvgIcon(
  <MinusSquare />,
  'IconMinusSquare'
);
export const IconMoon = createSvgIcon(<Moon />, 'IconMoon');
export const IconMoreVertical = createSvgIcon(
  <MoreVertical />,
  'IconMoreVertical'
);
export const IconPackage = createSvgIcon(<Package />, 'IconPackage');
export const IconPlanet = createSvgIcon(<Planet />, 'IconPlanet');
export const IconPlus = createSvgIcon(<Plus />, 'IconPlus');
export const IconPlusCircle = createSvgIcon(<PlusCircle />, 'IconPlusCircle');
export const IconPlusSquare = createSvgIcon(<PlusSquare />, 'IconPlusSquare');
export const IconPrioritize = createSvgIcon(<Prioritize />, 'IconPrioritize');
export const IconRocket = createSvgIcon(<Rocket />, 'IconRocket');
export const IconRotateCcw = createSvgIcon(<RotateCcw />, 'IconRotateCcw');
export const IconRefreshCw = createSvgIcon(<RefreshCw />, 'IconRefreshCw');
export const IconSave = createSvgIcon(<Save />, 'IconSave');
export const IconSearch = createSvgIcon(<Search />, 'IconSearch');
export const IconSemiCircleFilled = createSvgIcon(
  <SemiCircleFilled />,
  'IconSemiCircleFilled'
);
export const IconSettings = createSvgIcon(<Settings />, 'IconSettings');
export const IconSidebar = createSvgIcon(
  <Sidebar style={{ transform: 'scaleX(-1)' }} />,
  'IconSidebar'
);
export const IconSidebarRight = createSvgIcon(
  <SidebarRight />,
  'IconSidebarRight'
);
export const IconSlash = createSvgIcon(<Slash />, 'IconSlash');
export const IconSquare = createSvgIcon(<Square />, 'IconSquare');
export const IconSun = createSvgIcon(<Sun />, 'IconSun');
export const IconSquareFilled = createSvgIcon(
  <SquareFilled />,
  'IconSquareFilled'
);
export const IconStar = createSvgIcon(<Star />, 'IconStar');
export const IconTarget = createSvgIcon(<Target />, 'IconTarget');
export const IconTelescope = createSvgIcon(<Telescope />, 'IconTelescope');
export const IconTerminal = createSvgIcon(<Terminal />, 'IconTerminal');
export const IconTerminalOutlined = createSvgIcon(
  <TerminalOutlined />,
  'IconTerminalOutlined'
);
export const IconTicket = createSvgIcon(<Ticket />, 'IconTicket');
export const IconTool = createSvgIcon(<Tool />, 'IconTool');
export const IconTrash = createSvgIcon(<Trash2 />, 'IconTrash');
export const IconTrendingDown = createSvgIcon(
  <TrendingDown />,
  'IconTrendingDown'
);
export const IconTrendingUp = createSvgIcon(<TrendingUp />, 'IconTrendingUp');
export const IconTriangleFilled = createSvgIcon(
  <TriangleFilled />,
  'IconTriangleFilled'
);
export const IconUfo = createSvgIcon(<Ufo />, 'IconUfo');
export const IconUnlock = createSvgIcon(<Unlock />, 'IconUnlock');
export const IconUpload = createSvgIcon(<Upload />, 'IconUpload');
export const IconUploadCloud = createSvgIcon(
  <UploadCloud />,
  'IconUploadCloud'
);
export const IconUsers = createSvgIcon(<Users />, 'IconUsers');
export const IconVerified = createSvgIcon(<Verified />, 'IconVerified');
export const IconWind = createSvgIcon(<Wind />, 'IconWind');
export const IconX = createSvgIcon(<X />, 'IconX');
export const IconXCircle = createSvgIcon(<XCircle />, 'IconXCircle');
export const IconZap = createSvgIcon(<Zap />, 'IconZap');
export const IconCornerUpLeft = createSvgIcon(
  <CornerUpLeft />,
  'IconCornerUpLeft'
);
