import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  InstallationServiceApi,
  V1CountResponse,
  V1Installation,
  V1ListParameters,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import { InstallationResource, InstallationResourceList } from './types';
import { ResourceMutateOptions, ResourceQueryOptions } from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  DEFAULT_UPDATE_MASK,
  getClientConfiguration,
} from './utils';

export interface InstallationReadParams {
  namespace: string;
  uuid: string;
}
export interface InstallationWriteParams {
  namespace: string;
  resource: V1Installation;
}

export interface InstallationUpdateParams extends InstallationWriteParams {
  mask?: string;
}

type CountInstallationOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListInstallationOptions = ResourceQueryOptions<InstallationResourceList>;

type CreateInstallationOptions = ResourceMutateOptions<
  V1Installation,
  InstallationWriteParams
>;

type DeleteInstallationOptions = ResourceMutateOptions<
  object,
  InstallationReadParams
>;
type UpsertInstallationOptions = ResourceMutateOptions<
  V1Installation,
  InstallationUpdateParams
>;

const BASE_KEY = 'v1/installations';

const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};

export const InstallationQueryKeys = QK;

const getApiService = () =>
  new InstallationServiceApi(getClientConfiguration());

const countInstallations = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.installationServiceListInstallations(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountInstallations = (
  namespace: string,
  opts: CountInstallationOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Installation',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countInstallations(namespace, requestParameters),
    opts
  );
};

const listInstallations = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.installationServiceListInstallations(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as InstallationResourceList;
};

export const useListInstallations = (
  namespace: string,
  opts: ListInstallationOptions = {},
  listParams?: ListRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Installation',
    'COUNT',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listInstallations(namespace, requestParameters),
    opts
  );
};

const createInstallation = async (params: InstallationWriteParams) => {
  const api = getApiService();
  const resp = await api.installationServiceCreateInstallation(
    params.namespace,
    params.resource,
    {
      // Increase timeout, to support VERY long installation calls.
      // BE is waiting on GitHub call to complete, and this call does not
      // return until the call completes.
      headers: {
        'Request-Timeout': 600,
      },
    }
  );
  return resp.data as InstallationResource;
};

export const useCreateInstallation = (opts: CreateInstallationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'Installation'),
    mutationFn: (params: InstallationWriteParams) => createInstallation(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteInstallation = async (params: InstallationReadParams) => {
  const api = getApiService();
  const resp = await api.installationServiceDeleteInstallation(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteInstallation = (opts: DeleteInstallationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'Installation'),
    mutationFn: (params: InstallationReadParams) => deleteInstallation(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        queryClient.invalidateQueries(QK.record(vars.namespace, vars.uuid));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateInstallation = async (params: InstallationUpdateParams) => {
  const { resource, namespace, mask = DEFAULT_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.installationServiceUpdateInstallation(namespace, req);
  return resp.data as InstallationResource;
};

export const useUpdateInstallation = (opts: UpsertInstallationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'Installation'),
    mutationFn: (params: InstallationUpdateParams) =>
      updateInstallation(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
