import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useMatch } from '@tanstack/react-location';
import { useMemo } from 'react';
import { FormattedMessage as FM, FormattedNumber } from 'react-intl';

import { V1ScoreCategory } from '@endorlabs/api_client';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import {
  selectMetricScores,
  selectMetricScoresByCategory,
  useQueryHuggingFaceModels,
} from '@endorlabs/queries';
import {
  AttributeDisplayStack,
  BooleanDisplay,
  ButtonExternalLink,
  DateDisplay,
  ExternalLink,
  IconDatabase,
  IconFrame,
  IconTitleDisplay,
  ImgIconLogoHuggingFace,
  MetadataMetric,
  NilDisplay,
  NumberDisplay,
  RowStack,
  ScoreCategoryMessages,
  TagsDisplay,
  useStyles,
} from '@endorlabs/ui-common';

import {
  PageHeader,
  ScoreFactorsListTabs,
  ScoreFactorsOverview,
} from '../../components';
import { ScoreCardDisplayItemV2 } from '../../components/ScoreCardDisplay/ScoreCardDisplayItemV2';
import { Layout } from '../../constants';
import { HUGGING_FACE_BASE_URL } from '../../domains/OSS';

const MAX_DATASETS_LIST_SIZE = 10;

const METADATA_ATTRIBUTE_RECORDS = [
  { attributeKey: 'url', heading: <FM defaultMessage="URL" /> },
  {
    attributeKey: 'license',
    heading: <FM defaultMessage="License" />,
  },
  { attributeKey: 'modelSize', heading: <FM defaultMessage="Model Size" /> },
  { attributeKey: 'tensorType', heading: <FM defaultMessage="Tensor Type" /> },
  { attributeKey: 'author', heading: <FM defaultMessage="Author" /> },
  {
    attributeKey: 'modified',
    heading: <FM defaultMessage="Last Modified" />,
  },
  { attributeKey: 'created', heading: <FM defaultMessage="Created" /> },
  {
    attributeKey: 'requiresAuth',
    heading: <FM defaultMessage="Requires Authentication" />,
  },
  { attributeKey: 'gated', heading: <FM defaultMessage="Gated" /> },
  { attributeKey: 'downloads', heading: <FM defaultMessage="Downloads" /> },
  { attributeKey: 'library', heading: <FM defaultMessage="Library" /> },
  { attributeKey: 'likes', heading: <FM defaultMessage="Likes" /> },
  {
    attributeKey: 'spaces',
    heading: <FM defaultMessage="Spaces using model" />,
  },
  { attributeKey: 'tags', heading: <FM defaultMessage="Tags" /> },
];

export const HuggingFaceModelDetailPage = () => {
  const {
    params: { modelUuid },
  } = useMatch();
  const { space } = useTheme();
  const containerSx = useStyles(containerStyles);

  const qHuggingFaceModel = useQueryHuggingFaceModels(
    NAMESPACES.OSS,
    {
      filter: `uuid==${modelUuid}`,
    },
    { enabled: !!modelUuid }
  );

  const model = qHuggingFaceModel.data?.list?.objects?.[0];
  const datasets = model?.spec?.card_data?.datasets ?? [];
  const topDatasets = datasets.slice(0, MAX_DATASETS_LIST_SIZE);

  const Metric = model?.meta.references?.ScorecardMetric.list?.objects?.[0];
  const modelUrl = `${HUGGING_FACE_BASE_URL}/${model?.spec.model_id}`;

  const scores = selectMetricScores(Metric);

  const scoresByCategory = selectMetricScoresByCategory(Metric);
  const scoreCard = Metric?.spec.metric_values.scorecard.score_card;
  const scoreFactors =
    Metric?.spec.metric_values.scorefactor.score_factor_list?.score_factors;

  const summary: MetadataMetric[] = [
    {
      label: <FM defaultMessage="Security" />,
      value: scores.scoreSecurity?.score,
      variant: 'score',
    },
    {
      label: <FM defaultMessage="Activity" />,
      value: scores.scoreActivity?.score,
      variant: 'score',
    },
    {
      label: <FM defaultMessage="Popularity" />,
      value: scores.scorePopularity?.score,
      variant: 'score',
    },
    {
      label: <FM defaultMessage="Quality" />,
      value: scores.scoreCodeQuality?.score,
      variant: 'score',
    },
    {
      label: <FM defaultMessage="Repository" />,
      value: modelUrl ? (
        <ExternalLink to={modelUrl}>{modelUrl}</ExternalLink>
      ) : (
        <NilDisplay variant="text" />
      ),
      variant: 'raw',
    },
  ];

  const resource = useMemo(() => {
    const { library_name, license, tags } = model?.spec.card_data ?? {};
    const { parameters, total } = model?.spec.safetensors ?? {};
    const tensorType = Object.keys(parameters ?? {});

    return {
      url: <ExternalLink to={modelUrl}>{modelUrl}</ExternalLink>,
      license: license,
      modelSize: (
        <FM
          defaultMessage="{total, number, ::compact-short} params"
          values={{ total }}
        />
      ),
      tensorType: tensorType.join(', '),
      author: model?.spec.author,
      modified: <DateDisplay value={model?.spec.last_modified} />,
      created: <DateDisplay value={model?.spec.created_at} />,
      requiresAuth: (
        <BooleanDisplay value={model?.spec.authorization_required ?? false} />
      ),
      gated: (
        <BooleanDisplay value={model?.spec.authorization_required ?? false} />
      ),
      downloads: model?.spec.downloads && (
        <FormattedNumber notation="compact" value={model?.spec.downloads} />
      ),
      library: library_name,
      likes: model?.spec.likes && (
        <FormattedNumber notation="compact" value={model?.spec.likes} />
      ),
      spaces: model?.spec.spaces && (
        <FormattedNumber notation="compact" value={model?.spec.spaces.length} />
      ),
      tags: <TagsDisplay tags={tags} />,
    };
  }, [model, modelUrl]);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={space.md}>
      <Grid item>
        <PageHeader
          image={
            <IconFrame size="large" variant="rounded">
              <ImgIconLogoHuggingFace fontSize="large" />
            </IconFrame>
          }
          isLoading={qHuggingFaceModel.isLoading}
          title={model?.spec?.name}
          metadata={{ summary }}
        />
      </Grid>

      <Grid item>
        <Stack sx={containerSx}>
          <Card className="ModelDetail-scores" elevation={0}>
            <CardContent sx={{ paddingX: 0 }}>
              <Stack
                alignItems="center"
                direction="column"
                padding={space.lg}
                spacing={space.md}
              >
                <Box>
                  <ScoreCardDisplayItemV2
                    colorize
                    score={
                      Metric?.spec.metric_values.scorecard.score_card
                        .overall_score
                    }
                    size="xlarge"
                    totalSize="xlarge"
                    showTotal
                    title={<FM defaultMessage="Endor Score" />}
                  />
                </Box>

                <Stack
                  direction="row"
                  spacing={space.md}
                  divider={<Divider flexItem orientation="vertical" />}
                  width="80%"
                >
                  {Object.entries(scoresByCategory).map(([category, score]) => (
                    <ScoreCardDisplayItemV2
                      colorize
                      key={category}
                      score={score}
                      size="large"
                      title={
                        <FM
                          {...ScoreCategoryMessages[
                            category as V1ScoreCategory
                          ]}
                        />
                      }
                    />
                  ))}
                </Stack>
              </Stack>

              <ScoreFactorsListTabs
                scoreCard={scoreCard}
                scoreFactors={scoreFactors}
                isLoading={qHuggingFaceModel.isLoading}
              />
            </CardContent>
          </Card>

          <Card className="ModelDetail-metadata" elevation={0}>
            <CardContent>
              <Stack spacing={space.md}>
                <Stack spacing={space.sm}>
                  <Typography variant="h3">
                    <FM defaultMessage="Top Score Factors" />
                  </Typography>
                  <ScoreFactorsOverview scoreFactors={scoreFactors} />
                </Stack>
                <Stack spacing={space.sm}>
                  <Typography variant="h3">
                    <FM defaultMessage="Model Metadata" />
                  </Typography>
                  <AttributeDisplayStack
                    resource={resource}
                    attributeRecords={METADATA_ATTRIBUTE_RECORDS}
                    headingWidth="30%"
                    variant="flex"
                  />
                </Stack>

                <Stack spacing={space.sm}>
                  <Typography variant="h3">
                    <FM defaultMessage="Datasets" />
                  </Typography>
                  <Stack spacing={space.xs}>
                    {topDatasets.length === 0 && (
                      <Typography>
                        <FM defaultMessage="No Datasets Found" />
                      </Typography>
                    )}
                    {topDatasets.map((dataset) => (
                      <IconTitleDisplay
                        key={dataset}
                        Icon={IconDatabase}
                        iconFrame
                        title={dataset}
                      />
                    ))}
                    <Box>
                      <ButtonExternalLink
                        fullWidth={false}
                        linkProps={{ to: modelUrl }}
                      >
                        Go to Hugging Face to see more
                      </ButtonExternalLink>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};

function containerStyles({ breakpoints, space, spacing }: Theme) {
  return {
    alignItems: 'stretch',
    flexDirection: 'column',
    gap: space.md,

    '.ModelDetail-scores': {
      flex: '1 0 0',
    },
    '.ModelDetail-metadata': {
      flex: '1 0 0',
    },

    [breakpoints.up('lg')]: {
      alignItems: 'stretch',
      flexDirection: 'row',

      '.RemediationDetail-details': {
        flexGrow: 1,
      },

      '.RemediationDetail-overview': {
        width: spacing(Layout.INFO_DRAWER_WIDTH),
        flexShrink: 0,
      },
    },
  } satisfies SxProps;
}
