import { Grid } from '@mui/material';
import { useNavigate, useRouter } from '@tanstack/react-location';
import { useEffect, useMemo, useRef } from 'react';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { RepoVersionResource } from '@endorlabs/queries';

import { ResourceFilterContextProvider } from '../../domains/filters';
import {
  REMEDIATIONS_SEARCH_KEYS,
  useRemediationsExportDialog,
} from '../../domains/Remediations';
import {
  RemediationsTableView,
  RemediationsTableViewRef,
} from '../../domains/Remediations/components/RemediationsTableView';
import { useAuthInfo, useLicensingInfo } from '../../providers';
import { getPoliciesPath } from '../../routes';

export interface ProjectVersionRemediationsProps {
  isLoading?: boolean;
  namespace: string;
  project?: ProjectResource;
  repositoryVersion?: RepoVersionResource;
}

/**
 * @deprecated
 * @see ProjectVersionRemediationsV2 */
export const ProjectVersionRemediations = ({
  isLoading: isProjectLoading,
  namespace: tenantName,
  project,
  repositoryVersion,
}: ProjectVersionRemediationsProps) => {
  const navigate = useNavigate();

  const { setLicenseInCurrentRoute } = useAuthInfo();

  const router = useRouter();

  const baseFilterExpression = useMemo(() => {
    const findingsReductionFilter = [
      'spec.upgrade_info.vuln_finding_info.reduction > 0',
      'spec.upgrade_info.other_finding_info.reduction > 0',
    ];
    const filterExpression = [
      `meta.name==PrioritizedPerPackage`,
      `spec.upgrade_info exists`,
      filterExpressionBuilders.or(findingsReductionFilter),
    ];

    if (project?.uuid) {
      filterExpression.push(`spec.project_uuid==${project?.uuid}`);
    }

    if (repositoryVersion?.context) {
      filterExpression.push(
        filterExpressionBuilders.relatedResourceContext(
          repositoryVersion
        ) as string
      );
    }

    return filterExpressionBuilders.and(filterExpression);
  }, [project, repositoryVersion]);

  const tableRef = useRef<RemediationsTableViewRef>(null);

  const { checkLicensePresent } = useLicensingInfo();

  const isRemediationLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.Recommendation
  );

  useEffect(() => {
    if (!isRemediationLicensePresent) {
      setLicenseInCurrentRoute({
        pathname: router.state.location.pathname,
        isLicense: false,
      });
      return () => {
        setLicenseInCurrentRoute({
          pathname: '',
          isLicense: true,
        });
      };
    }
  }, [
    isRemediationLicensePresent,
    router.state.location.pathname,
    setLicenseInCurrentRoute,
  ]);

  const remediationsExportDialog = useRemediationsExportDialog();

  const handleExport = () => {
    const remediationsFilterExpression = tableRef.current
      ? tableRef.current?.remediationsFilterExpression
      : baseFilterExpression;
    remediationsExportDialog.openDialog({
      namespace: tenantName,
      filter: remediationsFilterExpression,
      downloadProps: {
        filename: `project_${project?.uuid}_remediations-export.csv`,
      },
    });
  };

  const handleRefresh = () => {
    if (tableRef.current) {
      tableRef.current.refreshData();
    }
  };

  const handleCreatePolicy = () => {
    navigate({ to: getPoliciesPath({ tenantName, section: `actions/new` }) });
  };

  const actionList = [
    // NOTE: Hidden until create policy is available
    // {
    //   actionId: 'createPolicy',
    //   label: 'Create Policy',
    //   onApply: handleCreatePolicy,
    // },
    { actionId: 'export', label: 'Export', onApply: handleExport },
    { actionId: 'refresh', label: 'Refresh', onApply: handleRefresh },
  ];

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <ResourceFilterContextProvider
          namespace={tenantName}
          resourceKind="VersionUpgrade"
          resourceSearchKeys={REMEDIATIONS_SEARCH_KEYS}
        >
          <RemediationsTableView
            actions={actionList}
            excludeColumns={['projectName']}
            baseFilterExpression={baseFilterExpression}
            ref={tableRef}
            tenantName={tenantName}
          />
        </ResourceFilterContextProvider>
      </Grid>
      <remediationsExportDialog.Dialog
        {...remediationsExportDialog.getDialogProps()}
      />
    </Grid>
  );
};
