import { Badge, Box, Theme, ToggleButton, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM, FormattedNumber } from 'react-intl';

import { FindingSource } from '@endorlabs/endor-core/Finding';
import { ExclusiveToggleButtonGroup, useStyles } from '@endorlabs/ui-common';

export type FindingSourceToggleOption = {
  value: FindingSource;
  label: ReactNode;
  count?: number;
  isBeta?: boolean;
};

export const buildFindingSourceToggleOptions = (
  counts: {
    containerFindings?: number;
    dependencyFindings?: number;
    githubActionFindings?: number;
    packageFindings?: number;
    repositoryFindings?: number;
    secretsFindings?: number;
  },
  excludeList: FindingSource[] = []
) => {
  const {
    containerFindings = 0,
    dependencyFindings = 0,
    githubActionFindings = 0,
    packageFindings = 0,
    repositoryFindings = 0,
    secretsFindings = 0,
  } = counts;

  // NOTE: container findings count has overlap with dependencies, and is not
  // used in count towards total.
  const totalFindings =
    dependencyFindings +
    githubActionFindings +
    packageFindings +
    repositoryFindings +
    secretsFindings;

  const options: FindingSourceToggleOption[] = [
    {
      value: FindingSource.All,
      label: <FM defaultMessage="All" />,
      count: totalFindings,
    },
    {
      value: FindingSource.Dependency,
      label: <FM defaultMessage="Dependencies" />,
      count: dependencyFindings,
    },
    {
      value: FindingSource.Package,
      label: <FM defaultMessage="Packages" />,
      count: packageFindings,
    },
    {
      value: FindingSource.Repository,
      label: <FM defaultMessage="Repository" />,
      count: repositoryFindings,
    },
    {
      value: FindingSource.Secrets,
      label: <FM defaultMessage="Secrets" />,
      count: secretsFindings,
    },
    {
      value: FindingSource.GithubAction,
      label: <FM defaultMessage="CI Workflows" />,
      count: githubActionFindings,
      isBeta: true,
    },
    {
      value: FindingSource.Container,
      label: <FM defaultMessage="Containers" />,
      count: containerFindings,
    },
  ];

  return options.filter((option) => !excludeList.includes(option.value));
};

const DEFAULT_FINDING_SOURCE_TOGGLE_OPTIONS = buildFindingSourceToggleOptions(
  {}
);

/**
 * Complementary component to a Filter or Search Bar in a Findings page. This
 * component is intended to present the options for Finding "source", used to
 * determine a base filter for Findings in the view.
 *
 * Wraps the {@see ExclusiveToggleButtonGroup} component, adding a margin and
 * arrows below to associate the toggles with the related search or filter.
 *
 * @example
 * <Box>
 *   <FindingSourceToggles
 *     hideFindingCounts={isLoadingCounts}
 *     options={findingSourceToggleOptions}
 *     onChange={(_, value) => value && setFindingSource(value)}
 *     value={findingSource}
 *   />
 *   <FilterBar fields={filterFields} />
 * </Box>
 */
export function FindingSourceToggles({
  hideArrow = false,
  hideFindingCounts = false,
  onChange,
  options = DEFAULT_FINDING_SOURCE_TOGGLE_OPTIONS,
  value,
}: {
  /**
   * If true, hides the arrows below the toggle buttons and removes the margin
   */
  hideArrow?: boolean;
  hideFindingCounts?: boolean;
  options: FindingSourceToggleOption[];
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    value: FindingSource
  ) => void;
  value: FindingSource;
}) {
  const badgeSx = useStyles(badgeStyles);
  const containerSx = useStyles(containerStyles, { hideArrow });

  return (
    <Box sx={containerSx}>
      <ExclusiveToggleButtonGroup value={value} onChange={onChange}>
        {options.map(({ count, isBeta, label, value }) => {
          if (isBeta) {
            return (
              <ToggleButton
                key={value}
                value={value}
                disabled={count === 0}
                className="FindingSourceToggleButton-beta"
              >
                <Badge
                  badgeContent={<FM defaultMessage="Beta" />}
                  color="warning"
                  sx={badgeSx}
                >
                  {label}

                  {!hideFindingCounts && !!count && (
                    <Typography variant="inherit">
                      <FormattedNumber value={count} notation="compact" />
                    </Typography>
                  )}
                </Badge>
              </ToggleButton>
            );
          }
          return (
            <ToggleButton key={value} value={value} disabled={count === 0}>
              {label}

              {!hideFindingCounts && !!count && (
                <Typography variant="inherit">
                  <FormattedNumber value={count} notation="compact" />
                </Typography>
              )}
            </ToggleButton>
          );
        })}
      </ExclusiveToggleButtonGroup>
    </Box>
  );
}

function containerStyles(
  { palette, spacing }: Theme,
  options?: { hideArrow?: boolean }
) {
  if (options?.hideArrow) return {};

  return {
    marginBottom: spacing(4),
    '& .MuiToggleButton-root.Mui-selected': {
      // Add arrow below the selected toggle group option
      '&::after': {
        content: '""',
        borderStyle: 'solid',
        borderWidth: '12px',
        borderColor: `transparent transparent ${palette.background.paper} transparent`,
        position: 'absolute',
        bottom: '-16px',
      },
    },
    // HACK: ensure space after button with beta badge
    '& .MuiToggleButtonGroup-grouped:not(:last-of-type).MuiToggleButton-root.FindingSourceToggleButton-beta':
      {
        marginRight: spacing(7),
      },
  };
}

function badgeStyles({ spacing }: Theme) {
  return {
    gap: spacing(1),
    '& .MuiBadge-badge': {
      fontSize: 10,
      right: spacing(-4),
      textTransform: 'uppercase',
    },
  };
}
