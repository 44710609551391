export enum PlatformSourceType {
  GITHUB = 'GITHUB',
  GITLAB = 'GITLAB',
  BITBUCKET = 'BITBUCKET',
  BINARY = 'BINARY',
  HUGGINGFACE = 'HUGGINGFACE',
}

export enum SupportedOS {
  Linux = 'LINUX',
  MacOS = 'MAC',
  Windows = 'WINDOWS',
}

export enum InstallMode {
  HOMEBREW = 'HOMEBREW',
  NPM = 'NPM',
  CURL = 'CURL',
}

export enum AuthMode {
  Google = 'GOOGLE',
  GitHub = 'GITHUB',
  GitLab = 'GITLAB',
  Email = 'EMAIL',
}

export enum EndorctlVersions {
  LATESTVERSION = 'LATESTVERSION',
  SPECIFICVERSION = 'SPECIFICVERSION',
}
