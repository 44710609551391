import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';

import { useInfoDrawer } from '../../../components/InfoDrawer';
import {
  AssuredPackageVersionDetailDrawer,
  AssuredPackageVersionDetailDrawerProps,
} from '../components/AssuredPackageVersionDetailDrawer';

export const useAssuredPackageVersionDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    AssuredPackageVersionDetailDrawerProps,
    AssuredPackageVersionDetailDrawerProps
  >({
    drawerParams: ['packageVersionName'],
    Component: AssuredPackageVersionDetailDrawer,
  });

  const permalinkEffect = ({
    assuredPackageVersions,
  }: {
    assuredPackageVersions: AssuredPackageVersionResource[];
  }) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();
    if (!activeDrawerParams?.packageVersionName) return;

    const packageVersionName = activeDrawerParams?.packageVersionName;

    const assuredPackageVersion = assuredPackageVersions.find(
      (a) => a.meta.name === activeDrawerParams.packageVersionName
    );
    if (!assuredPackageVersion) return;

    const nextDrawerParams = { packageVersionName };

    if (!DetailDrawer.matchesActiveContent(nextDrawerParams)) {
      DetailDrawer.activate(nextDrawerParams, nextDrawerParams);
    }
  };

  return { DetailDrawer, permalinkEffect };
};
