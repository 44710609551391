import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { useListAssuredPackageVersions } from '@endorlabs/queries';
import {
  PackageEcosystemIcons,
  Pip,
  RowStack,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../../components';
import {
  AssuredPackageVersionDetailDrawerOverview,
  AssuredPackageVersionDetailDrawerPatches,
} from './AssuredPackageVersionDetailDrawer';

export const AssuredPackageVersionDetailPage = ({
  packageVersionName,
}: {
  packageVersionName?: string;
}) => {
  const { space } = useTheme();

  const {
    label: packageName,
    ecosystem,
    version,
  } = UIPackageVersionUtils.parsePackageName(packageVersionName);
  const EcosystemIcon = PackageEcosystemIcons[ecosystem];

  const qListAssuredPackageVersions = useListAssuredPackageVersions(
    NAMESPACES.OSS,
    {
      filter: `meta.name=="${packageVersionName}"`,
      page_size: 1,
    },
    { enabled: !!packageVersionName }
  );

  const assuredPackageVersion = qListAssuredPackageVersions.data?.objects.at(0);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={space.md}>
      <Grid item>
        <PageHeader
          // TODO: enable patch instruction action when exists
          // action={
          //   <RowStack justifyContent="flex-end">
          //     <ButtonPrimary>
          //       <FM defaultMessage="Patch Instructions" />
          //     </ButtonPrimary>
          //   </RowStack>
          // }
          Icon={EcosystemIcon}
          title={
            <RowStack>
              <FM
                defaultMessage="Patch {packageName}"
                values={{ packageName }}
              />
              <Pip
                color="warning"
                label={version}
                size="large"
                variant="outlined"
              />
            </RowStack>
          }
        />
      </Grid>

      {/* Show details when AssuredPackageVersion exists */}
      {assuredPackageVersion && (
        <Grid item>
          <Grid container spacing={space.md}>
            <Grid item xs={4}>
              <Paper sx={{ paddingY: 6 }} variant="outlined">
                <Box paddingLeft={6} paddingBottom={4}>
                  <Typography variant="h3">
                    <FM defaultMessage="Overview" />
                  </Typography>
                </Box>

                <AssuredPackageVersionDetailDrawerOverview
                  assuredPackageVersion={assuredPackageVersion}
                />
              </Paper>
            </Grid>

            <Grid item xs={8}>
              <Paper sx={{ paddingY: 6 }} variant="outlined">
                <Box paddingLeft={6} paddingBottom={4}>
                  <Typography variant="h3">
                    <FM defaultMessage="Patches" />
                  </Typography>
                </Box>

                <AssuredPackageVersionDetailDrawerPatches
                  assuredPackageVersion={assuredPackageVersion}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
