import {
  Alert,
  AlertTitle,
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import produce from 'immer';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  SpecArchType,
  SpecBinaryType,
  V1PlatformSource,
} from '@endorlabs/api_client';
import {
  PluginBinaryResource,
  sortParamBuilders,
  useListPluginBinaries,
} from '@endorlabs/queries';
import { CodeBlock, DateDisplay } from '@endorlabs/ui-common';

import { useInfoDrawer } from '../../../components';
import { getEnv } from '../../../constants';
import {
  AuthMode,
  EndorctlVersions,
  InstallMode,
  PlatformSourceType,
  SupportedOS,
} from './constants';
import {
  getEndorctlInitCodeBlock,
  getEndorctlInstallCodeBlock,
  getEndorctlInstallCodeBlockWindows,
  getEndorctlScanCodeBlock,
  getHomebrewCodeBlock,
  getNpmCodeBlock,
  stepHeadingsEndorctl,
  stepHeadingsGeneral,
} from './endorctlStepsAndInstallationCode';
import { EnvVariablesInfoDrawer } from './EnvVariablesInfoDrawer';
import { StepHeading } from './StepHeading';
import {
  InstallModeType,
  OSArchTypeOption,
  PlatformSourceTypeOption,
  VersionType,
} from './types';

const RECENT_VERSION_COUNT = 50;
const ENV = getEnv();

const PLATFORM_SOURCE_TYPES: Record<
  V1PlatformSource,
  PlatformSourceTypeOption[]
> = {
  [V1PlatformSource.Unspecified]: [],
  [V1PlatformSource.Gitserver]: [],
  [V1PlatformSource.Github]: [
    {
      type: PlatformSourceType.GITHUB,
      label: <FM defaultMessage="GitHub" />,
    },
  ],
  [V1PlatformSource.Gitlab]: [
    {
      type: PlatformSourceType.GITLAB,
      label: <FM defaultMessage="Gitlab" />,
    },
  ],
  [V1PlatformSource.Bitbucket]: [
    {
      type: PlatformSourceType.BITBUCKET,
      label: <FM defaultMessage="Bitbucket" />,
    },
  ],
  [V1PlatformSource.Binary]: [
    {
      type: PlatformSourceType.BINARY,
      label: <FM defaultMessage="Binary" />,
    },
  ],
  [V1PlatformSource.HuggingFace]: [
    {
      type: PlatformSourceType.HUGGINGFACE,
      label: <FM defaultMessage="Hugging Face" />,
    },
  ],
  [V1PlatformSource.Azure]: [
    {
      type: PlatformSourceType.AZURE,
      label: <FM defaultMessage="Azure" />,
    },
  ],
};

const OS_TYPE_CHECKS: Record<SupportedOS, RegExp[]> = {
  LINUX: [/linux/i],
  MAC: [/macintosh/i],
  WINDOWS: [/windows/i],
};

const detectSupportedOS = (): SupportedOS | undefined => {
  const { userAgent } = navigator;

  for (const [os, checks] of Object.entries(OS_TYPE_CHECKS)) {
    const isCandidate = checks.every((check) => check.test(userAgent));
    if (isCandidate) {
      return os as SupportedOS;
    }
  }
};

const OS_ARCH_TYPES: Record<SupportedOS, OSArchTypeOption[]> = {
  LINUX: [
    { type: SpecArchType.LinuxAmd64, label: <FM defaultMessage="Intel" /> },
  ],
  MAC: [
    {
      type: SpecArchType.MacosArm64,
      label: <FM defaultMessage="Apple Silicon" />,
    },
    {
      type: SpecArchType.MacosAmd64,
      label: <FM defaultMessage="Intel" />,
    },
  ],
  WINDOWS: [
    {
      type: SpecArchType.WindowsAmd64,
      label: <FM defaultMessage="Windows" />,
    },
  ],
};

/**
 * Get the most recently published EndorCTL binary for the given version
 */
const useListEndorctlBinaries = (archType?: SpecArchType) => {
  return useListPluginBinaries(
    {
      enabled: !!archType,

      select: produce((data) => {
        const seen = new Set();

        // de-duplicate binaries by version
        if (data.list?.objects) {
          const binaries =
            data.list?.objects.filter((o) => {
              const version = o.spec.version;
              if (!version) return false;
              if (seen.has(version)) return false;

              seen.add(version);
              return true;
            }) ?? [];

          // HACK: transform download URL to replace googleapis with api download proxy
          binaries.forEach((o) => {
            if (o.spec.url?.startsWith('https://storage.googleapis.com/')) {
              o.spec.url = o.spec.url.replace(
                'https://storage.googleapis.com/',
                `${ENV.API_BASE_URL}/download/`
              );
            }
          });

          data.list.objects = binaries;
        }

        return data;
      }),
    },
    {
      filter: [
        // include only endorctl binaries for the target arch
        `spec.binary_type=="${SpecBinaryType.EndorctlBinary}"`,
        `spec.arch_type=="${archType}"`,
        // exclude binaries marked as 'unsupported'
        `spec.unsupported!=true`,
      ].join(' and '),
      mask: [
        'uuid',
        'spec.checksum',
        'spec.published_date',
        'spec.unsupported',
        'spec.url',
        'spec.version',
      ].join(','),
      page_size: RECENT_VERSION_COUNT,
      sort: sortParamBuilders.descendingBy('spec.published_date'),
    }
  );
};

interface EnvVarInfoDrawerParams {
  envVarInfo: boolean;
}

const ENV_DRAWER_FLAG = { envVarsInfo: 'true' };
interface NewProjectEndorCtlCliProps {
  tenantName: string;
  platformSource: V1PlatformSource;
}

export const NewProjectEndorCtlCli = ({
  tenantName,
  platformSource = V1PlatformSource.Github,
}: NewProjectEndorCtlCliProps) => {
  const [selectedPlatformSourceType, setSelectedPlatformSourceType] = useState<
    PlatformSourceType | undefined
  >();
  const { formatMessage: fm } = useIntl();
  const selectedPlatformSourceTypes = useMemo<
    PlatformSourceTypeOption[]
  >(() => {
    return PLATFORM_SOURCE_TYPES[platformSource] ?? [];
  }, [platformSource]);

  const [selectedAuthMode, setSelectedAuthMode] = useState<
    AuthMode | undefined
  >(AuthMode.Google);

  const [installationMode, setInstallationMode] = useState<InstallModeType>(
    InstallMode.HOMEBREW
  );
  const handleInstallationChange = (
    e: React.MouseEvent<HTMLElement>,
    value: InstallMode
  ) => {
    setInstallationMode(value);
    setSelectedVersion(EndorctlVersions.LATESTVERSION);
  };

  // default the first platform source type option as selected when switching platform sources
  useEffect(() => {
    if (selectedPlatformSourceTypes.length) {
      setSelectedPlatformSourceType(selectedPlatformSourceTypes[0].type);
    } else {
      setSelectedPlatformSourceType(undefined);
    }
  }, [selectedPlatformSourceTypes]);

  const [selectedPlatform, setSelectedPlatform] = useState<
    SupportedOS | undefined
  >(() => detectSupportedOS() ?? SupportedOS.Linux);

  const [selectedArchType, setSelectedArchType] = useState<
    SpecArchType | undefined
  >();

  // update the arch type base on the selected os type
  const archTypes = useMemo<OSArchTypeOption[]>(() => {
    if (selectedPlatform && selectedPlatform in OS_ARCH_TYPES) {
      return OS_ARCH_TYPES[selectedPlatform as SupportedOS] ?? [];
    }
    return [];
  }, [selectedPlatform]);

  // default the first arch type option as selected when switching os types
  useEffect(() => {
    if (archTypes.length) {
      setSelectedArchType(archTypes[0].type);
    } else {
      setSelectedArchType(undefined);
    }

    if (selectedPlatform === SupportedOS.MacOS) {
      setInstallationMode(InstallMode.HOMEBREW);
    } else {
      setInstallationMode(InstallMode.NPM);
    }
    setSelectedVersion(EndorctlVersions.LATESTVERSION);
  }, [archTypes, selectedPlatform]);

  const qListEndorctlBinaries = useListEndorctlBinaries(selectedArchType);

  const [endorctlBinaryOptions, hasEndorctlBinary] = useMemo(() => {
    const options = qListEndorctlBinaries.data?.list?.objects ?? [];
    return [options, !!options.length];
  }, [qListEndorctlBinaries.data]);

  const [selectedEndorctlBinary, setSelectedEndorctlBinary] =
    useState<PluginBinaryResource>();
  const [selectedVersion, setSelectedVersion] = useState<VersionType>(
    EndorctlVersions.LATESTVERSION
  );

  useEffect(() => {
    if (endorctlBinaryOptions.length > 0) {
      setSelectedEndorctlBinary(endorctlBinaryOptions[0]);
    } else {
      setSelectedEndorctlBinary(undefined);
    }
  }, [selectedVersion, installationMode, endorctlBinaryOptions]);

  const handleVersionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedVersion = e.target.value as VersionType;
    setSelectedVersion(selectedVersion);
  };

  const handlePlatformChange = (
    evt: React.MouseEvent<HTMLElement>,
    value: SupportedOS | null
  ) => {
    if (value !== null) {
      setSelectedPlatform(value);
    }
  };

  const handleAuthModeChange = (
    evt: React.MouseEvent<HTMLElement>,
    value: AuthMode | null
  ) => {
    if (value !== null) {
      setSelectedAuthMode(value);
    }
  };

  const handleArchTypeChange = (
    evt: React.MouseEvent<HTMLElement>,
    value: SpecArchType | null
  ) => {
    if (value !== null) {
      setSelectedArchType(value);
    }
  };

  const EnvVariablesDrawer = useInfoDrawer<
    { envVarsInfo: string },
    EnvVarInfoDrawerParams
  >({
    drawerParams: ['envVarsInfo'],
    Component: EnvVariablesInfoDrawer,
  });

  const showCodeBlock = useMemo(() => {
    if (
      (selectedPlatform === SupportedOS.Linux ||
        selectedPlatform === SupportedOS.MacOS ||
        selectedPlatform === SupportedOS.Windows) &&
      installationMode === InstallMode.NPM
    ) {
      return 'NPM';
    } else if (
      (selectedPlatform === SupportedOS.Linux ||
        selectedPlatform === SupportedOS.MacOS) &&
      installationMode === InstallMode.CURL
    ) {
      return 'MACANDLINUXCURL';
    } else if (selectedPlatform === SupportedOS.Windows) {
      return 'WINDOWSCURL';
    }
    return 'BREW';
  }, [installationMode, selectedPlatform]);

  return (
    <>
      <Stack spacing={6}>
        {/* Step 1 */}
        <Stack spacing={4} alignItems="flex-start">
          <StepHeading msg={stepHeadingsGeneral.step1} />

          <ToggleButtonGroup
            exclusive
            onChange={handlePlatformChange}
            value={selectedPlatform}
          >
            <ToggleButton color="primary" value={SupportedOS.MacOS}>
              <FM defaultMessage="macOS" />
            </ToggleButton>

            <ToggleButton color="primary" value={SupportedOS.Linux}>
              <FM defaultMessage="Linux" />
            </ToggleButton>

            <ToggleButton color="primary" value={SupportedOS.Windows}>
              <FM defaultMessage="Windows" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        {/* Step 2 */}
        <Stack spacing={4} alignItems="flex-start">
          <StepHeading msg={stepHeadingsGeneral.selectVersion} />

          {qListEndorctlBinaries.isLoading ? (
            <Skeleton variant="rounded" width={300} height={26} />
          ) : !hasEndorctlBinary ? (
            <Alert severity="error" sx={{ marginTop: 4 }}>
              <FM defaultMessage="No endorctl binary found for the selected Platform" />
            </Alert>
          ) : (
            <Stack direction="row" alignItems="center">
              <RadioGroup
                aria-label={fm({
                  defaultMessage: 'Choose endorctl version',
                })}
                name="endorctl-version"
                onChange={handleVersionChange}
                value={selectedVersion}
                row
              >
                <FormControlLabel
                  control={<Radio />}
                  value={EndorctlVersions.LATESTVERSION}
                  label={fm({
                    defaultMessage: 'Latest version',
                  })}
                />

                <FormControlLabel
                  control={<Radio />}
                  value={EndorctlVersions.SPECIFICVERSION}
                  label={fm({
                    defaultMessage: 'Specific version',
                  })}
                  disabled={showCodeBlock === 'BREW' || showCodeBlock === 'NPM'}
                />
              </RadioGroup>
              {selectedEndorctlBinary && (
                <Autocomplete
                  // NOTE: using key to allow resetting `defaultValue` for each arch type
                  key={selectedArchType}
                  value={selectedEndorctlBinary}
                  disableClearable
                  disabled={selectedVersion != EndorctlVersions.SPECIFICVERSION}
                  getOptionLabel={(option) =>
                    'string' === typeof option
                      ? option
                      : (option.spec.version as string)
                  }
                  isOptionEqualToValue={(a, b) => a.uuid === b.uuid}
                  onChange={(_, option) => {
                    if (
                      selectedVersion === EndorctlVersions.SPECIFICVERSION &&
                      option
                    )
                      setSelectedEndorctlBinary(option);
                  }}
                  options={endorctlBinaryOptions}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Version" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                        width="100%"
                      >
                        <Typography component="span">
                          {option.spec.version}
                        </Typography>
                        <Typography component="span" color="text.secondary">
                          <DateDisplay value={option.spec.published_date} />
                        </Typography>
                      </Stack>
                    </li>
                  )}
                  sx={{ minWidth: 300 }}
                />
              )}
            </Stack>
          )}
        </Stack>

        {/* Step 3 */}
        <>
          <Stack spacing={4}>
            <StepHeading msg={stepHeadingsEndorctl.stepEndorctlInstall} />
            <ToggleButtonGroup
              exclusive
              onChange={handleInstallationChange}
              value={installationMode}
            >
              {selectedPlatform === SupportedOS.MacOS && (
                <ToggleButton color="primary" value={InstallMode.HOMEBREW}>
                  <FM defaultMessage="brew" />
                </ToggleButton>
              )}

              <ToggleButton color="primary" value={InstallMode.NPM}>
                <FM defaultMessage="npm" />
              </ToggleButton>

              <ToggleButton color="primary" value={InstallMode.CURL}>
                <FM defaultMessage="curl" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          {/* Code block for brew install */}
          {showCodeBlock === 'BREW' &&
            (hasEndorctlBinary ? (
              <Stack spacing={4}>
                <CodeBlock value={getHomebrewCodeBlock()} />
              </Stack>
            ) : (
              <Alert severity="error" sx={{ marginTop: 4 }}>
                <FM defaultMessage="No endorctl binary found for the selected Platform" />
              </Alert>
            ))}

          {/* Code block for npm install */}
          {showCodeBlock === 'NPM' &&
            (hasEndorctlBinary ? (
              <Stack spacing={4}>
                <CodeBlock value={getNpmCodeBlock()} />
              </Stack>
            ) : (
              <Alert severity="error" sx={{ marginTop: 4 }}>
                <FM defaultMessage="No endorctl binary found for the selected Platform" />
              </Alert>
            ))}

          {/* Code block for curl install in mac and linux */}
          {showCodeBlock === 'MACANDLINUXCURL' && (
            <Stack spacing={4}>
              {/* Allow selection if multiple architecture types are present */}
              {archTypes.length > 1 && (
                <ToggleButtonGroup
                  exclusive
                  onChange={handleArchTypeChange}
                  value={selectedArchType}
                  sx={{ marginBottom: 4 }}
                >
                  {archTypes.map((archType) => (
                    <ToggleButton
                      key={archType.type}
                      value={archType.type}
                      color="primary"
                    >
                      {archType.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              )}

              {/* Show the placeholder while loading, code block for install, or an alert when no binary is available */}
              {qListEndorctlBinaries.isLoading ? (
                <Skeleton width="100%" sx={{ transform: 'none' }}>
                  <CodeBlock value={getEndorctlInstallCodeBlock()} />
                </Skeleton>
              ) : hasEndorctlBinary ? (
                <>
                  <CodeBlock
                    value={getEndorctlInstallCodeBlock(
                      selectedPlatform,
                      selectedEndorctlBinary?.spec
                    )}
                  />
                  <Alert
                    severity="info"
                    variant="outlined"
                    sx={{ marginTop: 4 }}
                  >
                    <AlertTitle
                      sx={({ spacing }) => ({
                        fontSize: spacing(3.5),
                        fontWeight: '400',
                      })}
                    >
                      <FM defaultMessage="We recommend you place this binary in a location defined in your $PATH" />
                    </AlertTitle>
                  </Alert>
                </>
              ) : (
                <Alert severity="error" sx={{ marginTop: 4 }}>
                  <FM defaultMessage="No endorctl binary found for the selected Platform" />
                </Alert>
              )}
            </Stack>
          )}
          {showCodeBlock === 'WINDOWSCURL' && (
            <Stack spacing={4}>
              {/* Show the placeholder while loading, code block for install, or an alert when no binary is available */}
              {qListEndorctlBinaries.isLoading ? (
                <Skeleton width="100%" sx={{ transform: 'none' }}>
                  <CodeBlock value={getEndorctlInstallCodeBlockWindows()} />
                </Skeleton>
              ) : hasEndorctlBinary ? (
                <>
                  <CodeBlock
                    value={getEndorctlInstallCodeBlockWindows(
                      selectedEndorctlBinary?.spec
                    )}
                  />
                  {/* <Alert severity="info" sx={{ marginTop: 4 }}>
                    <FM defaultMessage="It's recommended to place this binary in a location defined in your $PATH" />
                  </Alert> */}
                </>
              ) : (
                <Alert severity="error" sx={{ marginTop: 4 }}>
                  <FM defaultMessage="No endorctl binary found for the selected Platform" />
                </Alert>
              )}
            </Stack>
          )}
        </>

        {/* Step 4 & 5 - MAC and Linux */}
        {/* Hide the below steps unless a binary is present */}
        {(selectedPlatform === SupportedOS.Linux ||
          selectedPlatform === SupportedOS.MacOS) &&
          hasEndorctlBinary && (
            <>
              <Stack spacing={4}>
                <StepHeading msg={stepHeadingsEndorctl.stepEndorctlInit} />

                <ToggleButtonGroup
                  exclusive
                  onChange={handleAuthModeChange}
                  value={selectedAuthMode}
                >
                  <ToggleButton color="primary" value={AuthMode.Google}>
                    <FM defaultMessage="Google" />
                  </ToggleButton>

                  <ToggleButton color="primary" value={AuthMode.GitHub}>
                    <FM defaultMessage="GitHub" />
                  </ToggleButton>

                  <ToggleButton color="primary" value={AuthMode.GitLab}>
                    <FM defaultMessage="GitLab" />
                  </ToggleButton>

                  {/*
                    // NOTE: option hidden for https://endorlabs.atlassian.net/browse/UI-114.
                    // May be re-enabled in the future.
                    <ToggleButton color="primary" value={AuthMode.Azure}>
                      <FM defaultMessage="Azure" />
                    </ToggleButton>
                    */}
                </ToggleButtonGroup>

                <CodeBlock value={getEndorctlInitCodeBlock(selectedAuthMode)} />
              </Stack>

              <Stack spacing={4}>
                <StepHeading msg={stepHeadingsEndorctl.stepEndorctlScan} />
                <CodeBlock
                  value={getEndorctlScanCodeBlock(selectedPlatformSourceType)}
                />
              </Stack>
            </>
          )}

        {/* Step 4 & 5 - Windows */}
        {/* Hide the below steps unless a binary is present */}
        {selectedPlatform === SupportedOS.Windows && hasEndorctlBinary && (
          <>
            <Stack spacing={4}>
              <StepHeading msg={stepHeadingsEndorctl.stepEndorctlInit} />

              <ToggleButtonGroup
                exclusive
                onChange={handleAuthModeChange}
                value={selectedAuthMode}
              >
                <ToggleButton color="primary" value={AuthMode.Google}>
                  <FM defaultMessage="Google" />
                </ToggleButton>

                <ToggleButton color="primary" value={AuthMode.GitHub}>
                  <FM defaultMessage="GitHub" />
                </ToggleButton>

                <ToggleButton color="primary" value={AuthMode.GitLab}>
                  <FM defaultMessage="GitLab" />
                </ToggleButton>

                <ToggleButton color="primary" value={AuthMode.Azure}>
                  <FM defaultMessage="Azure" />
                </ToggleButton>
              </ToggleButtonGroup>

              <CodeBlock value={getEndorctlInitCodeBlock(selectedAuthMode)} />
            </Stack>

            <Stack spacing={4}>
              <StepHeading msg={stepHeadingsEndorctl.stepEndorctlScan} />
              <CodeBlock value="endorctl scan --path=<insert path to cloned source code>" />
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};
