import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
} from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  isBinaryProject,
  ProjectResource,
} from '@endorlabs/endor-core/Project';
import { useDeleteRepositoryVersion } from '@endorlabs/queries';
import {
  Link,
  useAppNotify,
  useConfirmationDialog,
} from '@endorlabs/ui-common';

import { NamedRoutes } from '../../../../routes/constants';
import { useUpdateSingleProjectFields } from '../../hooks';
import { FormProjectSettingsLabels } from '../FormProjectSettingsLabels';
import { ProjectMonitoredVersions } from '../ProjectMonitoredVersions';

export type ProjectSettingsViewProps = {
  isGitHubInstallation?: boolean;
  isLoading: boolean;
  namespace: string;
  project?: ProjectResource;
};

export const ProjectSettingsView = ({
  isGitHubInstallation,
  isLoading,
  namespace,
  project,
}: ProjectSettingsViewProps) => {
  // Hide monitored versions for projects without repositories
  const showMonitoredVersions = project && !isBinaryProject(project);

  const isAutomatedScanDisabled =
    project?.processing_status?.disable_automated_scan === true;

  const { handleUpdateLabels, handleToggleAutomatedScans, qUpdateProject } =
    useUpdateSingleProjectFields(project);

  const addAppNotification = useAppNotify();

  const qRepositoryVersionDelete = useDeleteRepositoryVersion({
    onSuccess: () => {
      addAppNotification({
        id: 'repo-version:delete:success',
        severity: 'success',
        message: <FM defaultMessage="Project Version Successfully Deleted" />,
      });
    },
    onError: (error) => {
      addAppNotification({
        id: 'repo-version:delete:error',
        severity: 'error',
        message: <FM defaultMessage="Error Deleting Project Version" />,
        details: error.response.data?.message,
      });
    },
  });

  const RepositoryVersionDeleteConfirmation = useConfirmationDialog<{
    namespace: string;
    uuid: string;
  }>({
    confirmText: <FM defaultMessage="Delete" />,
    isDestructive: true,
    onConfirm: (props) => {
      if (props) {
        qRepositoryVersionDelete.mutate({
          namespace: props.namespace,
          uuid: props.uuid,
        });
      }
    },
    titleText: <FM defaultMessage="Delete this Project Version?" />,
    descriptionText: (
      <FM defaultMessage="This Project Version and related resources will be deleted." />
    ),
  });

  return (
    <>
      <Stack direction="column" spacing={6}>
        <Card>
          <CardHeader title={<FM defaultMessage="Custom Tags" />} />
          <CardContent>
            <FormProjectSettingsLabels
              isLoading={isLoading || qUpdateProject.isLoading}
              onSubmit={handleUpdateLabels}
              project={project}
            />
          </CardContent>
        </Card>

        {showMonitoredVersions && (
          <Card>
            <CardHeader title={<FM defaultMessage="Monitored Versions" />} />
            <CardContent>
              <Stack direction="column" spacing={4}>
                <FormControl>
                  <FormControlLabel
                    sx={{ marginLeft: -1 }}
                    checked={!isAutomatedScanDisabled}
                    control={<Switch size="small" />}
                    disabled={
                      !isGitHubInstallation ||
                      isLoading ||
                      qUpdateProject.isLoading
                    }
                    label={
                      <FM defaultMessage="Enable automated scans for this project" />
                    }
                    onChange={handleToggleAutomatedScans}
                  />
                  {!isGitHubInstallation && (
                    <FormHelperText sx={{ marginLeft: 0 }}>
                      <FM
                        defaultMessage="Automated scanning through the GitHub App is supported on GitHub Cloud only. For more infomation, see the <link>GitHub App Docs</link>."
                        values={{
                          link: (value) => (
                            <Link to={NamedRoutes.DOCS_GITHUB_APP}>
                              {value}
                            </Link>
                          ),
                        }}
                      />
                    </FormHelperText>
                  )}
                </FormControl>

                <ProjectMonitoredVersions
                  isLoading={isLoading}
                  namespace={namespace}
                  onDelete={(row) =>
                    RepositoryVersionDeleteConfirmation.openDialog(row)
                  }
                  project={project}
                />
              </Stack>
            </CardContent>
          </Card>
        )}
      </Stack>

      <RepositoryVersionDeleteConfirmation.Dialog
        {...RepositoryVersionDeleteConfirmation.dialogProps}
      />
    </>
  );
};
