import { Skeleton } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { useGetScanProfile } from '@endorlabs/queries';
import { ButtonLinkSecondary, ButtonStack } from '@endorlabs/ui-common';

import {
  DetailDrawerContainer,
  DetailDrawerSection,
} from '../../../components/DetailDrawer';
import { getSettingsScanProfilesPath } from '../../../routes';

export interface ScanProfileDetailDrawerProps {
  scanProfileNamespace: string;
  scanProfileUuid: string;
}

export const ScanProfileDetailDrawer = ({
  scanProfileNamespace,
  scanProfileUuid,
}: ScanProfileDetailDrawerProps) => {
  const qListScanProfiles = useGetScanProfile(
    scanProfileNamespace,
    { uuid: scanProfileUuid },
    { enabled: !!scanProfileNamespace && !!scanProfileUuid }
  );

  const scanProfile = qListScanProfiles?.data;
  const isLoading = qListScanProfiles.isLoading;

  const toolChainContent = (
    <DetailDrawerSection>
      {/* TODO(UI-1468): Tool Chain Summary */}
    </DetailDrawerSection>
  );

  return (
    <DetailDrawerContainer
      headerProps={{
        title: isLoading ? <Skeleton width={200} /> : scanProfile?.meta.name,
        action: (
          <ButtonStack>
            <ButtonLinkSecondary
              linkProps={{
                to: getSettingsScanProfilesPath({
                  tenantName: scanProfileNamespace,
                  uuid: scanProfileUuid,
                }),
              }}
            >
              <FM defaultMessage="Edit Scan Profile" />
            </ButtonLinkSecondary>
          </ButtonStack>
        ),
      }}
    >
      {toolChainContent}
    </DetailDrawerContainer>
  );
};
