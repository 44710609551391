import { useNavigate } from '@tanstack/react-location';
import { Row, Table } from '@tanstack/react-table';
import { ForwardedRef, forwardRef, MouseEvent } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnHeader,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  RelativeTimeDisplay,
  ScanResultStatusIndicator,
  UIEventUtils,
} from '@endorlabs/ui-common';

import { CiCdToolsTableRow } from '../../domains/CiCdTools';
import { getProjectVersionPath } from '../../routes';

export type CiCdToolsTableProps = Omit<
  DataTableProps<CiCdToolsTableRow>,
  'columns'
>;

const STATIC_COLUMNS = [
  'lastCodeScan',
  'namespace',
  'platformSource',
  'project',
  'projectName',
  'scanResults',
  'versionRef',
];

const buildCiCdToolsTableColumns = ({
  data,
}: CiCdToolsTableProps): DataTableColumnDef<CiCdToolsTableRow>[] => {
  const columns: DataTableColumnDef<CiCdToolsTableRow>[] = [
    {
      accessorKey: 'scanResults',
      cell: ({ getValue }) => (
        <ScanResultStatusIndicator scanResults={getValue()} />
      ),
      colType: ColTypes.STATUS_INDICATOR,
    },
    {
      accessorKey: 'projectName',
      colType: ColTypes.PROJECT,
      header: () => <FM defaultMessage="Project Name" />,
    },
    {
      accessorKey: 'lastCodeScan',
      colType: ColTypes.TEXT,
      cell: (t) => {
        const lastScanTime = t.getValue() as string;
        return <RelativeTimeDisplay value={lastScanTime ?? ''} />;
      },
      header: () => (
        <DataTableColumnHeader
          label={<FM defaultMessage="Last Tool Scan" />}
          helpTooltip={
            <FM defaultMessage="How long ago was the default branch scanned?" />
          }
        />
      ),
      minSize: 170,
      displayName: 'Last Tool Scan',
      enableHiding: true,
    },
  ];

  const allCategoryNames = data.reduce((acc, d) => {
    Object.keys(d).forEach((cat) => {
      if (!STATIC_COLUMNS.includes(cat) && !acc.includes(cat)) {
        acc.push(cat);
      }
    });
    return acc;
  }, [] as string[]);

  allCategoryNames.forEach((category) => {
    columns.push({
      accessorKey: category,
      colType: ColTypes.TOOLS_LOGOS,
      enableReorder: true,
    });
  });

  return columns;
};

export const CiCdToolsTable = forwardRef(function CiCdToolsTableComponent(
  { ...props }: CiCdToolsTableProps,
  ref: ForwardedRef<Table<CiCdToolsTableRow>>
) {
  const columns = buildCiCdToolsTableColumns({
    data: props.data,
  });
  const navigate = useNavigate();

  function handleRowClick(
    row: CiCdToolsTableRow,
    _: Row<CiCdToolsTableRow>,
    evt: MouseEvent
  ) {
    const link = getProjectVersionPath({
      tenantName: row.namespace,
      resourceName: 'projects',
      additionalSegments: ['tools'],
      uuid: row.project.uuid,
      versionRef: row.versionRef,
    });
    UIEventUtils.simulateLinkClick(link, evt, navigate);
  }
  return (
    <DataTable
      {...props}
      enableColumnReorder
      enableColumnVisibility
      ref={ref}
      columns={columns}
      onRowClick={handleRowClick}
      reorderableColumnHeading="Tool Categories"
      tableId="ci-cd-tools"
    />
  );
});
