import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
// import { ReactLocationDevtoolsPanel } from '@tanstack/react-location-devtools';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { ReactNode, useMemo } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';

import { useUserPreferences } from '@endorlabs/queries';

import { getTheme } from '../../themes';
import { getGlobalStyles } from '../../themes/endor/globalStyles';
import { FontEmbeds } from '../FontEmbeds';

export interface AppContainerProps {
  children: ReactNode;
  locale?: string;
  messages:
    | Record<string, string>
    | Record<string, MessageFormatElement[]>
    | undefined;
  paletteMode?: 'light' | 'dark';
  queryClient: QueryClient;
}

export const styleCache = createCache({
  key: 'endorlabs',
  nonce: window.ENDOR_NONCE,
});

/**
 * AppContainer provides everything needed at a UI level to stand up a React-based Endor application.
 * This includes theming and style defaults, fonts, internationalization, and API query client.
 */
export const AppContainer = ({
  children,
  locale = 'en',
  messages,
  paletteMode = 'light',
  queryClient,
}: AppContainerProps) => {
  const { darkMode } = useUserPreferences();
  const userPaletteMode = darkMode ? 'dark' : 'light';
  const theme = useMemo(() => getTheme(userPaletteMode), [userPaletteMode]);

  return (
    <CacheProvider value={styleCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={getGlobalStyles(theme)} />
        <FontEmbeds />

        <IntlProvider defaultLocale="en" locale={locale} messages={messages}>
          <QueryClientProvider client={queryClient}>
            {/* Dev tools only appear in NODE_ENV=development */}
            {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
            {/* <ReactLocationDevtoolsPanel /> */}
            {children}
          </QueryClientProvider>
        </IntlProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};
