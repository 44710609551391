import { useTheme } from '@mui/material';
import { AxisBottom, AxisLeft, AxisRight, AxisTop } from '@visx/axis';
import { Grid } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal, StringLike } from '@visx/scale';
import { Bar } from '@visx/shape';
import { defaultStyles, Tooltip, useTooltip } from '@visx/tooltip';
import { ReactNode, useCallback, useMemo } from 'react';

import { Colors } from './constants';
import { Legend } from './Legend';
import {
  BarChartHorizontalProps,
  TooltipData,
  VerticalPosition,
} from './types';
import { getChartDimensions } from './utils';

const defaultMargin = { top: 40, left: 50, right: 40, bottom: 100 };

export function BarChartHorizontal<T extends Record<string, string | number>>({
  axisBottom = true,
  axisColor = Colors.BLUE,
  axisLeft = true,
  axisRight = false,
  axisTop = false,
  barColors = [Colors.PURPLE],
  barPadding = 0.4,
  barTitleColor = Colors.BLACK,
  barTitleFontSize = 12,
  barTitlePadding = 12,
  barTitlePosition = 'bottom',
  data,
  gridColor = Colors.BLACK,
  gridOpacity = 0.07,
  height,
  labelColor = Colors.BLACK,
  labelSize = 12,
  legendAlign = 'center',
  legendContainerSize = 0.2,
  legendDirection = 'column',
  legendFontSize = 12,
  legendIconShape = 'circle',
  legendJustify = 'center',
  legendPosition = 'left',
  margin = defaultMargin,
  onClickHandler,
  showBarTitles = false,
  showGrid = true,
  showLabels = true,
  showLegends = false,
  tooltipBackgroundColor = Colors.BLACK,
  tooltips = true,
  tooltipTextColor = Colors.WHITE,
  width,
  xKey,
  yKey,
}: BarChartHorizontalProps<T>) {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<TooltipData>();

  const [
    svgWidth,
    svgHeight,
    chartWidth,
    chartHeight,
    legendContainerWidth,
    legendContainerHeight,
  ] = getChartDimensions(
    width,
    height,
    margin,
    showLegends,
    legendPosition,
    legendContainerSize
  );

  const theme = useTheme();

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: tooltipBackgroundColor,
    color: tooltipTextColor,
    zIndex: theme.zIndex.tooltip,
  };

  const getX = useCallback(
    (d: T): number => (!isNaN(Number(d[xKey])) ? Number(d[xKey]) : 0),
    [xKey]
  );
  const getY = useCallback((d: T): StringLike => d[yKey], [yKey]);

  const getId = useCallback((d: T): StringLike => d[yKey], [yKey]);

  // scales, memoize for performance
  const yScale = useMemo(
    () =>
      scaleBand<StringLike>({
        range: [0, chartHeight],
        round: true,
        domain: data.map(getY),
        paddingInner: barPadding,
        paddingOuter: 0,
      }),
    [chartHeight, data, getY, barPadding]
  );
  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, chartWidth],
        round: true,
        domain: [0, Math.max(...data.map(getX))],
      }),
    [chartWidth, data, getX]
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal<string | number, string>({
        domain: data.map(getX),
        range: barColors,
      }),
    [data, getX, barColors]
  );

  const getBarHeightWithTitle = (height: number): number => {
    return showBarTitles ? height - barTitlePadding : height;
  };

  const getBarY = (barY: number, titlePos: VerticalPosition) => {
    return showBarTitles
      ? titlePos === 'bottom'
        ? barY
        : barY + barTitlePadding
      : barY;
  };

  const getLabelPos = (
    barY: number,
    barWidth: number,
    barHeight: number,
    titlePos: VerticalPosition
  ) => {
    return {
      x: barWidth + labelSize / 2,
      y: showBarTitles
        ? titlePos === 'bottom'
          ? barY + getBarHeightWithTitle(barHeight) / 2
          : barY + barTitlePadding + getBarHeightWithTitle(barHeight) / 2
        : barY + getBarHeightWithTitle(barHeight) / 2,
    };
  };

  const getTitleY = (
    barY: number,
    barHeight: number,
    titlePos: VerticalPosition
  ) => {
    return titlePos === 'bottom'
      ? barY + getBarHeightWithTitle(barHeight) + barTitlePadding
      : barY;
  };

  const getTooltipPos = (barY: number, barWidth: number, barHeight: number) => {
    return {
      x: barWidth / 2 + margin.left,
      y: barY + getBarHeightWithTitle(barHeight) + margin.top,
    };
  };

  return width < 10 ? null : (
    <div>
      <svg width={svgWidth} height={svgHeight}>
        {showGrid && (
          <Grid
            top={margin.top}
            left={margin.left}
            xScale={xScale}
            yScale={yScale}
            width={chartWidth}
            height={chartHeight}
            stroke={gridColor}
            strokeOpacity={gridOpacity}
            yOffset={showBarTitles ? -barTitleFontSize * 0.6 : 0}
          />
        )}
        <Group top={margin.top} left={margin.left}>
          {data.map((d, index) => {
            const id = getId(d);
            const xValue = getX(d);
            const yValue = getY(d);
            const barWidth = xScale(xValue ?? 0);
            const barHeight = yScale.bandwidth();
            const barX = 0;
            const barY = yScale(yValue);
            return (
              <Group
                key={`bar-${id}-${index}`}
                x1={barX}
                y1={barY}
                width={barWidth}
                height={barHeight}
                style={{ cursor: 'pointer' }}
                onClick={onClickHandler ? () => onClickHandler(d) : undefined}
              >
                <Bar
                  key={`bar-${id}-${index}`}
                  x={barX}
                  y={getBarY(barY ?? 0, barTitlePosition)}
                  width={barWidth ? barWidth : 1}
                  height={getBarHeightWithTitle(barHeight)}
                  fill={colorScale(xValue)}
                  onMouseLeave={
                    tooltips
                      ? () => {
                          hideTooltip();
                        }
                      : undefined
                  }
                  onMouseMove={
                    tooltips
                      ? () => {
                          showTooltip({
                            tooltipData: { key: String(yValue), value: xValue },
                            tooltipTop: getTooltipPos(
                              barY ?? 0,
                              barWidth,
                              barHeight
                            ).y,
                            tooltipLeft: getTooltipPos(
                              barY ?? 0,
                              barWidth,
                              barHeight
                            ).x,
                          });
                        }
                      : undefined
                  }
                />
                {showBarTitles && (
                  <text
                    fontSize={barTitleFontSize}
                    fontWeight={600}
                    fill={barTitleColor}
                    alignmentBaseline="middle"
                    x={barX}
                    y={getTitleY(barY ?? 0, barHeight, barTitlePosition)}
                  >
                    {yValue as ReactNode}
                  </text>
                )}
                {showLabels && (
                  <text
                    fontSize={labelSize}
                    fill={labelColor}
                    alignmentBaseline="middle"
                    x={
                      getLabelPos(
                        barY ?? 0,
                        barWidth,
                        barHeight,
                        barTitlePosition
                      ).x
                    }
                    y={
                      getLabelPos(
                        barY ?? 0,
                        barWidth,
                        barHeight,
                        barTitlePosition
                      ).y
                    }
                  >
                    {xValue}
                  </text>
                )}
              </Group>
            );
          })}
        </Group>
        {axisTop && (
          <AxisTop
            left={margin.left}
            top={margin.top}
            scale={xScale}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={() => ({
              fill: axisColor,
              fontSize: 11,
              textAnchor: 'middle',
              dy: '-0.33em',
            })}
          />
        )}
        {axisBottom && (
          <AxisBottom
            left={margin.left}
            top={chartHeight + margin.top}
            scale={xScale}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={() => ({
              fill: axisColor,
              fontSize: 11,
              textAnchor: 'middle',
            })}
          />
        )}
        {axisLeft && (
          <AxisLeft
            left={margin.left}
            top={margin.top}
            scale={yScale}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={() => ({
              fill: axisColor,
              fontSize: 11,
              textAnchor: 'end',
              dy: '0.33em',
            })}
          />
        )}
        {axisRight && (
          <AxisRight
            left={chartWidth + margin.left}
            top={margin.top}
            scale={yScale}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={() => ({
              fill: axisColor,
              fontSize: 11,
              textAnchor: 'start',
              dy: '0.33em',
            })}
          />
        )}
      </svg>
      {showLegends && (
        <Legend
          legendPosition={legendPosition}
          legendDirection={legendDirection}
          legendJustify={legendJustify}
          legendAlign={legendAlign}
          legendFontSize={legendFontSize}
          legendIconShape={legendIconShape}
          maxWidth={legendContainerWidth}
          maxHeight={legendContainerHeight}
          margin={margin}
          colorScale={colorScale}
        />
      )}
      {tooltips && tooltipOpen && tooltipData && (
        <Tooltip top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div>
            <strong>{tooltipData.key}</strong>
          </div>
          <div>{tooltipData.value}</div>
        </Tooltip>
      )}
    </div>
  );
}
