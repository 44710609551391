import { get as _get, isArray as _isArray } from 'lodash-es';
import { useMemo } from 'react';

import { ResourceKind } from '@endorlabs/endor-core';
import { buildQueryCall, sortParamBuilders } from '@endorlabs/queries';
import { ControlledTableMultiselect } from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { getTableRegistryEntry } from '../constants/DataTableRegistry';
import { PolicyTemplateParameterField } from '../types';
import { PolicyParameterFieldFromData } from './PolicyParameterFieldFromData';

interface PolicyParameterFieldFromQueryProps {
  parameter: PolicyTemplateParameterField;
  parameterFieldName: string;
  parameterFieldValue?: string[];
}

export const PolicyParameterFieldFromQuery = ({
  parameter,
  parameterFieldName,
  parameterFieldValue,
}: PolicyParameterFieldFromQueryProps) => {
  const { possible_values_from_db, values, multiple_ok, name } = parameter;
  const hasValues = Boolean(values && values.length > 0);
  const allowMultiple = multiple_ok === true;
  const resourceKind = possible_values_from_db?.resource_kind as ResourceKind;
  const valueKey = possible_values_from_db?.input_field;

  const resource_kind = possible_values_from_db?.resource_kind;
  const { activeNamespace: tenantName } = useAuthInfo();

  // Retrieve parameter values from DB if called for
  const qParameterValues = buildQueryCall(resourceKind, {
    filter: possible_values_from_db?.filter,
    page_size: 500,
    sort: sortParamBuilders.ascendingBy('meta.name'),
  }).useSuccessiveQuery(tenantName, { enabled: Boolean(resource_kind) });

  const { allRows, columns, rows } = useMemo(() => {
    const valuesFromDb =
      qParameterValues.data?.spec?.query_response?.list.objects ?? [];

    const tableRegisterEntry = getTableRegistryEntry(
      resourceKind,
      valueKey as string
    );

    /** If existing policy has established values, match these to rows generated from db values */
    const selectedRows = qParameterValues.isLoading
      ? []
      : tableRegisterEntry
          .buildRows(
            hasValues && valueKey
              ? valuesFromDb.filter((val: unknown) => {
                  const possibleValueFromDB = _get(val, valueKey);
                  // If valueKey corresponds to a list, check for each item in the list
                  if (_isArray(possibleValueFromDB)) {
                    return possibleValueFromDB.some((possibleValue) =>
                      values?.includes(possibleValue)
                    );
                  } else {
                    return values?.includes(_get(val, valueKey));
                  }
                })
              : []
          )
          /**
           * Ensure that the generated rows only include existing values.
           * This is needed when valueKey corresponds to a list and buildRow generates separate rows for each item in the list, even if the item is not in existing values
           */
          .filter((row) => values?.includes(_get(row, 'optionValue')));

    return {
      allRows: tableRegisterEntry.buildRows(valuesFromDb),
      columns: tableRegisterEntry.buildColumns(),
      rows: selectedRows,
    };
  }, [hasValues, qParameterValues, resourceKind, valueKey, values]);

  if (!allowMultiple) {
    const possibleValues = allRows.map((row) => row?.optionValue);
    return (
      <PolicyParameterFieldFromData
        allowMultiple={false}
        inputFieldName={name}
        possibleValues={possibleValues}
        parameterFieldName={parameterFieldName}
        parameterFieldValue={parameterFieldValue}
      />
    );
  }

  return (
    <ControlledTableMultiselect
      allRows={allRows}
      columns={columns}
      isLoading={qParameterValues.isLoading}
      name={parameterFieldName}
      rows={rows}
    />
  );
};
