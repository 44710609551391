import { V1Ecosystem } from '@endorlabs/api_client';

import ImageLogomarkCocoapods from '../../assets/logomark_cocoapods.svg';
import ImageLogomarkDebian from '../../assets/logomark_debian.svg';
import ImageLogomarkDocker from '../../assets/logomark_docker.svg';
import ImageLogomarkGithubAction from '../../assets/logomark_github_action.svg';
import ImageLogomarkGo from '../../assets/logomark_go.svg';
import ImageLogomarkMaven from '../../assets/logomark_maven.svg';
import ImageLogomarkNpm from '../../assets/logomark_npm.svg';
import ImageLogomarkNuget from '../../assets/logomark_nuget.svg';
import ImageLogomarkPackagist from '../../assets/logomark_packagist.svg';
import ImageLogomarkPypi from '../../assets/logomark_pypi.svg';
import ImageLogomarkRubyGems from '../../assets/logomark_rubygems.svg';
import ImageLogomarkRust from '../../assets/logomark_rust.svg';

/**
 * Icon overrides for known ecosystems
 */
export const EcosystemIcons: Partial<Record<V1Ecosystem, string>> = {
  [V1Ecosystem.Cargo]: ImageLogomarkRust,
  [V1Ecosystem.Cocoapod]: ImageLogomarkCocoapods,
  [V1Ecosystem.Container]: ImageLogomarkDocker,
  [V1Ecosystem.Debian]: ImageLogomarkDebian,
  [V1Ecosystem.Gem]: ImageLogomarkRubyGems,
  [V1Ecosystem.GithubAction]: ImageLogomarkGithubAction,
  [V1Ecosystem.Go]: ImageLogomarkGo,
  [V1Ecosystem.Maven]: ImageLogomarkMaven,
  [V1Ecosystem.Npm]: ImageLogomarkNpm,
  [V1Ecosystem.Nuget]: ImageLogomarkNuget,
  [V1Ecosystem.Packagist]: ImageLogomarkPackagist,
  [V1Ecosystem.Pypi]: ImageLogomarkPypi,
};
