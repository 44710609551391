import { omit as _omit, union as _union } from 'lodash-es';

import { ContextContextType } from '@endorlabs/api_client';
import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import {
  ProjectResource,
  QueryProjectToolsResponse,
  selectMetricCiCdToolCategories,
  selectMetricCiCdToolsByCategory,
} from '@endorlabs/queries';

import { CiCdToolsTableRow } from '../components/CiCdToolsTable';

export const mapToCiCdToolsTableRows = (
  namespace: string,
  projects?: QueryProjectToolsResponse,
  override?: (row: CiCdToolsTableRow) => CiCdToolsTableRow
): CiCdToolsTableRow[] => {
  if (!projects?.list?.objects) return [];

  const allToolCategories = projects.list.objects.reduce((acc, project) => {
    const { MainContextMetric, RefContextMetric } =
      project.meta.references ?? {};
    // If the project does not have tools in the main context, check in ref context
    const toolMetrics =
      MainContextMetric?.list?.objects[0] ?? RefContextMetric?.list?.objects[0];
    const projectToolsByCategory = selectMetricCiCdToolCategories(toolMetrics);
    return _union(acc, projectToolsByCategory);
  }, [] as string[]);

  const sortedToolCategories = allToolCategories.sort((a, b) =>
    a.localeCompare(b)
  );

  return projects.list.objects.map((project) => {
    const {
      MainContextMetric,
      MainScanResult,
      RefContextMetric,
      RefScanResult,
    } = project.meta.references ?? {};

    // Use the metrics from the main context if they exist,
    // otherwise use the ref context
    const toolMetrics =
      MainContextMetric?.list?.objects[0] ?? RefContextMetric?.list?.objects[0];
    const toolsByCategory = selectMetricCiCdToolsByCategory(toolMetrics);

    // Get the scan results for the same context as the Metrics
    const scanResults = ((toolMetrics?.context.type === ContextContextType.Main
      ? MainScanResult?.list?.objects
      : RefScanResult?.list?.objects) ?? []) as ScanResultResource[];

    const versionRef =
      toolMetrics?.context.type === ContextContextType.Ref
        ? toolMetrics?.context.id
        : undefined;

    const row = {} as CiCdToolsTableRow;

    row.lastCodeScan = toolMetrics?.meta.update_time;
    row.namespace = namespace;
    row.project = _omit(project, ['meta.references']) as ProjectResource;
    row.projectName = project.meta.name;
    row.platformSource = project.spec.platform_source;
    row.scanResults = scanResults;
    row.versionRef = versionRef;

    // For each known category, add the matching tools from this project
    sortedToolCategories.map((category) => {
      const tools = toolsByCategory[category];
      row[category] = tools;
    });

    // allow override
    if (override) {
      return override(row);
    }
    return row;
  });
};
