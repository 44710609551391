import { getEnv } from '../../../constants';
import { getProjectDetailPath } from '../../../routes';
import { BasicTourStepProps } from '../types';
import { clickOn, waitForElementsAvailable } from '../utils';

const ENV = getEnv();

const selRepoButton = `button[value="FINDING_SOURCE_REPOSITORY"]`;

const selNav = '#app-primary-nav [data-testid="AppDrawerNavItem-projects"]';

export const getSecurityPostureStep = ({
  navigate,
  tourData,
}: BasicTourStepProps) => {
  const selFinding = `#FindingSummaryAccordion-${tourData.scmFindingUuid}-header`;
  const fullFinding = `#FindingSummaryAccordion-${tourData.scmFindingUuid}`;

  return {
    attachTo: {
      element: fullFinding,
      on: 'top-end',
    },

    beforeShowPromise: async function () {
      navigate({
        to: `${getProjectDetailPath({
          tenantName: tourData.tourTenant,
          uuid: tourData.findingsProject?.uuid,
          section: 'findings',
        })}`,
      });

      await clickOn(selRepoButton);

      await waitForElementsAvailable([selFinding]);
      await clickOn(selFinding);
    },
    title: 'Identify Repository Misconfigurations',
    description:
      'Enhance the security posture of your source code repositories by proactively identifying misconfigurations, enforcing configuration best practices, and ensuring compliance with CIS benchmarks.',
    highlightSelectors: [selNav, selRepoButton, fullFinding],

    urlLearn: `${ENV.URL_ENDOR_DOCS}/scan-with-endorlabs/scm-configuration-management`,
  };
};
