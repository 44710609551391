import { partition as _partition, uniqBy as _uniqBy } from 'lodash-es';
import { useMemo } from 'react';

import { V1Ecosystem } from '@endorlabs/api_client';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  PackageResource,
  tryParseGroupResponseAggregationKey,
  useCreateAIQuery,
  useListPackageVersions,
} from '@endorlabs/queries';
import { useListPackageVersionsMetadata } from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

export enum OSSExplorerPageSearchSource {
  DroidGPT = 'DROID_GPT',
  OSS = 'OSS',
}

type PackageVersionSummary = {
  name: string;
  versionRef: string;
  repositoryUrl?: string;
};

export type OSSExplorerPageData = {
  packageResource: PackageResource;
  versionCount: number;
  versions: PackageVersionSummary[];
}[];

export const useOSSExplorerPageData = ({
  ecosystem,
  searchValue,
  source,
}: {
  ecosystem?: V1Ecosystem;
  searchValue?: string;
  source: OSSExplorerPageSearchSource;
}) => {
  const packageVersionsFilter = [`meta.name matches "${searchValue}"`];
  if (ecosystem) {
    // NOTE: adding the ecosystem filter as the first filter in an attempt to optimize the search
    packageVersionsFilter.unshift(`spec.ecosystem == ${ecosystem}`);
  }

  // Handle OSS Package searches
  const qPackageVersionsMetadata = useListPackageVersionsMetadata(
    NAMESPACES.OSS,
    {
      filter: filterExpressionBuilders.and(packageVersionsFilter),
      page_size: 25,
      traverse: false,
    },
    { enabled: source === OSSExplorerPageSearchSource.OSS && !!searchValue }
  );

  // Handle DroidGPT queries
  const qAIQueryResponse = useCreateAIQuery(
    {
      namespace: NAMESPACES.OSS,
      ecosystem: ecosystem ?? V1Ecosystem.Unspecified,
      prompt: searchValue ?? '',
    },
    {
      enabled:
        source === OSSExplorerPageSearchSource.DroidGPT &&
        !!searchValue &&
        !!ecosystem,
    }
  );

  const recommendedPackages = useMemo(() => {
    const results = _uniqBy(
      qAIQueryResponse.data?.packages ?? [],
      (p) => p.name
    );

    return results.map((result) => {
      let versionRef = result.latest_version;
      let packageName = String(result.name);

      if (ecosystem === V1Ecosystem.Maven) {
        // handle malformed package versions from maven, where the version is contained in the name
        const [_, __, version] = packageName.split(':');
        if (version) {
          versionRef = version;
        }
      }

      const prefix =
        ecosystem && UIPackageVersionUtils.prefixFromEcosystem(ecosystem);

      if (prefix) {
        packageName = `${prefix}://${packageName}`;
      }

      const packageVersionName = versionRef
        ? `${packageName}@${versionRef}`
        : undefined;

      return {
        uuid: result.package_version_uuid,
        packageDescription: result.description,
        packageName,
        packageVersionName,
        repositoryUrl: result.repository,
      };
    });
  }, [qAIQueryResponse.data, ecosystem]);

  const packageNames = useMemo(() => {
    return recommendedPackages.map((p) => p.packageName).sort();
  }, [recommendedPackages]);

  const qPackageVersionGroups = useListPackageVersions(
    NAMESPACES.OSS,
    {
      enabled:
        source === OSSExplorerPageSearchSource.DroidGPT &&
        !!packageNames.length,
    },
    {
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.mainResourceContext(),
        `spec.package_name in ["${packageNames.join('","')}"]`,
      ]),
      group: {
        aggregation_paths: 'spec.package_name',
        unique_value_paths: 'meta.name',
      },
    }
  );

  // Transform data from search source
  const data: OSSExplorerPageData = useMemo(() => {
    if (source === OSSExplorerPageSearchSource.DroidGPT) {
      const packageVersionsPackageName = Object.entries(
        qPackageVersionGroups.data?.group_response?.groups ?? {}
      ).reduce((acc, [key, group]) => {
        const values = tryParseGroupResponseAggregationKey(key);
        const packageName = values.find((kv) => kv.key === 'spec.package_name')
          ?.value as string | undefined;

        const allVersions = (
          (group.unique_values?.['meta.name'] as string[]) ?? []
        ).map((name) => {
          const { version: versionRef } =
            UIPackageVersionUtils.parsePackageName(name);
          return { name, versionRef };
        });

        // Handle potentially invalid semver versions in the list,
        // appending to the end of the sorted list of valid versions
        const [validVersions, additionalVersions] = _partition(
          allVersions,
          (v) => UIPackageVersionUtils.isValidSemanticVersion(v.versionRef)
        );

        validVersions.sort((a, b) =>
          UIPackageVersionUtils.sortBySemanticVersion(
            b.versionRef,
            a.versionRef
          )
        );

        if (packageName) {
          const versionCount = group.aggregation_count?.count ?? 1;
          acc[packageName] = {
            versionCount,
            versions: [...validVersions, ...additionalVersions],
          };
        }

        return acc;
      }, {} as Partial<Record<string, { versionCount: number; versions: PackageVersionSummary[] }>>);

      return recommendedPackages.map(
        ({
          packageDescription,
          packageName,
          packageVersionName,
          repositoryUrl,
        }) => {
          const { version: packageVersionRef, ecosystem } =
            UIPackageVersionUtils.parsePackageName(packageVersionName);

          // Use version information from group response, falling back to the
          // DroidGPT response
          let { versionCount, versions } =
            packageVersionsPackageName[packageName] ?? {};

          // If recommended package version exists in response from Droid GPT
          if (packageVersionName) {
            if (versions?.length) {
              versions[0].repositoryUrl = repositoryUrl;
            } else {
              versionCount = 1;
              versions = [
                {
                  name: packageVersionName,
                  versionRef: packageVersionRef,
                  repositoryUrl,
                },
              ];
            }
          }

          return {
            packageResource: {
              tenant_meta: { namespace: NAMESPACES.OSS },
              meta: { name: packageName, description: packageDescription },
              spec: { ecosystem },
            } satisfies PackageResource,
            versionCount: versionCount as number,
            versions: versions as PackageVersionSummary[],
          };
        }
      );
    }

    const packageVersionsMetadata =
      qPackageVersionsMetadata.data?.objects ?? [];

    const result = packageVersionsMetadata.map((o) => {
      const packageName = o.meta.name;
      const ecosystem = o.spec.ecosystem;

      const versionCount = o.spec.versions.length;
      const versions = o.spec.versions
        .map(({ version, source_code_url }) => {
          const packageVersionName =
            UIPackageVersionUtils.deriveFullPackageName({
              ecosystem,
              name: packageName,
              version,
            });

          const repositoryUrl = source_code_url ? source_code_url : undefined;

          return {
            name: packageVersionName,
            versionRef: version,
            repositoryUrl,
          };
        })
        // sort by version, descending
        .sort((a, b) =>
          UIPackageVersionUtils.sortBySemanticVersion(
            b.versionRef,
            a.versionRef
          )
        );

      return {
        packageResource: {
          tenant_meta: { namespace: NAMESPACES.OSS },
          meta: { name: packageName },
          spec: { ecosystem },
        } satisfies PackageResource,
        versionCount,
        versions,
      };
    });

    return result;
  }, [
    qPackageVersionGroups.data,
    qPackageVersionsMetadata.data,
    recommendedPackages,
    source,
  ]);

  // Determine state from search source
  const { error, isError, isLoading } = useMemo(() => {
    if (source === OSSExplorerPageSearchSource.DroidGPT) {
      const isLoading =
        qAIQueryResponse.isLoading || qPackageVersionGroups.isLoading;
      return {
        error: qAIQueryResponse.error,
        isError: qAIQueryResponse.isError,
        isLoading,
      };
    }

    return {
      error: qPackageVersionsMetadata.error,
      isError: qPackageVersionsMetadata.isError,
      isLoading: qPackageVersionsMetadata.isLoading,
    };
  }, [
    qAIQueryResponse.error,
    qAIQueryResponse.isError,
    qAIQueryResponse.isLoading,
    qPackageVersionGroups.isLoading,
    qPackageVersionsMetadata.error,
    qPackageVersionsMetadata.isError,
    qPackageVersionsMetadata.isLoading,
    source,
  ]);

  const isEmptyState = !isError && !isLoading && data.length === 0;

  return { data, error, isEmptyState, isError, isLoading };
};
