import { Box, Card, Stack, Theme, useTheme } from '@mui/material';
import { uniq as _uniq } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { ProjectResource } from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders as FB } from '@endorlabs/filters';
import {
  ButtonPrimary,
  ButtonStack,
  DataTablePaginator,
  RowStack,
  SimplePagination,
  useStyles,
} from '@endorlabs/ui-common';

import { Layout } from '../../../constants';
import { FilterBar, useFilterContext } from '../../filters';
import { FINDING_FILTER_FIELDS } from '../constants';
import { AggregatedFinding, useFindingsExportDialog } from '../hooks';
import { FindingAggregation } from '../types';
import {
  FindingAggregationMenu,
  FindingAggregationMenuProps,
} from './FindingAggregationMenu';
import { FindingsDataTableViewAccordionGroup } from './FindingsDataTableViewAccordionGroup';
import { FindingsDataTableViewAccordionGroupTitleNode } from './FindingsDataTableViewAccordionGroupTitleNode';

export interface FindingsAggregatedViewProps {
  aggregationType: FindingAggregation;
  findingGroups: AggregatedFinding[];
  namespace: string;
  paginator: DataTablePaginator;
  project?: Partial<ProjectResource>;
  setAggregationType: FindingAggregationMenuProps['onSelect'];
}

export const FindingsAggregatedView = ({
  aggregationType,
  findingGroups,
  namespace,
  paginator,
  project,
  setAggregationType,
}: FindingsAggregatedViewProps) => {
  const { space } = useTheme();
  const sx = useStyles(styles);

  const { filter } = useFilterContext();

  /* ===== EXPORT ===== */
  const findingsExportDialog = useFindingsExportDialog();

  // Limit export to upgrades contained in provided groups
  const handleExport = () => {
    const uuidsFilter = _uniq(
      findingGroups.reduce((acc: string[], findingGroup) => {
        return acc.concat(findingGroup.uuids);
      }, [])
    ).join(',');

    const uuidsFilterExpression = `uuid in [${uuidsFilter}]`;

    const exportFilter = filter
      ? FB.and([filter, uuidsFilterExpression])
      : uuidsFilterExpression;

    findingsExportDialog.openDialog({
      downloadProps: {
        filename: project
          ? `project_${project?.uuid}_findings-export.csv`
          : `${namespace}_findings-export.csv`,
      },
      filter: exportFilter,
      namespace,
    });
  };

  return (
    <>
      <Card className="FindingsAggregatedView-root" sx={sx}>
        <Stack className="FindingsAggregatedView-header">
          <FilterBar fields={FINDING_FILTER_FIELDS} isDataTableView={true} />

          <RowStack gap={space.sm} justifyContent="space-between">
            <SimplePagination
              paginator={paginator}
              resourceLabel="Group"
              resourceLabelPlural="Groups"
              verbose
            />

            <ButtonStack justifySelf="flex-end">
              <FindingAggregationMenu
                onSelect={(_, value) => {
                  setAggregationType(null, value);
                }}
                value={aggregationType}
              />

              <ButtonPrimary onClick={handleExport}>
                <FM defaultMessage="Export All" />
              </ButtonPrimary>
            </ButtonStack>
          </RowStack>
        </Stack>

        <Box>
          {findingGroups.map((group, index) => {
            return (
              <FindingsDataTableViewAccordionGroup
                aggregationGroup={group}
                id={`FindingsDataTableGroup-${index}`}
                isLoading={false}
                key={index}
                namespace={namespace}
                titleNode={
                  <FindingsDataTableViewAccordionGroupTitleNode
                    aggregationType={aggregationType}
                    level={group.severity}
                    title={group.title}
                  />
                }
              />
            );
          })}
        </Box>
      </Card>
      <findingsExportDialog.Dialog {...findingsExportDialog.getDialogProps()} />
    </>
  );
};

function styles({ palette, space, spacing, typography }: Theme) {
  const accordionOffset = 6;
  const arrowButtonOffset = 1;

  const columnSpacing = '30% 25% 10% 10% 1fr';
  const columnAttrs = {
    columnGap: space.sm,
    display: 'grid',
    gridTemplateColumns: columnSpacing,
  };

  return {
    '& .FindingsAggregatedView-columnHeadings': {
      ...columnAttrs,
      borderBottom: `1px solid ${palette.divider}`,
      padding: `${space.sm} ${space.md} ${space.xs}`,
      paddingLeft: spacing(
        Layout.CARD_PADDING + accordionOffset + arrowButtonOffset
      ),

      '& .MuiTypography-h6': {
        fontSize: typography.body2.fontSize,
      },
    },

    '& .FindingsAggregatedView-header': {
      borderBottom: `1px solid ${palette.divider}`,
      padding: `${spacing(4)} ${spacing(Layout.CARD_PADDING)} ${spacing(5)}`,
      gap: space.sm,
    },

    '& .DrawerAccordion-root': {
      borderBottom: `1px solid ${palette.divider}`,
      paddingX: spacing(Layout.CARD_PADDING),
    },

    '& .FindingsGroupAccordionTitleNode-root': {
      ...columnAttrs,
    },
  };
}
