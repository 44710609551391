import { orderBy as _orderBy } from 'lodash-es';
import { useMemo } from 'react';

import { VersionUpgradeResource } from '@endorlabs/endor-core/VersionUpgrade';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  sortParamBuilders,
  useGetFinding,
  useGetPackageVersion,
  useGetProject,
  useListPolicies,
  useListVersionUpgrade,
} from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

import { StaleTimes } from '../../../constants';

interface UseFindingDetailDataProps {
  findingUuid?: string;
  namespace: string;
}

export const useFindingDetailData = ({
  findingUuid,
  namespace,
}: UseFindingDetailDataProps) => {
  /**
   * Loads the full finding object from the given query object
   */
  const qGetFinding = useGetFinding(
    { namespace: namespace, uuid: findingUuid ?? '' },
    {
      enabled: !!findingUuid && !!namespace,
      staleTime: StaleTimes.MEDIUM,
    }
  );

  const finding = qGetFinding.data;

  const qGetProject = useGetProject(
    {
      namespace: finding?.tenant_meta.namespace ?? '',
      uuid: finding?.spec.project_uuid ?? '',
    },
    {
      enabled: !!finding?.spec.project_uuid,
      staleTime: StaleTimes.MEDIUM,
    }
  );

  const qGetPackageVersion = useGetPackageVersion(
    {
      namespace: finding?.tenant_meta.namespace ?? '',
      uuid: finding?.meta.parent_uuid ?? '',
    },
    {
      enabled:
        !!finding?.meta.parent_uuid &&
        finding?.meta.parent_kind === 'PackageVersion',
      staleTime: StaleTimes.MEDIUM,
    }
  );

  const hasExceptions =
    finding?.spec.exceptions?.policy_uuids &&
    (finding?.spec.exceptions?.policy_uuids ?? []).length > 0;

  const qExceptionPolicies = useListPolicies(
    namespace,
    {
      enabled: hasExceptions && !!finding,
    },
    {
      filter: `uuid in [${(finding?.spec.exceptions?.policy_uuids ?? []).join(
        ','
      )}]`,
    }
  );

  const exceptionPolicies = useMemo(() => {
    return qExceptionPolicies.data?.list?.objects ?? [];
  }, [qExceptionPolicies.data?.list?.objects]);

  const dependencyPackage =
    finding?.spec.target_dependency_package_name &&
    UIPackageVersionUtils.stripPackageVersionRef(
      finding?.spec.target_dependency_package_name
    );
  const qVersionUpgrade = useListVersionUpgrade(
    namespace,
    {
      filter: filterExpressionBuilders.and([
        `spec.upgrade_info.direct_dependency_package=="${dependencyPackage}"`,
        `spec.upgrade_info.from_version=="${finding?.spec.target_dependency_version}"`,
      ]),
      mask: [
        'spec.upgrade_info.direct_dependency_package',
        'spec.upgrade_info.from_version',
        'spec.upgrade_info.is_best',
        'spec.upgrade_info.is_endor_patch',
        'spec.upgrade_info.root_package_version',
        'spec.upgrade_info.score',
        'spec.upgrade_info.to_version',
        'spec.upgrade_info.upgrade_risk',
        'tenant_meta',
      ].join(','),
      page_size: 10,
      sort: sortParamBuilders.descendingBy('spec.upgrade_info.score'),
    },
    { enabled: !!dependencyPackage }
  );

  const { magicPatchVersionUpgrade, versionUpgrade } = useMemo(() => {
    const sortedUpgrades = _orderBy(
      qVersionUpgrade.data?.objects ?? [],
      ['spec.upgrade_info.score'],
      ['desc']
    );

    const magicPatchVersionUpgrade = sortedUpgrades.find(
      (u) => u.spec?.upgrade_info?.is_endor_patch
    );

    const bestUpgrade = sortedUpgrades.find(
      (u) => u.spec?.upgrade_info?.is_best
    );
    let versionUpgrade: VersionUpgradeResource | undefined =
      bestUpgrade ?? sortedUpgrades[0];

    // Hide the additional version upgrade if the Magic Patch is already the
    // best version.
    if (versionUpgrade === magicPatchVersionUpgrade) {
      versionUpgrade = undefined;
    }

    return { magicPatchVersionUpgrade, versionUpgrade };
  }, [qVersionUpgrade.data?.objects]);

  return {
    exceptionPolicies,
    finding,
    isLoading:
      qGetFinding.isLoading ||
      qGetProject.isLoading ||
      qGetPackageVersion.isLoading,
    magicPatchVersionUpgrade,
    packageVersion: qGetPackageVersion.data,
    project: qGetProject.data,
    versionUpgrade,
  };
};
