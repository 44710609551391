import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1CiCdTool } from '@endorlabs/api_client';
import {
  DrawerAccordion,
  Pip,
  RowStack,
  ToolLogoDisplay,
} from '@endorlabs/ui-common';

import { ToolEvidenceAppsDisplay } from './ToolEvidenceAppsDisplay';
import { ToolEvidenceBadgesDisplay } from './ToolEvidenceBadgesDisplay';
import { ToolEvidenceFilePathsDisplay } from './ToolEvidenceFilePathsDisplay';

export const ToolEvidenceAccordion = ({
  tool,
  isExpanded,
  repositoryUrl,
  repositorySha,
}: {
  tool: V1CiCdTool;
  isExpanded?: boolean;
  repositoryUrl?: string;
  repositorySha?: string;
}) => {
  const { appEvidences, badgeEvidences, fileEvidences } = useMemo(() => {
    const appEvidences = tool.evidence?.filter((evd) => evd.app_id) ?? [];
    const badgeEvidences = tool.evidence?.filter((evd) => evd.badge) ?? [];
    const fileEvidences = tool.evidence?.filter((evd) => evd.file_path) ?? [];
    return { appEvidences, badgeEvidences, fileEvidences };
  }, [tool.evidence]);

  return (
    <DrawerAccordion
      align="start"
      expanded={isExpanded}
      id={tool.name}
      titleNode={
        <RowStack flexWrap="nowrap" alignItems="start">
          <Box>
            <ToolLogoDisplay toolName={tool.name} fontSize="medium" />
          </Box>

          <Stack>
            <RowStack>
              <Typography variant="button">{tool.name}</Typography>

              <Pip
                color="default"
                label={
                  <FM
                    defaultMessage="{count, plural, =0 {no evidence items} one {# evidence item} other {# evidence items}}"
                    values={{ count: tool.evidence?.length }}
                  />
                }
              />
            </RowStack>

            <Typography component="span">{tool.description}</Typography>
          </Stack>
        </RowStack>
      }
    >
      <Stack
        gap={4}
        // Account for offset of drawer collapse icon + tool logo
        marginLeft={12.5}
        marginTop={4}
      >
        {fileEvidences?.length > 0 && (
          <ToolEvidenceFilePathsDisplay
            evidences={fileEvidences}
            repositoryUrl={repositoryUrl}
            repositorySha={repositorySha}
          />
        )}

        {badgeEvidences?.length > 0 && (
          <ToolEvidenceBadgesDisplay evidences={badgeEvidences} />
        )}

        {appEvidences?.length > 0 && (
          <ToolEvidenceAppsDisplay evidences={appEvidences} />
        )}
      </Stack>
    </DrawerAccordion>
  );
};
