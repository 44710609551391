import produce from 'immer';
import { set as _set } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { V1Project } from '@endorlabs/api_client';
import {
  IQueryError,
  ProjectResource,
  useUpdateProject,
} from '@endorlabs/queries';
import { useAppNotify } from '@endorlabs/ui-common';

import { FormProjectSettingsLabelsFieldValues } from '../components/FormProjectSettingsLabels';

export const useUpdateSingleProjectFields = (
  namespace: string,
  project?: ProjectResource | V1Project
) => {
  const addAppNotification = useAppNotify();

  const qUpdateProject = useUpdateProject({
    onSuccess: () => {
      addAppNotification({
        id: 'project:update:success',
        severity: 'success',
        message: <FM defaultMessage="Project Successfully Updated" />,
      });
    },
    onError: (error: IQueryError) => {
      addAppNotification({
        id: 'project:update:error',
        severity: 'error',
        message: <FM defaultMessage="Error Updating Project" />,
        details: error.response.data?.message,
      });
    },
  });

  /**
   * Handles updating a single field on the project, with the given mask
   */
  const handleUpdateProjectField = (
    mask: string,
    value: unknown,
    project?: V1Project
  ) => {
    // TODO: handle edge case
    if (!project) return;

    const updated = produce(project, (draft) => {
      _set(draft, mask, value);
    });

    qUpdateProject.mutate({
      mask,
      namespace,
      resource: updated,
    });
  };

  const handleUpdateLabels = (values: FormProjectSettingsLabelsFieldValues) => {
    handleUpdateProjectField('meta.tags', values.meta.tags, project);
  };

  const handleToggleAutomatedScans = (
    event: React.SyntheticEvent,
    enableAutomatedScan: boolean
  ) => {
    // NOTE: the control shows the inverse of the actual value
    // When the toggle is `checked`, this value should be `false
    handleUpdateProjectField(
      'processing_status.disable_automated_scan',
      !enableAutomatedScan,
      project
    );
  };

  return {
    handleUpdateProjectField,
    handleUpdateLabels,
    handleToggleAutomatedScans,
    qUpdateProject,
  };
};
