import produce from 'immer';
import { isEmpty as _isEmpty, unset as _unset } from 'lodash-es';

import { PackagistSpecAuthKind } from '@endorlabs/api_client';

import {
  PackagistFieldValues,
  PasswordAuthFieldValues,
  TokenAuthFieldValues,
} from './types';

interface ServerFieldError {
  field: 'url' | 'user' | 'password';
  object: { type: string; message: string };
}

export const translateServerError = (code: number): ServerFieldError => {
  switch (code) {
    default:
      return {
        field: 'url',
        object: {
          type: 'server',
          message: 'An unknown error occurred',
        },
      };
  }
};

export const updatePasswordFieldsToModel = (
  fieldValues: PasswordAuthFieldValues,
  isAuthDisplayed: boolean
) => {
  const formattedValues = produce(fieldValues, (draft) => {
    //Reset the username and password if user unselects the authRequired checkbox
    if (!isAuthDisplayed) {
      _unset(draft, 'user');
      _unset(draft, 'password');
    }
  });
  return formattedValues;
};

export const updateTokenFieldsToModel = (
  fieldValues: TokenAuthFieldValues,
  isAuthDisplayed: boolean
) => {
  const formattedValues = produce(fieldValues, (draft) => {
    //Reset the username and password if user unselects the authRequired checkbox
    if (!isAuthDisplayed) {
      _unset(draft, 'token');
      _unset(draft, 'scope');
    }
  });
  return formattedValues;
};

export const updatePackagistFieldsToModel = (
  fieldValues: PackagistFieldValues
) => {
  const formattedValues = produce(fieldValues, (draft) => {
    if (
      fieldValues.auth_kind != PackagistSpecAuthKind.HttpBasic &&
      fieldValues.auth_kind != PackagistSpecAuthKind.BitbucketOauth &&
      fieldValues.auth_kind != PackagistSpecAuthKind.GitlabToken
    ) {
      _unset(draft, 'user');
    } else if (_isEmpty(fieldValues.password)) {
      _unset(draft, 'user');
      _unset(draft, 'password');
    }
  });
  return formattedValues;
};
