import { Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1ChangeType,
  V1Confidence,
  V1ModifierChangeType,
  V1VersionDiffResult,
} from '@endorlabs/api_client';
import {
  DrawerAccordion,
  IconPackage,
  InlineCodeDisplay,
} from '@endorlabs/ui-common';

import {
  parseBreakingChangeReachablePaths,
  parseBreakingChangeURI,
} from '../../utils';
import { BreakingChangeDisplayTitle } from './BreakingChangeDisplayTitle';

export type BreakingChangeDisplayProps = {
  ciaResult: V1VersionDiffResult;
  isExpanded: boolean;
};

export type BreakingChangeData = {
  className?: string;
  functionName?: string;
  diffChange?: V1ChangeType;
  modifierChange?: V1ModifierChangeType;
  confidence?: V1Confidence;
  paths?: {
    packageName: string;
    affectedPaths: string[];
  }[];
  id?: string;
};

export const BreakingChangeDisplay = ({
  ciaResult,
  isExpanded,
}: BreakingChangeDisplayProps) => {
  const { space } = useTheme();

  const breakingChangeData = useMemo(() => {
    if (ciaResult.function_change) {
      const functionData = parseBreakingChangeURI(
        ciaResult.function_change?.function_reference ?? ''
      );
      const reachablePaths = parseBreakingChangeReachablePaths(
        ciaResult.reachable_path ?? []
      );
      const paths = Object.entries(reachablePaths).map(([key, value]) => {
        return {
          packageName: key,
          affectedPaths: value.map((val) => {
            const pathSegments = [];
            if (val.className) {
              pathSegments.push(val.className);
            }
            if (val.functionName) {
              pathSegments.push(`${val.functionName}()`);
            }
            return pathSegments.join('.');
          }),
        };
      });
      return {
        className: functionData.className,
        functionName: functionData.functionName,
        diffChange: ciaResult.function_change?.diff_change,
        modifierChange: ciaResult.function_change?.modifier_change,
        confidence: ciaResult.confidence,
        paths,
        id: ciaResult.function_change.id,
      };
    } else if (ciaResult.type_change) {
      const paths = Object.entries(ciaResult.violating_types ?? {}).map(
        ([key, value]) => {
          return {
            packageName: key,
            affectedPaths: value.values ?? [],
          };
        }
      );
      return {
        className: ciaResult.type_change.declared_type,
        diffChange: ciaResult.type_change?.diff_change,
        modifierChange: ciaResult.type_change?.modifier_change,
        confidence: ciaResult.confidence,
        paths,
        id: ciaResult.type_change.id,
      };
    }
  }, [ciaResult]);

  const hasViolatingTypes =
    ciaResult.violating_types &&
    Object.keys(ciaResult.violating_types).length > 0;

  const hasPaths =
    breakingChangeData?.paths && breakingChangeData?.paths.length > 0;

  return (
    <DrawerAccordion
      align="start"
      expanded={isExpanded}
      id={`breaking-change-${breakingChangeData?.id}`}
      titleNode={
        <BreakingChangeDisplayTitle breakingChanges={breakingChangeData} />
      }
    >
      {hasPaths && (
        <Stack spacing={space.sm} paddingX={space.sm} paddingY={space.xs}>
          <Typography component="h4" variant="overline">
            {hasViolatingTypes ? (
              <FM defaultMessage="Sample Call Affected Path(s)" />
            ) : (
              <FM defaultMessage="Violating Type(s)" />
            )}
          </Typography>
          <Stack spacing={space.sm}>
            {breakingChangeData?.paths.map(
              ({ packageName, affectedPaths }, index) => (
                <Stack
                  direction="row"
                  key={`${packageName}-${index}`}
                  spacing={space.sm}
                >
                  <IconPackage />
                  <Stack flexGrow={1} spacing={space.xs}>
                    <Typography variant="h6">{packageName}</Typography>
                    <InlineCodeDisplay showIcon={true}>
                      {affectedPaths}
                    </InlineCodeDisplay>
                  </Stack>
                </Stack>
              )
            )}
          </Stack>
        </Stack>
      )}
    </DrawerAccordion>
  );
};
