import { Box, SvgIconProps, Theme, useTheme } from '@mui/material';

import { V1Ecosystem } from '@endorlabs/api_client';

import { EcosystemIcons } from '../../constants';
import { IconConstellation, IconPlanet } from '../../themes';

/**
 * Component to display ecosystem icon with border.
 * Intended for cases where multiple packages may be present.
 * If needed for just one ecosystem display, see PackageIconDisplay/PackageNameDisplay component.
 */

export interface EcosystemIconDisplayProps extends SvgIconProps {
  ecosystem: V1Ecosystem[];
  displayDefault: 'package' | 'dependency';
  multiplePackages: boolean;
}

export const EcosystemIconDisplay = ({
  ecosystem,
  displayDefault,
  multiplePackages,
}: EcosystemIconDisplayProps) => {
  const sx = styles(useTheme());

  const sortedEcosystems = [...ecosystem].sort();
  const displayedEcosystem = sortedEcosystems[0];

  const ecosystemIcon = EcosystemIcons[displayedEcosystem] ? (
    <img
      src={EcosystemIcons[displayedEcosystem]}
      style={{ height: '1rem', width: 'auto' }}
      alt=""
      role="presentation"
    />
  ) : displayDefault === 'package' ? (
    <IconPlanet fontSize="inherit" />
  ) : (
    <IconConstellation fontSize="inherit" />
  );

  return (
    <Box
      sx={{ ...sx.borderContainer, ...(multiplePackages && sx.multiBorder) }}
    >
      {ecosystemIcon}
    </Box>
  );
};

function styles({ palette }: Theme) {
  return {
    borderContainer: {
      display: 'flex',
      position: 'relative',
      padding: '2px',
      border: `1px solid ${palette.text.light}`,
      borderRadius: '4px',
    },
    multiBorder: {
      '&::before': {
        content: "' '",
        position: 'absolute',
        zIndex: '-1',
        top: '-1px',
        left: '0px',
        right: '-3px',
        bottom: '-1px',
        borderRight: `1px solid ${palette.text.light}`,
        borderRadius: '7px / 4px',
      },
      '&::after': {
        content: "' '",
        position: 'absolute',
        zIndex: '-1',
        top: '-0px',
        left: '0px',
        right: '-5px',
        bottom: '-0px',
        borderRight: `1px solid ${palette.text.light}`,
        borderRadius: '7px / 4px',
      },
    },
  };
}
