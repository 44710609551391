import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, ErrorOption, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  IQueryErrorResponse,
  SBOMOrganizationalContactResource,
} from '@endorlabs/queries';

import { REGEX_EMAIL_VALIDATION } from '../../../../constants';
import { IconAlertTriangle } from '../../../../themes';

interface ServerFieldError<T> {
  field?: keyof T;
  object: ErrorOption;
}

const translateServerError = (
  code: number
): ServerFieldError<FormUpsertSBOMOrganizationalContactFieldValues> => {
  switch (code) {
    case 403: {
      return {
        object: {
          type: 'server',
          message:
            'Failed to update SBOM Organizational Contact. Not authorized to make updates.',
        },
      };
    }
    default:
      return {
        object: {
          type: 'server',
          message: 'An unknown error occurred',
        },
      };
  }
};

export type FormUpsertSBOMOrganizationalContactFieldValues = Pick<
  SBOMOrganizationalContactResource,
  'spec'
>;

export type FormUpsertSBOMOrganizationalContactProps = {
  onSubmit: (data: FormUpsertSBOMOrganizationalContactFieldValues) => void;
  isLoading: boolean;
  /**
   * Existing Resource to be updated. Must be memoized.
   */
  sBOMOrganizationalContact?: SBOMOrganizationalContactResource;
  serverErrorResponse?: IQueryErrorResponse;
};

export const FormUpsertSBOMOrganizationalContact = ({
  onSubmit,
  sBOMOrganizationalContact,
  isLoading,
  serverErrorResponse,
}: FormUpsertSBOMOrganizationalContactProps) => {
  const { formatMessage: fm } = useIntl();

  const { control, handleSubmit, reset, setError } =
    useForm<FormUpsertSBOMOrganizationalContactFieldValues>({});

  useEffect(() => {
    // ensure required props for the form are defaulted
    // add defaults as needed
    const merged = {
      ...sBOMOrganizationalContact,
      spec: {
        ...sBOMOrganizationalContact?.spec,
      },
    };

    reset(merged);
  }, [reset, sBOMOrganizationalContact]);

  const [localError, setLocalError] = useState<ErrorOption | undefined>();
  useEffect(() => {
    // handle server error
    if (serverErrorResponse) {
      const { field, object } = translateServerError(
        serverErrorResponse.status
      );
      if (field) {
        setError(field, object);
      } else {
        setLocalError(object);
      }
    } else {
      setLocalError(undefined);
    }
  }, [serverErrorResponse, setError]);

  // process the form data before passing it up to the parent
  const wrappedOnSubmit = useCallback(
    (fieldValues: FormUpsertSBOMOrganizationalContactFieldValues) => {
      onSubmit(fieldValues);
    },
    [onSubmit]
  );

  return (
    <form
      id="FormUpsertSBOMOrganizationalContact"
      onSubmit={handleSubmit(wrappedOnSubmit)}
    >
      <Grid container direction="column" spacing={6} sx={{ marginTop: 0 }}>
        <Grid item>
          <Box
            component="fieldset"
            sx={{ margin: 0, padding: 0, border: 'none ' }}
          >
            <FormLabel component="legend" sx={{ marginBottom: 2 }}>
              <Typography variant="body1" color="text.primary">
                <FM defaultMessage="Supplier Name" />
              </Typography>

              <Typography variant="body2">
                <FM defaultMessage="The organization that supplied the component that the SBOM describes" />
              </Typography>
            </FormLabel>

            <Controller
              control={control}
              defaultValue={''}
              name="spec.organizational_entity_name"
              render={({ field: { ref, ...props }, fieldState }) => {
                return (
                  <TextField
                    {...props}
                    autoComplete="off"
                    error={!!fieldState.error}
                    fullWidth
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    inputRef={ref}
                    label={fm({ defaultMessage: 'Organization Name' })}
                    variant="standard"
                  />
                );
              }}
              rules={{
                required: fm({
                  defaultMessage: 'Organization name is required',
                }),
              }}
            />
          </Box>
        </Grid>

        <Grid item>
          <Box
            component="fieldset"
            sx={{ margin: 0, padding: 0, border: 'none ' }}
          >
            <FormLabel component="legend" sx={{ marginBottom: 2 }}>
              <Typography variant="body1" color="text.primary">
                <FM defaultMessage="Supplier Contact" />
              </Typography>

              <Typography variant="body2">
                <FM defaultMessage="A contact at the organization who may be contacted for SBOM related inquires" />
              </Typography>
            </FormLabel>

            {/* NOTE: this control could instead be handled with field array, making all contact attributes editable */}

            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="spec.organizational_contacts.0.name"
                  render={({ field: { ref, ...props }, fieldState }) => {
                    return (
                      <TextField
                        {...props}
                        autoComplete="off"
                        error={!!fieldState.error}
                        fullWidth
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                        inputRef={ref}
                        label={fm({
                          defaultMessage: 'Contact name',
                        })}
                        variant="standard"
                      />
                    );
                  }}
                  rules={{
                    required: fm({
                      defaultMessage: 'Contact name is required',
                    }),
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="spec.organizational_contacts.0.email"
                  render={({ field: { ref, ...props }, fieldState }) => {
                    return (
                      <TextField
                        {...props}
                        autoComplete="off"
                        error={!!fieldState.error}
                        fullWidth
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                        inputRef={ref}
                        label={fm({
                          defaultMessage: 'Contact email address',
                        })}
                        variant="standard"
                      />
                    );
                  }}
                  rules={{
                    required: fm({
                      defaultMessage: 'Contact email address is required',
                    }),
                    validate: (value) =>
                      value?.trim().match(REGEX_EMAIL_VALIDATION) ||
                      fm({
                        defaultMessage:
                          'One or more email addresses is invalid',
                      }),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item>
          <Box
            component="fieldset"
            sx={{ margin: 0, padding: 0, border: 'none ' }}
          >
            <FormLabel component="legend" sx={{ marginBottom: 2 }}>
              <Typography variant="body1" color="text.primary">
                <FM defaultMessage="Supplier URL" />
              </Typography>

              <Typography variant="body2">
                <FM defaultMessage="The primary URL of your organization." />
              </Typography>
            </FormLabel>

            <Controller
              control={control}
              defaultValue={''}
              // NOTE: this control could instead be handled with field array, making all urls editable
              name={`spec.organization_urls.0` as const}
              render={({ field: { ref, ...props }, fieldState }) => (
                <TextField
                  {...props}
                  autoComplete="off"
                  error={!!fieldState.error}
                  fullWidth
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  inputRef={ref}
                  placeholder={fm({
                    defaultMessage: 'https://example.com',
                  })}
                  variant="standard"
                />
              )}
              rules={{
                required: fm({
                  defaultMessage: 'Organization URL is required',
                }),
              }}
            />
          </Box>
        </Grid>

        <Grid item>
          <LoadingButton
            loading={isLoading}
            type="submit"
            color="primary"
            variant="contained"
          >
            <FM defaultMessage="Save SBOM Settings" />
          </LoadingButton>
        </Grid>

        {localError && (
          <Grid item>
            <Alert severity="error" icon={<IconAlertTriangle />}>
              <AlertTitle>{localError.message}</AlertTitle>
            </Alert>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
