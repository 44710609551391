import { Checkbox, FormControlLabel } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { FILTER_COMPARATORS, FilterableResource } from '@endorlabs/filters';
import { MultiSelectInput, SeverityDisplay } from '@endorlabs/ui-common';

import { FacetFilterDefinition } from '../../../components';
import { FilterFieldConfig, filterFieldTransformBuilders } from '../../filters';
import { SastRulesDefinedBy } from '../types';

export const FindingPolicyFacets = [
  {
    id: 'FindingPolicy:spec.disable',
    filter: {
      kind: 'Policy' as FilterableResource,
      key: 'spec.disable',
      type: 'boolean',
    },
    control: 'FacetFilterToggle',
    comparator: FILTER_COMPARATORS.NOT_EQUAL,
    label: <FM defaultMessage="Hide Disabled" />,
  },
  {
    id: 'FindingPolicy:spec.finding.level',
    filter: {
      kind: 'Policy' as FilterableResource,
      key: 'spec.finding.level',
      type: 'array',
    },
    comparator: FILTER_COMPARATORS.CONTAINS,
    label: <FM defaultMessage="Finding Level" />,
    control: 'FacetFilterMultiSelect',
    values: Object.values(SpecFindingLevel)
      .filter((lvl) => lvl !== SpecFindingLevel.Unspecified)
      .map((value) => ({
        key: value.toString(),
        value,
        label: <SeverityDisplay value={value} />,
      })),
  },
] as FacetFilterDefinition[];

export const AdmissionPolicyFacets = [
  {
    id: 'AdmissionPolicy:spec.admission.disable_enforcement',
    filter: {
      kind: 'Policy',
      key: 'spec.admission.disable_enforcement',
      type: 'boolean',
    },
    control: 'FacetFilterToggle',
    comparator: FILTER_COMPARATORS.NOT_EQUAL,
    label: <FM defaultMessage="Hide Warnings" />,
  },
] as FacetFilterDefinition[];

const SastFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'definedBy', //Todo: Change it to field once disabled is defined in response.
  ...filterFieldTransformBuilders.fromFilter({
    key: 'definedBy', //Todo: Change it to field once disabled is defined in response.
    comparator: FILTER_COMPARATORS.IN,
  }),
  renderInput: ({ onChange, value }) => (
    <MultiSelectInput
      label={<FM defaultMessage="Defined By" />}
      onChange={onChange}
      options={Object.values(SastRulesDefinedBy).map((value) => ({
        value,
        label: value,
      }))}
      value={value}
    />
  ),
} satisfies FilterFieldConfig<string[]>;

export const SAST_FILTER_FIELDS: FilterFieldConfig<any>[] = [
  SastFilterFieldConfig,
];

const DisabledRemediationPolicyFieldConfig: FilterFieldConfig<any> = {
  id: 'spec.disable',
  ...filterFieldTransformBuilders.fromFilter({
    key: 'spec.disable',
    comparator: FILTER_COMPARATORS.NOT_EQUAL,
  }),

  renderInput: ({ onChange, value }) => (
    <FormControlLabel
      componentsProps={{
        typography: {
          fontWeight: value ? 'bold' : undefined,
        },
      }}
      control={
        <Checkbox checked={value} onChange={(_, value) => onChange(value)} />
      }
      label={<FM defaultMessage="Hide Disabled" />}
      sx={{
        color: 'primary.main',
        marginRight: 0,
        paddingX: 3,
        borderRadius: 1,
      }}
    />
  ),
} satisfies FilterFieldConfig<boolean>;

// Remediation Policy filter fields
export const REMEDIATION_POLICIES_FILTER_FIELDS: FilterFieldConfig<any>[] = [
  DisabledRemediationPolicyFieldConfig,
];

/**
 * Default search keys for use with Remediation Policies filters
 */
export const REMEDIATION_POLICIES_SEARCH_KEYS = ['meta.name'];
