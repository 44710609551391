import { Skeleton } from '@mui/material';
import { RowSelectionState } from '@tanstack/react-table';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FindingResource, FindingSource } from '@endorlabs/endor-core/Finding';
import {
  PackageVersionResource,
  selectFindingCountsFromGroupResponse,
  useListFindings,
} from '@endorlabs/queries';
import {
  CommonDataTableRowActions,
  DataTableView,
  DataTableViewProps,
  FindingCountArrayDisplay,
  RowStack,
  useDataTablePaginator,
  useDialog,
} from '@endorlabs/ui-common';

import { StaleTimes } from '../../../constants';
import { FilterBar, useFilterContext } from '../../filters';
import { ExceptionPolicyCreateDialog } from '../../Policies/components/ExceptionPolicyCreateDialog';
import { useFindingDetailDrawer, useFindingsFilterFields } from '../hooks';
import { useFindingsData } from '../hooks/useFindingsData';
import {
  buildFindingsTableV2ColumnDefs,
  FindingsTableRowV2,
  mapFindingToV2TableRow,
} from './FindingsTable';

interface FindingsDataTableViewProps
  extends Omit<DataTableViewProps<FindingsTableRowV2>, 'columns' | 'data'> {}

export const FindingsDataTableView = ({
  namespace,
}: FindingsDataTableViewProps) => {
  const { DetailDrawer, permalinkEffect } = useFindingDetailDrawer();

  const { filter: filterExpression, _state: filterState } = useFilterContext();

  const filterFields = useFindingsFilterFields(FindingSource.All);

  const qFindingsGroupByLevel = useListFindings(
    namespace,
    {
      enabled: !!namespace && !!filterExpression,
      staleTime: StaleTimes.EXTENDED,
    },
    {
      filter: filterExpression,
      group: {
        aggregation_paths: 'spec.level',
      },
    }
  );

  const [findingCounts, totalCount] = useMemo(() => {
    if (qFindingsGroupByLevel.isLoading) {
      return [[], 0];
    }

    const findingCounts = selectFindingCountsFromGroupResponse(
      qFindingsGroupByLevel.data?.group_response
    );
    const totalCount = findingCounts.reduce((s, v) => s + v.value, 0);

    return [findingCounts, totalCount];
  }, [qFindingsGroupByLevel.data, qFindingsGroupByLevel.isLoading]);

  const paginator = useDataTablePaginator({
    totalCount: totalCount,
  });

  const { findings, isLoading, packageVersions } = useFindingsData({
    namespace,
    filterExpression,
    filterState,
    paginator,
  });

  useLayoutEffect(() => {
    // @ts-expect-error - Wants FindingsQueryResponse but that's unnecessary
    permalinkEffect(findings);
  }, [findings, permalinkEffect]);

  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const handleRowSelection = (state: RowSelectionState) => {
    setSelectedRowCount(Object.keys(state).length);
  };

  const exceptionPolicyCreateDialog = useDialog({
    component: ExceptionPolicyCreateDialog,
  });

  const openExceptionPolicyDialog = useCallback(
    (rows: FindingsTableRowV2[]) => {
      if (rows.length < 1) return;

      // Get Finding and PackageVersion objects from the selected rows
      const selectedFindings = rows
        .map((r) => findings.find((f) => f.uuid === r.uuid))
        .filter(Boolean) as FindingResource[];

      const selectedPackageVersions = rows
        .map((r) => r.packageVersion)
        .filter(Boolean) as PackageVersionResource[];

      exceptionPolicyCreateDialog.openDialog({
        findings: selectedFindings,
        namespace,
        packageVersions: selectedPackageVersions,
      });
    },
    [exceptionPolicyCreateDialog, findings, namespace]
  );

  const bulkActions = [
    {
      actionId: 'ADD_TO_EXCEPTION_POLICY',
      disabled: selectedRowCount === 0,
      isSelectionRequired: true,
      label: <FM defaultMessage="Add Exception" />,
      onApply: openExceptionPolicyDialog,
    },
  ];

  const rowActions = [
    {
      ...CommonDataTableRowActions.ACTIVATE_DRAWER,
      isPrimaryAction: true,
      onClick: (row: FindingsTableRowV2) => {
        DetailDrawer.activate(
          {
            findingUuid: row.uuid,
            findingNamespace: row.namespace,
          },
          { findingUuid: row.uuid, namespace: row.namespace }
        );
      },
    },
  ];

  return (
    <>
      <DataTableView
        bulkActions={bulkActions}
        columns={buildFindingsTableV2ColumnDefs()}
        countMessage={
          <RowStack>
            {qFindingsGroupByLevel.isLoading ? (
              <Skeleton width={72} />
            ) : (
              <FM
                defaultMessage="{totalCount, number, ::compact-short} Findings"
                values={{ totalCount }}
              />
            )}
            <FindingCountArrayDisplay value={findingCounts} />
          </RowStack>
        }
        data={findings.map((finding) =>
          mapFindingToV2TableRow({ finding, namespace, packageVersions })
        )}
        filtersContent={
          <FilterBar
            enableAdvanced
            enableSavedFilters
            fields={filterFields}
            isDataTableView
          />
        }
        isLoading={isLoading}
        namespace={namespace}
        onRowSelectionChange={handleRowSelection}
        paginator={paginator}
        rowActions={rowActions}
      />

      <exceptionPolicyCreateDialog.Dialog
        {...exceptionPolicyCreateDialog.dialogProps}
      />
    </>
  );
};
