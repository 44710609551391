import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { minutesToMilliseconds } from 'date-fns';
import produce from 'immer';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1Installation,
  V1PlatformSource,
  V1ScanState,
} from '@endorlabs/api_client';
import {
  QueryInstallationsResponseObject,
  sortParamBuilders,
  useDeleteInstallation,
  useQueryInstallations,
  useUpdateInstallation,
} from '@endorlabs/queries';
import {
  AccordionCard,
  ButtonPrimary,
  ButtonSecondary,
  DataTableActionDropdown,
  EmptyState,
  IconCloud,
  IconCloudOff,
  IconRotateCcw,
  MetadataList,
  MetadataMetric,
  ScanStateLabel,
  useAppNotify,
  useConfirmationDialog,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { useAuthInfo } from '../../providers';
import {
  getIntegrationsRootPath,
  getProjectPath,
  useFullMatch,
} from '../../routes';
import {
  SOURCE_CONTROL_MANAGERS,
  SourceControlManagerOption,
} from './constants';
import EditEnableFeatureDialog from './EditEnableFeatureDialog';
import {
  InstallationProjectsTable,
  InstallationProjectsTableRow,
} from './InstallationProjectsTable';
import { InstallationListItem } from './types';

const REFRESH_INTERVAL = minutesToMilliseconds(1);

const mapToInstallationList = (
  installations: QueryInstallationsResponseObject[]
): InstallationListItem[] => {
  return installations.map((installation) => {
    const { Project } = installation.meta.references;

    const installationProjects: InstallationProjectsTableRow[] = (
      Project?.list?.objects ?? []
    ).map((project) => {
      const { LatestScanResult } = project.meta.references;

      const scanResults = LatestScanResult?.list?.objects ?? [];

      return {
        uuid: project.uuid,
        namespace: project?.tenant_meta.namespace,
        name: project?.meta.name,
        scanTime: project?.processing_status?.scan_time,
        analyticTime: project?.processing_status?.analytic_time,
        platformSource: project?.spec.platform_source,
        webUrl: project?.spec.git?.web_url,
        scanResults,
      };
    });

    // get the repository url for use as link
    const repositoryUrl = installationProjects.find((p) => !!p.webUrl)?.webUrl;

    const lastScanTime = installation.processing_status?.scan_time;
    const scanState = installation.processing_status?.scan_state;

    const summary: MetadataMetric[] = [
      {
        label: <FM defaultMessage="Projects" />,
        value: installationProjects.length,
        variant: 'raw',
      },
      {
        label: <FM defaultMessage="URL" />,
        value: repositoryUrl,
        variant: 'link',
      },
      {
        label: <FM defaultMessage="Last Scanned" />,
        value: lastScanTime,
        variant: 'date',
      },
      {
        label: <FM defaultMessage="Scan Status" />,
        value: <ScanStateLabel value={scanState} />,
        variant: 'raw',
      },
    ];

    const installationListData: InstallationListItem = {
      uuid: installation.uuid,
      name: installation.meta.name,
      login: installation.spec.login,
      projects: installationProjects,
      summary,
      scanState,
      scanTime: lastScanTime,
      isInvalid: installation.spec.invalid === true,
      enabledFeatures: installation.spec.enabled_features,
    };
    return installationListData;
  });
};

export const SourceControlSettingsPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { platformName },
  } = useFullMatch();

  const sourceControlManager = SOURCE_CONTROL_MANAGERS.find(
    (pm) => pm.key.toLowerCase() === platformName?.toLocaleLowerCase()
  ) as SourceControlManagerOption;

  // NOTE: considering all installations as GitHub
  const qListInstallations = useQueryInstallations(
    tenantName,
    { sort: sortParamBuilders.descendingBy('meta.create_time') },
    {
      refetchInterval: REFRESH_INTERVAL,
    }
  );

  const installations = useMemo(
    () => mapToInstallationList(qListInstallations.data?.list?.objects ?? []),
    [qListInstallations.data?.list?.objects]
  );

  const addAppNotification = useAppNotify();

  const qDeleteInstallation = useDeleteInstallation();
  const handleDeleteInstallation = (item?: InstallationListItem) => {
    // Find installation from list results
    const installation =
      item &&
      qListInstallations.data?.list?.objects.find((o) => o.uuid === item.uuid);
    if (!installation) return;

    qDeleteInstallation.mutate(
      {
        namespace: installation.tenant_meta.namespace,
        uuid: installation.uuid,
      },
      {
        onError: () => {
          addAppNotification({
            id: 'installation:delete:error',
            message: <FM defaultMessage="We've Encountered a Problem" />,
            details: (
              <FM defaultMessage="Failed to delete Installation. Please try again" />
            ),
            severity: 'error',
          });
        },
        onSuccess: () => {
          qListInstallations.refetch();
        },
      }
    );
  };

  const qUpdateInstallation = useUpdateInstallation({
    onError: () => {
      addAppNotification({
        id: 'installation:rescan:error',
        message: (
          <FM defaultMessage="Failed to schedule a rescan. Please try again." />
        ),
        severity: 'error',
      });
    },
    onSuccess: () => {
      addAppNotification({
        id: 'installation:rescan:success',
        message: <FM defaultMessage="Successfully scheduled a rescan. " />,
        severity: 'success',
      });
    },
  });
  const handleRescanOrg = (item?: InstallationListItem) => {
    const installation = qListInstallations.data?.list?.objects.find(
      (i) => i.uuid === item?.uuid
    );
    // TODO: handle possible edge case
    if (!installation) return;

    const updatedInstallation = produce(installation, (draft) => {
      // unset the scan state to trigger a rescan
      draft.processing_status = {
        ...draft.processing_status,
        scan_state: V1ScanState.NotProcessed,
        scan_time: undefined,
      };
    });

    qUpdateInstallation.mutate({
      namespace: updatedInstallation.tenant_meta.namespace,
      resource: updatedInstallation,
      mask: [
        'processing_status.scan_state',
        'processing_status.scan_time',
      ].join(','),
    });
  };

  /**  Licensing and Bundling **/
  const [installationToEdit, setInstallationToEdit] =
    useState<InstallationListItem>({} as InstallationListItem);
  const [editFeatureDialogOpen, setEditFeatureDialogOpen] = useState(false);

  const handleClose = () => {
    setEditFeatureDialogOpen(false);
    setInstallationToEdit({} as InstallationListItem);
  };

  /** End */

  const InstallationDeleteConfirmation =
    useConfirmationDialog<InstallationListItem>({
      confirmText: <FM defaultMessage="Delete" />,
      isDestructive: true,
      onConfirm: handleDeleteInstallation,
      titleText: <FM defaultMessage="Delete this Integration?" />,
      descriptionText: (
        <FM defaultMessage="Projects created through this integration will no longer be automatically scanned." />
      ),
    });

  const isLoading = qListInstallations.isLoading;
  const isEmptyState = !isLoading && installations?.length === 0;
  const installationResponseObject =
    qListInstallations.data?.list?.objects.find(
      (i) => i.uuid === installationToEdit?.uuid
    ) ?? ({} as V1Installation);

  return (
    <>
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <PageHeader
            action={
              !isEmptyState && (
                <Box display="flex" justifyContent="flex-end">
                  <ButtonPrimary
                    href={getProjectPath({ tenantName, uuid: 'new' })}
                  >
                    <FM defaultMessage="Scan More Repositories" />
                  </ButtonPrimary>
                </Box>
              )
            }
            breadcrumbsLinks={[
              {
                label: <FM defaultMessage="Integrations" />,
                url: getIntegrationsRootPath({ tenantName }),
              },
            ]}
            metadata={{ summary: [] }}
            title={sourceControlManager.label}
          />
        </Grid>

        {isEmptyState && (
          <Grid item>
            <EmptyState
              size="large"
              title={
                <FM defaultMessage="You have not scanned any GitHub repositories." />
              }
              description={
                <FM defaultMessage="As you scan your GitHub repositories, keep track of all points of data ingest here" />
              }
            >
              <ButtonPrimary href={getProjectPath({ tenantName, uuid: 'new' })}>
                <FM defaultMessage="Scan Repositories" />
              </ButtonPrimary>
            </EmptyState>
          </Grid>
        )}

        {!isEmptyState && (
          <Grid container direction="column" item spacing={6}>
            {installations?.map((installation) => {
              return (
                <Grid item key={installation.uuid}>
                  <AccordionCard
                    disableHeaderTextSelection
                    key={installation.uuid}
                    id={installation.uuid}
                    summaryContent={
                      <MetadataList metrics={installation.summary} />
                    }
                    title={
                      <Stack alignItems="center" direction="row" spacing={3}>
                        {installation.isInvalid ? (
                          <Tooltip
                            placement="bottom-end"
                            title={
                              <>
                                <Typography variant="h4" marginBottom={1}>
                                  <FM defaultMessage="Invalid GitHub App Installation" />
                                </Typography>
                                <Typography variant="caption">
                                  <FM defaultMessage="Unable to re-sync projects through this installation." />
                                </Typography>
                              </>
                            }
                          >
                            <IconCloudOff
                              fontSize="inherit"
                              sx={{ color: 'status.failure' }}
                            />
                          </Tooltip>
                        ) : (
                          <IconCloud fontSize="inherit" />
                        )}

                        <Typography component="span" variant="inherit">
                          {installation.name}
                        </Typography>
                      </Stack>
                    }
                    action={
                      <Stack
                        direction="row"
                        display="flex"
                        justifyContent="flex-end"
                        spacing={4}
                      >
                        <ButtonSecondary
                          disabled={
                            installation.scanState !== V1ScanState.Idle ||
                            installation.isInvalid ||
                            isLoading ||
                            qUpdateInstallation.isLoading
                          }
                          onClick={(evt) => {
                            evt.stopPropagation();
                            handleRescanOrg(installation);
                          }}
                          size="small"
                          startIcon={<IconRotateCcw fontSize="inherit" />}
                        >
                          <FM defaultMessage="Rescan Org" />
                        </ButtonSecondary>
                        {sourceControlManager.platformSource ===
                          V1PlatformSource.Github && (
                          <DataTableActionDropdown
                            items={[
                              {
                                label: <FM defaultMessage="Edit Integration" />,
                                onClick: (evt) => {
                                  evt.stopPropagation();
                                  setInstallationToEdit(installation);
                                  setEditFeatureDialogOpen(true);
                                },
                              },
                              {
                                isDestructive: true,
                                label: (
                                  <FM defaultMessage="Delete Integration" />
                                ),
                                onClick: (evt) => {
                                  evt.stopPropagation();
                                  InstallationDeleteConfirmation.openDialog(
                                    installation
                                  );
                                },
                              },
                            ]}
                          />
                        )}
                      </Stack>
                    }
                  >
                    <InstallationProjectsTable
                      data={installation.projects}
                      isLoading={isLoading}
                      emptyStateProps={{
                        title: (
                          <FM defaultMessage="No Projects found for this installation" />
                        ),
                      }}
                    />
                  </AccordionCard>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>

      <InstallationDeleteConfirmation.Dialog
        {...InstallationDeleteConfirmation.dialogProps}
      />

      <EditEnableFeatureDialog
        open={editFeatureDialogOpen}
        onClose={handleClose}
        installation={installationToEdit}
        installationObject={installationResponseObject}
        updateInstallation={() => qListInstallations.refetch()}
      />
    </>
  );
};
