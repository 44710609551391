import { Box, IconButton, StackProps, Theme, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { IconXCircle, useStyles } from '@endorlabs/ui-common';

import { useInfoDrawer } from '../InfoDrawer';
import { DRAWER_SECTION_PADDING_HORIZ } from './constants';

export interface DetailDrawerHeaderProps extends Omit<StackProps, 'title'> {
  action?: ReactNode;
  title?: ReactNode;
}

/**
 * Layout component to keep detail drawer layout simple
 */
export const DetailDrawerHeader = ({
  action,
  title,
}: DetailDrawerHeaderProps) => {
  const sx = useStyles(styles);
  const InfoDrawerState = useInfoDrawer();

  return (
    <Box className="DetailDrawerHeader-root" sx={sx}>
      <Box className="DetailDrawerHeader-header">
        <Typography className="DetailDrawerHeader-title" variant="h3">
          {title}
        </Typography>

        <IconButton
          className="DetailDrawerHeader-close"
          onClick={InfoDrawerState.close}
        >
          <IconXCircle fontSize="medium" />
        </IconButton>
      </Box>

      <Box className="DetailDrawerHeader-action">{action}</Box>
    </Box>
  );
};

const styles = ({ space }: Theme) => ({
  '&.DetailDrawerHeader-root': {
    display: 'grid',
    paddingLeft: DRAWER_SECTION_PADDING_HORIZ,
    paddingRight: space.sm,
    paddingTop: space.sm,
  },

  '& .DetailDrawerHeader-header': {
    display: 'grid',
    gap: space.sm,
    gridTemplateColumns: '1fr 20px',
  },

  '& .DetailDrawerHeader-title': {},

  '& .DetailDrawerHeader-close': {
    placeSelf: 'start end',
  },
});
