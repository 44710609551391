import _map from 'lodash-es/map';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { useFeatureFlags, useSession } from '@endorlabs/queries';

import {
  useAuthInfo,
  useAuthTenantInfo,
  useLicensingInfo,
} from '../../providers';
import {
  getArtifactsNavItems,
  getCICDNavItems,
  getDiscoverNavItems,
  getInventoryNavItems,
  getManageNavItems,
  getRemediateNavItems,
} from './NavItemsUtils';
import { AppDrawerNavItemProps, NavItemsContextProps } from './types';

export const useAppDrawerNavSections = () => {
  const {
    getLastUsedTenant,

    hasValidTenantAccess,
  } = useSession();

  const lastUsedTenant = getLastUsedTenant(true);
  /**
   * Verify the user can access tenants other than Global (oss) or Shared (demo-trial)
   */
  const hasTenants = hasValidTenantAccess();

  const {
    ENABLE_CI_CD_TOOLS: isCiCdToolsEnabled,
    ENABLE_ARTIFACTS: isArtifactsEnabled,
    ENABLE_SERVICE_REQUEST: isServiceRequestEnabled,
    ENABLE_LICENSE_AND_BUNDLING: isLicenseAndBundlingEnabled,
    ENABLE_REMEDIATIONS: isRemediationsEnabled,
  } = useFeatureFlags();

  const { isPremiumTenant, isSharedTenant } = useAuthTenantInfo(lastUsedTenant);
  const { licenseInfo } = useAuthInfo();
  const { checkLicensePresent } = useLicensingInfo();

  const { navSections, sharedTenantNavKeys } = useMemo(() => {
    const navSections = [];
    let sharedTenantNavKeys: string[] = [],
      navItems: AppDrawerNavItemProps[] = [];

    const context: NavItemsContextProps = {
      licenseInfo,
      hasTenants,
      isArtifactsEnabled,
      isCiCdToolsEnabled,
      isLicenseAndBundlingEnabled,
      isPremiumTenant,
      isRemediationsEnabled,
      isServiceRequestEnabled,
      isSharedTenant,
      lastUsedTenant,
    };

    // When tenant is set, enable the nav items
    if (hasTenants && lastUsedTenant) {
      const isSBomPresent = checkLicensePresent(
        SpecEndorLicenseFeatureType.SbomIngestion
      );
      const isCICDPresent = checkLicensePresent(
        SpecEndorLicenseFeatureType.CicdDiscovery
      );
      const isArtifactPresent = checkLicensePresent(
        SpecEndorLicenseFeatureType.ArtifactSign
      );
      const isRemediatePresent = checkLicensePresent(
        SpecEndorLicenseFeatureType.Recommendation
      );

      const inventoryNavItems: AppDrawerNavItemProps[] = getInventoryNavItems(
        lastUsedTenant,
        isSBomPresent
      );

      navSections.push({ navItems: inventoryNavItems });

      sharedTenantNavKeys = _map(inventoryNavItems, 'navKey');

      navSections.push({
        title: <FM defaultMessage="Discover" />,
        navItems: getDiscoverNavItems(),
      });

      const cicdItem = getCICDNavItems(lastUsedTenant, context, isCICDPresent);
      const artifactItem = getArtifactsNavItems(
        lastUsedTenant,
        context,
        isArtifactPresent
      );

      navItems = [...cicdItem, ...artifactItem];

      if (navItems.length > 0) {
        navSections.push({
          title: <FM defaultMessage="CI/CD" />,
          navItems,
        });
      }

      // NOTE: The global remediations section needs to be hidden for now, and would be enabled later
      // const remediateItems = getRemediateNavItems(
      //   lastUsedTenant,
      //   context,
      //   isRemediatePresent
      // );

      // if (remediateItems.length > 0) {
      //   navSections.push({
      //     title: <FM defaultMessage="Remediate" />,
      //     navItems: remediateItems,
      //   });
      // }

      // TODO: use auth policy to check if user should have permissions on the given tenant
      const manageNavItems: AppDrawerNavItemProps[] = getManageNavItems(
        lastUsedTenant,
        context
      );

      navSections.push({
        title: <FM defaultMessage="Manage" />,
        navItems: manageNavItems,
      });
      sharedTenantNavKeys.push('droidGPT', 'notifications', 'policies');
    }

    return { navSections, sharedTenantNavKeys };
  }, [
    licenseInfo,
    hasTenants,
    isArtifactsEnabled,
    isCiCdToolsEnabled,
    isLicenseAndBundlingEnabled,
    isPremiumTenant,
    isServiceRequestEnabled,
    isSharedTenant,
    lastUsedTenant,
    checkLicensePresent,
  ]);
  return { navSections, sharedTenantNavKeys };
};
