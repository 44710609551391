import { defineMessages, MessageDescriptor } from 'react-intl';

import {
  V1Method as PermissionRuleMethod,
  V1SystemRole as PermissionRole,
} from '@endorlabs/api_client';

export const PermissionRuleMethodMessages: Record<
  PermissionRuleMethod,
  MessageDescriptor
> = defineMessages({
  [PermissionRuleMethod.All]: { defaultMessage: 'All' },
  [PermissionRuleMethod.Create]: { defaultMessage: 'Create' },
  [PermissionRuleMethod.Delete]: { defaultMessage: 'Delete' },
  [PermissionRuleMethod.Unspecified]: { defaultMessage: 'Unspecified' },
  [PermissionRuleMethod.Update]: { defaultMessage: 'Update' },
  [PermissionRuleMethod.Read]: { defaultMessage: 'Read' },
});

export const PermissionRoleMessages: Record<PermissionRole, MessageDescriptor> =
  defineMessages({
    [PermissionRole.Admin]: { defaultMessage: 'Admin' },
    [PermissionRole.CodeScanner]: { defaultMessage: 'Code Scanner' },
    [PermissionRole.PolicyEditor]: { defaultMessage: 'Policy Editor' },
    [PermissionRole.ReadOnly]: { defaultMessage: 'Read-Only' },
    [PermissionRole.Unspecified]: { defaultMessage: 'Unspecified' },
    [PermissionRole.MagicPatching]: { defaultMessage: 'Magic Patching User' },
  });
