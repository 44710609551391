import { Box, Typography, useTheme } from '@mui/material';

import { V1PlatformSource } from '@endorlabs/api_client';
import { ProjectMinimal } from '@endorlabs/endor-core/Project';

import { UIProjectUtils } from '../../domains';
import { RowStack } from '../RowStack';

export interface ProjectNameDisplayV2Props {
  platformSource?: V1PlatformSource;
  project: ProjectMinimal;
  showIcon?: boolean;
  size?: 'small' | 'medium' | 'large' | 'inherit';
}

export const ProjectNameDisplayV2 = ({
  platformSource: platformSourceOverride,
  project,
  showIcon = true,
  size = 'small',
}: ProjectNameDisplayV2Props) => {
  const platformSource = platformSourceOverride ?? project.spec.platform_source;

  const { palette, space, spacing } = useTheme();

  const PlatformIcon = UIProjectUtils.getPlatformIcon(platformSource);
  const sizeMedium = size === 'medium';

  return (
    <RowStack flexWrap="nowrap" gap={space.xs}>
      {showIcon && (
        <Box
          alignItems="center"
          display="flex"
          sx={
            palette.darkMode
              ? {
                  backgroundColor: palette.common.white,
                  borderRadius: spacing(1),
                  padding: spacing(0.5),
                }
              : undefined
          }
        >
          <PlatformIcon fontSize={sizeMedium ? 'small' : size} />
        </Box>
      )}

      <Typography
        component="span"
        fontSize={sizeMedium ? 'inherit' : size}
        variant={sizeMedium ? 'button' : 'body1'}
      >
        {UIProjectUtils.parseProjectName(project.meta.name, platformSource)}
      </Typography>
    </RowStack>
  );
};
