import { getEnv } from '../../../constants';
import { getProjectDetailPath } from '../../../routes';
import { BasicTourStepProps } from '../types';
import { clickOn, waitForElementAvailable } from '../utils';

const ENV = getEnv();
const selNav = '#app-primary-nav [data-testid="AppDrawerNavItem-projects"]';
// const selFilterButton =
//   '[data-testid="ControlsFacetFilterFindingAttributes-button"]';
// const selAttrPopover = '.MuiPopover-paper';
// const selBtnPatch = 'button[value="FINDING_TAGS_FIX_AVAILABLE"]';
// const selBtnReachable = 'button[value="FINDING_TAGS_REACHABLE_FUNCTION"]';

export const getProjectFindingsStep = ({
  navigate,
  tourData,
}: BasicTourStepProps) => {
  const selFinding = `#FindingSummaryAccordion-${tourData.vulnFindingUuid}`;

  return {
    attachTo: {
      element: selFinding,
      on: 'bottom-start',
    },
    beforeShowPromise: async function () {
      navigate({
        to: getProjectDetailPath({
          tenantName: tourData.tourTenant,
          uuid: tourData.findingsProject?.uuid,
          section: 'findings',
        }),
      });
      await waitForElementAvailable(selFinding);

      // await clickOn(selFilterButton);
      // await clickOn(selBtnReachable);
    },

    highlightSelectors: [selFinding, selNav],
    title: 'Prioritize Reachable Vulnerabilities',
    description:
      'Cut down the open source vulnerability noise by over 80% using reachability analysis.',

    stepUrl: getProjectDetailPath({
      tenantName: tourData.tourTenant,
      uuid: tourData.findingsProject?.uuid,
      section: 'findings',
    }),

    urlLearn: `${ENV.URL_ENDOR_DOCS}/managing-projects/view-findings/`,
  };
};
