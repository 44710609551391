export const getLinkOverrides = () => {
  return {
    MuiLink: {
      defaultProps: {
        color: 'text.link',
        underline: 'hover',
      },
    },
  };
};
