import { defineMessages, MessageDescriptor } from 'react-intl';

import { V1Language } from '@endorlabs/api_client';

export const LanguageMessages: Record<V1Language, MessageDescriptor> =
  defineMessages({
    [V1Language.C]: { defaultMessage: 'C/C++' },
    [V1Language.Csharp]: { defaultMessage: '.Net (C#)' },
    [V1Language.Go]: { defaultMessage: 'Golang' },
    [V1Language.Java]: { defaultMessage: 'Java' },
    [V1Language.Js]: { defaultMessage: 'JavaScript' },
    [V1Language.Kotlin]: { defaultMessage: 'Kotlin' },
    [V1Language.Objectivec]: { defaultMessage: 'Objective C' },
    [V1Language.Php]: { defaultMessage: 'PHP' },
    [V1Language.Python]: { defaultMessage: 'Python' },
    [V1Language.Ruby]: { defaultMessage: 'Ruby' },
    [V1Language.Rust]: { defaultMessage: 'Rust' },
    [V1Language.Scala]: { defaultMessage: 'Scala' },
    [V1Language.Swift]: { defaultMessage: 'Swift' },
    [V1Language.Typescript]: { defaultMessage: 'TypeScript' },
    [V1Language.Unspecified]: { defaultMessage: 'Unspecified' },
  });
