import { FormattedMessage as FM } from 'react-intl';

import { getArtifactsPath } from '../../routes/utils';

export const ArtifactsTabNames = {
  Signatures: 'signatures',
} as const;

export type ArtifactsTabName =
  typeof ArtifactsTabNames[keyof typeof ArtifactsTabNames];

const buildTabRecords = (namespace: string) => {
  return [
    {
      label: <FM defaultMessage="Signatures" />,
      to: getArtifactsPath({
        tenantName: namespace,
      }),
      value: ArtifactsTabNames.Signatures,
    },
  ];
};

export const useArtifactsPageTabs = ({
  activeTab: targetActiveTab,
  namespace,
}: {
  activeTab: ArtifactsTabName;
  namespace: string;
}) => {
  const tabRecords = buildTabRecords(namespace);

  const activeTab =
    tabRecords.find((r) => r.value === targetActiveTab)?.value ??
    ArtifactsTabNames.Signatures;

  return { activeTab, tabRecords };
};
