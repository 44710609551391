import { Box, Theme } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { useStyles } from '@endorlabs/ui-common';

import { useInfoDrawer } from '../InfoDrawer';

interface PageLayoutProps {
  pageContent: ReactNode;
  pageHeader: ReactNode;
  pageNavigation: ReactNode;
}

export const PageLayout = ({
  pageHeader,
  pageNavigation,
  pageContent,
}: PageLayoutProps) => {
  const sx = useStyles(styles);

  const { isOpen: isDetailDrawerOpen } = useInfoDrawer();

  const classNames = clsx({
    'PageLayout-root': true,
    'PageLayout-drawerOpen': isDetailDrawerOpen,
  });

  return (
    <Box className={classNames} sx={sx}>
      <Box className="PageLayout-pageHeader">{pageHeader}</Box>

      <Box className="PageLayout-pageNavigation">{pageNavigation}</Box>

      <Box className="PageLayout-content">{pageContent}</Box>
    </Box>
  );
};

const styles = ({ space }: Theme) => ({
  '&.PageLayout-root': {
    columnGap: space.md,
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gridTemplateRows: 'auto auto',
    rowGap: space.md,
    transition:
      'column-gap 300ms ease-out, grid-template-columns 300ms ease-out',

    '& .PageLayout-pageHeader': {
      gridColumn: '1 / 3',
    },

    '& .PageLayout-pageNavigation': {
      gridColumn: '1 / 2',
      opacity: 1,
      overflow: 'hidden',
      transition: 'opacity 200ms',
    },

    '& .PageLayout-content': {
      gridColumn: '2 / 3',
    },

    // Transition page navigation out if drawer open
    '&.PageLayout-drawerOpen': {
      columnGap: 0,
      gridTemplateColumns: '0 1fr',

      '&. PageLayout-pageNavigation': {
        opacity: 0,
      },
    },
  },
});
