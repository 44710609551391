import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { FormattedMessage as FM, FormattedNumber } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  useCountPackageVersions,
  useCountProjects,
  useCountUniqueDependencyMetadata,
} from '@endorlabs/queries';

import { useAuthInfo } from '../../providers';

export const ResourceStatsVis = () => {
  const { activeNamespace: tenantName } = useAuthInfo();

  const qCountProjectsTotal = useCountProjects(tenantName, {
    staleTime: Infinity,
  });

  const projectsCount = qCountProjectsTotal.data?.count ?? 0;

  const qCountPackageVersionsTotal = useCountPackageVersions(
    tenantName,
    {
      staleTime: Infinity,
    },
    {
      filter: filterExpressionBuilders.mainResourceContext(),
    }
  );

  const packageVersionsCount = qCountPackageVersionsTotal.data?.count ?? 0;

  const qCountDependenciesTotal = useCountUniqueDependencyMetadata(
    tenantName,
    {
      staleTime: Infinity,
    },
    {
      filter: filterExpressionBuilders.defaultResourceContexts(),
    }
  );

  const dependenciesCount = qCountDependenciesTotal.data?.count ?? 0;

  return (
    <Grid container justifyContent="space-around" data-testid="resource-stats">
      <Grid item>
        <Stack direction="column" justifyContent="center" spacing={2}>
          <Typography variant="h3" textAlign="center">
            <FM defaultMessage="Your Projects" />
          </Typography>

          <Typography variant="xxl" textAlign="center">
            {qCountProjectsTotal.isLoading && <CircularProgress />}
            {!qCountProjectsTotal.isLoading && (
              <FormattedNumber value={projectsCount} notation="compact" />
            )}
          </Typography>
        </Stack>
      </Grid>

      <Grid item>
        <Stack direction="column" justifyContent="center" spacing={2}>
          <Typography variant="h3" textAlign="center">
            <FM defaultMessage="Your Packages" />
          </Typography>

          <Typography variant="xxl" textAlign="center">
            {qCountPackageVersionsTotal.isLoading && <CircularProgress />}
            {!qCountPackageVersionsTotal.isLoading && (
              <FormattedNumber
                value={packageVersionsCount}
                notation="compact"
              />
            )}
          </Typography>
        </Stack>
      </Grid>

      <Grid item>
        <Stack direction="column" justifyContent="center" spacing={2}>
          <Typography variant="h3" textAlign="center">
            <FM defaultMessage="Dependencies" />
          </Typography>

          <Typography variant="xxl" textAlign="center">
            {qCountDependenciesTotal.isLoading && <CircularProgress />}
            {!qCountDependenciesTotal.isLoading && (
              <FormattedNumber value={dependenciesCount} notation="compact" />
            )}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
