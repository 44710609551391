import {
  Box,
  Button,
  Divider,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { MakeGenerics, useSearch } from '@tanstack/react-location';
import { MouseEvent, useCallback, useState } from 'react';
import { FormattedList, FormattedMessage as FM, useIntl } from 'react-intl';

import { ButtonLogin, IconMail, IconUnlock } from '@endorlabs/ui-common';

import { BrandedLink, EndorSalesLink } from '../../../components';
import { NamedRoutes } from '../../../routes';
import { AuthContainer } from './AuthContainer';
import {
  privacyPolicyLink,
  SuccessEmail,
  termsOfUseLink,
} from './AuthHelperComponents';
import { LoginButtons } from './LoginButtons';
import { LoginErrorDisplay } from './LoginErrorDisplay';
import { LoginFormEmailLink } from './LoginFormEmailLink';
import { LoginFormSSO } from './LoginFormSSO';

interface LoginPageProps {
  invitation?: boolean;
}

type LoginRouteLocationGenerics = MakeGenerics<{
  Search: {
    code?: string;
    return_to?: string;
  };
}>;

export const LoginPage = ({ invitation = false }: LoginPageProps) => {
  const { code, return_to } = useSearch<LoginRouteLocationGenerics>();
  const { formatMessage: fm } = useIntl();
  const theme = useTheme();

  const [loginMode, setLoginMode] = useState<
    'AUTH_PROVIDER' | 'EMAIL' | 'TENANT_SSO'
  >('AUTH_PROVIDER');

  const [loginSuccess, setLoginSuccess] = useState(false);

  const sx = styles(theme);

  const handleEmailLinkSent = useCallback(() => {
    setLoginSuccess(true);
  }, []);

  const handleHideEmailSuccess = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setLoginSuccess(false);
  }, []);

  return (
    <AuthContainer>
      {loginSuccess ? (
        <SuccessEmail
          onReturnLinkClick={handleHideEmailSuccess}
          returnTo={NamedRoutes.LOGIN}
        />
      ) : (
        <Stack spacing={6} alignItems="center" sx={sx.textWrapper}>
          {!code && (
            <Typography sx={sx.textLogin} variant="h1">
              <FM defaultMessage="Sign In" />
            </Typography>
          )}

          {code && <LoginErrorDisplay />}

          {loginMode === 'AUTH_PROVIDER' && (
            <Stack
              direction="column"
              spacing={4}
              justifyContent="center"
              width="auto"
            >
              <LoginButtons returnTo={return_to} />

              <ButtonLogin
                onClick={() => setLoginMode('EMAIL')}
                color="secondary"
                sx={{
                  '& .MuiButton-startIcon': {
                    color: theme.palette.brand.main,
                  },
                }}
                Icon={IconMail}
              >
                <FM defaultMessage="Log in with email link" />
              </ButtonLogin>
            </Stack>
          )}

          {loginMode === 'EMAIL' && (
            <LoginFormEmailLink
              onEmailLinkSent={handleEmailLinkSent}
              returnTo={return_to}
            />
          )}
          {loginMode === 'TENANT_SSO' && <LoginFormSSO returnTo={return_to} />}

          <Typography
            variant="caption"
            sx={{ width: '100%' }}
            textAlign="center"
          >
            <FM
              defaultMessage="By logging in, you agree to the Endor Labs <privacy></privacy>
                and <terms></terms>."
              values={{
                privacy: () => privacyPolicyLink,
                terms: () => termsOfUseLink,
              }}
            />
          </Typography>

          <Divider sx={{ width: '100%' }} />

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
            width="auto"
          >
            <Button
              onClick={() =>
                setLoginMode((mode) =>
                  mode !== 'AUTH_PROVIDER' ? 'AUTH_PROVIDER' : 'TENANT_SSO'
                )
              }
              variant="text"
              size="large"
              startIcon={<IconUnlock fontSize="medium" />}
            >
              {loginMode === 'AUTH_PROVIDER' ? (
                <FM defaultMessage="Log in with Enterprise SSO" />
              ) : (
                <FM defaultMessage="Log in a different way" />
              )}
            </Button>

            <Typography color="text.secondary" variant="body2">
              <FM defaultMessage="Don't have an Endor Labs account yet?" />
            </Typography>

            <Box>
              <FormattedList
                value={[
                  <BrandedLink key="signup" to={NamedRoutes.SIGNUP}>
                    <FM defaultMessage="Sign up for a free trial" />
                  </BrandedLink>,
                  <EndorSalesLink key="contact" />,
                ]}
                type="disjunction"
              />
            </Box>
          </Stack>
        </Stack>
      )}
    </AuthContainer>
  );
};

function styles({ breakpoints, palette }: Theme) {
  return {
    textWrapper: {
      height: '100%',
      background: palette.background.paper,
      padding: '36px',
      width: '30vw',
      [breakpoints.down('md')]: {
        marginTop: '1rem',
        width: '100vw',
      },
    },
    textLogin: {
      fontSize: '2.5rem',
      fontWeight: 600,
      padding: '24px',
      [breakpoints.down('md')]: {
        paddingY: '56px',
      },
    },
  };
}
