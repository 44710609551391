import { getEnv } from '../../constants';

const ENV = getEnv();

const DOCS_ROOT = ENV.URL_ENDOR_DOCS;

/**
 * The keys starting with 'tour-' maps to the tour component id, so each 'Learn more' url uses `tour-${TourID}` as key
 */
const DocLinks = [
  {
    key: 'tour-project',
    url: `${DOCS_ROOT}/getting-started/`,
  },
  {
    key: 'tour-projectFindings',
    url: `${DOCS_ROOT}/getting-started/`,
  },
  {
    key: 'tour-callpath',
    url: `${DOCS_ROOT}/getting-started/`,
  },
  {
    key: 'tour-secrets',
    url: `${DOCS_ROOT}/getting-started/`,
  },
  {
    key: 'tour-securityPosture',
    url: `${DOCS_ROOT}/getting-started/`,
  },
  {
    key: 'tour-createPolicy',
    url: `${DOCS_ROOT}/getting-started/`,
  },
  {
    key: 'tour-exportData',
    url: `${DOCS_ROOT}/getting-started/`,
  },
  {
    key: 'tour-ossDependency',
    url: `${DOCS_ROOT}/getting-started/`,
  },
  {
    key: 'tour-droidGPT',
    url: `${DOCS_ROOT}/getting-started/`,
  },
  {
    key: 'onboard-add-project-step-how-to',
    url: `${DOCS_ROOT}/getting-started/`,
  },
  {
    key: 'onboard-add-project-step-troubleshoot',
    url: `${DOCS_ROOT}/getting-started/`,
  },
];

const onboardingLinks = [
  {
    key: 'onboard-documentation',
    url: `${DOCS_ROOT}`,
  },
  {
    key: 'onboard-blog',
    url: 'https://www.endorlabs.com/blog',
  },
  {
    key: 'onboard-youtube',
    url: 'https://www.youtube.com/channel/UCtYJpGeyFGDrTBelaWubwEQ',
  },
  {
    key: 'onboard-view-events',
    url: `https://endorlabs.com/events`,
  },
];

export const getDocLink = (key: string) => {
  return DocLinks.find((d) => d.key === key)?.url ?? DOCS_ROOT;
};

export const getOnboardingLink = (key: string) => {
  return onboardingLinks.find((d) => d.key === key)?.url ?? DOCS_ROOT;
};
