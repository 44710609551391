import { Grid, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import {
  useCreateScanProfile,
  useListMasterToolChainProfiles,
} from '@endorlabs/queries';

import { PageHeader } from '../../../components';
import { FormUpsertScanProfile } from '../../../domains/ScanProfiles';
import { useAuthInfo } from '../../../providers';
import { getSettingsScanProfilesPath } from '../../../routes';

export const ScanProfileCreatePage = () => {
  const { space } = useTheme();
  const { activeNamespace } = useAuthInfo();
  const navigate = useNavigate();

  const qListMasterToolChainProfiles = useListMasterToolChainProfiles(
    activeNamespace,
    {},
    { staleTime: Infinity }
  );

  const masterToolChainProfile = qListMasterToolChainProfiles.data?.objects[0];

  const qCreateScanProfile = useCreateScanProfile({
    onSuccess: (data) => {
      // On success, navigate to newly created scan profile page
      navigate({
        replace: true,
        to: getSettingsScanProfilesPath({
          tenantName: data.tenant_meta.namespace,
          uuid: data.uuid,
        }),
      });
    },
  });

  const handleSubmit = (resource: ScanProfileResource) => {
    qCreateScanProfile.mutate({ namespace: activeNamespace, resource });
  };

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={space.sm}>
      <Grid item>
        <PageHeader title={<FM defaultMessage="New Scan Profile" />} />
      </Grid>

      <Grid item>
        <FormUpsertScanProfile
          isLoading={
            qListMasterToolChainProfiles.isLoading ||
            qCreateScanProfile.isLoading
          }
          masterToolChainProfile={masterToolChainProfile}
          namespace={activeNamespace}
          onSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};
