import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { RowSelectionState, Table } from '@tanstack/react-table';
import { find as _find } from 'lodash-es';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  filterExpressionBuilders,
  useFilterSearchParams,
} from '@endorlabs/filters';
import {
  PackageVersionResource,
  useGroupDependencyMetadata,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  EmptyState,
  SearchBar,
  usePackageVersionDependencies,
  usePackageVersionDependencyScoreMetrics,
  useTanstackTableRef,
} from '@endorlabs/ui-common';

import { FIVE_MINUTES_IN_MILLISECONDS } from '../../constants';
import {
  DependenciesTable,
  DependenciesTableHeader,
  DependenciesTableRow,
  useDependencyDetailDrawer,
} from '../../domains/Dependencies';

export interface DependencyDetailDependenciesProps {
  dependencyPackageVersion?: PackageVersionResource;
  tenantName: string;
}

export const DependencyDetailDependencies = ({
  dependencyPackageVersion,
  tenantName,
}: DependencyDetailDependenciesProps) => {
  const { formatMessage: fm } = useIntl();
  const {
    filterDefaultSearchParams: searchValue,
    updateFilterDefaultSearchParams: handleSearchChange,
  } = useFilterSearchParams();
  const { DetailDrawer, permalinkEffect } = useDependencyDetailDrawer();

  const [selectedRows, setSelectedRows] = useState({});
  const tableRef = useTanstackTableRef<DependenciesTableRow>();

  useEffect(() => {
    if (!DetailDrawer.isOpen && Object.keys(selectedRows).length > 0) {
      if (tableRef.current) {
        const currentTable = tableRef.current as Table<DependenciesTableRow>;
        currentTable.resetRowSelection();
        setSelectedRows({});
      }
    }
  }, [DetailDrawer, selectedRows, tableRef]);

  const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(rowSelection);
  }, []);

  const qDependenciesTotal = usePackageVersionDependencies(
    tenantName,
    dependencyPackageVersion
  );

  const qDependencies = usePackageVersionDependencies(
    tenantName,
    dependencyPackageVersion,
    searchValue
  );

  const qDependencyScoreMetrics = usePackageVersionDependencyScoreMetrics(
    tenantName,
    qDependencies.dependencies
  );

  // Get the count for Dependency -> Dependencies
  // NOTE: this uses dep meta aggregation counts instead of the PackageVersion resolved dependencies,
  // and _should_ be eventually consistent
  const qGroupedDependencyDependencies = useGroupDependencyMetadata(
    tenantName,
    {
      enabled: dependencyPackageVersion && !!qDependencies.dependencies.length,
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    },
    {
      filter: [
        `spec.importer_data.package_version_uuid==${dependencyPackageVersion?.uuid}`,
        filterExpressionBuilders.defaultResourceContexts(),
      ].join(' and '),
      group: {
        aggregation_paths: 'spec.dependency_data.package_version_uuid',
      },
    }
  );

  // merge dependencies with score metrics
  const [dependencyPackageVersionDependencies, hasApproximateDependencies] =
    useMemo(() => {
      let hasApproximateDependencies = false;
      const groupedDependencyCounts =
        qGroupedDependencyDependencies.data?.groups ?? {};

      const dependencyPackageVersionDependencies =
        qDependencies.dependencies.map((dep) => {
          if (dep.hasApproximation) {
            hasApproximateDependencies = true;
          }

          // if a package version record for the dependency exists,
          // find the related score metrics and dependency counts
          if (dep.uuid) {
            const scoreMetrics = qDependencyScoreMetrics.scoreMetrics[dep.uuid];
            const dependencyDependenciesCount = _find(
              groupedDependencyCounts,
              (_, key) => key.includes(dep.uuid as string)
            );

            return {
              id: dep.uuid, // Required for tanstack-table operations
              ...dep,
              ...scoreMetrics,
              dependenciesCount:
                dependencyDependenciesCount?.aggregation_count?.count,
            };
          }

          return dep;
        });

      return [dependencyPackageVersionDependencies, hasApproximateDependencies];
    }, [
      qDependencies.dependencies,
      qGroupedDependencyDependencies.data,
      qDependencyScoreMetrics.scoreMetrics,
    ]);

  const handleClearSearch = useCallback(() => {
    handleSearchChange('');
  }, [handleSearchChange]);

  const handleClickDetail = useCallback(
    (row?: DependenciesTableRow) => {
      if (!row?.name) return;

      DetailDrawer.activate(
        {
          name: row.name,
          namespace: row.namespace,
        },
        {
          importingNamespace: tenantName,
          importingPackageVersion: dependencyPackageVersion,
          name: row.name,
          namespace: row.namespace,
          uuid: row.uuid,
        }
      );
    },
    [DetailDrawer, dependencyPackageVersion, tenantName]
  );

  // show the detail drawer if possible
  useLayoutEffect(
    () =>
      permalinkEffect({
        dependencies: dependencyPackageVersionDependencies,
        importingNamespace: tenantName,
        importingPackageVersion: dependencyPackageVersion,
      }),
    [
      dependencyPackageVersion,
      dependencyPackageVersionDependencies,
      permalinkEffect,
      tenantName,
    ]
  );

  const isLoading =
    !dependencyPackageVersion ||
    qDependencies.isLoading ||
    qDependencyScoreMetrics.isLoading;

  const isEmptyState =
    !isLoading &&
    dependencyPackageVersionDependencies.length === 0 &&
    !searchValue;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="This dependency version does not have any dependencies" />
            }
            description={
              <FM defaultMessage="Select another dependency, or dependency version to view its dependencies." />
            }
          ></EmptyState>
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <SearchBar
            onSearch={handleSearchChange}
            placeholder={fm({ defaultMessage: 'Search for Dependencies' })}
            searchValue={searchValue}
          />
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <Card>
            <CardHeader
              title={
                <DependenciesTableHeader
                  isLoading={isLoading}
                  hasApproximateDependencies={hasApproximateDependencies}
                  totalCount={qDependenciesTotal.dependencies.length}
                  filteredCount={qDependencies.dependencies.length}
                  resourceKind="Package"
                />
              }
            />
            <CardContent>
              <DependenciesTable
                isLoading={isLoading}
                data={dependencyPackageVersionDependencies}
                includeColumns={[
                  'hasApproximation',
                  'isDirectDependency',
                  'dependenciesCount',
                  'reachability',
                  'isPublic',
                  'sourceCode',
                ]}
                showVersion
                enableColumnSort
                enablePagination
                ref={tableRef}
                enableRowSelection
                onRowSelectionChange={handleRowSelection}
                onClickDetail={handleClickDetail}
                emptyStateProps={{
                  title: (
                    <FM defaultMessage="No Dependencies match the search criteria" />
                  ),
                  children: (
                    <ButtonCancel onClick={handleClearSearch}>
                      <FM defaultMessage="Clear Search" />
                    </ButtonCancel>
                  ),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
