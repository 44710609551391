import {
  Box,
  IconButton,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';

import { V1SemgrepNativeRule } from '@endorlabs/api_client';
import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';
import {
  FileDownloadButton,
  IconExternalLink,
  RowStack,
  useStyles,
} from '@endorlabs/ui-common';
import { CodeEditor } from '@endorlabs/ui-common/domains/code';

import { DetailDrawerSection } from '../../../../components/DetailDrawer/DetailDrawerSection';

export const FindingRuleDrawer = ({
  sastRuleForFinding,
}: {
  sastRuleForFinding: SemgrepRulesResource | undefined;
}) => {
  const { space, palette } = useTheme();

  const sx = useStyles(styles);

  const fileName = `${sastRuleForFinding?.spec?.rule?.id ?? 'rule'}.json`;
  return (
    <DetailDrawerSection>
      <Stack
        direction="column"
        border={`1px solid ${palette.design.grays[200]}`}
        sx={sx}
      >
        <Stack
          gap={space.xs}
          width="100%"
          direction="row"
          justifyContent={'space-between'}
          padding={4}
          borderBottom={`1px solid ${palette.design.grays[200]}`}
          alignItems="center"
        >
          <Typography variant="caption">
            {sastRuleForFinding?.spec?.rule?.id ?? 'Rule'}
          </Typography>
          <RowStack gap={space.sm} alignItems="center">
            <FileDownloadButton
              filetype="json"
              filename={fileName}
              data={JSON.stringify(sastRuleForFinding?.spec?.rule) ?? ''}
              size="small"
            />
            {/* TODO: Add external link to rules edit page */}
            {/* <Link to={''}> */}
            <IconButton>
              <IconExternalLink />
            </IconButton>
            {/* </Link> */}
          </RowStack>
        </Stack>

        <Box>
          <CodeEditor
            editorOptions={{
              lineNumbers: true,
              lineWrapping: false,
            }}
            enableClipboard={false}
            height="auto"
            language="application/json"
            value={JSON.stringify(sastRuleForFinding?.spec?.rule, null, '\t')}
            width="100%"
          />
        </Box>
      </Stack>
    </DetailDrawerSection>
  );
};

function styles({ palette }: Theme) {
  return {
    '.CodeMirror': {
      border: 'none',
      background: palette.design.grays[100],
    },
  };
}
