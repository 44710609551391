import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  FindingAttributeValue,
  FindingResource,
  getFindingAttributeValues,
} from '@endorlabs/endor-core/Finding';
import {
  IQueryError,
  PackageVersionResource,
  ProjectResource,
} from '@endorlabs/queries';
import {
  FindingAttributesArrayDisplay,
  Link,
  NilDisplay,
  PackageNameDisplay,
  ProjectNameDisplay,
  SeverityDisplay,
} from '@endorlabs/ui-common';

import { getPackageVersionPath, getProjectPath } from '../../../routes';

export const getFindingDetailMetadata = (
  finding?: FindingResource,
  findingProject?: ProjectResource,
  findingPackageVersion?: PackageVersionResource
) => {
  const findingAttributes = finding
    ? getFindingAttributeValues(finding)
    : ([] as FindingAttributeValue[]);

  return [
    {
      label: <FM defaultMessage="Severity" />,
      value: finding?.spec.level ? (
        <SeverityDisplay value={finding.spec.level} />
      ) : (
        <NilDisplay variant="text" />
      ),
    },
    {
      label: <FM defaultMessage="Project" />,
      value: findingProject ? (
        <Link
          to={getProjectPath({
            tenantName: findingProject.tenant_meta.namespace,
            uuid: findingProject.uuid,
          })}
        >
          <ProjectNameDisplay
            name={findingProject.meta.name}
            platformSource={findingProject.spec.platform_source}
          />
        </Link>
      ) : (
        <NilDisplay variant="text" />
      ),
    },
    {
      label: <FM defaultMessage="Package" />,
      value: findingPackageVersion ? (
        <Link
          to={getPackageVersionPath({
            tenantName: findingPackageVersion.tenant_meta.namespace,
            uuid: findingPackageVersion.uuid,
          })}
        >
          <PackageNameDisplay name={findingPackageVersion.meta.name} />
        </Link>
      ) : (
        <NilDisplay variant="text" />
      ),
    },
    {
      label: <FM defaultMessage="Attributes" />,
      value: <FindingAttributesArrayDisplay value={findingAttributes} />,
    },
  ];
};

export const getFindingErrorMessage = (
  error?: IQueryError
): { title: ReactNode; description: ReactNode } => {
  const statusCode = error?.response?.status;

  switch (statusCode) {
    case 403: {
      return {
        title: <FM defaultMessage="Unable to Authorize" />,
        description: (
          <FM defaultMessage="This account is not authorized to view the requested Finding for the current Tenant. Please check the URL and try again, or return to view all Findings." />
        ),
      };
    }
    case 404: {
      return {
        title: <FM defaultMessage="Finding Not Found" />,
        description: (
          <FM defaultMessage="We were unable to find the Finding you requested. Please check the URL and try again, or return to view all Findings." />
        ),
      };
    }
    default: {
      return {
        title: <FM defaultMessage="We've Encountered a Problem" />,
        description: (
          <FM defaultMessage="An unknown error has occurred. Please check the URL and try again, or return to view all Findings." />
        ),
      };
    }
  }
};
