import { Alert, AlertTitle, Stack } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  DependencyMetadataReachabilityType,
  V1Ecosystem,
  V1ScoreCategory,
} from '@endorlabs/api_client';
import {
  getLicenseMetricLicenseInfoValues,
  LicenseInfoValues,
} from '@endorlabs/endor-core/Metric';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import {
  IQueryError,
  PackageVersionResource,
  QueryDependenciesResponseObject,
  selectMetricScoresByCategory,
  useQueryAllDependencies,
} from '@endorlabs/queries';
import { UIPackageVersionUtils, useFileDownload } from '@endorlabs/ui-common';
import { WithRequired } from '@endorlabs/utils';
import * as CSV from '@endorlabs/utils/encoding/csv';

import {
  ExportResourceColumn,
  FormExportResource,
  FormExportResourceFieldValues,
} from '../../../../components';
import { DependenciesExportDialogProps } from './types';

type DependencyExportColumn = {
  isDirect?: boolean;
  ecosystem: V1Ecosystem;
  importing: {
    packageVersion?: PackageVersionResource;
    project?: ProjectResource;
  };
  licenseInfo: LicenseInfoValues;
  name: string;
  reachability?: DependencyMetadataReachabilityType;
  tags: string[];
  uuid: string;
  version: string;
  scores: Partial<Record<V1ScoreCategory, number>>;
};

type DependencyExportColumnPaths =
  | keyof DependencyExportColumn
  | `licenseInfo.${keyof LicenseInfoValues}`
  | `importing.packageVersion.meta.name`
  | `importing.packageVersion.uuid`
  | `importing.project.meta.name`
  | `importing.project.uuid`
  | `scores.${V1ScoreCategory}`;

const mapDependenciesResponseToExportColumns = (
  dependencies: QueryDependenciesResponseObject[]
): DependencyExportColumn[] => {
  return dependencies
    .map((dep) => {
      const {
        ecosystem,
        label: dependencyPackageName,
        version: dependencyPackageVersionRef,
      } = UIPackageVersionUtils.parsePackageName(dep.meta.name);
      const { DependencyMetrics, ImportingProject } = dep.meta.references;

      const importingProject = {
        uuid: dep.spec.importer_data.project_uuid,
        ...ImportingProject?.list?.objects[0],
      } as ProjectResource;

      const importingPackageVersion = {
        meta: { name: dep.spec.importer_data.package_version_name },
        uuid: dep.spec.importer_data.package_version_uuid,
      } as PackageVersionResource;

      const licenseMetric = DependencyMetrics?.list?.objects.find(
        (o) => o.meta.name === 'pkg_version_info_for_license'
      );
      const licenseInfo = getLicenseMetricLicenseInfoValues(licenseMetric);

      const scoreMetric = DependencyMetrics?.list?.objects.find(
        (o) => o.meta.name === 'package_version_scorecard'
      );
      const metricScores = selectMetricScoresByCategory(scoreMetric);

      return {
        ecosystem,
        isDirect: dep.spec.dependency_data.direct === true,
        licenseInfo,
        name: dependencyPackageName,
        importing: {
          packageVersion: importingPackageVersion,
          project: importingProject,
        },
        reachability: dep.spec.dependency_data.reachable,
        tags: dep.meta.tags,
        uuid: dep.uuid,
        version: dependencyPackageVersionRef,
        scores: metricScores,
      } satisfies DependencyExportColumn;
    })
    .sort((a, b) => {
      // Sort dependencies before export by:
      // - name, ascending
      // - version, ascending by semver
      return (
        a.name.localeCompare(b.name) ||
        UIPackageVersionUtils.sortBySemanticVersion(a.version, b.version)
      );
    });
};

const DEPENDENCY_EXPORT_COLUMNS = (
  [
    // Dependency Columns
    { isDefault: true, key: 'uuid', label: 'UUID' },
    { key: 'ecosystem', label: 'Ecosystem' },
    { isDefault: true, key: 'name', label: 'Name' },
    { isDefault: true, key: 'version', label: 'Version' },
    { key: 'tags', label: 'Tags' },
    { key: 'reachability', label: 'Reachability' },
    { key: 'isDirect', label: 'Is Direct' },
    // Dependency License Columns
    { isGroupHeader: true, key: 'licenseInfo', label: 'License' },
    {
      key: 'licenseInfo.files',
      label: 'License File',
    },
    {
      key: 'licenseInfo.matchedTexts',
      label: 'License Matched Text',
    },
    {
      isDefault: true,
      key: 'licenseInfo.names',
      label: 'License Name',
    },
    {
      key: 'licenseInfo.types',
      label: 'License Type',
    },
    {
      key: 'licenseInfo.urls',
      label: 'License URL',
    },
    // Dependency Score Columns
    { isGroupHeader: true, key: 'scores', label: 'Endor Scores' },
    {
      key: 'scores.SCORE_CATEGORY_SECURITY',
      label: 'Endor Security Score',
    },
    {
      key: 'scores.SCORE_CATEGORY_ACTIVITY',
      label: 'Endor Activity Score',
    },
    {
      key: 'scores.SCORE_CATEGORY_POPULARITY',
      label: 'Endor Popularity Score',
    },
    {
      key: 'scores.SCORE_CATEGORY_CODE_QUALITY',
      label: 'Endor Quality Score',
    },
    // Importing Columns
    {
      isGroupHeader: true,
      key: 'importing',
      label: 'Importing',
    },
    {
      key: 'importing.project.uuid',
      label: 'Project UUID',
    },
    {
      isDefault: true,
      key: 'importing.project.meta.name',
      label: 'Project Name',
    },
    {
      key: 'importing.packageVersion.uuid',
      label: 'Package Version UUID',
    },
    {
      isDefault: true,
      key: 'importing.packageVersion.meta.name',
      label: 'Package Version Name',
    },
  ] satisfies ExportResourceColumn<DependencyExportColumnPaths>[]
).map((column) => {
  const groupKey = column.key.split('.')[0];
  if (column.key === groupKey) return column;
  return { ...column, groupKey };
});

export const DependenciesExportDialogContent = (
  props: WithRequired<DependenciesExportDialogProps, 'state'>
) => {
  const {
    state: { downloadProps, filter, namespace },
    onClose,
  } = props;

  const isCancelled = useRef(false);
  const [isDownloadingExportData, setIsDownloadingExportData] = useState(false);
  const [exportError, setExportError] = useState<IQueryError | null>(null);

  const qQueryDependencies = useQueryAllDependencies(
    namespace,
    { filter, page_size: 500 },
    // HACK: query is disabled, but manually invoked
    { enabled: false }
  );

  const [_, downloadData] = useFileDownload({
    filetype: 'csv',
    filename: downloadProps?.filename ?? 'dependencies-export.csv',
  });

  const handleExportDependencies = useCallback(
    (values: FormExportResourceFieldValues) => {
      setExportError(null);

      const selectedColumns = values.columns;
      if (!selectedColumns.length) {
        // TODO: handle edge case
        return;
      }

      setIsDownloadingExportData(true);

      // wrap promise with loading/error handling
      qQueryDependencies
        .refetch()
        .then((result) => {
          // Exit without download if cancelled
          if (isCancelled.current) return;

          const dependencies = mapDependenciesResponseToExportColumns(
            result.data ?? []
          );

          // convert to CSV
          const output = CSV.stringify(dependencies, null, {
            headers: selectedColumns,
          });

          downloadData(output);
        })
        .catch((error) => {
          setExportError(error);
        })
        .finally(() => {
          setIsDownloadingExportData(false);
        });
    },
    [downloadData, qQueryDependencies]
  );

  const handleCancel = useCallback(() => {
    isCancelled.current = true;

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Stack spacing={4}>
      <FormExportResource
        columns={DEPENDENCY_EXPORT_COLUMNS}
        enableGrouping
        isLoading={isDownloadingExportData}
        onSubmit={handleExportDependencies}
        onCancel={handleCancel}
      />

      {exportError && (
        <Alert severity="error">
          <AlertTitle>
            <FM defaultMessage="Unable to Export Dependencies" />
          </AlertTitle>

          {exportError.response.data?.message}
        </Alert>
      )}
    </Stack>
  );
};
