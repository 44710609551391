import { FormattedMessage as FM } from 'react-intl';

export const FindingDetailPageTabNames = {
  OVERVIEW: 'overview',
  CALL_PATHS: 'call-paths',
} as const;

export type FindingDetailPageTabName =
  typeof FindingDetailPageTabNames[keyof typeof FindingDetailPageTabNames];

const buildTabRecords = () => {
  return [
    {
      label: <FM defaultMessage="Overview" />,
      to: FindingDetailPageTabNames.OVERVIEW,
      value: FindingDetailPageTabNames.OVERVIEW,
    },
    {
      label: <FM defaultMessage="Call Paths" />,
      to: FindingDetailPageTabNames.CALL_PATHS,
      value: FindingDetailPageTabNames.CALL_PATHS,
    },
  ];
};

export const useFindingDetailPageTabs = ({
  activeTab: targetActiveTab,
}: {
  activeTab: FindingDetailPageTabName;
}) => {
  const tabRecords = buildTabRecords();

  const activeTab =
    tabRecords.find((r) => r.value === targetActiveTab)?.value ??
    FindingDetailPageTabNames.OVERVIEW;

  return { activeTab, tabRecords };
};
