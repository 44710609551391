import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, TabsProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

import { TabRecord, useStyles, useTabs } from '@endorlabs/ui-common';

import { DRAWER_SECTION_PADDING_HORIZ } from './constants';

export interface DetailDrawerTabsProps extends TabsProps {
  activeTabKey?: string;
  id: string;
  tabPanelMap: Record<string, ReactNode>;
  tabRecords: TabRecord[];
}

export const DetailDrawerTabs = ({
  activeTabKey,
  id,
  tabPanelMap,
  tabRecords,
}: DetailDrawerTabsProps) => {
  const sx = useStyles(styles);
  const sxPanelContainer = useStyles(panelContainerStyles);

  const { activeTabValue, getTabsProps } = useTabs({
    activeTabKey,
    id,
    tabRecords,
  });

  return (
    <TabContext value={activeTabValue}>
      <TabList {...getTabsProps()} className="DetailDrawerTabs-root" sx={sx} />

      <Box className="DetailDrawerTabs-panelContainer" sx={sxPanelContainer}>
        {Object.entries(tabPanelMap).map(([tabValue, tabPanelNode]) => {
          return (
            <TabPanel key={tabValue} value={tabValue}>
              {tabPanelNode}
            </TabPanel>
          );
        })}
      </Box>
    </TabContext>
  );
};

function styles({ palette, space, spacing }: Theme) {
  return {
    '&.DetailDrawerTabs-root': {
      borderBottom: `1px solid ${palette.divider}`,
      paddingLeft: DRAWER_SECTION_PADDING_HORIZ,
      paddingRight: DRAWER_SECTION_PADDING_HORIZ,
      width: '100%',
    },

    '& .MuiTabs-flexContainer': {},

    '& .MuiTab-root': {
      flex: '1 1 auto',
      maxWidth: 'max-content',
      minWidth: spacing(16),
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
      width: 'min-content',
    },
  };
}

function panelContainerStyles({ space }: Theme) {
  return {
    '&.DetailDrawerTabs-panelContainer': {
      paddingBottom: space.sm,
      paddingTop: space.sm,
      overflowY: 'auto',
    },
  };
}
