import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { NotificationTargetActionActionType } from '@endorlabs/api_client';

import { SimpleDialog, SimpleDialogProps } from '../../../../components';
import {
  FormUpsertNotificationTarget,
  FormUpsertNotificationTargetProps,
} from '../FormUpsertNotificationTarget';

const messages = defineMessages<NotificationTargetActionActionType>({
  [NotificationTargetActionActionType.Email]: {
    defaultMessage: 'Send Notifications to Email',
  },
  [NotificationTargetActionActionType.Jira]: {
    defaultMessage: 'Send Notifications to Jira',
  },
  [NotificationTargetActionActionType.Vanta]: {
    defaultMessage: 'Send Notifications to Vanta',
  },
  [NotificationTargetActionActionType.Webhook]: {
    defaultMessage: 'Send Notifications to Webhook',
  },
  [NotificationTargetActionActionType.Slack]: {
    defaultMessage: 'Send Notifications to Slack',
  },
  [NotificationTargetActionActionType.GithubPr]: {
    defaultMessage: 'Enable GitHub PRs for Remediations',
  },
  [NotificationTargetActionActionType.Unspecified]: {
    defaultMessage: 'Send Notifications',
  },
});

export interface NotificationTargetUpsertDialogProps
  extends Pick<SimpleDialogProps, 'open' | 'onClose'>,
  FormUpsertNotificationTargetProps { }

/**
 * Dialog Wrapper around Notification Target Upsert Form
 */
export const NotificationTargetUpsertDialog = ({
  open,
  onClose,
  ...props
}: NotificationTargetUpsertDialogProps) => {
  const titleMessage =
    props.notificationTargetActionType in messages
      ? messages[props.notificationTargetActionType]
      : messages[NotificationTargetActionActionType.Unspecified];

  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      titleText={<FM {...titleMessage} />}
    >
      <FormUpsertNotificationTarget {...props} />
    </SimpleDialog>
  );
};
