import IconClose from '@mui/icons-material/Close';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import produce from 'immer';
import { isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecEnabledFeatureType, V1Installation } from '@endorlabs/api_client';
import { getEnabledFeaturesFromLicenses } from '@endorlabs/endor-core/EndorLicense';
import { useUpdateInstallation } from '@endorlabs/queries';
import { useListEndorLicenses } from '@endorlabs/queries/licenses';
import {
  ButtonPrimary,
  ButtonSecondary,
  useAppNotify,
} from '@endorlabs/ui-common';

import { LicenseSelectComponent } from '../../domains/Licenses/components';
import { useAuthInfo } from '../../providers';
import { InstallationListItem } from './types';

interface EditEnableFeatureDialogProps {
  open: boolean;
  onClose: () => void;
  installation: InstallationListItem;
  installationObject: V1Installation;
  updateInstallation: () => void;
}

export default function EditEnableFeatureDialog({
  open,
  onClose,
  installation,
  installationObject,
  updateInstallation,
}: EditEnableFeatureDialogProps) {
  const { activeNamespace: tenantName } = useAuthInfo();
  const { palette, typography } = useTheme();

  const [editedFeatures, setEditedFeatures] = useState<
    SpecEnabledFeatureType[]
  >([]);
  const [currentFeatures, setCurrentFeatures] = useState<
    SpecEnabledFeatureType[]
  >([]);

  const addAppNotification = useAppNotify();

  /* Handle the race condition of installation being updated after useState of enabledFeatures is set on modal open.
   * This is feature list which tenants enabled to be used for scanning. It need not be all features purchased in the bundle  */
  useEffect(() => {
    if (installation?.enabledFeatures) {
      setCurrentFeatures(installation.enabledFeatures);
      setEditedFeatures(installation.enabledFeatures);
    }
  }, [installation]);

  const qLicensesForTenant = useListEndorLicenses(
    { namespace: tenantName! },
    {
      enabled: open && !!tenantName && !_isEmpty(installation),
    }
  );

  /* Get list of installations available for tenant from the bundle info.
   * currentFeatures is subset of featuresListwithLicense */
  const featuresListwithLicense: SpecEnabledFeatureType[] = useMemo(() => {
    if (!qLicensesForTenant.data?.list?.objects) return [];
    return getEnabledFeaturesFromLicenses(qLicensesForTenant.data.list.objects);
  }, [qLicensesForTenant]);

  const handleResetIntegration = () => {
    setEditedFeatures(currentFeatures);
  };

  const qUpdateInstallation = useUpdateInstallation({
    onError: () => {
      addAppNotification({
        id: 'installation:enabledfeature:error',
        message: (
          <FM defaultMessage="Failed to update enabled features. Please try again." />
        ),
        severity: 'error',
      });
    },
    onSuccess: () => {
      addAppNotification({
        id: 'installation:enabledfeature:success',
        message: (
          <FM defaultMessage="Successfully updated enabled features. " />
        ),
        severity: 'success',
      });
      updateInstallation();
      onClose();
    },
  });

  const handleEditIntegration = () => {
    // TODO: handle possible edge case
    if (!installationObject) return;

    const updatedInstallation = produce(installationObject, (draft) => {
      // unset the scan state to trigger a rescan
      draft.spec = {
        ...draft.spec,
        enabled_features: editedFeatures,
      };
    });

    qUpdateInstallation.mutate({
      namespace: tenantName,
      resource: updatedInstallation,
      mask: 'spec.enabled_features',
    });
  };

  const handleCheckboxChange = (featureName: SpecEnabledFeatureType) => {
    setEditedFeatures((prevFeatures) =>
      prevFeatures.includes(featureName)
        ? prevFeatures.filter((f) => f !== featureName)
        : [...prevFeatures, featureName]
    );
  };

  const handleCheckboxAll = () => {
    setEditedFeatures((prevFeatures) =>
      prevFeatures.length > 0 ? [] : featuresListwithLicense
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="span" id="preview-modal-title" variant="h1">
            <FM defaultMessage={'Integration Settings'} />
          </Typography>

          <IconButton onClick={onClose} sx={{ marginRight: -2 }}>
            <IconClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Typography variant="h3">
            <FM defaultMessage="Enabled Scanners" />
          </Typography>
          <Typography variant="body1" color={palette.text.secondary}>
            <FM defaultMessage="Select the scan types to enable. Results for these scan types will be generated once your next scan is initiated." />
          </Typography>
          <Box
            sx={{
              border: `1px solid ${palette.status.pending}`,
              borderRadius: '4px',
              '& .MuiPaper-root': {
                width: '100%',
                boxSizing: 'border-box',
              },
            }}
          >
            <LicenseSelectComponent
              handleCheckboxAll={handleCheckboxAll}
              handleCheckboxChange={handleCheckboxChange}
              editedFeatures={editedFeatures}
              featuresListwithLicense={featuresListwithLicense}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonPrimary onClick={handleEditIntegration} sx={{ mr: 2 }}>
          <Typography>
            <FM defaultMessage={'Save'} />
          </Typography>
        </ButtonPrimary>
        <ButtonSecondary
          onClick={handleResetIntegration}
          disabled={_isEqual(currentFeatures, editedFeatures)}
          sx={{ mr: 2 }}
        >
          <Typography>
            <FM defaultMessage={'Reset'} />
          </Typography>
        </ButtonSecondary>
        <ButtonSecondary onClick={onClose}>
          <Typography>
            <FM defaultMessage={'Cancel'} />
          </Typography>
        </ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
