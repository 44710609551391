import { Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { useCountSemgrepRules, useListSemgrepRules } from '@endorlabs/queries';
import {
  ButtonPrimary,
  CommonDataTableRowActions,
  DataTableView,
  useConfirmationDialog,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { FilterBar, useFilterContext } from '../../../domains/filters';
import { useAuthInfo } from '../../../providers';
import { SAST_FILTER_FIELDS } from '../constants/filters';
import { useSASTRulesRules } from '../hooks/useSASTRules';
import { buildSASTRulesTableColumnDefs, SASTRulesTableRow } from './SASTRules';
import { SASTRulesImportDialog } from './SASTRules/SASTRulesImportDialog';

export const SASTRulesView = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const { formatMessage: fm } = useIntl();

  const { deleteRule } = useSASTRulesRules(tenantName);

  const [isSASTRulesDialogOpen, setIsSASTRulesDialogOpen] = useState(false);

  const { filter: searchFilterExpression } = useFilterContext();
  const qCountSASTRules = useCountSemgrepRules(
    tenantName,
    { filter: searchFilterExpression },
    { enabled: !!tenantName }
  );

  const paginator = useDataTablePaginator({
    totalCount: qCountSASTRules.data?.count,
  });

  const qSASTRules = useListSemgrepRules(
    tenantName,
    {
      ...paginator.getListParameters(),
      sort: { path: 'meta.name' },
      filter: searchFilterExpression,
    },
    { enabled: !!tenantName }
  );

  const SASTRules: SASTRulesTableRow[] = useMemo(() => {
    const rulesData = qSASTRules?.data?.objects ?? [];
    return rulesData.map((ruleObj) => {
      return {
        enabled: true,
        ruleName: ruleObj.meta.name,
        definedBy: 'Endor Labs',
        owasp: ruleObj.spec.rule?.metadata?.owasp?.join(', ') ?? '',
        cwe: ruleObj.spec.rule?.metadata?.cwe?.join(', ') ?? '',
        uuid: ruleObj.uuid,
        ruleTags: ruleObj.meta.tags,
      } as SASTRulesTableRow;
    });
  }, [qSASTRules?.data]);

  const emptyStateProps = {
    title: <FM defaultMessage="There are no SAST rules in this project" />,
    children: (
      <Typography>
        <FM defaultMessage="As new rules are created, they will appear here." />
      </Typography>
    ),
  };

  const SASTRuleDeleteConfirmation = useConfirmationDialog<{
    uuid: string;
  }>({
    confirmText: <FM defaultMessage="Delete" />,
    isDestructive: true,
    onConfirm: (props) => deleteRule(props?.uuid || ''),
    titleText: <FM defaultMessage="Delete this SAST Rule?" />,
    descriptionText: (
      <FM defaultMessage="This SAST Rule and related resources will be deleted." />
    ),
  });

  const handleDelete = ({ uuid }: SASTRulesTableRow) => {
    SASTRuleDeleteConfirmation.openDialog({ uuid });
  };

  const rowActions = [
    { ...CommonDataTableRowActions.DELETE_RESOURCE, onClick: handleDelete },
  ];

  const isLoading = qSASTRules?.isLoading || qCountSASTRules?.isLoading;
  return (
    <>
      <DataTableView
        bulkActions={[]}
        filtersContent={
          <FilterBar
            fields={SAST_FILTER_FIELDS}
            isCard={false}
            searchPlaceholder={fm({
              defaultMessage: 'Search by Rule ID/Name, CWE, OWASP or Tag Name',
            })}
          />
        }
        columns={buildSASTRulesTableColumnDefs({
          excludeColumns: [],
        })}
        countMessage={
          <FM
            defaultMessage="{count} Rules"
            values={{ count: qCountSASTRules?.data?.count ?? 0 }}
          />
        }
        data={SASTRules}
        emptyStateProps={emptyStateProps}
        isLoading={isLoading}
        namespace={tenantName}
        paginator={paginator}
        rowActions={rowActions}
      />
      <SASTRulesImportDialog
        enableUpload
        namespace={tenantName}
        open={isSASTRulesDialogOpen}
        onClose={() => {
          setIsSASTRulesDialogOpen(false);
        }}
      />
      <SASTRuleDeleteConfirmation.Dialog
        {...SASTRuleDeleteConfirmation.dialogProps}
      />
    </>
  );
};
