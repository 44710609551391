import { Grid, useTheme } from '@mui/material';
import { Outlet } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';
import {
  ImgIconLogoMarkAzure,
  ImgIconLogoMarkGitHub,
  ImgIconLogoMarkGitLab,
  NavigationTabs,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../../components';

const TabNames = {
  GITHUB: 'github',
  GITLAB: 'gitlab',
  AZURE: 'azure'
};

const BaseTabRecords = [
  {
    Icon: ImgIconLogoMarkGitHub,
    label: <FM defaultMessage="GitHub" />,
    to: TabNames.GITHUB,
    value: TabNames.GITHUB,
  },
  {
    Icon: ImgIconLogoMarkGitLab,
    label: <FM defaultMessage="GitLab" />,
    to: TabNames.GITLAB,
    value: TabNames.GITLAB,
  },
];

export const NewProjectPage = () => {
  const { space } = useTheme();
  const { ENABLE_AZURE_INTEGRATION: isAzureIntegrationEnabled } =
    useFeatureFlags();

  /* Shallow copy to ensure immutability on `BaseTabRecords`. */
  const TabRecords = [...BaseTabRecords];

  // Condition to include the Azure DevOps tab
  if (isAzureIntegrationEnabled) {
    TabRecords.push({
      Icon: ImgIconLogoMarkAzure,
      label: <FM defaultMessage="Azure Devops" />,
      to: TabNames.AZURE,
      value: TabNames.AZURE,
    });
  }

  return (
    <Grid container direction="column" spacing={space.md}>
      <Grid item>
        <PageHeader title={<FM defaultMessage="Scan Your Repositories" />} />
      </Grid>

      <Grid item>
        <NavigationTabs tabs={TabRecords} />
      </Grid>

      <Grid item>
        <Outlet />
      </Grid>
    </Grid>
  );
};
