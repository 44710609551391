import { Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1CiCdTool } from '@endorlabs/api_client';
import { RepositoryResource, RepoVersionResource } from '@endorlabs/queries';

import { ToolEvidenceAccordion } from '../../domains/CiCdTools';

export const ProjectToolDetailDrawerToolEvidence = ({
  tools,
  repository,
  repositoryVersion,
}: {
  tools: V1CiCdTool[];
  repository?: RepositoryResource;
  repositoryVersion?: RepoVersionResource;
}) => {
  const theme = useTheme();
  const totalEvidenceCount = tools.reduce((count, tool) => {
    return count + (tool.evidence?.length ?? 0);
  }, 0);

  const [allExpanded, setAllExpanded] = useState<boolean>(true);

  const repositoryUrl =
    repository && repository?.spec.http_clone_url.replace(/\.git$/, '');

  const repositorySha = repositoryVersion?.spec.version?.sha;

  return (
    <Stack gap={theme.space.sm} paddingTop={theme.space.lg}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2">
          <FM
            defaultMessage="Tools Evidence Items {count}"
            values={{ count: totalEvidenceCount }}
          />
        </Typography>
        <Stack direction="row" gap={theme.space.xs}>
          <Button
            onClick={() => setAllExpanded(true)}
            variant="text"
            sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
          >
            <Typography variant="caption">
              <FM defaultMessage="Expand All" />
            </Typography>
          </Button>
          <Divider orientation="vertical" />
          <Button
            onClick={() => setAllExpanded(false)}
            variant="text"
            sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
          >
            <Typography variant="caption">
              <FM defaultMessage="Collapse All" />
            </Typography>
          </Button>
        </Stack>
      </Stack>

      {tools.map((tool) => (
        <ToolEvidenceAccordion
          key={tool.name}
          isExpanded={allExpanded}
          tool={tool}
          repositoryUrl={repositoryUrl}
          repositorySha={repositorySha}
        />
      ))}
    </Stack>
  );
};
