import { Box, Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { orderBy as _orderBy } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  ContextContextType,
  SpecEndorLicenseFeatureType,
} from '@endorlabs/api_client';
import { getLicenseMetricLicenseInfoValues } from '@endorlabs/endor-core/Metric';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  selectMetricScoresByCategory,
  tryParseGroupResponseAggregationKey,
  useCountUniqueDependencyMetadata,
  useGroupDependencyMetadata,
  useQueryDependencies,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  ButtonLinkPrimary,
  ButtonPrimary,
  EmptyState,
  getPaginatorPageSlice,
  IconConstellation,
  UIPackageVersionUtils,
  useDataTablePaginator,
  useDialog,
} from '@endorlabs/ui-common';

import {
  FilterBuilder,
  PageHeader,
  PageHeaderCount,
  useFilterBuilder,
} from '../../components';
import { FIVE_MINUTES_IN_MILLISECONDS } from '../../constants';
import {
  DependenciesExportDialog,
  DependenciesTable,
  DependenciesTableColumnId,
  DependenciesTableRow,
} from '../../domains/Dependencies';
import { useAuthInfo } from '../../providers';
import { useLicensingInfo } from '../../providers/AuthInfo/useLicensingInfo';
import { getDependencyPath, getProjectPath } from '../../routes';

const DEPENDENCY_TABLE_INCLUDE_COLUMNS: DependenciesTableColumnId[] = [
  'dependentsCount',
  'licenseNames',
  'namespace',
  'versionRef',
];

export const DependenciesIndexPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();

  const { getFilterBuilderProps, filterExpressionMap, clearFilters } =
    useFilterBuilder({
      include: ['DependencyMetadata'],
    });

  const { checkLicensePresent } = useLicensingInfo();

  const isSBOMLicensePresent = useMemo(() => {
    return checkLicensePresent(SpecEndorLicenseFeatureType.SbomIngestion);
  }, [checkLicensePresent]);

  const contextTypeWithSBOM = [
    ContextContextType.Main,
    ContextContextType.Sbom,
  ];

  const baseDependenciesFilterExpression = isSBOMLicensePresent
    ? `context.type in [${contextTypeWithSBOM}]`
    : filterExpressionBuilders.mainResourceContext();

  const qCountUniqueDependencies = useCountUniqueDependencyMetadata(
    tenantName,
    {
      staleTime: Infinity,
    },
    {
      filter: baseDependenciesFilterExpression,
    }
  );

  const dependenciesFilters = [baseDependenciesFilterExpression];
  if (filterExpressionMap.has('DependencyMetadata')) {
    dependenciesFilters.push(
      `(${filterExpressionMap.get('DependencyMetadata')})`
    );
  }

  const qGroupedDependencies = useGroupDependencyMetadata(
    tenantName,
    {
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
      retry: false,
    },
    {
      filter: dependenciesFilters.join(' and '),
      group: {
        aggregation_paths: 'meta.name',
        show_aggregation_uuids: true,
        unique_value_paths: ['tenant_meta.namespace'].join(','),
      },
    }
  );

  // get the uuid, name, and count for the dependency package versions from dep meta
  const dependencyMetadata = useMemo(() => {
    const dependencyMetadata = Object.entries(
      qGroupedDependencies.data?.groups ?? {}
    )
      .map(([key, group]) => {
        // get the aggregate count (# of package versions dependent on this package)
        const count = group.aggregation_count?.count as number;

        // extract the dependency package version name and version ref from the aggregation key
        const aggregation = tryParseGroupResponseAggregationKey(key);
        const name = (aggregation.find((a) => a.key === 'meta.name')?.value ??
          '') as string;
        const namespace = group.unique_values?.[
          'tenant_meta.namespace'
        ]?.[0] as unknown as string;

        const {
          ecosystem,
          label: packageName,
          version: versionRef,
        } = UIPackageVersionUtils.parsePackageName(name);

        // get the first dependency uuid for use for the link to the detail page
        const uuid = group.aggregation_uuids?.[0] as unknown as string;

        return {
          uuid,
          ecosystem,
          name,
          namespace,
          packageName,
          count,
          versionRef,
        };
      })
      .filter((d) => d.name && d.namespace && d.uuid);

    // sort the initial list of dependencies by # of dependents, descending
    // with a secondary sort by the package name, ascending
    return _orderBy(
      dependencyMetadata,
      ['count', 'packageName'],
      ['desc', 'asc']
    );
  }, [qGroupedDependencies.data]);

  const paginator = useDataTablePaginator({
    totalCount: dependencyMetadata.length,
  });

  const dependencyMetadataPage = useMemo(
    () => getPaginatorPageSlice(paginator.state, dependencyMetadata),
    [dependencyMetadata, paginator.state]
  );

  const qDependencies = useQueryDependencies(
    tenantName,
    {
      page_size: dependencyMetadataPage.length,
      filter: [
        baseDependenciesFilterExpression,
        `uuid in ["${dependencyMetadataPage.map((d) => d.uuid).join('","')}"]`,
      ].join(' and '),
    },
    {
      enabled: qGroupedDependencies.isSuccess && dependencyMetadata.length > 0,
      retry: false,
    }
  );

  // map to dependencies table data
  const dependencies = useMemo(() => {
    const dependenciesByUuid = new Map(
      qDependencies.data?.list?.objects.map((o) => [o.uuid, o])
    );

    return dependencyMetadataPage.map((dep) => {
      const detail = dependenciesByUuid.get(dep.uuid);

      const scoreMetric =
        detail?.meta.references.DependencyMetrics?.list?.objects.find(
          (o) => o.meta.name === 'package_version_scorecard'
        );
      const metricScores = selectMetricScoresByCategory(scoreMetric);

      const licenseMetric =
        detail?.meta.references.DependencyMetrics?.list?.objects.find(
          (o) => o.meta.name === 'pkg_version_info_for_license'
        );
      const licenseInfo = getLicenseMetricLicenseInfoValues(licenseMetric);

      return {
        ecosystem: dep.ecosystem,
        name: dep.name,
        namespace: dep.namespace,
        versionRef: dep.versionRef,
        link: getDependencyPath({
          tenantName: dep.namespace as string,
          uuid: dep.uuid,
        }),
        dependentsCount: dep.count,
        licenseNames: licenseInfo.names,
        ...metricScores,
      } satisfies DependenciesTableRow;
    });
  }, [dependencyMetadataPage, qDependencies.data]);

  const dependenciesExportDialog = useDialog({
    component: DependenciesExportDialog,
  });
  const handleOpenExportDialog = () => {
    dependenciesExportDialog.openDialog({
      namespace: tenantName,
      filter: dependenciesFilters.join(' and '),
      downloadProps: {
        filename: `tenant_${tenantName}_dependencies-export.csv`,
      },
    });
  };

  // get calculated state for page
  // NOTE: show loading as false after the initial dependency information is loaded
  const isLoading =
    qCountUniqueDependencies.isLoading || qGroupedDependencies.isLoading;
  const hasError = !!qGroupedDependencies.error || !!qDependencies.error;
  const isEmptyState =
    !isLoading && !hasError && qCountUniqueDependencies.data?.count === 0;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          Icon={IconConstellation}
          title={<FM defaultMessage="All Dependencies" />}
          titleDetails={
            // hide count when `0`
            qCountUniqueDependencies.data &&
            qCountUniqueDependencies.data?.count !== 0 && (
              <PageHeaderCount value={qCountUniqueDependencies.data.count} />
            )
          }
        />
      </Grid>

      {!isEmptyState && (
        <Grid item>
          <FilterBuilder {...getFilterBuilderProps()} />
        </Grid>
      )}

      {!isEmptyState && !hasError && (
        <Grid item>
          <Card>
            <CardHeader
              disableTypography
              title={
                <Stack alignItems="center" direction="row" spacing={2}>
                  {/* Force download to the end of the header */}
                  <Box flexGrow={1}>&nbsp;</Box>

                  <ButtonPrimary
                    disabled={isLoading}
                    onClick={handleOpenExportDialog}
                  >
                    <FM defaultMessage="Export Dependencies" />
                  </ButtonPrimary>
                </Stack>
              }
            />

            <CardContent>
              <DependenciesTable
                data={dependencies}
                enablePagination
                includeColumns={DEPENDENCY_TABLE_INCLUDE_COLUMNS}
                isLoading={isLoading}
                isLoadingRelated={qDependencies.isLoading}
                paginator={paginator}
                emptyStateProps={{
                  title: (
                    <FM defaultMessage="No Dependencies match the filter criteria" />
                  ),
                  children: (
                    <ButtonCancel onClick={clearFilters}>
                      <FM defaultMessage="Clear Filters" />
                    </ButtonCancel>
                  ),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      )}

      {/* Handle Error States */}
      {hasError && (
        <Grid item>
          <EmptyState
            title={<FM defaultMessage="Failed to load Dependencies" />}
            description={
              <FM defaultMessage="The request to load the Dependencies failed to complete. Please remove filters or try again." />
            }
          >
            {filterExpressionMap.has('DependencyMetadata') ? (
              <ButtonPrimary onClick={clearFilters}>
                <FM defaultMessage="Clear Filters" />
              </ButtonPrimary>
            ) : (
              <ButtonPrimary onClick={() => qGroupedDependencies.refetch()}>
                <FM defaultMessage="Try Again" />
              </ButtonPrimary>
            )}
          </EmptyState>
        </Grid>
      )}

      {/* Handle Empty State */}
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="Add some projects to see their dependencies here" />
            }
            description={
              <FM defaultMessage="As your inventory of dependencies grows, this is where you can easily search across them." />
            }
          >
            <ButtonLinkPrimary
              linkProps={{ to: getProjectPath({ tenantName, uuid: 'new' }) }}
            >
              <FM defaultMessage="Add Project" />
            </ButtonLinkPrimary>
          </EmptyState>
        </Grid>
      )}

      <dependenciesExportDialog.Dialog
        {...dependenciesExportDialog.dialogProps}
      />
    </Grid>
  );
};
