import {
  Box,
  Button,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  TextField,
} from '@mui/material';
import { matchSorter } from 'match-sorter';
import {
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { FilterExpression } from '@endorlabs/filters';
import { IconFilter, IconUploadCloud, IconX } from '@endorlabs/ui-common';

import { SavedFilter } from '../types';

export type FilterBarSavedFiltersProps = {
  filter?: FilterExpression;
  onCreateSavedFilter: (newSavedFilter: Omit<SavedFilter, 'id'>) => void;
  onSavedFilterSelect: (value: SavedFilter) => void;
  savedFilters?: SavedFilter[];
};

export const FilterBarSavedFilters = ({
  filter,
  onCreateSavedFilter,
  onSavedFilterSelect,
  savedFilters = [],
}: FilterBarSavedFiltersProps) => {
  const { formatMessage: fm } = useIntl();

  const [selected, setSelected] = useState<SavedFilter | null>(null);
  const [inputValue, setInputValue] = useState('');
  const anchorRef = useRef() as MutableRefObject<HTMLDivElement>;
  const menuRef = useRef() as MutableRefObject<HTMLUListElement>;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Effects
  useEffect(() => {
    if (!filter) return;
    const existing = savedFilters.find((f) => f.filter === filter);
    if (existing) {
      setSelected(existing);
      setInputValue(existing.name);
    } else {
      setSelected(null);
      setInputValue('');
    }
  }, [filter, savedFilters]);

  // Handlers
  const handleInputClick = (event: React.SyntheticEvent) => {
    // if there is no value in the input field, open the popover
    if (!inputValue) {
      handlePopoverOpen(event);
      return;
    }

    // else: focus and select the input value
    const inputEl = anchorRef.current.querySelector('input');
    if (inputEl) {
      inputEl.focus();
      inputEl.select();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputClear = (event: SyntheticEvent) => {
    event.stopPropagation();
    setInputValue('');
  };

  const handleInputKeyup = (event: React.KeyboardEvent) => {
    if (event.code === 'ArrowDown') {
      // open the dropdown, or move focus on arrow down
      if (!isOpen) {
        handlePopoverOpen(event);
        return;
      }

      const focusTarget = menuRef.current.children.item(0);
      if (
        focusTarget &&
        'focus' in focusTarget &&
        'function' === typeof focusTarget.focus
      ) {
        focusTarget.focus();
      }
    } else if (event.code === 'Escape') {
      // reset the input on escape
      if (isOpen) {
        handlePopoverClose(event);
      } else {
        setInputValue('');
      }
    }
  };

  const handlePopoverClose = (_: React.SyntheticEvent, reason?: unknown) => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = (_: React.SyntheticEvent) => {
    setAnchorEl(anchorRef.current);
  };

  const handleSavedFilterSave = (event: SyntheticEvent) => {
    event.stopPropagation();

    const name = inputValue.trim();
    if (!filter) return;
    if (!name) return;

    onCreateSavedFilter({ name, filter });
    handlePopoverClose(event);
  };

  const handleSavedFilterSelect = (
    event: SyntheticEvent,
    value: SavedFilter
  ) => {
    setSelected(value);
    setInputValue(value.name);
    onSavedFilterSelect(value);

    handlePopoverClose(event);
  };

  // Derived state
  const visibleSavedFilters = useMemo(() => {
    const searchValue = inputValue.trim();
    if (!searchValue) return savedFilters;

    const matchKeys: (keyof SavedFilter)[] = ['name', 'tags'];
    return matchSorter(savedFilters, searchValue, {
      // use the default sort order as tie breaker
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
      keys: matchKeys,
    });
  }, [inputValue, savedFilters]);

  const canSaveFilter = useMemo(() => {
    const name = inputValue.trim();
    if (!filter || !name) return false;
    if (selected) return name !== selected.name;
    return true;
  }, [filter, inputValue, selected]);

  const isEmptyState = savedFilters.length === 0;
  const isOpen = Boolean(anchorEl);

  return (
    <Box
      sx={{
        // minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <TextField
        data-testid="FilterBarSavedFilters"
        placeholder={fm({
          defaultMessage: 'Saved Filters',
        })}
        ref={anchorRef}
        value={inputValue}
        onChange={handleInputChange}
        onKeyUp={handleInputKeyup}
        onClick={handleInputClick}
        InputProps={{
          endAdornment: (
            <Stack direction="row" spacing={1}>
              {inputValue && (
                <IconButton
                  onClick={handleInputClear}
                  aria-label={fm({ defaultMessage: 'Clear filter name' })}
                >
                  <IconX />
                </IconButton>
              )}

              <IconButton
                onClick={handleSavedFilterSave}
                disabled={!canSaveFilter}
                aria-label={fm({ defaultMessage: 'Save New filter' })}
              >
                <IconUploadCloud />
              </IconButton>
            </Stack>
          ),
          startAdornment: <IconFilter sx={{ marginRight: 1 }} />,
        }}
      />

      <Popover
        id={isOpen ? 'saved-filters-popover' : undefined}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableAutoFocus
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList
          sx={{
            minWidth: 300,
            maxHeight: 400,
            overflowY: 'auto',
            padding: 0,
            '& li:first-of-type': {
              marginTop: 2,
            },
          }}
          ref={menuRef}
        >
          {visibleSavedFilters.map((f) => (
            <MenuItem
              key={f.id}
              onClick={(event) => handleSavedFilterSelect(event, f)}
              selected={selected?.id === f.id}
            >
              <ListItemText>{f.name}</ListItemText>
            </MenuItem>
          ))}

          {isEmptyState && (
            <MenuItem disabled>
              <ListItemText>
                <FM defaultMessage="No saved filters exist" />
              </ListItemText>
            </MenuItem>
          )}
        </MenuList>

        <Button
          disabled={!canSaveFilter}
          onClick={handleSavedFilterSave}
          sx={(t) => ({
            borderTop: `1px solid ${t.palette.divider}`,
            borderRadius: 0,
            width: '100%',
            padding: t.spacing(2, 4),
            justifyContent: 'start',
          })}
        >
          <FM defaultMessage="Save New Filter" />
        </Button>
      </Popover>
    </Box>
  );
};
