import { Grid, Stack, useTheme } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage as FM } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';
import {
  EmptyState,
  FullHeightCard,
  IconBarChart,
  ResponsiveCardLayout,
  TitleActionHeader,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { useAuthInfo } from '../../providers';
import { CategoricalBarChartVis } from './CategoricalBarChartVis';
import { DashboardIndexPageV2 } from './DashboardIndexPageV2';
import { DependencyImportsVis } from './DependencyImportsVis';
import { ResourceStatsVis } from './ResourceStatsVis';
import { VulnerabilityStatsVis } from './VulnerabilityStatsVis';

const visHeight = 330;

export const DashboardIndexPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const { space } = useTheme();

  // When feature flag is enabled, render the V2 Dashboard
  const isDashboardV2Enabled = useFeatureFlags((f) => f.ENABLE_DASHBOARD_V2);
  if (isDashboardV2Enabled) {
    return <DashboardIndexPageV2 />;
  }

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          Icon={IconBarChart}
          title={<FM defaultMessage="Organization Dashboard" />}
        />
      </Grid>

      <Grid item>
        <Stack spacing={space.lg}>
          <Grid
            container
            direction="column"
            flexWrap="nowrap"
            spacing={space.xs}
          >
            <Grid item>
              <TitleActionHeader
                title={<FM defaultMessage="Inventory & Risk" />}
                variant="h2"
              />
            </Grid>

            <Grid item>
              <ResponsiveCardLayout>
                <FullHeightCard>
                  <ResourceStatsVis />
                </FullHeightCard>

                <FullHeightCard>
                  <VulnerabilityStatsVis />
                </FullHeightCard>
              </ResponsiveCardLayout>
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={space.xs}>
            <Grid item>
              <ResponsiveCardLayout>
                <FullHeightCard>
                  <ErrorBoundary FallbackComponent={DashboardCardFallback}>
                    <CategoricalBarChartVis
                      chartId="TopProjects-1"
                      height={visHeight}
                      namespace={tenantName}
                      title={<FM defaultMessage="Top Projects By" />}
                    />
                  </ErrorBoundary>
                </FullHeightCard>

                <FullHeightCard>
                  <ErrorBoundary FallbackComponent={DashboardCardFallback}>
                    <CategoricalBarChartVis
                      chartId="TopProjects-2"
                      height={visHeight}
                      namespace={tenantName}
                      title={<FM defaultMessage="Top Projects By" />}
                    />
                  </ErrorBoundary>
                </FullHeightCard>
              </ResponsiveCardLayout>
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={space.xs}>
            <Grid item>
              <ResponsiveCardLayout>
                <FullHeightCard>
                  <ErrorBoundary FallbackComponent={DashboardCardFallback}>
                    <CategoricalBarChartVis
                      chartId="TopPackages-1"
                      height={visHeight}
                      namespace={tenantName}
                      title={<FM defaultMessage="Top Packages By" />}
                    />
                  </ErrorBoundary>
                </FullHeightCard>

                <FullHeightCard>
                  <ErrorBoundary FallbackComponent={DashboardCardFallback}>
                    <CategoricalBarChartVis
                      chartId="TopPackages-2"
                      height={visHeight}
                      namespace={tenantName}
                      title={<FM defaultMessage="Top Packages By" />}
                    />
                  </ErrorBoundary>
                </FullHeightCard>
              </ResponsiveCardLayout>
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={space.xs}>
            <Grid item>
              <ResponsiveCardLayout>
                <FullHeightCard>
                  <ErrorBoundary FallbackComponent={DashboardCardFallback}>
                    <CategoricalBarChartVis
                      chartId="TopDependencies-1"
                      height={visHeight}
                      namespace={tenantName}
                      title={<FM defaultMessage="Top Dependencies By" />}
                    />
                  </ErrorBoundary>
                </FullHeightCard>

                <FullHeightCard>
                  <ErrorBoundary FallbackComponent={DashboardCardFallback}>
                    <DependencyImportsVis height={visHeight} />
                  </ErrorBoundary>
                </FullHeightCard>
              </ResponsiveCardLayout>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

const DashboardCardFallback = () => (
  <EmptyState
    title={<FM defaultMessage="We've Encountered a Problem" />}
    description={
      <FM defaultMessage="Unable to display the content for this section." />
    }
  />
);
