import {
  QueryFindingsResponseObject,
  useFeatureFlags,
} from '@endorlabs/queries';

import { useInfoDrawer } from '../../../components/InfoDrawer';
import { FindingDetailDrawer } from '../components/FindingDetailDrawer';
import {
  FindingDetailDrawerV2,
  FindingDetailDrawerV2Props,
} from '../components/FindingDetailDrawer/FindingDetailDrawerV2';

export interface FindingDetailDrawerParams {
  findingNamespace: string;
  findingUuid: string;
}

export const useFindingDetailDrawer = () => {
  const { ENABLE_IA_1_PAGE_IMPROVEMENTS } = useFeatureFlags();

  const DetailDrawer = useInfoDrawer<
    FindingDetailDrawerParams,
    FindingDetailDrawerV2Props
  >({
    drawerParams: ['findingNamespace', 'findingUuid'],
    Component: ENABLE_IA_1_PAGE_IMPROVEMENTS
      ? FindingDetailDrawerV2
      : FindingDetailDrawer,
  });

  const permalinkEffect = (findings: QueryFindingsResponseObject[]) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();

    if (
      ENABLE_IA_1_PAGE_IMPROVEMENTS &&
      activeDrawerParams?.findingNamespace &&
      activeDrawerParams?.findingUuid &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      const { findingNamespace, findingUuid } = activeDrawerParams;
      DetailDrawer.activate(
        { findingNamespace, findingUuid },
        { findingUuid, namespace: findingNamespace }
      );
      return;
    }

    const activeFinding = activeDrawerParams
      ? findings.find((p) => p.uuid === activeDrawerParams.findingUuid)
      : undefined;

    if (
      activeFinding &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      DetailDrawer.activate(
        {
          findingUuid: activeFinding.uuid,
          findingNamespace: activeFinding.tenant_meta.namespace,
        },
        {
          finding: activeFinding,
          findingUuid: activeFinding.uuid,
          namespace: activeFinding.tenant_meta.namespace,
        }
      );
    }
  };

  return { DetailDrawer, permalinkEffect };
};
