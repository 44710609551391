import { VersionUpgradeResource } from '@endorlabs/endor-core/VersionUpgrade';

import { RemediationsTableRow } from '../components/RemediationsTable';
import { getFixedFindingCountsBySeverity } from './getFixedFindingsBySeverity';

export const mapToRemediationsTableRow = (
  versionUpgrades: VersionUpgradeResource[]
): RemediationsTableRow[] => {
  return versionUpgrades.map((versionUpgrade) => {
    const upgrade = versionUpgrade.spec?.upgrade_info;

    const findingCounts = getFixedFindingCountsBySeverity(upgrade);

    return {
      dependencyName: upgrade?.direct_dependency_package,
      findingCounts,
      fromVersion: upgrade?.from_version,
      isBest: upgrade?.is_best,
      isLatest: upgrade?.is_latest,
      packageVersionName: upgrade?.root_package_version,
      projectName: upgrade?.project,
      remediationRisk: upgrade?.upgrade_risk,
      toVersion: upgrade?.to_version,
      versionAge: upgrade?.from_version_publish_time,
      uuid: versionUpgrade.uuid,
    } as RemediationsTableRow;
  });
};
