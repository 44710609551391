import { Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';

import { V1CiCdTool } from '@endorlabs/api_client';
import {
  AccordionCard,
  NumberDisplay,
  ToolNameDisplay,
} from '@endorlabs/ui-common';

import { ToolEvidenceAppsDisplay } from './ToolEvidenceAppsDisplay';
import { ToolEvidenceBadgesDisplay } from './ToolEvidenceBadgesDisplay';
import { ToolEvidenceFilePathsDisplay } from './ToolEvidenceFilePathsDisplay';

export const ToolEvidenceAccordion = ({
  tool,
  isExpanded,
  repositoryUrl,
  repositorySha,
}: {
  tool: V1CiCdTool;
  isExpanded?: boolean;
  repositoryUrl?: string;
  repositorySha?: string;
}) => {
  const theme = useTheme();
  const { appEvidences, badgeEvidences, fileEvidences } = useMemo(() => {
    const appEvidences = tool.evidence?.filter((evd) => evd.app_id) ?? [];
    const badgeEvidences = tool.evidence?.filter((evd) => evd.badge) ?? [];
    const fileEvidences = tool.evidence?.filter((evd) => evd.file_path) ?? [];
    return { appEvidences, badgeEvidences, fileEvidences };
  }, [tool.evidence]);
  return (
    <Stack
      spacing={4}
      sx={{
        '& .MuiPaper-root': {
          border: `1px solid ${theme.palette.divider}`,
          paddingX: 3,
          paddingY: 1,
          '&:before': {
            display: 'none', // hide the top border on the collapse
          },
        },
        '& .MuiAccordionDetails-root': {
          paddingTop: 0,
        },
      }}
    >
      <AccordionCard
        elevation={0}
        expanded={isExpanded}
        id={tool.name}
        title={<ToolNameDisplay toolName={tool.name} />}
        action={<NumberDisplay value={tool.evidence?.length} />}
        summaryContent={
          <Typography variant="caption" color={theme.palette.text.secondary}>
            {tool.description ?? ''}
          </Typography>
        }
      >
        <Stack
          borderTop={`1px solid ${theme.palette.divider}`}
          gap={2}
          paddingTop={4}
          paddingBottom={1}
          marginTop={1}
          sx={{ overflowWrap: 'anywhere' }}
        >
          {fileEvidences?.length > 0 && (
            <ToolEvidenceFilePathsDisplay
              evidences={fileEvidences}
              repositoryUrl={repositoryUrl}
              repositorySha={repositorySha}
            />
          )}
          {badgeEvidences?.length > 0 && (
            <ToolEvidenceBadgesDisplay evidences={badgeEvidences} />
          )}
          {appEvidences?.length > 0 && (
            <ToolEvidenceAppsDisplay evidences={appEvidences} />
          )}
        </Stack>
      </AccordionCard>
    </Stack>
  );
};
