import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  DataTable,
  DataTableActionDropdown,
  DataTableActionDropdownItem,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  NilDisplay,
} from '@endorlabs/ui-common';

export interface ArtifactDetailTableProps
  extends Omit<DataTableProps<ArtifactDetailTableRow>, 'columns'> {
  namespace?: string;
  openDetailDrawer: (uuid: string) => void;
}

export const ArtifactDetailTable = ({
  namespace,
  openDetailDrawer,
  ...props
}: ArtifactDetailTableProps) => {
  const { formatMessage: fm } = useIntl();

  const columns = useMemo(
    () => buildArtifactDetailTableColDefs({ openDetailDrawer }),
    [openDetailDrawer]
  );

  return (
    <DataTable
      {...props}
      columns={columns}
      enablePagination
      onRowClick={(_, row) => openDetailDrawer(row.original?.uuid)}
    />
  );
};

export interface ArtifactDetailTableRow {
  uuid: string;
  artifactDigest: string;
  reference?: string;
  createTime?: string;
  updateTime?: string;
}

/**
 * Build the Table columns
 * Allow for artifact dismissal, navigation to policy, and opening the detail drawer to learn more about artifact findings
 */
const buildArtifactDetailTableColDefs = ({
  openDetailDrawer,
}: {
  openDetailDrawer?: (uuid: string) => void;
}) => {
  const columns: DataTableColumnDef<ArtifactDetailTableRow>[] = [
    {
      accessorKey: 'artifactDigest',
      colType: ColTypes.TEXT,
      header: () => <FM defaultMessage="Artifact Digest" />,
      enableSorting: true,
    },
    {
      accessorKey: 'reference',
      colType: ColTypes.TEXT,
      header: () => <FM defaultMessage="Reference" />,
      cell: ({ getValue }) => {
        const ref = getValue();
        // Handle empty ref value
        if (ref) return <Typography component="span">{ref}</Typography>;
        return <NilDisplay variant="text" />;
      },
      enableSorting: true,
    },
    {
      accessorKey: 'createTime',
      colType: ColTypes.DATE_TIME,
      header: () => <FM defaultMessage="Created" />,
      enableSorting: true,
    },
    {
      accessorKey: 'updateTime',
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Last Updated" />,
      enableSorting: true,
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const actions: DataTableActionDropdownItem[] = [];
        if (openDetailDrawer) {
          actions.push({
            label: <FM defaultMessage="Show Details" />,
            onClick: () =>
              row.original?.uuid && openDetailDrawer(row.original.uuid),
          });
        }
        return (
          <Box display="flex" justifyContent="end">
            <DataTableActionDropdown items={actions} />
          </Box>
        );
      },
      colType: ColTypes.ACTIONS,
    },
  ];

  return columns;
};
