import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import {
  AttributeDisplayRecord,
  AttributeDisplayStack,
  FindingAttributesArrayDisplay,
  FindingCategoriesArrayDisplay,
  ProjectNameDisplayV2,
} from '@endorlabs/ui-common';

import { DetailDrawerSection } from '../../../components/DetailDrawer';
import { FindingDetailDrawerSectionProps } from '../types';
import {
  getFindingDependencyNameDisplay,
  getFindingPackageNameDisplay,
} from '../utils';

interface FindingPrimaryAttributesSectionProps
  extends FindingDetailDrawerSectionProps {
  packageVersion?: PackageVersionResource;
  project?: ProjectResource;
}

const genResource = ({
  finding,
  namespace,
  packageVersion,
  project,
}: FindingPrimaryAttributesSectionProps) => {
  const resource = {
    dependency: finding
      ? getFindingDependencyNameDisplay(finding, namespace, packageVersion)
      : undefined,

    finding_categories: finding?.spec?.finding_categories ? (
      <FindingCategoriesArrayDisplay
        value={finding?.spec?.finding_categories}
      />
    ) : undefined,

    package: finding
      ? getFindingPackageNameDisplay(finding, namespace, packageVersion)
      : undefined,

    project: project && <ProjectNameDisplayV2 project={project} />,

    tags: (
      <FindingAttributesArrayDisplay
        value={finding?.spec?.finding_tags ?? []}
      />
    ),
  };

  return resource;
};

const genRecords = (): AttributeDisplayRecord[] => {
  return [
    { attributeKey: 'project', heading: <FM defaultMessage="Project" /> },
    {
      attributeKey: 'package',
      heading: <FM defaultMessage="Package" />,
      hideIfNil: true,
    },
    {
      attributeKey: 'dependency',
      heading: <FM defaultMessage="Dependency" />,
      hideIfNil: true,
    },
    { attributeKey: 'tags', heading: <FM defaultMessage="Tags" /> },
    {
      attributeKey: 'finding_categories',
      heading: <FM defaultMessage="Categories" />,
    },
  ];
};

export const FindingPrimaryAttributesSection = ({
  finding,
  isLoading,
  namespace,
  packageVersion,
  project,
}: FindingPrimaryAttributesSectionProps) => {
  const records = useMemo(() => genRecords(), []);
  const resource = useMemo(
    () => genResource({ finding, namespace, packageVersion, project }),
    [finding, namespace, packageVersion, project]
  );

  return (
    <DetailDrawerSection>
      <AttributeDisplayStack
        attributeRecords={records}
        isLoading={isLoading}
        resource={resource}
      ></AttributeDisplayStack>
    </DetailDrawerSection>
  );
};
