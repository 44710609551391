import { Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { useGetVersionUpgrade } from '@endorlabs/queries';
import {
  ButtonLinkSecondary,
  IconLink,
  PackageVersionNameDisplay,
} from '@endorlabs/ui-common';

import { getRemediationsPath } from '../../../../../src/routes';
import { DetailDrawerContainer } from '../../../../components/DetailDrawer';
import { InfoDrawerContentProps } from '../../../../components/InfoDrawer';
import { VersionUpgradeDisplay } from '../..';
import { RemediationDetailDrawerBreakingChanges } from './RemediationDetailDrawerBreakingChanges';
import { RemediationDetailDrawerOverview } from './RemediationDetailDrawerOverview';
import { RemediationDetailDrawerPotentialConflicts } from './RemediationDetailDrawerPotentialConflicts';

export interface RemediationDetailDrawerProps extends InfoDrawerContentProps {
  namespace: string;
  upgradeUuid?: string;
}

/**
 * Detail Info Drawer for a Remediations Table rows
 */
export const RemediationDetailDrawer = ({
  namespace,
  upgradeUuid,
}: RemediationDetailDrawerProps) => {
  const { space } = useTheme();
  const tabRecords = [
    {
      label: <FM defaultMessage="Overview" />,
      value: 'overview',
    },
    {
      label: <FM defaultMessage="Potential Conflicts" />,
      value: 'conflicts',
    },
    {
      label: <FM defaultMessage="Breaking Changes" />,
      value: 'breakingChanges',
    },
  ];

  const qVersionUpgrade = useGetVersionUpgrade(
    namespace,
    { uuid: upgradeUuid ?? '' },
    { enabled: !!upgradeUuid }
  );

  const isLoading = qVersionUpgrade.isLoading;

  if (!namespace) return null;

  const upgradeInfo = qVersionUpgrade?.data?.spec?.upgrade_info;

  const dependencyName = upgradeInfo?.direct_dependency_package ?? '';

  const title = (
    <Stack direction="column" spacing={2} alignItems="start">
      {isLoading ? (
        <>
          <Skeleton width={240} />
          <Skeleton width={160} />
        </>
      ) : (
        <>
          <PackageVersionNameDisplay
            packageVersion={{ meta: { name: dependencyName } }}
            size="large"
          />
          <Stack alignItems="center" direction="row" spacing={space.xs}>
            <Typography variant="body1">
              <FM defaultMessage="Upgrade" />
            </Typography>

            <VersionUpgradeDisplay
              fromVersion={upgradeInfo?.from_version}
              toVersion={upgradeInfo?.to_version}
            />
          </Stack>
        </>
      )}

      {qVersionUpgrade.data && (
        <ButtonLinkSecondary
          linkProps={{
            to: getRemediationsPath({
              tenantName: namespace,
              uuid: qVersionUpgrade.data?.uuid,
            }),
          }}
          startIcon={<IconLink />}
        >
          <FM defaultMessage="View Details" />
        </ButtonLinkSecondary>
      )}
    </Stack>
  );

  const overviewContent = (
    <RemediationDetailDrawerOverview upgradeInfo={upgradeInfo} />
  );

  const potentialConflictsContent = (
    <RemediationDetailDrawerPotentialConflicts upgradeInfo={upgradeInfo} />
  );

  const breakingChangesContent = (
    <RemediationDetailDrawerBreakingChanges upgradeInfo={upgradeInfo} />
  );

  return (
    <DetailDrawerContainer
      headerProps={{
        title,
      }}
      tabProps={{
        id: 'remediation-detail-drawer',
        tabPanelMap: {
          breakingChanges: breakingChangesContent,
          conflicts: potentialConflictsContent,
          overview: overviewContent,
        },
        tabRecords: tabRecords,
      }}
    ></DetailDrawerContainer>
  );
};
