import { Box } from '@mui/material';
import { useCallback, useState } from 'react';

import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { UIProjectUtils, useResourceCRUDMessages } from '@endorlabs/ui-common';

import { PageLayout } from '../../components/PageLayout';
import {
  getInitialFilterValues,
  useFilterContext,
  withFilterProvider,
} from '../../domains/filters';
import {
  FINDING_FILTER_FIELDS,
  FINDING_SEARCH_KEYS,
  FindingAggregation,
  FindingAggregationMenu,
  FindingAggregationMenuProps,
  FindingsDataTableView,
  useAggregatedFindingsData,
} from '../../domains/Findings';
import { FindingsAggregatedView } from '../../domains/Findings/components/FindingsAggregatedView';
import { FindingsPageNavigation } from '../Findings/FindingsPageNavigation';
import { useProjectFindingCountsByFindingSource } from './useProjectFindingCountsByFindingSource';

export interface ProjectVersionFindingsProps {
  namespace: string;
  project?: ProjectResource;
  projectVersion?: ProjectVersionResource;
}

const ProjectVersionFindingsBase = ({
  namespace,
  project,
  projectVersion,
}: ProjectVersionFindingsProps) => {
  const { getErrorMessage } = useResourceCRUDMessages();

  const { filter: userFilterExpression, _state: filterState } =
    useFilterContext();

  // get finding total finding counts, and counts for toggles
  const { totalFindingsCount } = useProjectFindingCountsByFindingSource({
    namespace,
    project,
    projectVersion: projectVersion,
  });

  const [findingAggregation, setFindingAggregation] =
    useState<FindingAggregation>(FindingAggregation.None);
  const isGrouped = findingAggregation !== FindingAggregation.None;

  const baseFilterExpression =
    UIProjectUtils.getProjectFindingFilterExpressions(project, projectVersion);

  const findingsFilterExpression = filterExpressionBuilders.and(
    [baseFilterExpression, userFilterExpression].filter((v): v is string =>
      Boolean(v)
    )
  );

  const {
    data: aggregatedFindings,
    error: aggregatedFindingsError,
    isLoading: isLoadingAggregatedFindings,
    paginator,
    totalCount,
  } = useAggregatedFindingsData({
    enabled: isGrouped && !!baseFilterExpression,
    filterState,
    findingAggregation,
    filterExpression: findingsFilterExpression,
    namespace,
  });

  const hasError = !!aggregatedFindingsError;
  const isLoading = isGrouped && isLoadingAggregatedFindings;

  const isEmptyState = !isLoading && totalFindingsCount === 0;
  const isFilteredEmptyState =
    !isLoading && !hasError && !isEmptyState && totalCount === 0;
  const errorMessage = hasError
    ? getErrorMessage('LIST', 'Finding', aggregatedFindingsError)
    : undefined;

  const setAggregationType = useCallback<
    FindingAggregationMenuProps['onSelect']
  >((_, value) => setFindingAggregation(value), []);

  return (
    <PageLayout
      pageNavigation={<FindingsPageNavigation />}
      pageContent={
        <Box>
          {isGrouped && (
            <FindingsAggregatedView
              aggregationType={findingAggregation}
              setAggregationType={setAggregationType}
              findingGroups={aggregatedFindings}
              namespace={namespace}
              paginator={paginator}
            />
          )}

          {!isGrouped && (
            <FindingsDataTableView
              actionsContent={
                <FindingAggregationMenu
                  onSelect={(_, value) => setFindingAggregation(value)}
                  value={findingAggregation}
                />
              }
              baseFilterExpression={baseFilterExpression}
              isLoading={!projectVersion}
              namespace={namespace}
            />
          )}
        </Box>
      }
    />
  );
};

const initialFilterValues = getInitialFilterValues(FINDING_FILTER_FIELDS);

export const ProjectVersionFindings = withFilterProvider(
  ProjectVersionFindingsBase,
  {
    displayName: 'ProjectVersionFindings',
    searchKeys: FINDING_SEARCH_KEYS,
    initialFilterValues: new Map().set(
      'findingExceptions',
      initialFilterValues.get('findingExceptions')
    ),
  }
);
