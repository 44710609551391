import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import {
  ControllerProps,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form';

import {
  ChecklistInput,
  ChecklistInputOption,
  ChecklistInputProps,
} from './inputs';

export type ControlledChecklistProps<T extends FieldValues> = Pick<
  ControllerProps<T>,
  'name'
> &
  Pick<ChecklistInputProps, 'groupBy' | 'options'> & {
    label?: ReactNode;
    helperText?: ReactNode;
  };

export const ControlledChecklist = <T extends FieldValues>({
  label,
  groupBy,
  helperText,
  name,
  options,
}: ControlledChecklistProps<T>) => {
  const { clearErrors, control } = useFormContext<T>();
  const { field, fieldState } = useController({
    control,
    name,
  });

  const handleChange = (value: ChecklistInputOption[]) => {
    field.onChange(value);
    field.onBlur();
  };

  useEffect(() => {
    clearErrors(name);
  }, [clearErrors, name]);

  const errorMessage = fieldState.error?.message;

  return (
    <FormControl component="fieldset" variant="standard" fullWidth>
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <ChecklistInput
        value={field.value}
        onChange={handleChange}
        groupBy={groupBy}
        options={options}
      />

      {(errorMessage ?? helperText) && (
        <FormHelperText error={!!errorMessage}>
          {errorMessage ?? helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
