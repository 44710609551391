import { Skeleton, Stack, Typography } from '@mui/material';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  AttributeDisplayStack,
  FlexList,
  FlexListItem,
  FormattedTypography,
  IconCircleFilled,
  Link,
  NilDisplay,
} from '@endorlabs/ui-common';

import { FindingDetailCodeSnippet } from '../../../../../src/components/FindingDetail/FindingDetailCodeSnippet';
import { DetailDrawerSectionStack } from '../../../../components/DetailDrawer';
import { FindingsDetailSASTSectionProps } from '../../types';

const genResource = ({
  sastRuleForFinding,
}: FindingsDetailSASTSectionProps) => {
  const references = sastRuleForFinding?.spec?.rule?.metadata?.references;
  const resource = {
    ruleId: <Typography>{sastRuleForFinding?.spec?.rule?.id ?? ''}</Typography>,

    references: !_isEmpty(references) ? (
      <FlexList>
        {references?.map((ref: string) => (
          <FlexListItem
            key={ref}
            display="flex"
            flexDirection="row"
            alignItems="baseline"
          >
            <IconCircleFilled
              sx={{ color: 'primary.main', width: 4, height: 4, mr: 2 }}
            />
            <Link to={ref} target="_blank">
              <Typography variant="body2">{ref}</Typography>
            </Link>
          </FlexListItem>
        ))}
      </FlexList>
    ) : (
      <NilDisplay variant="text" />
    ),
  };

  return resource;
};

const genRecords = () => {
  return [
    {
      attributeKey: 'ruleId',
      heading: <FM defaultMessage="Rule ID" />,
      hideIfNil: true,
    },
    {
      attributeKey: 'references',
      heading: <FM defaultMessage="References" />,
      hideIfNil: true,
    },
  ];
};
export const FindingSASTDetailSection = ({
  sastRuleForFinding,
  isLoading,
  finding,
  namespace,
}: FindingsDetailSASTSectionProps) => {
  const records = useMemo(() => genRecords(), []);
  const resource = useMemo(
    () => genResource({ finding, namespace, sastRuleForFinding }),
    [finding, namespace, sastRuleForFinding]
  );
  const results = finding?.spec?.finding_metadata?.source_policy_info?.results;
  return (
    <DetailDrawerSectionStack>
      <AttributeDisplayStack
        attributeRecords={records.slice(0, 1)}
        headingWidth="30%"
        isLoading={isLoading}
        resource={resource}
      />

      {isLoading ? (
        <Skeleton />
      ) : (
        <Stack spacing={4}>
          {results?.map((r, index) => {
            return (
              <FindingDetailCodeSnippet
                code={r?.fields?.['Code Snippet'] || ''}
                key={index}
                fileLocation={r?.fields?.Location || ''}
              />
            );
          })}
        </Stack>
      )}

      {isLoading ? (
        <Skeleton />
      ) : (
        <FormattedTypography
          text={sastRuleForFinding?.spec?.rule?.metadata?.description}
        />
      )}

      <AttributeDisplayStack
        attributeRecords={records.slice(1)}
        headingWidth="30%"
        isLoading={isLoading}
        resource={resource}
      />
    </DetailDrawerSectionStack>
  );
};
