import _isMatch from 'lodash-es/isMatch';

import { ArtifactSignatureResource } from '@endorlabs/endor-core/Artifact';

import { useInfoDrawer } from '../../components';
import {
  ArtifactDetailDrawer,
  ArtifactDetailDrawerProps,
} from './ArtifactDetailDrawer';

interface PermalinkEffectProps {
  artifacts: ArtifactSignatureResource[];
}

export const useArtifactDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    { artifactNamespace: string; artifactUuid: string },
    ArtifactDetailDrawerProps
  >({
    Component: ArtifactDetailDrawer,
    drawerParams: ['artifactNamespace', 'artifactUuid'],
  });

  const permalinkEffect = ({ artifacts }: PermalinkEffectProps) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();
    const activeArtifact = activeDrawerParams
      ? artifacts.find((n) =>
          _isMatch(n, {
            uuid: activeDrawerParams.artifactUuid,
          })
        )
      : undefined;

    if (
      activeArtifact &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      const artifactNamespace = activeArtifact.tenant_meta.namespace;
      DetailDrawer.activate(
        {
          artifactNamespace,
          artifactUuid: activeArtifact.uuid,
        },
        {
          artifactNamespace,
          artifactUuid: activeArtifact.uuid,
        }
      );
    }
  };

  return { DetailDrawer, permalinkEffect };
};
