import { Breakpoints, Palette } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const commonFontFallbacks = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const FontStacks = {
  CODE: '"Source Code Pro", "Fira Code", "Consolas", "Inconsolata", monospace',
  INTER: `Inter, ${commonFontFallbacks}`,
  SWITZER: `Switzer, ${commonFontFallbacks}`,
};

/**
 * NOTE: 1rem = 16px
 *
 * From Figma text styles:
 * https://www.figma.com/file/XneTcn3Urmh31ThynELmss/Design-System?node-id=2%3A2104
 */

const smallBodyTreatment = {
  fontSize: '0.75rem',
  lineHeight: 1.5,
};

const medHeadingTreatment = {
  fontSize: '1.0625rem',
  fontWeight: 500,
  lineHeight: 1.25,
};

interface TypographyProps {
  breakpoints: Breakpoints;
  palette: Palette;
}

export const getTypographyOverrides = ({
  breakpoints,
  palette,
}: TypographyProps) => {
  const smallHeadingTreatment = {
    color: palette.text.secondary,
    fontFamily: FontStacks.SWITZER,
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.83px',
    lineHeight: 1.25,
    textTransform: 'uppercase',
  };

  return {
    fontFamily: FontStacks.INTER,
    fontSize: 14,
    htmlFontSize: 16,

    h1: {
      fontFamily: FontStacks.SWITZER,
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.25,

      [breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },

    h2: {
      fontFamily: FontStacks.SWITZER,
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.25,

      [breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },

    h3: { ...medHeadingTreatment, fontFamily: FontStacks.SWITZER },

    h4: smallHeadingTreatment,

    h5: smallHeadingTreatment,

    h6: { ...medHeadingTreatment, fontSize: '0.875rem' },

    overline: smallHeadingTreatment,

    body1: {
      fontSize: '0.875rem',
      lineHeight: 1.285,
    },

    body2: smallBodyTreatment,

    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.725,
      textTransform: 'none',
    },

    caption: smallBodyTreatment,

    code: {
      fontFamily: FontStacks.CODE,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.285,
    },

    subtitle1: medHeadingTreatment,

    subtitle2: { ...medHeadingTreatment, fontSize: '0.875rem' },

    xl: {
      fontSize: '2.5rem',
      fontWeight: 500,
      lineHeight: 1,

      [breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
    },

    xxl: {
      fontSize: '4.25rem',
      fontWeight: 500,
      lineHeight: 0.85,

      [breakpoints.down('md')]: {
        fontSize: '2.5rem',
      },
    },
  } as TypographyOptions;
};
