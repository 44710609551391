import { Switch } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { SyntheticEvent } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
} from '@endorlabs/ui-common';

import { SASTRulesTableRowActionHandler, SASTRuleTableProps } from './types';

export interface SASTRulesTableRow {
  disabled: boolean | undefined;
  ruleName: string | undefined;
  definedBy: string;
  cwe: string;
  confidence: string;
  ruleTags: Array<string> | undefined;
  uuid: string;
  namespace: string;
  languages: string;
}

const actionHandler =
  (row: Row<SASTRulesTableRow>, action: SASTRulesTableRowActionHandler) =>
  (_: SyntheticEvent) => {
    if (!row.original || !row.original.namespace) return;

    const { namespace, uuid } = row.original;
    return action({ namespace, uuid });
  };

export type SASTRulesTableColumnId = keyof SASTRulesTableRow;

export const buildSASTRulesTableColumnDefs = ({
  labelSwitch,
  onDisable,
  excludeColumns,
}: SASTRuleTableProps) => {
  const columns: DataTableColumnDef<SASTRulesTableRow>[] = [
    {
      id: 'enabled',
      cell: ({ row }) => {
        return (
          <Switch
            aria-label={labelSwitch}
            checked={!row.original?.disabled}
            onChange={actionHandler(row, onDisable)}
            size="small"
          />
        );
      },
      colType: ColTypes.SWITCH,
    },
    {
      accessorKey: 'ruleName',
      header: () => <FM defaultMessage="SAST Rule" />,
      colType: ColTypes.TEXT_LONG,
    },
    {
      accessorKey: 'definedBy',
      header: () => <FM defaultMessage="Defined By" />,
      colType: ColTypes.TEXT_WITH_DESCRIPTION,
    },
    {
      accessorKey: 'cwe',
      header: () => <FM defaultMessage="CWE" />,
      colType: ColTypes.TEXT_LONG,
    },
    {
      accessorKey: 'confidence',
      header: () => <FM defaultMessage="Confidence" />,
      colType: ColTypes.TEXT_WITH_DESCRIPTION,
    },
    {
      accessorKey: 'languages',
      header: () => <FM defaultMessage="Languages" />,
      colType: ColTypes.TEXT_LONG,
    },
    {
      accessorKey: 'ruleTags',
      header: () => <FM defaultMessage="Rule Tags" />,
      colType: ColTypes.TAGS,
    },
  ];
  return columns.filter((col) => {
    if (!col.accessorKey) return true;
    return (excludeColumns ?? []).includes(col.accessorKey) ? false : true;
  });
};
