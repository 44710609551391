import { defineMessages } from 'react-intl';

import { V1PluginBinarySpec } from '@endorlabs/api_client';

import { getEnv } from '../../../constants';
import { AuthMode, PlatformSourceType, SupportedOS } from './constants';

export const stepHeadingsGeneral = defineMessages({
  step1: { defaultMessage: '<b>Step 1 -</b> Choose your platform' },
  selectVersion: { defaultMessage: '<b>Step 2 -</b> Select Endorctl Version' },
});

export const stepHeadingsEndorctl = defineMessages({
  stepEndorctlInstall: {
    defaultMessage: '<b>Step 3 -</b> Download and install the endorctl CLI',
  },
  stepEndorctlInit: { defaultMessage: '<b>Step 4 -</b> Initialize endorctl' },
  stepEndorctlScan: {
    defaultMessage: '<b>Step 5 -</b> Scan a Repository',
  },
});

/**
 * @param binaryInfo subset of the {@see V1PluginBinarySpec}, used to populate the install code block
 */
export const getEndorctlInstallCodeBlock = (
  platform?: SupportedOS,
  binaryInfo?: Pick<V1PluginBinarySpec, 'url' | 'checksum'>
) => {
  const { checksum, url = '' } = binaryInfo ?? {};

  const binaryName = 'endorctl';

  const commands = [
    `## Download the CLI for your selected architecture`,
    `curl ${url} -o ${binaryName}`,
    '', // empty line between sections
  ];

  switch (platform) {
    case SupportedOS.MacOS:
      commands.push(
        `## Verify the checksum of the binary`,
        `echo "${checksum}  ${binaryName}" | shasum -a 256 --check`
      );
      break;
    case SupportedOS.Linux:
      commands.push(
        `## Verify the checksum of the binary`,
        `echo "${checksum} ${binaryName}" | sha256sum --check`
      );
      break;
    default:
      commands.push(`## Verify the checksum of the binary: ${checksum}`);
  }

  commands.push(
    '', // empty line between sections
    `## Modify the permissions of the binary to ensure it is executable`,
    `chmod +x ./${binaryName}`
  );

  commands.push(
    '', // empty line between sections
    `## Create an alias endorctl of the binary to ensure it is available in other directory`,
    `alias endorctl="$PWD/endorctl"`
  );

  return commands.join('\n');
};

export const getEndorctlInstallCodeBlockWindows = (
  binaryInfo?: Pick<V1PluginBinarySpec, 'url' | 'checksum'>
) => {
  const { checksum, url = '' } = binaryInfo ?? {};
  const filename = url.split('/').pop();

  return `## Download the CLI for Windows
curl -O ${url}

## Verify the checksum of the executable against SHA256 hash ${checksum}
certutil -hashfile .\\${filename} SHA256

## rename the binary
rename ${filename} endorctl.exe`;
};

export const getEndorctlInitCodeBlock = (authMode?: AuthMode) => {
  switch (authMode) {
    case AuthMode.Google:
      return 'endorctl init --auth-mode=google';
    case AuthMode.GitHub:
      return 'endorctl init --auth-mode=github';
    case AuthMode.GitLab:
      return 'endorctl init --auth-mode=gitlab';
    case AuthMode.Azure:
      return 'endorctl init --auth-mode=azure';
  }
  return '';
};

export const getEndorctlScanCodeBlock = (
  platformSourceType?: PlatformSourceType
) => {
  switch (platformSourceType) {
    case PlatformSourceType.GITHUB:
    case PlatformSourceType.GITLAB:
      return `\
## Clone the repository to scan
git clone <insert_git_clone_url>

## Enter the cloned repository directory
cd ./<repository>

## Run a scan against the local repository
endorctl scan`;
  }

  return '';
};

export const getHomebrewCodeBlock = () => {
  const homebrewCodeBlock = ['brew tap endorlabs/tap', 'brew install endorctl'];
  return homebrewCodeBlock.join('\n');
};

export const getNpmCodeBlock = () => {
  const npmCodeBlock = ['npm install -g endorctl'];
  return npmCodeBlock.join('\n');
};
