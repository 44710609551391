import { SvgIconProps } from '@mui/material';
import { JSXElementConstructor, ReactNode } from 'react';
import { FormattedMessage as FM, MessageFormatElement } from 'react-intl';

import { PackagistSpecAuthKind, V1PlatformSource } from '@endorlabs/api_client';
import {
  CustomNotificationTemplateTypeKey,
  IconGitPullRequest,
  IconLink,
  IconMail,
  ImgIconLogoMarkGitHub,
  ImgIconLogoMarkGitLab,
  ImgIconLogoMarkJira,
  ImgIconLogoMaven,
  ImgIconLogoNPM,
  ImgIconLogoNuget,
  ImgIconLogoPackagist,
  ImgIconLogoPypi,
  ImgIconLogoRubyGems,
  ImgIconLogoSlack,
  ImgIconLogoVanta,
  NotificationTargetActionTypeKey,
} from '@endorlabs/ui-common';

import { AllPackageManagerFieldKeys } from './types';

export type NotificationTargetOption = {
  count?: number;
  disabled?: boolean;
  /**
   * Icon for the source control manager
   */
  Icon: JSXElementConstructor<SvgIconProps>;
  isLoading?: boolean;
  label: ReactNode;
  key: NotificationTargetActionTypeKey;
};

export const NOTIFICATION_TARGETS: NotificationTargetOption[] = [
  {
    Icon: IconMail,
    key: 'email',
    label: <FM defaultMessage="Email" />,
  },
  {
    Icon: ImgIconLogoMarkJira,
    key: 'jira',
    label: <FM defaultMessage="Jira" />,
  },
  {
    Icon: ImgIconLogoSlack,
    key: 'slack',
    label: <FM defaultMessage="Slack" />,
  },
  {
    Icon: ImgIconLogoVanta,
    key: 'vanta',
    label: <FM defaultMessage="Vanta" />,
  },
  {
    Icon: IconLink,
    key: 'webhook',
    label: <FM defaultMessage="Webhook" />,
  },
];

export type CustomNotificationTemplateOption = {
  disabled?: boolean;
  Icon: JSXElementConstructor<SvgIconProps>;
  label: ReactNode;
  key: CustomNotificationTemplateTypeKey;
};

export const CUSTOM_TEMPLATE_TARGETS: CustomNotificationTemplateOption[] = [
  {
    Icon: IconGitPullRequest,
    key: 'pr_comments',
    label: <FM defaultMessage="GitHub PR Comments" />,
  },
];

export const SourceControlManagerKeys = {
  GitHub: 'GitHub',
  Gitlab: 'GitLab',
} as const;

export type SourceControlManagerKey =
  (typeof SourceControlManagerKeys)[keyof typeof SourceControlManagerKeys];

export type SourceControlManagerOption = {
  disabled?: boolean;
  /**
   * Icon for the source control manager
   */
  Icon: JSXElementConstructor<SvgIconProps>;
  isLoading?: boolean;
  label: ReactNode;
  key: SourceControlManagerKey;
  platformSource: V1PlatformSource;
  installationCount: number;
  repositoryCount: number;
};

export const SOURCE_CONTROL_MANAGERS: SourceControlManagerOption[] = [
  {
    Icon: ImgIconLogoMarkGitHub,
    key: SourceControlManagerKeys.GitHub,
    label: <FM defaultMessage="GitHub" />,
    platformSource: V1PlatformSource.Github,
    installationCount: 0,
    repositoryCount: 0,
  },
  {
    disabled: true,
    Icon: ImgIconLogoMarkGitLab,
    key: SourceControlManagerKeys.Gitlab,
    label: <FM defaultMessage="GitLab" />,
    platformSource: V1PlatformSource.Gitlab,
    installationCount: 0,
    repositoryCount: 0,
  },
];

export const PackageManagerKeys = {
  mvn: 'mvn',
  npm: 'npm',
  pypi: 'pypi',
  gem: 'gem',
  packagist: 'packagist',
  nuget: 'nuget',
} as const;

export type PackageManagerKey =
  (typeof PackageManagerKeys)[keyof typeof PackageManagerKeys];

export type AuthLoginPackageManagerKey = keyof Pick<
  typeof PackageManagerKeys,
  'mvn' | 'pypi' | 'gem' | 'nuget'
>;

export type TokenLoginPackageManagerKey = keyof Pick<
  typeof PackageManagerKeys,
  'npm'
>;

export type PackagistPackageManagerKey = keyof Pick<
  typeof PackageManagerKeys,
  'packagist'
>;

export type PackageManagerOption = {
  authRequired?: boolean;
  /**
   * Icon for the source control manager
   */
  Icon: JSXElementConstructor<SvgIconProps>;
  key: PackageManagerKey;
  label: ReactNode;
  packageManagerCount?: number;
  isLoading?: boolean;
};

export const PACKAGE_MANAGERS: PackageManagerOption[] = [
  {
    Icon: ImgIconLogoMaven,
    key: PackageManagerKeys.mvn,
    label: <FM defaultMessage="Maven" />,
    packageManagerCount: 0,
  },
  {
    Icon: ImgIconLogoNPM,
    key: PackageManagerKeys.npm,
    label: <FM defaultMessage="npm" />,
    packageManagerCount: 0,
  },
  {
    Icon: ImgIconLogoPypi,
    key: PackageManagerKeys.pypi,
    label: <FM defaultMessage="PyPI" />,
    packageManagerCount: 0,
  },
  {
    Icon: ImgIconLogoNuget,
    key: PackageManagerKeys.nuget,
    label: <FM defaultMessage="Nuget" />,
    packageManagerCount: 0,
  },
  {
    authRequired: true,
    Icon: ImgIconLogoRubyGems,
    key: PackageManagerKeys.gem,
    label: <FM defaultMessage="RubyGems" />,
    packageManagerCount: 0,
  },
  {
    authRequired: true,
    Icon: ImgIconLogoPackagist,
    key: PackageManagerKeys.packagist,
    label: <FM defaultMessage="Packagist" />,
    packageManagerCount: 0,
  },
];

export const PackageManagersWithPasswordAuth: PackageManagerKey[] = [
  PackageManagerKeys.mvn,
  PackageManagerKeys.pypi,
  PackageManagerKeys.gem,
  PackageManagerKeys.nuget,
];

export const PackageManagersWithTokenAuth: PackageManagerKey[] = [
  PackageManagerKeys.npm,
];

export const PackageManagerSpecFieldMasks = [
  'spec.gem.priority',
  'spec.gem.url',
  'spec.gem.user',
  'spec.gem.password',
  'spec.mvn.priority',
  'spec.mvn.password',
  'spec.mvn.url',
  'spec.mvn.user',
  'spec.nuget.priority',
  'spec.nuget.url',
  'spec.nuget.user',
  'spec.nuget.password',
  'spec.npm.priority',
  'spec.npm.scope',
  'spec.npm.url',
  'spec.npm.token',
  'spec.pypi.priority',
  'spec.pypi.url',
  'spec.pypi.user',
  'spec.pypi.password',
  'spec.packagist.host',
  'spec.packagist.auth_kind',
  'spec.packagist.user',
  'spec.packagist.password',
];

export const PACKAGIST_AUTH_TYPES: PackagistSpecAuthKind[] = [
  PackagistSpecAuthKind.HttpBasic,
  PackagistSpecAuthKind.HttpBearer,
  PackagistSpecAuthKind.GithubOauth,
  PackagistSpecAuthKind.GitlabOauth,
  PackagistSpecAuthKind.GitlabToken,
  PackagistSpecAuthKind.BitbucketOauth,
];

export const PackageManagerFieldsInForm: Array<AllPackageManagerFieldKeys> = [
  'priority',
  'url',
  'user',
  'password',
  'host',
  'auth_kind',
  'scope',
  'token',
];
