/** This function takes the object and returns path of the keys recursively.
 *
 *  Primary usage, it it can take dirtyfields from react-hook-form,
 *  return the array of paths of the objects which cane be passed to mask field.
 *  Input : {
    "meta": {
        "name": true,
        "description": true
    },
    "spec": {
        "action": {
            "jira_config": {
                "user_name": true,
                "api_key": true,
                "url": true,
                "project_key": true,
                "labels": true
            }
        }
    }
 *  Output:
    ["meta.name", "meta.description", "spec.action.jira_config.user_name", "spec.action.jira_config.api_key", "spec.action.jira_config.url", "spec.action.jira_config.project_key", "spec.action.jira_config.labels"]
 *
 */

export function getObjectKeys<T>(obj: T) {
  const result: string[] = [];
  if (typeof obj != 'object') return result;
  function keyExtractLoop(obj: T, path: string): string[] {
    for (const key in obj) {
      const newKey: string = path === '' ? key : path + '.' + key;
      if (typeof obj[key] === 'object') {
        keyExtractLoop(obj[key] as T, newKey);
      } else {
        result.push(newKey);
      }
    }
    return result;
  }
  return keyExtractLoop(obj, '');
}
