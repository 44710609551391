import { Avatar, Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import {
  Children as ReactChildren,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ApiKeyResource, useCreateApiKey } from '@endorlabs/queries';
import { ExternalLink, Link, RowStack } from '@endorlabs/ui-common';

import { FormNewApiKey, LicenseRequired } from '../../../../components';
import { getEnv } from '../../../../constants';
import { NamedRoutes } from '../../../../routes';
import { APIKeyPreview } from '../../../APIKey';
import { PatchConfigurationDialogState } from './types';

export type PatchConfigurationDialogContentProps =
  PatchConfigurationDialogState;

const { EMAIL_ENDOR_SUPPORT } = getEnv();

export const PatchConfigurationDialogContent = ({
  hasEndorPatchesLicense,
  namespace,
}: PatchConfigurationDialogContentProps) => {
  const { formatMessage: fm } = useIntl();
  const { palette, space } = useTheme();

  const qCreateApiKey = useCreateApiKey({
    // NOTE: error for API key is handled in the dialog
    onError: () => undefined,
  });

  useEffect(
    () => qCreateApiKey.reset(),
    // Reset API Key hook when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleCreate = (apiKey: ApiKeyResource) => {
    if (!namespace) return;
    qCreateApiKey.mutate({ namespace, resource: apiKey });
  };

  const mailToLink = useMemo(() => {
    const subject = fm({
      defaultMessage:
        'Endor Labs Support: Endor Labs Patch Factory Configuration',
    });

    return `mailto:${EMAIL_ENDOR_SUPPORT}?subject=${encodeURIComponent(
      subject
    )}`;
  }, [fm]);

  return (
    <NumberedSectionStack>
      {/* Section 1: Generate API Key */}
      <Stack gap={space.xs}>
        <Typography variant="body1" fontWeight={600}>
          <FM defaultMessage="Generate an API Key to the Endor Labs Patch Factory." />
        </Typography>

        <Typography variant="caption" color="text.secondary">
          <FM defaultMessage="This key will be used to communicate with our artifact repository, giving it access to our repository of secure artifacts." />
        </Typography>

        {!hasEndorPatchesLicense && (
          <Paper variant="outlined" sx={{ padding: space.sm }}>
            <LicenseRequired
              title={<FM defaultMessage="Endor Patches License Required" />}
            />
          </Paper>
        )}

        {hasEndorPatchesLicense && !qCreateApiKey.data && (
          <FormNewApiKey
            isLoading={qCreateApiKey.isLoading}
            onSubmit={handleCreate}
            serverErrorResponse={qCreateApiKey.error?.response}
          />
        )}

        {qCreateApiKey.data && (
          <APIKeyPreview apiKey={qCreateApiKey.data as ApiKeyResource} />
        )}
      </Stack>

      {/* Section 2: Configure Endor Labs Patch Factory */}
      <Stack gap={space.xs}>
        <Typography variant="body1" fontWeight={600}>
          <FM defaultMessage="Configure Endor Labs Patch Factory as an artifact repository in your package manager." />
        </Typography>

        <Box padding={space.sm}>
          <ExternalLink
            color={palette.brand.main}
            to={NamedRoutes.DOCS_ENDOR_PATCH_FACTORY_CONFIGURATION}
          >
            <FM defaultMessage="Configuration Instructions" />
          </ExternalLink>
        </Box>

        <Typography variant="caption" color="text.secondary">
          <FM
            defaultMessage="You can manually edit your dependency files to use the patched version. For further assistance, contact <link>Endor Labs Support</link>."
            values={{
              link: (value) => (
                <Link target="_blank" to={mailToLink}>
                  {value}
                </Link>
              ),
            }}
          />
        </Typography>
      </Stack>
    </NumberedSectionStack>
  );
};

const NumberedSectionStack = ({ children }: { children: ReactNode }) => {
  const { palette, space, typography } = useTheme();

  const sections = ReactChildren.toArray(children);

  return (
    <Stack gap={space.md}>
      {sections.map((section, index) => (
        <RowStack alignItems="stretch" flexWrap="nowrap" key={index}>
          <Stack
            gap={space.xs}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box flexShrink={0}>
              <Avatar
                sx={{
                  backgroundColor: palette.brand.main,
                  fontSize: typography.button.fontSize,
                  fontWeight: typography.button.fontWeight,
                  height: 24,
                  width: 24,
                }}
              >
                {index + 1}
              </Avatar>
            </Box>

            <Box borderLeft={`2px solid ${palette.divider}`} flexGrow={1} />
          </Stack>

          <Box paddingBottom={4}>{section}</Box>
        </RowStack>
      ))}
    </Stack>
  );
};
