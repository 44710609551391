import { defineMessages, MessageDescriptor } from 'react-intl';

import { V1ArtifactType as ArtifactType } from '@endorlabs/api_client';

/**
 * Map of Artifacts Categories to internationalized, human-readable labels.
 */
export const ArtifactTypeMessages: Record<ArtifactType, MessageDescriptor> =
  defineMessages({
    [ArtifactType.Container]: { defaultMessage: 'Container' },
    [ArtifactType.Jar]: { defaultMessage: 'Jar' },
    [ArtifactType.Wheel]: { defaultMessage: 'Wheel' },
    [ArtifactType.Dll]: { defaultMessage: 'DLL' },
    [ArtifactType.Elf]: { defaultMessage: 'ELF' },
    [ArtifactType.Sbom]: { defaultMessage: 'SBOM' },
    [ArtifactType.Unspecified]: { defaultMessage: 'Unspecified' },
  });
