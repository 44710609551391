import { Checkbox, CheckboxProps } from '@mui/material';
import _noop from 'lodash-es/noop';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';

export type ControlledCheckboxProps<TFieldValues extends FieldValues> = Omit<
  ControllerProps<TFieldValues>,
  'render'
> &
  // `control` prop is required for this component, but may be replaced with
  // the use of `FormProvider` in the future.
  Required<Pick<ControllerProps<TFieldValues>, 'control'>> &
  CheckboxProps & { htmlName?: string };

/**
 * A composite component that reduces boilerplate for the common pattern of a Checkbox controlled by react-hook-form.
 * Combines component interfaces, with most props belonging to Checkbox.
 * Handles render prop & common error state patterns.
 */
export const ControlledCheckbox = <T extends FieldValues>({
  control,
  defaultValue,
  htmlName,
  inputProps,
  name,
  rules,
  ...checkboxProps
}: ControlledCheckboxProps<T>) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      // NOTE: `name` prop is used for react-hook-form. Input `name` can be set with `htmlName`.
      name={name}
      render={({ field: { ref, ...props } }) => {
        return (
          <Checkbox
            // Standard Checkbox props
            {...checkboxProps}
            // Controller props take precedence
            {...props}
            // Overrides
            checked={props.value}
            inputProps={{ ...inputProps, name: htmlName }}
            inputRef={ref}
            onChange={(evt) => {
              // If not explicitly set, set event target name to controlled field name,
              // so that react-hook-form can operate on it.
              evt.target.name = htmlName ?? name;
              props.onChange(evt);
              (checkboxProps.onChange ?? _noop)(evt);
            }}
          />
        );
      }}
      rules={rules}
    />
  );
};
