import { Grid } from '@mui/material';
import { useNavigate, useRouter } from '@tanstack/react-location';
import { useEffect, useMemo, useRef } from 'react';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import { filterExpressionBuilders } from '@endorlabs/filters';

import { ResourceFilterContextProvider } from '../../domains/filters';
import {
  REMEDIATIONS_SEARCH_KEYS,
  useRemediationsExportDialog,
} from '../../domains/Remediations';
import {
  RemediationsTableView,
  RemediationsTableViewRef,
} from '../../domains/Remediations/components/RemediationsTableView';
import { useAuthInfo, useLicensingInfo } from '../../providers';
import { getPoliciesPath } from '../../routes';

export interface PackageVersionRemediationsProps {
  tenantName: string;
  packageVersion?: PackageVersionResource;
}

export const PackageVersionRemediations = ({
  tenantName,
  packageVersion,
}: PackageVersionRemediationsProps) => {
  const navigate = useNavigate();

  const { setLicenseInCurrentRoute } = useAuthInfo();

  const router = useRouter();

  const baseFilterExpression = useMemo(() => {
    const findingsReductionFilter = [
      'spec.upgrade_info.vuln_finding_info.reduction > 0',
      'spec.upgrade_info.other_finding_info.reduction > 0',
    ];
    const filterExpression = [
      `meta.name==PrioritizedPerPackage`,
      `spec.upgrade_info exists`,
      filterExpressionBuilders.or(findingsReductionFilter),
    ];

    if (packageVersion?.uuid) {
      filterExpression.push(`meta.parent_uuid==${packageVersion?.uuid}`);
    }

    return filterExpressionBuilders.and(filterExpression);
  }, [packageVersion]);

  const tableRef = useRef<RemediationsTableViewRef>(null);

  const { checkLicensePresent } = useLicensingInfo();

  const isRemediationLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.Recommendation
  );

  useEffect(() => {
    if (!isRemediationLicensePresent) {
      setLicenseInCurrentRoute({
        pathname: router.state.location.pathname,
        isLicense: false,
      });
      return () => {
        setLicenseInCurrentRoute({
          pathname: '',
          isLicense: true,
        });
      };
    }
  }, [
    isRemediationLicensePresent,
    router.state.location.pathname,
    setLicenseInCurrentRoute,
  ]);

  const remediationsExportDialog = useRemediationsExportDialog();

  const handleExport = () => {
    const remediationsFilterExpression = tableRef.current
      ? tableRef.current?.remediationsFilterExpression
      : baseFilterExpression;

    remediationsExportDialog.openDialog({
      namespace: tenantName,
      filter: remediationsFilterExpression,
      downloadProps: {
        filename: `package_version${packageVersion?.uuid}_remediations-export.csv`,
      },
    });
  };

  const handleRefresh = () => {
    if (tableRef.current) {
      tableRef.current.refreshData();
    }
  };

  const handleCreatePolicy = () => {
    navigate({ to: getPoliciesPath({ tenantName, section: `actions/new` }) });
  };

  const actionList = [
    // NOTE: Hidden until create policy is available
    // {
    //   actionId: 'createPolicy',
    //   label: 'Create Policy',
    //   onApply: handleCreatePolicy,
    // },
    { actionId: 'export', label: 'Export', onApply: handleExport },
    { actionId: 'refresh', label: 'Refresh', onApply: handleRefresh },
  ];

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <ResourceFilterContextProvider
          namespace={tenantName}
          resourceKind="VersionUpgrade"
          resourceSearchKeys={REMEDIATIONS_SEARCH_KEYS}
        >
          <RemediationsTableView
            actions={actionList}
            baseFilterExpression={baseFilterExpression}
            excludeColumns={['packageVersionName', 'projectName']}
            ref={tableRef}
            tenantName={tenantName}
          />
        </ResourceFilterContextProvider>
      </Grid>
      <remediationsExportDialog.Dialog
        {...remediationsExportDialog.getDialogProps()}
      />
    </Grid>
  );
};
