import { Stack, Typography } from '@mui/material';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  getFindingsLanguageDisplay,
  getSASTSecurityFindingCweId,
} from '@endorlabs/endor-core/Finding';
import {
  AttributeDisplayStack,
  FindingCategoriesSASTArrayDisplay,
  NilDisplay,
} from '@endorlabs/ui-common';

import { FindingsDetailSASTSectionProps } from '../../types';

const genResource = ({
  sastRuleForFinding,
}: FindingsDetailSASTSectionProps) => {
  const cweData = getSASTSecurityFindingCweId(sastRuleForFinding) ?? [];
  const languages = sastRuleForFinding?.spec?.rule?.languages ?? [];
  const tags = sastRuleForFinding?.spec?.rule?.metadata?.endor_tags ?? [];
  const resource = {
    cweId:
      sastRuleForFinding && !_isEmpty(cweData) ? (
        <Stack spacing={2}>
          {cweData?.map((cwe) => (
            <Stack key={cwe.cweId}>
              <Typography variant="body2">{cwe.cweId}</Typography>
              <Typography variant="body2">{cwe.cweDescription}</Typography>
            </Stack>
          ))}
        </Stack>
      ) : (
        <NilDisplay variant="text" />
      ),
    languages: !_isEmpty(languages) ? (
      <Typography variant="body2">
        {getFindingsLanguageDisplay(languages)}
      </Typography>
    ) : (
      <NilDisplay variant="text" />
    ),
    finding_categories: !_isEmpty(tags) ? (
      <FindingCategoriesSASTArrayDisplay value={tags} />
    ) : (
      <NilDisplay variant="text" />
    ),
  };

  return resource;
};
const genRecords = () => {
  return [
    {
      attributeKey: 'cweId',
      heading: <FM defaultMessage="CWE Id" />,
      hideIfNil: true,
    },
    {
      attributeKey: 'languages',
      heading: <FM defaultMessage="Languages" />,
      hideIfNil: true,
    },
    {
      attributeKey: 'finding_categories',
      heading: <FM defaultMessage="Category" />,
      hideIfNil: true,
    },
  ];
};
export const FindingSASTMetadataSection = ({
  isLoading,
  finding,
  namespace,
  sastRuleForFinding,
}: FindingsDetailSASTSectionProps) => {
  const records = useMemo(() => genRecords(), []);
  const resource = useMemo(
    () => genResource({ finding, namespace, sastRuleForFinding }),
    [finding, namespace, sastRuleForFinding]
  );
  return (
    <AttributeDisplayStack
      attributeRecords={records}
      headingWidth="30%"
      isLoading={isLoading}
      resource={resource}
      variant="flex"
    />
  );
};
