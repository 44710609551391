import {
  alpha,
  Box,
  Grid,
  IconButton,
  lighten,
  Paper,
  Stack,
} from '@mui/material';
import { ReactNode, useState } from 'react';

import {
  FilterableResource,
  ResourceFilter,
  ResourceFilterDefinition,
  ResourceFilterSingleValue,
} from '@endorlabs/filters';
import {
  IconClipboard,
  IconFolder,
  IconSave,
  IconSearch,
} from '@endorlabs/ui-common';

import { ActiveFilterList } from './ActiveFilterList';
import {
  AddResourceFilterButton,
  ControlSelectFilterDefinition,
  FormDefaultSearch,
  FormNewFilter,
} from './Controls';

export interface FilterBuilderProps {
  /**
   * The default filter to add on search.
   *
   * Uses `FilterSingleValue`, as search will return a string.
   */
  defaultSearchFilter: Omit<ResourceFilterSingleValue, 'value'>;
  /**
   * The available Filter Definitions to choose from
   */
  definitions: ResourceFilterDefinition[];
  /**
   * The active filters used for this component
   */
  filters?: ResourceFilter[];
  /**
   * handle
   */
  onChange: (filters: ResourceFilter[]) => void;
  /**
   *
   */
  primaryResourceKind: FilterableResource;
  /**
   * Optional label for the primary resource kind
   */
  primaryResourceKindLabel?: string;
  /**
   * The resource kinds supported in this Filter
   *
   * Should be memoized
   */
  resourceKinds: FilterableResource[];
}

/**
 * Component designed to manage filters for a resource, defined by filter
 * definitions that are generated from annotations on the Resource proto files.
 *
 * @note When only a simple Search Bar is needed, use instead {@see SearchBar}
 * @note For finer control over the filter fields, use instead {@see FilterBar}
 */
export const FilterBuilder = ({
  defaultSearchFilter,
  definitions,
  filters = [],
  onChange,
  primaryResourceKind,
  primaryResourceKindLabel,
  resourceKinds,
}: FilterBuilderProps) => {
  const [mode, setMode] = useState<'search' | 'filter'>('search');

  const [selectedResourceKind, setSelectedResourceKind] =
    useState<FilterableResource>();
  const [filterPartial, setSelectedFilterPartial] = useState<
    ResourceFilterDefinition & Partial<ResourceFilter>
  >();

  const handleCreateFilter = (filter: Partial<ResourceFilter>) => {
    // force the typing
    const newFilter = { ...filterPartial, ...filter } as ResourceFilter;
    onChange(filters.concat(newFilter));

    handleReset();
  };

  const handleReset = () => {
    setSelectedResourceKind(undefined);
    setSelectedFilterPartial(undefined);
    setMode('search');
  };

  const handleDeleteFilter = (filter: ResourceFilter) => {
    const without = filters.filter((f) => f !== filter);
    onChange(without);
  };

  const handleEditFilter = (filter: ResourceFilter) => {
    // remove the filter from the list while editing
    const without = filters.filter((f) => f !== filter);
    onChange(without);

    // use the existing filter as input to the filter form
    setSelectedFilterPartial(filter);
    setMode('filter');
  };

  const handleSelectResourceFilter = (resourceKind: FilterableResource) => {
    // restrict the available filter definitions to those that match the given kind
    setSelectedResourceKind(resourceKind);
    setMode('filter');
  };

  const handleDefaultSearch = (searchValue: string) => {
    if (searchValue && defaultSearchFilter) {
      onChange(
        filters.concat({
          ...defaultSearchFilter,
          value: searchValue,
        } as ResourceFilter)
      );
    }
  };

  return (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Grid container alignItems="start" spacing={4}>
        <Grid item>
          <Box sx={{ paddingTop: 1 }}>
            <IconSearch
              fontSize="medium"
              sx={{
                color: (theme) =>
                  lighten(alpha(theme.palette.text.secondary, 1), 0.74),
              }}
            />
          </Box>
        </Grid>

        <Grid item flexGrow={1}>
          <Stack spacing={2}>
            <ActiveFilterList
              filters={filters}
              onDelete={handleDeleteFilter}
              onEdit={handleEditFilter}
              primaryResourceKind={primaryResourceKind}
              primaryResourceKindLabel={primaryResourceKindLabel}
            />

            <Box
              sx={{
                // fixed height, to prevent jump in height from filter bar
                height: ({ spacing }) => spacing(8),
                justifyContent: 'center',
                display: 'inline-flex',
                flexDirection: 'column',
              }}
            >
              {mode === 'search' && (
                <FormDefaultSearch
                  primaryResourceKind={primaryResourceKind}
                  primaryResourceKindLabel={primaryResourceKindLabel}
                  onChange={handleDefaultSearch}
                />
              )}
              {mode === 'filter' &&
                (filterPartial ? (
                  <FormNewFilter
                    filter={filterPartial}
                    onSubmit={handleCreateFilter}
                    onCancel={handleReset}
                  />
                ) : (
                  <ControlSelectFilterDefinition
                    options={definitions}
                    onSelect={(definition) =>
                      setSelectedFilterPartial(definition)
                    }
                    onCancel={handleReset}
                    primaryResourceKind={primaryResourceKind}
                    primaryResourceKindLabel={primaryResourceKindLabel}
                    resourceKinds={resourceKinds}
                    selectedResourceKind={selectedResourceKind}
                  />
                ))}
            </Box>
          </Stack>
        </Grid>

        {/* Show Add Filter option when in search mode */}
        {mode === 'search' && (
          <Grid item>
            <Stack direction="row" spacing={2}>
              <AddResourceFilterButton
                resourceKinds={resourceKinds}
                onSelect={handleSelectResourceFilter}
                primaryResourceKindLabel={primaryResourceKindLabel}
              />

              {/* TODO: add, load, save, copy
                <IconButton size="small" color="primary">
                  <IconFolder />
                </IconButton>

                <IconButton size="small" color="primary">
                  <IconClipboard />
                </IconButton>
              */}
            </Stack>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
