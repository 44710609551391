import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { RowSelectionState } from '@tanstack/react-table';
import { isEmpty as _isEmpty } from 'lodash-es';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1Method, V1PlatformSource } from '@endorlabs/api_client';
import { FindingSource } from '@endorlabs/endor-core/Finding';
import {
  useCountProjects,
  useDeleteProject,
  useFeatureFlags,
  useUserPreferences,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  ButtonPrimary,
  EmptyState,
  FlexList,
  FlexListItem,
  IconGalaxy,
  UIFindingUtils,
  useAppNotify,
  useConfirmationDialog,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import {
  FilterBuilder,
  PageHeader,
  PageHeaderCount,
  TagEditorDialog,
  useFilterBuilder,
} from '../../components';
import { buildFindingSourceSelectOptions } from '../../domains/Findings';
import { useOnboardingSteps } from '../../domains/Onboarding';
import { ReturnToOnboarding } from '../../domains/Onboarding/components/ReturnToOnboarding';
import {
  useProjectDetailDrawer,
  useUpdateMultiProjectFields,
} from '../../domains/Projects';
import { useAuthInfo, useAuthTenantInfo } from '../../providers';
import { getProjectPath } from '../../routes';
import { ProjectsTable, ProjectsTableRow } from './ProjectsTable';
import { useProjectsIndexPageData } from './useProjectsIndexPageData';

export const ProjectsIndexPage = () => {
  const { projectFindingSource, setProjectFindingSource } =
    useUserPreferences();
  const isGithubActionsEnabled = useFeatureFlags(
    (s) => s.ENABLE_GITHUB_ACTIONS
  );
  const addAppNotification = useAppNotify();
  const { checkActivePermission, activeNamespace: tenantName } = useAuthInfo();
  const canCreateProject = checkActivePermission(V1Method.Create, 'Project');

  const [findingSource, setFindingSource] = useState(
    projectFindingSource ?? FindingSource.Dependency
  );
  const findingSourceSelectOptions = buildFindingSourceSelectOptions({
    excludeList: isGithubActionsEnabled
      ? [FindingSource.All]
      : [FindingSource.All, FindingSource.GithubAction],
  });

  const { getIsOnboardingComplete } = useOnboardingSteps();

  const navigate = useNavigate();

  const { formatMessage: fm } = useIntl();
  const { DetailDrawer, permalinkEffect } = useProjectDetailDrawer();

  const { filters, clearFilters, getFilterBuilderProps, filterExpressionMap } =
    useFilterBuilder({
      include: [
        'Project',
        'Repository',
        'RepositoryVersion',
        'DependencyMetadata',
        'Finding',
        'Metric',
      ],
      exclude: [
        {
          // NOTE: hiding SBOM specific filters from the projects page
          kind: 'Project',
          key: 'spec.sbom.main_component_purl',
        },
      ],
    });

  // NOTE: base filter to exclude projects created from SBOM imports
  const baseProjectFilterExpression = `spec.platform_source != ${V1PlatformSource.Unspecified}`;

  // get TOTAL count of projects
  const qCountProjectsTotal = useCountProjects(
    tenantName,
    {
      staleTime: Infinity,
    },
    { filter: baseProjectFilterExpression }
  );

  const paginator = useDataTablePaginator({
    isInfinite: true,
    hasNextPage: () => !!nextPageToken,
  });

  // Reset pagination on filter or tenant change.
  // NOTE: with infinite pagination, the paginator is not reset on the total
  // count change when filters are applied
  useEffect(
    () => {
      paginator.resetPagination();
    },
    // ignore changes from paginator outside of the reset handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, paginator.resetPagination, tenantName]
  );

  // build up project query filters
  const projectQueryFilters = useMemo(() => {
    const filterCopy = new Map(filterExpressionMap);

    if (filterCopy.has('Project')) {
      const projectFilterExpression = `${baseProjectFilterExpression} and (${filterCopy.get(
        'Project'
      )})`;
      filterCopy.set('Project', projectFilterExpression);
    } else {
      filterCopy.set('Project', baseProjectFilterExpression);
    }

    const queryFilters = Array.from(filterCopy.entries()).map(
      ([kind, filter]) => ({
        kind,
        filter,
      })
    );

    return queryFilters;
  }, [baseProjectFilterExpression, filterExpressionMap]);

  const projectFindingsFilter =
    UIFindingUtils.getFindingFilterExpression(findingSource);

  const {
    isLoading,
    isLoadingFindings,
    isLoadingRelated,
    isError,
    projects,
    nextPageToken,
    projectsWithoutReferences,
    refetch: refetchProjects,
  } = useProjectsIndexPageData({
    namespace: tenantName,
    projectFindingsFilter,
    projectQueryFilters,
    paginator,
  });

  const newProjectPath = getProjectPath({ tenantName, uuid: 'new' });
  const handleNavClick = () => {
    navigate({ to: newProjectPath });
  };

  const [returnAlertOpen, setReturnAlertOpen] = useState(false);

  const [projectToDelete, setProjectToDelete] =
    useState<ProjectsTableRow | null>(null);

  const [selectedRows, setSelectedRows] = useState({});
  const [tagEditorOpen, setTagEditorOpen] = useState(false);

  const tableRef = useRef<any>(null);

  const qProjectDelete = useDeleteProject({
    onSuccess: () => {
      // repopulate list of projects after delete
      refetchProjects();

      setProjectToDelete(null);

      addAppNotification({
        id: 'project:delete:success',
        message: <FM defaultMessage="Project Deleted" />,
        severity: 'success',
      });
    },
  });

  const ProjectDeleteConfirmation = useConfirmationDialog<ProjectsTableRow>({
    cancelText: <FM defaultMessage="Cancel" />,
    confirmText: <FM defaultMessage="Delete" />,
    descriptionText: projectToDelete ? (
      <FM
        defaultMessage="The Project <code>{name}</code> and related resources will be deleted. Are you sure you want to proceed?"
        values={{
          name: projectToDelete.name,
          // styled `code` element
          // forces content to wrap
          code: (value) => (
            <Typography
              component="code"
              variant="inherit"
              sx={{ fontWeight: 'bold', lineBreak: 'anywhere' }}
            >
              {value}
            </Typography>
          ),
        }}
      />
    ) : (
      <FM defaultMessage="The Project and related resources will be deleted. Are you sure you want to proceed?" />
    ),
    isDestructive: true,
    onConfirm: (row) =>
      row && qProjectDelete.mutate({ namespace: tenantName, uuid: row.uuid }),
    titleText: <FM defaultMessage="Delete this Project" />,
  });

  const handleFindingSourceChange = useCallback(
    (value: FindingSource) => {
      setFindingSource(value);

      // persist the selected finding source
      setProjectFindingSource(value);
    },
    [setProjectFindingSource]
  );

  const handleDelete = useCallback(
    (event: SyntheticEvent, row: ProjectsTableRow) => {
      setProjectToDelete(row);
      ProjectDeleteConfirmation.openDialog(row);
    },
    [ProjectDeleteConfirmation]
  );

  const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(rowSelection);
  }, []);

  // Handle error and empty states
  const isEmptyState =
    !qCountProjectsTotal.isError &&
    !qCountProjectsTotal.isLoading &&
    qCountProjectsTotal.data?.count === 0;

  const selectedProjects = useMemo(() => {
    const selectedProjectRows = Object.keys(selectedRows).map(
      (rowNumber) => projects[Number(rowNumber)]?.project
    );
    return selectedProjectRows;
  }, [selectedRows, projects]);

  const onLabelUpdateSuccess = () => {
    setTagEditorOpen(false);
    refetchProjects();
  };

  const { handleUpdateLabels, qUpdateManyProjects } =
    useUpdateMultiProjectFields(
      tenantName,
      selectedProjects,
      onLabelUpdateSuccess
    );

  const { isSharedTenant } = useAuthTenantInfo(tenantName);

  useEffect(() => {
    /**
     * Clear row selection when data changes, since rows selection is
     * index based and the count and positions can change on refetch.
     */
    tableRef.current?.resetRowSelection(true);
  }, [projects]);

  useEffect(() => {
    const completed = getIsOnboardingComplete();
    if (!completed && isSharedTenant) {
      setReturnAlertOpen(true);
    }
  }, [getIsOnboardingComplete, isSharedTenant]);

  useEffect(
    () => permalinkEffect(projectsWithoutReferences),
    [projectsWithoutReferences, permalinkEffect]
  );

  return (
    <>
      <Grid container direction="column" flexWrap="nowrap" spacing={6}>
        <Grid item>
          <PageHeader
            action={
              !isEmptyState &&
              canCreateProject && (
                <Grid alignItems="center" container justifyContent="flex-end">
                  <Grid item>
                    <ButtonPrimary
                      onClick={handleNavClick}
                      data-testid="add-project"
                    >
                      <FM defaultMessage="Add Project" />
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              )
            }
            Icon={IconGalaxy}
            isLoading={qCountProjectsTotal.isLoading}
            title={<FM defaultMessage="All Projects" />}
            titleDetails={
              <PageHeaderCount value={qCountProjectsTotal.data?.count} />
            }
          />
        </Grid>

        {!isEmptyState && (
          <Grid item>
            <FilterBuilder {...getFilterBuilderProps()} />
          </Grid>
        )}

        {/* Handle Error State */}
        {isError && (
          <Grid item>
            <EmptyState
              title={<FM defaultMessage="Failed to load Projects" />}
              description={
                <FM defaultMessage="The request to load the Projects failed to complete. Please remove filters or try again." />
              }
            >
              <Stack direction="row" spacing={4}>
                {!!filterExpressionMap.size && (
                  <ButtonPrimary onClick={clearFilters}>
                    <FM defaultMessage="Clear Filters" />
                  </ButtonPrimary>
                )}

                <ButtonPrimary onClick={() => refetchProjects()}>
                  <FM defaultMessage="Try Again" />
                </ButtonPrimary>
              </Stack>
            </EmptyState>
          </Grid>
        )}

        {/* Handle Empty State */}
        {isEmptyState && (
          <Grid item>
            <EmptyState
              size="large"
              title={
                <FM defaultMessage="You have not added any projects yet" />
              }
              description={
                <FM defaultMessage="Projects are how you monitor the health of your code, one repository at a time." />
              }
            >
              <ButtonPrimary onClick={handleNavClick} data-testid="add-project">
                <FM defaultMessage="Add Project" />
              </ButtonPrimary>
            </EmptyState>
          </Grid>
        )}

        {!isEmptyState && !isError && (
          <Grid item id="project-container">
            <Card>
              <CardContent>
                <ProjectsTable
                  ref={tableRef}
                  isLoading={isLoading}
                  isLoadingRelated={isLoadingRelated}
                  data={projects}
                  enablePagination
                  enableRowSelection
                  title={
                    !_isEmpty(selectedRows) ? (
                      <FM
                        defaultMessage="{selectedCount} of {totalCount} {totalCount, plural, one {Project} other {Projects}} selected"
                        values={{
                          selectedCount: Object.keys(selectedRows).length,
                          totalCount: projects.length,
                        }}
                      />
                    ) : undefined
                  }
                  actions={
                    <ButtonPrimary
                      onClick={() => {
                        setTagEditorOpen(true);
                      }}
                      disabled={_isEmpty(selectedRows)}
                    >
                      <FM defaultMessage="Edit Tags" />
                    </ButtonPrimary>
                  }
                  paginator={paginator}
                  emptyStateProps={{
                    title: <FM defaultMessage="No Project results found" />,
                    children: (
                      // Handle possible causes of empty states
                      <FlexList
                        divider={
                          <Typography color="text.secondary">
                            <FM defaultMessage="or" />
                          </Typography>
                        }
                        justifyContent="center"
                      >
                        {filters.length > 0 && (
                          <FlexListItem>
                            <ButtonCancel onClick={clearFilters}>
                              <FM defaultMessage="Clear Filters" />
                            </ButtonCancel>
                          </FlexListItem>
                        )}
                        {paginator.state.pageIndex > 0 && (
                          <FlexListItem>
                            <ButtonCancel
                              onClick={() => paginator.resetPagination()}
                            >
                              <FM defaultMessage="Go to First Page of Results" />
                            </ButtonCancel>
                          </FlexListItem>
                        )}
                      </FlexList>
                    ),
                  }}
                  onClickDetail={(row) =>
                    row &&
                    DetailDrawer.activate(
                      {
                        projectNamespace: row.namespace,
                        projectUuid: row.uuid,
                      },
                      {
                        namespace: row.namespace,
                        uuid: row.uuid,
                      }
                    )
                  }
                  onDelete={handleDelete}
                  onRowSelectionChange={handleRowSelection}
                  findingSourceProps={{
                    onChange: handleFindingSourceChange,
                    options: findingSourceSelectOptions,
                    value: findingSource,
                  }}
                  isLoadingFindings={isLoadingFindings}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      <ReturnToOnboarding
        isOpen={returnAlertOpen}
        closeCallback={() => setReturnAlertOpen(false)}
      />

      <ProjectDeleteConfirmation.Dialog
        {...ProjectDeleteConfirmation.dialogProps}
      />
      <TagEditorDialog
        open={tagEditorOpen}
        onClose={() => {
          setTagEditorOpen(false);
        }}
        isLoading={qUpdateManyProjects.isLoading}
        resources={selectedProjects}
        onSubmit={handleUpdateLabels}
        placeholderText={fm(
          {
            defaultMessage:
              'What tags do you want to assign {selectedCount, plural, one {this Project} other {these Projects}}?',
          },
          {
            selectedCount: Object.keys(selectedRows).length,
          }
        )}
        titleText={
          <FM
            defaultMessage="Edit Tags: {selectedCount} {selectedCount, plural, one {Project} other {Projects}}"
            values={{ selectedCount: selectedProjects?.length ?? 0 }}
          />
        }
      />
    </>
  );
};
