import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ColumnOrderState,
  RowData,
  VisibilityState,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { IconMenu, IconSettings } from '../../themes';
import { ButtonCancel, ButtonSecondary } from '../Button';
import { ReorderableList, ReorderableListItem } from '../ReorderableList';
import { DataTableColumnPreferences } from './hooks/useDataTablePreferences';
import { DataTableColumnDef } from './types';

export interface DataTableColumnSettingsProps<T> {
  columns: DataTableColumnDef<T>[];
  columnVisibility?: VisibilityState;
  hideableColumnOrder?: ColumnOrderState;
  reorderableColumnOrder?: ColumnOrderState;
  reorderableColumnHeading?: string;
  saveColumnState: (update: DataTableColumnPreferences) => void;
}

const REORDERABLE_ITEM_TYPE = 'data-table-column';

export const DataTableColumnSettings = <T extends RowData>({
  columns,
  columnVisibility,
  hideableColumnOrder,
  reorderableColumnOrder,
  reorderableColumnHeading,
  saveColumnState,
}: DataTableColumnSettingsProps<T>) => {
  const { palette, space, spacing } = useTheme();
  const [columnOrderState, setColumnOrderState] = useState(
    reorderableColumnOrder
  );
  const [visibilityState, setVisibilityState] = useState(columnVisibility);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const [isAllVisible, setIsAllVisible] = useState(true);

  useEffect(() => {
    if (columnVisibility) {
      const hasHiddenColumns = Object.values(columnVisibility).some(
        (col) => !col
      );
      setIsAllVisible(!hasHiddenColumns);
    }
  }, [columnVisibility]);

  useEffect(() => {
    setColumnOrderState(reorderableColumnOrder);
    setVisibilityState(columnVisibility);
  }, [reorderableColumnOrder, columnVisibility]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReorder = (dragIndex: number, hoverIndex: number) => {
    const newColumnOrder = [...(columnOrderState ?? [])];
    const [draggedItem] = newColumnOrder.splice(dragIndex, 1);
    newColumnOrder.splice(hoverIndex, 0, draggedItem);
    setColumnOrderState(newColumnOrder);
  };

  const handleVisibility = (col: string) => {
    const newVisibilityState = { ...visibilityState };
    newVisibilityState[col] = !newVisibilityState[col];
    setVisibilityState(newVisibilityState);
  };

  const handleCancel = () => {
    // Reset state to props on cancel
    setColumnOrderState(reorderableColumnOrder);
    setVisibilityState(columnVisibility);
    handleClose();
  };

  const handleSave = () => {
    saveColumnState({
      columnOrder: columnOrderState ?? [],
      columnVisibility: visibilityState ?? {},
    });
    handleClose();
  };

  const getColumnName = (id: string) => {
    const col = columns.find((c) => c.id === id || c.accessorKey === id);
    return col?.displayName ?? id;
  };

  const toggleAllVisibility = () => {
    const newVisibilityState = [
      ...(hideableColumnOrder ?? []),
      ...(reorderableColumnOrder ?? []),
    ].reduce((acc, col) => {
      acc[col] = !isAllVisible;
      return acc;
    }, {} as VisibilityState);

    setVisibilityState(newVisibilityState);
    setIsAllVisible(!isAllVisible);
  };

  return (
    <Box>
      <IconButton onClick={handleOpen} size="medium">
        <IconSettings fontSize="inherit" />
      </IconButton>
      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{
          zIndex: (theme) => theme.zIndex.tooltip,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              border: 'none',
              boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.1)`,
              '& .MuiListItemIcon-root': {
                minWidth: 32,
              },
            }}
            position="relative"
          >
            <Button
              onClick={toggleAllVisibility}
              sx={{ marginLeft: spacing(12), marginTop: space.sm }}
            >
              {isAllVisible ? (
                <FM defaultMessage="Clear All" />
              ) : (
                <FM defaultMessage="Select All" />
              )}
            </Button>

            {hideableColumnOrder && hideableColumnOrder.length > 0 && (
              <List>
                {hideableColumnOrder?.map((column) => (
                  <ListItem key={column} disablePadding>
                    <ListItemButton
                      sx={{
                        paddingX: space.sm,
                        paddingY: space.xs,
                      }}
                      alignItems="center"
                    >
                      <ListItemIcon
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <IconMenu sx={{ visibility: 'hidden' }} />
                      </ListItemIcon>
                      <ListItemText>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={visibilityState?.[column]}
                              tabIndex={-1}
                              disableRipple
                              onClick={() => handleVisibility(column)}
                            />
                          }
                          label={getColumnName(column)}
                        />
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
            {reorderableColumnHeading && (
              <Typography
                paddingX={space.sm}
                variant="code"
                color={palette.text.secondary}
                textTransform="uppercase"
              >
                {reorderableColumnHeading}
              </Typography>
            )}
            {reorderableColumnOrder && reorderableColumnOrder.length > 0 && (
              <ReorderableList>
                {columnOrderState?.map((column, index) => (
                  <ReorderableListItem
                    key={column}
                    id={column}
                    index={index}
                    reorderFn={handleReorder}
                    type={REORDERABLE_ITEM_TYPE}
                  >
                    <ListItemIcon
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <IconMenu sx={{ '&:hover': { cursor: 'move' } }} />
                    </ListItemIcon>
                    <ListItemText>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={visibilityState?.[column]}
                            tabIndex={-1}
                            disableRipple
                            onClick={() => handleVisibility(column)}
                          />
                        }
                        label={getColumnName(column)}
                      />
                    </ListItemText>
                  </ReorderableListItem>
                ))}
              </ReorderableList>
            )}
            <Stack direction="row" sx={{ padding: space.sm }} gap={space.sm}>
              <ButtonSecondary onClick={handleSave}>
                <FM defaultMessage="Save" />
              </ButtonSecondary>
              <ButtonCancel onClick={handleCancel}>
                <FM defaultMessage="Cancel" />
              </ButtonCancel>
            </Stack>
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};
