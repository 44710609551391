import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { RowSelectionState, Table } from '@tanstack/react-table';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { useFilterSearchParams } from '@endorlabs/filters';
import { PackageVersionResource } from '@endorlabs/queries';
import {
  ButtonCancel,
  EmptyState,
  SearchBar,
  usePackageVersionDependencyScoreMetrics,
} from '@endorlabs/ui-common';
import { useTanstackTableRef } from '@endorlabs/ui-common';
import { usePackageVersionDependencies } from '@endorlabs/ui-common/domains/PackageVersion';

import {
  DependenciesTable,
  DependenciesTableColumnId,
  DependenciesTableHeader,
  DependenciesTableRow,
  useDependencyDetailDrawer,
} from '../../domains/Dependencies';

const DEFAULT_INCLUDE_COLUMNS: DependenciesTableColumnId[] = [
  'hasApproximation',
  'isDirectDependency',
  'reachability',
  'isPublic',
  'sourceCode',
  'unresolvedVersionRefs',
];

export interface PackageVersionDetailDependenciesProps {
  dependenciesTableincludeColumns?: DependenciesTableColumnId[];
  tenantName: string;
  /**
   * The currently viewed package version
   */
  packageVersion?: PackageVersionResource;
}

export const PackageVersionDetailDependencies = ({
  dependenciesTableincludeColumns = DEFAULT_INCLUDE_COLUMNS,
  tenantName,
  packageVersion,
}: PackageVersionDetailDependenciesProps) => {
  const { formatMessage: fm } = useIntl();
  const {
    filterDefaultSearchParams: searchValue,
    updateFilterDefaultSearchParams: handleSearchChange,
  } = useFilterSearchParams();
  const { DetailDrawer, permalinkEffect } = useDependencyDetailDrawer();

  const [selectedRows, setSelectedRows] = useState({});
  const tableRef = useTanstackTableRef<DependenciesTableRow>();

  const qPackageVersionDependenciesTotal = usePackageVersionDependencies(
    tenantName,
    packageVersion
  );

  const qPackageVersionDependencies = usePackageVersionDependencies(
    tenantName,
    packageVersion,
    searchValue
  );

  const qPackageVersionScoreMetrics = usePackageVersionDependencyScoreMetrics(
    tenantName,
    qPackageVersionDependencies.dependencies
  );

  // merge dependencies with score metrics
  const [dependencyPackageVersions, hasApproximateDependencies] =
    useMemo(() => {
      let hasApproximateDependencies = false;
      const dependencyPackageVersions =
        qPackageVersionDependencies.dependencies.map((dep) => {
          if (dep.hasApproximation) {
            hasApproximateDependencies = true;
          }
          // find the related score metrics
          const scoreMetrics = dep.uuid
            ? qPackageVersionScoreMetrics.scoreMetrics[dep.uuid]
            : undefined;

          return { id: dep.uuid, ...dep, ...scoreMetrics };
        });

      return [dependencyPackageVersions, hasApproximateDependencies];
    }, [
      qPackageVersionDependencies.dependencies,
      qPackageVersionScoreMetrics.scoreMetrics,
    ]);

  const handleClickDetail = useCallback(
    (row?: DependenciesTableRow) => {
      // TODO: handle action for fallback row
      if (!row?.name) return;

      DetailDrawer.activate(
        {
          name: row.name,
          namespace: row.namespace,
        },
        {
          importingNamespace: tenantName,
          importingPackageVersion: packageVersion,
          name: row.name,
          namespace: row.namespace,
          uuid: row.uuid,
        }
      );
    },
    [DetailDrawer, packageVersion, tenantName]
  );

  useEffect(() => {
    if (!DetailDrawer.isOpen && Object.keys(selectedRows).length > 0) {
      if (tableRef.current) {
        const currentTable = tableRef.current as Table<DependenciesTableRow>;
        currentTable.resetRowSelection();
        setSelectedRows({});
      }
    }
  }, [DetailDrawer, selectedRows, tableRef]);

  const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(rowSelection);
  }, []);

  const handleClearSearch = useCallback(() => {
    handleSearchChange('');
  }, [handleSearchChange]);

  // on data change, if the target item is available, open the drawer
  useLayoutEffect(
    () =>
      permalinkEffect({
        dependencies: dependencyPackageVersions,
        importingNamespace: tenantName,
        importingPackageVersion: packageVersion,
      }),
    [permalinkEffect, dependencyPackageVersions, packageVersion, tenantName]
  );

  // calc page state
  const isLoading =
    qPackageVersionDependencies.isLoading ||
    qPackageVersionScoreMetrics.isLoading;
  const isEmptyState =
    !isLoading &&
    qPackageVersionDependencies.dependencies.length === 0 &&
    !searchValue;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="There are no dependencies in this package version" />
            }
            description={
              <FM defaultMessage="As dependencies are added to a package, those dependencies will appear here." />
            }
          />
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <SearchBar
            onSearch={handleSearchChange}
            placeholder={fm({ defaultMessage: 'Search for Dependencies' })}
            searchValue={searchValue}
          />
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <Card>
            <CardHeader
              title={
                <DependenciesTableHeader
                  isLoading={isLoading}
                  hasApproximateDependencies={hasApproximateDependencies}
                  totalCount={
                    qPackageVersionDependenciesTotal.dependencies.length
                  }
                  filteredCount={
                    qPackageVersionDependencies.dependencies.length
                  }
                  resourceKind="Package"
                />
              }
            />
            <CardContent>
              <DependenciesTable
                isLoading={isLoading}
                data={dependencyPackageVersions}
                ref={tableRef}
                enableRowSelection
                enableColumnSort
                includeColumns={dependenciesTableincludeColumns}
                enablePagination
                showVersion
                onClickDetail={handleClickDetail}
                emptyStateProps={{
                  title: (
                    <FM defaultMessage="No Dependencies match the filter criteria" />
                  ),
                  children: (
                    <ButtonCancel onClick={handleClearSearch}>
                      <FM defaultMessage="Clear Search" />
                    </ButtonCancel>
                  ),
                }}
                onRowSelectionChange={handleRowSelection}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
