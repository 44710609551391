import _merge from 'lodash-es/merge';

const brandColors = {
  dark: 'hsla(180, 98%, 12%, 1)',
  main: 'hsla(177, 100%, 17%, 1)', // '#005652'
  light: 'hsla(150, 100%, 47%, 1)', // '#00f078'
  contrastText: '#fff',
};

const defaultPalette = {
  background: {
    default: 'hsla(216, 45%, 98%, 1)', // #f7f9fc
    // default: 'hsla(160, 20%, 97%, 1)',
    paper: '#fff',
    dark: 'hsla(0, 0%, 0%, 0.87)',
  },

  primary: brandColors,

  secondary: {
    main: 'hsla(216, 67%, 57%, 1)', // #4782da
    contrastText: '#fff',
  },

  brand: brandColors,

  data: {
    blue: 'hsla(202, 100%, 71%, 1)', // #6AC9FF
    // purple: 'hsla(291, 82%, 65%, 1)', // #DA5EEF
    purple: 'hsla(269, 67%, 48%, 1)', // #7729cc
    yellow: 'hsla(47, 100%, 50%, 1)', // #FFC700
  },

  priority: {
    prioritize: 'hsla(206, 99%, 31%, 1)', // #01579b
    plan: 'hsla(201, 98%, 41%, 1)', // #0288d1
    backlog: 'hsla(199, 92%, 56%, 1)', // #29b6f6
    defer: 'hsla(199, 92%, 74%, 1)', // #81d4fa
  },

  severity: {
    critical: 'hsla(346, 60%, 35%, 1)', // #8F2406
    high: 'hsla(357, 76%, 49%, 1)', // #DC1E27
    medium: 'hsla(25, 100%, 62%, 1)', // #FF8E3D
    low: 'hsla(47, 100%, 50%, 1)', // #FFC800
  },

  status: {
    failure: 'hsla(4, 96%, 46%, 1)', // #E41405
    partialSuccess: 'hsla(47, 86%, 49%, 1)', // #EABA12
    pending: 'hsla(0, 0%, 88%, 1)', // #E0E0E0
    success: 'hsla(76, 89%, 41%, 1)', // #96C70C
  },

  scores: {
    average: 'hsla(47, 86%, 49%, 1)', // #EABA12
    bad: 'hsla(4, 96%, 46%, 1)', // #E41405
    good: 'hsla(76, 89%, 41%, 1)', // #EABA12
    unknown: 'hsla(0, 0%, 88%, 1)', // #E0E0E0
  },

  text: {
    link: 'hsla(212, 80%, 42%, 1)', // #1565C0
    primary: 'hsla(0, 0%, 12%, 1)', // #1F1F1F
    secondary: 'hsla(0, 0%, 44%, 1)', // #6F6F6F
    tertiary: 'hsla(36, 2%, 43%, 1)', // #706e6b
    light: 'hsla(0, 0%, 88%, 1)', // #E0E0E0
  },
};

/**
 * Dark mode overrides
 */
const darkPalette = {
  background: {
    default: 'hsla(215, 33%, 16%, 1)', // #1B2635
    paper: 'hsla(216, 32%, 20%, 1)', // #233044
  },

  text: {
    primary: 'hsla(0, 100%, 100%, 0.95)',
    secondary: 'hsla(0, 100%, 100%, 0.5)',
  },
};

export const getPalette = (paletteMode: 'light' | 'dark' = 'light') => {
  // Add a simple boolean for dark mode so we don't have to constantly evaluate the `mode` string
  const darkMode = paletteMode === 'dark';

  // Determine final palette
  const palette = darkMode
    ? _merge(defaultPalette, darkPalette)
    : defaultPalette;

  // return createPalette(_merge(palette, { darkMode }));
  return _merge(palette, { darkMode });
};
