import { remove as _remove } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';
import { LinkTabProps } from '@endorlabs/ui-common';

interface RemediationDetailTabRecord extends LinkTabProps {
  value: (typeof RemediationDetailTabNames)[keyof typeof RemediationDetailTabNames];
}

export const RemediationDetailTabNames = {
  FIXED_FINDINGS: 'fixed-findings',
  CONFLICTS: 'conflicts',
  BREAKING_CHANGES: 'breaking-changes',
} as const;

const buildTabRecords = (props: {
  fixedFindingsCount: number;
  conflictsCount: number;
  breakingChangesCount: number;
}) => {
  const tabRecords: RemediationDetailTabRecord[] = [
    {
      label: <FM defaultMessage="Fixed Findings" />,
      to: RemediationDetailTabNames.FIXED_FINDINGS,
      value: RemediationDetailTabNames.FIXED_FINDINGS,
      count: props.fixedFindingsCount,
    },
    {
      label: <FM defaultMessage="Potential Conflicts" />,
      to: RemediationDetailTabNames.CONFLICTS,
      value: RemediationDetailTabNames.CONFLICTS,
      count: props.conflictsCount,
    },
    {
      label: <FM defaultMessage="Breaking Changes" />,
      to: RemediationDetailTabNames.BREAKING_CHANGES,
      value: RemediationDetailTabNames.BREAKING_CHANGES,
      count: props.breakingChangesCount,
    },
  ];

  return tabRecords;
};

export interface UseRemediationDetailTabsProps {
  activeTab?: string;
  upgradeInfo?: V1ReleaseUpgradeInfo;
}

export const useRemediationDetailTabs = ({
  activeTab: targetActiveTab,
  upgradeInfo,
}: UseRemediationDetailTabsProps) => {
  const fixedFindingsCount =
    (upgradeInfo?.vuln_finding_info?.reduction ?? 0) +
    (upgradeInfo?.other_finding_info?.reduction ?? 0);

  const conflictsCount =
    (upgradeInfo?.conflicts ?? 0) + (upgradeInfo?.minor_conflicts ?? 0);

  const breakingChangesCount = upgradeInfo?.cia_results?.length ?? 0;

  const tabRecords = buildTabRecords({
    fixedFindingsCount,
    conflictsCount,
    breakingChangesCount,
  });

  const activeTab =
    tabRecords.find((r) => r.value === targetActiveTab)?.value ??
    RemediationDetailTabNames.FIXED_FINDINGS;

  return { activeTab, tabRecords };
};
