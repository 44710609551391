import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useRouter } from '@tanstack/react-location';
import { useEffect, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import {
  ButtonCancel,
  ButtonPrimary,
  EmptyState,
  FlexList,
  FlexListItem,
  IconRocketLaunchOutlined,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { CiCdToolsTable } from '../../components/CiCdToolsTable';
import {
  useCiCdToolsIndexPageData,
  useCiCdToolsIndexPageFilterFields,
} from '../../domains/CiCdTools/hooks';
import {
  FilterBar,
  useFilterContext,
  withFilterProvider,
} from '../../domains/filters';
import { useAuthInfo, useLicensingInfo } from '../../providers';

export const BaseCiCdToolsIndexPage = () => {
  const { activeNamespace: tenantName, setLicenseInCurrentRoute } =
    useAuthInfo();
  const { formatMessage: fm } = useIntl();
  const router = useRouter();

  const {
    clearFilter,
    filter: filterExpression,
    _state: filterState,
  } = useFilterContext();

  // Whether to hide projects without tools
  const [hideEmptyProjects, setHideEmptyProjects] = useState(true);

  const { baseProjectFilterExpression, filterFields, projectQueryFilters } =
    useCiCdToolsIndexPageFilterFields(
      tenantName,
      filterState,
      hideEmptyProjects
    );

  const paginator = useDataTablePaginator({
    isInfinite: true,
    hasNextPage: () => !!nextPageToken,
  });

  const { cicdTools, projectCount, toolCount, ...qQueryProjectTools } =
    useCiCdToolsIndexPageData({
      namespace: tenantName,
      paginator,
      baseFilter: baseProjectFilterExpression,
      queryFilters: projectQueryFilters,
    });

  const nextPageToken =
    qQueryProjectTools.data?.list?.response?.next_page_token;

  // Reset pagination on filter or tenant change.
  // NOTE: with infinite pagination, the paginator is not reset on the total
  // count change when filters are applied
  useEffect(
    () => {
      paginator.resetPagination();
    },
    // ignore changes from paginator outside of the reset handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginator.resetPagination, projectQueryFilters, tenantName]
  );

  // Handle error, loading, and empty states
  const hasError = qQueryProjectTools.isError;

  const isLoading = qQueryProjectTools.isLoading;

  const isEmptyState = !isLoading && !hasError && projectCount === 0;

  const { checkLicensePresent } = useLicensingInfo();
  const isCICDLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.CicdDiscovery
  );

  useEffect(() => {
    if (!isCICDLicensePresent) {
      setLicenseInCurrentRoute({
        pathname: router.state.location.pathname,
        isLicense: false,
      });
      return () => {
        setLicenseInCurrentRoute({
          pathname: '',
          isLicense: true,
        });
      };
    }
  }, [
    isCICDLicensePresent,
    router.state.location.pathname,
    setLicenseInCurrentRoute,
  ]);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          Icon={IconRocketLaunchOutlined}
          title={<FM defaultMessage="Tools" />}
          titleDetails={
            <Typography variant="h1" fontWeight={400} color="text.secondary">
              <FM
                defaultMessage="{toolCount, number} in use"
                values={{ toolCount }}
              />
            </Typography>
          }
        />
      </Grid>

      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="CI/CD tools will be available to view here." />
            }
            description={
              <FM defaultMessage="As your inventory of CI/CD tools grow, this is where you can easily search across them." />
            }
          ></EmptyState>
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <FilterBar
            fields={filterFields}
            searchPlaceholder={fm({ defaultMessage: 'Search Project Names' })}
          />
        </Grid>
      )}

      {/* Handle Error State */}
      {hasError && (
        <Grid item>
          <EmptyState
            title={<FM defaultMessage="Failed to load CI/CD tools" />}
            description={
              <FM defaultMessage="The request to load CI/CD tools failed to complete. Please remove filters or try again." />
            }
          >
            <Stack direction="row" spacing={4}>
              {filterExpression && (
                <ButtonPrimary onClick={clearFilter}>
                  <FM defaultMessage="Clear Filters" />
                </ButtonPrimary>
              )}

              <ButtonPrimary onClick={() => qQueryProjectTools.refetch()}>
                <FM defaultMessage="Try Again" />
              </ButtonPrimary>
            </Stack>
          </EmptyState>
        </Grid>
      )}
      {!isEmptyState && !hasError && (
        <Grid item>
          <Card>
            <CardContent>
              <CiCdToolsTable
                actions={
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hideEmptyProjects}
                        onChange={(e) => setHideEmptyProjects(e.target.checked)}
                      />
                    }
                    label={<FM defaultMessage="Hide Projects without Tools" />}
                    sx={{ marginRight: 0 }}
                  />
                }
                isLoading={isLoading}
                data={cicdTools}
                emptyStateProps={{
                  title: (
                    <FM defaultMessage="No CI/CD tools match the filter criteria" />
                  ),
                  children: (
                    // Handle possible causes of empty states
                    <FlexList
                      divider={
                        <Typography color="text.secondary">
                          <FM defaultMessage="or" />
                        </Typography>
                      }
                      justifyContent="center"
                    >
                      {filterExpression && (
                        <FlexListItem>
                          <ButtonCancel onClick={clearFilter}>
                            <FM defaultMessage="Clear Filters" />
                          </ButtonCancel>
                        </FlexListItem>
                      )}
                      {paginator.state.pageIndex > 0 && (
                        <FlexListItem>
                          <ButtonCancel
                            onClick={() => paginator.resetPagination()}
                          >
                            <FM defaultMessage="Go to First Page of Results" />
                          </ButtonCancel>
                        </FlexListItem>
                      )}
                    </FlexList>
                  ),
                }}
                enablePagination
                paginator={paginator}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

/**
 * Wire Filter Context to page
 */
export const CiCdToolsIndexPage = withFilterProvider(BaseCiCdToolsIndexPage, {
  displayName: 'CiCdToolsIndexPage',
  searchKeys: ['meta.name'],
});
