import _first from 'lodash-es/first';
import _groupBy from 'lodash-es/groupBy';
import _uniqBy from 'lodash-es/uniqBy';

import { GroupResponseGroupData } from '@endorlabs/api_client';

const DEFAULT_DISPLAY_COUNT = 8;

interface PrimaryRecord {
  uuid: string;
  name: string;
  url?: string;
}

export interface InterimChartData {
  count: number;
  primaryUuid: string;
  map: Record<string, unknown[]>;
}

interface GenerateInterimChartData {
  // The attribute on categoryRecords used to determine individual subcategories
  categoryKeyAttr?: string;
  // Map of all possible categories in the chart: categoryKeyAttr value => translated label
  categoryMessages: Map<string, string>;
  // Records representing the resources that will form the categories within each primary resource group.
  categoryGroupRecords: { groups: GroupResponseGroupData }[];
  // Number of primary records to display
  primaryDisplayCount?: number;
  // The attribute on categoryGroupRecords used to key against uuid of the primary records.
  primaryUuidAttr: string;
}
interface GenBarChartData {
  // Map of all possible categories in the chart: categoryKeyAttr value => translated label
  categoryMessages: Map<string, string>;
  // Records representing the category records
  interimChartData: InterimChartData[];
  // Records representing the resources the chart will be grouped by.
  // E.g. for a "Top Projects" chart, this is a list of Project records.
  primaryRecords: PrimaryRecord[];
}

export const generateInterimChartData = ({
  categoryKeyAttr,
  categoryMessages,
  categoryGroupRecords,
  primaryDisplayCount = DEFAULT_DISPLAY_COUNT,
  primaryUuidAttr,
}: GenerateInterimChartData) => {
  const allPrimaryGroups = _groupBy(categoryGroupRecords, primaryUuidAttr);
  const genericKeyMap = Object.fromEntries(categoryMessages);
  const genericKey = Object.keys(genericKeyMap)[0];

  // Transform into an interim object with only the top N primary records with the highest counts.
  // This prevents further transformations from operating on the entire list.
  const topNGroups = Object.entries(allPrimaryGroups)
    .reduce((acc: InterimChartData[], [primaryUuid, records]) => {
      acc.push({
        count: records.reduce((c: number, record) => {
          return c + (record?.groups?.aggregation_count?.count ?? 0);
        }, 0),

        // If provided a subcategory attribute, group category records by that.
        // Otherwise, ensure all records have a single "subcategory" based on category key.
        map: categoryKeyAttr
          ? _groupBy(records, categoryKeyAttr)
          : _groupBy(
              records.map((r) => ({
                ...r,
                [genericKey]: genericKey,
              })),
              genericKey
            ),
        primaryUuid,
      });

      return acc;
    }, [])
    // TODO: Sort by count, then name. More predictable in testing.
    .sort((a, b) => b.count - a.count)
    .slice(0, primaryDisplayCount);

  return topNGroups;
};

// Stitches interim chart data together with primary records.
// Returns data in form consumable by bar chart visualizations.
export const genBarChartData = ({
  categoryMessages,
  interimChartData = [],
  primaryRecords,
}: GenBarChartData) => {
  const chartData = interimChartData.reduce((arr: unknown[], group) => {
    const primaryRecord = primaryRecords.find(
      (p) => p.uuid === group.primaryUuid
    );

    if (!primaryRecord) return arr;

    const hasSubcategories = categoryMessages.size > 1;
    const countsByTranslatedKeys: Record<string, number> = {};

    // @ts-expect-error - supported by es2015 or higher, but still complains
    for (const [messageKey, translation] of categoryMessages) {
      countsByTranslatedKeys[translation] = hasSubcategories
        ? // @ts-expect-error - complaint about groups
          _first(group.map[messageKey] ?? [])?.groups?.aggregation_count
            ?.count ?? 0
        : group.count;
    }

    return arr.concat([
      {
        name: primaryRecord?.name ?? 'Unknown',
        url: primaryRecord?.url ?? '',
        ...countsByTranslatedKeys,
      },
    ]);
  }, []);

  return _uniqBy(chartData, 'name');
};
