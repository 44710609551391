import {
  defaults as _defaults,
  round as _round,
  sortBy as _sortBy,
} from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { V1FindingTags } from '@endorlabs/api_client';
import { DashboardConfigResource } from '@endorlabs/endor-core/DashboardConfig';
import {
  IQueryError,
  useCreateDashboardConfig,
  useListDashboardConfig,
  useUpdateDashboardConfig,
} from '@endorlabs/queries';
import { WithOptional } from '@endorlabs/utils';

import { VULN_PRIORITIZATION_STAGES } from './useVulnerabilityPrioritization';

export type UseDashboardConfigProps = {
  namespace: string;
};

export type DashboardConfigValue = {
  baselineFindingsFilter: string;
  currencyCode: string;
  devHours: number;
  epssProbabilityThreshold: number;
  hourlyCost: number;
  findingReachabilityTags: Extract<
    V1FindingTags,
    V1FindingTags.ReachableFunction | V1FindingTags.PotentiallyReachableFunction
  >[];
};

/**
 * @private exported for testing only
 */
export const DEFAULT_DASHBOARD_CONFIG_VALUE: DashboardConfigValue = {
  baselineFindingsFilter: VULN_PRIORITIZATION_STAGES[1].key,
  currencyCode: 'USD',
  devHours: 8,
  epssProbabilityThreshold: 1.0,
  findingReachabilityTags: [V1FindingTags.ReachableFunction],
  hourlyCost: 70,
};

const toDashboardConfigValue = (
  resource?: DashboardConfigResource
): DashboardConfigValue => {
  const { dev_estimate, vuln_prioritization } = resource?.spec ?? {};

  const hasKnownBaseline =
    dev_estimate?.baseline_findings_filter &&
    VULN_PRIORITIZATION_STAGES.some(
      (s) => s.key === dev_estimate.baseline_findings_filter
    );

  return _defaults(
    {
      baselineFindingsFilter: hasKnownBaseline
        ? dev_estimate.baseline_findings_filter
        : undefined,
      currencyCode: dev_estimate?.currency_code,
      devHours: dev_estimate?.dev_hours,
      epssProbabilityThreshold:
        vuln_prioritization?.epss_probability_score_threshold !== undefined
          ? _round(
              vuln_prioritization.epss_probability_score_threshold * 100,
              4
            )
          : undefined,
      findingReachabilityTags: vuln_prioritization?.finding_reachability_tags
        ?.length
        ? vuln_prioritization.finding_reachability_tags
        : undefined,
      hourlyCost: dev_estimate?.hourly_cost,
    },
    DEFAULT_DASHBOARD_CONFIG_VALUE
  );
};

const fromDashboardConfigValue = <
  T extends WithOptional<DashboardConfigResource, 'spec' | 'uuid'>
>(
  config: DashboardConfigValue,
  base: T
): T => {
  const spec: DashboardConfigResource['spec'] = {
    dev_estimate: {
      baseline_findings_filter: config.baselineFindingsFilter,
      currency_code: config.currencyCode,
      dev_hours: config.devHours,
      hourly_cost: config.hourlyCost,
    },
    vuln_prioritization: {
      epss_probability_score_threshold: config.epssProbabilityThreshold / 100,
      finding_reachability_tags: config.findingReachabilityTags,
    },
  };

  return {
    ...base,
    spec: { ...base.spec, ...spec },
  };
};

/**
 * Wrapper around the DashboardConfigResource for a given namespace
 *
 * - Manages automatic create/update for a config
 * - Manages default values for the config
 */
export const useDashboardConfigValue = ({
  namespace,
}: UseDashboardConfigProps) => {
  const qListDashboardConfig = useListDashboardConfig(namespace, {
    traverse: false, // Prevent loading config for child namespaces
  });

  const qCreateDashboardConfig = useCreateDashboardConfig();
  const qUpdateDashboardConfig = useUpdateDashboardConfig();

  const isLoading =
    qListDashboardConfig.isLoading ||
    qCreateDashboardConfig.isLoading ||
    qUpdateDashboardConfig.isLoading;

  const configResource = useMemo(() => {
    // For configs loaded from the current namespace or parent, get the most specific
    return _sortBy(
      qListDashboardConfig.data?.objects ?? [],
      (o) => o.tenant_meta.namespace
    ).reverse()[0];
  }, [qListDashboardConfig.data]);

  const config = useMemo(
    () => toDashboardConfigValue(configResource),
    [configResource]
  );

  const update = useCallback(
    (
      config: DashboardConfigValue,
      options?: {
        onSuccess?: () => void;
        onError?: (error: IQueryError) => void;
      }
    ) => {
      const configResourceNamespace = configResource?.tenant_meta.namespace;

      // If the config resource is for the active namespace, update
      if (configResource && configResourceNamespace === namespace) {
        qUpdateDashboardConfig.mutate(
          {
            namespace: configResourceNamespace,
            resource: fromDashboardConfigValue(config, configResource),
          },
          options
        );
        return;
      }

      // Otherwise, create a new config in the active namespace
      qCreateDashboardConfig.mutate(
        {
          namespace,
          resource: fromDashboardConfigValue(config, {
            meta: { name: `Dashboard Config: ${namespace}` },
            propagate: true,
            spec: {},
            tenant_meta: { namespace },
          }),
        },
        options
      );
    },
    [configResource, namespace, qCreateDashboardConfig, qUpdateDashboardConfig]
  );

  return {
    config,
    update,
    isLoading,
  };
};
