import {
  QueryKey,
  QueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import {
  ProjectServiceApi,
  V1CountResponse,
  V1ListParameters,
  V1Project,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListAllRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  ProjectResource,
  ProjectResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
  listAllResource,
} from './utils';

interface ProjectReadParams {
  namespace: string;
  uuid: string;
}

export interface ProjectWriteParams {
  namespace: string;
  resource: V1Project;
}

export interface ProjectUpdateParams extends ProjectWriteParams {
  mask?: string;
}

export interface ProjectWriteManyParams {
  namespace: string;
  resources: V1Project[];
}

export interface ProjectUpdateManyParams extends ProjectWriteManyParams {
  mask?: string;
}

type CountProjectOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListProjectOptions = ResourceQueryOptions<ProjectResourceList>;
type ListAllProjectOptions = ResourceQueryOptions<ProjectResource[]>;
type GetProjectOptions = ResourceQueryOptions<ProjectResource>;
type UpsertProjectOptions = ResourceMutateOptions<
  V1Project,
  ProjectWriteParams
>;
type UpsertManyProjectOptions = ResourceMutateOptions<
  V1Project[],
  ProjectWriteManyParams
>;
type DeleteProjectOptions = ResourceMutateOptions<object, ProjectReadParams>;

const BASE_KEY = 'v1/projects';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  listAll: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list-all', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const ProjectQueryKeys = QK;

export const PROJECT_UPDATE_MASK = 'meta,spec';

const getApiService = () => new ProjectServiceApi(getClientConfiguration());

const countProjects = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.projectServiceListProjects(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountProjects = (
  namespace: string,
  opts: CountProjectOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Project',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countProjects(namespace, requestParameters),
    opts
  );
};

const listProjects = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.projectServiceListProjects(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as ProjectResourceList;
};

export const useListProjects = (
  namespace: string,
  opts: ListProjectOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Project',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listProjects(namespace, requestParameters),
    opts
  );
};

export const useListAllProjects = (
  namespace: string,
  opts: ListAllProjectOptions = {},
  listParams: ListAllRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Project',
    'LIST_ALL',
    listParams,
    opts
  );

  return useQuery(
    QK.listAll(namespace, requestParameters),
    (ctx) =>
      listAllResource<ProjectResource, ProjectResourceList>(
        (pageToken) => {
          const pageParams: V1ListParameters = {
            ...requestParameters,
            page_token: pageToken,
          };
          return listProjects(namespace, pageParams);
        },
        { signal: ctx.signal }
      ),
    opts
  );
};

const getProject = async (namespace: string, projectUuid: string) => {
  const api = getApiService();
  const resp = await api.projectServiceGetProject(namespace, projectUuid);
  return resp.data as ProjectResource;
};

export const getProjectQueryOptions = (
  params: ProjectReadParams
): QueryOptions<ProjectResource> => ({
  queryKey: QK.record(params.namespace, params.uuid),
  queryFn: () => getProject(params.namespace, params.uuid),
});

export const useGetProject = (
  params: ProjectReadParams,
  opts: GetProjectOptions = {}
) => {
  return useQuery({
    ...getProjectQueryOptions(params),
    ...opts,
  });
};

const createProject = async (namespace: string, projectBody: V1Project) => {
  const api = getApiService();
  const resp = await api.projectServiceCreateProject(namespace, projectBody);
  return resp.data as ProjectResource;
};

export const useCreateProject = (opts: UpsertProjectOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'Project'),
    mutationFn: (params: ProjectWriteParams) =>
      createProject(params.namespace, params.resource),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateProject = async (params: ProjectUpdateParams) => {
  const { resource, namespace, mask = PROJECT_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.projectServiceUpdateProject(namespace, req);
  return resp.data as ProjectResource;
};

export const useUpdateProject = (opts: UpsertProjectOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'Project'),
    mutationFn: (params: ProjectUpdateParams) => updateProject(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateManyProject = async (params: ProjectUpdateManyParams) => {
  const api = getApiService();
  const allPromiseResponses = await Promise.all(
    params.resources.map((resource) => {
      const { namespace, mask = PROJECT_UPDATE_MASK } = params;
      const req = buildUpdateReq(resource, mask);
      return api.projectServiceUpdateProject(namespace, req);
    })
  );
  const allResponses = allPromiseResponses.map(
    (resp) => resp.data as ProjectResource
  );
  return allResponses;
};

export const useUpdateManyProject = (opts: UpsertManyProjectOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'Project'),
    mutationFn: (params: ProjectUpdateManyParams) => updateManyProject(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        vars.resources.forEach((resource) => {
          if (resource.uuid) {
            queryClient.invalidateQueries(
              QK.record(vars.namespace, resource.uuid)
            );
          }
        });
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteProject = async (params: ProjectReadParams) => {
  const api = getApiService();
  const resp = await api.projectServiceDeleteProject(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteProject = (opts: DeleteProjectOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'Project'),
    mutationFn: (params: ProjectReadParams) => deleteProject(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        queryClient.invalidateQueries(QK.list(vars.uuid));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
