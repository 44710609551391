import { useMemo } from 'react';

import { SortEntrySortEntryOrder } from '@endorlabs/api_client';
import {
  useGetFinding,
  useGetPackageVersion,
  useGetProject,
  useListAssuredPackageVersions,
  useListPolicies,
  useListVersionUpgrade,
} from '@endorlabs/queries';

import { StaleTimes } from '../../../constants';

interface UseFindingDetailDataProps {
  findingUuid?: string;
  namespace: string;
}

export const useFindingDetailData = ({
  findingUuid,
  namespace,
}: UseFindingDetailDataProps) => {
  /**
   * Loads the full finding object from the given query object
   */
  const qGetFinding = useGetFinding(
    { namespace: namespace, uuid: findingUuid ?? '' },
    {
      enabled: !!findingUuid && !!namespace,
      staleTime: StaleTimes.MEDIUM,
    }
  );

  const finding = qGetFinding.data;

  const qGetProject = useGetProject(
    {
      namespace: finding?.tenant_meta.namespace ?? '',
      uuid: finding?.spec.project_uuid ?? '',
    },
    {
      enabled: !!finding?.spec.project_uuid,
      staleTime: StaleTimes.MEDIUM,
    }
  );

  const qGetPackageVersion = useGetPackageVersion(
    {
      namespace: finding?.tenant_meta.namespace ?? '',
      uuid: finding?.meta.parent_uuid ?? '',
    },
    {
      enabled:
        !!finding?.meta.parent_uuid &&
        finding?.meta.parent_kind === 'PackageVersion',
      staleTime: StaleTimes.MEDIUM,
    }
  );

  const hasExceptions =
    finding?.spec.exceptions?.policy_uuids &&
    (finding?.spec.exceptions?.policy_uuids ?? []).length > 0;

  const qExceptionPolicies = useListPolicies(
    namespace,
    {
      enabled: hasExceptions && !!finding,
    },
    {
      filter: `uuid in [${(finding?.spec.exceptions?.policy_uuids ?? []).join(
        ','
      )}]`,
    }
  );

  const exceptionPolicies = useMemo(() => {
    return qExceptionPolicies.data?.list?.objects ?? [];
  }, [qExceptionPolicies.data?.list?.objects]);

  const qAssuredPackageVersion = useListAssuredPackageVersions(
    'oss',
    {
      filter: `meta.name matches "${finding?.spec.target_dependency_package_name}"`,
      mask: ['meta.name', 'spec.package_name'].join(','),
    },
    { enabled: !!finding?.spec.target_dependency_package_name }
  );

  const assuredPackageVersion = qAssuredPackageVersion.data?.objects?.[0];

  const qVersionUpgrade = useListVersionUpgrade(
    namespace,
    {
      filter: `spec.upgrade_info.direct_dependency_package matches "${finding?.spec.target_dependency_name}" and spec.upgrade_info.from_version==${finding?.spec.target_dependency_version}`,
      mask: [
        'spec.upgrade_info.direct_dependency_package',
        'spec.upgrade_info.root_package_version',
        'spec.upgrade_info.from_version',
        'spec.upgrade_info.to_version',
        'spec.upgrade_info.upgrade_risk',
      ].join(','),
      sort: {
        path: 'spec.upgrade_info.score',
        order: SortEntrySortEntryOrder.Desc,
      },
    },
    { enabled: !!finding?.spec.target_dependency_package_name }
  );

  const versionUpgrade = qVersionUpgrade.data?.objects?.[0];

  return {
    assuredPackageVersion,
    exceptionPolicies,
    finding,
    isLoading:
      qGetFinding.isLoading ||
      qGetProject.isLoading ||
      qGetPackageVersion.isLoading,
    packageVersion: qGetPackageVersion.data,
    project: qGetProject.data,
    versionUpgrade,
  };
};
