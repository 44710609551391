import { Grid } from '@mui/material';
import { Outlet } from '@tanstack/react-location';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';
import { IconSettings, NavigationTabs } from '@endorlabs/ui-common';

import { PageHeader } from '../../components';

const SettingsTabNames = {
  LICENSE: 'license',
  SAVED_FILTERS: 'saved-filters',
  SYSTEM: 'system',
};

const DefaultSettingsTabRecords = [
  {
    label: <FM defaultMessage="License" />,
    to: SettingsTabNames.LICENSE,
    value: SettingsTabNames.LICENSE,
    key: SettingsTabNames.LICENSE,
  },
  {
    label: <FM defaultMessage="System Settings" />,
    to: SettingsTabNames.SYSTEM,
    value: SettingsTabNames.SYSTEM,
    key: SettingsTabNames.SYSTEM,
  },
  {
    label: <FM defaultMessage="Saved Filters" />,
    to: SettingsTabNames.SAVED_FILTERS,
    value: SettingsTabNames.SAVED_FILTERS,
    key: SettingsTabNames.SAVED_FILTERS,
  },
];

export const SettingsPage = () => {
  const { ENABLE_LICENSE_AND_BUNDLING: isLicensingAndBundling } =
    useFeatureFlags();

  const SettingsTabRecords = useMemo(() => {
    // Omit license tab when feature flag is not enabled
    if (!isLicensingAndBundling) {
      return DefaultSettingsTabRecords.filter(
        (r) => r.key !== SettingsTabNames.LICENSE
      );
    }

    return DefaultSettingsTabRecords;
  }, [isLicensingAndBundling]);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          Icon={IconSettings}
          title={<FM defaultMessage="Settings" />}
          metadata={{ summary: [] }}
        />
      </Grid>
      <Grid item flexWrap="nowrap" sx={{ width: '100%' }} xs={12}>
        <NavigationTabs tabs={SettingsTabRecords} />
      </Grid>

      <Grid item>
        <Outlet />
      </Grid>
    </Grid>
  );
};
