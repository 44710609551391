import { UseNavigateType } from '@tanstack/react-location';
import Shepherd from 'shepherd.js';

import { ProjectResource } from '@endorlabs/queries';

export const TourStepNames = {
  callpath: 'callpath',
  createPolicy: 'createPolicy',
  exportData: 'exportData',
  projectFindings: 'projectFindings',
  secrets: 'secrets',
  securityPosture: 'securityPosture',
} as const;

export type TourStepName = typeof TourStepNames[keyof typeof TourStepNames];

// Ordered list of steps
export const TourSteps = [
  TourStepNames.projectFindings,
  TourStepNames.callpath,
  TourStepNames.securityPosture,
  TourStepNames.secrets,
  TourStepNames.exportData,
  TourStepNames.createPolicy,
];

export interface ProductTourInputData {
  actionPolicyName: string;
  findingsProjectName: string;
  scmFindingName: string;
  secretsFindingName: string;
  tourTenant: string;
  vulnFindingName: string;
}

export interface ProductTourData {
  actionPolicyUuid?: string;
  findingsProject?: ProjectResource;
  lastUsedTenant?: string;
  scmFindingUuid?: string;
  secretsFindingUuid?: string;
  tourTenant: string;
  vulnFindingUuid?: string;
}

export interface BasicTourStepProps {
  id: TourStepName;
  namespace?: string;
  navigate: UseNavigateType;
  stepNumber: number;
  tour?: Shepherd.Tour;
  tourData: ProductTourData;
  uuid?: string;
}

export type TourStepDefinition = Shepherd.Step.StepOptions & {
  // Primary step text
  description?: string;
  // All elements to highlight
  highlightSelectors?: string[];
  // Number of the step (not 0-indexed)
  stepNumber: number;
  // Step will navigate to this URL before showing
  stepUrl?: string;
  // URL for the "Learn More" button
  urlLearn?: string;
};
