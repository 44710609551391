import { FormattedMessage as FM } from 'react-intl';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import {
  IconArtifactRegistry,
  IconBarChart,
  IconBarChartHorizontal,
  IconBell,
  IconBookmark,
  IconCloud,
  IconConstellation,
  IconGalaxy,
  IconLock,
  IconPlanet,
  IconRocketLaunchOutlined,
  IconSettings,
  IconSmartToyOutlined,
  IconTarget,
  IconTelescope,
  IconUfo,
} from '@endorlabs/ui-common';

import {
  getAccessRootPath,
  getArtifactsPath,
  getCiCdToolsPath,
  getDashboardPath,
  getDependencyPath,
  getFindingsPath,
  getIntegrationsRootPath,
  getNamespacesRootPath,
  getNotificationsRootPath,
  getPackageVersionRootPath,
  getPoliciesRootPath,
  getProjectPath,
  getRemediationsPath,
  getServiceRequestPath,
  getSettingsRootPath,
  getThirdPartyPath,
  NamedRoutes,
} from '../../routes';
import {
  AppDrawerNavItemProps,
  DisplayStatus,
  NavItemsContextProps,
} from './types';
import { getLicensePresent } from './utils';

export const getInventoryNavItems = (
  lastUsedTenant: string,
  isSBomPresent: boolean
): AppDrawerNavItemProps[] => {
  const displaySBOMStatus = isSBomPresent
    ? DisplayStatus.Show
    : DisplayStatus.Deactivate;
  const inventoryItems = [
    {
      Icon: IconBarChart,
      navKey: 'dashboard',
      label: <FM defaultMessage="Dashboard" />,
      url: getDashboardPath({ tenantName: lastUsedTenant }),
      displayStatus: DisplayStatus.Show,
    },
    {
      Icon: IconGalaxy,
      navKey: 'projects',
      label: <FM defaultMessage="Projects" />,
      url: getProjectPath({ tenantName: lastUsedTenant }),
      displayStatus: DisplayStatus.Show,
    },
    {
      Icon: IconPlanet,
      navKey: 'packages',
      label: <FM defaultMessage="My Packages" />,
      url: getPackageVersionRootPath({ tenantName: lastUsedTenant }),
      displayStatus: DisplayStatus.Show,
    },
    {
      Icon: IconConstellation,
      navKey: 'dependencies',
      label: <FM defaultMessage="Dependencies" />,
      url: getDependencyPath({ tenantName: lastUsedTenant }),
      displayStatus: DisplayStatus.Show,
    },
    {
      Icon: IconTelescope,
      navKey: 'findings',
      label: <FM defaultMessage="Findings" />,
      url: getFindingsPath({ tenantName: lastUsedTenant }),
      displayStatus: DisplayStatus.Show,
    },
    {
      Icon: IconUfo,
      navKey: 'sbom',
      label: <FM defaultMessage="SBOM Hub" />,
      url: getThirdPartyPath({ tenantName: lastUsedTenant }),
      displayStatus: displaySBOMStatus,
    },
  ];
  return inventoryItems.filter(
    (item) => item.displayStatus !== DisplayStatus.Hide
  );
};

export const getDiscoverNavItems = (): AppDrawerNavItemProps[] => {
  return [
    {
      Icon: IconSmartToyOutlined,
      navKey: 'droidGPT',
      label: <FM defaultMessage="DroidGPT" />,
      url: NamedRoutes.OSS_EXPLORER,
      displayStatus: DisplayStatus.Show,
    },
  ];
};

// Show CI/CD section when:
// - At least one feature flag for sub-sections is enabled
// - Authenticated user has user tenants
// - The active namespaces is not a shared tenant
export const getCICDNavItems = (
  lastUsedTenant: string,
  context: NavItemsContextProps,
  isCICDPresent: boolean
): AppDrawerNavItemProps[] => {
  const { isCiCdToolsEnabled } = context;
  let displayCICDStatus;
  if (!isCiCdToolsEnabled) {
    displayCICDStatus = DisplayStatus.Hide;
  } else {
    if (isCICDPresent) {
      displayCICDStatus = DisplayStatus.Show;
    } else {
      displayCICDStatus = DisplayStatus.Deactivate;
    }
  }

  return displayCICDStatus !== DisplayStatus.Hide
    ? [
      {
        Icon: IconRocketLaunchOutlined,
        navKey: 'tools',
        label: <FM defaultMessage="Tools" />,
        url: getCiCdToolsPath({ tenantName: lastUsedTenant }),
        displayStatus: displayCICDStatus,
      },
    ]
    : [];
};

// Show Artifact section when:
// - At least one feature flag for sub-sections is enabled
// - Authenticated user has user tenants
// - The active namespaces is not a shared tenant
export const getArtifactsNavItems = (
  lastUsedTenant: string,
  context: NavItemsContextProps,
  isArtifactPresent: boolean
): AppDrawerNavItemProps[] => {
  let displayArtifactStatus;

  if (isArtifactPresent) {
    displayArtifactStatus = DisplayStatus.Show;
  } else {
    displayArtifactStatus = DisplayStatus.Deactivate;
  }

  return [
    {
      Icon: IconArtifactRegistry,
      navKey: 'artifacts',
      label: <FM defaultMessage="Artifacts" />,
      url: getArtifactsPath({ tenantName: lastUsedTenant }),
      displayStatus: displayArtifactStatus,
    },
  ];
};

export const getRemediateNavItems = (
  lastUsedTenant: string,
  context: NavItemsContextProps,
  isRemediatePresent: boolean
): AppDrawerNavItemProps[] => {
  const { isRemediationsEnabled } = context;
  let displayRemediateStatus;

  if (!isRemediationsEnabled) {
    displayRemediateStatus = DisplayStatus.Hide;
  } else {
    if (isRemediatePresent) {
      displayRemediateStatus = DisplayStatus.Show;
    } else {
      displayRemediateStatus = DisplayStatus.Deactivate;
    }
  }

  return displayRemediateStatus !== DisplayStatus.Hide
    ? [
      {
        isBeta: false,
        Icon: IconTarget,
        navKey: 'remediations',
        label: <FM defaultMessage="Remediations" />,
        url: getRemediationsPath({ tenantName: lastUsedTenant }),
        displayStatus: displayRemediateStatus,
      },
    ]
    : [];
};

export const getManageNavItems = (
  lastUsedTenant: string,
  context: NavItemsContextProps
): AppDrawerNavItemProps[] => {
  const { isSharedTenant, isServiceRequestEnabled } = context;

  const displayAdminStatus =
    isServiceRequestEnabled && lastUsedTenant === NAMESPACES.ENDOR_ADMIN
      ? DisplayStatus.Show
      : DisplayStatus.Hide;

  const manageItems = [
    {
      Icon: IconLock,
      navKey: 'access',
      label: <FM defaultMessage="Access Control" />,
      url: getAccessRootPath({ tenantName: lastUsedTenant }),
      displayStatus: !isSharedTenant ? DisplayStatus.Show : DisplayStatus.Hide,
    },
    {
      Icon: IconCloud,
      navKey: 'integrations',
      label: <FM defaultMessage="Integrations" />,
      url: getIntegrationsRootPath({ tenantName: lastUsedTenant }),
      displayStatus: !isSharedTenant ? DisplayStatus.Show : DisplayStatus.Hide,
    },
    {
      Icon: IconBarChartHorizontal,
      navKey: 'namespaces',
      label: <FM defaultMessage="Namespaces" />,
      url: getNamespacesRootPath({ tenantName: lastUsedTenant }),
      displayStatus: !isSharedTenant ? DisplayStatus.Show : DisplayStatus.Hide,
    },
    {
      Icon: IconBell,
      navKey: 'notifications',
      label: <FM defaultMessage="Notifications" />,
      url: getNotificationsRootPath({ tenantName: lastUsedTenant }),
      displayStatus: DisplayStatus.Show,
    },
    {
      Icon: IconBookmark,
      navKey: 'policies',
      label: <FM defaultMessage="Policies" />,
      url: getPoliciesRootPath({ tenantName: lastUsedTenant }),
      displayStatus: DisplayStatus.Show,
    },
    {
      Icon: IconSettings,
      navKey: 'settings',
      label: <FM defaultMessage="Settings" />,
      url: getSettingsRootPath({ tenantName: lastUsedTenant }),
      displayStatus: DisplayStatus.Show,
    },
    {
      Icon: IconArtifactRegistry,
      navKey: 'admin_dashboard',
      label: <FM defaultMessage="Service Requests" />,
      url: getServiceRequestPath({ tenantName: lastUsedTenant }),
      displayStatus: displayAdminStatus,
    },
  ];
  return manageItems.filter(
    (items) => items.displayStatus !== DisplayStatus.Hide
  );
};
