import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  IQueryErrorResponse,
  PackageManagerResource,
} from '@endorlabs/queries';
import { ButtonPrimary, ControlledTextField } from '@endorlabs/ui-common';

import { AuthLoginPackageManagerKey } from './constants';
import { PackageManagerAdvancedFields } from './PackageManagerAdvancedFields';
import { PasswordAuthFieldValues } from './types';
import { translateServerError, updatePasswordFieldsToModel } from './utils';

interface FormPasswordManagerProps {
  authRequired: boolean;
  onSubmit: (data: PasswordAuthFieldValues) => void;
  packageManager?: PackageManagerResource;
  packageManagerKey: AuthLoginPackageManagerKey;
  serverErrorResponse?: IQueryErrorResponse;
}

export const FormUpsertPasswordAuthPackageManager = ({
  authRequired,
  onSubmit,
  packageManager,
  packageManagerKey,
  serverErrorResponse,
}: FormPasswordManagerProps) => {
  const { control, handleSubmit, setError, reset } =
    useForm<PasswordAuthFieldValues>();
  const { formatMessage: fm } = useIntl();
  //Check if it is edit and user has added password
  const isUser = packageManager?.spec[packageManagerKey]?.user;
  const isAuthAdded = isUser && isUser.length > 0 ? true : false;
  const [isAuthDisplayed, setIsAuthDisplayed] = useState<boolean>(
    authRequired || isAuthAdded
  );

  useEffect(() => {
    // reset fields from the given package manager
    reset({ propagate: packageManager?.propagate ?? false });
  }, [packageManager, reset]);

  useEffect(() => {
    if (serverErrorResponse) {
      const err = translateServerError(serverErrorResponse.status);
      setError(err?.field, err.object);
    }
  }, [serverErrorResponse, setError]);

  // process the form data before passing it up to the parent
  const wrappedOnSubmit = useCallback(
    (fieldValues: PasswordAuthFieldValues) => {
      const formattedValues = updatePasswordFieldsToModel(
        fieldValues,
        isAuthDisplayed
      );
      onSubmit(formattedValues);
    },
    [onSubmit, isAuthDisplayed]
  );

  return (
    <form id="passwordAuthPM" onSubmit={handleSubmit(wrappedOnSubmit)}>
      <Grid container direction="column" item spacing={6}>
        <Grid item>
          <ControlledTextField
            control={control}
            defaultValue={
              packageManager?.spec[packageManagerKey]?.priority ?? ''
            }
            name="priority"
            type="hidden"
          />

          <ControlledTextField
            autoComplete="off"
            control={control}
            defaultValue={packageManager?.spec[packageManagerKey]?.url ?? ''}
            fullWidth
            label={fm({
              defaultMessage: 'Package manager URL',
            })}
            name="url"
            rules={{
              required: fm({
                defaultMessage: 'Package manager URL is required',
              }),
              // TODO: URL validation that accounts for S3
            }}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            label={
              authRequired ? (
                <FM defaultMessage="Authenticate to this registry (required)" />
              ) : (
                <FM defaultMessage="Authenticate to this registry (optional)" />
              )
            }
            control={
              <Checkbox
                checked={isAuthDisplayed}
                disabled={authRequired}
                onClick={() => setIsAuthDisplayed(!isAuthDisplayed)}
              />
            }
          />
        </Grid>
        {isAuthDisplayed && (
          <>
            <Grid item width="36%">
              <ControlledTextField
                autoComplete="off"
                control={control}
                defaultValue={
                  packageManager?.spec[packageManagerKey]?.user ?? ''
                }
                fullWidth
                label={<FM defaultMessage="Username" />}
                name="user"
                rules={{
                  required: fm({
                    defaultMessage:
                      'Username is required if using authentication',
                  }),
                }}
                variant="standard"
              />
            </Grid>
            <Grid item width="36%">
              <ControlledTextField
                autoComplete="off"
                control={control}
                defaultValue={
                  packageManager?.spec[packageManagerKey]?.password ?? ''
                }
                fullWidth
                label={<FM defaultMessage="Password" />}
                name="password"
                rules={{
                  required: fm({
                    defaultMessage:
                      'Password is required if using authentication',
                  }),
                }}
                type="password"
                variant="standard"
              />
            </Grid>
          </>
        )}

        <Grid item>
          <PackageManagerAdvancedFields control={control} />
        </Grid>

        <Grid item marginTop={4}>
          <ButtonPrimary type="submit">
            {packageManager && <FM defaultMessage="Save Changes" />}
            {!packageManager && <FM defaultMessage="Add Package Manager" />}
          </ButtonPrimary>
        </Grid>
      </Grid>
    </form>
  );
};
