import { ProductTourInputData } from '../types';

/**
 * Identifiers for specific records needed by the product tour
 */
const staging: ProductTourInputData = {
  actionPolicyName: 'vulnerabilities demo action policy',
  findingsProjectName: 'https://github.com/endorlabs/acme-monorepo.git',
  scmFindingName: 'MFA is not required for external contributors',
  secretsFindingName: 'Valid AWS Access Token: ID #c74b80',
  tourTenant: 'demo-trial',
  vulnFindingName:
    'GHSA-93m7-c69f-5cfj: xmlquery lacks check for whether LoadURL response is in XML format, causing denial of service',
};

// Using the same names for staging & prod
const TourDataConfig = {
  staging,
  production: { ...staging },
};

export const getTourDataConfig = () => {
  return process.env.NODE_ENV === 'production'
    ? TourDataConfig.production
    : TourDataConfig.staging;
};
