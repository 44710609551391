import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SimpleDialog, SimpleDialogProps } from '@endorlabs/ui-common';

import {
  FormDashboardConfigValue,
  FormDashboardConfigValueProps,
} from './FormDashboardConfigValue';

export type DashboardConfigDialogState = Pick<
  FormDashboardConfigValueProps,
  'config' | 'fieldsets' | 'isLoading' | 'onSubmit'
>;

export type DashboardConfigDialogProps = Pick<
  SimpleDialogProps,
  'onClose' | 'open'
> & {
  state?: DashboardConfigDialogState;
};

export const DashboardConfigDialog = ({
  open,
  onClose,
  state,
}: DashboardConfigDialogProps) => {
  const { isLoading = true, fieldsets = [] } = state ?? {};

  const title = useMemo(() => {
    if (isLoading) return <FM defaultMessage="Loading &hellip;" />;

    const [fieldset] = fieldsets;
    switch (fieldset) {
      case 'COST_ESTIMATE':
        return <FM defaultMessage="Customize Cost Saved" />;
      case 'DEV_ESTIMATE':
        return <FM defaultMessage="Customize Dev Hours Saved" />;
      case 'EPSS_THRESHOLD':
        return <FM defaultMessage="Customize Exploitable Likelihood" />;
    }
    return <FM defaultMessage="Dashboard Configuration" />;
  }, [fieldsets, isLoading]);

  return (
    <SimpleDialog onClose={onClose} open={open} titleText={title} maxWidth="sm">
      {state && <FormDashboardConfigValue {...state} onCancel={onClose} />}
    </SimpleDialog>
  );
};
