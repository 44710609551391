import { useMemo } from 'react';

import { ContextContextType } from '@endorlabs/api_client';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  sortParamBuilders,
  useCountRepositoryVersions,
  useListRepositoryVersions,
} from '@endorlabs/queries';
import { useDataTablePaginator } from '@endorlabs/ui-common';

import { ProjectMonitoredVersionsTable } from '../ProjectMonitoredVersionsTable';

export const ProjectMonitoredVersions = ({
  isLoading: isParentLoading,
  namespace,
  onDelete,
  project,
}: {
  isLoading?: boolean;
  namespace: string;
  onDelete: (row: { uuid: string }) => void;
  project?: ProjectResource;
}) => {
  const repositoryVersionFilter =
    project &&
    filterExpressionBuilders.and([
      `meta.parent_uuid=="${project?.uuid}"`,
      filterExpressionBuilders.defaultResourceContexts(),
    ]);

  const qRepositoryVersionCount = useCountRepositoryVersions(
    namespace,
    { enabled: !!project },
    {
      filter: repositoryVersionFilter,
    }
  );

  const repositoryVersionsPaginator = useDataTablePaginator({
    totalCount: qRepositoryVersionCount.data?.count,
  });
  const qRepositoryVersions = useListRepositoryVersions(
    namespace,
    { enabled: !!project && qRepositoryVersionCount.isSuccess },
    {
      ...repositoryVersionsPaginator.getListParameters(),
      filter: repositoryVersionFilter,
      mask: ['uuid', 'meta.name', 'context.type', 'spec.version.sha'].join(','),
      sort: sortParamBuilders.descendingBy('meta.update_time'),
    }
  );

  const monitoredVersions = useMemo(() => {
    const repositoryVersions = qRepositoryVersions.data?.list?.objects ?? [];

    return repositoryVersions.map((rv) => ({
      uuid: rv.uuid,
      name: rv.meta.name,
      sha: rv.spec.version?.sha,
      isDefault: rv.context.type === ContextContextType.Main,
    }));
  }, [qRepositoryVersions.data]);

  const isLoading = isParentLoading || qRepositoryVersions.isLoading;

  return (
    <ProjectMonitoredVersionsTable
      data={monitoredVersions}
      isLoading={isLoading}
      onDelete={onDelete}
    />
  );
};
