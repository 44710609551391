import { SvgIconProps } from '@mui/material';
import { JSXElementConstructor } from 'react';

import { V1PlatformSource } from '@endorlabs/api_client';

import {
  IconArchive,
  IconGalaxy,
  ImgIconLogoMarkBitbucket,
  ImgIconLogoMarkGitHub,
  ImgIconLogoMarkGitLab,
} from '../../../themes';

export const PlatformIconVariants: Record<
  V1PlatformSource,
  JSXElementConstructor<SvgIconProps>
> = {
  [V1PlatformSource.Unspecified]: IconGalaxy,
  [V1PlatformSource.Github]: ImgIconLogoMarkGitHub,
  [V1PlatformSource.Gitlab]: ImgIconLogoMarkGitLab,
  [V1PlatformSource.Gitserver]: IconGalaxy,
  [V1PlatformSource.Bitbucket]: ImgIconLogoMarkBitbucket,
  [V1PlatformSource.Binary]: IconArchive,
  [V1PlatformSource.HuggingFace]: IconGalaxy,
};
