export enum BarLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;
type HSLA = `hsla(${number}, ${number}%, ${number}%, ${number})`;

export type Color = RGB | RGBA | HEX | HSLA;

export type ChartMargin = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export type VerticalPosition = 'top' | 'bottom';
export type HorizontalPosition = 'left' | 'center' | 'right';

export type LegendPosition = 'top' | 'bottom' | 'left' | 'center' | 'right';
export type LegendShape = 'rect' | 'circle' | 'pill';
export type LegendDirection = 'row' | 'column';
export type LegendJustify = 'start' | 'center' | 'end';
export type LegendAlign = 'start' | 'center' | 'end';

export type ValueFormatter = (
  value: number | string,
  key?: string | number
) => string;

export type LegendProps = {
  showLegends?: boolean;
  legendAlign?: LegendAlign;
  legendContainerSize?: number;
  legendDirection?: LegendDirection;
  legendFontSize?: number;
  legendGap?: number;
  legendIconShape?: LegendShape;
  legendJustify?: LegendJustify;
  legendPosition?: LegendPosition;
  showLegendValues?: boolean;
  dataValues?: number[];
  valueFormat?: ValueFormatter;
};

export type BarChartBaseProps<T extends Record<string, string | number>> = {
  data: T[];
  xKey: keyof T;
  yKey: keyof T;
  width: number;
  height: number;
  barColors?: Color[];
  barPadding?: number;
  showLabels?: boolean;
  labelSize?: number;
  labelColor?: Color;
  tooltips?: boolean;
  tooltipTextColor?: Color;
  tooltipBackgroundColor?: Color;
  axisTop?: boolean;
  axisLeft?: boolean;
  axisBottom?: boolean;
  axisRight?: boolean;
  axisColor?: Color;
  showGrid?: boolean;
  gridColor?: Color;
  gridOpacity?: number;
  margin?: ChartMargin;
  onClickHandler?: (data: T, key?: string | number) => void;
} & LegendProps;

export type BarChartVerticalProps<T extends Record<string, string | number>> =
  BarChartBaseProps<T>;

export type BarChartHorizontalProps<T extends Record<string, string | number>> =
  BarChartBaseProps<T> & {
    showBarTitles?: boolean;
    barTitlePosition?: VerticalPosition;
    barTitleFontSize?: number;
    barTitleColor?: Color;
    barTitlePadding?: number;
  };

export type BarChartStackedHorizontalTitlePosition =
  | VerticalPosition
  | Extract<HorizontalPosition, 'left'>;
export type BarChartStackedHorizontalProps<
  T extends Record<string, string | number>
> = Omit<BarChartHorizontalProps<T>, 'xKey' | 'barTitlePosition'> & {
  xKeys: Array<keyof T>;
  axisNumTicks?: number;
  axisPadding?: number;
  axisTickFormat?: ValueFormatter;
  /**
   * When set, enforces a fixed size for individual bars, based on an expected
   * count of total bars in the chart.
   */
  barCount?: number;
  barTitlePosition?: BarChartStackedHorizontalTitlePosition;
  barTitleWidth?: number;
  barTitleValueFormat?: ValueFormatter;
  showBarTitleTooltips?: boolean;
  valueFormat?: ValueFormatter;
};

export type BarChartStackedVerticalsProps<
  T extends Record<string, string | number>
> = Omit<BarChartVerticalProps<T>, 'yKey'> & {
  yKeys: Array<keyof T>;
};

export type StackedAreaChartProps<T extends Record<string, string | number>> =
  Omit<BarChartVerticalProps<T>, 'yKey' | 'barColors'> & {
    areaColors?: Color[];
    yKeys: Array<Extract<keyof T, string | number>>;
  };

export type HeirarchyChartBaseProps<T extends Record<string, unknown>> = {
  data: T[];
  countKey: keyof T;
  groupKey: keyof T;
  parentKey: keyof T;
  width: number;
  height: number;
  strokeWidth?: number;
  showLabels?: boolean;
  labelColor?: Color;
  labelBaseSize?: number;
  labelSkipSize?: number;
  allowVerticalLabels?: boolean;
  colorList: string[];
  tooltips?: boolean;
  tooltipTextColor?: Color;
  tooltipBackgroundColor?: Color;
  margin?: ChartMargin;
  onClickHandler?: (data: T) => void;
};

export type TreemapProps<T extends Record<string, unknown>> =
  HeirarchyChartBaseProps<T>;

export type PackProps<T extends Record<string, unknown>> =
  HeirarchyChartBaseProps<T>;

export type PieChartProps<T extends Record<string, string | number>> = {
  data: T[];
  nameKey: keyof T;
  valueKey: keyof T;
  valueFormat?: ValueFormatter;
  width: number;
  height: number;
  donutThickness?: number;
  cornerRadius?: number;
  padAngle?: number;
  colorList?: Color[];
  showLabels?: boolean;
  labelSize?: number;
  labelColor?: Color;
  tooltips?: boolean;
  tooltipTextColor?: Color;
  tooltipBackgroundColor?: Color;
  margin?: ChartMargin;
  onClickHandler?: (
    data: T | { key: 'TOTAL_VALUE'; value: number | string }
  ) => void;
  showTotalValue?: boolean;
  totalValueLabelSize?: number;
} & LegendProps;

export type BoxPlotProps<T extends Record<string, number[]>> = {
  data: T;
  width: number;
  height: number;
  boxSizeScale?: number;
  boxPadding?: number;
  color?: Color;
  boxFillOpacity?: number;
  strokeWidth?: number;
  tooltips?: boolean;
  tooltipTextColor?: Color;
  tooltipBackgroundColor?: Color;
  axisTop?: boolean;
  axisLeft?: boolean;
  axisBottom?: boolean;
  axisRight?: boolean;
  showGrid?: boolean;
  gridColor?: Color;
  gridOpacity?: number;
  margin?: ChartMargin;
};

export type TooltipData = {
  key: string;
  value: string | number;
};

export interface BoxPlotBoxData {
  name: string;
  median: number;
  IQR: number;
  min: number;
  max: number;
  firstQuartile: number;
  thirdQuartile: number;
  outliers: number[];
}

export interface BoxPlotTooltipData {
  name?: string;
  min?: number;
  median?: number;
  max?: number;
  firstQuartile?: number;
  thirdQuartile?: number;
}
